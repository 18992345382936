import * as _ from "lodash";

const KNOWN_PATHS = ["creditBankAccount", "invoiceNumber", "supplierName"];

export const formatWarnings = (warnings, documentPaths = null) => {
    let groupedWarnings = {};
    let hasWarnings = warnings && warnings.length > 0;
    const filteredKnownPaths = documentPaths ? KNOWN_PATHS.filter((path) => documentPaths.includes(path)) : KNOWN_PATHS;

    if (hasWarnings) {
        let mappedWarnings = warnings.map(warning => {
            warning.path = _.find(filteredKnownPaths, (knownPath) => {
                return knownPath === warning.path
            }) ? warning.path : "";
            return warning;

        });
        groupedWarnings = _.groupBy(mappedWarnings, "path");
        groupedWarnings.hasWarnings = hasWarnings;
        groupedWarnings.hasTopWarning = groupedWarnings[""] !== undefined;
    }

    return groupedWarnings;
};