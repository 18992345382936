import {
    SAVE_MY_TASKS_DONE,
    SAVE_MY_TASKS_ERROR,
    SAVE_MY_TASKS_LOADING,
    CHANGE_SORT,
    CHANGE_SEARCH_FILTER,
    CHANGE_COMPANY_FILTER,
    TOGGLE_ALL_SELECTION,
    TOGGLE_TASK_SELECTION,
    TASK_REMOVE_ONE,
    ENFORCE_APPROVE_DIALOG,
    SCROLL_POSITION,
    HIDDEN_TASKS_LOADED,
    CHANGE_DISPLAYED_DATE,
    REMOVE_FROM_BATCH,
    BATCH_LOADED,
    UNBUNDLE_BATCH,
    CHANGE_AUTOPAY_URL,
    HIDE_AUTOPAY_IFRAME
} from '../store/actionTypes';
import {MY_TASKS_DATES_ORDER} from "../../utils/constants";
import {handleError} from '../../utils/errorHandle.function';
import * as Api from '../../utils/api/api';
import {navigateBetweenSignableTasks} from './action/navigateBetweenTasks.action';
import * as _ from 'lodash';



/**
 * actions dispatched by myTasks.component
 */

export function tasksLoading() {
    return {
        type: SAVE_MY_TASKS_LOADING
    };
}

export function tasksLoaded(tasksArray = [], tasksWithPayment = []) {
    return {
        type: SAVE_MY_TASKS_DONE,
        data: tasksArray,
        tasksWithPayment: tasksWithPayment
    };
}

export function hiddenTasksLoaded(number = 0) {
    return {
        type: HIDDEN_TASKS_LOADED,
        data: number
    };
}

export function tasksLoadingFailed(error) {
    return {
        type: SAVE_MY_TASKS_ERROR,
        data: error
    };
}

export function changeSort(newSort) {
    return {
        type: CHANGE_SORT,
        data: newSort
    };
}

export function changeSearchFilter(textFilter) {
    return {
        type: CHANGE_SEARCH_FILTER,
        data: textFilter
    };
}

export function changeCompanyFilter(filterToCompany = false) {
    return {
        type: CHANGE_COMPANY_FILTER,
        data: filterToCompany
    };
}

/**
 * change the selection of particular task
 * @param taskId
 */
export function changeTaskSelection(taskId) {
    return {
        type: TOGGLE_TASK_SELECTION,
        data: taskId
    }
}

/**
 * if no parameter provided, it toggle all filtered tasks (if all filtered tasks are selected, then it unselects them, otherwise selects all of them)
 * if parameter provided, it will use it as target state
 * @param toggleOn
 */
export function changeAllTaskSelection(wantedState = null) {
    return {
        type: TOGGLE_ALL_SELECTION,
        data: wantedState
    }
}

export function saveScrollPosition(scrollTop = 0) {
    return {
        type: SCROLL_POSITION,
        data: scrollTop
    }
}

export function changeDisplayedDate(newDate) {
    let dateOrder = MY_TASKS_DATES_ORDER[newDate];

    return function (dispatch) {
        dispatch(changeSort(dateOrder));
        dispatch(changeTasksDate(newDate));
    }
}

export function changeTasksDate(newDate) {
    return {
        type: CHANGE_DISPLAYED_DATE,
        data: newDate
    };
}

/**
 * async action, takes care of tasks loading
 * @return {Function}
 */
export function loadTasks() {
    return function (dispatch) {
        dispatch(tasksLoading());
        dispatch(getAllMyTasks());
    }
}

export function seamlessLoadTasks() {
    return function (dispatch) {
        dispatch(getAllMyTasks());
    }
}

function getAllMyTasks() {
    return function (dispatch) {
        Api.getAllTasks().then(
            function (response) {
                dispatch(checkPaymentBatch(response));
            }).catch(error => {
            dispatch(tasksLoadingFailed(error));
        })
    }
}

export function checkPaymentBatch(allTasks) {
    return function (dispatch) {
        let tasksWithPayment = [];
        let newTaskList = [];

        if (allTasks.tasks && allTasks.tasks.length > 0)
            allTasks.tasks.forEach((task) => {
                if (task.preApprovedForPayment !== undefined && task.preApprovedForPayment !== null && task.preApprovedForPayment !== true)
                    tasksWithPayment.push(task);
            });

        Api.getActiveBatches().then(response => {
            let batchActive = response.id ? response : null;

            //we have an active batch so we need to filter out those tasks fromt he task list
            if (batchActive) {
                newTaskList = _.filter(allTasks.tasks, (task) => {
                    return _.findIndex(response.documents, (doc) => {
                        return doc.taskId === task.id
                    }) === -1;
                });

            }

            dispatch(batchLoaded(batchActive));


            let tasks = response.documents && response.documents.length > 0 ? newTaskList : _.cloneDeep(allTasks.tasks);
            dispatch(tasksLoaded(tasks, tasksWithPayment));
            dispatch(hiddenTasksLoaded(allTasks.hiddenTasksForCurrentSession));
        }).catch((error) => {
            console.log(error);
            dispatch(tasksLoaded(allTasks.tasks, tasksWithPayment));
            dispatch(hiddenTasksLoaded(allTasks.hiddenTasksForCurrentSession));
        });
    }
}

export function removeTaskFromStore(key) {
    return {
        type: TASK_REMOVE_ONE,
        data: key
    }
}

export function approveMultipleShortcutKey(doEnforce) {
    return {
        type: ENFORCE_APPROVE_DIALOG,
        data: doEnforce
    }
}


export function batchLoaded(batch = null) {
    return {
        type: BATCH_LOADED,
        data: batch
    }
}

export function addTaskToBatch(batchId, version, id, taskkey) {
    return function (dispatch) {
        if (!batchId) {
            //no batch so I need to create it
            Api.createBatch().then(response => {
                dispatch(addToBatch(response.id, response.version, id, taskkey));
            }).catch((error) => {
                handleError(error);
            });
        } else {
            dispatch(addToBatch(batchId, version, id, taskkey));
        }
    }
}

function addToBatch(batchId, version, id, taskkey) {
    return function (dispatch) {
        Api.addToBatch(batchId, version, id).then(response => {
            // Need to update batch id to response.version
            dispatch(batchLoaded(response));
            dispatch(navigateBetweenSignableTasks(taskkey, true));
            dispatch(removeTaskFromStore(taskkey));
        }).catch((error) => {
            if (error.errorCode === 4) {
                error.applyTranslations = true;
                error.errorTitle = 'taskDetail.approveAndPayError.title';
                error.errorMessages = 'taskDetail.approveAndPayError.body';
            }
            handleError(error);
        });
    }
}

export function batchUnbundled(key) {
    return {
        type: UNBUNDLE_BATCH,
        data: key
    }
}

export function changeAutopayURL(url) {
    return {
        type: CHANGE_AUTOPAY_URL,
        data: url
    }
}

export function hideAutopayiFrame() {
    return {
        type: HIDE_AUTOPAY_IFRAME
    }
}

export function unbundleBatch(batchId, version) {
    return function (dispatch) {
        let state = "CANCELED";
        Api.changeBatch(batchId, version, state).then(response => {
            dispatch(batchUnbundled(batchId));
            dispatch(loadTasks());
        }).catch((error) => {
            dispatch(handlePreApprovalError(error));
        });
    }
}


export function confirmBatch(batchId, version, secret) {
    return function (dispatch) {
        let state = "CONFIRMED";
        return Api.changeBatch(batchId, version, state, secret).then(response => {
            //dispatch(loadTasks());
        }).catch((error) => {
            dispatch(handlePreApprovalError(error));
        });
    }
}

export function prepareBatch(batchId, version) {
    return function (dispatch) {
        let state = "PREPARED";
        Api.changeBatch(batchId, version, state).then(response => {
            if (response.redirectUrl) {
                dispatch(changeAutopayURL(response.redirectUrl));
            }
        }).catch((error) => {
            dispatch(handlePreApprovalError(error));
        });

    }
}

export function handlePreApprovalError(error) {
    return function (dispatch) {
        error.applyTranslations = true;
        if (error.httpStatus === 400) {
            switch (error.errorCode) {
                case 3:
                    let errorMessage = mapErrorsToMessage(error);
                    error.errorMessages = errorMessage !== null ? errorMessage : "preApproveErrors.validationFailed";
                    error.applyTranslations = errorMessage !== null;
                    break;
                case 4:
                    error.errorMessages = "preApproveErrors.operationNotAllowed";
                    break;
                case 5:
                case 6:
                    error.errorMessages = "preApproveErrors.invalidSecret";
                    break;
                default:
                    error.errorMessages = "preApproveErrors.invalidSecret";
                    break;
            }
        } else if (error.httpStatus === 404) {
            error.errorMessages = "preApproveErrors.noBatch";
        } else if (error.httpStatus === 409) {
            error.errorMessages = "preApproveErrors.incorrectVersion";
        }
        handleError(error);
    }
}

export function removeTaskFromBatch(key) {
    return {
        type: REMOVE_FROM_BATCH,
        data: key
    }
}

export function mapErrorsToMessage(error) {
    if (error.fieldErrors && error.fieldErrors.length > 0) {
        return error.fieldErrors.map((item) => {
            return item.message
        }).join("\n");
    } else
        return null;
}