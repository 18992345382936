import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Comment from '../input/Comment.component';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import {NOT_OK, TASK_ACTIONS} from 'utils/constants';
import translate from '../translations/translations.wrapper.jsx';


//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'approve with comment'
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class ReApprovePopUp extends PopUp {

    static propTypes = {
        tasks: PropTypes.array, // selected tasks
        closeCallback: PropTypes.func, //callback for closing the popup
        handledTaskCallback: PropTypes.func, //callback to mark task as handled
        translate: PropTypes.func, // to translate texts
        showNotification: PropTypes.func, // to show transient notification
        approveMultipleTasks: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);

        this.state = {
            comment: '',
            actionRunning: false // we're waiting for backend call result
        }
    }

    ok(skipWorkflowRestart) {
        const self = this;

        this.setState({actionRunning: true});


        const taskIds = this.props.tasks.map((task) => {
            return task.key;
        });

        let commentToSend = this.state.comment ? this.state.comment : null;

        // this redirects to myTasks
        this.props.approveMultipleTasks(taskIds, commentToSend, this.props.taskDetails.documentVersion, skipWorkflowRestart,false,this.props.navigate).then(
            () => self.props.closeCallback()
        );

    }

    componentDidMount() {
        super.componentDidMount();
        this.okButton.focus();
    }

    buildContent() {

        const okEnabled = !this.state.actionRunning;
        return (
            <Fragment>
                <div className="modal-body">
                    <div>
                        <span>{this.props.translate("popUp.reapprove.info")}</span>
                    </div>
                    {this.props.userSettings.showCommentOnApprove &&
                    <div>
                        <hr/>
                        <div>
                            <label htmlFor="comment">{this.props.translate("popUp.approve.commentLabel")}</label>
                        </div>
                        <VisibleToEverybody translate={this.props.translate}/>

                        <Comment
                            ref="commentInput"
                            inputHint={this.props.translate("popUp.approve.commentHint")}
                            translate={this.props.translate}
                            tabIndex={TAB_INDEX}
                            id="comment"
                            value={this.state.comment}
                            propagateValue={value => this.setState({comment: value})}
                        />
                    </div>
                    }
                </div>
                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.ok.bind(null, false)}
                                disabled={!okEnabled}
                                ref={(element) => {
                                    this.okButton = element;
                                }}
                                tabIndex={TAB_INDEX+1}>
                            {this.props.translate("popUp.reapprove.reApprove")}
                        </button>
                        <button className="btn btn-default btn-margin-right"
                                onClick={this.ok.bind(null, true)}
                                disabled={!okEnabled}
                                tabIndex={TAB_INDEX+2}>
                            {this.props.translate("popUp.reapprove.notReApprove")}
                        </button>
                        <button className="btn btn-default"
                                onBlur={() => {
                                    this.refs.commentInput.focus();
                                }}
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX+3}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </Fragment>

        );
    }

    getType() {
        return TASK_ACTIONS.REAPPROVE;
    }

    getTitle() {
        return this.props.translate("popUp.reapprove.title");
    }

}

const withTranslations = translate(ReApprovePopUp);
export default withTranslations;
