import React, { useEffect, useRef, ChangeEvent } from "react";
import { CommentsInputProps } from "types/comments";

const CommentsInput: React.FC<CommentsInputProps> = (props) => {

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const divRef = useRef<HTMLDivElement>(null);

    const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();

        divRef.current.style.height = "auto";
        textAreaRef.current.style.height = "auto";
        divRef.current.style.height = `${textAreaRef.current.scrollHeight + 1}px`;
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;

        if (props.onChange) {
            props.onChange(event);
        }
    };

    useEffect(() => {
        divRef.current.style.height = "auto";
        textAreaRef.current.style.height = "auto";
        divRef.current.style.height = `${textAreaRef.current.scrollHeight + 1}px`;
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;

    }, [props.value]);

    return (
        <div ref={divRef}
             className="d-flex overflow-auto flex-grow-1 flex-shrink-1 input-max-height">
            <textarea
                className="w-100 border-0 overflow-hidden outline-none p-16 shadow-0 resize-none"
                {...props}
                ref={textAreaRef}
                rows={1}
                onChange={onChangeHandler}
                data-cy="taskDetail.comments.input"
            />
        </div>
    );
};

export default CommentsInput;