import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as reduxSelectors from '../../store/application.reducers';
import translate from '../../translations/translations.wrapper.jsx';
import CostUnitWarningPopup from "../../popup/CostUnitWarningPopup.component";
import arrowDown from "../../../assets/arrow-down-icon.png";
import * as _ from "lodash";

import {
    getCostUnitRoles,
    getCostUnitWarnings,
    getApproversTreeRoot,
    getApproversNodes,
    getAllAncerstors,
    closeCostUnitNode
} from '../costunits.action';

import * as Api from "../../../utils/api/api";
import {handleError} from "utils/errorHandle.function";
import {CONFIRMATION} from "utils/constants";
import Confirm from '../../popup/ConfirmActionPopup.component';
import AddCostUnitPopUp from '../../popup/AddCostUnitPopUp.component';
import AssignApproversGrid from "./ApproversForCombinations.component";

export class AssignApprovers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmDelete: false,
            toDelete: null,
            warningMessage: '',
            addCostUnitDialog: false,
            nodes: [],
            showWarningModal: false,
            windowWidth: typeof window !== 'undefined' && window.innerWidth
        };
        this.showWarningModal = this.showWarningModal.bind(this);
        this.hideWarningModal = this.hideWarningModal.bind(this);

        this.getChildren = this.getChildren.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.deleteNode = this.deleteNode.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.openCostUnitDialog = this.openCostUnitDialog.bind(this);
        this.closeCostUnitDialog = this.closeCostUnitDialog.bind(this);
        this.addCostUnitCombination = this.addCostUnitCombination.bind(this);
        this.findNodeInTree = this.findNodeInTree.bind(this);
        this.preventNavigation = this.preventNavigation.bind(this);
        this.computeTreeWidth = this.computeTreeWidth.bind(this);
        this.approversBody = React.createRef();
    }

    componentDidMount() {
        this.props.getApproversTreeRoot();
        this.props.getCostUnitWarnings();

        if (window && typeof window !== 'undefined') {
            window.addEventListener('resize', () => {
                this.setState({
                    windowWidth: window.innerWidth
                })
            });
        }
    }

    getChildren(node, rootId) {
        this.props.getApproversNodes(node, rootId);
    }

    handleScroll(event) {
        let fixedLeft = event.target.scrollLeft + this.approversBody.current.getBoundingClientRect().width - 140;
        let decisions = this.approversBody.current.getElementsByClassName("actions");

        for (let decision of decisions) {
            decision.style.left = fixedLeft + "px";
        }
    }


    showWarningModal() {
        this.setState({
            showWarningModal: true
        })
    }

    hideWarningModal() {
        this.setState({
            showWarningModal: false
        })
    }

    findNodeInTree(id) {
        let foundFirstLevel = _.find(this.props.approversTree.nodes, (node) => {
            return node.id === id;
        });
        if (foundFirstLevel)
            return foundFirstLevel;
        else {
            if (this.props.approversNodes[id])
                return this.props.approversNodes[id];
        }

        return null;
    }

    confirmDelete(toDelete) {
        this.deleteNode(this.state.toDelete, true);
        this.setState({confirmDelete: false});
        this.getChildren(this.state.toDelete, this.state.toDelete.parentId);
    }


    deleteNode(node, confirm = false) {
        Api.deleteCostUnitNode(node.id, confirm, this.props.approversTree.version).then(response => {
            let parent = this.findNodeInTree(node.parent);
            if (parent)
                this.getChildren(parent, parent.rootId);
            else
                this.props.getApproversTreeRoot();
        }, error => {
            if (error.errorType === CONFIRMATION) {
                this.setState({
                    confirmDelete: true,
                    toDelete: {
                        id: node.id
                    },
                    warningMessage: error.errorMessages
                });
            } else if (error.httpStatus === 409) {
                error.errorMessages = this.props.translate("costUnitApprovers.wrongVersion");
                handleError(error);
                this.props.getApproversTreeRoot();
            } else
                handleError(error);
        });
    }


    addCostUnitCombination(response) {
        this.closeCostUnitDialog();
        let lastNodeAdded = response.nodes[0];
        if (lastNodeAdded) {
            let ancestors = lastNodeAdded.ancestors;
            if (ancestors.length > 1) {
                this.props.getAllAncerstors(ancestors)
                    .then(() => this.props.getCostUnitRoles(lastNodeAdded.id, lastNodeAdded.level, this.props.sort));
            } else {
                this.props.getApproversTreeRoot()
                    .then(() => this.props.getCostUnitRoles(lastNodeAdded.id, lastNodeAdded.level, this.props.sort));
            }

        }
    }

    openCostUnitDialog(node) {
        let nodesHierarchy = {};
        let startLevel = node.level ? node.level - 2 : 0;

        nodesHierarchy[startLevel] = {
            id: node.number,
            name: node.name,
            dimensionNumber: node.dimensionNumber
        };

        //current node is not the first node, so we must iterate through all it's parents
        if (startLevel > 0 && node.parent) {
            let parent = this.props.approversNodes[node.parent];
            while (parent) {
                nodesHierarchy[parent.level - 2] = {
                    id: parent.number,
                    name: parent.name,
                    dimensionNumber: node.dimensionNumber
                };
                parent = this.props.approversNodes[parent.parent];
            }
        } else if (startLevel === 0 && this.props.approversTree && _.findIndex(this.props.approversTree.nodes, (n) => {
            return n.id === node.id
        }) !== -1) {
            nodesHierarchy[0] = {
                id: node.number,
                name: node.name,
                dimensionNumber: node.dimensionNumber
            }
        } else {
            nodesHierarchy[0] = {
                id: null,
                name: this.props.translate("generic.genericAny"),
                costunitId: null
            }
        }

        this.setState({
            addCostUnitDialog: true,
            selectedNodes: nodesHierarchy
        });
    }

    closeCostUnitDialog() {
        this.setState({
            addCostUnitDialog: false
        });
    }

    preventNavigation(shouldPrevent) {
        this.setState({isEditing: shouldPrevent});
        this.props.preventNavigation(shouldPrevent);
    }

    computeTreeWidth(treeDepth, calculatedTreeWidth) {
        if (treeDepth <= 3)
            return "100%";

        if (typeof window !== 'undefined' && calculatedTreeWidth < (this.state.windowWidth - 60)) {
            return this.state.windowWidth - 60 + "px";
        }
        return calculatedTreeWidth + "px";
    }

    render() {
        const treeDepth = this.props.costUnits.length;
        const calculatedTreeWidth = (treeDepth + 1) * 250;
        const treeWidth = this.computeTreeWidth(treeDepth, calculatedTreeWidth);
        const containerWidth = this.approversBody.current ? this.approversBody.current.getBoundingClientRect().width : "100%";

        let tree = this.props.approversTree && this.props.approversTree.nodes.map((node, index) => {
            return (node.leaf ?
                    <TreeLeafNode node={node} key={node.id + "_" + node.parent + "_" + node.level} getCostUnitRoles={this.props.getCostUnitRoles}
                                  sort={this.props.sort}
                                  depth={treeDepth} treeWidth={containerWidth}
                                  deleteNode={this.deleteNode}
                                  isEditing={this.state.isEditing}
                                  allowActions={this.props.userRoles.systemAdministrator}
                                  selectedCostUnit={this.props.approversTree.selectedCostUnit}
                                  onEdit={this.openCostUnitDialog}/> :
                    <TreeNodes node={node}
                               key={node.id + "_" + node.parent + "_" + node.level}
                               rootId={this.props.approversTree.rootId}
                               children={this.props.approversNodes}
                               getCostUnitRoles={this.props.getCostUnitRoles}
                               sort={this.props.sort}
                               getChildren={this.getChildren}
                               treeWidth={containerWidth}
                               depth={treeDepth}
                               deleteNode={this.deleteNode}
                               isEditing={this.state.isEditing}
                               allowActions={this.props.userRoles.systemAdministrator}
                               closeCostUnitNode={this.props.closeCostUnitNode}
                               selectedCostUnit={this.props.approversTree.selectedCostUnit}
                               onLeafEdit={this.openCostUnitDialog}
                               onEdit={this.openCostUnitDialog}/>
            );
        });

        let treeHeader = this.props.costUnits && this.props.costUnits.map((costUnit, index) => {
            return <div className={"header-title" + (index === 0 ? " margin-left-8" : "")} key={costUnit.name+"_"+costUnit.ordinal}>{costUnit.name}</div>
        });


        return (
            <div>
                {this.state.confirmDelete &&
                    <Confirm translate={this.props.translate}
                             message={this.state.warningMessage}
                             handleAction={this.confirmDelete}
                             closeCallback={() => {
                                 this.setState({confirmDelete: false})
                             }}/>}
                <h2 className="no-border d-flex align-items-center pt-4">{this.props.translate("costUnitApprovers.header")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                          title={this.props.translate("costUnitApprovers.headerInfo")}/>
                    {this.props.warnings && this.props.warnings.length > 0 ?
                        <span className="vismaicon vismaicon-filled vismaicon-warning margin-left"
                              onClick={this.showWarningModal}
                              title={this.props.translate("costUnitApprovers.warningInfo")}/> : null}

                    <button className="btn btn-primary btn-margin-right ms-auto"
                            onClick={this.openCostUnitDialog}
                            disabled={this.state.showLastRow || this.state.isEditing || !this.props.userRoles.systemAdministrator}>
                        {this.props.translate("costUnitApprovers.addCombination")}
                    </button>
                </h2>

                {this.state.addCostUnitDialog &&
                    <AddCostUnitPopUp translate={this.props.translate}
                                      getApproversTreeRoot={this.props.getApproversTreeRoot}
                                      costUnits={this.props.costUnits}
                                      treeVersion={this.props.approversTree?.version}
                                      selectedNodes={this.state.selectedNodes}
                                      handleAction={this.addCostUnitCombination}
                                      closeCallback={this.closeCostUnitDialog}/>
                }


                <div id="Approvers-Tree" className="actions-list" onScroll={this.handleScroll} ref={this.approversBody}>
                    {this.state.showWarningModal ?
                        <CostUnitWarningPopup warnings={this.props.warnings}
                                              translate={this.props.translate}
                                              closeCallback={this.hideWarningModal}/> : null}
                    <div className="tree-header header-row" style={{width: treeWidth}}>
                        {this.props.costUnits && treeHeader}
                        <div className="header-title actions text-end"
                             style={{left: containerWidth - 280 + "px"}}>
                            {this.props.translate("costUnitApprovers.actions")}
                        </div>
                    </div>

                    <ul id="Assign-Approvers" style={{width: treeWidth}}>
                        {this.props.approversTree && this.props.approversTree.nodes.length > 0 ?
                            tree :
                            <li>
                                <h2 className="py-3 no-border text-center color-grey"> {this.props.translate("costUnitApprovers.noCostUnits")}</h2>
                            </li>
                        }
                    </ul>
                </div>


                <AssignApproversGrid translate={this.props.translate}
                                     costUnitRoles={this.props.costUnitRoles}
                                     preventNavigation={this.preventNavigation}
                                     allowActions={this.props.userRoles.systemAdministrator}
                />

            </div>)

    }

}

const withTranslations = translate(AssignApprovers);
const mapStateToProps = function (store) {
    return {
        costUnits: reduxSelectors.getSeletedCostUnitTypes(store),
        costUnitRoles: reduxSelectors.getCostUnitRoles(store),
        warnings: reduxSelectors.getCostUnitWarnings(store),
        approversTree: reduxSelectors.getApproversTree(store),
        approversNodes: reduxSelectors.getApproversNodes(store),
        sort: reduxSelectors.getRolesSort(store),
        userRoles: reduxSelectors.getUsersRoles(store)
    };
};

const connected = connect(mapStateToProps, {
    getCostUnitRoles,
    getCostUnitWarnings,
    getApproversTreeRoot,
    getApproversNodes,
    getAllAncerstors,
    closeCostUnitNode
})(withTranslations);

export default connected;

class TreeNodes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tree: null,
            open: false
        };
        this.getChildren = this.getChildren.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.openNode = this.openNode.bind(this);
        this.deleteNode = this.deleteNode.bind(this);
        this.getAssignedApprovers = this.getAssignedApprovers.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    openNode(event) {
        event.stopPropagation();
        let isNodeOpen = this.props.children && this.props.children[this.props.node.id] && this.props.children[this.props.node.id].open;

        if (!isNodeOpen && !this.props.isEditing) {
            this.getChildren();
        } else {
            if (!this.props.isEditing)
                this.props.closeCostUnitNode(this.props.node);
        }
    }

    onEdit(event) {
        if (event.stopPropagation)
            event.stopPropagation();
        this.props.onEdit(this.props.node);
    }

    getAssignedApprovers(event) {
        event.stopPropagation();
        if (!this.props.isEditing)
            this.props.getCostUnitRoles(this.props.node.id, this.props.node.level, this.props.sort);
    }

    getChildren(event) {
        this.props.getChildren(this.props.node, this.props.node.parent);
    };

    deleteNode(event) {
        event.stopPropagation();
        this.props.deleteNode(this.props.node);
    }


    buildContent() {

        let children = this.props.children && this.props.children[this.props.node.id] ? this.props.children[this.props.node.id].nodes.map((node, index) => {
            return (node.leaf ?
                    <TreeLeafNode node={node} key={node.id + "_" + node.parent + "_" + node.level}
                                  getCostUnitRoles={this.props.getCostUnitRoles}
                                  deleteNode={this.props.deleteNode}
                                  depth={this.props.depth} treeWidth={this.props.treeWidth}
                                  selectedCostUnit={this.props.selectedCostUnit}
                                  sort={this.props.sort}
                                  isEditing={this.props.isEditing}
                                  allowActions={this.props.allowActions}
                                  onEdit={this.props.onLeafEdit}/> :
                    <TreeNodes node={node}
                               key={node.id + "_" + node.parent + "_" + node.level}
                               rootId={this.props.node.parent}
                               children={this.props.children}
                               getCostUnitRoles={this.props.getCostUnitRoles}
                               sort={this.props.sort}
                               depth={this.props.depth}
                               treeWidth={this.props.treeWidth}
                               getChildren={this.props.getChildren}
                               deleteNode={this.props.deleteNode}
                               selectedCostUnit={this.props.selectedCostUnit}
                               onLeafEdit={this.props.onLeafEdit}
                               isEditing={this.props.isEditing}
                               allowActions={this.props.allowActions}
                               closeCostUnitNode={this.props.closeCostUnitNode}
                               onEdit={this.props.onEdit.bind(this, node)}/>
            );
        }) : null;

        return children;

    }

    render() {
        let generatedCols = [...Array(this.props.node.level - 2)].map(() => {
            return <div className="col-md-3 column empty"
                        key={this.props.node.id + "_" + this.props.node.parent + "_" + this.props.node.level}/>;
        });


        let depthCols = this.props.depth - this.props.node.level > 1 ? [...Array(this.props.depth - this.props.node.level + 1)].map(() => {
            return <div className="column empty-after"
                        key={this.props.node.id + "_" + this.props.node.parent + "_" + this.props.node.level}/>;
        }) : null;

        let isOpen = this.props.children && this.props.children[this.props.node.id] && this.props.children[this.props.node.id].open;
        return (
            <Fragment>
                <li className={"tree-child " + (this.props.selectedCostUnit === this.props.node.id ? " selected" : "")}
                    onClick={this.getAssignedApprovers}>

                    {generatedCols}

                    <div className="child-title cursor-pointer cropped-text"
                         title={this.props.node.name + " (" + this.props.node.number + ")"}>
                        <img className={`p-1 me-2 arrow-icon ${!isOpen ? '' : 'right'}`}
                             src={arrowDown}
                             alt="arrow down"
                             onClick={this.openNode}/>
                        {this.props.node.name} ({this.props.node.number})
                    </div>

                    {depthCols}

                    <div className="actions column text-end"
                         style={{left: this.props.treeWidth - 140 + "px"}}>
                        <span
                            className={"vismaicon vismaicon-delete vismaicon-dynamic" + (this.props.isEditing || !this.props.allowActions ? " disabled" : "")}
                            onClick={this.props.deleteNode.bind(this, this.props.node)}/>
                        <span
                            className={"vismaicon vismaicon-add-circle vismaicon-dynamic ms-3" + (this.props.isEditing || !this.props.allowActions ? " disabled" : "")}
                            onClick={this.onEdit}/>
                    </div>


                </li>

                {isOpen && this.buildContent()}

            </Fragment>);
    }
}

const TreeLeafNode = (props) => {
    let getData = function () {
        if (!props.isEditing)
            props.getCostUnitRoles(props.node.id, props.node.level, props.sort);
    };

    let editNode = function (event) {
        event.stopPropagation();
        props.onEdit(props.node);
    };


    let generatedCols = [...Array(props.node.level - 2)].map((number, index) => {
        return <div className="column empty" key={props.node + "_" + props.node.level}/>;
    });

    let depthCols = props.depth - props.node.level > 1 ? [...Array(props.depth - props.node.level)].map((number, index) => {
        return <div className="column empty" key={props.node + "_" + props.node.level}/>;
    }) : null;

    return (
        <li className={"tree-child leaf cursor-pointer" + (props.selectedCostUnit === props.node.id ? " selected" : "")}
            onClick={getData}>

            {generatedCols}
            <div className="column child-title margin-left-8 cropped-text"
                 title={props.node.name + " (" + props.node.number + ")"}>{props.node.name} ({props.node.number})
            </div>
            {depthCols}

            <div className="actions column text-end " style={{left: props.treeWidth - 140 + "px"}}>
                <span
                    className={"vismaicon vismaicon-delete vismaicon-dynamic" + (props.isEditing || !props.allowActions ? " disabled" : "")}
                    onClick={props.deleteNode.bind(null, props.node)}/>
                <span
                    className={"vismaicon vismaicon-add-circle vismaicon-dynamic ms-3" + (props.isEditing || !props.allowActions ? " disabled" : "")}
                    onClick={editNode}/>
            </div>
        </li>);
};