import Tooltip from "react-tooltip";
import React from "react";
import _ from 'lodash';

export const WarningIcon = () => (<span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-warning"/>);

export const WarningsTooltip = (props) => {
    const warningsBySource = _.groupBy(props.warnings, w => w.source);
    const content = Object.keys(warningsBySource).map((source) => {
        const warnings = warningsBySource[source];
        return (
            <div>
                <strong>{source}</strong>
                {warnings.map((warning, index) => {
                    return (<div key={index}>{warning.message}</div>);
                })}
            </div>
        )
    })

    return (
        <Tooltip id={props.id} place="top" type="dark" effect="solid">
            {content}
        </Tooltip>
    );
};
