import React from 'react';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import Confirm from '../popup/ConfirmActionPopup.component';
import translate from '../translations/translations.wrapper.jsx';
import ConfigurationWrapper from './ConfigurationWrapper.component';

export class Configuration extends ConfigurationWrapper {

    buildContent() {
        let frameStyle = {
            height: "80vh",
            marginTop: "36px"
        };

        return (
            <div>
                {this.state.showWarning &&
                <Confirm translate={this.props.translate}
                         message={this.props.translate("settings.confirmPageLeave")}
                         handleAction={this.remain}
                         closeCallback={this.leave}/>
                }
                <iframe ref="old_settings"
                        title={"Old Settings"}
                        className="col-md-12 no-padding"
                        style={frameStyle}
                        frameBorder="0"
                        height="85vh"
                        src={"/approval/settings.xhtml?contextSelector:switchContext=" + this.props.currentContextId}/>
            </div>
        )
    }
}

const withTranslations = translate(Configuration);
const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        businessFeatures: reduxSelectors.getUsersCurrentBusinessFeatures(store)
    };
};

const connected = connect(mapStateToProps, {})(withTranslations);
export default connected;

