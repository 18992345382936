import {showErrorMessage} from "components/usercontext/user.action";
import store from 'components/store/Store.component';

export function handleError(error) {
    store.dispatch(showErrorMessage({
        status: error.status,
        statusText: error.errorMessages || error.message,
        title: error.errorTitle,
        applyTranslations: error.applyTranslations
    }));

}

export const insufficientRightsError = {
    errorCode: 304,
    errorMessages: "You do not have access rights to this part of Visma.net Approval.",
    errorTitle: "Insufficient rights",
    errorType: "ERROR",
    fieldErrors: [],
    status: 403
};

/**
 * get error sent by backend
 * maps the error
 * @returns {string}
 */

export function mapErrorMessage(error) {
    //the error is just a text
    if (typeof error === 'string') {
        return error;
    }
    //json error
    if (typeof error === 'object') {
        if (error.errorMessages)
            return error.errorMessages;
        //sometimes it's error.data other times it's error.details so make it generic
        let errorKeys = Object.keys(error);
        if (errorKeys.length > 0) {
            return error[errorKeys[0]].errorMessages;
        }
    }
    //should not get here
    return 'unknown error';
}
