import React from 'react';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import translate from '../translations/translations.wrapper.jsx';
import {LOADING_STATUS} from 'utils/constants';
import {
    activateRule,
    activateReadonlyRule,
    deactivateReadonlyRule,
    addNewRule, clearWorkflowsHistory,
    getWorkflows,
    getWorkflowsHistory
} from './workflows.action';
import "./workflows.scss";
import {Rules} from "./Rules.component";
import * as _ from "lodash";
import {snowplowTrackEvent} from 'utils/snowplow';
import WorkflowHistoryPopUp from "../popup/WorkflowHistoryPopUp.component";
import ConfigurationWrapper from "components/configuration/ConfigurationWrapper.component.jsx";


export class Workflows extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            currentContextId: props.currentContextId,
            showWorkflowHistory: false,
            showDocTypeFilters: true,
            selectedRuleId: null
        };
        this.openWorkflowsHistory = this.openWorkflowsHistory.bind(this);
        this.userInput = React.createRef();
    }

    openWorkflowsHistory() {
        snowplowTrackEvent({
            category: "audit trail",
            action: "open_workflow_history",
            label: "global workflows history",
            value: ""
        }, this.props.userConfiguration);

        this.props.getWorkflowsHistory();

        this.setState({
            showWorkflowHistory: true,
            showDocTypeFilters: true,
            selectedRuleId: null
        });

    }

    componentDidMount() {
        this.props.getWorkflows();
    }

    componentDidUpdate(props) {
        if (props.currentContextId !== this.props.currentContextId) {
            this.setState({
                currentContextId: this.props.currentContextId
            }, () => {
                this.props.getWorkflows();
            });
        }
    }

    buildContent() {
        let workflowHeaders = Object.keys(this.props.workflows).map((key, index) => {
            return <Rules rules={this.props.workflows[key]}
                          key={index}
                          index={index}
                          showActions={this.props.userData.userRoles.systemAdministrator}
                          docType={key}
                          addRule={this.props.addNewRule}
                          activateRule={this.props.activateRule}
                          activateReadonlyRule={this.props.activateReadonlyRule}
                          deactivateReadonlyRule={this.props.deactivateReadonlyRule}
                          translate={this.props.translate}
                          lastCreatedWorkflow={this.props.lastCreatedWorkflow}/>
        });

        let workflowHeadersNew = this.props.docTypes ? this.props.docTypes.map((docType, index) => {
            return <Rules rules={[]}
                          key={index}
                          index={index}
                          showActions={this.props.userData.userRoles.systemAdministrator}
                          docType={docType}
                          addRule={this.props.addNewRule}
                          activateRule={this.props.activateRule}
                          activateReadonlyRule={this.props.activateReadonlyRule}
                          deactivateReadonlyRule={this.props.deactivateReadonlyRule}
                          lastCreatedWorkflow={this.props.lastCreatedWorkflow}
                          translate={this.props.translate}/>
        }) : [];

        switch (this.props.loadingStatus) {
            case LOADING_STATUS.LOADING:
                return (<div className="col-md-12">
                    <span className="spinner spinner-default-blue loading"/>
                </div>);
            case LOADING_STATUS.DONE:
                return (
                    <div className="workflows-container">
                        <div className="pt-4 workflows workflows-center-align">
                            {this.state.showWorkflowHistory &&
                                <WorkflowHistoryPopUp
                                    closeCallback={() => {
                                        this.setState({showWorkflowHistory: false});
                                        this.props.clearWorkflowsHistory();
                                    }}
                                    docTypes={this.state.showDocTypeFilters ? this.props.docTypes : null}
                                    ruleId={this.state.selectedRuleId}
                                    translate={this.props.translate}/>
                            }
                            <h3 className="no-border">{this.props.translate("workflows.header")}
                                    <span>
                                        <button type="button" className="btn float-end"
                                                onClick={this.openWorkflowsHistory}>
                                        <span
                                            className={"vismaicon vismaicon-sm  vismaicon-dynamic vismaicon-history"}/>
                                            {this.props.translate("workflows.workflowHistoryBtn")}
                                        </button>

                                    </span>
                            </h3>

                            <ul className="workflow-list list-unstyled workflows-center-align workflows-list-center-align" ref="workflowList">
                                {_.isEmpty(this.props.workflows) ?
                                    workflowHeadersNew : workflowHeaders
                                }
                            </ul>
                        </div>
                    </div>
                )

            default:
                return (<div/>);
        }
    }
}

const withTranslations = translate(Workflows);
const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userData: reduxSelectors.getUsersData(store),
        loadingStatus: reduxSelectors.getWorkflowsLoadingStatus(store),
        workflows: reduxSelectors.getWorkflowsForRender(store),
        docTypes: reduxSelectors.getWorkflowsDocTypes(store),
        userConfiguration: reduxSelectors.getUsersData(store),
        lastCreatedWorkflow: reduxSelectors.getLastCreatedWorkflow(store),
    };
};

const connected = connect(mapStateToProps, {
    activateRule,
    activateReadonlyRule,
    deactivateReadonlyRule,
    addNewRule,
    getWorkflows,
    getWorkflowsHistory,
    clearWorkflowsHistory
})(withTranslations);

export default connected;


export const UserCommentArea = (props) => {
    return (
        <div>
            <div className="mb-4">
                {props.message}
            </div>
            <br/>
            <div>{props.translate("workflowDetails.confirmDialog.describeChanges")}</div>
            <textarea
                id="save_workflow_user_comment_input"
                ref={props.userInput}
                className={"w-100 comment-box-textarea"}/>
            <div className={"text-muted"}>
                {props.translate("workflowDetails.confirmDialog.moreInfo")}
            </div>
        </div>

    )
}