import React, {Fragment} from 'react';
import PopUp from "./PopUp.component";

export default class CostUnitWarningPopup extends PopUp {

    getType() {
        return 'costUnitWarning';
    }

    buildContent() {
        return (
            <Fragment>
                <div id="warning-grid" className="modal-body">
                    <div className="list-group px-0" id="warning-grid-title">
                        <div className="list-group-item list-group-title">
                            <div className="row mx-0">
                                <div className="col-md-6">{this.props.translate("popUp.costUnitWarning.costUnit")}</div>
                                <div className="col-md-6">{this.props.translate("popUp.costUnitWarning.warning")}</div>
                            </div>
                        </div>

                        {this.props.warnings.map((item, index) => {
                            const costUnit = `${item.dimensionName} (${item.dimensionNumber}): ${item.number} (${item.name})`;
                            return (
                                <div className="list-group-item" key={index}>
                                    <div className="row mx-0">
                                        <div className="col-md-6">{costUnit}</div>
                                        <div
                                            className="col-md-6 warning">{this.props.translate("popUp.costUnitWarning.noLongerAvailable")}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="close-button float-right">
                        <button type="button" className="btn btn-default"
                                onClick={this.props.closeCallback}>{this.props.translate("popUp.close")}</button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

