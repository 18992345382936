import {APP_START, SAVE_VERSION} from '../store/actionTypes';
/**
 * action denoting that we should display application (eg we've finished merging of previous store state with actual session context)
 * @return {{type}}
 */
export function startApp(){
    return {
        type: APP_START
    }
}

export function saveVersion(version) {
    return {
        type: SAVE_VERSION,
        data: version
    }
}