import moment from 'moment';
import {formatCurrency} from './i18n/wnumb.js';
import {DOCUMENT_TYPE, TASK_OVERDUE_STATUS} from './constants';

/*
 * copied from old approval frontend, proper definitions are in AC-2929 but differs from defaults
 * TODO align with the old definitions, https://jira.visma.com/browse/AC-3233
 */

/**
 * convert utc to localized string
 * @param value unix timestamp
 * @param defaultValue value which will be displayed for invalid @value, defaults to '-'
 * @returns localized date string in local timezone
 */
export function formatDate(value, defaultValue) {

    if (defaultValue == null) {
        defaultValue = "-";
    }

    if (value === null || value === "" || value === undefined) {
        return defaultValue;
    }

    // see the comment above
    return moment.utc(value).local().format("L");
}

/**
 * convert utc to localized string
 * @param value unix timestamp
 * @param defaultValue value which will be displayed for invalid @value, defaults to '-'
 * @returns localized date with hours and minutes as string in local timezone
 */
export function formatDateTime(value, defaultValue) {
    if (defaultValue === null) {
        defaultValue = "-";
    }

    if (value === null || value === "") {
        return defaultValue;
    }

    // see the comment above
    return moment.utc(value).local().format("L LT");
}

export function formatAmountValue(amount) {
    let result = formatCurrency(amount ? amount : 0);
    return result;
}

/** *
 * @param type document type
 * @param amount
 * @returns formated class name based on document type and amount
 */
export function formatDocumentType(type, amount) {
    let result = "document-type-icon document-type-" + DOCUMENT_TYPE.asString(type);
    if (amount && type === DOCUMENT_TYPE.INVOICE && amount.toString().trim().indexOf("-") === 0)
        result += "-NEGATIVE";
    return result;
}

/**
 * whether we should show foreignAmount and/or currency
 * @param amount
 * @param unit
 * @param foreignAmount
 * @param foreignUnit
 * @return {{amount: *, unit: *, foreignAmount: *, foreignUnit: null}}
 */
export function shouldShowForeignAmount(amount, unit, foreignAmount = null, foreignUnit = null) {


    let foreignAmountFinal = foreignAmount ? foreignAmount : null;
    let foreignUnitFinal = foreignUnit && foreignAmount ? foreignUnit : null;

    // in foreign data are the same as 'local' don't show them
    if (foreignUnitFinal === unit && amount === foreignAmountFinal) {
        foreignAmountFinal = null;
        foreignUnitFinal = null;
    }

    return {
        amount: amount,
        unit: unit,
        foreignAmount: foreignAmountFinal,
        foreignUnit: foreignUnitFinal
    }
}

export function formatAmount(amount, currency, foreignAmount = null, foreignCurrency = null) {

    let status = shouldShowForeignAmount(amount, currency, foreignAmount, foreignCurrency);

    let value = formatAmountValue(status.amount);
    if (status.unit) {
        value += " " + status.unit;
    }

    if (status.foreignAmount) {
        value += " (" + formatAmountValue(status.foreignAmount) + " " + (status.foreignUnit || '') + ")";
    }

    return value;
}

export function formatDocumentOrganizationUnit(orgUnit) {
    if (!orgUnit) return "-";

    // used to be toString on DocumentOrganizationUnit
    let value = orgUnit.id;
    if (orgUnit.name && orgUnit.code) value = orgUnit.name + " (" + orgUnit.code + ")";
    else if (orgUnit.name) value = orgUnit.name;
    else if (orgUnit.code) value = orgUnit.code;

    return value;
}

export function formatCostUnit_dimension(orgUnit) {
    return nullSafe(orgUnit.dimensionName) + " (" + nullSafe(orgUnit.dimension) + ")";
}

export function formatCostUnit_number(orgUnit) {
    return nullSafe(orgUnit.costUnitName) + " (" + nullSafe(orgUnit.costUnitNumber) + ")";
}

function nullSafe(value) {
    return value == null ? "N/A" : value;
}

/**
 * backend in some cases uses own syntax for placeholders (for example: "The task {0} is approved"), so this function replace them
 * with additional arguments to this call (placeholders should be numbered from zero: {0}, {1}, ..)
 * @param message
 * @param replacement.. additional arguments will replace placeholders
 */
export function replacePlaceholderInMessage(message) {
    // inspiration for replacement taken from the stackoverflow
    // http://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
    let args = Array.prototype.slice.call(arguments, 1);
    return message.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
    });
}

/**
 * in translations we have placeholder's with different syntax (for example: "The document is due at %1, but please finish your task by %2")
 * so this replace them with additional arguments to this call (placeholders should be numbered from 1: %1, %2 ..)
 * @param message
 */
export function replacePlaceholderInTranslation(message) {
    // inspiration for replacement taken from the stackoverflow
    // http://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format/4673436#4673436
    let args = Array.prototype.slice.call(arguments, 1);
    return message.replace(/%(\d+)/g, function (match, number) {
        return typeof args[number - 1] !== 'undefined' ? args[number - 1] : match;
    });
}

/*
*formated value for the task header : from user name + description
* */
export function getTaskUserDescription(task) {
    const currentTask = task.task ? Object.assign({}, task.task) : Object.assign(task);
    //add the from field value
    const fromName = currentTask.supplierName ? currentTask.supplierName : currentTask.requesterName;
    //add the description
    const description = currentTask.description;

    let response = fromName ? (fromName + ": ") : "";
    response += description;
    return response;
}

/*
* return the default value for the task banner description for task details
*  currentTask: 1,  // in view task number from the list of selected tasks
*  total: undefined,   // number of selected tasks - all if none selected
*  title: "",  // task title : user name + amount + currency
*  description: "",    // task description
*  type: "",    //task type
* */
export function formatTaskDetailsTopBanner(task, isHistory = false) {
    let response = {
        currentTask: 1,  // in view task number from the list of selected tasks
        total: undefined,   // number of selected tasks - all if none selected
        title: "",  // task title : user name + amount + currency
        shortTitle: "",  // task title : user name
        description: "",    // task description
        type: "",    //task type
        overdue: ''  //is task overdue
    };

    if (!task)
        return response;

    response.title = task.supplierName || task.requesterName || task.requesterFirstName +" " + task.requesterLastName;
    response.shortTitle = response.title;

    if (task.amount) {
        if (response.title?.length > 0)
            response.title += ": ";
        response.title += (isHistory ? task.amount : formatAmountValue(task.amount)) + " " + (task.currency ? task.currency : (task.currencyCode || ''));
    }
    response.description = task.description;
    response.type = formatDocumentType(task.documentType, task.amount);
    response.overdue = getTaskOverdueStatus(task.documentDueDate, task.dueDateWarning);
    response.isProcess = isHistory;

    return response;
}

/*
* Task Overdue status
* @param dueDate- task due date
* @param dueDateWarning- task dueDateWarning
* returns : String
* */
export function getTaskOverdueStatus(dueDate, dueDateWarning) {
    let response = {
        overdueClass: '',
        tooltip: '',
        dueDate: ''
    };
    let isTaskOverdue = dueDate != null && dueDate < Date.now();
    let isSoonOverdue = dueDateWarning !== null && dueDateWarning < Date.now();
    response.dueDate = formatDate(dueDate);
    if (isTaskOverdue) {
        response.overdueClass = TASK_OVERDUE_STATUS.TASK_OVERDUE;
    } else if (isSoonOverdue) {
        response.overdueClass = TASK_OVERDUE_STATUS.TASK_SOON_OVERDUE;
    } else {
        response.overdueClass = TASK_OVERDUE_STATUS.TASK_NOT_OVERDUE;
    }
    return response;
}

export function formatCalendarDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return year + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
}

export function getLocaleDateFormat(userLanguage) {
    let lang = moment().locale(userLanguage === "en" ? "en-gb" : userLanguage);
    let valueFormatter = lang.localeData()._longDateFormat.L;
    return valueFormatter;
}

export function addTasksAmount(tasks) {
    let amount = 0;
    let currency = "";

    tasks.forEach(task => {
        if (currency === "") {
            currency = task.currency;
        }
        if (amount !== null && task.currency && task.currency === currency) {
            amount += task.amount;
        } else {
            amount = null;
            return false;
        }
    });

    let respone = amount > 0 ? formatAmountValue(amount) + " " + currency : null;
    return respone;
}
