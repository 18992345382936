import * as _ from "lodash";

export const customStyles = {
    valueContainer: (provided) => {
        const padding = "0 8px";
        return {...provided, padding};
    },

   /* option: (provided) => ({
        ...provided,
        height: "30px",
        padding: "6px 12px"
    }),*/

    multiValue: (provided, {data}) => ({
        ...provided,
        backgroundColor: data.valid === false ? "#ffebf5" : "#ebf5ff",
        border: "1px solid lightgrey"
    }),

    multiValueRemove: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "lightgrey",
        '&:hover': {
            backgroundColor: "transparent",
            color: "darkgrey"
        },
        borderLeft: "1px solid lightgrey"
    }),

    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return {...provided, opacity, transition};
    },

  /*  menu: (provided) => ({
        ...provided,
        marginTop: "0px",
        border: "1px solid #2684FF",
        minWidth: "250px",
        maxWidth: "580px",
        position: "fixed",
        top: "inherit",
        zIndex:"2"
    }),
   /* menuList: (provided) => ({
        ...provided,
        //minHeight: "30px",
        //maxHeight: "200px",
        lineHeight: "20px",
        padding: "0"
    }),*/

    noOptionsMessage: (provided) => ({
        ...provided,
        color: "red",
        textAlign: "left"
    }),
};

let menuStyle = _.cloneDeep(customStyles);

menuStyle.menu = (provided) => {
    return {
        ...provided,
        marginTop: "0px",
        border: "1px solid #2684FF",
       // minWidth: "250px",
        //maxWidth: "500px"
    }
}

export let autoMenuStyle = menuStyle;

let advancedHistory = _.cloneDeep(customStyles);

advancedHistory.menu = (provided) => {
    return {
        ...provided,
        marginTop: "0px",
        border: "1px solid #2684FF",
        minWidth: "250px",
        minHeight: "30px",
        position: "absolute",
        top: "inherit",
        zIndex:"2"
    }
}

export let advancedHistoryStyle = advancedHistory;

let fixedStyle = _.cloneDeep(customStyles);

fixedStyle.menu = (provided) => {
    return {
        marginTop: "0px",
        border: "1px solid #2684FF",
        backgroundColor:"white",
        minWidth: "390px",
        maxWidth: "680px",
        position: "fixed",
        top: "inherit",
        zIndex:"2"
    }
}
fixedStyle.menuList= (provided) => ({
    ...provided,
    minHeight: "30px",
    maxHeight: "200px",
    lineHeight: "20px",
    padding: "0"
})

export let fixedMenuStyle = fixedStyle;
