import {
    PROJECTS_USERS_LOADED,
    PROJECTS_ROLES_LOADED,
    PROJECTS_USERS_ROLES_LOADING
} from '../store/actionTypes';

import * as Api from '../../utils/api/api';
import {handleError} from '../../utils/errorHandle.function';


export function projectsUsersLoading(value) {
    return {
        type: PROJECTS_USERS_ROLES_LOADING,
        data: value
    }
}

export function usersLoaded(value) {
    return {
        type: PROJECTS_USERS_LOADED,
        data: value
    }
}

export function rolesLoaded(value) {
    return {
        type: PROJECTS_ROLES_LOADED,
        data: value
    }
}


export function getProjectsUsersAndRoles(approvers) {
    return function (dispatch) {

        dispatch(projectsUsersLoading());

        const asPromises = approvers.map(approver => {
            return Api.getCompanyUser(approver).then(response => {
                return {value: response.idUser, label: response.fullName};
            });
        });

        Promise.all(asPromises).then(
            values => {
                dispatch(usersLoaded(values))
            }, error => {
                handleError(error);
            });

        Api.getRuleConfigurationRoles().then(response => {
            let mappedRoles = response ? response.map(role => {
                return {value: role.roleId, label: role.name};

            }) : [];

            dispatch(rolesLoaded(mappedRoles))
        }, error => {
            handleError(error);
        });
    }
}
