/*
 * remove the old user data that we used to store in the local storage
 * temporary: this should be removed for older version when we will not have any of this data in the localstorage
 */

export function cleanStorage() {

    if (!window.localStorage['persist:primary'])
        return;

    let storage = JSON.parse(window.localStorage['persist:primary']);
    if (!storage)
        return;

    if (storage.startup)
        delete storage.startup;

    if (storage.myTasks)
        delete storage.myTasks;

    if (storage.myHistory)
        delete storage.myHistory;

    if (storage.documentOverview)
        delete storage.documentOverview;

    if (storage.processOverview)
        delete storage.processOverview;

    window.localStorage['persist:primary'] = JSON.stringify(storage);

}