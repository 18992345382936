import { UserInputAreaProps } from "types/userArea";

export function UserInputArea(props: UserInputAreaProps) {

    return (
        <div className="w-100">
            <div className="mb-4 text-default">
                {props.message}
            </div>
            <br />
            <div className="my-2">
                <span className="text-error">*</span>
                <span className="text-default">{props.inputMessage}</span>
            </div>
            <input
                id="workflows_user_comment_input"
                data-testid="workflows_user_comment_input"
                ref={props.userInput}
                defaultValue={props.defaultValue}
                onFocus={(e) => {
                    e.target.select();
                }}
                className="d-flex w-100 py-2 px-3 my-2" />
            <div className="text-secondary font-13">
                {props.moreInfo}
            </div>
        </div>

    );
}
export default UserInputArea;