import React from 'react';
import PropTypes from 'prop-types';

const WorkflowMap = ({url, coordinates}) => {
    let workflowMap = coordinates ? coordinates.map((map, index) => {
        let explanation = "";
        if (map.explanations) {
            explanation = map.explanations.map(exp => {
                return explanation += exp +
                    "";
            });
        }
        if (map.text) {
            explanation += map.text;
        }

        return (<area shape="rect" href="#" className="cursor-pointer" key={index} coords={map.coord} title={explanation} alt=""/>)
    }) : [];

    return (
        <div className="workflow-map m-5">
            <map name="workflowmap">
                {workflowMap}
            </map>
            <img className="no-border margin-top-bottom margin-left-right"
                 useMap="#workflowmap"
                 role="presentation"
                 src={"data:image/png;base64," + url}
                 alt={"workflowmap"}/>
        </div>
    );
};
WorkflowMap.propTypes = {
    url: PropTypes.string, // workflow image url
    coordinates: PropTypes.array, //tooltip coordinates position

};

export default WorkflowMap;

