import React from 'react';
import {handleError} from "utils/errorHandle.function";
import Confirm from "../popup/ConfirmActionPopup.component";
import translate from "../translations/translations.wrapper.jsx";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import * as _ from 'lodash';
import SettingsButtonsBarView from "components/settings/SettingsButtonsBarView.component";
import {getCostUnitTypesVersion} from "components/store/application.reducers";
import {connect} from "react-redux";

export class CostUnitTypes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSavePopup: false,
            warningMessage: '',
            saveDisabled: false,
            costUnitModified: false
        };

        this.confirmSave = this.confirmSave.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.hideSavePopup = this.hideSavePopup.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.selectCostUnitItem = this.selectCostUnitItem.bind(this);
        this.unselectCostUnitItem = this.unselectCostUnitItem.bind(this);
    }

    hideSavePopup() {
        this.setState({showSavePopup: false})
    }

    onClickSave() {
        const data = {
            costUnits: this.props.selectedCostUnitTypes,
            confirm: false
        };
        if (this.state.saveDisabled) {
            return;
        }
        this.setState({saveDisabled: true});
        this.props.setCostUnitsData(data, this.props.costUnitTypesVersion).then(() => {
                this.setState({
                    saveDisabled: false
                });
                this.props.costUnitTypesSaved();
                this.props.getCostUnitsData();
            },
            (error) => {
                this.setState({
                    showSavePopup: true,
                    warningMessage: error.errorMessages,
                    saveDisabled: false
                });
            });
    }

    confirmSave() {
        const data = {
            costUnits: this.props.selectedCostUnitTypes,
            confirm: true
        };

        this.props.setCostUnitsData(data, this.props.costUnitTypesVersion).then(() => {
            this.props.costUnitTypesSaved();
            this.props.getCostUnitsData();
            this.hideSavePopup();
        }, error => {
            handleError(error);
            this.hideSavePopup();
        });
    }

    renderCurrentlySelectedPanel() {
        return (
            this.props.selectedCostUnitTypes.length === 0 ?
                <p>{this.props.translate("costUnitTypes.noItemSelected")}</p> :
                this.props.selectedCostUnitTypes.map((item, index) => {
                    return index === this.props.selectedCostUnitTypes.length - 1 && index !== 0 ? null :
                        <div key={item.index}>
                            {index === 0 ?
                                <p className="mb-2">{this.props.translate("costUnitTypes.firstItemSelected") + item.name + ' (' + item.dimension + ')'}</p> : null}
                            {this.props.selectedCostUnitTypes.length > 1 ?
                                <p className="mb-2">{item.name + ' (' + item.dimension + ')' + this.props.translate("costUnitTypes.currentSelection") + this.props.selectedCostUnitTypes[index + 1].name + ' (' + this.props.selectedCostUnitTypes[index + 1].dimension + ')'}</p> : null}
                        </div>;
                })
        );
    }

    selectCostUnitItem(item) {
        this.props.costUnitTypeSelected(item);
    }

    unselectCostUnitItem(item) {
        this.props.costUnitTypeUnselected(item);
    }

    onSortEnd(event) {
        this.props.costUnitTypeReordered({
            oldIndex: event.oldIndex,
            newIndex: event.newIndex
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.selectedCostUnitTypes, this.props.selectedCostUnitTypes) ||
            !_.isEqual(prevProps.initialSelectedCostUnitTypes, this.props.initialSelectedCostUnitTypes)) {
            this.setState({
                costUnitModified: !_.isEqual(this.props.selectedCostUnitTypes, this.props.initialSelectedCostUnitTypes),
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.showSavePopup &&
                    <Confirm translate={this.props.translate}
                             message={this.state.warningMessage}
                             handleAction={this.confirmSave}
                             closeCallback={this.hideSavePopup}
                    />}
                <h3 className="no-border d-flex align-items-center pt-4">{this.props.translate("costUnitTypes.header")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                          title={this.props.translate("costUnitTypes.headerInfo")}/>
                </h3>
                <div className="row" id="cost-unit-types">
                    <div className="col-md-6">
                        <CostUnitList
                            translate={this.props.translate}
                            costUnitTypes={this.props.costUnitTypes}
                            selectedCostUnitTypes={this.props.selectedCostUnitTypes}
                            onClick={this.selectCostUnitItem}
                            placement='left'
                            type='available'
                            sortable={false}
                            helperClass={'list-group-item-sortable list-group-item-sortable-left'}
                            lockAxis={'y'}
                            useDragHandle
                            systemViewerOnly={this.props.systemViewerOnly}
                        />
                    </div>
                    <div className="col-md-6">
                        <CostUnitList
                            translate={this.props.translate}
                            costUnitTypes={this.props.selectedCostUnitTypes}
                            selectedCostUnitTypes={this.props.costUnitTypes}
                            onClick={this.unselectCostUnitItem}
                            placement='right'
                            type='selected'
                            onSortEnd={this.onSortEnd}
                            sortable={!this.props.systemViewerOnly}
                            distance={1}
                            helperClass={'list-group-item-sortable list-group-item-sortable-right'}
                            lockAxis={'y'}
                            useDragHandle
                            systemViewerOnly={this.props.systemViewerOnly}
                        />
                    </div>
                </div>
                <div className="row" id="cost-unit-types-panel">
                    <div className="col-md-6 offset-md-6">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <span className="group-title">
                                    {this.props.translate("costUnitTypes.panelHeader")}
                                </span>
                            </div>
                            <div className="panel-body">
                                {this.renderCurrentlySelectedPanel()}
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.systemViewerOnly ? null :
                    <SettingsButtonsBarView
                        containerInnerStyling={"container"}
                        okCallback={this.onClickSave}
                        cancelEnabled={this.state.costUnitModified}
                        okEnabled={this.state.costUnitModified}
                        cancelCallback={this.props.getCostUnitsData}/>
                }
            </div>
        );
    }
}

const mapStateToProps = function (store) {
    return {
        costUnitTypesVersion: getCostUnitTypesVersion(store)
    }
}
const withTranslations = translate(CostUnitTypes);
const connected = connect(mapStateToProps, {})(withTranslations);

export default connected;


const CostUnitListItemTitle = SortableHandle((props) =>
    <div className="list-group-item-title">{props.item.name} ({props.item.dimension})</div>
);

const CostUnitListItem = SortableElement((props) =>
    <a className={`list-group-item ${props.item.deleted ? 'deleted' : ''}`}
       onClick={props.systemViewerOnly ? null : props.onClick.bind(null, props.item)}>
        <CostUnitListItemTitle item={props.item}/>
        {props.systemViewerOnly ? null :
            <span className={`vismaicon vismaicon-arrow-${props.placement === 'left' ? 'right' : 'left'}-circle`}
                  aria-hidden="true"></span>
        }
    </a>
);

const CostUnitList = SortableContainer((props) => {
    return (
        <div className={`list-group ${props.placement}`}>
            <div
                className="list-group-item group-title">{props.type === 'available' ? props.translate("costUnitTypes.available") : props.translate("costUnitTypes.selected")}</div>
            {props.costUnitTypes.map((item, index) => {
                return (
                    <CostUnitListItem key={`${item.name}-${item.dimension}`}
                                      disabled={!props.sortable}
                                      onClick={props.onClick}
                                      index={index}
                                      item={item}
                                      placement={props.placement}
                                      systemViewerOnly={props.systemViewerOnly}/>
                );
            })}
            {props.selectedCostUnitTypes.length > props.costUnitTypes.length ?
                [...Array(props.selectedCostUnitTypes.length - props.costUnitTypes.length)].map((item, index) =>
                    <div className="list-group-item ghost-item" key={"ghost-item_" + index}></div>
                ) :
                null}
        </div>
    );
});





