import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import * as Api from 'utils/api/api.js';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import Comment from '../input/Comment.component';
import UserSearch from '../input/UserSearch.component'
import {TASK_ACTIONS} from 'utils/constants';
import ErrorDialog from './ErrorDialog.component';
import translate from '../translations/translations.wrapper.jsx';
import HandleTask from "components/popup/HandleTask.js";


//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

export class SendForward extends PopUp {
    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.state = {
            comment: '',
            recipient: null,
            actionRunning: false,
            displayError: null,
            refresh: null,
            isHandled: false
        };

        this._handle = this._handle.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    focusFirst() {
        this.setState({
            refresh: true
        });
    }

    ok() {
        const self = this;
        if (this.state.comment && this.state.recipient && this.state.recipient.value) {
            this.setState({actionRunning: true});

            const key = this.props.tasks.map(task => task.key);
            Api.OverviewReassign(key, this.state.comment, this.state.recipient.value).then(function (response) {
                    self.props.showNotification(response.message);
                    self._handle();
                    self.props.closeCallback();
                },
                function (error) {
                    self.setState({displayError: error});
                });
        }
    }
    _handle() {
        this.setState({
            isHandled: true
        });
    }

    buildContent() {

        const okEnabled = this.state.comment && this.state.recipient && !this.state.actionRunning;
        const keys = this.props.tasks.map(task => task.key);
        return (
            <Fragment>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({actionRunning: false, displayError: null})}
                    translate={this.props.translate}
                />}
                {this.state.isHandled &&
                    <HandleTask key={this.props.tasks.map(task => task.key)}/>
                }
                <div className="modal-body ast_send_reassign">
                    <div><span className="text-danger">*</span> {this.props.translate("popUp.forward.recipient")}</div>
                    <UserSearch ref="recipientInput"
                                tabindex={TAB_INDEX}
                                taskId={keys}
                                translate={this.props.translate}
                                refresh={this.state.refresh}
                                propagateValue={ value => this.setState({recipient: value})}
                                placeholder={this.props.translate("userSearch.placeholder")}
                                value={this.state.recipient}
                                includeSameUser={true}
                                releaseFocus={()=>{this.setState({refresh:false})}}
                    />
                    <div className="mt-3">
                        <label htmlFor="comment"><span
                            className="text-danger">* </span> {this.props.translate("popUp.forward.commentLabel")}
                        </label>

                        <VisibleToEverybody translate={this.props.translate}/>

                        <Comment id="comment"
                                 inputHint={this.props.translate("popUp.forward.commentHint")}
                                 translate={this.props.translate}
                                 tabIndex={TAB_INDEX+1}
                                 propagateValue={value => this.setState({comment: value,refresh:false})}
                                 value={this.state.comment}
                        />
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.ok}
                                tabIndex={TAB_INDEX+2}
                                disabled={!okEnabled}
                        >{this.props.translate("popUp.ok")}</button>
                        <button className="btn btn-default"
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX+3}
                                onBlur={this.focusFirst}
                        >{this.props.translate("popUp.cancel")}</button>
                    </div>
                </div>
            </Fragment>

        );
    }

    getType() {
        return TASK_ACTIONS.REASSIGN;
    }

}

SendForward.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
    showNotification: PropTypes.func, // to show transient notification

};
const withTranslations = translate(SendForward);
export default withTranslations;

