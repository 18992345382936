import AttachmentsPreview from "./AttachmentsPreview.component";
import BypassCIRContainer from "./BypassCIRContainer.component";
import './attachments.scss';
import {launchDarkly} from 'utils/launchDarkly';
import {AttachmentsPanelProps} from "types/attachment";
import { useSelector } from "react-redux";
import { isDocumentPreviewDetached } from "components/store/application.reducers";

const AttachmentsPanel = (props: AttachmentsPanelProps) => {
    const bypassCIR: null | boolean = launchDarkly.userClient && launchDarkly.userClient.variation("bypass-cir", false) as boolean;
    const isPreviewDetached = useSelector(isDocumentPreviewDetached);

    return (
        <div
            className={`${bypassCIR ? "p-0 attachments-preview-modal" : ""} grid-attachments-preview ${isPreviewDetached ? "detached-height" : ""}`}
            style={{width: props.width + "px"}}
            data-cy="taskDetail.attachmentsPanel">
            {bypassCIR ?
                <BypassCIRContainer data-testid={"bypass-cir-container"}
                                    isAttachmentPanelWidthDefault={props.isAttachmentPanelWidthDefault}
                                    isAttachmentPanelMaxWidth={props.isAttachmentPanelMaxWidth}
                                    maximizePanel={props.maximizePanel}
                                    minimizePanel={props.minimizePanel}
                                    ownerDocument={props.ownerDocument}/>
                :
                <AttachmentsPreview
                    attachments={props.attachments}
                    loadingStatus={props.loadingStatus}
                    taskDetails={props.taskDetails}
                    translate={props.translate}
                    isAttachmentPanelWidthDefault={props.isAttachmentPanelWidthDefault}
                    isAttachmentPanelMaxWidth={props.isAttachmentPanelMaxWidth}
                    maximizePanel={props.maximizePanel}
                    minimizePanel={props.minimizePanel}
                />
            }
        </div>
    );
}

export default AttachmentsPanel;