import React, {Component} from 'react';
import {EditDeleteButtons, SaveCancelButtons} from 'utils/listActionButtons.component';
import ProjectsApprovers from "./ProjectsApprovers.component";
import {EditableDimension} from './ProjectsEditables.component';

import * as _ from "lodash";

export class ProjectsRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: props.editMode ? props.editMode : false,
            newLineValues: null,
            saveEnabled: false,
            saveTooltop: ""
        };

        this.changeLineDimension = this.changeLineDimension.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.saveRowChanges = this.saveRowChanges.bind(this);
        this.saveIdRole = this.saveIdRole.bind(this);
        this.saveApproverEmail = this.saveApproverEmail.bind(this);
    }

    componentDidMount() {
        if (this.props.isNewRow) {
            this.checkSaveStatus();
        }
    }

    changeLineDimension(value, index) {
        let newLine = this.state.newLineValues ? _.cloneDeep(this.state.newLineValues) : _.cloneDeep(this.props.line);
        let dimension = {
            mdmGuid: value.value,
            name: value.label
        };
        newLine.dimensions[index] = dimension;
        this.setState({
            newLineValues: newLine
        });
        this.checkSaveStatus(newLine);
    }


    deleteRow() {
        this.props.deleteRow(this.props.line.id);
    }

    saveRowChanges() {
        this.props.saveRow(this.state.newLineValues);
        this.exitEditMode();
    }

    enterEditMode() {
        this.setState({
            editMode: true
        });
        this.props.toggleEditMode(true);
        this.checkSaveStatus();
    }

    exitEditMode() {
        this.setState({
            editMode: false,
            newLineValues: null
        });
        this.props.toggleEditMode(false);
    }

    checkSaveStatus(line) {
        let newLine = line ? line : _.cloneDeep(this.props.line);
        let tooltipString = this.props.checkLine(newLine);
        this.setState({
            saveTooltop: tooltipString,
            saveEnabled: !tooltipString
        });
    }

    saveIdRole(value) {
        let newLine = this.state.newLineValues ? _.cloneDeep(this.state.newLineValues) : _.cloneDeep(this.props.line);
        newLine.idRole = value.value;
        this.setState({
            newLineValues: newLine
        });
        this.checkSaveStatus(newLine);
    }

    saveApproverEmail(value) {
        let newLine = this.state.newLineValues ? _.cloneDeep(this.state.newLineValues) : _.cloneDeep(this.props.line);

        newLine.approvers = (Array.isArray(value) ? value : []).map(value => {
            return value.value
        });
        this.setState({
            newLineValues: newLine
        });
        this.checkSaveStatus(newLine);
    }

    render() {
        let {line} = this.props;
        return (
            <div className="row">

                <Dimensions dimensions={line.dimensions}
                            editMode={this.state.editMode}
                            changeDimensionValue={this.changeLineDimension}
                            possibleValues={this.props.possibleValues}
                            translate={this.props.translate}
                            width={this.props.width}
                />
                <ProjectsApprovers approvers={line.approvers} idRole={line.idRole}
                               editMode={this.state.editMode}
                               saveIdRole={this.saveIdRole}
                               saveApproverEmail={this.saveApproverEmail}
                               translate={this.props.translate}/>

                <div className="no-left-padding text-right col col-action-buttons">
                    {this.state.editMode ?
                        <SaveCancelButtons
                            saveCallback={this.saveRowChanges}
                            cancelCallback={this.props.isNewRow ? this.props.cancelNewRow : this.exitEditMode}
                            translate={this.props.translate}
                            saveDisabled={!this.state.saveEnabled}
                            saveTooltop={this.state.saveTooltop}
                        />
                        : <EditDeleteButtons
                            disabled={this.props.editMode || this.props.readOnly}
                            editCallback={this.enterEditMode}
                            deleteCallback={this.deleteRow}
                            translate={this.props.translate}
                        />}
                </div>
            </div>
        );
    }
}

const Dimensions = (props) => {

    let dimStyle = props.dimensions.length > 4 ? {width: "200px"} : {width: (Math.abs(props.width / props.dimensions.length) + "px")};

    return props.dimensions.map((value, index) => {
        let selectedValue = value.name ? {label: value.name, value: value.mdmGuid} : {
            label: props.translate("projects.any"),
            value: null
        };

        if (props.editMode) {
            return (
                <div className="col" style={dimStyle} key={index}>
                    <EditableDimension translate={props.translate}
                                       value={selectedValue}
                                       index={index}
                                       changeValue={props.changeDimensionValue}
                                       options={props.possibleValues[index]}
                    />
                </div>);
        }
        else {
            return (<div className="col" style={dimStyle} key={index}>{selectedValue.label}</div>);
        }
    })
};
