import {APP_START, LOGGED_OUT_TYPE, SAVE_VERSION} from '../store/actionTypes';
import {REHYDRATE} from 'redux-persist'

export const DEFAULT_STATE = {
    initialized: false,
    hydrated: false,
    version: ''
};
/**
 * store.initialized: bool
 */
export default function appInitializationReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {
        case APP_START:
            partialState = {
                initialized: true
            };
            return Object.assign({}, state, partialState);

        case SAVE_VERSION:
            partialState = {
                version: action.data
            };
            return Object.assign({}, state, partialState);

        case REHYDRATE:
            partialState = {
                hydrated: true
            };
            return Object.assign({}, state, partialState);

        case LOGGED_OUT_TYPE:
            return DEFAULT_STATE;

        default:
            return state;
    }
}

export function getVersion(state) {
    return state.version;
}
export function getHydrated(state) {
    return state.hydrated;
}
export function getInitialized(state) {
    return state.initialized;
}