import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {snowplowTrackEvent} from "utils/snowplow";
import * as reduxSelectors from "../store/application.reducers";
import {
    clearWorkflowsHistory,
    copyRuleWithName, deleteRule,
    getRuleHistory,
} from "components/workflows/workflows.action";
import translate from "components/translations/translations.wrapper";
import WorkflowHistoryPopUp from "components/popup/WorkflowHistoryPopUp.component";
import Confirm from "components/popup/ConfirmActionPopup.component";
import {WorkflowActionsDropdownProps} from "types/workflowActionsDropdown";
import UserInputArea from "components/workflows/UserInputArea.component";
import UserCommentArea from "components/workflows/UserCommentArea.component";
import {useNavigate} from "react-router-dom";
import {PATH_TO_WORKFLOW_DETAILS} from "components/router/paths";

function WorkflowsActionsDropdownComponent(props: WorkflowActionsDropdownProps) {
    const dispatch = useDispatch();
    const userConfiguration = useSelector(reduxSelectors.getUsersData);
    const deleteUserInputRef = useRef(null);
    const copyUserInputRef = useRef(null);
    const [showWorkflowHistory, setShowWorkflowHistory] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmCopy, setShowConfirmCopy] = useState(false);
    const navigate = useNavigate();

    const navigateToWorkflowDetails = (idRuleConfiguration: number) => {
        navigate(PATH_TO_WORKFLOW_DETAILS + idRuleConfiguration);
    }

    const onCopyRule = function () {
        const newRuleName = copyUserInputRef.current ? (copyUserInputRef.current as HTMLTextAreaElement).value : "";
        let newRule = Object.assign({}, props.rule);
        newRule.name = newRuleName;
        dispatch(copyRuleWithName(newRule, navigateToWorkflowDetails, props.copyCallback));
        setShowConfirmCopy(false);
    };

    const onDeleteRule = function () {
        const userComment = deleteUserInputRef.current ? (deleteUserInputRef.current as HTMLTextAreaElement).value : "";
        dispatch(deleteRule(props.rule.idRuleConfiguration, userComment, props.deleteCallback));
        setShowConfirmDelete(false);
    };

    const onShowRuleHistory = function () {
        snowplowTrackEvent({
            category: "audit trail",
            action: "open_workflow_history",
            label: "rule level workflow history",
            value: props.rule.idRuleConfiguration + "",
        }, userConfiguration);
        setShowWorkflowHistory(true);
        dispatch(getRuleHistory(props.rule.idRuleConfiguration));
    };

    const onHideRuleHistory = function () {
        setShowWorkflowHistory(false);
        dispatch(clearWorkflowsHistory());
    }


    return (
        <div className="workflow-actions">
            {showWorkflowHistory &&
                <WorkflowHistoryPopUp
                    // @ts-ignore
                    closeCallback={onHideRuleHistory}
                    ruleId={props.rule.idRuleConfiguration}
                    translate={props.translate}/>
            }
            {showConfirmDelete &&
                <Confirm translate={props.translate}
                         handleAction={onDeleteRule}
                         closeCallback={() => {
                             setShowConfirmDelete(false);
                         }}
                         modalType={"modal-warning"}
                         confirmButtonColor={"btn-default"}
                         confirmButtonText={props.translate("workflowDetails.confirmDialog.deleteWorkflow")}
                         cancelButtonText={props.translate("workflowDetails.confirmDialog.dontDelete")}
                         title={props.translate("workflowDetails.confirmDialog.deleteTitle", props.rule.name)}
                         moreContent={<UserCommentArea
                             message={props.rule.active ? props.translate("workflowDetails.confirmDialog.moreDeleteInfo")
                                 : props.translate("workflowDetails.confirmDialog.moreDeleteInfoUndone")
                             }
                             inputMessage={props.translate("workflowDetails.confirmDialog.deleteInputMessage")}
                             moreInfo={props.translate("workflowDetails.confirmDialog.moreInfo")}
                             userInput={deleteUserInputRef}
                         />}
                >
                </Confirm>
            }
            {showConfirmCopy &&
                <Confirm translate={props.translate}
                         handleAction={onCopyRule}
                         closeCallback={() => {
                             setShowConfirmCopy(false);
                         }}
                         modalType={"modal-info"}
                         confirmButtonText={props.translate("workflowDetails.confirmDialog.copyWorkflow")}
                         cancelButtonText={props.translate("workflowDetails.confirmDialog.cancel")}
                         title={props.translate("workflowDetails.confirmDialog.copyTitle", props.rule.name)}
                         moreContent={<UserInputArea
                             message={props.translate("workflowDetails.confirmDialog.copyMessage")}
                             inputMessage={props.translate("workflowDetails.confirmDialog.copyInputMessage")}
                             moreInfo={props.translate("workflowDetails.confirmDialog.copyMoreInfo")}
                             defaultValue={props.rule.name + " (1)"}
                             userInput={copyUserInputRef}
                         />}
                >
                </Confirm>
            }
            <Dropdown>
                <Dropdown.Toggle as={props.customContainer}
                                 childBsPrefix={"btn btn-default dropdown-toggle"}>
                    {props.translate("workflows.moreActions")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item eventKey="1"
                                   onClick={() => setShowConfirmCopy(true)}>
                        <span className="vismaicon vismaicon-sm vismaicon-copy vismaicon-dynamic"/>
                        {props.translate("workflows.duplicate")}
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2"
                                   onClick={onShowRuleHistory}>
                        <span className="vismaicon vismaicon-sm vismaicon-history vismaicon-dynamic"/>
                        {props.translate("workflows.workflowHistoryBtn")}
                    </Dropdown.Item>
                    <Dropdown.Divider className="divider"></Dropdown.Divider>
                    <Dropdown.Item eventKey="3"
                                   onClick={() => setShowConfirmDelete(true)}
                                   disabled={props.rule && props.rule.readonly}>
                        <span className="vismaicon vismaicon-sm vismaicon-delete vismaicon-dynamic"/>
                        {props.translate("workflows.delete")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

const withTranslations = translate(WorkflowsActionsDropdownComponent);
export default withTranslations;