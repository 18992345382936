import React, {Fragment} from "react";
import {DOCUMENT_TYPE} from "utils/constants";
import * as _ from "lodash";
import { formatDocumentType } from "utils/valueFormatter.function";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import {
    activateReadonlyRule,
    deactivateReadonlyRule,
    getCostUnits,
    getOrganizationChart,
    getRuleConfigurationRoles,
    removeActiveWorkflow,
    removeStep,
    renameRule,
    saveRule,
    set4EyesEnabled,
    set4EyesFallbackUsers,
    setActiveWorkflow,
    setStepChange,
    setStepSettings,
    sortSteps,
} from "./workflowdetails.action";
import Confirm from "../popup/ConfirmActionPopup.component";
import SettingsButtonsBarView from "../settings/SettingsButtonsBarView.component";
import "./WorkflowDetails.scss";
import * as reduxSelectors from "../store/application.reducers";
import { connect } from "react-redux";
import translate from "../translations/translations.wrapper.jsx";
import Step from "./Step.component";
import ConfigurationWrapper from "../configuration/ConfigurationWrapper.component";
import { navigateToWorkflows } from "../router/router.function";
import Simulator from "./simulator/Simulator.component";
import ApprovalStep4Eyes from "./4eyesApprovalStep.component";
import { UserCommentArea } from "../workflows/Workflows.component";
import WorkflowsActionsDropdownComponent from "components/workflows/WorkflowsActionsDropdown.component";
import ActivateWorkflowPopup from "components/popup/ActivateWorkflowPopup.component";
import {PATH_TO_WORKFLOWS} from "components/router/paths.js";


export class WorkflowDetails extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            showRules: true,
            okEnabled: false,
            approval4EyesChanged: false,
            isEditing: null,
            addingNew: false,
            disable4EyesPopupVisible: false,
            showConfirmSave: false,
            showActivate: false,
        };

        this.setActiveWorkflow = this.setActiveWorkflow.bind(this);
        this.swapSteps = this.swapSteps.bind(this);
        this.activateRule = this.activateRule.bind(this);
        this.confirmActivateReadonlyRule = this.confirmActivateReadonlyRule.bind(this);
        this.changeEditDuringInitialWorkflow = this.changeEditDuringInitialWorkflow.bind(this);
        this.renameRule = this.renameRule.bind(this);
        this.saveRule = this.saveRule.bind(this);
        this.saveStepSettings = this.saveStepSettings.bind(this);
        this.getData = this.getData.bind(this);
        this.changeStepName = this.changeStepName.bind(this);
        this.editStep = this.editStep.bind(this);
        this.insertNewStep = this.insertNewStep.bind(this);
        this.on4EyesChange = this.on4EyesChange.bind(this);
        this.on4EyesToggle = this.on4EyesToggle.bind(this);
        this.show4EyesDisablePopup = this.show4EyesDisablePopup.bind(this);
        this.hide4EyesDisablePopup = this.hide4EyesDisablePopup.bind(this);
        this.confirm4EyesDisablePopup = this.confirm4EyesDisablePopup.bind(this);
        this.confirmSave = this.confirmSave.bind(this);
        this.isActiveWorkflowReadonly = this.isActiveWorkflowReadonly.bind(this);
        this.isBackNavigationAllowed = this.isBackNavigationAllowed.bind(this);
        this.getReadonlyStyleProperties = this.getReadonlyStyleProperties.bind(this);
        this.moreActionsCallback = this.moreActionsCallback.bind(this);

        this.userInput = React.createRef();
        this.activateUserInputRef = React.createRef();
    }

    activateRule() {
        if (this.isActiveWorkflowReadonly()) {
            this.setState({
                showActivate: true,
            });
        } else if (this.state.showRules) {
            let newRule = Object.assign({}, this.props.activeWorkflow);
            newRule.active = !newRule.active;

            this.props.setStepChange(newRule);
        }
    }

    confirmActivateReadonlyRule() {
        this.props.activeWorkflow.active ? this.props.deactivateReadonlyRule(this.props.activeWorkflow) :
            this.props.activateReadonlyRule(this.props.activeWorkflow);
        this.state.showActivate = false;
    }

    insertNewStep() {
        let newRule = Object.assign({}, this.props.activeWorkflow);

        const lastNumber = newRule.steps.length === 0 ? 0 : Math.max(...newRule.steps.map((step) => step.stepNumber));
        // New step ids should be negative so that backend can replace them by generated ids
        const newStepId = (newRule.steps.length === 0 ? 0 : -Math.max(...newRule.steps.map((step) => Math.abs(step.idStep)))) - 1;

        newRule.steps.push({
            active: true,
            optional: false,
            stepNumber: lastNumber + 1,
            recordVersion: 0,
            name: "New Step",
            resolveOnLines: false,
            allowEdit: true,
            reapproveType: "ALWAYS",
            condition: undefined,
            conditionVersion: 0,
            idStep: newStepId,
            rules: [
                {
                    action: null,
                    idStep: newStepId,
                    idRule: 1,
                    name: "NewRule",
                    ruleNumber: 1,
                    userMap: {},
                },
            ],
            dependencies: undefined,
            approvers4Eyes: [],
        });

        this.props.setStepChange(newRule);
        this.setState({
            addingNew: true,
            isEditing: newRule.steps.length - 1,
        });
    }

    changeEditDuringInitialWorkflow() {
        let newRule = Object.assign({}, this.props.activeWorkflow);
        newRule.editDuringInitialWorkflow = !newRule.editDuringInitialWorkflow;

        this.props.setStepChange(newRule);
    }

    saveStepSettings(step, isComplete) {
        this.props.setStepSettings(step, isComplete);
    }

    changeStepName(stepIndex, value) {
        let newRule = Object.assign({}, this.props.activeWorkflow);

        newRule.steps[stepIndex].name = value;

        this.props.setStepChange(newRule);
    }

    setActiveWorkflow() {
        let activeWorkflow = _.find(this.props.workflows, (workflow => {
                return workflow.idRuleConfiguration.toString() === this.props.match.params.workflowId;
            }
        ));
        if (activeWorkflow) {
            this.props.setActiveWorkflow(activeWorkflow);
        } else {
            setTimeout(() => {
                this.props.blocker?.history(PATH_TO_WORKFLOWS);
            }, 100);
        }
    }

    renameRule(event) {
        // the workflow name has to be between 1 and 80 characters
        let newName = event.target.value;
        this.props.renameRule(newName);
    }

    editStep(number, editing) {
        if (this.props.activeWorkflow && this.props.activeWorkflow.steps[number]) {
            this.setState({
                isEditing: editing ? number : undefined,
            });
        }
    }

    confirmSave() {
        this.setState({
            showConfirmSave: true,
        });
    }

    saveRule() {
        this.props.activeWorkflow.steps.forEach((step, index) => {
            step.stepNumber = index;
        });

        let userComment = this.userInput.current ? this.userInput.current.value || "" : null;
        this.props.saveRule(this.props.activeWorkflow, userComment);

        this.setState({
            isEditing: null,
            addingNew: false,
            approvers4EyesChanged: false,
            showConfirmSave: false,
        });
    }

    isActiveWorkflowReadonly() {
        return this.props.activeWorkflow && this.props.activeWorkflow.readonly;
    }

    isBackNavigationAllowed() {
        return !this.state.okEnabled || this.isActiveWorkflowReadonly();
    }

    getReadonlyStyleProperties() {
        return this.isActiveWorkflowReadonly() ? "workflow-details-readonly disabled" : "";
    }

    componentDidMount(props) {
        super.componentDidMount(props);
    }

    componentWillUnmount() {
        this.props.removeActiveWorkflow();
    }

    componentDidUpdate(prevProps) {
        // this.props.workflowChanged && !approverListEmpty || this.state.approvers4EyesChanged

        if (this.props.workflowChanged || this.props.workflowChanged !== prevProps.workflowChanged) {
            let approverListEmpty = false;
            if (this.props.activeWorkflow?.steps?.length > 0) {
                this.props.activeWorkflow.steps.forEach((step) => {
                    if (step.rules) {
                        step.rules.forEach((rule) => {
                            if (!rule.action || (rule.action.users && rule.action.users.length === 0)) {
                                approverListEmpty = true;
                            }
                        });
                    }
                });
            } else {
                approverListEmpty = true;
            }

            const okEnabled = this.props.workflowChanged && !approverListEmpty;
            if (okEnabled !== this.state.okEnabled) {
                this.setState({
                    okEnabled,
                });
                this.props.blocker.shouldWaitForConfirmation(okEnabled);
            }
        }
    }

    getData() {
        this.setActiveWorkflow();
        //load here everything needed to map the values of the steps e.g. users, roles
        this.props.getRuleConfigurationRoles();
        this.props.getCostUnits();
        this.props.getOrganizationChart();
    }

    swapSteps({ oldIndex, newIndex }) {
        let workflow = _.cloneDeep(this.props.activeWorkflow);
        let steps = workflow.steps;

        steps.splice(newIndex, 0, steps.splice(oldIndex, 1)[0]);

        steps.forEach((step, index) => {
            step.stepNumber = index;
        });

        workflow.steps = steps;
        this.props.setStepChange(workflow);
    }

    on4EyesToggle(event) {
        if (!event.target.checked) {
            this.show4EyesDisablePopup();
        } else {
            this.props.set4EyesEnabled(event.target.checked);
        }
    }

    on4EyesChange(value) {
        this.props.set4EyesFallbackUsers(value);
    }

    show4EyesDisablePopup() {
        this.setState({
            disable4EyesPopupVisible: true,
        });
    }

    hide4EyesDisablePopup() {
        this.setState({
            disable4EyesPopupVisible: false,
        });
    }

    confirm4EyesDisablePopup() {
        this.props.set4EyesEnabled(false);
        this.hide4EyesDisablePopup();
    }

    moreActionsCallback() {
        this.setState({move: true}, () => {
            this.props.blocker.history(PATH_TO_WORKFLOWS);
        });
    }

    buildContent() {
        let { activeWorkflow } = this.props;
        let valid4EyesApproval = true;
        const twoPersonsRule = (this.props.activeWorkflow && this.props.activeWorkflow.twoPersonsRule) || {};
        if (twoPersonsRule.enabled) {
            const fallbackUsers = this.props.activeWorkflow.twoPersonsRule.fallbackUsers || [];
            valid4EyesApproval = fallbackUsers.length >= 2 && fallbackUsers.length <= 10;
        }


        return (
            <div id="Workflow-details">
                {activeWorkflow &&
                    <div>
                        {this.state.showActivate &&
                            <ActivateWorkflowPopup onActivate={this.confirmActivateReadonlyRule}
                                                   onCancel={() => this.setState({
                                                       showActivate: false,
                                                   })}
                                                   activate={!activeWorkflow.active}
                                                   ruleName={activeWorkflow.name}
                                                   documentType={activeWorkflow.documenttype}
                                                   activateUserInputRef={this.activateUserInputRef}
                            ></ActivateWorkflowPopup>}
                        {this.state.showConfirmSave &&
                            <Confirm translate={this.props.translate}
                                     handleAction={this.saveRule}
                                     closeCallback={() => {
                                         this.setState({ showConfirmSave: false });
                                     }}
                                     confirmIcon={"vismaicon-info"}
                                     confirmButtonText={this.props.translate("workflowDetails.confirmDialog.updateWorkflow")}
                                     title={this.props.translate("workflowDetails.confirmDialog.title", activeWorkflow.name)}
                                     moreContent={<UserCommentArea translate={this.props.translate}
                                                                   message={this.props.translate("workflowDetails.confirmDialog.info", this.props.translate("documentTypes." + DOCUMENT_TYPE.asString(activeWorkflow.documenttype)))}
                                                                   userInput={this.userInput} />}
                            >

                            </Confirm>
                        }

                        <div
                            className="title-area workflows-container">
                            <div
                                className="row px-40 workflows-center-align">
                                <span
                                    className="col-md-1 d-flex align-items-center justify-content-start mx-0 px-0 appicon vismaicon-arrow-left"
                                    onClick={this.isBackNavigationAllowed() ? () => this.props.blocker.history(PATH_TO_WORKFLOWS) : undefined}
                                    title={this.useWorkflowConfigurationImprovements ? this.props.translate("workflowDetails.backToWorkflows") : ""}/>
                                <div
                                    className={`col-md-5 d-flex align-items-center px-0 ${this.getReadonlyStyleProperties()}`}>
                                    <input type="text"
                                           value={activeWorkflow.name}
                                           onChange={this.renameRule}/>
                                </div>
                                <div className="d-flex col-md-6 justify-content-end px-0">
                                    <div className="d-flex align-items-center"
                                         id="workflow-header-slider">
                                        <label className="switch switch-label m-0">
                                            <input type="checkbox"
                                                   checked={activeWorkflow.active} onChange={this.activateRule}/>
                                            <span
                                                className={"togglemark " + (activeWorkflow.active ? "" : " disabled")}/>
                                        </label>
                                        <span className="margin-left-8">
                                        {this.props.translate("workflowDetails.active")}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="workflows-container workflow-details-scroll">
                            <div className="px-40 workflows-center-align">
                                {this.isActiveWorkflowReadonly() ?
                                    <div className="row mt-5">
                                        <div className="col-md-12">
                                            <div className="alert alert-warning alert-readonly" role="alert">
                                                <div className="pre-wrap">
                                                    {this.props.translate("workflowDetails.readonlyWarning.titleInfoBefore")}
                                                    <strong>{this.props.translate("workflowDetails.readonlyWarning.titleMode")}</strong>
                                                    {this.props.translate("workflowDetails.readonlyWarning.titleInfoAfter")}
                                                </div>
                                                <div
                                                    className="pre-wrap">{this.props.translate("workflowDetails.readonlyWarning.details")}</div>
                                            </div>
                                        </div>
                                    </div> : null
                                }
                                <div className={`row mb-4 mt-5 mx-0 ${this.getReadonlyStyleProperties()}`}>
                                    <div
                                        className="col-md-2 document-type-title text-disabled d-flex align-items-center px-0">
                                    <span
                                        className={"mr-8 appicon appicon-block document-type-icon-24 " + formatDocumentType(activeWorkflow.documenttype) + "-24"} />
                                        {this.props.translate("documentType." + DOCUMENT_TYPE.asString(activeWorkflow.documenttype))}
                                    </div>


                                    <div className="col-md-6 d-flex align-items-center px-0">
                                        {(activeWorkflow.documenttype === DOCUMENT_TYPE.INVOICE || activeWorkflow.documenttype === DOCUMENT_TYPE.VOUCHER) &&
                                            <div className="checkbox mb-0 mt-0">
                                                <input type="checkbox"
                                                       id="initial-approver-checkbox"
                                                       checked={activeWorkflow.editDuringInitialWorkflow}
                                                       className="checkbox-margin"
                                                       onChange={this.changeEditDuringInitialWorkflow} />
                                                <label htmlFor="initial-approver-checkbox">
                                                    {this.props.translate("workflowDetails.initialApprover")}
                                                </label>
                                            </div>
                                        }
                                    </div>

                                    <div className="col-md-4 px-0" onClick={() => {
                                    }}>
                                        <div className="float-right align-items-center d-flex">
                                            <div className="checkbox m-0">
                                                <input type="checkbox"
                                                       id="four-eyes-checkbox"
                                                       defaultChecked={twoPersonsRule.enabled}
                                                       checked={twoPersonsRule.enabled}
                                                       onChange={this.on4EyesToggle} />
                                                <label htmlFor="four-eyes-checkbox" className="me-2">
                                                    {this.props.translate("workflowDetails.4EyesApproval.checkbox")}
                                                </label>
                                            </div>
                                            <span
                                                className="vismaicon vismaicon-sm vismaicon-dynamic vismaicon-filled vismaicon-help"
                                                title={this.props.translate("workflowDetails.4EyesApproval.checkboxInfo")} />
                                        </div>
                                        {this.state.disable4EyesPopupVisible ?
                                            <Confirm translate={this.props.translate}
                                                     handleAction={this.confirm4EyesDisablePopup}
                                                     closeCallback={this.hide4EyesDisablePopup}
                                                     confirmButtonText={"workflowDetails.4EyesApproval.disablePopup.disableButton"}
                                                     confirmButtonColor={"btn-default"}
                                                     title={this.props.translate("workflowDetails.4EyesApproval.disablePopup.title")}
                                                     confirmIcon={"vismaicon-warning"}>
                                                <div>
                                                    <p>{this.props.translate("workflowDetails.4EyesApproval.disablePopup.body")}</p>
                                                    <p className="text-muted">{this.props.translate("workflowDetails.4EyesApproval.disablePopup.bodyHelper")}</p>
                                                </div>
                                            </Confirm> : null}
                                    </div>
                                </div>
                                <div className="row">
                                    {twoPersonsRule.enabled ?
                                        <div className={`col-md-12 ${this.getReadonlyStyleProperties()}`}>
                                            <div className="alert alert-icon-md alert-warning" role="alert">
                                                <p>
                                                    <strong>{this.props.translate("workflowDetails.4EyesApproval.alertTitle")}</strong>
                                                </p>
                                                <p>
                                                    <div
                                                        className="pre-wrap">{this.props.translate("workflowDetails.4EyesApproval.alertDescription")}</div>
                                                </p>
                                            </div>
                                        </div> : null
                                    }

                                    {!this.props.userRoles.systemAdministrator && !this.isActiveWorkflowReadonly() &&
                                        <div className={`col-md-12 ${this.getReadonlyStyleProperties()}`}>
                                            <div className="alert alert-sm alert-warning mt-3" role="alert">
                                                <div> {this.props.translate("workflowDetails.noSaveWarning")}</div>
                                                <span
                                                    className="vismaicon vismaicon-sm vismaicon-filled vismaicon-warning" />
                                            </div>
                                        </div>
                                    }

                                    <div className={"col-md-12 mt-3"}>
                                        <Simulator documentType={activeWorkflow.documenttype}
                                                   workflow={activeWorkflow} />
                                        <div className={this.getReadonlyStyleProperties()}>
                                            <div>

                                                <SortableList items={activeWorkflow} onSortEnd={this.swapSteps}
                                                              useDragHandle={true}
                                                              helperContainer={() => {
                                                                  return document.getElementById("Workflow-details");
                                                              }}
                                                              helperClass={"dragging-workflow-item"}
                                                              lockAxis="y"
                                                              isEditing={this.state.isEditing || this.state.addingNew}
                                                              numSteps={activeWorkflow.steps ? activeWorkflow.steps.length : 0}
                                                              swapSteps={this.swapSteps}
                                                              changeStepName={this.changeStepName}
                                                              saveStepSettings={this.saveStepSettings}
                                                              editStep={this.editStep}
                                                              removeStep={this.props.removeStep}
                                                              translate={this.props.translate}
                                                />
                                            </div>

                                            <button type="button" className="btn btn-primary-tint"
                                                    onClick={this.insertNewStep}>
                                                    <span
                                                        className="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-add-circle" />
                                                {this.props.translate("workflowDetails.addNewStep")}
                                            </button>

                                            {twoPersonsRule.enabled ?
                                                <ApprovalStep4Eyes
                                                    approvers={this.props.activeWorkflow.twoPersonsRule.fallbackUsers || []}
                                                    documentType={activeWorkflow.documenttype}
                                                    onChange={this.on4EyesChange} />
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <SettingsButtonsBarView
                    containerFooterStyling={"workflows-container"}
                    containerInnerStyling={"px-40 workflows-center-align"}
                    hasBackButton={false}
                    backCallback={this.isBackNavigationAllowed() ? navigateToWorkflows : null}
                    moreActionsButton={<WorkflowsActionsDropdownComponent rule={this.props.activeWorkflow}
                                                                          copyCallback={this.moreActionsCallback}
                                                                          deleteCallback={this.moreActionsCallback} />}
                    okCallback={this.confirmSave}
                    hideSave={!this.props.userRoles.systemAdministrator || this.isActiveWorkflowReadonly()}
                    okEnabled={this.state.okEnabled && valid4EyesApproval}
                    cancelEnabled={this.props.userChangedTheWorkflow || this.state.okEnabled}
                    cancelCallback={this.setActiveWorkflow}
                    hideCancel={this.isActiveWorkflowReadonly()} />

            </div>
        );
    }
}

const withTranslations = translate(WorkflowDetails);
const mapStateToProps = function(store) {
    return {
        workflows: reduxSelectors.getWorkflows(store),
        activeWorkflow: reduxSelectors.getActiveWorkflow(store),
        workflowChanged: reduxSelectors.getWorkflowChangedStatus(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        usersBusinessFeatures: reduxSelectors.getUsersCurrentBusinessFeatures(store),
        userChangedTheWorkflow: reduxSelectors.getChangesDoneByUser(store),
    };
};

const connected = connect(mapStateToProps, {
    getRuleConfigurationRoles,
    getCostUnits,
    getOrganizationChart,
    setActiveWorkflow,
    sortSteps,
    setStepSettings,
    setStepChange,
    set4EyesEnabled,
    set4EyesFallbackUsers,
    removeActiveWorkflow,
    saveRule,
    activateReadonlyRule,
    deactivateReadonlyRule,
    renameRule,
    removeStep,
})(withTranslations);

export default connected;

const SortableList = SortableContainer((props) => {
    return (
        <ul className="list-unstyled">
            {props.items ? props.items.steps.map((step, index) => {
                return (
                    <SortableItem {...props}
                                  step={step}
                                  canDelete={props.items.steps.length > 1}
                                  key={`item-${index}`}
                                  index={index}
                                  itemIndex={index} />
                );
            }) : (<li />)
            }
        </ul>
    );
});

const SortableItem = SortableElement(({
                                          items,
                                          step,
                                          canDelete,
                                          index,
                                          itemIndex,
                                          isEditing,
                                          swapSteps,
                                          changeStepName,
                                          saveStepSettings,
                                          editStep,
                                          removeStep,
                                          translate,
                                      }) => {
        return (
            <li className="draggable-workflow-item">
                <div className="height-100">
                    {items.steps.length > 1 &&
                        <DragHandle isEditing={false} />
                    }
                    <Step type={items.documenttype}
                          step={step}
                          key={`step-${step.stepNumber}`}
                          isEditing={isEditing}
                          numSteps={items.steps.length}
                          swapSteps={swapSteps}
                          changeStepName={changeStepName}
                          saveStepSettings={saveStepSettings}
                          editStep={editStep}
                          removeStep={removeStep}
                          translate={translate}
                          canDelete={canDelete}
                          itemIndex={itemIndex}
                    />
                </div>
            </li>
        );
    },
);


const DragHandle = SortableHandle(({ isEditing }) => <span
    className={"vismaicon vismaicon-drag vismaicon-dynamic" + (isEditing ? " disabled" : "")} />);





