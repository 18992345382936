import React, {ReactElement, useEffect, useState} from 'react';
import translate from "../translations/translations.wrapper";
import {getDecisionTableList} from "./decisionTable.functions";
import {DecisionTable} from "./decisionTable.class";
import * as reduxSelectors from "../store/application.reducers";
import {connect} from "react-redux";
import {Store} from "redux";
import {DecisionTablesList} from "./decisionTablesList.component";
import {Tooltip} from "../tooltip/Tooltip";
import cube from "./icons/cube.svg";
import arrow from "./icons/arrow.svg";
import "./decisionTables.scss";
import smallSumIcon from "./icons/smallSumIcon.svg";
import {LOADING_STATUS} from "../../utils/constants";

type DecisionTablesProps = {
    translate: React.FC,
    currentContextId: number
}


export function DecisionTables(props: DecisionTablesProps) {

    const [decisionTables, setDecisionTables] = useState<DecisionTable[] | null | undefined>(null);
    const [showNewTable, setShowNewTable] = useState<boolean>(false);
    const [loadingData, setLoadingData] = useState<number | null>(null);


    useEffect(() => {
        getData();
    }, [props.currentContextId]);

    const getData = function (): void {
        setLoadingData(LOADING_STATUS.LOADING);
        getDecisionTableList().then((result: DecisionTable[] | null) => {
            setLoadingData(LOADING_STATUS.DONE);
            if (result) {
                setDecisionTables(result);
            }
        });
    }

    const showNewTableFinished = function (): void {
        setShowNewTable(false);
    }

    return (<div className="pt-4 workflows decision-tables">
        <div className={"row m-0 p-0"}>
            <h3 className="col-md-8 p-0 no-border d-flex align-items-center">{props.translate("decisionTables.title")}
                <span className="vismaicon vismaicon-filled vismaicon-help ms-4" data-tip
                      data-for={"Decision_Table_Title_Info"}/>
                <Tooltip
                    id={"Decision_Table_Title_Info"}
                    content={() => <span>{props.translate("decisionTables.info")}</span>}
                />
            </h3>
            <div className={"col-md-4 text-end top-action-buttons"}>
                <span className={"btn btn-primary"}
                      onClick={() => setShowNewTable(true)}>
                    <img src={smallSumIcon} alt={"sum"}
                         className={"border-0 mr-4"}/>{props.translate("decisionTables.newDecisionTable")}</span>
                <span className={"btn btn-default"}>{props.translate("decisionTables.history")}</span>
            </div>
        </div>

        {loadingData === LOADING_STATUS.DONE &&
            (((decisionTables && Object.keys(decisionTables).length > 0) || showNewTable) ?
                <div id={"decisiontableslist"}>
                    <DecisionTablesList
                        showNewTable={showNewTable}
                        showNewTableFinished={showNewTableFinished}
                        decisionTables={decisionTables}
                        translate={props.translate}
                        callback={getData}
                    />
                </div> : <NoDecisionTables translate={props.translate}/>)
        }

        {loadingData === LOADING_STATUS.LOADING &&
            <span className="spinner spinner-default-blue loading"/>
        }
    </div>)

}

const withTranslations = translate(DecisionTables);
const mapStateToProps = function (store: Store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        docTypes: reduxSelectors.getWorkflowsDocTypes(store)
    };
};

const connected = connect(mapStateToProps, {})(withTranslations);
export default connected;


type NoDecisionTablesProps = {
    translate: React.FC
}


function NoDecisionTables(props: NoDecisionTablesProps): ReactElement {
    return (
        <div className={"no-decision-table mt-auto mx-auto"}>
            <img src={arrow} alt={"arrow"} className={"border-0 arrow-icon"}/>
            <img src={cube} alt={"no decision tables"} className={"border-0 cube-img"}/>
            <h2 className={"main-info"}>{props.translate("decisionTables.noTables.mainInfo")}</h2>
            <span>{props.translate("decisionTables.noTables.moreInfo")}</span>
            <br/>
            <a href={"#"}>{props.translate("decisionTables.noTables.link")}</a>
        </div>
    );

}