import React, {Component} from 'react';
import * as reduxSelectors from '../components/store/application.reducers';
import {connect} from 'react-redux';
import translate from 'components/translations/translations.wrapper.jsx';



/*
* inspiration from https://github.com/Wootric/spa-examples/blob/master/react/src/components/Wootric.js
* */

class Wootric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            didWootricRun: false
        };
        this.runWootric = this.runWootric.bind(this);
    }

    runWootric() {
        if (window.wootric === undefined) {
            setTimeout(this.runWootric, 2000);
            return;
        }

        //run this part only if the survey hasn't been triggered
        if (!this.state.didWootricRun) {

            let {userData, userLanguage, userConfig, translate} = this.props;
            //since we load wootric at the start of the app the translations might not yet be loaded so we need to wait for them
            let areTranslationsLoaded = translate("wootric.disclaimer.feedbackText") !== "wootric.disclaimer.feedbackText";

            //we need all props available to complete the wootric settings JSON
            if (!userData || !userLanguage || !userConfig || !areTranslationsLoaded) {
                setTimeout(this.runWootric, 2000);
                return;
            }
            //window.wootric_survey_immediately = true;
            //window.wootric_no_surveyed_cookie = true;
            window.wootricSettings = {
                email: userData.emailAddress, //Discuss if we want this (GDPR)
                product_name: 'Visma.net Approval',
                account_token: 'NPS-EU-6afedf64', // This is your unique account token. EU token: NPS-EU-6afedf64, US: NPS-96cf5e48
                language: userLanguage.toUpperCase(),
                aria: true,
                //This needs to be translated by us
                disclaimer: {
                    text: translate("wootric.disclaimer.feedbackText"),
                    link: 'https://www.visma.com/privacy/customer-feedback/',
                    link_word: translate("wootric.disclaimer.feedbackLink"),
                },
                properties: {
                    role: userConfig.userRoles.approver ? "approver" : "not approver", //Just for testing
                    country: userConfig.countryCode,
                    distributor: userConfig.distributorName ? userConfig.distributorName : "unknown",
                    customer: userConfig.customerName ? userConfig.customerName : "unknown",
                    companyId: userConfig.currentCompany.odpcompanyId.odpcompanyId,
                    companyRegistrationNumber: userConfig.currentCompany.organizationNumber
                }
            };
            window.wootric('run');
            this.setState({
                didWootricRun: true
            });
        }
    }

    componentDidMount() {
        this.runWootric();
    }

    render() {
        return (
            <div/>
        );
    }
}

const withTranslations = translate(Wootric);

const mapStateToProps = function (store) {
    return {
        userData: reduxSelectors.getUsersLoggedInData(store),
        userLanguage: reduxSelectors.getUsersCurrentLanguage(store),
        userConfig: reduxSelectors.getUsersData(store),
        usersBusinessFeatures: reduxSelectors.getUsersCurrentBusinessFeatures(store),
    };
};
const connected = connect(mapStateToProps, {})(withTranslations);

export default connected;