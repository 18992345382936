import React, {Component, Fragment} from 'react';
import {PopUp} from './PopUp.component';
import {DOCUMENT_ACTIONS} from '../../utils/constants';
import translate from '../translations/translations.wrapper.jsx';
import * as Api from '../../utils/api/api.js';
import {handleError} from '../../utils/errorHandle.function';
import NumericInput from 'react-numeric-input';
import Confirm from './ConfirmActionPopup.component';
import {connect} from 'react-redux';
import * as reduxSelectors from '../store/application.reducers';
import {getWorkflowsAmountLimits} from '../workflows/workflows.action';
import Select from 'react-select';
import {inPopUpCustomStyles} from '../../utils/SelectTheme';

import "./ChangeAmountLimit.scss";
//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

export class ChangeAmountLimit extends PopUp {

    constructor(props) {
        super(props);
        this.buildContent = this.buildContent.bind(this);
        this.getAmountDetails = this.getAmountDetails.bind(this);
        this.deleteAmountLimit = this.deleteAmountLimit.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.discardDelete = this.discardDelete.bind(this);
        this.deleteCurency = this.deleteCurency.bind(this);
        this.saveAmountLimit = this.saveAmountLimit.bind(this);
        this.addAmountLimit = this.addAmountLimit.bind(this);
        this.addCurrencyLimit = this.addCurrencyLimit.bind(this);

        this.state = {
            selectedLimit: null,
            amountDetails: [],
            allCurencies: [],
            showNewLimit: false,
            showNewDetails: false,
            amountLimits: props.amountLimits
        }
    }

    componentDidMount() {
        super.componentDidMount();
        Api.getAllCurrencies().then(response => {
            this.setState({allCurencies: response});
        }, error => {
            handleError(error);
        });
    }


    getAmountDetails(id) {
        this.setState({
            amountDetails: [],
            selectedLimit: id
        });
        Api.getAmountLimitDetails(id).then(response => {
            this.setState({amountDetails: response});
        }, error => {
            handleError(error);
        });
    }

    deleteCurency(id, currency) {
        Api.deleteCurrency(id, currency).then(response => {
            this.getAmountDetails(id);
        }, error => {
            handleError(error);
        });
    }

    discardDelete() {
        this.setState({
            toDelete: null,
            warningMessage: null
        });
    }

    confirmDelete() {
        this.deleteAmountLimit(this.state.toDelete, true);
    }

    deleteAmountLimit(id, confirm = false) {
        Api.deleteAmountLimit(id, confirm).then(respose => {
            this.setState({
                warningMessage: null,
                selectedLimit: null,
                amountDetails: []
            });
            this.props.getWorkflowsAmountLimits();

        }, error => {
            if (error.errorType === "CONFIRMATION") {
                this.setState({
                    confirmDelete: true,
                    toDelete: id,
                    warningMessage: error.errorMessages
                });
            }
            else
                handleError(error);
        });
    }

    saveAmountLimit(value) {
        Api.editAmountLimit(this.state.selectedLimit, value).then(response => {
            this.props.getWorkflowsAmountLimits();
        }, error => {
            handleError(error);
        });
    }

    addAmountLimit(value) {
        Api.addAmountLimit(value).then(response => {
            this.props.getWorkflowsAmountLimits();
            this.setState({showNewLimit: false});
        }, error => {
            handleError(error);
        });
    }

    addCurrencyLimit(id, currency, amount) {
        Api.addCurrencyAmountLimit(id, currency, amount).then(response => {
            this.setState({showNewDetails: false});
            this.getAmountDetails(id);
        }, error => {
            handleError(error);
        });
    }

    getModalClasses(){
        return "py-4";
    }

    buildContent() {

        let limitsName = this.props.amountLimits ? this.props.amountLimits.map((amount, index) => {
            return (
                <LimitsNames key={index}
                             amount={amount}
                             getAmountDetails={this.getAmountDetails}
                             deleteAmountLimit={this.deleteAmountLimit}
                             saveAmountLimitName={this.saveAmountLimit}
                             selectedLimit={this.state.selectedLimit}/>
            );
        }) : [];


        let amountDetails = this.state.amountDetails.map((detail, index) => {
            return (
                <AmountDetails key={index} detail={detail}
                               id={this.state.selectedLimit}
                               allCurencies={this.state.allCurencies}
                               deleteCurency={this.deleteCurency}
                               addCurrencyLimit={this.addCurrencyLimit}/>
            )
        });

        return (
            <Fragment>

                {this.state.warningMessage &&
                <Confirm translate={this.props.translate}
                         message={this.state.warningMessage}
                         handleAction={this.confirmDelete}
                         closeCallback={this.discardDelete}/>
                }


                <div className="modal-body" id="Edit-Limit">
                    <div className="actions-list col-md-12 px-0 margin-bottom">
                        <div className="row px-0 mx-0">
                            <div className="col-md-9 px-0">
                                {this.props.translate("popUp.editLimit.limit")}
                            </div>
                            <div className="col-md-3 px-0 float-right text-end">
                                {this.props.translate("roles.actions")}
                            </div>
                        </div>
                        <div
                            className="col-md-12 main-panel px-0 border-0">
                            {limitsName}
                            {this.state.showNewLimit &&
                            <LimitsNames isNew={true}
                                         getAmountDetails={this.getAmountDetails}
                                         deleteAmountLimit={this.deleteAmountLimit}
                                         saveAmountLimitName={this.addAmountLimit}
                                         cancelCallback={() => {
                                             this.setState({showNewLimit: false})
                                         }}
                                         selectedLimit={null}/>
                            }

                            <div className="row px-0 mx-0 border-0">
                                <span className="vismaicon  vismaicon-add-circle vismaicon-dynamic float-right" onClick={() => {
                                    this.setState({showNewLimit: true})
                                }}/>
                            </div>
                        </div>
                    </div>


                    <span>{this.props.translate("popUp.editLimit.dividerText")}</span>
                    <hr className="margin-bottom"/>

                    <div className="actions-list col-md-12 px-0">
                        <div className="row px-0 mx-0">
                            <div className="col-md-3 px-0">
                                {this.props.translate("popUp.editLimit.currency")}
                            </div>
                            <div className="col-md-6 text-center">
                                {this.props.translate("popUp.editLimit.value")}
                            </div>
                            <div className="col-md-3 px-0 float-right text-end">
                                {this.props.translate("roles.actions")}
                            </div>
                        </div>

                        <div
                            className={"col-md-12 main-panel px-0 border-0" + (this.state.showNewDetails ? " editing" : '')}>
                            {this.state.selectedLimit &&
                                <Fragment>
                                    {amountDetails}
                                    {this.state.showNewDetails &&
                                        <AmountDetails detail={null}
                                                       isNew={true}
                                                       cancelCallback={() => {
                                                           this.setState({showNewDetails: false})
                                                       }}
                                                       id={this.state.selectedLimit}
                                                       allCurencies={this.state.allCurencies}
                                                       deleteCurency={this.deleteCurency}
                                                       addCurrencyLimit={this.addCurrencyLimit}/>
                                    }

                                <div className="row px-0 mx-0 border-0">
                                <span className="vismaicon vismaicon-add-circle vismaicon-dynamic float-right" onClick={() => {
                                    this.setState({showNewDetails: true})
                                }}/>
                                </div>
                            </Fragment>
                            }
                        </div>
                    </div>
                </div>


                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX}>
                            {this.props.translate("popUp.ok")}
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }

    getType() {
        return DOCUMENT_ACTIONS.APPROVE_DIRECTLY;
    }

    getTitle() {
        return this.props.translate("popUp.editLimit.title");
    }

}

const withTranslations = translate(ChangeAmountLimit);


const mapStateToProps = function (store) {
    return {
        amountLimits: reduxSelectors.getWorkflowsAmountLimits(store)
    };
};
const connected = connect(mapStateToProps, {getWorkflowsAmountLimits})(withTranslations);
export default connected;

//export default withTranslations;


class LimitsNames extends Component {

    constructor(props) {
        super(props);
        this.getAmountDetails = this.getAmountDetails.bind(this);
        this.deleteAmountLimit = this.deleteAmountLimit.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.saveAmountLimitName = this.saveAmountLimitName.bind(this);

        this.state = {
            editMode: !!props.isNew
        }

    }

    getAmountDetails() {
        this.props.getAmountDetails(this.props.amount.limitId);
    }

    deleteAmountLimit() {
        this.props.deleteAmountLimit(this.props.amount.limitId);
    }

    enterEditMode() {
        this.setState({editMode: true});
    }

    exitEditMode() {
        if (this.props.isNew) {
            this.props.cancelCallback();
        }
        else {
            this.setState({editMode: false});
        }
    }

    saveAmountLimitName() {
        let amountName = this.refs.inputValue.value;
        if (amountName.length > 80)
            amountName = amountName.substring(0, 80);
        if (amountName)
            this.props.saveAmountLimitName(amountName);
        if (!this.props.isNew)
            this.exitEditMode();
    }


    render() {
        if (this.state.editMode) {
            return (
                <div className="row slected">
                    <div className="col-md-9 px-0 cropped-text">
                        <input type="text" autoFocus={true}
                               defaultValue={this.props.amount ? this.props.amount.name : ''}
                               ref="inputValue"/>
                    </div>
                    <div className="col-md-3 px-0 text-end">
                        <span
                            className="vismaicon vismaicon-save margin-right-4"
                            onClick={this.saveAmountLimitName}/>
                        <span className="vismaicon vismaicon-cancel-circle"
                              onClick={this.exitEditMode}/>
                    </div>
                </div>
            )
        }
        return (
            <div
                className={"row px-0" + (this.props.selectedLimit === this.props.amount.limitId ? " selected" : "")}
                onClick={this.getAmountDetails}>
                <div className="col-md-9 px-0 cropped-text">
                    {this.props.amount.name}
                </div>
                <div className="col-md-3 px-0 text-end">
                    <span className="vismaicon vismaicon-edit vismaicon-dynamic margin-right-4" onClick={this.enterEditMode}/>
                    <span className="vismaicon vismaicon-delete vismaicon-dynamic"
                          onClick={this.deleteAmountLimit}/>
                </div>
            </div>
        );


    }
}

class AmountDetails extends Component {

    constructor(props) {
        super(props);

        this.deleteCurency = this.deleteCurency.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.changeAmount = this.changeAmount.bind(this);
        this.setNewCurrency = this.setNewCurrency.bind(this);
        this.saveAmount = this.saveAmount.bind(this);


        this.state = {
            editMode: !!props.isNew,
            amount: props.detail ? props.detail.amount : '',
            curency: props.detail ? props.detail.currencyCode : ''
        }

    }

    enterEditMode() {
        this.setState({editMode: true});
    }

    exitEditMode() {
        if (this.props.isNew) {
            this.props.cancelCallback();
        }
        else {
            this.setState({editMode: false});
        }
    }

    deleteCurency() {
        this.props.deleteCurency(this.props.id, this.props.detail.currencyCode);
    }

    changeAmount(value) {
        this.setState({
            amount: value
        });
    }

    setNewCurrency(curency) {
        this.setState({
            curency: curency.value
        });
    }

    saveAmount() {
        this.props.addCurrencyLimit(this.props.id, this.state.curency, this.state.amount);
        if (!this.props.isNew)
            this.exitEditMode();
    }


    render() {
        if (this.state.editMode) {
            let curencies = this.props.allCurencies.map((curency) => {
                return {label: curency, value: curency}
            });

            return (
                <div className="row px-0">
                    <div className="col-md-3 px-0">
                        {this.props.isNew ?
                            <Select options={curencies}
                                    isClearable={false}
                                    isSearchable={true}
                                    styles={inPopUpCustomStyles}
                                    onChange={this.setNewCurrency}
                            />
                            : this.state.curency
                        }

                    </div>
                    <div className="col-md-6 text-center">
                        <NumericInput min={1} value={this.state.amount} size={7}
                                      className="text-end vertical-center"
                                      strict={true}
                                      onChange={this.changeAmount}/>
                    </div>
                    <div className="col-md-3 text-end px-0">
                        <span className="vismaicon vismaicon-save margin-right-4" onClick={this.saveAmount}/>
                        <span className="vismaicon vismaicon-cancel-circle"
                              onClick={this.exitEditMode}/>
                    </div>
                </div>
            );
        }
        return (
            <div className="row px-0">
                <div className="col-md-3 px-0">
                    {this.state.curency}
                </div>
                <div className="col-md-6 text-center">
                    {this.state.amount}
                </div>
                <div className="col-md-3 text-end px-0">
                    <span className="vismaicon vismaicon-edit vismaicon-dynamic margin-right-4" onClick={this.enterEditMode}/>
                    <span className="vismaicon vismaicon-delete vismaicon-dynamic"
                          onClick={this.deleteCurency}/>
                </div>
            </div>)
    }
}