import React from 'react';
import {formatDate} from '../../../../utils/valueFormatter.function';
import {Collapse} from 'react-bootstrap';
import classNames from 'classnames';

export const DocumentDataDefault = ({details, translate, onCollapse, isCollapsed}) => {
     return (
            <div className={classNames("panel panel-default details default-details",
                {collapsed: isCollapsed})}
                 style={{overflow: "auto !important"}}>
                <div className="panel-heading " onClick={onCollapse}>
                    {translate("documentData.documentData")}
                    <span className={"margin-left-8 vismaicon sort-arrow sort-arrow-" +
                    (!isCollapsed ? "down" : "right")}>
                    </span>
                </div>
                <Collapse in={!isCollapsed}>
                    <div id="data-details" className="panel-body no-bottom-padding">
                        <table className="document-data col-md-12 no-side-padding">
                            <tbody>
                            <tr>
                                <td>{translate("documentData.documentID")}</td>
                                <td title={details.documentId}>{details.documentId}</td>
                            </tr>
                            {details.description &&
                            <tr>
                                <td>{translate("documentData.description")}</td>
                                <td title={details.description}>{details.description}</td>
                            </tr>
                            }
                            {details.invoiceDate &&
                            <tr>
                                <td>{translate("documentData.invoiceDate")}</td>
                                <td>{formatDate(details.invoiceDate)}</td>
                            </tr>
                            }
                            {details.supplierName &&
                            <tr>
                                <td>{translate("documentData.supplierName")}</td>
                                <td title={details.supplierName}>{details.supplierName}</td>
                            </tr>
                            }
                            {details.dueDate &&
                            <tr>
                                <td>{translate("documentData.dueDate")}</td>
                                <td>{formatDate(details.dueDate)}</td>
                            </tr>
                            }
                            <tr>
                                <td>{translate("documentData.amount")}</td>
                                <td><b> {details.amount}</b> {details.currency}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Collapse>
            </div>
        )
    };

export default  DocumentDataDefault;