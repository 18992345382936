import fi from '../../utils/i18n/translations_fi.json';
import sv from '../../utils/i18n/translations_sv.json';
import da from '../../utils/i18n/translations_da.json';
import nl from '../../utils/i18n/translations_nl.json';
import no from '../../utils/i18n/translations_no.json';
import en from '../../utils/i18n/translations.json';

const flattenObject = function(o) {
    const result = {};

    for (const i in o) {
        if (!o.hasOwnProperty(i)) continue;

        if ((typeof o[i]) == 'object') {
            const flatObject = flattenObject(o[i]);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                result[i + '.' + x] = flatObject[x];
            }
        } else {
            result[i] = o[i];
        }
    }
    return result
        ;
};

// asynchronous implementation, moved to own file for simpler unittesting of original implementation
export default function loadTranslationsImpl(lang, command) {
    // we have to be verbose here, for webpack to properly identify separated js bundle
    switch (lang) {
        case 'fi':
            command(lang, flattenObject(fi));
            break;

        case 'sv':
            command(lang, flattenObject(sv));
            break;

        case 'da':
            command(lang, flattenObject(da));
            break;

        case 'nl':
            command(lang, flattenObject(nl));
            break;

        case 'no':
            command(lang, flattenObject(no));
            break;

        case 'vi':
            // we have to output it into new object, otherwise we're changing the content of translations.json :)
            const viTranslations = {};
            // until we have AC-3291, copy the keys into the values
            for (let property in en) {
                if (en.hasOwnProperty(property)) {
                    viTranslations[property] = property;
                }
            }
            command(lang, flattenObject(viTranslations));
            break;


        case 'en':
        default:
            command(lang, flattenObject(en));
            break;
    }

}
