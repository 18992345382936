import DocumentEditor from "./DocumentEditor.component";
import React from "react";
import {DETAILS_VIEW_TYPE} from "utils/constants.js";
import resizeLarge from "../../../../assets/editor_resize_large.png";
import resizeLargeActive from "../../../../assets/editor_resize_large_blue.png";
import resizeSmall from "../../../../assets/editor_resize_small.png";
import resizeSmallActive from "../../../../assets/editor_resize_small_blue.png";
import {snowplowTrackEvent} from "utils/snowplow.js";
import * as reduxSelectors from "../../../store/application.reducers";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
//import {Store} from "redux";


export const DocumentEditorPanel = (props) => {

    const userConfiguration = useSelector((store) => reduxSelectors.getUsersData(store));
    const navigate = useNavigate();
    const getEditorExternalApplicationName = () => {
        switch (props.type) {
            case DETAILS_VIEW_TYPE.TASK:
                return props.task.externalApplicationName;
            case DETAILS_VIEW_TYPE.HISTORY_TASK:
            case DETAILS_VIEW_TYPE.PROCESS:
                return props.task.applicationTypeName;
            case DETAILS_VIEW_TYPE.DOCUMENT:
                return props.task.applicationTypeName;
            default:
                return props.task.externalApplicationName;
        }
    }

    const getDocumentType = () => {
        return props.task.documentType;
    }

    const getEditorExternalId = () => {
        switch (props.type) {
            case DETAILS_VIEW_TYPE.TASK:
                return props.task.externalId;
            case DETAILS_VIEW_TYPE.HISTORY_TASK:
            case DETAILS_VIEW_TYPE.PROCESS:
                return props.task.externalApplicationId;
            case DETAILS_VIEW_TYPE.DOCUMENT:
                return props.task.claimId;
            default:
                return props.task.externalId;
        }
    }

    const trackPanelCollapsing = (isCollapsed) => {
        snowplowTrackEvent({
            category: "external_editor_panel",
            action: "external_editor_collapsing",
            label: "external_editor_panel",
            value: isCollapsed ? "collapsed" : "extended"
        }, userConfiguration);
    }

    const applicationName = props.task.externalApplicationName || props.task.applicationTypeName || '';
    const externalApplicationNameKey = applicationName.replace(/[ .]/g, '');


    if (props.taskDetails.externalEditorAvailable) {
        return (
            <div className={`grid-layout-editor ${props.renderInPortal ? 'in-portal' : ''}`}
                 data-cy="taskDetail.externalEditorPanel">
                <div className="grid-panel-wrapper">
                    <div className="grid-panel">
                        <div className="grid-panel-header d-flex justify-content-between align-items-center">
                            <strong>
                                {props.translate("taskDetail.externalEditor")} {props.translationKeyExists(`taskDetail.applicationName.${externalApplicationNameKey}`) ?
                                props.translate(`taskDetail.applicationName.${externalApplicationNameKey}`) : applicationName}
                            </strong>
                            <div className={`editor-resize-buttons ${props.inEditMode ? ' disabled' : ''}`}>
                                <img src={props.renderInPortal ? resizeSmall : resizeSmallActive}
                                     alt="resize button"
                                     title={props.translate("taskDetail.placeAtTheSide")}
                                     className={`details__action-button ${props.renderInPortal ? '' : ' cursor-pointer'}`}
                                     onClick={() => {
                                         trackPanelCollapsing(true);
                                         props.onRenderInPortalChange(true);
                                     }}/>
                                <img src={props.renderInPortal ? resizeLargeActive : resizeLarge}
                                     alt="resize button"
                                     title={props.translate("taskDetail.placeAtTheBottom")}
                                     className={`details__action-button ${props.renderInPortal ? 'cursor-pointer' : ''}`}
                                     onClick={() => {
                                         trackPanelCollapsing(false);
                                         props.onRenderInPortalChange(false);
                                     }}/>
                            </div>

                        </div>
                        <div className={`grid-editor ${props.renderInPortal ? '' : 'grid-editor-large'}`}>
                            <DocumentEditor translate={props.translate}
                                            taskId={props.params.taskId}
                                            id={props.taskDetails ? props.taskDetails.id : null}
                                            isHistory={props.isHistory}
                                            isReadOnly={props.editorReadOnly || props.taskDetails.readonly}
                                            externalAppId={getEditorExternalId()}
                                            externalApplicationName={getEditorExternalApplicationName()}
                                            documentType={getDocumentType()}
                                            companyId={props.task.companyId}
                                            reapproveType={props.taskDetails ? props.taskDetails.reapproveType : null}
                                            route={navigate}
                                            viewType={props.type}
                                            isPopup={false}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }

}

export default DocumentEditorPanel;