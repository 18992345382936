import React, {Component} from 'react';
import Select from 'react-select';
import UserSearch from '../input/UserSearch.component';
import {autoMenuStyle} from '../../utils/reactSelectTheme';

export class EditableEmails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: props.value
        };

        this.changeSelectedValue = this.changeSelectedValue.bind(this);
    }

    changeSelectedValue(value) {
        this.setState({
            selectedValue: value
        });

        this.props.changeValue(value, this.props.index);
    }

    render() {
        return (
            <UserSearch propagateValue={this.changeSelectedValue}
                        includeSameUser={true}
                        value={this.state.selectedValue}
                        stopFocus={true}
                        multi={true}
                        menuPlacement={"auto"}
                        styles={autoMenuStyle}
                        translate={this.props.translate}/>
        );
    }
}


export class EditableDimension extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: props.value
        };
        this.changeSelectedValue = this.changeSelectedValue.bind(this);
    }

    changeSelectedValue(value) {
        this.setState({
            selectedValue: value
        });

        this.props.changeValue(value, this.props.index);
    }


    render() {
        return (
            <Select
                clearable={false}
                menuShouldScrollIntoView={true}
                cache={true}
                multi={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                menuPlacement={"auto"}
                searchable={true}
                minMenuHeight={100}
                value={this.state.selectedValue}
                noResultsText={this.props.translate("userSearch.noresults")}
                options={this.props.options}
                onChange={this.changeSelectedValue}
            />
        );
    }
}