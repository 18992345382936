import {
    APPROVAL_MENU_ITEMS,
    SETTINGS_URL_PART,
    CONFIGURATION_URL_PART,
    PROCESS_DETAILS_URL_PART, DOCUMENT_DETAILS_URL_PART, MY_HISTORY_URL_PART
} from "utils/constants";
import _ from "lodash";
import history from './history.jsx';


// TODO all paths should be extracted into constants and reused here and in routes definitions

export function navigateToMyTasks() {
    history.push("/" );
    history.go();
}

export function navigateToMyHistory() {
    history.push("/history");
    history.go();
}

export const navigateToTaskDetail = (taskKey) => {
    history.push("/task/" + taskKey);
    history.go();
}

export function navigateToErrorPage() {
    history.push('/error');
    history.go();
}

export function navigateToHistoryTaskDetail(taskKey) {
    history.push("/taskhistory/" + taskKey);
    history.go();
}

export function switchUserContextRoute(context, userRoles) {
    let action = 'push';
    let futureLocation = window.location.pathname;
    //if the context is changed from the details view then redirect the user to the "main" view
    let currentLocation = window.location.pathname;
    //current path is a settings or configuration one
    if (currentLocation.indexOf(SETTINGS_URL_PART) !== -1) {
        futureLocation = SETTINGS_URL_PART;
        history[action](futureLocation + "?contextSelector:switchContext=" + context);
        return;
    }

    if (currentLocation.indexOf(CONFIGURATION_URL_PART) !== -1) {
        if (!userRoles.systemViewer && !userRoles.systemAdministrator)
            futureLocation = SETTINGS_URL_PART;
    } else {
        let findCurrent = _.findIndex(APPROVAL_MENU_ITEMS, (x => {
            return x.url === currentLocation || (x.detailsView && currentLocation.indexOf(x.detailsView.url) !== -1);
        }));
        futureLocation = APPROVAL_MENU_ITEMS[findCurrent].url;
    }

    if (
        currentLocation.indexOf(PROCESS_DETAILS_URL_PART) !== -1 ||
        currentLocation.indexOf(DOCUMENT_DETAILS_URL_PART) !== -1 ||
        currentLocation.indexOf(MY_HISTORY_URL_PART) !== -1
    ) {
        action = 'replace';
    }

    history[action](futureLocation + "?contextSelector:switchContext=" + context);
}

export function navigateToProcessOverview() {
    history.push("/processoverview");
    history.go();
}

export function navigateToTaskOverview() {
    history.push('/taskoverview');
    history.go();
}

export function navigateToDocumentOverview() {
    history.push('/documentoverview');
    history.go();
}

export function navigateToProcessTaskDetail(taskKey) {
    history.push("/processtask/" + taskKey);
    history.go();
}

export function navigateToDocumentOverviewDetails(key) {
    history.push("/document/" + key);
    history.go();
}

export function navigateToWorkflows() {
    history.push("/configuration/workflows");
    history.go();
}

export function navigateToWorkflowDetails(key) {
    history.push("/configuration/workflows/workflow/" + key);
    history.go();
}

export function removeSeachParamsFromUrl() {
    const currentLocation = {...window.location};
    if (currentLocation && currentLocation.search)
        delete currentLocation.search;
    history.push(currentLocation);
    history.go();
}
