/**
 * by default sort by due date (~ have to match id of column from getColumnDefinition)
 * @type {number}
 */
export const DEFAULT_SORT = 6;

/**
 * definitions of columns and their identifiers
 * @type {{documentType: number, numberOfComments: number, supplierName: number, description: number, companyName: number, documentDueDate: number, amount: number}}
 */
export const COLUMNS = {
    documentType: 1,
    numberOfComments: -2,
    supplierName: 3,
    description: 4,
    companyName: 5,
    documentDueDate: DEFAULT_SORT,
    amount: -7,
    activatedDate: -8,
    dueDate: 10
};

const COLUMN_SORTING = {
    documentType: forDocumentTypeColumn,
    numberOfComments: forCommentsColumn,
    supplierName: forSupplierColumn,
    description: forDescriptionColumn,
    companyName: forCompanyColumn,
    documentDueDate: forDocumentDueDateColumn,
    amount: forAmountColumn,
    activatedDate: forActivatedDateColumn,
    dueDate: forDueDateColumn
};

const MAX_DATE = Number.MAX_SAFE_INTEGER;

/**
 * sort tasks using sorting defined for particular column, modifies the tasks argument
 * @param tasks
 * @param sortBy
 * @param columns
 * @return {*}
 */
export function sortTasks(tasks, sortBy) {

    const sortingDefinition = pickProperSorting(sortBy);

    // adding sort direction as argument to the function
    const sortWithDirection = function (f, s) {
        // and we have wrapper, instead of plain task now, so add nesting
        return sortingDefinition(f.task, s.task, sortBy > 0);
    };

    return tasks.sort(sortWithDirection);
}

/**
 * find proper sorting function
 * @param sortBy
 * @return {*}
 */
function pickProperSorting(sortBy) {

    let columnName = Object.keys(COLUMNS).reduce((prev, curr) => {
        if (Math.abs(COLUMNS[curr]) === Math.abs(sortBy)) return curr;
        else return prev;
    }, 'documentDueDate');

    return COLUMN_SORTING[columnName];
}


// ---------------------------------------------------------------
// ---------------- elementary compare functions -----------------
// ---------------------------------------------------------------

/**
 * ascending sort
 * @param nameA
 * @param nameB
 * @return {number}
 */
export function compareString(first, second) {
    const nameA = first ? first.toLowerCase() : '';
    const nameB = second ? second.toLowerCase() : '';
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}

export function compareNumber(first, second) {
    let f = first || 0;
    let s = second || 0;
    return f - s;
}

function compareDocumentType(first, second) {
    return compareNumber(first.documentType, second.documentType);
}

function compareCompany(first, second) {
    return compareString(first.companyName, second.companyName);
}

export function compareAmount(first, second) {
    return compareNumber(first.amount, second.amount);
}

function withDirection(result, ascending) {
    return ascending ? result : -1 * result;
}


function compareId(first, second) {
    return compareNumber(first.id, second.id);
}

export function forDocumentTypeColumn(first, second, ascending) {
    return withDirection(compareDocumentType(first, second), ascending);
}

export function forCommentsColumn(first, second, ascending) {

    let result = withDirection(compareNumber(first.numberOfComments, second.numberOfComments), ascending);

    if (result !== 0) return result;

    result = compareCompany(first, second);

    if (result !== 0) return result;

    result = compareDocumentType(first, second);

    if (result !== 0) return result;

    return compareId(first, second);
}

/**
 * eg 'FROM' from confluence page
 * @param first
 * @param second
 * @return {number}
 */
export function forSupplierColumn(first, second, ascending) {

    // Alphabetically (SupplierName for Invoices and Vouchers, RequesterName for Claims, Leave Requests, Timesheets)
    //TaskView.component: let fromField = task.supplierName ? task.supplierName : task.requesterName;

    let f = first.supplierName ? first.supplierName : first.requesterName;
    let s = second.supplierName ? second.supplierName : second.requesterName;
    let result = withDirection(compareString(f, s), ascending);

    if (result !== 0) return result;

    result = compareCompany(first, second);

    if (result !== 0) return result;

    result = compareDocumentType(first, second);

    if (result !== 0) return result;

    return compareId(first, second);
}

export function forDescriptionColumn(first, second, ascending) {

    let result = withDirection(compareString(first.description, second.description), ascending);

    if (result !== 0) return result;

    result = compareCompany(first, second);

    if (result !== 0) return result;

    result = compareAmount(first, second);

    if (result !== 0) return result;

    return compareId(first, second);
}

export function forCompanyColumn(first, second, ascending) {

    let result = withDirection(compareCompany(first, second), ascending);

    if (result !== 0) return result;

    result = compareDocumentType(first, second);

    if (result !== 0) return result;

    result = compareNumber(first.dueDate, second.dueDate);

    if (result !== 0) return result;

    return compareId(first, second);
}

export function forDocumentDueDateColumn(first, second, ascending) {
    let firstDate = first.documentDueDate || MAX_DATE;
    let secondDate = second.documentDueDate || MAX_DATE;

    let result = withDirection(compareNumber(firstDate, secondDate), ascending);

    if (result !== 0) return result;

    result = compareCompany(first, second);

    if (result !== 0) return result;

    result = compareDocumentType(first, second);

    if (result !== 0) return result;

    return compareId(first, second);
}

export function forActivatedDateColumn(first, second, ascending) {
    let firstDate = first.activatedDate || MAX_DATE;
    let secondDate = second.activatedDate || MAX_DATE;

    let result = withDirection(compareNumber(firstDate, secondDate), ascending);

    if (result !== 0) return result;

    result = compareCompany(first, second);

    if (result !== 0) return result;

    result = compareDocumentType(first, second);

    if (result !== 0) return result;

    return compareId(first, second);
}

export function forDueDateColumn(first, second, ascending) {
    let firstDate = first.dueDate || MAX_DATE;
    let secondDate = second.dueDate || MAX_DATE;

    let result = withDirection(compareNumber(firstDate, secondDate), ascending);

    if (result !== 0) return result;

    result = compareCompany(first, second);

    if (result !== 0) return result;

    result = compareDocumentType(first, second);

    if (result !== 0) return result;

    return compareId(first, second);
}

export function forAmountColumn(first, second, ascending) {
    let result = withDirection(compareAmount(first, second), ascending);

    if (result !== 0) return result;

    return compareId(first, second);
}