import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TransientNotification from './TransientNotification.component';
import "./notifications.scss";
import ErrorDialog from '../popup/ErrorDialog.component';

import translate from '../translations/translations.wrapper.jsx';
import {connect} from 'react-redux';
import {getNotifications} from '../store/application.reducers';
import {hideNotification} from '../notifications/notification.action';


export class NotificationsView extends Component {

    static propTypes = {
        notifications: PropTypes.object,
        hideNotification: PropTypes.func,
        translate: PropTypes.func
    };

    render() {
        const self = this;
        if (Object.keys(this.props.notifications).length === 0) {
            return null;
        } else {

            // if there is error, show the dialog, otherwise show the transient notifications
            // (we don't want to show transient notifications when we need to display error dialog)
            // from discussion with OWR, we can should show only one error dialog at the time
            let error = null;
            let out = [];
            for (let id in this.props.notifications) {
                if (this.props.notifications.hasOwnProperty(id)) {
                    const notification = this.props.notifications[id];
                    //if (notification.type === NOTIFICATION_TYPE.POSITIVE_TRANSIENT) {
                    out.push(notification);
                    /*} else {
                        error = notification;
                        break;
                    }*/
                }
            }

            if (error !== null) {
                return (
                    <ErrorDialog
                        error={error}
                        closeCallback={this.props.hideNotification}
                        translate={this.props.translate}
                    />
                );
            } else {
                return (
                    <ul className={"list-unstyled notification-view ast_notifications_list notifications_bottom"}>
                        {out.map((item) => {
                            return <TransientNotification key={item.id} data={item}
                                                          close={self.props.hideNotification}/>
                        })}
                    </ul>
                );
            }
        }
    }
}

const withTranslations = translate(NotificationsView);

const mapStateToProps = function (store) {
    return {
        notifications: getNotifications(store)
    };
};

export default connect(mapStateToProps, {
    hideNotification
})(withTranslations);