import React from "react";
import { VisibleToEverybody } from "components/popup/PopUp.component";
import Comment from "components/input/Comment.component";
import OKCancelButtons from "components/modals/OKCancelButtons";
import { MODAL_CONTENT_TAB_INDEX } from "utils/constants";
import { UserSearch } from "components/input/UserSearch.component";
import { forwardTask } from "components/modals/modal.actions";
import { useDispatch, useSelector } from "react-redux";
import { ForwardActionData, ForwardProps } from "types/modals";
import { getTaskUserDescription } from "utils/valueFormatter.function";
import { Store } from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import OneButtonModalFooter from "components/modals/OneButtonModalFooter";

const Forward = ({ more, translate, closeModal, loopFocus, errorCallback }: ForwardProps): React.ReactElement => {
    const dispatch = useDispatch();
    const initComment = useSelector((store: Store) => reduxSelectors.getTaskCurrentComment(store));

    const [recipient, setRecipient] = React.useState(null);
    const [comment, setComment] = React.useState(initComment);
    //TODO: this should be automatically removed if we use forms
    const [actionRunning, setActionRunning] = React.useState(false);

    const forwardAction = () => {
        if (comment && recipient && recipient.value) {
            setActionRunning(true);
            const currentTask = more?.tasks[0];
            const description = getTaskUserDescription(currentTask);
            const forwardMessage = translate("popUp.forward.toastMessage", description, recipient.label);
            const data: ForwardActionData = {
                key: currentTask.key,
                comment: comment,
                recipient: recipient.value,
                forwardMessage: forwardMessage,
            };

            if (currentTask)
                dispatch(forwardTask(data,
                    forwardSuccessCallback,
                    forwardErrorCallback));
        }
    };

    const forwardSuccessCallback = () => {
        // TODO: discuss how to handle this...
        //handledTaskCallback(more?.tasks[0]?.key, more.navigate);
        closeModal();
    }
    const forwardErrorCallback = () => {
        setActionRunning(false);
        errorCallback();
    }

    const okEnabled = comment && recipient && !actionRunning;
    return (
        <div>
            <h4 className="modal-title">
                {more?.title || translate("popUp.forward.title")}
            </h4>

            <div className="modal-body">
                <div><span className="text-danger">*</span> {translate("popUp.forward.recipient")}</div>
                <UserSearch tabindex={MODAL_CONTENT_TAB_INDEX}
                            taskId={more.keys}
                            translate={translate}
                            propagateValue={(value: any) => setRecipient(value)}
                            placeholder={translate("userSearch.placeholder")}
                            value={recipient}
                            includeSameUser={true}
                />
                <div className="mt-3">
                    <label htmlFor="comment"><span
                        className="text-danger">* </span> {translate("popUp.forward.commentLabel")}
                    </label>

                    <VisibleToEverybody translate={translate} />

                    <Comment id="comment"
                             inputHint={translate("popUp.forward.commentHint")}
                             translate={translate}
                             tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                             propagateValue={value => {
                                 setComment(value);
                             }}
                             value={comment}
                    />
                </div>
            </div>
            {
                more.isCurrentTaskHandled ?
                    <OneButtonModalFooter tabIndex={MODAL_CONTENT_TAB_INDEX + 1} enabled={true} callback={closeModal}
                                          text={translate("popUp.reload")} loopFocus={loopFocus} /> :
                    <OKCancelButtons
                        tabIndex={MODAL_CONTENT_TAB_INDEX + 2}
                        okEnabled={okEnabled}
                        okCallback={forwardAction}
                        okText={translate("popUp.ok")}
                        cancelEnabled={true}
                        cancelCallback={closeModal}
                        cancelText={translate("popUp.cancel")}
                        loopFocus={loopFocus}
                    />
            }
        </div>
    );
};

export default Forward;