// TODO aren't they the same? because it seems to work same, regardless the GET parameter
/**
 * redirect to ODP login form
 */
export function login() {
    // eslint-disable-next-line
    window.location.href = ENVIRONMENT.ODP_LOGIN_SERVER + "loginPage.xhtml?msgCode=NOTLOGGEDIN&requestedPage=" + encodeURIComponent(ENVIRONMENT.PUBLIC_URL);
}

/**
 * redirect to ODP login form with cancelling of user session
 */
export function logout() {
    // eslint-disable-next-line
    window.location.href= ENVIRONMENT.ODP_LOGIN_SERVER + "loginPage.xhtml?msgCode=LOGOUT&requestedPage=" + encodeURIComponent(ENVIRONMENT.PUBLIC_URL);
}

/**
 * user box contains link to user details in ODP
 * @returns {string}
 */
export function myDetailsUrl() {
    // eslint-disable-next-line
    return ENVIRONMENT.ACCOUNT_SETTINGS_BASE_URL + "?client_id=odp";
}
