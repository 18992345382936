import React from 'react';
import ConfigurationWrapper from "../configuration/ConfigurationWrapper.component";
import "./managers.scss";
import SearchGroupInput from "../input/SearchGroupInput.component";
import withTranslations from "../translations/translations.wrapper.jsx";
import * as Api from "../../utils/api/api";
import {connect} from "react-redux";
import * as reduxSelectors from "../store/application.reducers";
import {userHasAccessRights} from "../../utils/accessRights.function";
import {handleError, insufficientRightsError} from "../../utils/errorHandle.function";
import {canViewConfiguration} from "../menu/Menu.component";

const MANAGER_BY_EMPLOYEE = 'MANAGER_BY_EMPLOYEE';
const EMPLOYEE_BY_MANAGER = 'EMPLOYEE_BY_MANAGER';

class Managers extends ConfigurationWrapper {

    state = {
        searchValue: '',
        page: 1,
        itemsPerPage: 20,
        nearestManagerMode: MANAGER_BY_EMPLOYEE,
        asc: true,
        rows: [],
        isLoading: false,
        hasMore: true
    };

    constructor(props){
        super(props);

        this.scrollable = React.createRef();
        this.updateState = this.updateState.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.onListScroll = this.onListScroll.bind(this);

    }

    componentDidMount() {
        if (!userHasAccessRights(this.props.userData.userRoles, this.props.location) || !canViewConfiguration(this.props.userData.userRoles)) {
            handleError(insufficientRightsError);
        }
        super.componentDidMount();
        this.updateState();
        this.scrollable.current.onscroll = this.onListScroll
    }

    updateState(replace = true) {
        Api.getNearestManagers(this.state.page, this.state.itemsPerPage, this.state.nearestManagerMode, this.state.asc, this.state.searchValue ).then(response => {
                this.setState({
                    page: response.page,
                    total: response.total,
                    records: response.records,
                    rows: replace ? response.rows : [...this.state.rows, ...response.rows]
                });
            },
            error => {
                console.error(error);
            });
    }

    clearSearch() {
        this.setState({
            searchValue: ''
        },
            () => this.updateState());
    }

    inputChange(event){
        const name = event.target.value;
        this.setState({
            searchValue: name,
            page: 1
        }, () => {
            this.updateState();
        });
    }

    renderList(employeeByManager){
        return (
            this.state.rows.map((row, index) => {
                    return (
                        <div key={index} className="list-group-item">
                            { employeeByManager ?
                                <div>
                                    {`${row.managerFirstName} ${row.managerLastName} `}
                                    <span className="text-muted">
                                        { `(${row.managerEmail})`}
                                    </span>
                                </div> :
                                <div>
                                    {`${row.employeeFirstName} ${row.employeeLastName} `}
                                    <span className="text-muted">
                                        { `(${row.employeeEmail})`}
                                    </span>
                                </div>}
                        </div>
                    );
                })
        );
    }

    onManagerClick(mode){
        this.setState({
                nearestManagerMode: mode,
                page: 1
            },
            () => this.updateState())
    }

    onListScroll(){
        const scrollTop = this.scrollable.current.scrollTop;
        const scrollHeight = this.scrollable.current.scrollHeight;
        const offsetHeight = this.scrollable.current.offsetHeight;
        if ((scrollHeight - scrollTop * 1.25 <= offsetHeight) && (this.state.rows.length > 0 && this.state.rows.length < this.state.records)) {
            this.setState({
                page: this.state.page + 1
            }, () => {
                this.updateState(false);
            });
        }
    }

    render(){
        const isManagerByEmployee = this.state.nearestManagerMode === MANAGER_BY_EMPLOYEE;
        return(
            <div id="managers">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="no-border d-flex align-items-center pt-4">
                            {this.props.translate("managers.header")}
                            <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                                  title={this.props.translate("managers.headerInfo")}/>
                        </h3>
                    </div>
                </div>
                <div id="managers-headers-wrapper">
                    <div id="managers-headers">
                        <div className="row">
                            <div className="col-md-6 mb-4 pe-0">
                                <SearchGroupInput placeholder={this.props.translate("managers.search")}
                                                  value={this.state.searchValue}
                                                  onInputChange={this.inputChange}
                                                  onClearInput={this.clearSearch}
                                                  translate={this.props.translate}/>
                            </div>
                        </div>
                        <div className="row" id="managers-buttons">
                            <div className="col-md-12">
                                <div className="btn-toolbar btn-toolbar-primary">
                                    <div className="btn-group">
                                        <button type="button"
                                                className={`btn btn-default ${isManagerByEmployee ? 'active' : ''}`}
                                                onClick={this.onManagerClick.bind(this,MANAGER_BY_EMPLOYEE)}>
                                            {this.props.translate("managers.managerByEmployee")}</button>
                                        <button type="button"
                                                className={`btn btn-default ${!isManagerByEmployee ? 'active' : ''}`}
                                                onClick={this.onManagerClick.bind(this,EMPLOYEE_BY_MANAGER)}>
                                            {this.props.translate("managers.employeesByManager")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row managers-lists managers-lists-header">
                            <div className="col-md-6">
                                <div className="list-group left">
                                    <div className="list-group-item group-title">{!isManagerByEmployee ? this.props.translate("managers.manager") : this.props.translate("managers.employee")}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="list-group right">
                                    <div className="list-group-item group-title">{isManagerByEmployee ? this.props.translate("managers.manager") : this.props.translate("managers.employee")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="managers-scrollable" ref={this.scrollable}>
                    <div className="row managers-lists managers-lists-body">
                        <div className="col-md-6">
                            <div className="list-group left">
                                {this.renderList(!isManagerByEmployee)}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="list-group right">
                                {this.renderList(isManagerByEmployee)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect((store)=>{
    return {
        userData: reduxSelectors.getUsersData(store)
    }
})(withTranslations(Managers));