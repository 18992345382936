import {ADD_NOTIFICATION, REMOVE_NOTIFICATION, LOGGED_OUT_TYPE, DIFFERENT_USER} from '../store/actionTypes';
import * as logger from '../../utils/logger.function';

export const DEFAULT_STATE = {};

export default function notificationReducer (state = DEFAULT_STATE, action) {

    const item = action.data;

    //     id: messageId,
    //     data: data,
    //     type: type,
    //     created: new Date().

    switch (action.type) {
        case ADD_NOTIFICATION:

            if (state[item.id] !== undefined) {
                logger.debug("notification already displayed, ignoring:", item);
                return state;
            } else {
                let partialState = {};
                partialState[ item.id ] = item;
                return Object.assign({}, state, partialState);
            }

        case REMOVE_NOTIFICATION:
            let newState = Object.assign({}, state);
            delete newState[action.id];
            return newState;
        case LOGGED_OUT_TYPE:
        case DIFFERENT_USER:
            return DEFAULT_STATE;
        default:
            return state;
    }
}



export function getNotifications(state) {
    return state;
}
