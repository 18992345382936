import React, {Component} from "react";
import translate from "../../../translations/translations.wrapper.jsx";
import UserProfileTooltip from "../../../tooltip/UserProfileTooltip.component";
import xIcon from "../../../../assets/close-x.svg";


class WorkflowDetailsUsersModal extends Component {
    constructor() {
        super();

        this.onClose = this.onClose.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.visible && typeof window !== 'undefined') {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }

    onClose() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        return this.props.visible ? (
            <div className={"pop-up my-3 workflow-details-users-modal"} tabIndex="-1">
                <div className="pop-up-modal">
                    <h4>
                        {this.props.assignments.length > 99 ? '99+' : this.props.assignments.length} {this.props.translate("taskDetail.approvers")}
                        {this.props.onClose && <span className={'pop-up-close'} onClick={this.onClose}>
                            <img src={xIcon} alt="close"/>
                        </span>}
                    </h4>
                    <ul>
                        {
                            this.props.assignments.map((assignment) => <li key={assignment.userDTO.idUser}>
                                <UserProfileTooltip user={assignment.userDTO} />
                                <div className={"mb-2"}>{assignment.explanations[0]?.action}</div>
                            </li>)
                        }
                    </ul>
                </div>
            </div>
        ) : null;
    }
}

const withTranslations = translate(WorkflowDetailsUsersModal);

export default withTranslations;