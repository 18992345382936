import {
    LOGGED_IN_TYPE,
    PROCESS_ADVANCED_SEARCH_OPEN,
    PROCESS_OVERVIEW_COST_UNITS_LOADED,
    PROCESS_OVERVIEW_FILTER_CHANGED,
    PROCESS_OVERVIEW_INITIALIZE,
    PROCESS_OVERVIEW_LOAD_COMPLETE_ERROR,
    PROCESS_OVERVIEW_LOAD_COMPLETED,
    PROCESS_OVERVIEW_LOAD_MORE,
    PROCESS_OVERVIEW_LOADING,
    PROCESS_OVERVIEW_USER_ROLES_LOADED,
    PROCESS_PREVENT_DATA_RELOAD,
    PROCESS_SAVE_SCROLL_POSITION,
} from "../store/actionTypes";
import {createSelector} from 'reselect';
import {LOADING_STATUS} from 'utils/constants';
import _ from "lodash";
import {getUsersCurrentContextId} from '../usercontext/user.reducer';

export const DEFAULT_STATE = {
    //boolean - to display the advanced search in states expanded/hidden
    advancedSearchOpen: false,
    costUnitsOptions: [],
    filter: {
        costUnits: [], //cost units filter - has the format required for backend
        columns: {}, //top grid filtering
        account: undefined
    },
    loadingStatus: LOADING_STATUS.LOADING,
    //boolean to stop the gird data from refreshing when navigation details -> Process Overview
    preventReload: false,
    //scroll position from top for the Process Overview grid
    scrollTop: 0,
    selectedTasks: [],
    //sorting logic needed for the reactabular grid
    sortingLogic: {},
    tasks: [],
    userRoles: null,
    cursor: null,
    loadingError: null
};
export const getProcessOverviewAsyncLoading = createSelector(
    [getCursor],
    (cursor) => {
        return {cursor};
    }
);

export const getProcessOverviewTasks = createSelector(
    [getTasks, getOverviewUserRoles],
    (tasks, roles) => {

        let value = Object.assign({}, tasks);
        if (roles && tasks?.rows) {
            value.rows = tasks.rows.map(task => {
                let role = _.find(roles, ['actorTypeCode', task.actorType]);
                task.role = role ? role.actorTypeName : '';
                return task;
            });
        }

        return {
            allTasks: value.rows,
            records: tasks?.records,
            page: tasks?.page
        };
    }
);

export default function processOverviewReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case  LOGGED_IN_TYPE:
            const newContextId = getUsersCurrentContextId(action.data);
            if (newContextId !== state.currentContext) {
                partialState = {
                    currentContext: newContextId,
                    filter: Object.assign({}, DEFAULT_STATE.filter),
                    sortingLogic: {},
                    selectedTasks: []
                };
            } else {
                partialState = Object.assign({}, state);
            }
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_INITIALIZE:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.LOADING;
            partialState.cursor = null;
            partialState.scrollTop= 0;
            partialState.tasks = {
                page: 1,
                records: 0,
                rows: [],
            };
            partialState.userRoles = null;
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_LOAD_MORE:
            let oldTasks = Object.assign([], state.tasks.rows);
            // concatenate old rows with new rows
            let allTasks = oldTasks.concat(action.data.rows);
            let newData = Object.assign({}, action.data);
            newData.rows = allTasks;
            newData.records = allTasks.length;
            partialState = {
                tasks: newData,
                cursor: action.data.cursor
            };
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_LOAD_COMPLETED:
            return Object.assign({}, state, {
                tasks: {
                    rows: Object.assign([], state.tasks.rows),
                    records: state.tasks.rows.length
                },
                cursor: action.data.cursor,
                loadingStatus: LOADING_STATUS.DONE,
                loadingError: null
            });

        case PROCESS_OVERVIEW_LOAD_COMPLETE_ERROR:
            return Object.assign({}, state, {
                tasks: {
                    rows: Object.assign([], state.tasks.rows),
                    records: state.tasks.rows.length
                },
                cursor: action.data.cursor,
                loadingError: action.data.error,
            });

        case PROCESS_ADVANCED_SEARCH_OPEN:
            partialState = {
                advancedSearchOpen: action.advancedSearchOpen
            };
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_COST_UNITS_LOADED:
            partialState = {
                costUnitsOptions: action.data
            };
            //map new with old
            if (state.costUnitsOptions.length > 0) {
                let costUnits = [];
                costUnits = partialState.costUnitsOptions.map(costUnit => {
                    let currentValue = _.find(state.costUnitsOptions, (value => value.dimension === costUnit.dimension));
                    if (currentValue && currentValue.selectedValue) {
                        costUnit.selectedValue = currentValue.selectedValue;
                        costUnit.label = currentValue.label;
                    }
                    return costUnit;
                });
                partialState = {
                    costUnitsOptions: costUnits
                };
            }

            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_USER_ROLES_LOADED:
            partialState = Object.assign([], state);
            partialState.userRoles = action.data.userRoles;
            return Object.assign({}, state, partialState);


        case PROCESS_PREVENT_DATA_RELOAD:
            partialState = {
                preventReload: action.data
            };
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_FILTER_CHANGED:
            partialState = {
                tasks: {
                    rows: [],
                    records: 0
                },
                cursor: null,
                loadingStatus: LOADING_STATUS.LOADING,
                filter: action.filter
            };
            return Object.assign({}, state, partialState);

        case PROCESS_OVERVIEW_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                cursor: action.data,
            };
            return Object.assign({}, state, partialState);

        case PROCESS_SAVE_SCROLL_POSITION:
            partialState = {
                scrollTop: action.data
            };
            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}

export function getProcessToggleAdvancedSearch(state) {
    return state.advancedSearchOpen;
}

export function getCostUnitsOptions(state) {
    return state.costUnitsOptions;
}

export function getProcessOverviewFilter(state) {
    return state.filter;
}

export function getOverviewLoadingStatus(state) {
    return state.loadingStatus;
}

export function getOverviewLoadingError(state) {
    return state.loadingError;
}

export function shouldPreventProcessReload(state) {
    return state.preventReload;
}

export function getProcessScrollTop(state) {
    return state.scrollTop;
}

export function getTasks(state) {
    return state.tasks;
}

export function getOverviewUserRoles(state) {
    return state.userRoles;
}

export function getCursor(state) {
    return state.cursor;
}
