import React, { Component } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { DOCUMENT_TYPE, RULE_TYPES, RULE_TYPE } from "utils/constants";
import {
    FindByEmployeeRoleAction,
    OrganisationChartAction,
    ProjectManagerAction,
    CostUnitsAction,
    DepartmentManagerAction,
    ListOfApproversAction,
    ProjectRoleAction,
} from "./StepActions.component";

import {
    getEnabledFeaturesForDocumentType,
    setStepAction,
    setStepCondition,
    setStepConditionByNumber,
} from "./workflowdetails.action";
import * as reduxSelectors from "../store/application.reducers";
import { connect } from "react-redux";

import AmountCondition from "./AmountCondition.Component";
import { DaysCondition } from "./DaysCondition.Component";

//map the action component to the rule types - we should cover all the RULE_TYPES .. key from constants.js
//TODO discuss this with Alban, maybe this is not the brightest solution :)
const COMPONENT_TYPE = {
    BY_EMPLOYEE: FindByEmployeeRoleAction,
    BY_LIST: ListOfApproversAction,
    BY_PROJECT_MANAGER: ProjectManagerAction,
    BY_DEPARTMENT_MANAGER: DepartmentManagerAction,
    BY_COST_UNITS: CostUnitsAction,
    BY_ORGANISATION_CHART: OrganisationChartAction,
    BY_GENERAL_APPROVER: ProjectRoleAction,
};

export class StepRule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRuleType: props.action ? RULE_TYPES[props.action.xtype].translationKey : null,
        };
        this.selectRuleType = this.selectRuleType.bind(this);
        this.changeAmountCondition = this.changeAmountCondition.bind(this);
        this.deleteStepRule = this.deleteStepRule.bind(this);
        this.addRule = this.addRule.bind(this);
    }

    selectRuleType(value) {
        if (RULE_TYPES[value].translationKey !== this.state.selectedRuleType) {
            this.setState({
                selectedRuleType: RULE_TYPES[value].translationKey,
            });

            let action = {
                actionType: value,
                xtype: value,
            };

            let isRuleComplete = (value === RULE_TYPE.BY_PROJECT_MANAGER || value === RULE_TYPE.BY_DEPARTMENT_MANAGER || value === RULE_TYPE.BY_NEAREST_MANAGER);

            this.props.setStepAction(this.props.idStep, this.props.idRule, action, this.props.stepNumber, this.props.ruleNumber, isRuleComplete);
        }
    }

    changeAmountCondition(amount, isComplete = true) {
        if (this.props.idRule !== null && typeof this.props.idRule !== "undefined" && this.props.idStep !== null && typeof this.props.idStep !== "undefined") {
            this.props.setStepCondition(this.props.idStep, this.props.idRule, amount, isComplete);
        } else {
            this.props.setStepConditionByNumber(this.props.stepNumber, this.props.ruleNumber, amount, isComplete);
        }
    }

    componentDidMount() {
        this.props.getEnabledFeaturesForDocumentType(this.props.type,this.props.currentContextId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.action !== this.props.action) {
            this.setState({
                selectedRuleType: this.props.action ? RULE_TYPES[this.props.action.xtype].translationKey : null,
            });
        }
    }

    deleteStepRule() {
        this.props.deleteStepRule(this.props.idRule);
    }

    addRule() {
        this.props.addRule(this.props.idStep);
    }

    render() {
        let { type, translate, action } = this.props;
        let ComponentName = this.state.selectedRuleType ? COMPONENT_TYPE[this.state.selectedRuleType] : null;

        const actions = this.props.features[this.props.type] ? this.props.features[this.props.type].actions : [];

        let menuItems = actions.filter((item) => {
            return item !== "Visma.Approval.Rules.FindByRoleActionWithRequester";
        })
            .map((action, index) => {
                return <Dropdown.Item eventKey={action}
                                      key={index}>
                    {RULE_TYPES[action] ? translate("workflowDetails.rule." + RULE_TYPES[action].translationKey) : translate("workflowDetails.rule.unknown")}
                </Dropdown.Item>;
            });

        return (
            <div className="rule-area">
                <h4 className="no-border mb-3 d-flex flex-row align-items-center">
                    <strong>
                        {this.props.translate("workflowDetails.ruleName") + " " + (this.props.index + 1)}
                    </strong>
                    <span className="mx-3">
                        {translate("workflowDetails.approve", translate("documentTypes." + DOCUMENT_TYPE.asString(type)))}
                    </span>
                    <span
                        className={"ms-auto vismaicon vismaicon-delete vismaicon-dynamic" + (this.props.canDelete ? "" : " disabled")}
                        title={this.props.translate("workflowDetails.deleteRule")}
                        onClick={this.deleteStepRule}
                    />
                </h4>

                <div className="d-flex">
                    <div className="no-side-padding margin-right" id="Rule_Type_Selection">
                        <DropdownButton className="text-start mb-0"
                                        bsPrefix="btn btn-default"
                                        id="rule_type"
                                        onSelect={this.selectRuleType}
                                        title={this.state.selectedRuleType ? translate("workflowDetails.rule." + this.state.selectedRuleType) : ""}>
                            {menuItems}
                        </DropdownButton>
                    </div>
                    {ComponentName &&
                        <div className="ms-4 component">
                            <ComponentName type={type}
                                           action={action}
                                           componentType={RULE_TYPE[this.state.selectedRuleType]}
                                           idStep={this.props.idStep}
                                           idRule={this.props.idRule}
                                           ruleNumber={this.props.ruleNumber}
                                           stepNumber={this.props.stepNumber}
                                           translate={translate} />
                        </div>
                    }
                </div>


                {this.props.type === DOCUMENT_TYPE.LEAVE_REQUEST ?
                    <DaysCondition translate={this.props.translate}
                                   condition={this.props.condition}
                                   changeAmountCondition={this.changeAmountCondition}
                                   stepNumber={this.props.stepNumber}
                                   ruleNumber={this.props.ruleNumber}
                                   idRule={this.props.idRule} /> :
                    <AmountCondition translate={this.props.translate}
                                     condition={this.props.condition}
                                     changeAmountCondition={this.changeAmountCondition}
                                     stepNumber={this.props.stepNumber}
                                     ruleNumber={this.props.ruleNumber}
                                     idRule={this.props.idRule} />}

                {this.props.addRule &&
                    <div>
                        <hr />

                        <button type="button" className="btn btn-primary-tint"
                                onClick={this.addRule}>
                            <span className="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-add-circle" />
                            {this.props.translate("workflowDetails.addNewRule")}
                        </button>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = function(store) {
    return {
        workflowChanged: reduxSelectors.getWorkflowChangedStatus(store),
        features: store.workflowDetails.features,
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
    };
};

const connected = connect(mapStateToProps, {
    setStepAction,
    getEnabledFeaturesForDocumentType,
    setStepCondition,
    setStepConditionByNumber,
})(StepRule);
export default connected;
