import React, {Component} from 'react';

/**
 * @props rootUrl of Help Centre
 * @props widgetUrl of Help Centre
 * @props userConfiguration from startup
 * @props label title
 */
export default class MenuHelp extends Component {

    componentDidMount() {

        // since adding script tag into JSX doesn't work, React is just appending to innerHtml, which isn't executed
        // we have to manually append to DOM

        const props = this.props;
        const onLoad = function () { // callback once we download the script
            let selectors = {
                helpButton: ".vismaicon-help vismaicon-dynamicvismaicon-menu ",
                notificationBadge: ".notificationBadge"
            };

            let options = {
                applicationCode: 'ApprovalCenter',
                companyId: props.userConfiguration.currentContext,
                source: "VismaNETService",
                userLanguageCode: props.userConfiguration.localeString,
                companyCountryCode: props.userConfiguration.countryCode,
                customerId: props.userConfiguration.currentCompany.idCustomer,
                distributorName: props.userConfiguration.distributorName
            };
            window.vismaSupportPanelRootUrl = props.rootUrl;
            // supportPanelWidgetInit is created by supportpanelwidget.js
            // eslint-disable-next-line
            window.supportPanelWidget = supportPanelWidgetInit(selectors, options);
        };

        // add into DOM
        let js = document.createElement("script");
        js.type = "text/javascript";
        js.src = props.widgetUrl;
        js.onload = onLoad;
        document.body.appendChild(js);
    }

    openPanel() {
        window.supportPanelWidget.open();
        return false;
    }

    render() {
        return (
            <li className="icon" title={this.props.label} onClick={this.openPanel}>
                <a className="button-help text-transparent" href={"#"}>
                    <span className="vismaicon vismaicon-menu vismaicon-dynamic vismaicon-help"/>
                    <span className="notificationBadge badge"/>
                    {this.props.label}
                </a>
            </li>
        );
    }
}

