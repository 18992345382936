/**
 * get current version of application
 * extracted into own function, so it can be easily mocked in unit tests
 * @returns {string}
 */
export function getCurrentVersion() {
    console.log(window.ENVIRONMENT)
    // eslint-disable-next-line
    return window.ENVIRONMENT.VERSION;

}

export function getApprovalURL() {

    // eslint-disable-next-line
    return window.ENVIRONMENT.PUBLIC_URL;

}



