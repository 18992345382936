import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PopUp} from './PopUp.component';
import * as Api from 'utils/api/api';
import {TASK_ACTIONS} from 'utils/constants';
import ErrorDialog from './ErrorDialog.component';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import {removeSelectedDocument, getTaskDetails} from '../taskdetails-remake/taskDetails.action';
import translate from '../translations/translations.wrapper.jsx';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'delete' single (in) - task details
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class DeleteAttachmentPopup extends PopUp {
    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.focusButton = null;
        this.state = {
            actionRunning: false,
            displayError: null
        }
    }

    ok() {

        const self = this;
        this.setState({actionRunning: true});
        const currentTask = this.props.tasks[0];

        //TODO implement delete attachment
        Api.deleteAttachment(currentTask.key, currentTask.attachmentCollectionValues.latestAttachmentCollectionGuid, this.props.selected.id).then(function (response) {
                self.props.removeSelectedDocument();
                self.props.getTaskDetails(currentTask.key);
                self.props.showNotification(self.props.translate("popUp.deleteAttachment.hasBeenRemoved"));
                self.props.closeCallback();
            },
            function (error) {
                self.setState({displayError: error});
            }
        );
    }

    componentDidMount() {
        super.componentDidMount();
        this.focusFirst();
    }

    focusFirst() {
        this.focusButton?.focus();
    }

    buildContent() {
        return (
            this.state.displayError ? <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => {
                        this.setState({actionRunning: false, displayError: null});
                        this.props.closeCallback();
                    }}
                    translate={this.props.translate}
                /> :
                <Fragment>
                    <div className="modal-body ast_delete_attachment">
                        <p>{this.props.translate("popUp.deleteAttachment.message")}</p>
                    </div>
                    <div className="modal-footer">
                        <div className="float-right">
                            <button className="btn btn-default btn-margin-right"
                                    onClick={this.ok}
                                    tabIndex={TAB_INDEX}
                            >{this.props.translate("popUp.deleteAttachment.deleteButton")}</button>
                            <button className="btn btn-default"
                                    onBlur={this.focusFirst}
                                    onClick={this.props.closeCallback}
                                    ref={(input) => {
                                        this.focusButton = input;
                                    }}
                                    tabIndex={TAB_INDEX + 1}>
                                {this.props.translate("popUp.deleteAttachment.keepButton")}
                            </button>
                        </div>
                    </div>
                </Fragment>
        );
    }

    getType() {
        return TASK_ACTIONS.DELETE_ATTACHMENT;
    }

    getClasses() {
        return "modal modal-help";
    }

}

DeleteAttachmentPopup.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
    showNotification: PropTypes.func, // to show transient notification
};

const mapStateToProps = function (store) {
    return {
        selected: reduxSelectors.getSelectedDocument(store)
    };
};
const withTranslations = translate(DeleteAttachmentPopup);
const connected = connect(mapStateToProps, {
    removeSelectedDocument,
    getTaskDetails
})(withTranslations);

export default connected;

//export default DeleteAttachmentPopup;