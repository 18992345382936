import React from 'react';
import {TIME_RULE_TYPES} from 'utils/constants';
import NumericInput from 'react-numeric-input';
import {DropdownButton, Dropdown, FormGroup} from "react-bootstrap";

export const DaysCondition = (props) => {

    let changeLimit = function (value) {
        let newAmount = Object.assign({}, props.condition);
        newAmount.limit = value > 0 ? value : 1;
        props.changeAmountCondition(newAmount);
    };

    let changeLimitHigh = function (value) {
        let newAmount = Object.assign({}, props.condition);
        newAmount.limitHigh = value > 0 ? value : 1;
        let isComplete = newAmount.limitLow !== null && newAmount.limitLow !== undefined;
        props.changeAmountCondition(newAmount, isComplete);
    };

    let changeLimitLow = function (value) {
        let newAmount = Object.assign({}, props.condition);
        newAmount.limitLow = value > 0 ? value : 1;
        let isComplete = newAmount.limitHigh !== null && newAmount.limitHigh !== undefined;
        props.changeAmountCondition(newAmount, isComplete);
    };

    let changeLimitType = function (value) {
        let newAmount = Object.assign({}, props.condition);
        newAmount.xtype = value;
        newAmount.conditionType = value;
        if (value === TIME_RULE_TYPES["Visma.Approval.Rules.NumberOfDaysConditionBetween"].xType) {
            if (newAmount.limit)
                delete newAmount.limit;
            newAmount.limitHigh = undefined;
            newAmount.limitLow = undefined;
        } else {
            if (newAmount.limitHigh)
                delete newAmount.limitHigh;
            if (newAmount.limitLow)
                delete newAmount.limitLow;
            newAmount.limit = undefined;
        }

        props.changeAmountCondition(newAmount, false);
    };

    let toggleAmountCondition = function (event) {
        if (event.target.checked) {
            changeLimitType("Visma.Approval.Rules.NumberOfDaysConditionBelow");
        } else {
            let newAmount = Object.assign({}, props.condition);
            newAmount = null;
            props.changeAmountCondition(newAmount);
        }

    };

    let hasAmountConditionSet = props.condition && TIME_RULE_TYPES[props.condition.conditionType] !== undefined;
    let isSimpleLimit;

    if (hasAmountConditionSet) {
        isSimpleLimit = props.condition.conditionType !== TIME_RULE_TYPES["Visma.Approval.Rules.NumberOfDaysConditionBetween"].xType;
    }

    return (
        <div>
            {props.isStepLevel ?
                <span>
                    <div className="mt-3"/>
                    <span className="active-slider pull-right">
                        <span className="me-2">{props.translate("workflowDetails.stepCondition")}</span>
                        <label className="switch switch-label">
                            <input type="checkbox"
                                   checked={hasAmountConditionSet} onChange={toggleAmountCondition}/>
                             <span className={"togglemark " + (hasAmountConditionSet ? "" : " disabled")}/>
                        </label>
                    </span>
                </span>

                : <div className="checkbox">
                    <input type="checkbox"
                           id={`days-condition-checkbox-step${props.stepNumber}-rule${props.idRule || props.ruleNumber}`}
                           checked={hasAmountConditionSet}
                           onChange={toggleAmountCondition}/>
                    <label
                        htmlFor={`days-condition-checkbox-step${props.stepNumber}-rule${props.idRule || props.ruleNumber}`}>
                        {props.translate("workflowDetails.daysCondition")}
                    </label>
                </div>
            }
            {hasAmountConditionSet &&
                <FormGroup className={"d-inline-flex"}>
                    <DropdownButton className="text-start"
                                    bsPrefix="btn btn-default"
                                    id="amount_type"
                                    onSelect={changeLimitType}
                                    title={props.translate("workflowDetails." + TIME_RULE_TYPES[props.condition.conditionType].translationKey)}>

                        {Object.keys(TIME_RULE_TYPES).map((key, index) => {
                            return <Dropdown.Item eventKey={key} key={index}>
                                {props.translate("workflowDetails." + TIME_RULE_TYPES[key].translationKey)}
                            </Dropdown.Item>
                        })}


                    </DropdownButton>

                    {isSimpleLimit ?
                            <NumericInput value={props.condition.limit}
                                          onChange={changeLimit}
                                          min={1} max={99} step={1} size={2}
                            /> :
                        (<div className="compound-numeric-condition">
                                <NumericInput value={props.condition.limitLow}
                                              onChange={changeLimitLow}
                                              min={1} max={99} step={1} size={2}/>

                                <span className="margin-left margin-right numeric-condition-txt"> {props.translate("generic.and")} </span>

                                <NumericInput value={props.condition.limitHigh}
                                              onChange={changeLimitHigh}
                                              min={1} max={99} step={1} size={2}/>

                                <span className="margin-left numeric-condition-txt"> {props.translate("generic.workingDays")} </span>
                            </div>
                        )
                    }
                </FormGroup>
            }
        </div>

    )
};