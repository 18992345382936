import React from 'react';
import PropTypes from 'prop-types';
import * as Api from 'utils/api/api.js';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import Comment from '../input/Comment.component';
import UserSearch from '../input/UserSearch.component'
import {TASK_ACTIONS} from 'utils/constants';
import ErrorDialog from './ErrorDialog.component';
import {replacePlaceholderInMessage, getTaskUserDescription} from 'utils/valueFormatter.function';
import translate from '../translations/translations.wrapper.jsx';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

export class RequestReviewPopup extends PopUp {
    constructor(props) {
        super(props);

        this.state = {
            comment: '',
            recipient: undefined,
            actionRunning: false,
            displayError: null,
            refresh: null
        };

        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    focusFirst() {
        this.setState({
            refresh: true
        });
    }

    ok() {
        const self = this;

        if (this.state.comment && this.state.recipient && this.state.recipient.value) {
            this.setState({actionRunning: true});

            const key = this.props.tasks[0].key;
            Api.requestReviewTask(key, this.state.recipient.value, this.state.comment).then(function (response) {
                    const withPlaceholder = replacePlaceholderInMessage(response.message, getTaskUserDescription(self.props.tasks[0]));
                    self.props.showNotification(withPlaceholder);
                    self.props.handledTaskCallback(key, self.props.navigate);
                    self.props.closeCallback();
                },
                function (error) {
                     self.setState({displayError: error});
                });
        }

    }

    buildContent() {
        const okEnabled = this.state.comment && this.state.recipient && !this.state.actionRunning;

        return (
            <div className="ast_request_review">
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({actionRunning: false, displayError: null})}
                    translate={this.props.translate}
                />}
                <div className="modal-body">
                    <div className="margin-bottom-12">
                        <div>
                            <span className="text-danger">* </span>
                            {this.props.translate("popUp.sendforreview.reviewer")}
                        </div>
                        <UserSearch ref="recipientInput" propagateValue={value => this.setState({recipient: value})}
                                    tabindex={TAB_INDEX}
                                    refresh={this.state.refresh}
                                    taskId={this.props.tasks[0].key}
                                    ariaLabel={this.props.translate("popUp.sendforreview.reviewer")}
                                    placeholder={this.props.translate("userSearch.placeholder")}
                                    value={this.state.recipient}
                                    translate={this.props.translate}
                                    releaseFocus={()=>{this.setState({refresh:false})}}/>
                    </div>
                    <div>

                        <label htmlFor="comment"><span
                            className="text-danger">* </span> {this.props.translate("popUp.sendforreview.commentLabel")}
                        </label>
                    </div>
                    <VisibleToEverybody translate={this.props.translate}/>
                    <Comment inputHint={this.props.translate("popUp.sendforreview.commentHint")}
                             translate={this.props.translate}
                             tabIndex={TAB_INDEX+1}
                             id="comment"
                             propagateValue={value => this.setState({comment: value,refresh:false})}
                             value={this.state.comment}
                    />
                </div>
                <div className="modal-footer">
                    {this.props.isCurrentTaskHandled ?
                        <div className="float-left">
                            <button
                                className="btn btn-primary btn-margin-right "
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 6}>{this.props.translate("popUp.reload")}
                            </button>
                        </div> :
                        <div className="float-right">
                            <button className="btn btn-primary btn-margin-right"
                                    onClick={this.ok}
                                    tabIndex={TAB_INDEX + 2}
                                    disabled={!okEnabled}
                            >{this.props.translate("popUp.ok")}</button>
                            <button className="btn btn-default"
                                    onClick={this.props.closeCallback}
                                    onBlur={this.focusFirst}
                                    tabIndex={TAB_INDEX + 3}
                            >{this.props.translate("popUp.cancel")}</button>
                        </div>
                    }
                </div>
            </div>

        );
    }

    getType() {
        return TASK_ACTIONS.SEND_FOR_REVIEW;
    }

}

RequestReviewPopup.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
    showNotification: PropTypes.func, // to show transient notification
    handledTaskCallback: PropTypes.func,
    navigate:PropTypes.func,
};

const withTranslations = translate(RequestReviewPopup);
export default withTranslations;