import React, {Component} from 'react';
import {connect} from 'react-redux';
import PageLayout from '../pagelayout/PageLayout.component.jsx';
import KeepAlive from './KeepAlive.component';
import LanguageSwitcher from '../translations/LanguageSwitcher.component';
import {getVersion} from '../store/application.reducers';
import NotificationsView from '../notifications/NotificationsList.component';
import Wootric from "utils/Wootric.component";
import WebSocketConnection from "./WebSocketConnection.component";
/**
 * root of user's context (loading and verifying of it is in the parent)
 */
export class UserContext extends Component {

    render() {

        return (
            <div className={"context"}>
                <NotificationsView/>
                <KeepAlive/>
                <LanguageSwitcher/>
                <PageLayout {...this.props} />
                <Wootric/>
                <WebSocketConnection/>
            </div>
        );

    }
}

const mapStateToProps = function (store) {
    return {
        // data after app start to pass to children, so they don't need to connect to store themselves
        version: getVersion(store)
    };
};


export default connect(mapStateToProps)(UserContext);