import {
    ROLE_NAME_EXCEPTIONS_LOADED
} from "../store/actionTypes";

export const DEFAULT_STATE = {
    items: []
};

export default function exceptionsReducer(state = DEFAULT_STATE, action) {
    let partialState;

    switch (action.type) {
        case ROLE_NAME_EXCEPTIONS_LOADED:
            partialState = { ...state };
            partialState.items = action.data;

            return partialState;

        default:
            return state;
    }
}