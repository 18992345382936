import {areTaskActionsRunning} from "../store/application.reducers";
import * as Api from "../../utils/api/api";
import {handleError} from "../../utils/errorHandle.function";
import {MY_TASKS_ADD, MY_TASKS_REMOVED, TASKS_HANDLED_IN_BACKGROUND} from "../store/actionTypes";
import store from "../store/Store.component";

export function taskListAdd(taskId) {
    return {
        type: MY_TASKS_ADD,
        data: taskId
    };
}

export function taskListRemove(taskId) {
    return {
        type: MY_TASKS_REMOVED,
        data: taskId
    };
}

export function tasksHandledInBackground(taskId) {
    return {
        type: TASKS_HANDLED_IN_BACKGROUND,
        data: taskId
    };
}

export function taskAddChange(taskId) {
    return function (dispatch) {
        const state = store.getState();
        let areActionsRunning = areTaskActionsRunning(state);
        if (!areActionsRunning) {
            Api.getListOfTasks(taskId).then(
                function (response) {
                    dispatch(taskListAdd(response));
                }).catch(error => {
                handleError(error);
            });
        }
    }
}

export function taskRemovedChange(taskId) {
    return function (dispatch) {
        const state = store.getState();
        let areActionsRunning = areTaskActionsRunning(state);
        // do not update when a dialog is visible
        if (!areActionsRunning) {
            dispatch(taskListRemove(taskId));
        } else {
            dispatch(tasksHandledInBackground(taskId));
        }
    }
}