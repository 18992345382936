import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PopUp} from './PopUp.component';
import "./ErrorDialog.component.scss";
import {mapErrorMessage} from '../../utils/errorHandle.function';
/**
 * display some error (passed in props), it has only close button
 */
export default class ErrorDialog extends PopUp {

    static propTypes = {
        closeCallback: PropTypes.func, //callback for closing the popup
        error: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.buildContent = this.buildContent.bind(this);
    }

    getTitle() {
        if (this.props.error.errorTitle)
            return this.props.error.errorTitle;

        if (this.props.error.data && this.props.error.data.errorTitle)
            return this.props.error.data.errorTitle;

        return this.props.translate("errorPage.serverError.title");
    }

    getClasses() {
        return "overflow-modal modal modal-error";
    }


    buildContent() {
        const errorMessage = mapErrorMessage(this.props.error);
        // {
        //     errorCode : 309
        //     errorMessages : "Tjänsten är inte tillgänglig"
        //     errorTitle : "Tjänsten kan inte nås. Försök igen senare eller kontakta Support om problemet kvarstår."
        //     errorType:  "ERROR"
        // }
         return (
            <Fragment>
                <div className="modal-body">
                    <p><span aria-hidden="true"
                             className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error pull-left"/></p>
                    <p>{errorMessage}</p>
                </div>
                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary"
                                onClick={this.props.closeCallback}
                        >{this.props.translate("popUp.cancel")}</button>
                    </div>
                </div>
            </Fragment>

        );
    }

    getType() {
        return "error";
    }

}
