import React from "react";
import {formatDate} from "utils/valueFormatter.function";

export const PreApprovedDetails = (props) => {

    let hasPaymentDetails = Boolean(props.paymentDetails && props.paymentDetails.date);
    let preApprovedBy = hasPaymentDetails && props.paymentDetails.preApprovedBy ? props.paymentDetails.preApprovedBy : null;
    let preApproveDate = hasPaymentDetails && props.paymentDetails.date ? props.paymentDetails.date : null;

    return (
        <span>
            <div className="document-data-field third">
                <div className="document-data-field-label">
                    {props.translate("documentData.paymentPreApproved")}
                </div>
                <div className="document-data-field-value">
                    {props.translate("boolean." + hasPaymentDetails)}
                </div>
            </div>

            {preApprovedBy &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {props.translate("documentData.preApprovedBy")}
                    </div>
                    <div className="document-data-field-value">
                        {preApprovedBy.firstName} {preApprovedBy.lastName}
                    </div>
                </div>
            }
            {preApproveDate &&
                <div className="document-data-field third" >
                    <div className="document-data-field-label">
                        {props.translate("documentData.preApprovedWhen")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(preApproveDate)}
                    </div>
                </div>
            }
        </span>
    );
};
