import React, {Fragment} from 'react';
import {PopUp} from './PopUp.component';
import UserSearch from '../input/UserSearch.component';
import * as Api from '../../utils/api/api';
import {connect} from 'react-redux';
import translate from '../translations/translations.wrapper.jsx';
import * as reduxSelectors from '../store/application.reducers';
import DateFilter from '../input/CalendarInput.component';
import moment from 'moment';
import "./AddSubstitutePopup.scss";
import {fixedMenuStyle} from "../../utils/reactSelectTheme";

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;
const ADD_SUBSTITUTE = "addSubstitute";
const EDIT_SUBSTITUTE = "editSubstitute";

const FROM = "from";
const TO = "to";

export class AddSubstitute extends PopUp {
    constructor(props) {
        super(props);

        this.state = this.setInitialState(props);

        this.buildContent = this.buildContent.bind(this);
        this.buildSubstitute = this.buildSubstitute.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.deactivateSubstitute = this.deactivateSubstitute.bind(this);

        this.focusFirst = this.focusFirst.bind(this);
        this.getToday = this.getToday.bind(this);
        this.getTomorrow = this.getTomorrow.bind(this);
        this.isSaveEnabled = this.isSaveEnabled.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.mapExistingSubstitute = this.mapExistingSubstitute.bind(this);
        this.ok = this.ok.bind(this);
        this.saveSubstitute = this.saveSubstitute.bind(this);
        this.setApprover = this.setApprover.bind(this);
        this.setNoEndDate = this.setNoEndDate.bind(this);
        this.setSubstitute = this.setSubstitute.bind(this);
        this.validateSubstitute = this.validateSubstitute.bind(this);

        this.from = React.createRef();
        this.to = React.createRef();
    }

    setInitialState(props) {
        //common state for edit and add
        let initial = {
            disableSave: false,
            noEndDate: false,
            focusApprover: false,
            focusSubstitute: false,
            errorMessage: null,
            warningMessage: null,
            version: 0
        };
        //edit mode so fill in the data from the props
        if (props.edit) {
            initial.approver = {
                value: props.edit.userId,
                //we have no labels for the user search, this should be name + email
                label: props.edit.userName
            };
            initial.substitute = {
                value: props.edit.substituteId,
                label: props.edit.substituteName
            };
            initial.version = props.edit.version;
            initial.from = moment(props.edit.from);
            if (!props.edit.to && props.edit.from) {
                initial.to = props.edit.to;
                initial.noEndDate = true;
            } else {
                initial.to = moment(props.edit.to);
            }
        } else {
            let emptyUser = {
                value: undefined,
                label: undefined
            };
            initial.approver = Object.assign({}, emptyUser);
            initial.substitute = Object.assign({}, emptyUser);
            initial.from = this.getToday();
            initial.to = this.getToday();
        }

        return initial;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    buildSubstitute(active = true) {
        let fromDate = new Date(this.state.from).setHours(12);
        fromDate = new Date(fromDate).toISOString().substring(0, 10);

        let result = {
            from: fromDate,
            substituteName: this.state.substitute.label,
            userName: this.state.approver.label,
            substituteId: this.state.substitute.value,
            userId: this.state.approver.value,
            active: active,
            version: this.state.version
        };

        if (!this.state.noEndDate) {
            let toDate;
            toDate = new Date(this.state.to).setHours(12);
            toDate = new Date(toDate).toISOString().substring(0, 10);

            result.to = toDate;
        }
        return result;
    }

    changeDate(val, type) {
        const fromDate = type === FROM ? new Date(val) : new Date(this.from.current.getValue());
        let toDate = (type === TO) ? new Date(val) : null;

        //to can be NULL -> it's indefinitely
        if (type !== TO && this.to.current)
            toDate = new Date(this.to.current.getValue());


        const datedValue = new Date(val);
        let dateValue = datedValue instanceof Date && !isNaN(datedValue.valueOf()) ? datedValue : this.getToday();
        let newState = Object.assign({}, this.state);

        if (toDate && (fromDate.getTime() > toDate.getTime())) {
            if (type === TO) {
                newState.from = moment(dateValue).format('YYYY-MM-DD');
            } else {
                newState.to = moment(dateValue).format('YYYY-MM-DD');
            }
        }

        newState[type] = moment(dateValue).format('YYYY-MM-DD');
        this.setState({...newState}, () => {
            this.validateSubstitute();
        });
    }

    deactivateSubstitute() {
        this.saveSubstitute(false);
    }

    focusFirst() {
        this.setState({
            focusApprover: true
        });
    }

    onBlur() {
        this.setState({
            focusApprover: false
        }, () => {
            this.focusFirst();
        });
    }

    getToday() {
        return new Date().toISOString().substring(0, 10);
    }

    getTomorrow() {
        let today = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        return tomorrow.toISOString().substring(0, 10);
    }

    isSaveEnabled() {
        return !(this.state.disableSave || this.state.substitute.value === undefined || this.state.approver.value === undefined);
    }

    mapExistingSubstitute(substitute) {
        let response = {
            from: moment(substitute.from).valueOf() < moment().valueOf() ? moment() : substitute.from,
            to: moment(substitute.to).valueOf() < moment().valueOf() ? moment() : substitute.to,
            substitute: {
                value: substitute.substituteId,
                label: substitute.substituteName
            },
            version: substitute.version
        };

        if (!substitute.to && substitute.from !== null) {
            response.noEndDate = true;
        }

        if (substitute.substituteMessage) {
            response.warningMessage = substitute.substituteMessage.message;
        } else {
            response.warningMessage = null;
        }

        this.setState({...response}, () => {
            this.validateSubstitute();
        });

    }

    ok() {
        this.saveSubstitute();
    }

    //@param active
    saveSubstitute(active = true) {
        let result = this.buildSubstitute(active);

        Api.saveSubstitute(result).then(response => {
                if (this.props.edit)
                    this.props.closeCallback();
                else
                    this.props.saveCloseCallback();
            },
            error => {
                this.setState({
                    errorMessage: error.errorMessages
                });
            });
    }

    setApprover(value) {
        //check if this user already was a substitute assigned (active or not and fill that result in)
        Api.getSubstitute(value.value).then(response => {
            if (response.substituteId !== 0) {
                this.mapExistingSubstitute(response);
            } else {
                this.setState({
                    substitute: {
                        value: undefined,
                        label: ""
                    },
                    errorMessage: null,
                    disableSave: true
                });
            }
        });

        this.setState({approver: value, focusApprover: false});
    }

    setSubstitute(value) {
        this.setState({substitute: value, warningMessage: null}, () => {
            this.validateSubstitute();
        });
    }

    setNoEndDate(event) {
        let hasNoEndDate = event.target.checked;
        let toValue = null;

        // must set a date after the TO date when re-activating the FROM date
        if (!hasNoEndDate) {
            let newTo = new Date(this.state.from);
            newTo = newTo.setDate(newTo.getDate() + 1);
            newTo = new Date(newTo).setHours(12, 0, 0, 0);
            toValue = new Date(newTo).toISOString().substring(0, 10);
        }

        this.setState({
            to: toValue,
            noEndDate: hasNoEndDate
        }, () => {
            this.validateSubstitute();
        })
    }

    validateSubstitute() {
        let newSubstitute = this.buildSubstitute();
        Api.validateSubstitute(newSubstitute).then(response => {
            let newState = Object.assign({}, this.state);

            newState.disableSave = false;
            newState.errorMessage = null;

            if (response.substituteMessage) {
                newState.warningMessage = response.substituteMessage.message;
            }

            this.setState({...newState});

        }, error => {
            this.setState({
                disableSave: true,
                errorMessage: error.errorMessages
            });
        });
    }


    buildContent() {
        return (
            <Fragment>
                <div className="modal-body" id="add_substitute_pop_up" aria-modal="true">
                    <div>
                        <span> {this.props.translate("popUp.addSubstitute.approver")}</span>
                    </div>
                    <UserSearch
                        ref={htmlInput => {
                            this.approverInput = htmlInput
                        }}
                        includeSameUser={true}
                        refresh={this.state.focusApprover}
                        translate={this.props.translate}
                        tabindex={TAB_INDEX}
                        placeholder={this.props.translate("popUp.addSubstitute.name")}
                        propagateValue={this.setApprover}
                        styles={fixedMenuStyle}
                        value={this.state.approver}
                    />
                    <div className="mt-4">
                        <span> {this.props.translate("popUp.addSubstitute.substitute")}</span>
                    </div>
                    <UserSearch
                        ref={htmlInput => {
                            this.substituteInput = htmlInput
                        }}
                        includeSameUser={true}
                        disabled={this.state.approver.value === undefined}
                        tabindex={TAB_INDEX + 2}
                        placeholder={this.props.translate("popUp.addSubstitute.name")}
                        propagateValue={this.setSubstitute}
                        translate={this.props.translate}
                        value={this.state.substitute}
                        styles={fixedMenuStyle}
                    />
                    {this.state.warningMessage &&
                        <WarningSmall text={this.state.warningMessage}/>
                    }
                    {this.state.errorMessage &&
                        <ErrorSmall text={this.state.errorMessage}/>
                    }

                    <div className="row mt-4 mx-0">
                        <div className="col-md-4 px-0">
                            <div>
                                {this.props.translate("settings.substitute.from")}
                            </div>
                            <div>
                                <DateFilter
                                    ref={this.from}
                                    style="inPopup"
                                    translate={this.props.translate}
                                    userLanguage={this.props.userLanguage}
                                    onBlur={this.calendarBlur}
                                    format={this.props.dateFormat}
                                    minDate={moment()}
                                    isDisabled={this.state.approver.value === undefined}
                                    selectedValue={moment(this.state.from).valueOf() < moment().valueOf() ? moment() : this.state.from}
                                    onChange={(value) => this.changeDate(value, FROM)}
                                />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div>
                                {this.props.translate("settings.substitute.to")}
                            </div>
                            {this.state.noEndDate ?
                                (<span>{this.props.translate("substitutes.indefinitely")}</span>) :
                                (<div className={(this.state.noEndDate ? "no-end" : "")}>
                                    <DateFilter
                                        ref={this.to}
                                        style="inPopup"
                                        translate={this.props.translate}
                                        userLanguage={this.props.userLanguage}
                                        onBlur={this.calendarBlur}
                                        format={this.props.dateFormat}
                                        minDate={moment()}
                                        isDisabled={this.state.approver.value === undefined}
                                        selectedValue={this.state.noEndDate ? moment(null) : moment(this.state.to).valueOf() < moment().valueOf() ? moment() : this.state.to}
                                        onChange={(value) => this.changeDate(value, TO)}
                                    />
                                </div>)
                            }
                        </div>

                        <div className="col-md-4 mt-4">
                            <div className="checkbox">
                                <input type="checkbox"
                                       id="noEndDate"
                                       onChange={this.setNoEndDate}
                                       disabled={this.state.approver.value === undefined}
                                       checked={this.state.noEndDate}/>
                                <label className="form-check-label" htmlFor="noEndDate">
                                    {this.props.translate("popUp.addSubstitute.noEndDate")}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="float-start">
                        {this.props.edit &&
                            <button className="btn btn-default"
                                    onClick={this.deactivateSubstitute}
                                    tabIndex={TAB_INDEX + 4}>
                                {this.props.translate("popUp.editSubstitute.stopNow")}
                            </button>
                        }
                    </div>

                    <div className="float-end">
                        <button
                            className="btn btn-primary btn-margin-right"
                            onClick={this.ok}
                            disabled={!this.isSaveEnabled()}
                            tabIndex={TAB_INDEX + 2}> {this.props.translate("popUp.ok")
                        }</button>
                        <button className="btn btn-default"
                                onBlur={this.onBlur}
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 3}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }

    getType() {
        return this.props.edit ? EDIT_SUBSTITUTE : ADD_SUBSTITUTE;
    }

}

const withTranslations = translate(AddSubstitute);
const mapStateToProps = function (store) {
    return {
        userLanguage: reduxSelectors.getLanguage(store),
        dateFormat: reduxSelectors.getDateFormatForLanguage(store)
    }
};

const connected = connect(mapStateToProps, {})(withTranslations);

export default connected;


export const ErrorSmall = ({text}) => {
    return (
        <div className="alert alert-sm alert-error col-md-12 mt-4" role="alert">
            <div>{text}</div>
            <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"/>
        </div>
    )
};

export const WarningSmall = ({text}) => {
    return (
        <div className="alert alert-sm alert-warning col-md-12 mt-4" role="alert">
            <div>{text}</div>
            <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-warning"/>
        </div>
    )
};
