import React from 'react';
import withTranslations from '../translations/translations.wrapper.jsx';
import './ErrorPage.scss';
import {Link} from "react-router-dom";


/**
 * @param props
 *    .errorType ~ string, used for translation keys and css class for image
 *    .translate ~ function, used for translating
 *    .msg ~ string, optional, if provided, it will be used for error detail in the translated string
 * @return react component
 */
const ErrorPageRaw = (props) => {

    const translationKey = props.msg ?  "errorPage." + props.errorType+".msgWithError" : "errorPage." + props.errorType+".msg";
    const msg = props.translate(translationKey, props.msg);

    const onReload = () => {
        window.location.reload();
    }

    return (
        <div id="error-message-container">
            <span className={"error-image " + props.errorType} />
            <div className="row">
                <div className="col-md-4">
                    <div className="error-title">{props.translate("errorPage." + props.errorType+".title")}</div>
                    <div className="error-body">{msg}</div>
                    {props.errorType === 'serverError' ?
                        <div>
                            <button type="button"
                                    className="btn btn-primary"
                                    onClick={onReload}>
                                {props.translate("errorPage.refresh")}
                            </button>
                        </div> :
                        <Link to='/'>
                            <button type="button"
                                    className="btn btn-primary">
                                {props.translate("errorPage.goToHomepage")}
                            </button>
                        </Link>}
                </div>
            </div>
        </div>
    );
};

const ErrorPageWithTranslations = withTranslations(ErrorPageRaw);

/**
 * for anyone who wants to use this class for error reporting
 *
 * if props.translate is provided, it will be used for localisation
 * @param props
 */
export const LocalisedErrorPage = (props) => {
    if(props.translate) {
        return <ErrorPageRaw {...props} />
    } else {
        return <ErrorPageWithTranslations {...props} />
    }
};

// default #404 page
export const NotFound = (props) => {
    return ( <LocalisedErrorPage {...props} errorType="notFound" />);
};

// default error reporting when 'page content component' fails to load necessary data to show anything
export const ServerError = (props) => {
    return ( <LocalisedErrorPage {...props} errorType="serverError" />);
};

/**
 * error page when we're unable to load /startup data
 * @param props
 * @return react component
 * @constructor
 */
export const WithoutStartup = (props) => {
    return ( <LocalisedErrorPage errorType="serverDown" {...props} />);
};




