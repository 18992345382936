import {
    ACTIVE_COUNTER_CHNAGED,
    CHANGE_DOCUMENT_KEY,
    DOCUMENT_OVERVIEW_DOCTYPE_CHANGED,
    DOCUMENT_OVERVIEW_DOCTYPE_CHANGED_TO_DEFAULT,
    DOCUMENT_OVERVIEW_LOADED,
    DOCUMENT_OVERVIEW_LOADING,
    DOCUMENT_OVERVIEW_SORT_COLUMN_CHANGED,
    DOCUMENT_OVERVIEW_TASK_SELECTED,
    DOCUMENT_PREVENT_DATA_RELOAD,
    DOCUMENT_SAVE_SCROLL_POSITION,
    LOAD_MORE_DOCUMENT_OVERVIEW,
    DOCUMENT_OVERVIEW_FILTER_CHANGED,
    RESET_CHANGE_DOCUMENT_KEY
} from '../store/actionTypes';

import * as Api from '../../utils/api/api';
import {handleError} from '../../utils/errorHandle.function';
import {prepareFilters} from "../../utils/tableFilters.function";

//default format of the object needed by backend
let callObject = {
    page: 1,
    rows: 40,
    asstype: "all"
};

export function changeActiveCounter(tab, counter, filters, companyId) {
    return function (dispatch) {
        dispatch(getDocumentOverview(tab, counter, filters, companyId));
        dispatch(changeCounter(counter));
    }
}

export function changeActiveDocType(type) {
    return {
        type: DOCUMENT_OVERVIEW_DOCTYPE_CHANGED,
        docType: type
    };
}

export function changeActiveDocTypeToDefault() {
    return {
        type: DOCUMENT_OVERVIEW_DOCTYPE_CHANGED_TO_DEFAULT
    };
}

export function changeCounter(counter) {
    return {
        type: ACTIVE_COUNTER_CHNAGED,
        data: counter
    };
}

export function changeFilter(filter) {
    return {
        type: DOCUMENT_OVERVIEW_FILTER_CHANGED,
        filter: filter
    };
}

export function documentOverviewLoaded(tasks = []) {
    return {
        type: DOCUMENT_OVERVIEW_LOADED,
        data: tasks
    };
}

export function documentOverviewLoading() {
    return {
        type: DOCUMENT_OVERVIEW_LOADING,
        data: null
    };
}

export function documentOverviewTaskSelect(taskKey) {
    return {
        type: DOCUMENT_OVERVIEW_TASK_SELECTED,
        key: taskKey
    };
}

export function moreOverviewLoaded(tasks = []) {
    return {
        type: LOAD_MORE_DOCUMENT_OVERVIEW,
        data: tasks
    };
}

export function preventDocumentReload(isStopDataReload) {
    return {
        type: DOCUMENT_PREVENT_DATA_RELOAD,
        data: isStopDataReload
    };
}

export function removeUpdatedDocumentKey(taskKey) {
    return {
        type: RESET_CHANGE_DOCUMENT_KEY,
        taskKey: taskKey
    };
}

export function saveDocumentScrollPosition(topScroll) {
    return {
        type: DOCUMENT_SAVE_SCROLL_POSITION,
        data: topScroll
    };
}

export function sortingColumnChanged(sortColumn) {
    return {
        type: DOCUMENT_OVERVIEW_SORT_COLUMN_CHANGED,
        sortColumn: sortColumn.sort,
        sortLogic: sortColumn.sortBy
    };
}

export function updateDocumentKey(oldKey, newKey) {
    return {
        type: CHANGE_DOCUMENT_KEY,
        oldKey: oldKey,
        newKey: newKey
    };
}


export function getDocumentOverview(tab, counter, filters, companyId) {
    return function (dispatch) {
        dispatch(documentOverviewLoading());
        dispatch(saveDocumentScrollPosition(0));

        let value = {};
        let filter = prepareFilters(filters);
        let tocall = Object.assign({}, callObject, filter);

        let getOverview = Api.getDocumentOverview(tab, counter, tocall).then(response => {
            value.tasks = response;
        }, error => {
            handleError(error);
        });

        let getCounters = Api.getDocumentOverviewCounter().then(response => {
            value.counters = response;
        }, error => {
            handleError(error);
        });

        let getCompanies = Api.getCompanyDocumentTypesBasedOnFeatures(companyId).then((response) => {
            value.companies = response;
        }, error => {
            handleError(error);
        });

        Promise.all([getOverview, getCounters, getCompanies]).then(
            () => {
                dispatch(documentOverviewLoaded(value));
            }
        );

    }
}

export function loadMoreOverview(callObject, counter, tab) {
    return function (dispatch) {
        let filter = prepareFilters(callObject.filters);
        let tocall = Object.assign({}, callObject, filter);
        Api.getDocumentOverview(counter, tab, tocall).then(
            response => {
                dispatch(moreOverviewLoaded(response));
            }, error => {
                handleError(error);
            });
    }
}

