import React, {Component} from 'react';
import OrganisationChartPopUp from "../../popup/OrganisationChartPopUp.component";
import Select from 'react-select';
import * as reduxSelectors from "../../store/application.reducers";
import {connect} from "react-redux";
import {getOrgChartTreeNodes} from "../../organisationChart/organisationChart.action";
import {customStyles} from "../../../utils/reactSelectTheme";

export class SimulatorOrgansationChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            search: ''
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.onConfirmModal = this.onConfirmModal.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSelectInputChange = this.onSelectInputChange.bind(this);

        this.fieldRef = null;
    }


    componentDidMount() {
        this.props.getOrgChartTreeNodes(new Date());
    }

    showModal() {
        this.setState({
            showModal: true
        });
    }

    hideModal() {
        this.setState({
            showModal: false
        });
    }

    onConfirmModal(node) {
        this.setState({
            showModal: false
        });

        this.props.onChange({
            value: node.item.organizationUnitId,
            label: `${node.item.organizationUnitCode} - ${node.item.name}`
        });
    }

    onSelectChange(option) {
        this.props.onChange(option || null);
    }

    onSelectInputChange(value) {
        this.setState({
            search: value
        });
    }

    render() {
        const options = this.state.search !== '' ? this.props.selectEntries : [];
        const selected = this.props.orgChartId;
        return (
            <div className="form-group organization-chart-input">
                { this.state.showModal &&
                    <OrganisationChartPopUp translate={this.props.translate}
                                            handleAction={this.onConfirmModal}
                                            closeCallback={this.hideModal} /> }

                <label>{this.props.translate("workflowDetails.simulator.organisationUnit")}</label>
                <div className="form-group">
                    <Select options={options}
                            ref={(field) => { this.props.onRefInit(field) }}
                            value={selected}
                            inputValue={this.state.search}
                            isClearable={true}
                            isSearchable={true}
                            styles={customStyles}
                            onChange={this.onSelectChange}
                            onInputChange={this.onSelectInputChange}
                            name="department"
                    />
                    <button onClick={this.showModal} className="open-modal-button">
                        <span className="advanced-search-icon mt-2"/>
                    </button>
                </div>
            </div>
        );
    }
}


const mapStateToProps = function (store) {
    const orgChartTree = reduxSelectors.getOrgChartTree(store);

    return {
        orgChartTree,
        selectEntries: orgChartTree && orgChartTree.rows
            .map((node) => ({
                label: `${node.item.organizationUnitCode} - ${node.item.name}`,
                value: node.item.organizationUnitId
            })),
    };
};

const connected = connect(mapStateToProps, {
    getOrgChartTreeNodes,
})(SimulatorOrgansationChart);

export default connected;
