import * as Api from "../../utils/api/api";
import {DecisionTable} from "./decisionTable.class";

let TOKEN: string = "";

type temporaryObject = {
    groupedByUuids: DecisionTable[]
}

export async function getDecisionTablesToken(): Promise<string | null> {
    let token = null;
    try {
        const response = await Api.getDecisionTablesToken();
        if (response && response.webSocketToken) {
            token = response.webSocketToken;
            TOKEN = response.webSocketToken;
        }
    } catch {
        //handle get token error
    }

    return token;
}

export type GroupedDecisionTables = {
    docTypes: number[];
    tables: object | null;
};


export async function getDecisionTableList(): Promise<DecisionTable[] | null> {
    await getDecisionTablesToken();
    try {
        let result: temporaryObject = await Api.getDecisionTables(TOKEN);
        return result.groupedByUuids;
    } catch
        (error) {
        console.log(error);
    }
    return null;
}


export async function createNewDecisionTable(name: string) {
    const basicDecisionTable = {
        name: name,
        version: 0,
        status: "DRAFT",
        columns: [],
        rows: [],
        description:""
    }

    await Api.createDecisionTable(TOKEN, basicDecisionTable);
}

export async function deleteDecisionTable(etag: string | undefined, uuid: string) {
    await Api.deleteDecisionTable(TOKEN, etag, uuid);
}