import {
    TASK_OVERVIEW_LOADED,
    TASK_OVERVIEW_LOADING,
    ACTIVE_COUNTER_CHNAGED,
    OVERVIEW_FILTER_CHANGED,
    OVERVIEW_COUNTERS_LOADED,
    OVERVIEW_SORT_COLUMN_CHANGED,
    LOAD_MORE_OVERVIEW,
    OVERVIEW_TASK_SELECTED
} from '../store/actionTypes';

import * as Api from '../../utils/api/api';
import {handleError} from '../../utils/errorHandle.function';
import {prepareFilters} from "../../utils/tableFilters.function";

const FIRST_PAGE = 1;
const NUMBER_OF_ROWS = 40;
let callObject = {
    page: FIRST_PAGE,
    rows: NUMBER_OF_ROWS,
    asstype: "all"
}

export function taskOverviewLoaded(counters = {}) {
    return {
        type: TASK_OVERVIEW_LOADED,
        data: counters
    };
}

export function taskOverviewLoading() {
    return {
        type: TASK_OVERVIEW_LOADING,
        data: null
    };
}

export function countersLoaded(counter) {
    return {
        type: OVERVIEW_COUNTERS_LOADED,
        data: counter
    };
}

export function changeCounter(counter) {
    return {
        type: ACTIVE_COUNTER_CHNAGED,
        data: counter
    };
}

export function moreOverviewLoaded(tasks = []) {
    return {
        type: LOAD_MORE_OVERVIEW,
        data: tasks
    };
}

export function sortingColumnChanged(sortColumn) {
    return {
        type: OVERVIEW_SORT_COLUMN_CHANGED,
        sortColumn: sortColumn.sort,
        sortLogic: sortColumn.sortBy
    };
}

export function changeActiveCounter(counter, filters) {
    return function (dispatch) {
        dispatch(getOverviewCounters(filters, counter));
        dispatch(changeCounter(counter));
    }
}

export function filterChanged(filter) {
    return {
        type: OVERVIEW_FILTER_CHANGED,
        filter: filter
    };
}

export function overviewTaskSelect(taskKey) {
    return {
        type: OVERVIEW_TASK_SELECTED,
        key: taskKey
    };
}

export function getOverviewCounters(filters, counter) {
    return function (dispatch) {
        dispatch(taskOverviewLoading());

        let value = {};
        let filter = prepareFilters(filters);
        let tocall = Object.assign({
            status: counter
        }, callObject, filter);

        let getOverview = Api.getTaskOverview(tocall).then(response => {
            value.tasks = response;
        }, error => {
            handleError(error);
        });

        let getAssignmentType = Api.getAssignmentType().then(response => {
            value.userRoles = response;
        }, error => {
            handleError(error);
        });

        Promise.all([getOverview, getAssignmentType]).then(
            () => {
                dispatch(taskOverviewLoaded(value));
            }
        );
    }
}

export function getCounters() {
    return function (dispatch) {
        dispatch(taskOverviewLoading());
        Api.getTaskOverviewCounters().then(
            response => {
                dispatch(countersLoaded(response));
            }, error => {
                handleError(error);
            });
    }
}

export function loadMoreOverview(callObject, counter) {
    return function (dispatch) {
        let filter = prepareFilters(callObject.filters);
        let tocall = Object.assign({
            status: counter
        }, callObject, filter);
        Api.getTaskOverview(tocall).then(
            response => {
                dispatch(moreOverviewLoaded(response));
            }, error => {
                handleError(error);
            });
    }
}

