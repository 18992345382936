import React from "react";
import {eventBus} from "../../../pagelayout/EventBus";

class CommentsInput extends React.Component {
    constructor() {
        super();

        this.state = {
            text: '',
            textAreaHeight: 'auto',
            parentHeight: 'auto',
            initialized: false
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.textAreaRef = React.createRef();
    }

    onChangeHandler (event) {
        event.preventDefault();

        this.setState({
            text: event.target.value,
            parentHeight: 'auto',
            textAreaHeight: 'auto'
        }, () => {
            this.setState({
                parentHeight: `${this.textAreaRef.current.scrollHeight + 4}px`,
                textAreaHeight: `${this.textAreaRef.current.scrollHeight}px`
            }, () => {
                eventBus.dispatch('@@grid/refreshGridLayout');
            });
        });

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    componentDidMount() {
        this.setState({
            parentHeight: `${this.textAreaRef.current.scrollHeight + 4}px`,
            textAreaHeight: `${this.textAreaRef.current.scrollHeight}px`
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.taskId !== prevProps.taskId) {
            this.setState({
                initialized: false,
                parentHeight: 'auto',
                textAreaHeight: 'auto'
            });
        }
        
        if (this.props.value !== prevProps.value && !this.state.initialized && this.props.value !== '') {
            this.setState({
                initialized: true,
                parentHeight: 'auto',
                textAreaHeight: 'auto'
            }, () => {
                this.setState({
                    parentHeight: `${this.textAreaRef.current.scrollHeight + 4}px`,
                    textAreaHeight: `${this.textAreaRef.current.scrollHeight}px`
                });
            });
        }
    }

    render() {
        return (
            <div style={{
                height: this.state.parentHeight,
            }} className="comments-textarea-wrapper">
                <textarea
                    {...this.props}
                    ref={this.textAreaRef}
                    rows={1}
                    style={{
                        height: this.state.textAreaHeight,
                    }}
                    onChange={this.onChangeHandler}
                />
            </div>
        );
    }
};

export default CommentsInput;