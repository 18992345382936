import React, {useEffect} from "react";
import ImageUnavailable from "./Image.unavailable";
import ImageLoading from "./Image.loading";
import {LOADING_STATUS} from "utils/constants";


export const ImageTiff = ({id, key, zoom, style, src, rotation}: any): React.ReactElement => {
    const [loadingStatus, setLoadingStatus] = React.useState<number | null>(null);

    const imageRef = React.useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (src && src !== "") {
            setLoadingStatus(LOADING_STATUS.LOADING);
            try {
                // Create an ImageData object
                const imageData = new ImageData(new Uint8ClampedArray(src.buffer), src.width, src.height);
                let canvas = document.createElement('canvas');
                canvas.height = src.height;
                canvas.width = src.width
                let ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                // Use the offscreen canvas as the source for the drawImage call
                ctx.putImageData(imageData, 0, 0);
                // Reset the transformation matrix to avoid cumulative scaling
                ctx.setTransform(1, 0, 0, 1, 0, 0);


                const dataUrl = canvas.toDataURL();
                imageRef.current.src = dataUrl;
                setLoadingStatus(LOADING_STATUS.DONE);
            } catch (error) {
                setLoadingStatus(LOADING_STATUS.ERROR);
            }

        }
    }, [src, zoom]);

    return (
        <div className={`images-auto-sizer__page-container page_${id}`} style={style} key={key}>
            <div className={"attachment-image-container tiff"}>
                {src && src !== "" &&
                    <img
                        ref={imageRef}
                        className={`${"attachment-image"}  ${rotation ? "rotate_" + rotation : ""}`}
                        style={{width: zoom + "%"}}
                        alt={`attachment_${id}`}
                        src={""}
                        onError={() => {
                            setLoadingStatus(LOADING_STATUS.ERROR)
                        }}
                        role="presentation"/>
                }
                {loadingStatus === LOADING_STATUS.ERROR &&
                    <ImageUnavailable/>
                }

                {!src || src === "" || loadingStatus === LOADING_STATUS.LOADING ? <ImageLoading/> : null}
            </div>
        </div>
    )
}
export default ImageTiff;