import {removeTaskFromStore} from '../myTasks.action';
import * as logger from '../../../utils/logger.function';
import {
    getTasksForRender,
    getOneHandleOnlyFlag,
    getUsersSettings,
    getTryLoopTasksFlag,
    getErrorMessageVisibility
} from '../../store/application.reducers';
import {navigateBetweenTasks, isLastInTheIteration} from './navigateBetweenTasks.action';
import {PATH_TO_MY_TASKS} from "components/router/paths.js";

export function handleTask(taskKey, navigate) {

    return function (dispatch, getState) {

        const state = getState();
        const tasks = getTasksForRender(state);
        let noErrorPressent = !getErrorMessageVisibility(state);
        let userSettings = getUsersSettings(state);
        let loopTasks = userSettings.nextTask;

        logger.debug("task handled", taskKey);
        // if we're outside the iteration, return back to taskList
        const oneHandleOnly = getOneHandleOnlyFlag(state);
        const tryLoopTasksFlag = getTryLoopTasksFlag(state);
        const notInMyTasks = window.location.pathname !== "/";

        //user opened a subset of tasks
        if (!oneHandleOnly) {
            if (isLastInTheIteration(taskKey, tasks.selectedKeys)) {
                if (noErrorPressent && notInMyTasks)
                    navigate(PATH_TO_MY_TASKS, {replace: true});
                return;
            }
            logger.debug("open sublist of tasks and loop within that list");
            if (noErrorPressent) {
                navigate(dispatch(navigateBetweenTasks(taskKey, true)));
                dispatch(removeTaskFromStore(taskKey));
            }
            return;
        }

        if (!loopTasks || !tryLoopTasksFlag || isLastInTheIteration(taskKey, tasks.filteredKeys)) {
            logger.debug("TaskHandlingActive=false and oneHandleOnly=true so navigate to my tasks");
            if (noErrorPressent) {
                dispatch(removeTaskFromStore(taskKey));
                if (notInMyTasks)
                    navigate(PATH_TO_MY_TASKS, {replace: true});
            }
            return;
        }

        logger.debug("in iteration and not last, navigating");
        // otherwise try to navigate to the next
        const nextPath= dispatch(navigateBetweenTasks(taskKey, true));
        navigate(nextPath, {replace: true});
        // remove from the store, so disable navigating back to handled task
        // we have to dispatch removing from the store after the navigation, because otherwise taskDetail starts rerendering (it's connected to the store)
        dispatch(removeTaskFromStore(taskKey));

    }
}