import {showNotification} from '../../notifications/notification.action';
import {seamlessLoadTasks} from '../myTasks.action';
import * as Api from 'utils/api/api';
import {translate} from '../../translations/translations.wrapper.jsx';
import {handleTask} from './handleTask.action';
import * as logger from '../../../utils/logger.function';
import {getTask} from '../../store/application.reducers';
import {
    replacePlaceholderInMessage,
    getTaskUserDescription,
    addTasksAmount
} from 'utils/valueFormatter.function';
import _ from "lodash";
import {handleError} from 'utils/errorHandle.function';
import {approveActionRunning, approveActionStop} from "./taskAction.action";


const ERROR = "error";

/**
 * tries to approve multiple tasks and returns to refreshed tasklist .. in case of error shows error dialog (~ creates negative notification)
 * in case of success it shows one transient positive notification
 *
 * this is the redux action
 * @param taskIds
 * @return {Function}
 */


export function approveMultipleTasks(taskIds, comment, version, skipRestart, isFromBatch = false, navigate) {

    return async function (dispatch) {
        // direct approve:
        const onlyOne = taskIds.length === 1;

        const results = [];
        try {
            dispatch(approveActionRunning());
            for (const id of taskIds) {
                let currentComment = null;
                if (!comment && comment !== null) {
                    results.push(
                        await Api.getTaskComments(id).then(function (response) {
                            let currentFound = _.find(response, ['current', true]);
                            if (currentFound)
                                currentComment = currentFound.content;
                            return Api.approveTask(id, currentComment, version, skipRestart).then((response) => {
                                if (onlyOne)
                                    dispatch(singleTaskToast(id, response.message, isFromBatch));

                            }).catch(error => {
                                logger.debug("Task cannot be approved because of unapproved lines " + error.errorMessages);
                                throw error;
                            });
                        })
                    )
                } else {
                    results.push(
                        await Api.approveTask(id, comment, version, skipRestart).then((response) => {
                            if (onlyOne)
                                dispatch(singleTaskToast(id, response.message, isFromBatch));

                        }).catch(error => {
                            logger.debug("Task cannot be approved because of unapproved lines " + error.errorMessages);
                            throw error;
                        })
                    );
                }
            }
            //don't automatically move from task details if there's an error
            if (onlyOne && results[0] === ERROR) {
                return;
            }
            // positive notification
            if (!onlyOne) {
                dispatch(multipleTasksToast(taskIds, isFromBatch));
            }
            // resolve for last
            const last = taskIds.slice().pop();
            dispatch(approveActionStop());
            dispatch(handleTask(last, navigate));
        } catch (error) {
            logger.error("redux.approveMultipleTasks", error);
            //refresh task list
            dispatch(seamlessLoadTasks());
            handleError(error);
        }
    }
}

function singleTaskToast(id, response, isFromBatch) {
    return function (dispatch, getState) {
        const state = getState();
        let message = "";

        const task = getTask(id, state);
        if (isFromBatch) {
            let text = task.task.amount ? task.task.amount + " " + task.task.currency : "";
            message = translate(state, 'popUp.approve.approveAndPaySingle', text);
        } else
            message = replacePlaceholderInMessage(response, getTaskUserDescription(task));

        dispatch(showNotification(message));
    }
}

function multipleTasksToast(ids, isFromBatch) {
    return function (dispatch, getState) {
        const state = getState();
        let message = "";

        if (isFromBatch) {
            let tasks = ids.map(id => {
                return getTask(id, state).task;
            });

            let cost = addTasksAmount(tasks);

            message = cost ? translate(state, 'popUp.approve.approveAndPayMultipleWithCost', ids.length, cost) :
                translate(state, 'popUp.approve.approveAndPayMultipleWithoutCost', ids.length);
        } else
            message = translate(state, 'popUp.approve.actionSuccess', ids.length);
        dispatch(showNotification(message));
    }
}