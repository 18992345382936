import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PopUp} from './PopUp.component';
import translate from '../translations/translations.wrapper.jsx';
import image1 from "./icons/onboarding-01.png";
import image2 from "./icons/onboarding-02.png";
import image3 from "./icons/onboarding-03.png";
import {snowplowTrackEvent} from "../../utils/snowplow";

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;


export class Onboarding extends PopUp {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        }
        this.closePopup = this.closePopup.bind(this);
        this.dontShowAgain = React.createRef();
        this.goToNext = this.goToNext.bind(this);
        this.goBack = this.goBack.bind(this);
    }


    componentDidMount() {
        super.componentDidMount();
        this.setState({
            currentPage: 1
        });
        snowplowTrackEvent({
            category: "modular_onboarding",
            action: "view_onboarding_page_" + this.state.currentPage,
            label: "view_onboarding_page_" + this.state.currentPage,
            value: this.state.currentPage
        }, this.props.userData);
    }

    goToNext() {
        let nextPage = this.state.currentPage < 3 ? this.state.currentPage + 1 : this.state.currentPage;
        if (this.state.currentPage !== nextPage) {
            this.setState({
                currentPage: nextPage
            }, () => {
                snowplowTrackEvent({
                    category: "modular_onboarding",
                    action: "view_onboarding_page_" + this.state.currentPage,
                    label: "view_onboarding_page_" + this.state.currentPage,
                    value: this.state.currentPage
                }, this.props.userData);
            });
        }
    }

    goBack() {
        if (this.state.currentPage > 1) {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }
    }

    closePopup() {
        window.localStorage.setItem('onboarding/popup/dontShowAgain', this.dontShowAgain?.current.checked);
        snowplowTrackEvent({
            category: "modular_onboarding",
            action: "close_onboarding_modal",
            label: "close_on_page_" + this.state.currentPage,
            value: true
        }, this.props.userData);
        this.props.closeCallback();
    }

    focusFirst() {
    }

    buildContent() {
        const images = {
            1: image1,
            2: image2,
            3: image3
        }

        return (
            <Fragment>
                <div className="modal-body">
                    <div><h3>{this.props.translate("popUp.onboarding.page_" + this.state.currentPage)}</h3>
                        <div className={"d-flex justify-content-center"}>
                            <img alt={"page 1"} src={images[this.state.currentPage]} className={"border-0"}
                                 width={500}/>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="float-left">
                        <div className="checkbox">
                            <input type="checkbox" id="checkbox-example" ref={this.dontShowAgain}
                                   defaultChecked={true}/>
                            <label
                                htmlFor="checkbox-example">{this.props.translate("popUp.onboarding.dontShowAgain")}</label>
                            <br/>
                            {this.state.currentPage === 3 &&
                                <small><span
                                    className={"vismaicon vismaicon-filled vismaicon-sm vismaicon-help d-flex"}>
                                    {this.props.translate("popUp.onboarding.stillSee")}
                                </span> </small>
                            }
                        </div>
                    </div>

                    <div className="float-right">

                        {this.state.currentPage > 1 && this.state.currentPage <= 3 &&
                            <button type="button" className="btn btn-default left" onClick={this.goBack}>
                                {this.props.translate("settings.back")}
                            </button>
                        }

                        {this.state.currentPage < 3 &&
                            <button type="button" className="btn btn-primary right" onClick={this.goToNext}>
                                {this.props.translate("popUp.onboarding.showMeMore")}
                            </button>
                        }

                        {this.state.currentPage === 3 &&
                            <button className="btn btn-primary mt-2"
                                    onClick={this.closePopup}
                                    tabIndex={TAB_INDEX}>
                                {this.props.translate("popUp.onboarding.exit")}
                            </button>
                        }
                    </div>
                </div>
            </Fragment>

        );
    }

    getType() {
        return "onboarding";
    }

    getTitle() {
        return <span>{this.props.translate("popUp.onboarding.title_" + this.state.currentPage)}</span>;

    }

}

Onboarding.propTypes = {
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
};
const withTranslations = translate(Onboarding);
export default withTranslations;