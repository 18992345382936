import React, {Component} from 'react';
import * as api from "../../../utils/api/api";

export class SimulatorAmount extends Component {

    componentDidMount() {
        this.getCurrency();
    }

    getCurrency(){
        api.getCurrencyCode(this.props.currentContextId).then((response) => {
            if(response){
                this.props.onCurrencyChange({
                    target: {
                        value: response
                    }
                })
            }
        })
    }

    render() {
        return (
            <div className="form-group">
                <label>{this.props.translate("workflowDetails.simulator.amount")}</label>
                <div className="form-group form-inline">
                    <div className={`form-group ${this.props.valid ? "" : "has-error"}`}>
                        <input type="number"
                               min={1}
                               className="form-control"
                               value={this.props.amount}
                               onChange={this.props.onAmountChange}/>
                    </div>
                    <div className="form-group">
                        <select className="form-control"
                                value={this.props.currency}
                                onChange={this.props.onCurrencyChange}>
                            <option value={'NOK'}>NOK</option>
                            <option value={'SEK'}>SEK</option>
                            <option value={'DKK'}>DKK</option>
                            <option value={'EUR'}>EUR</option>
                            <option value={'RON'}>RON</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}