import {
    SUBSTITUES_LOADED,
    SUBSTITUES_LOADING,
    SUBSTITUES_ACTIVE_FILTER_CHANGE,
    SUBSTITUES_SEARCH_VALUE,
    SUBSTITUE_REMOVED,
    SUBSTITUES_SORT,
    MORE_SUBSTITUTES_LOADED
} from '../store/actionTypes';

import * as Api from '../../utils/api/api';
import {
    getSubstitutesActiveFilter,
    getSubstitutesSorting,
    getSubstitutesSearchValue,
    getSubstitutesForRender
} from '../store/application.reducers';
import {handleError} from '../../utils/errorHandle.function';

export function substitutesLoaded(substitutes = []) {
    return {
        type: SUBSTITUES_LOADED,
        data: substitutes
    };
}

export function substitutesLoading() {
    return {
        type: SUBSTITUES_LOADING,
        data: null
    };
}

export function moreSubstitutesLoaded(substitutes = []) {
    return {
        type: MORE_SUBSTITUTES_LOADED,
        data: substitutes
    };
}

export function changeActiveFilter(filter) {
    return {
        type: SUBSTITUES_ACTIVE_FILTER_CHANGE,
        data: filter
    };
}

export function changeSort(criteria) {
    return {
        type: SUBSTITUES_SORT,
        data: criteria
    };
}

export function changeSearchValue(value) {
    return {
        type: SUBSTITUES_SEARCH_VALUE,
        data: value
    };
}

export function substituteRemoved(value) {
    return {
        type: SUBSTITUE_REMOVED
    };
}


export function getSubstitutes(page, activeFilter, searchValue, sort, skipLoad = false) {
    return function (dispatch) {
        if (!skipLoad)
            dispatch(substitutesLoading());


        Api.getSubstitutesSortedOnPage(page, activeFilter, searchValue, sort).then(response => {
                if (page > 1)
                    dispatch(moreSubstitutesLoaded(response));
                else
                    dispatch(substitutesLoaded(response));
            },
            error => {
                handleError(error);
            });
    }
}

export function deleteSubstitute(index) {
    return function (dispatch, getState) {
        let state = getState();
        let searchValue = getSubstitutesSearchValue(state);
        let sort = getSubstitutesSorting(state);
        let activeFilter = getSubstitutesActiveFilter(state);
        let subs = getSubstitutesForRender(state);

        if (subs.allSubstitutes) {
            let toDelete = subs.allSubstitutes[index];
            //this is not really a delete, we just set the active flag to false to filter this out of the UI
            toDelete.active = false;

            Api.saveSubstitute(toDelete).then(response => {
                    dispatch(getSubstitutes(1, activeFilter, searchValue, sort, true));
                },
                error => {
                    handleError(error);
                });
        }
    }
}


