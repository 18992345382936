import {APPROVAL_MENU_ITEMS} from './constants';

export function userHasAccessRights(userRoles, location) {
    let currentLocation = location ? location.pathname : document.location.pathname;

    let matchingMenuItem = {};

    APPROVAL_MENU_ITEMS.forEach((menuItem) => {
        if (menuItem.url === currentLocation) {
            matchingMenuItem = menuItem;
        } else if (menuItem.children) {
            menuItem.children.forEach((childMenuItem) => {
                if (menuItem.url + childMenuItem.url === currentLocation) {
                    matchingMenuItem = childMenuItem;
                }
            });
        }
    });

    return userRoles[matchingMenuItem.rightsToView];
}

export function canViewConfiguration(userRoles) {
    return userRoles.systemViewer || userRoles.systemAdministrator;
}