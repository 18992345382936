import {TASK_ACTIONS,} from 'utils/constants';
import DeleteAttachmentPopup from 'components/popup/DeleteAttachmentPopup.component';

import { useDispatch, useSelector } from "react-redux";
import {actionFinished} from 'components/mytasks/action/taskAction.action';
import {showNotification} from "components/notifications/notification.action";
import * as logger from 'utils/logger.function';
import * as reduxSelectors from "components/store/application.reducers";
import { Store } from "redux";

/**
 * asynchronous part of the task action - showing the dialog, which will execute the action
 * synchronous part of action is inside redux action
 */
export const AsyncTaskActionExecutor = () => {
    const dispatch = useDispatch();
    const tasks = useSelector((store: Store) => reduxSelectors.getTasksForRender(store));
    const actionParameters = useSelector((store: Store) => reduxSelectors.getActionParameters(store));

    // nothing to show
    if (!actionParameters?.type) {
        return null;
    }

    const taskList = tasks.allTasks.map((task: any) => task.task);

    const tasksForAction = taskList
        .filter((task : any) => { // find those matching props.taskIds
            return actionParameters.taskIds.some((taskKeyWanted: any) => {
                return taskKeyWanted === task.key;
            });
        });

    /**
     * sometimes, when user has dialog opened and navigates away for some time or changes context
     * the keys for task/history details expire, which then results in empty tasks for the popup
     * so detect this situation and rather hide the popup than check for invalid props inside each popup
     */
    if (tasksForAction.length === 0) {
        logger.debug("AsyncTaskActionExecutor", "no task to be shown for these keys: " + actionParameters.taskIds);
        dispatch(actionFinished());
        return null;
    }

    const popupProps = {
        closeCallback: () => dispatch(actionFinished()),
        tasks: tasksForAction,
        showNotification: (data: string) => dispatch(showNotification(data)),
    };

    // according to type either show popup or execute action directly ..
    if (actionParameters.type === TASK_ACTIONS.DELETE_ATTACHMENT) {
        return <DeleteAttachmentPopup {...popupProps} />;
    } else {
        console.log('unsupported action type: ' + actionParameters.type);
        return null;
    }

}

export default AsyncTaskActionExecutor;