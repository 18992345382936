import {VIRTUAL_PAGE_ROUTING, VIRTUAL_PAGE_ROUTING_END} from '../store/actionTypes';

export const ROUTE_STATE_DEFAULT = {
    action: null
};

export default function routeReducer(state = ROUTE_STATE_DEFAULT, action) {
    // save the current translations into store
    if (action.type === VIRTUAL_PAGE_ROUTING) {
        return Object.assign({}, {action: action.action});
    } else if (action.type === VIRTUAL_PAGE_ROUTING_END) {
        return ROUTE_STATE_DEFAULT;
    } else {
        return state;
    }
}


export function getRouteTrackingAction(state) {
    return state.action;
}