import React, {useState, useCallback, useMemo} from 'react';
import Menu from '../menu/Menu.component.jsx';
import SmallScreenAlert from './SmallScreenAlert.component';
import translate from '../translations/translations.wrapper.jsx';
import {Outlet} from "react-router-dom";
import Modal from 'components/modals/Modal';
import {ModalContext} from 'components/modals/ModalContext';

// this.props.children is determined by router definition
const PageLayout = (props) => {
    const [modalType, setModalType] = useState(null);
    const [modalProps, setModalProps] = useState(null);

    const toggleModal = useCallback((type, more) => {
        setModalType(type);
        setModalProps(more);
    }, []);


    const modalValue = useMemo(() => ({toggleModal}), []);
    return (
        <div>
            <Menu location={props.location}/>
            <Modal type={modalType} toggleModal={toggleModal} more = {modalProps}/>
            <ModalContext.Provider value={modalValue}>
                <div className="app-width full-height-container container-fluid">
                    <Outlet/>
                    <SmallScreenAlert/>
                </div>
            </ModalContext.Provider>
        </div>
    )
}
const withTranslations = translate(PageLayout);
export default withTranslations;