import React from 'react';
import {formatDate,formatAmountValue,formatDocumentOrganizationUnit} from '../../../../utils/valueFormatter.function';

const TimesheetDocumentDetailsDTO = ({details, translate}) => {
        let displayId = details.displayId ? details.displayId : details.documentId;

        return (
            <div>
                {details.description &&
                <div className="document-data-field">
                    <div className="document-data-field-label">
                        {translate("documentData.description")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.description}>
                        {details.description}
                    </div>
                </div>}

                <div className="document-data-divided">
                    {details.submitterName &&
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.submitterName")}
                        </div>
                        <div className="document-data-field-value"
                             title={details.submitterName}>
                            {details.submitterName}
                        </div>
                    </div>}

                    {details.branch && (details.branch.branchCd || details.branch.name) &&
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.branch")}
                        </div>
                        <div className="document-data-field-value"
                             title={details.branch.name ? details.branch.name : details.branch.branchCd}>
                            {details.branch.name ? details.branch.name : details.branch.branchCd}
                        </div>
                    </div>}

                    {details.type &&
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.type")}
                        </div>
                        <div className="document-data-field-value"
                             title={details.type}>
                            {details.type}
                        </div>
                    </div>}

                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.documentID")}
                        </div>
                        <div className="document-data-field-value"
                             title={displayId}>
                            {displayId}
                        </div>
                    </div>

                    {details.weekNumber &&
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.weekNumber")}
                        </div>
                        <div className="document-data-field-value">
                            {details.weekNumber}
                        </div>
                    </div>}

                    {details.weekStart &&
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.weekStart")}
                        </div>
                        <div className="document-data-field-value">
                            {formatDate(details.weekStart)}
                        </div>
                    </div>}

                    {details.weekEnd &&
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.weekEnd")}
                        </div>
                        <div className="document-data-field-value">
                            {formatDate(details.weekEnd)}
                        </div>
                    </div>}

                    {details.regularHours && details.regularHours !== 0 ?
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.regularHours")}
                        </div>
                        <div className="document-data-field-value">
                            {formatAmountValue(details.regularHours)}
                        </div>
                    </div> : null}

                    {details.overtimeHours && details.overtimeHours !== 0 ?
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.overtimeHours")}
                        </div>
                        <div className="document-data-field-value">
                            {formatAmountValue(details.overtimeHours)}
                        </div>
                    </div> : null}

                    {details.otherHours && details.otherHours !== 0 ?
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.otherHours")}
                        </div>
                        <div className="document-data-field-value">
                            {formatAmountValue(details.otherHours)}
                        </div>
                    </div> : null}

                    {details.invoiceOtherHours && details.invoiceOtherHours !== 0 ?
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.invoiceOtherHours")}
                        </div>
                        <div className="document-data-field-value">
                            {formatAmountValue(details.invoiceOtherHours)}
                        </div>
                    </div> : null}

                    {details.invoiceOvertimeHours && details.invoiceOvertimeHours !== 0 ?
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.invoiceOvertimeHours")}
                        </div>
                        <div className="document-data-field-value">
                            {formatAmountValue(details.invoiceOvertimeHours)}
                        </div>
                    </div> : null}

                    {details.totalHours && details.totalHours !== 0 ?
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.totalHours")}
                        </div>
                        <div className="document-data-field-value">
                            {formatAmountValue(details.totalHours)}
                        </div>
                    </div> : null}

                    {details.totalInvoiceHours && details.totalInvoiceHours !== 0 ?
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.invoiceTotalHours")}
                        </div>
                        <div className="document-data-field-value">
                            {formatAmountValue(details.totalInvoiceHours)}
                        </div>
                    </div> : null}

                    {details.documentOrganizationUnit &&
                    <div className="document-data-field third">
                        <div className="document-data-field-label">
                            {translate("documentData.organizationUnit")}
                        </div>
                        <div className="document-data-field-value"
                             title={formatDocumentOrganizationUnit(details.documentOrganizationUnit)}>
                            {formatDocumentOrganizationUnit(details.documentOrganizationUnit)}
                        </div>
                    </div>}
                </div>
            </div>
        )
    };

export default TimesheetDocumentDetailsDTO;