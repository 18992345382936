import {
    DEPARTMENTS_LOADED,
    DEPARTMENTS_FILTER_CHANGED,
    ASSIGNED_DEPARTMENTS_LOADED
} from '../store/actionTypes';

import * as Api from '../../utils/api/api';
import {handleError} from '../../utils/errorHandle.function';


export function departmentsLoaded(departments) {
    return {
        type: DEPARTMENTS_LOADED,
        data: departments
    }
}

export function filterChanged(value) {
    return {
        type: DEPARTMENTS_FILTER_CHANGED,
        data: value
    }
}

export function assignedManagersLoaded(value){
    return {
        type: ASSIGNED_DEPARTMENTS_LOADED,
        data: value
    }
}

export function getDepartments() {
    return function (dispatch) {

        let value = {};

        const getPossibleDepartmentManagers = Api.getPossibleDepartmentManagers().then(users => {
            value.possibleManagers = users.result;

        }, error => {
            handleError(error);
        });

        const getDepartmentsManagers = Api.getAssignedDepartmentManagers().then(managers => {
            value.assignedManagers = managers.result;
        }, error => {
            handleError(error);
        });

        const getDepartmentsList = Api.getDepartments().then(departments => {
            value.departments = departments.result;
        }, error => {
            handleError(error);
        });


        Promise.all([getPossibleDepartmentManagers, getDepartmentsManagers, getDepartmentsList]).then(
            () => {
                dispatch(departmentsLoaded(value));
            }
        );

    }
}

export function saveDepartmentChanges(data) {
    return function (dispatch) {
        Api.saveDepartmentManagers(data).then(response => {
            Api.getAssignedDepartmentManagers().then(managers => {
                dispatch(assignedManagersLoaded(data));
            }, error => {
                handleError(error);
            });
        }, error => {
            handleError(error);
        });

    }
}

