import * as Api from "../../utils/api/api";
import {handleError} from "../../utils/errorHandle.function";
import {BackendTableDetails} from "../decisionTables/decisionTableTypes";


export function sendUpdatedDecisionTable(token: string, etag: string, uuid: string, rows: any, tableName: string, columns: any) {
    let data = {
        uuid: uuid,
        status: "DRAFT",
        rows: rows,
        name: tableName,
        columns: columns
    };

    Api.updateDecisionTable(token, etag, uuid, data).then((result: string) => {
    }).catch((error: any) => {
        handleError(error);
    });
}


export function getDecisionTableDetails(token: string | null, tableId: string): Promise<BackendTableDetails> {
    return Api.getDecisionTable(token, tableId, "DRAFT").then((resultAndEtag: BackendTableDetails) => {
        let splitEtag = resultAndEtag.etag.split('"');
        let finalEtag = splitEtag[1] ? splitEtag[1] : resultAndEtag.etag;

        return {
            etag: finalEtag,
            table: resultAndEtag.result
        }

    }).catch((error: any) => {
        handleError(error);
    });

}