import * as Api from "utils/api/api";
import { getTaskDetails, removeSelectedDocument } from "components/taskdetails-remake/taskDetails.action";
import { showNotification } from "components/notifications/notification.action";
import {seamlessLoadTasks} from "components/mytasks/myTasks.action";
import { ForwardActionData, DeleteActionData } from "types/modals";
import { Dispatch } from "redux";

export function deleteAttachment( data: DeleteActionData, successCallback: Function, errorCallback: Function) {
    return function(dispatch: Dispatch<any>) {
        Api.deleteAttachment(data.key, data.collectionGuid, data.id).then(function() {
                dispatch(removeSelectedDocument());
                dispatch(getTaskDetails(data.key));
                dispatch(showNotification(data.deleteMessage));
                successCallback();
            },
            function(error: any) {
                errorCallback({ error: error });
            }
        );
    }
}

export function forwardTask(data: ForwardActionData, successCallback: Function, errorCallback: Function) {
    return function(dispatch: Dispatch<any>) {
        Api.forwardTask(data.key,data.comment, data.recipient).then(function () {
                dispatch(showNotification(data.forwardMessage));
                successCallback();
            },
            function (error:any) {
                errorCallback({ error: error });
            });
    }
}

export function postponeTasks(taskKeys, comment, dayFields, postponeMessage, successCallback, errorCallback) {

    Api.postponeTask(taskKeys, comment, dayFields.thisDay, dayFields.daysFromNow, dayFields.daysBefore).then(function(response) {
            if (response.success > 0) {
                showNotification(postponeMessage);
                if (response.total !== response.success) {
                    errorCallback();

                } else {
                    successCallback();
                    seamlessLoadTasks();
                }
            } else {
                errorCallback();
            }

        },
        function(error) {
            errorCallback({ error: error });
        },
    );
}