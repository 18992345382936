import {
    DEPARTMENTS_LOADED,
    DEPARTMENTS_FILTER_CHANGED,
    ASSIGNED_DEPARTMENTS_LOADED
} from '../store/actionTypes';
import {createSelector} from 'reselect';

import * as _ from 'lodash';


export const DEFAULT_STATE = {
    possibleManagers: [],
    assignedManagers: [],
    allDepartments: [],
    filter: ""
};

export const getDepartmentsForRender = createSelector(
    [getDepartments, getAssignedManagers, getDepartmentsFilter],
    (departments, managers, filter) => {
        let response = _.filter(departments, (department) => {
            const attributesList = ["name", "organizationUnitId"];

            // fulltext search over all attributes in attributesList
            for (let index in attributesList) {
                if (department[attributesList[index]] && department[attributesList[index]].toString().toLowerCase().indexOf(filter) !== -1)
                    return true;
            }

            //filter by managers
            let isManager = _.find(department.managers, manager => {
                return manager.label.indexOf(filter) !== -1;
            });

            return isManager;

        });

        response = _.sortBy(response, "name");

        return response;
    }
);

export default function departmentsReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {
        case DEPARTMENTS_LOADED:
            partialState = Object.assign([], state);
            partialState.possibleManagers = action.data.possibleManagers;
            partialState.assignedManagers = action.data.assignedManagers;

            let mappedData = [];
            action.data.departments.forEach(department => {
                let foundOrgUnit = _.find(Object.keys(action.data.assignedManagers), (man => {
                        return man === department.internalOrgUnitId.toString();
                    }
                ));

                if (foundOrgUnit) {
                    let managersIds = action.data.assignedManagers[foundOrgUnit];
                    let managersNames = managersIds.map(id => {
                        let user = _.find(action.data.possibleManagers, manager => {
                            return manager.idUser === id
                        });

                        if (user) {
                            return {
                                value: user.idUser,
                                label: user.firstName + " " + user.lastName + "(" + user.email + ")"
                            };
                        }
                        else {
                            return {value: null, label: ""};
                        }
                    });

                    department.managers = managersNames;
                }
                mappedData.push(department);
            });

            partialState.allDepartments = mappedData;
            return Object.assign({}, state, partialState);


        case DEPARTMENTS_FILTER_CHANGED:
            partialState = Object.assign([], state);
            partialState.filter = action.data;

            return Object.assign({}, state, partialState);

        case ASSIGNED_DEPARTMENTS_LOADED: {
            partialState = Object.assign([], state);

            let departmentId = action.data.departmentId;
            let managers = action.data.departmentManagerIds;

            let changedDepartmentIndex = _.findIndex(partialState.allDepartments, department => {
                return department.internalOrgUnitId === departmentId;
            });

            if (changedDepartmentIndex !== -1) {
                partialState.allDepartments[changedDepartmentIndex].managers = managers;

                let managersNames = managers.map(function (id) {
                    let user = _.find(partialState.possibleManagers, function (manager) {
                        return manager.idUser === id;
                    });

                    if (user) {
                        return {
                            value: user.idUser,
                            label: user.firstName + " " + user.lastName + "(" + user.email + ")"
                        };
                    } else {
                        return { value: null, label: "" };
                    }
                });


                partialState.allDepartments[changedDepartmentIndex].managers = managersNames;
            }

            return Object.assign({}, state, partialState);
        }

        default:
            return state;
    }
}

export function getFormatedPossibleManagers(state) {
    if (!state.possibleManagers || state.possibleManagers.length === 0)
        return [];

    let response = state.possibleManagers.map(user => {
        return {
            value: user.idUser,
            label: user.firstName + " " + user.lastName + "(" + user.email + ")"
        }

    });
    return response;
}

export function getAssignedManagers(state) {
    return state.assignedManagers;
}

export function getDepartments(state) {
    return state.allDepartments;
}

export function getDepartmentsFilter(state) {
    return state.filter;
}

