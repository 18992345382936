import React, {Component} from 'react';
import {
    TASK_ACTIONS,
    DETAILS_VIEW_TYPE,
    DOCUMENT_OVERVIEW_STATUS,
    DOCUMENT_ACTIONS
} from 'utils/constants';
import {connect} from 'react-redux';
import {taskAction} from '../mytasks/action/taskAction.action';
import {navigateBetweenDocuments} from '../mytasks/action/navigateBetweenTasks.action';
import {
    getDocumentOverviewTasks,
    getDocumentOverviewActiveCounter,
    getUsersData,
    getTaskAttachments,
    getTaskDetails,
    getAttachmentsLoadingStatus,
    getHasDocumentKeyChanged
} from '../store/application.reducers';
import {DocumentDetailActions} from '../mytasks/AvailableActions.class';
import {pageLoaded} from '../router/route.action';
import {
    getDocumentCommentsData,
    getDocumentOverviewDetails,
    handleAddDocumentsComments,
} from "../taskdetails-remake/taskDetails.action";
import {formatTaskDetailsTopBanner} from 'utils/valueFormatter.function';
import {handleNPKeyNavigation} from 'utils/keyPressHandler.function';
import _ from 'lodash';
import {removeUpdatedDocumentKey} from "../documentoverview/documentOverview.action";
import {preventDocumentReload} from '../documentoverview/documentOverview.action';
import TaskDetails from 'components/taskdetails-remake/TaskDetails.component';
import {withRouter} from "components/router/withRouter.tsx";
import {PATH_TO_DOCUMENT_OVERVIEW} from "components/router/paths.js";
import {launchDarkly} from "utils/launchDarkly.ts";

const cl = class TaskDetailMasterLayer extends Component {
    constructor(props) {
        super(props);

        this.getActionsForTask = this.getActionsForTask.bind(this);
        this.getTaskDetailFromStore = this.getTaskDetailFromStore.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getNavigation = this.getNavigation.bind(this);
        this.isExternalEditorAvailable = this.isExternalEditorAvailable.bind(this);
        this.navigateBack = this.navigateBack.bind(this);
        this.navigateBetweenTasks = this.navigateBetweenTasks.bind(this);

        this.useNewAddComments = launchDarkly.companyClient && launchDarkly.companyClient.variation("fe-improve-add-comments", false);
    }

    /*
     * default view is the attachemnts page so we need to get the attachemnts first
     * */
    componentDidMount() {
        let hasEditor = this.isExternalEditorAvailable();

        this.props.getDocumentOverviewDetails(this.props.match.params.taskId, hasEditor);
        document.addEventListener('keydown', this.handleKeyPress);
    }

    //this is needed to reload data at navigate between tasks with ctrl+alt+n
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.taskId !== this.props.match.params.taskId) {
            let hasEditor = this.isExternalEditorAvailable();

            this.props.getDocumentOverviewDetails(this.props.match.params.taskId, hasEditor);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    navigateBetweenTasks(taskId, isForward) {
        this.props.history(this.props.navigateBetweenDocuments(taskId, isForward), {replace: true});
    }

    handleKeyPress(e) {
        handleNPKeyNavigation(e, this.props.match.params.taskId, this.navigateBetweenTasks);
    }

    isExternalEditorAvailable() {
        let task = this.getTaskDetailFromStore();

        if (task && (!task.isExternalEditorAvailable || task.readonly))
            return false;
        if (!this.props.userData.userRoles.globalRoles.isSystemAdministrator && !this.props.userData.userRoles.systemAdministrator && !this.props.userData.userRoles.superAdministrator)
            return false;

        return true;
    }


    navigateBack() {
        this.props.preventDocumentReload(true);
    }


    getTaskDetailFromStore() {
        const thisTaskArray = this.props.tasks.rows.filter(task => {
            return task.key === this.props.match.params.taskId;
        });
        return thisTaskArray[0];
    }

    getActionsForTask(navigation) {
        let activeActions = new DocumentDetailActions();

        // add email
        activeActions.enable(TASK_ACTIONS.EMAIL);

        // can do actions only is the user is sysAdmin and the task is blocked
        if (this.props.userData.userRoles.systemAdministrator && this.props.activeCounter === DOCUMENT_OVERVIEW_STATUS.BLOCKED) {
            if (this.props.userData.userRoles.approver) {
                activeActions.enable(DOCUMENT_ACTIONS.APPROVE_DIRECTLY);
                activeActions.enable(DOCUMENT_ACTIONS.REJECT_DIRECTLY);
            }
            activeActions.enable(DOCUMENT_ACTIONS.SEND_FOR_APPROVAL);
        }
        //check if move actions are available
        if (navigation.currentTask !== navigation.total) {
            //enable move to next
            activeActions.enable(TASK_ACTIONS.NEXT_TASK);
        }
        if (navigation.currentTask !== 1) {
            activeActions.enable(TASK_ACTIONS.PREVIOUS_TASK);
        }
        return activeActions;
    }

    getNavigation(task) {
        //fill in all the data found in the task
        let response = formatTaskDetailsTopBanner(task, true);

        response.companyName = task.companyName;
        //task number and number of selected tasks still need to be computed
        response.currentTask = _.findIndex(this.props.tasks.rows, (x => {
            return x.key === task.key;
        })) + 1;
        response.total = this.props.tasks.rows.length;
        return response;
    }


    render() {
        const task = this.getTaskDetailFromStore();
        if (task) {
            let navigation = this.getNavigation(task);
            const activeActions = this.getActionsForTask(navigation);
            let params = {
                taskId: this.props.match.params.taskId
            }

            return (
                <TaskDetails params={params}
                             task={task}
                             taskAction={this.props.taskAction}
                             attachments={this.props.attachments ? this.props.attachments : null}
                             taskDetails={this.props.taskDetails}
                             loadingStatus={this.props.loadingStatus}
                             navigation={navigation}
                             route={this.props.route}
                             navigateBetweenTasks={this.props.navigateBetweenDocuments}
                             activeActions={activeActions}
                             activeCounter={this.props.activeCounter}
                             navigateBack={this.navigateBack}
                             navigateBackPath={PATH_TO_DOCUMENT_OVERVIEW}
                             hideComments={!this.useNewAddComments}
                             isDocument={true}
                             routerBlocker={this.props.blocker}
                             type={DETAILS_VIEW_TYPE.DOCUMENT}
                             userRoles={this.props.userData.userRoles}
                             allowActionlessComments = {this.useNewAddComments}
                             addCommentHandler={this.props.handleAddDocumentsComments}
                             getCommentsHandler={this.props.getDocumentCommentsData}
                />
            );
        } else {
            return (<div><span className="appicon icon loading"/></div>);
        }
    }
}

const mapStateToProps = function (store) {
    return {
        tasks: getDocumentOverviewTasks(store),
        activeCounter: getDocumentOverviewActiveCounter(store),
        taskDetails: getTaskDetails(store),
        attachments: getTaskAttachments(store),
        userData: getUsersData(store),
        loadingStatus: getAttachmentsLoadingStatus(store),
        getHasDocumentKeyChanged: getHasDocumentKeyChanged(store)
    };
};
const DocumentTaskDetailWithLocation = withRouter(cl);
const connected = connect(mapStateToProps, {
    taskAction,
    navigateBetweenDocuments,
    pageLoaded,
    getDocumentOverviewDetails,
    removeUpdatedDocumentKey,
    preventDocumentReload,
    handleAddDocumentsComments,
    getDocumentCommentsData,
})(DocumentTaskDetailWithLocation);

export default connected;
