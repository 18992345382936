import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select';
import * as Api from 'utils/api/api.js';
import {connect} from 'react-redux';
import * as reduxSelectors from '../store/application.reducers';
import {handleError} from 'utils/errorHandle.function';
import {customStyles} from 'utils/reactSelectTheme';
import _ from "lodash";


const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="advanced-search-icon"/>
        </components.DropdownIndicator>
    );
};

class Search extends Component {

    static propTypes = {
        propagateValue: PropTypes.func, // callback to propage value to parent
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // displayed in the input
        includeSameUser: PropTypes.bool
    };

    static defaultProps = {
        value: '',
        includeSameUser: false
    };


    constructor(props) {
        super(props);
        this.state = {
            options: [],
            inputValue: ''
        };
        this.htmlInput = null;
        this.onChange = this.onChange.bind(this);
        this.focus = this.focus.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.filterOptions = this.filterOptions.bind(this);
        this.delayedCall = _.debounce((input) => this.getUsers(input), 700);
    }

    componentDidMount() {
        this.focus();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.refresh !== this.props.refresh && this.props.refresh) {
            this.focus();
        }
    }

    componentWillUnmount() {
        this.delayedCall.cancel();
    }

    /**
     * focus the input of the component
     */
    focus() {
        if (!this.props.disabled && !this.props.stopFocus)
            this.htmlInput.focus();
        if (this.props.releaseFocus)
            this.props.releaseFocus();
    }

    mapSearchValue({id, valid,value,label}){
        let result={id, valid,value,label};
        return result;
    }


    onChange(selected) {
        if (this.props.multi && selected) {
            let values = selected.map(value => {
                return this.mapSearchValue(value);
            });
            this.props.propagateValue(values);
        } else {
            if(!selected){
                this.props.propagateValue(null);
                return;
            }
            this.props.propagateValue(this.mapSearchValue(selected));
        }
    }

    filterOptions(input) {
        this.delayedCall(input);
        this.setState({
            inputValue: input
        })
    }

    getUsers(input) {
        // needs to be overwritten
    }

    arrowRenderer() {
        return (<span className="advanced-search-icon"/>);
    }


    render() {
        let value = this.props.value && this.props.value.value ? this.props.value : "";
        if (this.props.multi) {
            value = this.props.value;
        }
        return (
            <div className="user-search react-select-container">
                <Select
                    ref={htmlInput => {
                        this.htmlInput = htmlInput
                    }}
                    aria-label={this.props.ariaLabel ? this.props.ariaLabel : "search"}
                    styles={this.props.styles ? this.props.styles : customStyles}
                    components={{DropdownIndicator}}
                    menuPlacement={this.props.menuPlacement ? this.props.menuPlacement : "bottom"}
                    menuPosition={this.props.menuPosition ? this.props.menuPosition : "absolute"}
                    name="form-field-name"
                    maxMenuHeight={this.props.maxMenuHeight ? this.props.maxMenuHeight : 200}
                    menuShouldScrollIntoView={true}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    isMulti={this.props.multi}
                    tabIndex={this.props.tabindex}
                    options={this.props.options ? this.props.options : this.state.options}
                    placeholder={this.props.placeholder}
                    arrowRenderer={this.arrowRenderer.bind(this)}
                    onInputChange={this.filterOptions}
                    value={value}
                    onChange={this.onChange}
                    autoFocus={this.props.stopFocus ? !this.props.stopFocus : !this.props.disabled}
                    isDisabled={this.props.disabled}
                    isClearable={this.props.clearable}
                    noOptionsMessage={() => this.state.inputValue === '' ? null : this.props.translate("userSearch.noSuchUser")}
                    blurInputOnSelect={true}
                />
            </div>

        );
    }
}

export class FindEmployees extends Search {
    constructor(props) {
        super(props);
        this.getUsers = this.getUsers.bind(this);
    }


    getUsers(input) {
        if (!input)
            return Promise.resolve({options: []});

        Api.findEmployees(input).then(response => {
            const results = [];
            const filterValues = this.props.filterValues || [];

            response.forEach(item => {
                if (item.key && !filterValues.includes(item.key)) {
                    results.push({
                        label: item.nameAndEmail,
                        id: item.employeeId,
                        value: item.key
                    });
                }
            });
            this.setState({options: results});
        }, error => {
            handleError(error);
        });
    }
}

export class ExceptionsRoles extends Search {
    constructor(props) {
        super(props);
        this.getUsers = this.getUsers.bind(this);
    }


    getUsers(input) {
        if (!input)
            return Promise.resolve({options: []});

        Api.searchExceptionsRole(input).then(response => {
            let results = response.map(item => {
                return {
                    label: item.name,
                    value: item.roleId
                };
            });
            this.setState({options: results});
        }, error => {
            handleError(error);
        });
    }
}


export class UserSearch extends Search {
    constructor(props) {
        super(props);
        this.getUsers = this.getUsers.bind(this);
    }


    getUsers(input) {
        if (!input)
            return Promise.resolve({options: []});

        let id = this.props.taskId;
        let includeSameUser = this.props.includeSameUser;
        const isAdminApprover = this.props.includeAdminApprover && this.props.userConfiguration.userRoles.approver && this.props.userConfiguration.userRoles.systemAdministrator;

        Api.userSearch(input, id).then(response => {
            let results = [];
            response.forEach(item => {
                //remove the current loggin user from the search
                if (!this.props.userConfiguration || item.key !== this.props.userConfiguration.currentUserId || includeSameUser || (item.key === this.props.userConfiguration.currentUserId && isAdminApprover)) {
                    results.push({
                        label: item.nameAndEmail,
                        value: item.key
                    });
                }
            });
            this.setState({options: results});
        }, error => {
            handleError(error);
        });
    }
}

const mapStateToProps = function (store) {
    return {
        userConfiguration: reduxSelectors.getUsersData(store)
    };
};
const connected = connect(mapStateToProps, {})(UserSearch);

export default connected;