import React from 'react';
import PropTypes from 'prop-types';
import {PopUp} from '../../popup/PopUp.component';
import {CONFIRM_POPUP_TYPE} from '../../../utils/constants';
import UserSearch, {ExceptionsRoles, FindEmployees} from "../../input/UserSearch.component";


//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

export class AddExceptionPopup extends PopUp {

    static propTypes = {
        closeCallback: PropTypes.func, //callback for closing the popup
        handleAction: PropTypes.func, //callback for closing the popup
        translate: PropTypes.func, // to translate texts
        hideClose: PropTypes.bool //do not show the close button
    };

    constructor(props) {
        super(props);
        this.buildContent = this.buildContent.bind(this);
        this.state = {
            approver: undefined,
            requester: undefined,
            focusFirst: false,
            role: undefined,
            saveDisabled: true
        };

        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.setApprover = this.setApprover.bind(this);
        this.setRequester = this.setRequester.bind(this);
        this.setRole = this.setRole.bind(this);
        this.checkSave = this.checkSave.bind(this);
        this.focusFirst=this.focusFirst.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.setState({
            focusFirst: true
        });
    }

    focusFirst(){
        this.employeeInput?.focus();
    }
    cancel() {
        this.setState({
            approver: undefined,
            requester: undefined
        });

        this.props.closeCallback();
    }

    checkSave() {
        let hasAllValue = Boolean(this.state.requester);

        hasAllValue = hasAllValue && this.state.approver && this.state.approver.length > 0;
        hasAllValue = hasAllValue && this.state.role;

        this.setState({saveDisabled: !hasAllValue});
    }

    save() {
        let users = this.state.approver ? this.state.approver.map((user => {
            return {valid: user.valid, value: user.value, label: user.label};
        })) : [this.state.approver];

        let newRow = {
            valid: this.state.requester.valid,
            label: this.state.requester.label,
            value: this.state.requester.value,
            users: users,
            role: this.state.role,
        };

        this.props.handleAction(newRow);
    }

    setApprover(value) {
        this.setState({approver: value}, () => {
            this.checkSave();
        });

    }

    setRequester(value) {
        this.setState({requester: value, focusFirst: false}, () => {
            this.checkSave();
        });
    }

    setRole(value) {
        this.setState({role: value}, () => {
            this.checkSave();
        });
    }

    getTitle() {
        return this.props.translate("workflowDetails.exceptions.addNewException");
    }

    getModalClasses(){
        return "py-5";
    }

    buildContent() {
        const filterValues = this.props.noDuplicates ? (this.props.rows || []).map((row) => row.value) : [];

        return (
            <div className="add-new-exception-popup">
                <div className="row">
                    <div className="col-md-12">
                        <label>{this.props.translate("workflowDetails.exceptions.requester")}</label>
                        <FindEmployees propagateValue={this.setRequester}
                                       ref={htmlInput => {
                                           this.employeeInput = htmlInput
                                       }}
                                       filterValues={filterValues}
                                       value={this.state.requester}
                                       refresh={this.state.focusFirst}
                                       tabindex={TAB_INDEX}
                                       translate={this.props.translate}
                                       releaseFocus={()=>{this.setState({refresh:false})}}/>
                    </div>
                    <div className="col-md-12">
                        <label>{this.props.translate("workflowDetails.exceptions.role")}</label>

                        <ExceptionsRoles propagateValue={this.setRole}
                                         isRule={true}
                                         value={this.state.role}
                                         tabindex={TAB_INDEX+1}
                                         translate={this.props.translate}
                                         releaseFocus={()=>{this.setState({refresh:false})}}/>
                    </div>
                    <div className={"col-md-12"}>
                        <label>{this.props.translate("workflowDetails.exceptions.approvers")}</label>
                        <UserSearch multi={true}
                                    propagateValue={this.setApprover}
                                    value={this.state.approver}
                                    includeAdminApprover={true}
                                    maxMenuHeight={150}
                                    tabindex={TAB_INDEX+2}
                                    translate={this.props.translate}
                                    menuPlacement={"top"}
                                    releaseFocus={()=>{this.setState({refresh:false})}}/>
                    </div>
                </div>

                <div className="panel-footer">
                    <div className="float-end pe-4">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.save}
                                disabled={this.state.saveDisabled}
                                tabIndex={TAB_INDEX+3}>
                            {this.props.translate("workflowDetails.exceptions.addException")}
                        </button>
                        {!this.props.hideClose &&
                            <button className="btn btn-default"
                                    onClick={this.cancel}
                                    onBlur={this.focusFirst}
                                    tabIndex={TAB_INDEX+4}>{this.props.translate("popUp.cancel")}</button>
                        }
                    </div>
                </div>
            </div>

        );
    }

    getType() {
        if (this.props.popUpType)
            return this.props.popUpType;
        return CONFIRM_POPUP_TYPE;
    }

}

export default AddExceptionPopup;