import * as LDClient from "launchdarkly-js-client-sdk";
import {LDClientBase} from "launchdarkly-js-sdk-common";

interface LaunchDarklyType {
    tenantClients: { [key: string]: LDClientBase };
    userClient: LDClientBase | null;
    companyClient: LDClientBase | null;

    createClient(context: any): Promise<LDClientBase>;

    setCompany(company: any): Promise<void>;
}

export const LaunchDarklyContextKind = {
    USER: 'user',
    TENANT: 'tenant',
    APPLICATION: 'application',
};


export const launchDarkly: LaunchDarklyType = {
    tenantClients: {},
    userClient: null,
    companyClient: null,
    createClient(context) {
        if (launchDarkly.tenantClients[context.key]) {
            return Promise.resolve(launchDarkly.tenantClients[context.key]);
        }

        return new Promise((resolve) => {
            const client = LDClient.initialize(window.ENVIRONMENT.LAUNCH_DARKLY_CLIENTSIDE_ID, context);

            client.on('ready', () => {
                if (context.kind === LaunchDarklyContextKind.USER) {
                    launchDarkly.userClient = client;
                }

                if (context.kind === LaunchDarklyContextKind.TENANT) {
                    launchDarkly.companyClient = client;
                }

                launchDarkly.tenantClients[context.key] = client;

                resolve(client);
            });
        });
    },
    async setCompany(company) {
        if (company) {
            const tenantContext = {
                "kind": LaunchDarklyContextKind.TENANT,
                "key": company.tenantId || `company-${company.id}`,
                "name": company.name,
                "customer": company.idCustomer,
                "distributor": company.distributorName,
                "demo": company.demonstration,
                "odpcompanyId": company.odpcompanyId,
            };

            launchDarkly.companyClient = await launchDarkly.createClient(tenantContext);
        }
    }
};
