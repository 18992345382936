import React, {Component} from 'react';
import Confirm from '../popup/ConfirmActionPopup.component';
import history from '../router/history';

let move = false;
let unblockRouter = null;

export class SettingsWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showWarning: false,
            nextLocation: ''
        }
        this.remain = this.remain.bind(this);
        this.leave = this.leave.bind(this);
        this.getData = this.getData.bind(this);
        this._routerWillLeave = this._routerWillLeave.bind(this);

    }

    componentDidMount() {
        move = false;
        // if (!unblockRouter)
        //     unblockRouter=history.block(this._routerWillLeave);
        this.getData();
    }

    componentWillUnmount() {
        // if(unblockRouter) {
        //     unblockRouter();
        //     unblockRouter = null;
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.context !== prevProps.context) {
            this.getData();
            this.setState({
                currentContext: prevProps.context
            });
        }
    }

    _routerWillLeave(nextLocation) {
        //user made changes
        if (this.state.okEnabled) {
            if (move) {
                return true;
            } else {
                this.setState({
                    showWarning: true,
                    nextLocation: nextLocation,
                    move: false
                });
                return false;
            }
        }
        return true;
    }

    leave() {
        move = true;
        this.props.blocker.blocker?.proceed();
        this.setState({
            showWarning: false
        });
        // history.push(this.state.nextLocation.pathname);
    }

    remain() {
        this.props.blocker.blocker?.reset();
        this.setState({
            showWarning: false
        });
    }

    getData() {
        // to be overriden by children
    }

    buildContent() {
        // to be overriden by children
    }

    render() {
        let content = this.buildContent();
        return (
            <div>
                {this.props.blocker?.isNavigationBlocked() &&
                    <Confirm translate={this.props.translate}
                             message={this.props.translate("settings.confirmPageLeave")}
                             handleAction={this.remain}
                             closeCallback={this.leave}/>
                }
                {content}
            </div>
        )
    }
}

export default SettingsWrapper;