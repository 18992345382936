import wNumb from 'wnumb';
import Immutable from 'seamless-immutable';

/**
 *  wrapper around wNumb with templates for each supported locale
 *  because numeral/numbro doesn't support this behavior (numbro does, but it's definitions doesn't match ours)
 */

/**
 * definitions for each supported locale
 * @type {{en: *, da: *, nl: *, sv: *, no: *, fi: *}}
 *
 * @see https://refreshless.com/wnumb/options/
 */
const DEFINITIONS = Immutable.from({
    'en': wNumb({
        decimals: 2,
        mark: '.',
        thousand: ' '
    }),
    'da': wNumb({
        decimals: 2,
        mark: ',',
        thousand: ' '
    }),
    'nl': wNumb({
        decimals: 2,
        mark: ',',
        thousand: '.'
    }),
    'sv': wNumb({
        decimals: 2,
        mark: ',',
        thousand: ' '
    }),
    'no': wNumb({
        decimals: 2,
        mark: ',',
        thousand: ' '
    }),
    'fi': wNumb({
        decimals: 2,
        mark: ',',
        thousand: ' '
    })
});

const DEFINITIONS_FORMAT = Immutable.from({
    'en': {
        decimals: 2,
        mark: '.',
        thousand: ' '
    },
    'da': {
        decimals: 2,
        mark: ',',
        thousand: ' '
    },
    'nl': {
        decimals: 2,
        mark: ',',
        thousand: '.'
    },
    'sv': {
        decimals: 2,
        mark: ',',
        thousand: ' '
    },
    'no':{
        decimals: 2,
        mark: ',',
        thousand: ' '
    },
    'fi': {
        decimals: 2,
        mark: ',',
        thousand: ' '
    }
});

/**
 * singleton :) it holds current value
 * @type {*}
 */
let CURRENT = 'en';

/**
 * formats amount
 * @param amount
 * @param definitions optional, used only from unit tests, it has to be object with at least key 'en'
 */
export function formatCurrency(amount, definitions = DEFINITIONS) {
    let normalized = parseFloat(amount);
    if(isNaN(normalized)) {
        normalized = 0;
    }

    return _pickDefinition(CURRENT, definitions).to(normalized);
}
function _pickDefinition(locale, definitions) {

    let mask = locale;
    if(locale ==='nb') {
        mask = 'no';
    }
    if(locale ==='se') {
        mask = 'sv';
    }

    return definitions[mask] ? definitions[mask]  : definitions.en;
}
/**
 * switch between locales
 */
export function changeLocale(locale) {
    CURRENT = locale;
}

export function getLocaleSeparator(locale) {
    return DEFINITIONS_FORMAT[locale].mark;
}
export function getLocaleThousandSeparator(locale) {
    return DEFINITIONS_FORMAT[locale].thousand;
}

export default changeLocale;
