import React from 'react';
import {formatDate, formatDocumentOrganizationUnit} from '../../../../utils/valueFormatter.function';

const LeaveRequestDocumentDetailsDTO = ({details, translate}) => {
    let displayId = details.displayId ? details.displayId : details.documentId;
    return (
        <div>
            {details.description &&
            <div className="document-data-field">
                <div className="document-data-field-label">
                    {translate("documentData.description")}
                </div>
                <div className="document-data-field-value"
                     title={details.description}>
                    {details.description}
                </div>
            </div>}

            <div className="document-data-divided">

                {details.userName &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.requester")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.userName}>
                        {details.userName}
                    </div>
                </div>}

                {details.employeeCode &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.employeeCode")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.employeeCode}>
                        {details.employeeCode}
                    </div>
                </div>}

                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.documentID")}
                    </div>
                    <div className="document-data-field-value"
                         title={displayId}>
                        {displayId}
                    </div>
                </div>

                {details.documentOrganizationUnit &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.organizationUnit")}
                    </div>
                    <div className="document-data-field-value"
                         title={formatDocumentOrganizationUnit(details.documentOrganizationUnit)}>
                        {formatDocumentOrganizationUnit(details.documentOrganizationUnit)}
                    </div>
                </div>}

                {details.receivedDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.receivedDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.receivedDate)}
                    </div>
                </div>}

                {details.dueDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.dueDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.dueDate)}
                    </div>
                </div>}

                {details.fromDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.fromDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.fromDate)}
                    </div>
                </div>}

                {details.toDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.toDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.toDate)}
                    </div>
                </div>}

                {details.eventCode &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.eventCode")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.eventCode}>
                        {details.eventCode}
                    </div>
                </div>}

                {details.numberOfDays &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.numberOfDays")}
                    </div>
                    <div className="document-data-field-value">
                        {details.numberOfDays}
                    </div>
                </div>}
            </div>
        </div>
    )
};

export default LeaveRequestDocumentDetailsDTO;
