import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Comment from '../input/Comment.component';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import {TASK_ACTIONS} from 'utils/constants';
import translate from '../translations/translations.wrapper.jsx';
import {connect} from 'react-redux';
import {getUsersSettings} from '../store/application.reducers';
import * as Api from '../../utils/api/api';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'approve with comment'
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class Approve extends PopUp {

    static propTypes = {
        tasks: PropTypes.array, // selected tasks
        closeCallback: PropTypes.func, //callback for closing the popup
        handledTaskCallback: PropTypes.func, //callback to mark task as handled
        translate: PropTypes.func, // to translate texts
        showNotification: PropTypes.func, // to show transient notification
        approveMultipleTasks: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);

        this.state = {
            comment: '',
            hideDialog: false,
            actionRunning: false // we're waiting for backend call result
        }

        this.ok = this.ok.bind(this);
    }


    ok() {
        this.setState({
            actionRunning: true
        });


        const taskIds = this.props.tasks.map((task) => {
            return task.key;
        });


        if (this.state.hideDialog) {
            let newData = this.props.userSettings;
            newData.showCommentOnApprove = false;
            Api.updateUserSettings(newData);
        }

        // this redirects to myTasks
        this.props.approveMultipleTasks(taskIds, this.state.comment, undefined, undefined, false, this.props.navigate)
        this.props.closeCallback();
    }

    componentDidMount() {
        super.componentDidMount();
        this.okButton.focus();
    }

    buildContent() {

        const okEnabled = !this.state.actionRunning;
        return (
            <Fragment>
                <div className="modal-body">
                    {this.props.showComments ?
                        (<div>
                            <div>
                                <label htmlFor="comment">{this.props.translate("popUp.approve.commentLabel")}</label>
                            </div>
                            <VisibleToEverybody translate={this.props.translate}/>

                            <Comment
                                ref="commentInput"
                                inputHint={this.props.translate("popUp.approve.commentHint")}
                                translate={this.props.translate}
                                autoFocus={true}
                                tabIndex={TAB_INDEX}
                                id="comment"
                                value={this.state.comment}
                                propagateValue={value => this.setState({comment: value})}
                            />
                            <div className="checkbox my-3" ref="hideApproveDialog">
                                <input type="checkbox"
                                       id="hideApproveDialog"
                                       onChange={event => this.setState({hideDialog: event.target.checked})}/>
                                <label className="form-check-label" htmlFor="hideApproveDialog">
                                    {this.props.translate("popUp.approve.hideDialog")}
                                </label>
                            </div>
                        </div>) :
                        (
                            <span>{this.props.translate("popUp.approve.approveNTasks", this.props.tasks.length)}</span>
                        )
                    }
                </div>

                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.ok}
                                disabled={!okEnabled}
                                ref={(element) => {
                                    this.okButton = element;
                                }}
                                tabIndex={TAB_INDEX + 1}>
                            {this.props.translate("popUp.ok")}
                        </button>
                        <button className="btn btn-default"
                                onBlur={() => {
                                    this.refs.commentInput.focus();
                                }}
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 2}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </Fragment>

        );
    }

    getType() {
        return TASK_ACTIONS.APPROVE;
    }

    getTitle() {
        if (this.props.tasks.length > 1) {
            return this.props.translate("popUp.approve.multipleApproveTitle");
        }
        return this.props.translate("popUp.approve.title");
    }

}

const withTranslations = translate(Approve);
//export default withTranslations;

const mapStateToProps = function (store) {
    return {
        userSettings: getUsersSettings(store)
    };
};

const connected = connect(mapStateToProps, {})(withTranslations);
export default connected;