import Immutable from "seamless-immutable";
import moment from "moment";

export const FLATPICKR_DATE_FORMATS = Immutable.from({
    "en": {
        short: 'd/m/Y',
        long: "DD/MM/YYYY"
    },
    "da": {
        short: "d-m-Y",
        long: "DD-MM-YYYY"
    },
    "fi": {
        short: "d.m.Y",
        long: "DD.MM.YYYY"
    },
    "nl": {
        short: "d-m-Y",
        long: "DD-MM-YYYY"
    },
    "no": {
        short: "d.m.Y",
        long: "DD.MM.YYYY"
    },
    "sv": {
        short: "Y-m-d",
        long: "YYYY-MM-DD"
    },
    "nb": {
        short: "d.m.Y",
        long: ""
    }
});


export function getDateFormatFromLanguage(language = "en") {
    let lang = moment().locale(language === "en" ? "en-gb" : language);
    return lang.localeData()._longDateFormat.L;
}

export function getDateTimeFormatFromLanguage(language = "en") {
    let lang = moment().locale(language === "en" ? "en-gb" : language);
    return lang.localeData()._longDateFormat.L + " " + lang.localeData()._longDateFormat.LTS;
}