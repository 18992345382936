/**
 * WHY:
 * AppDynamics doesn't understand navigation using HTML History API so we have to manually track (virtual)pages
 * when navigation using react-router
 *
 * Tracking (virtual)page means creating JS object, calling its start() for the page start and when the transition is
 * done and content is loaded, call end() on it and send the object into AppDynamics. If we need more granularity
 * (for example to distinct the navigation and loading of the data for the component on the route) we have to call
 * other mark* methods on the same object.
 *
 * HOW:
 * This container component holds the current instance of page tracker and listens for incoming notification
 * (precisely Redux state change caused by 'pageLoaded' Redux action) about the end of loading,
 * then it will send it into AD. It has to be directly below the router, so it gets notified of the navigation,
 *  which is passed from the router as props.location
 *
 * because we're stopping measuring by Redux action, it adds some small delay into the statistics (to process it)
 * if it won't be acceptable, we can for example export the functions into window object and call them directly
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/History_API
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AppInitialization from "../startup/AppInitialization.component.jsx";
import {getRouteTrackingAction} from '../store/application.reducers';
import {ACTION, pageTracked} from './route.action';
import {
    getPageStartTracker,
    pageEnd,
    pageSwitched,
    prematurePageEnd,
    xhrRequestsCompleted
} from '../../utils/appDynamics.function';
import * as logger from '../../utils/logger.function.js';
import {getSnowplowContexts} from "../../utils/snowplow";
import * as reduxSelectors from "../store/application.reducers";
import {withRouter} from "components/router/withRouter.tsx";


export class VirtualPageTracker extends Component {

    static propTypes = {
        pageTrackingAction: PropTypes.number, // mapped action discriminator from the redux action
        location: PropTypes.object, // 'target' path from the router
        pageTracked: PropTypes.func // redux action to clear store
    };

    constructor(props) {
        super(props);
        this.vPageView = null;
        this.pageTrackingLogic = this.pageTrackingLogic.bind(this);
        this.pageTrackingLogic(props.pageTrackingAction, null, props.location.pathname);
    }

    //Moved to componentDidUpdate
    /*  componentWillReceiveProps(nextProps) {
          this.pageTrackingLogic(nextProps.pageTrackingAction, this.props.location.pathname, nextProps.location.pathname);
      }*/

    componentDidUpdate(prevProps) {
        if (this.vPageView !== null) {
            pageSwitched(this.vPageView);
        }

        this.pageTrackingLogic(this.props.pageTrackingAction, prevProps.location.pathname, this.props.location.pathname);

    }

    /**
     * @param pageTrackingAfter new page tracking action
     * @param approvalWebPageBefore path router before the transition (for example '/' for myTasks, '/history' for myHistory)
     * @param approvalWebPageAfter path route after the transition (for example '/' for myTasks, '/history' for myHistory),
     * @param window.location.href hidden parameter, AppDynamics needs whole url including the protocol
     */
    pageTrackingLogic(pageTrackingAfter, approvalWebPageBefore, approvalWebPageAfter) {
        // if it's redux action to close the tracking of the route
        if (pageTrackingAfter === ACTION.markViewDOMLoaded) {
            pageSwitched(this.vPageView);
        } else if (pageTrackingAfter === ACTION.markXhrRequestsCompleted) {
            xhrRequestsCompleted(this.vPageView);
        } else if (pageTrackingAfter === ACTION.end) {
            pageEnd(this.vPageView);
            this.vPageView = null;
            // this cleans the pageTrackingAction from the store, eg causes new call of this soon after
            this.props.pageTracked();
        } else if (approvalWebPageBefore !== approvalWebPageAfter) {
            // if there is unfinished page, report it straight away
            if (this.vPageView !== null) {
                logger.error('VirtualPageTracker', 'sending unfinished VirtualPage to AD', this.vPageView);
                prematurePageEnd(this.vPageView);
            }
            // overwrite instance with new virtual page
            this.vPageView = getPageStartTracker(window.location.href);

            //Trigger pageView on re-route
            window.snowplow('trackPageView', {
                'title': null,
                'context': this.props.userConfiguration ? getSnowplowContexts(this.props.userConfiguration) : []
            });
        }
    }

    render() {
        const propsWithoutRouteTracker = Object.assign({}, this.props);
        delete propsWithoutRouteTracker.pageTrackingAction;
        return <AppInitialization {...propsWithoutRouteTracker} />;
    }

}

const mapStateToProps = function (store) {
    return {
        pageTrackingAction: getRouteTrackingAction(store),
        userConfiguration: reduxSelectors.getUsersData(store)
    };
};

const VirtualPageTrackerWithLocation = withRouter(VirtualPageTracker);
export default connect(mapStateToProps, {pageTracked})(VirtualPageTrackerWithLocation);

