import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getDictionary, getLanguage, getFallbackDictionary} from '../store/application.reducers';
import {replacePlaceholderInTranslation} from '../../utils/valueFormatter.function';
import {requestLanguageChange} from './translations.action';
import store from '../store/Store.component';

/**
 * to be used in unit tests for unwrapped components
 * @param key
 * @returns {*}
 */
export function mockTranslate(key) {
    return key;
}

/**
 * logic of finding key in dictionary exposed as own function, for easy unit testing
 */
export function findProperTranslation(key, dictionary, fallbackDictionary) {
    //make sure we always have translations available
    if (dictionary === null) {
        store.dispatch(requestLanguageChange('en'));
    }
    if (typeof dictionary !== 'object' ||
        fallbackDictionary === null || typeof fallbackDictionary !== 'object') return key;
    if (dictionary[key]) return dictionary[key];
    if (fallbackDictionary[key]) return fallbackDictionary[key];
    else return key;
}

/**
 * translate key with value from the dictionary, and optionally replacing placeholders in the translations with additional arguments
 * @param key for translation
 * @param selected dictionary
 * @param fallback dictionary (en)
 * @param .. additional values, to be replacement for %[number] placeholders in the strings
 * @return {*}
 *
 */
export function translateWithPlaceholders(key, dictionary, fallbackDictionary) {
    // basic translate function
    const translated = findProperTranslation(key, dictionary, fallbackDictionary);
    // handle additional arguments as replacement for placeholders
    if (arguments.length > 3) {
        let args = Array.prototype.slice.call(arguments, 3);
        return replacePlaceholderInTranslation(translated, ...args);
    } else {
        return translated;
    }
}


/**
 * translation function for the context of asynchronous redux actions
 * @param key
 */
export function translate(wholeStoreState, key) {
    const dictionary = getDictionary(wholeStoreState);
    const fallbackDictionary = getFallbackDictionary(wholeStoreState)
    // additional arguments, if any
    let args = Array.prototype.slice.call(arguments, 2);
    return translateWithPlaceholders(key, dictionary, fallbackDictionary, ...args);
}


/**
 * high order component, to decorate any common component to add localization for strings,
 * adds props.translate(key) function
 *
 * usage:
 * import App from './App.component.js';
 * import wrap from './withTranslation.wrapper.js';
 * export default wrap(App);
 *
 * and inside App:
 *  render() {
  *    return (<span>{this.props.translate('app.name')}</span>);
  *  }
 *
 * @param WrappedComponent
 * @returns {*}
 */
export default function translationsWrapper (WrappedComponent) {

    class WrappedWithTranslation extends Component {

        constructor() {
            super();
            this.translate = this.translate.bind(this);
            this.translationKeyExists = this.translationKeyExists.bind(this);
        }

        // add props.translate function to original component
        render() {
            // remove the translations injected by store from props
            let propsWithoutTranslations = Object.assign({}, this.props);
            delete propsWithoutTranslations.translations;
            delete propsWithoutTranslations.fallbackTranslations;
            delete propsWithoutTranslations.language;

            return (
                <WrappedComponent
                    {...this.state}
                    {...propsWithoutTranslations}
                    translate={this.translate}
                    translationKeyExists={this.translationKeyExists}
                />
            );
        }


        translate(key) {
            // additional arguments
            let args = Array.prototype.slice.call(arguments, 1);

            // push the dictionaries as the first arguments, to be able to translate with placeholders for replacement in the translations
            return translateWithPlaceholders(key, this.props.translations, this.props.fallbackTranslations, ...args);
        }

        translationKeyExists(key) {
            return this.translate(key) !== key;
        }
    }

    // and register wrapped component to translations in store
    const mapStateToProps = function (store) {
        return {
            // local : store (.translations for combineReducer, .dictionary for data in state)
            /**
             * we have to map language, because the translations get filled at some point and then changes inside wont propagate render
             */
            language: getLanguage(store),
            translations: getDictionary(store),
            fallbackTranslations: getFallbackDictionary(store)
        };
    };
    return connect(mapStateToProps, null)(WrappedWithTranslation);

}
