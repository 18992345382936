import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Api from '../../utils/api/api';
import {LOADING_STATUS} from '../../utils/constants';
import VirtualizedSelect from 'react-virtualized-select';
import './user_context_selector.scss';

import classNames from 'classnames';

/**
 * handle loading of context selector (keep Api call separated from presentational component
 */
export default class UserContextSelector extends Component {

    static propTypes = {
        translate: PropTypes.func, // localization
        currentContextId: PropTypes.number, // current user's context
        onSelection: PropTypes.func, // propagate selected value
        collapseSelection: PropTypes.func // close the selector, without propagating
    };

    constructor(props) {
        super(props);
        this.state = {
            list: [], // all values - we don't load with pagination
            state: LOADING_STATUS.LOADING

        };
        this.requestFromBackend = this.requestFromBackend.bind(this);
    }

    componentDidMount() {
        this.requestFromBackend();
    }

    requestFromBackend() {
        this.setState({state: LOADING_STATUS.LOADING});
        let self = this;

        Api.getUserContexts()
            .then(function (result) {
                self.setState({list: result, state: LOADING_STATUS.DONE});
            }, function (err) {
                console.error(err);
                self.setState({state: LOADING_STATUS.ERROR});
            });

    }

    render() {

        return (
            <AngularUiSelectToReactSelect
                loadingState={this.state.state}
                options={this.state.list}
                translate={this.props.translate}
                currentContextId={this.props.currentContextId}
                onSelection={this.props.onSelection}
                collapseSelection={this.props.collapseSelection}
                companyName={this.props.companyName}
            />
        );
    }
}


/**
 * original component uses angular-ui-select component, which has no react equivalent, we're using react-select
 * with modifications to match original:
 * - 'no match' result is outside the input box
 * - loading status is outside the input box
 * - custom value renderer, because original component does not show preselected value inside input
 * - custom menu&options renderer to match html from original (class for focused option)
 */
export class AngularUiSelectToReactSelect extends Component {

    static propTypes = {
        translate: PropTypes.func, // localization
        currentContextId: PropTypes.number, // current user's context
        onSelection: PropTypes.func, // propagate selected value
        collapseSelection: PropTypes.func, // close the selector, without propagating
        options: PropTypes.array, // all values - we don't use pagination for contexts
        loadingState: PropTypes.number // loading status
    };

    constructor(props) {
        super(props);
        // value for the input, we need it to filter options and for ESC handling
        this.state = {
            value: ''
        };
        this.htmlInput = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.valueRenderer = this.valueRenderer.bind(this);
        this.optionRenderer = this.optionRenderer.bind(this);
        this.customEscHandling = this.customEscHandling.bind(this);
        /**
         * implementation of react-select collapse options upon clearing input, but ODP component keeps it opened
         * so we trigger remounting of the component with new (empty) value instead, by default the dropdown is opened  ~ option #openOnFocus )
         *
         * easiest way to force remounting is to change the 'key' of the element (mounted component), this would be used as  key ..
         * and when we need rerender, then we change it
         *
         * @see original implementation: method #clearValue https://github.com/JedWatson/react-select/blob/master/src/Select.js
         */
    }


    onChange(option) {
        // clearing the input triggers this (with undefined), not onInputChange
        if (option) {
            this.props.onSelection(option);
        } else {
            // in case user already cleared the input, collapse the whole selector
            if (this.state.value === '') {
                this.props.collapseSelection();
            } else {
                this.setState({value: ''});
            }
        }
    }

    onInputChange(input) {
        this.setState({value: input});
    }

    customEscHandling(event) {
        // escape
        if (event.keyCode === 27) {
            // stop original react-select implementation from triggering - we need this now
            //event.preventDefault();
            // and handle it like the user clicked on 'clear' button
            this.onChange(null);
        }
    }

    render() {

        let status;
        // let currentContextId = self.props.currentContextId;

        switch (this.props.loadingState) {
            case LOADING_STATUS.LOADING:
                status = <i className="glyphicon glyphicon-refresh"/>;
                break;
            case LOADING_STATUS.DONE:
                if (this.props.options.length !== 0 && this.state.value !== '') {
                    status = (<p>{this.props.translate('menu.userBox.contextNoMatch')}</p>);
                } else {
                    status = null;
                }
                break;

            case LOADING_STATUS.ERROR:
            default:
                status = (<p>{this.props.translate('menu.userBox.contextError')}</p>);
        }
        return (
            <div className="ui-select-bootstrap" id="user-context-selector">
                    <VirtualizedSelect
                        class="select"
                        ref={this.htmlInput}
                        labelKey="name"
                        valueKey="id"
                        name="user-context"
                        simpleValue
                        searchable={true}
                        options={this.props.options}
                        onChange={this.onChange}
                        clearable={false}
                        placeholder={this.props.companyName ? this.props.companyName : this.props.translate('menu.userBox.contextPlaceholder')}
                        value={this.props.currentContextId}
                        arrowRenderer={this.arrowRenderer}
                        optionRenderer={this.optionRenderer}
                        noResultsText={false}
                        onInputKeyDown={this.customEscHandling}
                        menuRenderer={this.menuRenderer}
                    />
                {status}
            </div>
        );
    }


    // ----------------- custom renderers for react-select ---------------

    valueRenderer(option) {
        /**
         * odp component doesn't show 'preselected value' inside input, which react-select does ..
         * so we have custom renderValue for that case here to display placeholder instead
         */
        return option.active ? option.label : this.props.translate('menu.userBox.contextPlaceholder');
    }

    optionRenderer(option) {
        let isSelected = option.option.id === this.props.currentContextId;
        let isFocus = option.focusedOption.id === option.option.id;

        let captureKeyDown = function (event) {
            if (event.keyCode === 13) {
                option.selectValue(option.option);
            }
        }

        return (
            <div key={option.key} style={option.style}
                 tabIndex={option.tabIndex}
                 className={"col-md-12 px-0 ui-select-choices-row " + (isSelected ? 'active' : '') + (isFocus ? " focused" : "")}
                 onClick={() => option.selectValue(option.option)}
                 onKeyDown={(event) => captureKeyDown(event)}>
            <span className="ui-select-row-choices-inner row mx-0">
                <span className="company-name" title={option.option.name}>{option.option.name}</span>
            </span>
            </div>
        );
    }

    arrowRenderer({isOpen, callback}) {
        // original odp component doesn't have clear button
        // isOpen~true when the list of options is shown
        if (isOpen) return null;
        else return (
            <span className="caret"></span>
        );
    }


    /**
     * custom menu renderer .. only difference from the original component is passing isSelected into option renderer
     */
    menuRenderer({
                     focusedOption,
                     instancePrefix,
                     labelKey,
                     onFocus,
                     onSelect,
                     optionClassName,
                     optionComponent,
                     optionRenderer,
                     options,
                     valueArray,
                     valueKey,
                     onOptionRef
                 }) {
        let Option = optionComponent;

        return options.map((option, i) => {
            let isSelected = valueArray && valueArray.indexOf(option) > -1;
            let isFocused = option === focusedOption;
            let optionClass = classNames(optionClassName, {
                'Select-option': true,
                'is-selected': isSelected,
                'is-focused': isFocused,
                'is-disabled': option.disabled,
            });

            return (
                <Option
                    tabIndex={i}
                    className={optionClass}
                    instancePrefix={instancePrefix}
                    isDisabled={option.disabled}
                    isFocused={isFocused}
                    isSelected={isSelected}
                    key={`option-${i}-${option[valueKey]}`}
                    onFocus={onFocus}
                    onSelect={onSelect}
                    option={option}
                    optionIndex={i}
                    ref={ref => {
                        onOptionRef(ref, isFocused);
                    }}
                >
                    {optionRenderer(option, i, isSelected)}
                </Option>
            );
        });
    }


}