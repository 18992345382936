import {
    COST_UNIT_TYPE_REORDERED,
    COST_UNIT_TYPE_SELECTED,
    COST_UNIT_TYPE_UNSELECTED,
    COST_UNIT_TYPES_LOADED,
    COST_UNIT_TYPES_SAVED,
    COST_UNIT_TYPES_SET,
    COST_UNIT_ROLES_LOADED,
    COST_UNIT_WARNINGS_LOADED,
    APPROVERS_TREE_ROOT_LOADED,
    APPROVERS_NODES_LOADED,
    COST_UNIT_ROLE_TYPES_LOADED,
    COST_UNITS_ROLES_SORT_CHANGED,
    CLOSE_COST_UNIT_NODE,
    DOCUMENT_TYPES_LOADED, COST_UNITS_BRANCHES_LOADED
} from "../store/actionTypes";
import * as Api from "utils/api/api";
import {handleError} from "utils/errorHandle.function";
import _ from "lodash";
import {getUsersCurrentContextId} from "components/store/application.reducers.js";


export function costUnitTypesLoaded(costUnitTypes) {
    return {
        type: COST_UNIT_TYPES_LOADED,
        data: costUnitTypes
    }
}

export function costUnitTypeSelected(costUnitTypes) {
    return {
        type: COST_UNIT_TYPE_SELECTED,
        data: costUnitTypes
    }
}

export function costUnitTypeUnselected(costUnitTypes) {
    return {
        type: COST_UNIT_TYPE_UNSELECTED,
        data: costUnitTypes
    }
}

export function costUnitTypeReordered(data) {
    return {
        type: COST_UNIT_TYPE_REORDERED,
        data
    }
}

export function costUnitTypesSet(costUnitTypes) {
    return {
        type: COST_UNIT_TYPES_SET,
        data: costUnitTypes
    }
}

export function costUnitTypesSaved() {
    return {
        type: COST_UNIT_TYPES_SAVED,
        data: {}
    }
}

export function costUnitRolesLoaded(id, level, roles = []) {
    return {
        type: COST_UNIT_ROLES_LOADED,
        data: roles,
        id: id,
        level: level
    }
}

export function costUnitWarningsLoaded(warnings = []) {
    return {
        type: COST_UNIT_WARNINGS_LOADED,
        data: warnings
    }
}

export function approversTreeRootLoaded(tree) {
    return {
        type: APPROVERS_TREE_ROOT_LOADED,
        data: tree
    }
}

export function costUnitsRoleTypesLoaded(roles = []) {
    return {
        type: COST_UNIT_ROLE_TYPES_LOADED,
        data: roles
    }
}


export function closeCostUnitNode(id) {
    return {
        type: CLOSE_COST_UNIT_NODE,
        data: id
    }
}

export function costUnitsBranchesLoaded(branches) {
    return {
        type: COST_UNITS_BRANCHES_LOADED,
        data: branches
    }
}


export function approversNodesLoaded(id, parentId, level, number, nodes = [], name = '') {
    return {
        type: APPROVERS_NODES_LOADED,
        id: id,
        data: nodes,
        name: name,
        parentId: parentId,
        level: level,
        number: number
    }
}

export function documentTypesLoaded(docTypes) {
    return {
        type: DOCUMENT_TYPES_LOADED,
        data: docTypes
    }
}


export function getCostUnitsData() {
    return function (dispatch) {
        dispatch(getDocumentTypesForWorkflowConfiguration());
        Api.getCostUnitTypes().then(costUnitTypes => {
            dispatch(costUnitTypesLoaded(costUnitTypes));
        }, error => {
            handleError(error);
        });
    }
}

export function setCostUnitsData(data, version) {
    return function (dispatch) {
        data.costUnits = data.costUnits.map((item) => {
            return {
                dimension: item.dimension,
                ordinal: item.ordinal
            }
        }, error => {
            handleError(error);
        });
        data.costUnits = JSON.stringify(data.costUnits);
        return Api.setCostUnitTypes(data, version);
    }
}

export function getCostUnitRoles(id, level, sort) {
    return function (dispatch) {
        Api.getCostUnitRoles(id, level, sort.sortColumn, sort.sortOrder).then(response => {
            dispatch(costUnitRolesLoaded(id, level, response));
        }, error => {
            handleError(error);
        });
    }
}

export function getCostUnitWarnings() {
    return function (dispatch) {
        Api.getDeletedCostUnitWarnings({page: 1, rows: 100}).then(response => {
            dispatch(costUnitWarningsLoaded(response.rows));
        }, error => {
            handleError(error);
        });
    }
}

export function getApproversTreeRoot() {
    return function (dispatch) {
        return Api.getCostUnitNodes().then(root => {
            return Api.getCostUnitsCombinations(root.rootId, 1).then(response => {
                return dispatch(approversTreeRootLoaded(response));
            });
        });
    }
}

export function getApproversNodes(node, rootId) {
    return function (dispatch) {
        return Api.getCostUnitsCombinations(node.id, node.level, rootId).then(response => {
            if (response.nodes) {
                return dispatch(approversNodesLoaded(node.id, node.parent, node.level, node.number, response, node.name));
            }

            return Promise.resolve({});
        }, error => {
            handleError(error);
        });
    }
}

export function deleteCostUnitRole(nodeId, userId, roleId, branchId, costUnitId, costUnitLevel, sort) {
    return function (dispatch) {
        Api.deleteCostUnitRole(nodeId, userId, roleId, branchId).then(response => {
            dispatch(getCostUnitRoles(costUnitId, costUnitLevel, sort));
        }, error => {
            handleError(error);
        });
    }
}

export function getCostUnitsRoles() {
    return function (dispatch) {
        Api.getRuleConfigurationRoles().then(response => {
            dispatch(costUnitsRoleTypesLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}

export function getCostUnitsBranches() {
    return function (dispatch) {
        Api.getBranches().then(response => {
            dispatch(costUnitsBranchesLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}

export function getDocumentTypesForWorkflowConfiguration() {
    return function (dispatch, getState) {
        const state = getState();
        const currentContextId = getUsersCurrentContextId(state);
        Api.getDocumentTypesForWorkflowConfiguration(currentContextId).then(response => {
            dispatch(documentTypesLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}

export function saveCostUnitsRoles(costUnitId, costUnitLevel, roleId, userId, branchId, deputy, sort) {
    return function (dispatch) {
        Api.saveCostUnitRole(costUnitId, roleId, userId, branchId, deputy).then(response => {
            dispatch(getCostUnitRoles(costUnitId, costUnitLevel, sort));
        }, error => {
            handleError(error);
        });
    }
}

export function editCostUnitsRoles(costUnitId, costUnitLevel, roleId, userId, branchId, deputy, oldRoleId, oldUserId, oldBranchId, sort) {
    return function (dispatch) {
        Api.editCostUnitRole(costUnitId, oldRoleId, oldUserId, oldBranchId, roleId, userId, branchId, deputy).then(response => {
            dispatch(getCostUnitRoles(costUnitId, costUnitLevel, sort));
        }, error => {
            handleError(error);
        });
    }
}

export function sortChanged(sort) {
    return {
        type: COST_UNITS_ROLES_SORT_CHANGED,
        sort: sort
    };

}

export function sortCostUnitRoles(sort, id, level) {
    return function (dispatch) {
        dispatch(sortChanged(sort));
        dispatch(getCostUnitRoles(id, level, sort));
    }

}

function getNodes(id, level, rootId) {
    return Api.getCostUnitsCombinations(id, level, rootId).then(response => {
        if (response.nodes) {
            return response;
        }

        return Promise.resolve();
    }, error => {
        handleError(error);
    });
}

export function getAllAncerstors(ancestors) {
    return function (dispatch) {
        //first get the root of the tree
        return getNodes(ancestors[0], 1).then(rootResponse => {
            dispatch(approversTreeRootLoaded(rootResponse));
            let nodes = Object.assign([], rootResponse.nodes);

            //parent is not in the root of the tree
            if (ancestors.length > 1 && rootResponse.nodes) {
                dispatch(loopAncestors(ancestors, 1, nodes));
            }

            return Promise.resolve();
        });
    }
}


function loopAncestors(ancestors, index, nodes) {
    return function (dispatch) {
        getNodes(ancestors[index], index + 1, ancestors[0]).then(response => {
            let currentNode = _.find(nodes, (node) => {
                return node.id === ancestors[index]
            });
            nodes = nodes.concat(response.nodes);
            dispatch(approversNodesLoaded(currentNode.id, currentNode.parent, currentNode.level, currentNode.number, response, currentNode.name));
            index += 1;

            if (index < ancestors.length) {
                dispatch(loopAncestors(ancestors, index, nodes));
            }
        });
    }
}

