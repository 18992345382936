import React, {Component} from 'react';
import translate from "components/translations/translations.wrapper.jsx";
import CommentsList from "./CommentsList.component";
import "./comments.scss";
import * as Api from "utils/api/api";
import CommentsInput from "./CommentsInput.component";
import spinner from '../../../../assets/spinner.svg';
import {handleError} from "utils/errorHandle.function";
import Skeleton from "react-loading-skeleton";
import {connect} from 'react-redux';
import {getTaskCommentsData} from '../../taskDetails.action';
import * as reduxSelectors from "../../../store/application.reducers";

class CommentsPanel extends Component {
    constructor() {
        super();

        this.state = {
            currentComment: null,
            comments: [],
            savingComment: false,
            commentSaved: true,
            currentCommentInitiallyEmpty: true,
            hideSavedNotification: false,
            offline: false
        };


        this.requests = [];
        this.addComment = this.addComment.bind(this);
        this.requestData = this.requestData.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            currentComment: null,
            currentCommentInitiallyEmpty: true,
        });

        if (!this.props.isDocument)
            this.requestData(this.props.taskId);
    }

    //this is needed to reload data at navigate between tasks with ctrl+alt+n
    componentDidUpdate(prevProps) {
        if (prevProps.taskId !== this.props.taskId) {
            this.setState({
                currentComment: null,
                currentCommentInitiallyEmpty: true
            });
            if (!this.props.isDocument)
                this.requestData(this.props.taskId);
        }
    }

    componentWillUnmount() {
        this.requests.forEach(p => p.cancel());
    }

    requestData(taskId) {
        //get task comments
        if (!this.props.hasDocumentDataOnly) {
            this.props.getTaskCommentsData(taskId);
        }
    }

    async addComment(comment) {
        if (!navigator.onLine) {
            this.setState({
                offline: true,
                savingComment: true
            });

            this.saveTimeout = setTimeout(this.addComment, 1000, comment);
            return;
        } else {
            this.setState({
                offline: false,
                savingComment: false
            });
        }


        this.setState({
            savingComment: true,
            commentSaved: false,
        });

        for (const request of this.requests) {
            await request;
        }

        this.requests.push(Api.insertComment(this.props.taskId, comment)
            .then(response => {
                this.setState({
                    savingComment: false,
                    commentSaved: true,
                });

                if (this.props.onAddComment) {
                    this.props.onAddComment(comment, this.state.currentCommentInitiallyEmpty);
                    this.setState({
                        currentCommentInitiallyEmpty: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    savingComment: false,
                    commentSaved: false,
                });
                if (error.errorCode !== 330) {
                    handleError(error);
                }
            })
        );
    }

    onChange(event) {
        let comment = event.target.value;

        if (comment !== this.state.currentComment) {
            this.setState({
                currentComment: comment,
                commentSaved: false,
                hideSavedNotification: false
            });

            if (this.saveTimeout !== undefined) {
                clearTimeout(this.saveTimeout);
            }

            this.saveTimeout = setTimeout(this.addComment, 300, comment);
        }
    }

    renderLoading() {
        return <div className="grid-comments-panel-loading">
            <div className="comment-loading-skeleton">
                <Skeleton className="comment-image-loading-skeleton"/>
                <Skeleton className="comment-content-loading-skeleton"/>
            </div>
            <div className="comment-loading-skeleton">
                <Skeleton className="comment-image-loading-skeleton"/>
                <Skeleton className="comment-content-loading-skeleton"/>
            </div>
            <div className="comment-loading-skeleton">
                <Skeleton className="comment-image-loading-skeleton"/>
                <Skeleton className="comment-content-loading-skeleton"/>
            </div>
        </div>
    }

    render() {
        const commentsCount = this.props.commentsCount;

        // const profilePictureBaseUrl = typeof window.ENVIRONMENT !== 'undefined' && window.ENVIRONMENT.PROFILE_PICTURE_BASE_URL;
        // const profilePicture = comment.connectUserId ? `${profilePictureBaseUrl}${comment.connectUserId}?type=initials` : commentsDefaultUserImage;

        return (
            <div className="grid-comments-panel">
                <div className="grid-panel-header">
                    <strong>
                        {this.props.translate("taskDetail.notesAndComments")}
                        {commentsCount > 0 ?
                            <span> ({commentsCount <= 99 ? commentsCount : '99+'})</span> : null
                        }
                    </strong>
                </div>
                {this.props.isLoading ?
                    this.renderLoading()
                    : <CommentsList comments={this.props.comments}
                                    translate={this.props.translate}/>
                }
                {!(this.props.readOnly) &&
                    <span>
                        <div
                            className={`comments-panel-input ${this.props.currentComment.length > 500 ? 'comment-too-long' : ''}`}>
                            <div>
                                <CommentsInput id="comments"
                                               value={this.state.currentComment !== null ? this.state.currentComment : this.props.currentComment}
                                               placeholder={this.props.translate("taskDetail.sendComment")}
                                               onChange={this.onChange}
                                               taskId={this.props.taskId}
                                               disabled={this.state.offline}
                                />
                                {this.state.savingComment ?
                                    <img src={spinner}
                                         title={this.state.offline ? this.props.translate("taskDetail.offlineComment") : ''}
                                         alt={'saving'}
                                         className={'comments-input-saving-spinner'}/> : null
                                }
                                {this.state.commentSaved && this.props.currentComment !== '' ?
                                    <span className="glyphicon glyphicon-ok comments-input-check"
                                          title={this.props.translate("taskDetail.commentSavedTooltip")}/> : null
                                }
                            </div>
                            {this.props.currentComment.length >= 450 ?
                                <div className="comments-length-validation">
                                    {this.props.currentComment.length <= 500 ?
                                        <div>
                                            <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-info"/>
                                            {500 - this.props.currentComment.length}
                                            {this.props.translate("taskDetail.charactersLeft")}
                                        </div> :
                                        <div>
                                        <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"
                                              title={this.props.translate("taskDetail.commentTooLongTooltip")}/>
                                            {this.props.translate("taskDetail.characters", this.props.currentComment.length)}
                                        </div>}
                                </div> : null}
                        </div>
                    </span>
                }
            </div>
        );
    }
}

const withTranslations = translate(CommentsPanel);

const connected = connect((store) => ({
    comments: reduxSelectors.getTaskCommentsAndEvents(store),
    isLoading: reduxSelectors.getTaskCommentsLoadingStatus(store),
    currentComment: reduxSelectors.getTaskCurrentComment(store)
}), {getTaskCommentsData})(withTranslations);

export default connected;