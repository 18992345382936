import translate from "../../../translations/translations.wrapper.jsx";
import commentsDefaultUserImage from "assets/comments-default-user.png";

import approved from "assets/24_OK.svg";
import autoApproved from "assets/24_autoapprove.svg";
import awaitingReview from "assets/24_awaiting_review.svg";
import canceled from "assets/24_cancelled1.svg";
import forwarded from "assets/24_forwarded_task.svg";
import rejected from "assets/24_rejected_document1.svg";
import reviewed from "assets/24_document_reviewed.svg";
import reassigned from "assets/24_reassign.svg";
import systemTask from "assets/24_system_task.svg";
import accountView from "assets/accountView.png";
import business from "assets/business_x64.png";
import businessNXT from "assets/business_NXT.svg";
import control from "assets/control_x64.png";
import documentCenter from "assets/documentCenter_x64.png";
import calendar from "assets/visma_calendar.svg";
import { CommentsProfileProps } from "types/comments";
import React from "react";

export const CommentsProfile: React.FC<CommentsProfileProps> = (props) => {


    const setFallbackImage = function(event) {
        event.target.src = "https://static-odp.visma.net/commoncomponents/vismaSkin/images/services/default/medium.png";
    };

    const icons = {
        0: systemTask,
        1: approved,
        2: awaitingReview,
        3: rejected,
        4: canceled,
        5: forwarded,
        9: autoApproved,
        22: reviewed,
        55: reassigned,
    };

    const profilePictureBaseUrl = typeof window.ENVIRONMENT !== "undefined" && window.ENVIRONMENT.PROFILE_PICTURE_BASE_URL;
    const profilePicture = props.connectUserId ? `${profilePictureBaseUrl}${props.connectUserId}?type=initials` : commentsDefaultUserImage;
    let applicationId = props.externalApplicationName ? props.externalApplicationName.toLowerCase().replace(/visma(\.net)? /, "").replace(/ /g, "_") : "default";

    let applicationPicture;
    switch (applicationId) {
        case "absence":
            applicationPicture = calendar;
            break;
        case "documentcenter":
            applicationPicture = documentCenter;
            break;
        case "business":
            applicationPicture = business;
            break;
        case "business_cloud":
            applicationPicture = businessNXT;
            break;
        case "control":
            applicationPicture = control;
            break;
        case "accountview":
            applicationPicture = accountView;
            break;
        default:
            applicationPicture = `https://static-odp.visma.net/commoncomponents/vismaSkin/images/services/${applicationId}/medium.png`;
    }

    const picture = props.externalApplicationName ? applicationPicture : profilePicture;
    const srcIcon = icons[props.eventType];

    return (
        <div className="new-comments-profile">
            <img src={picture}
                 width="48"
                 height="48"
                 className="rounded-8"
                 alt={props.translate("taskDetail.profilePicture", props.userName)}
                 onError={(event) => {
                     setFallbackImage(event);
                 }} />
            {!props.eventType || props.eventType === 7 ? null :
                <img className="action-icon rounded-12" src={srcIcon}
                     alt="icon" />}
        </div>
    );

};

const withTranslations = translate(CommentsProfile);
export default withTranslations;
