import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { PopUp } from "./PopUp.component";
import { DOCUMENT_TYPE } from "utils/constants";
import ErrorDialog from "./ErrorDialog.component";
import translate from "../translations/translations.wrapper.jsx";
import ResizableTable from "../resizableTable/Table.component";
import { WORKFLOW_HISTORY_BASE, WORKFLOW_HISTORY_WITH_PREVIEW } from "../resizableTable/columnsMap";
import "./WorkflowHistoryPopUp.scss";
import * as reduxSelectors from "../store/application.reducers";
import { connect } from "react-redux";
import { getDateTimeFormatFromLanguage } from "utils/dateFormatter";
import moment from "moment";
import { getWorkflowsHistory, loadMoreWorkflowsHistory } from "../workflows/workflows.action";
import { Tooltip } from "../tooltip/Tooltip";
import { Link } from "react-router-dom";
import { launchDarkly } from "utils/launchDarkly";
import { generateRandom } from "utils/genereateMathRandom.function";
import { UserProfile } from "components/workflows/UserProfile.component";


export class WorkflowHistoryPopUp extends PopUp {
    constructor(props) {
        super(props);
        this.buildContent = this.buildContent.bind(this);
        this.mapTableRows = this.mapTableRows.bind(this);
        this.setActiveCounter = this.setActiveCounter.bind(this);
        this.loadMoreRecords = this.loadMoreRecords.bind(this);
        this.dateValueFormatter = getDateTimeFormatFromLanguage(this.props.userLanguageCode);
        this.isPreviewHistoryEnabled = launchDarkly.userClient && launchDarkly.userClient.variation("show-workflow-history-preview", false);
        this.state = {
            filters: {
                columns: {},
            },
            activeCounter: 0,
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    mapTableRows() {
        let mappedRows = Object.assign({}, this.props.workflowHistory);
        mappedRows.rows = this.props.workflowHistory && this.props.workflowHistory.rows ? this.props.workflowHistory.rows.map((record, index) => {
            let mappedRecord = Object.assign({}, record);
            mappedRecord.validFrom = moment(record.validFrom).format(this.dateValueFormatter);
            mappedRecord.operation = this.props.translate("popUp.workflowHistory." + record.operation);
            mappedRecord.comment = record.comment || "";
            mappedRecord.user = (
                record.userDTO ?
                    <UserProfile user={record.userDTO} uniqueKey={index} /> :
                    ""
            );

            if (this.isPreviewHistoryEnabled) {
                mappedRecord.preview =
                    (<Link
                        to={"/configuration/workflows/workflowHistory/" + record.idRuleConfiguration + "/" + record.version}>
                    <span className={"vismaicon vismaicon-dynamic vismaicon-preview"}
                          data-for={record.idRuleConfiguration + "-preview-tooltip"}
                          data-tip="">
                 <Tooltip
                     place="bottom"
                     id={record.idRuleConfiguration + "-preview-tooltip"}
                     content={() =>
                         <div>{this.props.translate("popUp.workflowHistory.preview")}</div>}
                 />
            </span></Link>);
            }
            if (record.active) {
                let random = generateRandom();
                mappedRecord.status = <span
                    className="label label-success"
                    data-for={"active-when-deleted-" + random}
                    data-tip="">{this.props.translate("popUp.workflowHistory.active")}
                    {record.operation === "deleted" &&
                        <Tooltip
                            place="bottom"
                            id={"active-when-deleted-" + random}
                            content={() =>
                                <div>{this.props.translate("popUp.workflowHistory.activeDeleted", record.description)}</div>}
                        />
                    }
                    </span>;
            } else if (record.operation !== "start_history") {
                mappedRecord.status = <span
                    className="label label-danger label-grey">{this.props.translate("popUp.workflowHistory.inactive")}</span>;
            }
            return mappedRecord;
        }) : [];

        return mappedRows;
    }

    setActiveCounter(counter) {
        this.setState({ activeCounter: counter });
        this.props.getWorkflowsHistory(counter);
    }

    loadMoreRecords() {
        if (this.props.workflowHistory.records > 20) {
            let data = {
                page: this.props.workflowHistory.page + 1,
                rows: 20,
            };
            if (this.state.activeCounter > 0) {
                data.documentType = this.state.activeCounter;
            }
            if (this.props.ruleId) {
                data.ruleConfigId = this.props.ruleId;
            }

            this.props.loadMoreWorkflowsHistory(data);
        }
    }


    buildContent() {
        let selector_values = Object.assign({}, { "role_select": "" }, { "documentType": "" });
        let tableRows = this.mapTableRows();

        let countersList = this.props.docTypes ? this.props.docTypes.map((type, index) => {
            return (
                <button key={index} type="button"
                        className={"btn btn-default " + (this.state.activeCounter === this.props.docTypes[index] ? "active" : "")}
                        onClick={this.setActiveCounter.bind(this, this.props.docTypes[index])}>
                    <span
                        className={("me-4 appicon document-type-icon-24 document-type-icon document-type-" + DOCUMENT_TYPE.asString(type) + "-24")} />
                    {this.props.translate("documentTypes." + DOCUMENT_TYPE.asString(type))}
                </button>);
        }) : null;

        return (
            <Fragment>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({ actionRunning: false, displayError: null })}
                    translate={this.props.translate}
                />}
                <div className="modal-body workflow_history">
                    <button type="button" className="close" onClick={this.props.closeCallback} />

                    {countersList &&
                        <div className="btn-toolbar btn-toolbar-primary mx-0">
                            <div className="col-md-10 btn-group float-left px-0" role="group">
                                <button key={"history_all_doc_types"} type="button"
                                        className={"btn btn-default " + (this.state.activeCounter === 0 ? "active" : "")}
                                        onClick={this.setActiveCounter.bind(this, 0)}>
                                    {this.props.translate("popUp.workflowHistory.allDocumentType")}
                                </button>
                                {countersList}
                            </div>
                        </div>
                    }

                    <ResizableTable tasks={tableRows}
                                    hideSearchRow={true}
                                    className={countersList ? "with-counters" : "without-counters"}
                                    tableWidth={980}
                                    defaultColumns={this.isPreviewHistoryEnabled ? WORKFLOW_HISTORY_WITH_PREVIEW : WORKFLOW_HISTORY_BASE}
                                    filterChanged={this.filterChanged}
                                    selectors={selector_values}
                                    translate={this.props.translate}
                                    filters={this.state.filters}
                                    refreshGrid={this.refreshGrid}
                                    loadMoreTasks={this.loadMoreRecords}
                                    loadingStatus={this.props.historyLoadingStatus}
                                    scrollTop={this.scrollTop} />

                </div>

            </Fragment>

        );
    }

    getType() {
        return "workflowHistory";
    }

    getTitle() {
        return <span className="d-inline-flex">{this.props.translate("popUp.workflowHistory.title")}<span
            className="vismaicon vismaicon-dynamic vismaicon-info ml-4"
            data-for={"wh-info-tooltip"} data-tip="" />
        <Tooltip
            place="bottom"
            id={"wh-info-tooltip"}
            content={() => <div
                className="font-weight-light">{this.props.translate("popUp.workflowHistory.info")}</div>}
        />
        </span>;
    }

}

WorkflowHistoryPopUp
    .propTypes = {
    translate: PropTypes.func, // to translate texts
};
const
    withTranslations = translate(WorkflowHistoryPopUp);

const
    mapStateToProps = function(store) {
        return {
            userLanguageCode: reduxSelectors.getUsersCurrentLanguage(store),
            workflowHistory: reduxSelectors.getWorkflowHistory(store),
            historyLoadingStatus: reduxSelectors.getWorkflowHistoryLoadingStatus(store),
        };
    };

const
    connected = connect(mapStateToProps, {
        getWorkflowsHistory,
        loadMoreWorkflowsHistory,
    })(withTranslations);
export default connected;