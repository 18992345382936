import {AttachmentsForRenderProps} from "types/attachment";
import React from "react";

const Pdf = ({collection, url}: AttachmentsForRenderProps): React.ReactElement => {

    return <div className={"attachment-image-container h-100 bg-toolbar-dark-grey py-2 rounded-8"}>
        <embed id={"iframe_" + collection.id}
                src={url}
                title={"iframe_for_pdf_" + collection.id}
                className="pdf-iframe-container w-100 h-100"/>
    </div>
}

export default Pdf;