import React from 'react';
import {KEYS} from 'utils/constants';
import {FormGroup, Form} from 'react-bootstrap';
import _ from 'lodash';
import {RequestController} from "utils/requestController";


class SearchableCombobox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            results: [],
            focusedIndex: 0,
            open: false,
            page: 1,
            callFunc: undefined,
            records: 0,
            totalRecords: 0,
            noResults: false,
            value: undefined,
            isValidInput: true
        }
        this.STOP_LOAD = false;
        this.inputList = null;
        this.selectInput = React.createRef();
        this.requestController = null;

        this.getOptions = this.getOptions.bind(this);
        this.loadAll = this.loadAll.bind(this);
        this.listScrollEvent = this.listScrollEvent.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.openList = this.openList.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.getSelectedValue = this.getSelectedValue.bind(this);
        this.getValidationState = this.getValidationState.bind(this);
        this.apiCall = this.apiCall.bind(this);
        this.mapResponseData = this.mapResponseData.bind(this);

    }

    componentDidMount() {
        this.requestController = new RequestController();
        setTimeout(() => {
            if (this.selectInput.current && !this.props.stopAutoSelect) {
                this.selectInput.current.focus();
                this.selectInput.current.select();
            }
        }, 1);
    }

    openList(event) {
        this.inputList.style.position = "fixed";
        this.inputList.style.width = this.selectInput.current.getBoundingClientRect().width + "px";
        if (!this.props.genericPosition) {
            this.inputList.style.bottom = window.innerHeight - event.target.getBoundingClientRect().top - 16 + "px";
            this.inputList.style.left = this.selectInput.current.getBoundingClientRect().left + "px";
        }
        if (window.navigator.userAgent.match(/apple/i)) {
            if (window.innerHeight - this.selectInput.current.parentElement.getBoundingClientRect().bottom < 80) {
                this.inputList.style.bottom = window.innerHeight - event.target.getBoundingClientRect().top - 16 + "px";
            } else {
                this.inputList.style.top = this.selectInput.current.getBoundingClientRect().bottom + "px";
            }
            this.inputList.style.minHeight = "80px";
            this.inputList.style.maxHeight = "150px";
        }


        this.setState({
            open: true,
            focusedIndex: 0
        });
    }

    getSelectedValue(value) {
        let response;
        if (this.state.results.length > 0) {
            //if the user has typed something try to map it to the possible results list
            if (value && this.state.focusedIndex === 0) {
                let selectedIndex = _.findIndex(this.state.results, (x => x.value === value));
                let selectedValue = selectedIndex === -1 ? this.state.results[0].value : this.state.results[selectedIndex].value;
                response = selectedValue;
            } else {
                response = this.state.focusedIndex !== -1 && this.state.results[this.state.focusedIndex] ?
                    this.state.results[this.state.focusedIndex].value : '';
            }
            return response;
        } else {
            //maybe the call did not have time to begin
            if (!this.state.noResults) {
                return this.props.apiMethod(value).then(response => {
                    if (response.length === 1) {
                        let item = response[0];
                        return item[Object.keys(item)[0]];
                    } else return value;
                });

            } else
                return value;
        }
    }

    handleKeyDown(event) {
        event.stopPropagation();
        switch (event.keyCode) {
            case KEYS.ENTER: {
                event.stopPropagation();
                event.preventDefault();
                let val = this.getSelectedValue(event.target.value);
                if (typeof val === "object")
                    val.then((val) => {
                        this.props.onChangeSelected({value: val}, event)
                    });
                else this.props.onChangeSelected({value: val}, event);
                break;
            }
            case KEYS.DOWN_ARROW: {
                event.preventDefault();
                let rowHeight = 33;
                let newIndex = (this.state.focusedIndex === this.state.results.length - 1) ? this.state.focusedIndex : this.state.focusedIndex + 1;
                this.setState({focusedIndex: newIndex});
                if ((rowHeight * (newIndex)) >= this.inputList.offsetHeight) {
                    this.inputList.scrollTop += rowHeight;
                }
                break;
            }
            case KEYS.UP_ARROW: {
                event.preventDefault();
                let newIndex = 0;
                let rowHeight = 33;
                newIndex = (this.state.focusedIndex <= 0) ? newIndex : this.state.focusedIndex - 1;
                this.setState({focusedIndex: newIndex});

                if (((rowHeight * (newIndex + 1)) - this.inputList.scrollTop) <= rowHeight) {
                    this.inputList.scrollTop -= rowHeight;
                }
                break;
            }
            //we don't need this anymore
            case KEYS.TAB: {
                event.preventDefault();
                let val = this.getSelectedValue(event.target.value);
                let moveBack = !event.shiftKey;

                if (typeof val === "object")
                    val.then((val) => {
                        this.props.saveAndMove(val, moveBack)
                    });
                else {
                    this.props.saveAndMove(val, moveBack);
                }
                break;
            }
            default: {
                this.props.handleKeyDown(event);
                break;
            }
        }
    }

    handleBlur(event) {
        if ((event.relatedTarget && event.relatedTarget.className.indexOf("dropup") !== -1)) {
            return false;
        }
        //this is because of IE :  relatedTarget is always NULL for the Blur event in IE -> workaround
        else if (!event.relatedTarget && (document.activeElement.className.indexOf("dropup") !== -1 || document.activeElement.className.indexOf("advanced-search-icon") !== -1))
            return false;

        if (!event.target.value || (this.props.defaultValue === event.target.value) || (this.state.results && this.state.results.length > 0)) {
            this.setState({
                open: false
            });
            this.props.onBlur(event);
        }
    }

    loadAll(event) {
        event.preventDefault();
        event.stopPropagation();
        let data = {
            prefix: "",
            usedInIndexOnly: false,
            rows: 40,
            page: 1
        }

        this.openList(event);
        //this is be the function to handlle get all data
        return this.props.loadAllFunc(data).then(response => {
            const results = response.rows.map(item => {
                return {
                    label: item.number + " - " + item.name,
                    value: item.number
                }
            });
            this.setState({
                callFunc: this.props.loadAllFunc,
                records: 40,
                totalRecords: response.records,
                results: results,
                noResults: results.length === 0
            });
        });
    }

    getOptions(event) {
        let input = event.target.value;
        this.setState({
            value: input
        });
        //no input aka user has cleared the input
        if (!input) {
            this.setState({
                results: [],
                open: false
            });
            this.props.onEmpty(event);
            return;
        } else {
            this.openList(event);
        }

        const debounceCall = _.debounce((input) => {
            this.apiCall(input);
        }, 200);

        debounceCall(input);
    }

    apiCall(input) {
        this.requestController.single(this.props.apiMethod(input)).then((response) => {
            if (!response || !response.length) {
                this.setState({
                    results: [],
                    noResults: true,
                    isValidInput: false
                });
                return null;
            }
            this.mapResponseData(response, input);
        });
    }

    mapResponseData(response, input) {
        if (this.state.value === input) {
            const results = response.map(item => {
                return {
                    label: item[Object.keys(item)[0]] + " - " + item[Object.keys(item)[1]],
                    value: item[Object.keys(item)[0]]
                }
            });
            this.setState({
                results: results,
                noResults: results.length === 0,
                isValidInput: results.length > 0
            });
        }
    }

    listScrollEvent(event) {
        event.preventDefault();
        event.stopPropagation();

        if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight) {
            if (this.state.records < this.state.totalRecords && !this.STOP_LOAD) {
                this.STOP_LOAD = true;

                let data = {
                    prefix: "",
                    usedInIndexOnly: false,
                    rows: 40,
                    page: this.state.page + 1
                }
                return this.props.loadAllFunc(data).then(response => {
                    const results = response.rows.map(item => {
                        return {
                            label: item.number + " - " + item.name,
                            value: item.number
                        }
                    });
                    this.setState({
                        page: response.page,
                        records: this.state.records + 40,
                        results: this.state.results.concat(results),
                        noResults: results.length === 0
                    });
                    this.STOP_LOAD = false;
                });

            }
        }
    }

    getValidationState() {
        return this.state.isValidInput ? null : 'error';
    }

    render() {

        let optionsList = this.state.results.map((result, index) => {
            return (<li key={index}
                        className={"gla-account " + (this.state.focusedIndex === index ? 'focused-account' : '')}
                        title={result.label}
                        onClick={this.props.onChangeSelected.bind(this, result)}>{result.label}</li>);
        });
        return (
            <div className={"dropup m-0 w-100 " + (this.state.open ? "open" : "")}
                 tabIndex={0}
                 ref={(input) => {
                     this.parentInput = input;
                 }}
                 onKeyDown={this.handleKeyDown}
                 onBlur={this.handleBlur}>

                <Form className="col-md-12 px-0">
                    <FormGroup className="search-group col-md-12 px-0"
                               validationState={this.getValidationState()}>
                        <input type="text"
                               className="accounting-input cropped-text form-control"
                               onChange={this.getOptions}
                               onClick={(e) => {
                                   e.stopPropagation()
                               }}
                               tabIndex={this.props.tabIndex}
                               value={this.props.value}
                               defaultValue={this.props.defaultValue}
                               ref={this.selectInput}/>
                        <span className="advanced-search-icon mx-3"
                              onClick={this.loadAll}
                        />
                    </FormGroup>
                </Form>


                <ul className="dropdown-menu accounting-input-list" role="menu" aria-labelledby="dropdownMenu"
                    ref={(input) => {
                        this.inputList = input;
                    }}
                    onMouseOver={() => {
                        this.setState({focusedIndex: -1})
                    }}
                    onMouseLeave={(e) => {
                        console.log(e)
                    }}
                    onScroll={this.listScrollEvent}>
                    {optionsList}
                    {this.state.noResults &&
                        <li>{this.props.translate("accoutingGrid.noItems")}</li>
                    }
                </ul>
            </div>

        );
    }

}

export default SearchableCombobox;