import {TABLE_COLUMNS_TYPES} from '../../utils/constants';

const AMOUNT_WIDTH = 120;
const CURRENCY_WIDTH = 80;
const CHECKBOX_WIDTH = 40;
const DATE_WIDTH = 110;
const DATE_TIME_WIDTH = 160;
const DOCID_WIDTH = 140;
const DOCTYPE_WIDTH = 110;
const STATUS_WIDTH = 80;
const NAME_WIDTH = 140;
const ROLE_WIDTH = 100;
const ACK_WIDTH = 115;
const DATE_LARGE_WIDTH = 130;

const COMMON =
    [
        {
            value: "documentType",
            caption: "myHistory.type",
            type: "select",
            searchValue: "documentType",
            selectedValue: '',
            width: DOCTYPE_WIDTH
        },
        {
            value: "displayId",
            caption: "myHistory.documentId",
            type: "text",
            searchValue: "externalApplicationId",
            selectedValue: '',
            width: DOCID_WIDTH
        },
        {
            value: "requesterName",
            caption: "myHistory.from",
            type: "text",
            searchValue: "requesterName",
            selectedValue: ''
        },
        {
            value: "description",
            caption: "myHistory.description",
            type: "text",
            searchValue: "description",
            selectedValue: ''
        },
        {
            value: "amount",
            caption: "grid.amount",
            type: TABLE_COLUMNS_TYPES.AMOUNT,
            searchValue: "amount",
            selectedValue: '',
            width: AMOUNT_WIDTH
        },
        {
            value: "currency",
            caption: "grid.currency",
            type: "text",
            searchValue: "currency",
            selectedValue: '',
            width: CURRENCY_WIDTH
        },
        {
            value: "received",
            caption: "myHistory.received",
            type: "dateFilter",
            searchValue: "received",
            selectedValue: '',
            width: DATE_TIME_WIDTH
        },
        {
            value: "lastChanged",
            caption: "myHistory.lastChanged",
            type: "dateFilter",
            searchValue: "lastChanged",
            selectedValue: '',
            width: DATE_TIME_WIDTH
        },
        {
            value: "lastChangedByUserName",
            caption: "myHistory.lastChangedBy",
            type: "text",
            searchValue: "lastChangedBy",
            selectedValue: '',
            width: NAME_WIDTH
        }

    ];

export const COLUMN_MAP_ACTIVE = Object.assign([{}], COMMON);

const extra = {
    value: "status",
    caption: "myHistory.status",
    type: "status",
    searchValue: "status",
    selectedValue: '',
    width: STATUS_WIDTH
};

export const COLUMN_MAP_COMPLETED = Object.assign([], COMMON).concat(extra);

const ACKNOWLEDGED = {
    value: "acknowledged",
    caption: "myHistory.acknowledged",
    type: TABLE_COLUMNS_TYPES.NONE,
    searchValue: "ack",
    selectedValue: '',
    width: ACK_WIDTH
};

export const PROCESS_OVERVIEW_COMPLETED = Object.assign([], COMMON).concat(extra).concat(ACKNOWLEDGED);

export const OVERVIEW_COMMON =
    [
        {
            value: "documentType",
            caption: "myHistory.type",
            type: TABLE_COLUMNS_TYPES.SELECT,
            searchValue: "type",
            selectedValue: '',
            align: "center",
            width: DOCTYPE_WIDTH
        },
        {
            value: "externalApplicationId",
            caption: "taskOverview.grid.documentId",
            type: TABLE_COLUMNS_TYPES.TEXT,
            searchValue: "taskid",
            selectedValue: '',
            width: DOCID_WIDTH
        },
        {
            value: "userName",
            caption: "taskOverview.grid.user",
            type: TABLE_COLUMNS_TYPES.TEXT,
            searchValue: "user",
            selectedValue: '',
            width: NAME_WIDTH
        },
        {
            value: "requesterName",
            caption: "taskOverview.grid.from",
            type: TABLE_COLUMNS_TYPES.TEXT,
            searchValue: "requesterName",
            selectedValue: ''
        },
        {
            value: "description",
            caption: "taskOverview.grid.description",
            type: TABLE_COLUMNS_TYPES.TEXT,
            searchValue: "description",
            selectedValue: ''
        },
        {
            value: "invoiceNumber",
            caption: "documentData.invoiceNumber",
            type: TABLE_COLUMNS_TYPES.TEXT,
            searchValue: "invoiceNumber",
            selectedValue: ''
        },
        {
            value: "invoiceDate",
            caption: "documentData.invoiceDate",
            type: TABLE_COLUMNS_TYPES.DATE_FILTER,
            searchValue: "invoiceDate",
            selectedValue: '',
            width: DATE_WIDTH
        },
        {
            value: "dueDate",
            caption: "documentData.dueDate",
            type: TABLE_COLUMNS_TYPES.DATE_FILTER,
            searchValue: "dueDate",
            selectedValue: '',
            width: DATE_WIDTH
        },
        {
            value: "processCreatedDate",
            caption: "taskOverview.grid.received",
            type: TABLE_COLUMNS_TYPES.DATE_FILTER,
            searchValue: "received_to",
            selectedValue: '',
            width: DATE_TIME_WIDTH
        },
        {
            value: "taskActivatedDate",
            caption: "taskOverview.grid.taskStart",
            type: TABLE_COLUMNS_TYPES.DATE_FILTER,
            searchValue: "taskstart_to",
            selectedValue: '',
            width: DATE_TIME_WIDTH
        },
        {
            value: "taskDueDate",
            caption: "taskOverview.grid.taskDue",
            type: TABLE_COLUMNS_TYPES.DATE_FILTER,
            searchValue: "taskdue_to",
            selectedValue: '',
            width: DATE_WIDTH
        }

    ];
export const OVERVIEW = Object.assign([{}], OVERVIEW_COMMON);

const SELECT_COLUMN = [{
    value: "isSelected",
    caption: "",
    type: TABLE_COLUMNS_TYPES.CHECKBOX,
    searchValue: "",
    selectedValue: '',
    width: CHECKBOX_WIDTH
}];

const roleColumn = {
    value: "role",
    caption: "taskOverview.grid.role",
    type: TABLE_COLUMNS_TYPES.ROLE_SELECT,
    searchValue: "asstype",
    selectedValue: '',
    width: ROLE_WIDTH
};

const statusColumn = {
    value: "taskOverviewStatus",
    caption: "taskOverview.grid.status",
    type: TABLE_COLUMNS_TYPES.TASK_STATUS,
    searchValue: "status",
    selectedValue: '',
    width: STATUS_WIDTH
};

export const OVERVIEW_ALL_STATUS = Object.assign([{}], OVERVIEW_COMMON).concat(roleColumn).concat(statusColumn);

export const OVERVIEW_NO_ROLE_NO_SELECT = Object.assign([{}], OVERVIEW_COMMON);

export const OVERVIEW_NO_SELECT = Object.assign([{}], OVERVIEW_COMMON).concat(roleColumn);

export const OVERVIEW_FULL = Object.assign([], SELECT_COLUMN.concat(OVERVIEW_COMMON)).concat(roleColumn);

export const DOCUMENT_OVERVIEW =
    [
        {
            value: "type",
            caption: "myHistory.type",
            type: TABLE_COLUMNS_TYPES.SELECT,
            searchValue: "type",
            selectedValue: '',
            width: DOCTYPE_WIDTH
        },
        {
            value: "claimId",
            caption: "myHistory.documentId",
            type: TABLE_COLUMNS_TYPES.TEXT,
            searchValue: "claimId",
            selectedValue: '',
            width: DOCID_WIDTH
        },
        {
            value: "description",
            caption: "taskOverview.grid.description",
            type: TABLE_COLUMNS_TYPES.TEXT,
            searchValue: "description",
            selectedValue: ''
        }

    ];

export const DOCUMENT_OVERVIEW_GENERIC = Object.assign([], SELECT_COLUMN.concat(DOCUMENT_OVERVIEW));

export const DOCUMENT_OVERVIEW_EXPENSE_CLAIM = [
    {
        value: "claimId",
        caption: "myHistory.documentId",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "claimId",
        selectedValue: '',
        width: DOCID_WIDTH
    },
    {
        value: "description",
        caption: "taskOverview.grid.description",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "description",
        selectedValue: ''
    },
    {
        value: "date",
        caption: "grid.received",
        type: "dateFilter",
        searchValue: "date",
        selectedValue: '',
        width: DATE_TIME_WIDTH
    },
    {
        value: "userName",
        caption: "grid.claimant",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "userName",
        selectedValue: ''
    },
    {
        value: "amount",
        caption: "myHistory.amount",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: AMOUNT_WIDTH
    },
    {
        value: "currencyCode",
        caption: "myHistory.unit",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "currencyCode",
        selectedValue: '',
        width: CURRENCY_WIDTH
    }
];

export const DOCUMENT_OVERVIEW_EXPENSE_CLAIM_SELECT = Object.assign([], SELECT_COLUMN.concat(DOCUMENT_OVERVIEW_EXPENSE_CLAIM));

export const DOCUMENT_OVERVIEW_VOUCHER = [
    {
        value: "claimId",
        caption: "myHistory.documentId",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "claimId",
        selectedValue: '',
        width: DOCID_WIDTH
    },
    {
        value: "voucherSeries",
        caption: "grid.voucherSeries",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "voucherSeries",
        selectedValue: '',
        width: DOCID_WIDTH
    },
    {
        value: "voucherNumber",
        caption: "grid.voucherNumber",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "voucherNumber",
        selectedValue: '',
        width: DOCID_WIDTH
    },
    {
        value: "description",
        caption: "taskOverview.grid.description",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "description",
        selectedValue: ''
    },
    {
        value: "dueDate",
        caption: "grid.dueDate",
        type: TABLE_COLUMNS_TYPES.DATE,
        searchValue: "dueDate",
        selectedValue: '',
        width: DATE_WIDTH
    },
    {
        value: "supplierName",
        caption: "grid.submitter",
        type: TABLE_COLUMNS_TYPES.DATE,
        searchValue: "submitterName",
        selectedValue: ''
    },
    {
        value: "amount",
        caption: "grid.amount",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: AMOUNT_WIDTH
    },
    {
        value: "currency",
        caption: "grid.currency",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "currencyCode",
        selectedValue: '',
        width: CURRENCY_WIDTH
    }

];

export const DOCUMENT_OVERVIEW_VOUCHER_SELECT = Object.assign([], SELECT_COLUMN.concat(DOCUMENT_OVERVIEW_VOUCHER));

export const DOCUMENT_OVERVIEW_INVOICE = [
    {
        value: "claimId",
        caption: "myHistory.documentId",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "claimId",
        selectedValue: '',
        width: DOCID_WIDTH
    },
    {
        value: "description",
        caption: "taskOverview.grid.description",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "description",
        selectedValue: ''
    },
    {
        value: "invoiceDate",
        caption: "grid.invoiceDate",
        type: "dateFilter",
        searchValue: "invoiceDate",
        selectedValue: '',
        width: DATE_WIDTH
    },
    {
        value: "supplierName",
        caption: "grid.supplierName",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "supplierName",
        selectedValue: ''
    },
    {
        value: "dueDate",
        caption: "grid.dueDate",
        type: "dateFilter",
        searchValue: "dueDate",
        selectedValue: '',
        width: DATE_WIDTH
    },
    {
        value: "amount",
        caption: "grid.amount",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: AMOUNT_WIDTH
    },
    {
        value: "currencyCode",
        caption: "grid.currency",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "currencyCode",
        selectedValue: '',
        width: CURRENCY_WIDTH
    }

];

export const DOCUMENT_OVERVIEW_INVOICE_SELECT = Object.assign([], SELECT_COLUMN.concat(DOCUMENT_OVERVIEW_INVOICE));

export const DOCUMENT_OVERVIEW_TIMESHEET = [
    {
        value: "claimId",
        caption: "myHistory.documentId",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "claimId",
        selectedValue: '',
        width: DOCID_WIDTH
    },
    {
        value: "description",
        caption: "taskOverview.grid.description",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "description",
        selectedValue: ''
    },
    {
        value: "submitterName",
        caption: "grid.submitter",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "submitterName",
        selectedValue: ''
    },
    {
        value: "weekNumber",
        caption: "grid.weekNumber",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "weekNumber",
        selectedValue: '',
        width: DATE_LARGE_WIDTH
    },
    {
        value: "weekStart",
        caption: "grid.from",
        type: TABLE_COLUMNS_TYPES.DATE_FILTER,
        searchValue: "weekStart",
        selectedValue: '',
        width: DATE_LARGE_WIDTH
    },
    {
        value: "weekEnd",
        caption: "grid.to",
        type: TABLE_COLUMNS_TYPES.DATE_FILTER,
        searchValue: "weekEnd",
        selectedValue: '',
        width: DATE_LARGE_WIDTH
    },
    {
        value: "totalHours",
        caption: "grid.totalHours",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: DATE_LARGE_WIDTH
    },
    {
        value: "totalInvoiceHours",
        caption: "grid.invoiceableHours",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: DATE_LARGE_WIDTH
    }

];

export const DOCUMENT_OVERVIEW_TIMESHEET_SELECT = Object.assign([], SELECT_COLUMN.concat(DOCUMENT_OVERVIEW_TIMESHEET));

export const DOCUMENT_OVERVIEW_LEAVE_REQUEST = [
    {
        value: "claimId",
        caption: "myHistory.documentId",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "claimId",
        selectedValue: '',
        width: DOCID_WIDTH
    },
    {
        value: "eventCode",
        caption: "grid.eventType",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: ROLE_WIDTH
    },
    {
        value: "description",
        caption: "taskOverview.grid.description",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "description",
        selectedValue: ''
    },
    {
        value: "date",
        caption: "myHistory.received",
        type: "dateFilter",
        searchValue: "date",
        selectedValue: '',
        width: DATE_TIME_WIDTH
    },
    {
        value: "userName",
        caption: "grid.claimant",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "userName",
        selectedValue: ''
    },
    {
        value: "fromDate",
        caption: "grid.from",
        type: "dateFilter",
        searchValue: "fromDate",
        selectedValue: '',
        width: DATE_WIDTH
    },
    {
        value: "toDate",
        caption: "grid.to",
        type: "dateFilter",
        searchValue: "toDate",
        selectedValue: '',
        width: DATE_WIDTH
    },
    {
        value: "numberOfDays",
        caption: "grid.numberOfDays",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: DATE_TIME_WIDTH
    }

];

export const DOCUMENT_OVERVIEW_LEAVE_REQUEST_SELECT = Object.assign([], SELECT_COLUMN.concat(DOCUMENT_OVERVIEW_LEAVE_REQUEST));

export const DOCUMENT_OVERVIEW_COST_REQUEST = [
    {
        value: "claimId",
        caption: "myHistory.documentId",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "claimId",
        selectedValue: ''
    },
    {
        value: "supplierName",
        caption: "taskOverview.grid.from",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "supplierName",
        selectedValue: ''
    },
    {
        value: "description",
        caption: "taskOverview.grid.description",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "description",
        selectedValue: ''
    },
    {
        value: "amount",
        caption: "grid.amount",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: '',
        width: AMOUNT_WIDTH
    },
    {
        value: "currencyCode",
        caption: "grid.currency",
        type: TABLE_COLUMNS_TYPES.TEXT,
        searchValue: "currencyCode",
        selectedValue: '',
        width: CURRENCY_WIDTH
    },
    {
        value: "date",
        caption: "myHistory.received",
        type: "dateFilter",
        searchValue: "date",
        selectedValue: '',
        width: DATE_TIME_WIDTH
    }

];

export const DOCUMENT_OVERVIEW_COST_REQUEST_SELECT = Object.assign([], SELECT_COLUMN.concat(DOCUMENT_OVERVIEW_COST_REQUEST));

export const ORGANISATION_CHART = [
    {
        value: "roleName",
        caption: "Role",
        type: "role_select",
        searchValue: "roleName",
        selectedValue: '',
        width: 250
    },
    {
        value: "holder",
        caption: "Holder",
        type: "text",
        searchValue: "holder",
        selectedValue: ''
    },
    {
        value: "deputy",
        caption: "Deputy",
        type: "checkbox",
        searchValue: "deputy",
        selectedValue: '',
        width: STATUS_WIDTH
    }

];

export const WORKFLOW_HISTORY_BASE = [
    {
        value: "validFrom",
        caption: "Date",
        type: TABLE_COLUMNS_TYPES.DATE_FILTER,
        searchValue: "validFrom",
        selectedValue: '',
        width: 170
    },
    {
        value: "operation",
        caption: "Action",
        type: TABLE_COLUMNS_TYPES.NONE,
        searchValue: "",
        selectedValue: ''
    },
    {
        value: "description",
        caption: "Workflow name",
        type: "text",
        searchValue: "description",
        selectedValue: '',
        width: 220
    },
    {
        value: "comment",
        caption: "Comment",
        type: "text",
        searchValue: "comment",
        selectedValue: ''
    },
    {
        value: "status",
        caption: "Status",
        type: "custom",
        searchValue: "active",
        selectedValue: '',
        width: 110
    },
    {
        value: "user",
        caption: "User",
        type: "text",
        searchValue: "idUser",
        selectedValue: ''
    }
];

export const WORKFLOW_HISTORY_WITH_PREVIEW = WORKFLOW_HISTORY_BASE.concat({
    value: "preview",
    caption: "",
    type: "custom",
    searchValue: "",
    selectedValue: '',
    width: 40
});
