import React from 'react';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import Confirm from '../popup/ConfirmActionPopup.component';
import AddSubstitute from '../popup/AddSubstitutePopUp.component';
import translate from '../translations/translations.wrapper.jsx';
import ConfigurationWrapper from '../configuration/ConfigurationWrapper.component';
import SearchGroupInput from "../input/SearchGroupInput.component";
import "./substitutes.scss";
import ListWithActions from "../listWithActions/ListWithActions.component";
import {
    changeActiveFilter,
    changeSearchValue,
    getSubstitutes,
    deleteSubstitute,
    changeSort
} from './substitutes.action';
import {formatDate} from '../../utils/valueFormatter.function';
import {LOADING_STATUS} from "../../utils/constants";
import * as _ from 'lodash';

const TODAY = new Date().setHours(0, 0, 0, 0);
const NOT_ASSIGNED = "N/A";

const STATUS_FILTER = {
    ALL: "all",
    CURRENT: "current",
    UPCOMING: "upcoming"
};
let stopScroll = false;
const FIRST_PAGE = 1;

export class Substitutes extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            selectedSubstitute: null,
            confirmDelete: false,
            rowToDelete: null,
            showSubstitutePopUp: false
        };

        this.buildList = this.buildList.bind(this);

        this.clearSearch = this.clearSearch.bind(this);
        this.closeDeletePopUp = this.closeDeletePopUp.bind(this);
        this.closeSubstitutePopUp = this.closeSubstitutePopUp.bind(this);
        this.computeHeader = this.computeHeader.bind(this);
        this.computeStatus = this.computeStatus.bind(this);

        this.deleteRow = this.deleteRow.bind(this);
        this.editRow = this.editRow.bind(this);
        this.filter = this.filter.bind(this);
        this.getData = this.getData.bind(this);

        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.openAddSubstitutePopUp = this.openAddSubstitutePopUp.bind(this);

        this.removeRow = this.removeRow.bind(this);
        this.saveCloseCallback=this.saveCloseCallback.bind(this);
        this.search = this.search.bind(this);
        this.sort = this.sort.bind(this);
    }

    componentDidMount(props) {
        super.componentDidMount(props);
        this.computeHeader();
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        if (this.props.userRoles.systemAdministrator !== prevProps.userRoles.systemAdministrator) {
            this.computeHeader();
        }
    }

    getData() {
        this.props.getSubstitutes(FIRST_PAGE, this.props.activeFilter, this.props.searchValue, this.props.sort);
    }

    buildList() {
        stopScroll = false;
        return this.props.substitutes && this.props.substitutes.allSubstitutes ? this.props.substitutes.allSubstitutes.map(sub => {
            let fromDate = sub.from ? new Date(sub.from).setHours(0, 0, 0, 0) : null;
            let toDate = sub.to ? new Date(sub.to).setHours(0, 0, 0, 0) : null;
            let status = this.computeStatus(fromDate);

            return {
                user: sub.userName,
                userId: sub.userId,
                substituteId: sub.substituteId,
                substitute: sub.substituteName !== NOT_ASSIGNED ? sub.substituteName : "",
                notBefore: formatDate(fromDate),
                notAfter: toDate ? formatDate(toDate) : (sub.from ? this.props.translate("substitutes.indefinitely") : "-"),
                status: status,
                version: sub.version
            };
        }) : [];
    }

    clearSearch() {
        this.props.changeSearchValue("");
        this.props.getSubstitutes(this.props.substitutes.page, this.props.activeFilter, "", this.props.sort, true);
    }

    closeDeletePopUp() {
        this.setState({
            confirmDelete: false
        });
    }

    closeSubstitutePopUp() {
        this.setState({
            showSubstitutePopUp: false
        });
        this.props.getSubstitutes(FIRST_PAGE, this.props.activeFilter, "", this.props.sort,true);
    }

    computeStatus(fromDate) {
        //no start date -> not current, not upcoming
        if (fromDate === null)
            return "";

        return (fromDate <= TODAY) ? this.props.translate("substitutes.current") : this.props.translate("substitutes.upcoming");

    }

    computeHeader() {
        //there is no action column, so we can make the name columns bigger
        //const largeNames = !this.props.userRoles.systemAdministrator;

        let header = [
            {
                value: "user",
                text: "substitutes.headers.user",
                class: "col-md-3",
                sortable: true
            },
            {
                value: "substitute",
                text: "substitutes.headers.substitute",
                class: "col-md-3",
                sortable: true
            },
            {
                value: "notBefore",
                text: "substitutes.headers.notBefore",
                class: "col-md-2",
                sortable: true
            },
            {
                value: "notAfter",
                text: "substitutes.headers.notAfter",
                class: "col-md-2",
                sortable: true
            },
            {
                value: "status",
                text: "substitutes.headers.status",
                class: "col-md-1"
            }
        ];

        this.setState({
            header: header
        });

    }

    deleteRow(rowId) {
        this.setState({
            confirmDelete: true,
            rowToDelete: rowId
        });
    }

    editRow(rowIndex) {
        this.setState({
            selectedSubstitute: this.props.substitutes.allSubstitutes[rowIndex],
            showSubstitutePopUp: true
        });
    }

    filter(value) {
        this.props.changeActiveFilter(value);
        this.props.getSubstitutes(this.props.substitutes.page, value, this.props.searchValue, this.props.sort, true);
    }

    loadMoreItems() {
        if (!stopScroll) {
            let {allSubstitutes, records, page} = this.props.substitutes;
            stopScroll = true;
            if (allSubstitutes.length > 0 && allSubstitutes.length < records) {
                this.props.getSubstitutes(page + 1, this.props.activeFilter, this.props.searchValue, this.props.sort, true);
            }
        }
    }


    openAddSubstitutePopUp() {
        this.setState({
            selectedSubstitute: null,
            showSubstitutePopUp: true
        });
    }

    removeRow() {
        this.setState({
            confirmDelete: false
        });
        this.props.deleteSubstitute(this.state.rowToDelete);
        this.props.getSubstitutes(this.props.substitutes.page, this.props.activeFilter, this.props.searchValue, this.props.sort, true);
    }

    saveCloseCallback(){
        this.props.changeActiveFilter(STATUS_FILTER.ALL);
        this.closeSubstitutePopUp();
    }

    search(event) {
        this.props.changeSearchValue(event.target.value);
        this.props.getSubstitutes(this.props.substitutes.page, this.props.activeFilter, event.target.value, this.props.sort, true);
    }

    sort(column) {
        let currentHeader = _.cloneDeep(this.state.header);
        let newSort = {
            fieldName: currentHeader[column].value,
            sortOrder: true
        };

        let currentSort = Object.assign({}, this.props.sort);
        if (currentSort.fieldName === newSort.fieldName) {
            newSort.sortOrder = !currentSort.sortOrder;
        }

        currentHeader.forEach((header, index) => {
            //pressed column header
            if (index === column) {
                if (header.sortable) {
                    header.sortOrder = newSort.sortOrder ? "up" : "down";
                }
            }
            else {
                delete header.sortOrder;
            }
        });

        this.props.changeSort(newSort);
        this.props.getSubstitutes(this.props.substitutes.page, this.props.activeFilter, this.props.searchValue, newSort, true);

        this.setState({
            header: currentHeader
        });

    }

    buildContent() {
        return (
            <div id="Substitutes_page">

                {this.state.confirmDelete &&
                <Confirm translate={this.props.translate}
                         popUpType={"deleteSubstitute"}
                         message={this.props.translate("substitutes.confirmDelete", this.props.substitutes.allSubstitutes[this.state.rowToDelete].substituteName, this.props.substitutes.allSubstitutes[this.state.rowToDelete].userName)}
                         handleAction={this.removeRow} closeCallback={this.closeDeletePopUp}/>
                }

                {this.state.showSubstitutePopUp &&
                <AddSubstitute translate={this.props.translate}
                               edit={this.state.selectedSubstitute}
                               saveCloseCallback={this.saveCloseCallback}
                               closeCallback={this.closeSubstitutePopUp}/>
                }

                <h3 className="no-border d-flex align-items-center pt-4">{this.props.translate("substitutes.header")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                          title={this.props.translate("substitutes.headerInfo")}/>
                </h3>

                <div className="row mx-0">
                    <div className="col-md-3 mb-4">
                        <SearchGroupInput onInputChange={this.search}
                                          placeholder={this.props.translate("popUp.addSubstitute.name")}
                                          value={this.props.searchValue}
                                          onClearInput={this.clearSearch}
                                          translate={this.props.translate}/>
                    </div>
                </div>

                <div className="row mx-0">
                    <div className="col-md-12">
                        <StatusFilters translate={this.props.translate}
                                       newSubstitution={this.openAddSubstitutePopUp}
                                       filter={this.filter}
                                       canAddSubstitute={this.props.userRoles.systemAdministrator}
                                       activeFilter={this.props.activeFilter}/>
                        {this.props.loadingStatus === LOADING_STATUS.DONE &&
                            <ListWithActions header={this.state.header}
                                             values={this.buildList()}
                                             sortHandle={this.sort}
                                             deleteRow={this.deleteRow}
                                             editRow={this.editRow}
                                             sortColumn={this.props.sort}
                                             scrollAtBottom={this.loadMoreItems}
                                             showActions={this.props.userRoles.systemAdministrator}
                                             translate={this.props.translate}/>
                        }

                        {this.props.loadingStatus === LOADING_STATUS.LOADING &&
                            <span className="spinner spinner-default-blue loading"/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const withTranslations = translate(Substitutes);
const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        activeFilter: reduxSelectors.getSubstitutesActiveFilter(store),
        searchValue: reduxSelectors.getSubstitutesSearchValue(store),
        substitutes: reduxSelectors.getSubstitutesForRender(store),
        sort: reduxSelectors.getSubstitutesSorting(store),
        loadingStatus: reduxSelectors.getSubstitutesLoadingStatus(store)
    };
};

const connected = connect(mapStateToProps, {
    changeActiveFilter,
    changeSearchValue,
    getSubstitutes,
    deleteSubstitute,
    changeSort
})(withTranslations);

export default connected;

const StatusFilters = (props) => {
    return (
        <div className="btn-toolbar btn-toolbar-primary d-flex justify-content-between">
            <div className="btn-group" role="group">
                <button type="button"
                        onClick={() => {
                            props.filter(STATUS_FILTER.ALL)
                        }}
                        className={"btn btn-default " + (props.activeFilter === STATUS_FILTER.ALL ? "active" : "")}>
                    {props.translate("substitutes.all")}
                </button>

                <button type="button"
                        onClick={() => {
                            props.filter(STATUS_FILTER.CURRENT)
                        }}
                        className={"btn btn-default " + (props.activeFilter === STATUS_FILTER.CURRENT ? "active" : "")}>
                    {props.translate("substitutes.current")}
                </button>
                <button type="button"
                        onClick={() => {
                            props.filter(STATUS_FILTER.UPCOMING)
                        }}
                        className={"btn btn-default " + (props.activeFilter === STATUS_FILTER.UPCOMING ? "active" : "")}>
                    {props.translate("substitutes.upcoming")}
                </button>
            </div>

            {props.canAddSubstitute &&
            <div className="btn-group">
                <button type="button"
                        onClick={props.newSubstitution}
                        className={"btn btn-default active"}>
                    {props.translate("substitutes.newSubstitute")}
                </button>
            </div>
            }
        </div>

    );
};