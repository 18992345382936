import React, {Component} from 'react';
import {Form} from 'react-bootstrap';
import UserContextSelector from './UserContextSelector.component';

export default class ContextSelectorBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false // when parent closes, close this too
        };
        this.onBlur = this.onBlur.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.collapseSelector = this.collapseSelector.bind(this);
    }


    onBlur(e) {
        let self = this;
        //let currentTarget = e.currentTarget;
        setTimeout(function () {
           // if (!currentTarget.contains(document.activeElement)) {
                self.collapseSelector();
           // }
        }, 0);
    }


    collapseSelector() {
        this.setState({edit: false});
    }

    onSelection(value) {
        this.collapseSelector();

        // call redux action mounted in parent
        this.props.onSelection(value);
    }

    render() {
        let content = null;
        if (this.props.parentExpanded) {
            content = <UserContextSelector
                translate={this.props.translate}
                currentContextId={this.props.currentContextId}
                onSelection={this.onSelection}
                companyName={this.props.userData.companyName}
                collapseSelection={this.collapseSelector}
            />
        } else {
            content = <CurrentUserView name={this.props.userData.companyName}
                                       onClick={() => {
                                           this.setState({edit: true})
                                       }}
                                       />
        }

        return (
            <Form className="context-selector p-3">
                <div className="form">
                    <div className="one-context-type" onBlur={this.onBlur}>
                        {content}
                    </div>
                </div>
            </Form>
        );
    }
}

// https://facebook.github.io/react/blog/2015/10/07/react-v0.14.html#stateless-functional-components
const CurrentUserView = (props) => (
    <div className="company-input ui-select-container ui-select-bootstrap dropdown open"
         onClick={props.onClick}>
        <div className="ui-select-match">
                        <span tabIndex={0}
                              className="btn btn-default form-control ui-select-toggle"
                              aria-label="Select box activate" style={{outline: 0}}>
                            <span className="ui-select-match-text pull-left row mx-0">
                                <span className="company-name">{props.name}</span>
                            </span>
                            <i className="caret pull-right"/>
                        </span>
        </div>
    </div>

);
