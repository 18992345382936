import {
    ORG_CHART_TREE_LOADING,
    ORG_CHART_TREE_LOADED,
    ORG_CHART_USERS_LOADED,
    ORG_CHART_ROLES_LOADED,
    ORG_CHART_MORE_USERS_LOADED,
    ORG_CHART_CHILDREN_LOADED,
    ORG_CHART_CHANGE_SELECTED_NODE,
    ORG_CHART_CHANGE_SELECTED_DATE,
    ORG_CHART_FILTERS_CHANGED,
    ORG_CHART_SORT_COLUMN_CHANGED
} from '../store/actionTypes';
import {createSelector} from 'reselect';
import {LOADING_STATUS} from "utils/constants";

import * as _ from 'lodash';

let TODAY = new Date().toISOString().substring(0, 10);

export const DEFAULT_STATE = {
    orgChartTree: null,
    users: {
        rows: []
    },
    usersLoadingStatus: null,
    selectedNode: null,
    roles: null,
    childrenObject: {},
    filters: {
        columns: {},
        date: TODAY,
        sort: null
    },
    sortingLogic: {}
};

export const getOrgChartUsersForRender = createSelector(
    [getOrgChartUsers],
    (users) => {
        let mappedUsers = _.cloneDeep(users);

        let response = users.rows ? users.rows.map((user, index) => {
            let mappedUser = _.cloneDeep(user);
            mappedUser.isSelected = user.deputy;
            mappedUser.index = index;

            return mappedUser;
        }) : [];

        mappedUsers.rows = response;

        return mappedUsers;
    }
);

export default function organisationChartReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case ORG_CHART_TREE_LOADING:
            partialState = Object.assign({}, state);
            partialState.loadingStatus = LOADING_STATUS.LOADING;

            return Object.assign({}, state, partialState);

        case ORG_CHART_TREE_LOADED:
            partialState = Object.assign({}, state);
            partialState.orgChartTree = action.data;
            partialState.filters = Object.assign({}, DEFAULT_STATE.filters);
            partialState.users = Object.assign({}, DEFAULT_STATE.users);
            partialState.childrenObject = {};
            partialState.selectedNode = null;
            partialState.sortingLogic = {};

            return Object.assign({}, state, partialState);

        case ORG_CHART_CHILDREN_LOADED:
            partialState = Object.assign({}, state);
            partialState.childrenObject[action.nodeId] = action.data;

            return Object.assign({}, state, partialState);

        case ORG_CHART_USERS_LOADED:
            partialState = Object.assign({}, state);
            partialState.users = action.data;
            partialState.loadingStatus = LOADING_STATUS.DONE;

            return Object.assign({}, state, partialState);

        case ORG_CHART_ROLES_LOADED:
            partialState = Object.assign({}, state);
            partialState.roles = action.data;

            return Object.assign({}, state, partialState);

        case ORG_CHART_MORE_USERS_LOADED:
            partialState = Object.assign({}, state);
            let oldArray = Object.assign([], partialState.users);
            let allUsers = oldArray.concat(action.data.rows);
            let newData = Object.assign({}, action.data);
            newData.rows = allUsers;
            partialState = {
                users: newData
            };
            return Object.assign({}, state, partialState);

        case ORG_CHART_CHANGE_SELECTED_NODE:
            partialState = Object.assign({}, state);
            partialState.selectedNode = action.data;
            partialState.filters = Object.assign({}, DEFAULT_STATE.filters);
            partialState.sortingLogic = {};

            return Object.assign({}, state, partialState);

        case ORG_CHART_CHANGE_SELECTED_DATE:
            partialState = Object.assign({}, state);
            partialState.filters.date = action.data;
            partialState.selectedNode = null;
            partialState.filters.columns = {};
            partialState.filters.sort = null;
            partialState.sortingLogic = {};

            return Object.assign({}, state, partialState);

        case ORG_CHART_FILTERS_CHANGED:
            partialState = Object.assign({}, state);
            partialState = {
                filters: action.data
            };
            return Object.assign({}, state, partialState);

        case ORG_CHART_SORT_COLUMN_CHANGED:
            partialState = {
                filters: Object.assign({}, state.filters),
                sortingLogic: Object.assign({}, action.sortLogic)
            };

            partialState.filters.sort = action.sortColumn;
            return Object.assign({}, state, partialState);


        default:
            return state;
    }
}


export function getOrgChartUsers(state) {
    return state.users;
}

export function getOrgChartSelectedNode(state) {
    return state.selectedNode;
}

export function getOrgChartSelectedDate(state) {
    return state.filters.date;
}

export function getOrgChartRoles(state) {
    let mappedRoles = [];
    if (state.roles) {
        mappedRoles = state.roles.map(role => {
            return {actorTypeCode: role.orgChartRoleId, actorTypeName: role.name}
        });
    }
    return mappedRoles;
}

export function getOrgChartFilters(state) {
    return state.filters;
}

export function getOrgChartTree(state) {
    return state.orgChartTree;
}

export function getOrgChartSortingLogic(state) {
    return state.sortingLogic;
}

export function getOrgChartSortingColumn(state) {
    return state.filters.sort;
}

export function getOrgChartUsersLoadingStatus(state) {
    return state.loadingStatus;
}

export function getOrgChartChildNodes(state) {
    return state.childrenObject;
}