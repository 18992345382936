import {REAPPROVAL_TOOLTIP} from "./constants";

export function getTaskPreviewTooltip({pastActions, actorType, taskAssignmentType}, translate) {
    let tooltip = [];

    if (!pastActions?.actedOnDocumentBefore)
        return tooltip;


    //sentence 1
    if (pastActions?.approvedTaskOnDocumentBefore) {
        tooltip.push(translate('myTasks.reapprovalTooltip.approvedDocumentBefore'))
    } else {
        tooltip.push(translate('myTasks.reapprovalTooltip.actedOnDocumentBefore'))
    }

    //sentence 2
    if (actorType === 'SUBSTITUTE') {
        tooltip.push(translate('myTasks.reapprovalTooltip.substituteOnNewTask'))
    }

    let assignmentTypeTranslation =  REAPPROVAL_TOOLTIP[taskAssignmentType];
    if (assignmentTypeTranslation)
        tooltip.push(translate('myTasks.reapprovalTooltip.' + REAPPROVAL_TOOLTIP[taskAssignmentType]));


    if (!pastActions?.approvedTaskOnDocumentBefore) {
        tooltip.push(translate('myTasks.reapprovalTooltip.newTask'))
    }
    if (pastActions?.newLinesInSameStep) {
        tooltip.push(translate('myTasks.reapprovalTooltip.newLineAdded'))
    }
    if (pastActions?.changedLinesInSameStep) {
        tooltip.push(translate('myTasks.reapprovalTooltip.changeOnDocument'))
    }
    if (pastActions?.approvedOtherStep) {
        tooltip.push(translate('myTasks.reapprovalTooltip.newTask'))
    }

    return tooltip;
}