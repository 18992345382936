import {
    SAVE_MY_TASKS_LOADING,
    SAVE_MY_TASKS_LOADING_DONE,
    SAVE_MY_TASKS_DONE,
    SAVE_MY_TASKS_ERROR,
    LOGGED_OUT_TYPE,
    CHANGE_COMPANY_FILTER,
    CHANGE_SEARCH_FILTER,
    CHANGE_SORT,
    TOGGLE_TASK_SELECTION,
    TOGGLE_ALL_SELECTION,
    TASK_REMOVE_ONE,
    LOGGED_IN_TYPE,
    ENFORCE_APPROVE_DIALOG,
    SCROLL_POSITION,
    HIDDEN_TASKS_LOADED,
    CHANGE_DISPLAYED_DATE,
    ADD_TO_BATCH,
    BATCH_LOADED,
    UNBUNDLE_BATCH,
    CHANGE_AUTOPAY_URL,
    HIDE_AUTOPAY_IFRAME,
    MY_TASKS_ADD,
    MY_TASKS_REMOVED,
    TASKS_HANDLED_IN_BACKGROUND, STARTUP_ERROR
} from '../store/actionTypes';
import {LOADING_STATUS, MY_TASKS_DATES} from 'utils/constants';
import {sortTasks, DEFAULT_SORT} from './taskListSorting.function';
import {filterTasks} from './taskListFiltering.function';
import {createSelector} from 'reselect'
import _ from "lodash";

import {getUsersCurrentContextId} from '../usercontext/user.reducer';

/**
 * filter and sort tasks, and divide then into subsets (all > filtered > selected)
 *
 * <p>MEMOIZED selector, because it returns 'new object' each time it's called (new collection with filtered items), and because it's used inside redux connect,
 * it's called each time any part of whole store state changes .. which would then cause rerender of the connected component (because it would get new props)
 * @see http://redux.js.org/docs/recipes/ComputingDerivedData.html#creating-a-memoized-selector
 * @param state
 * @return {{allTasks: *, filteredTasks: *, selectedTasks: *, filteredKeys: Array, selectedKeys: Array, areAllSelected: boolean}}
 */
export const getTasksForRender = createSelector(
    [getTasks, getFilterCompany, getFilterText, getSort, getCurrentContextId],
    (tasksMap, isOnlyForCompany, search, sort, currentCompany) => {
        const tasks = _mapToArray(tasksMap);
        // filter unwanted
        const onlyForCompany = isOnlyForCompany ? currentCompany : null;
        const filteredTasks = filterTasks(tasks, search, onlyForCompany);
        // and sort
        const sortedAndFilteredTasks = sortTasks(filteredTasks, sort);

        let filteredTaskKeys = [];
        let selectedTaskKeys = [];
        let selectedTasks = [];
        sortedAndFilteredTasks.forEach(task => {
            filteredTaskKeys.push(task.task.key);
            if (task.selected) {
                selectedTasks.push(task.task);
                selectedTaskKeys.push(task.task.key);
            }
        });

        // let's pass the tasks as immutable .. to have the store state directly immutable is a bit tricky (and I don't have time for it :) )
        return {
            allTasks: tasks, // all tasks without filters in place ~ A
            filteredTasks: sortedAndFilteredTasks, // all tasks after filtering ~ B, subset of A
            selectedTasks: selectedTasks, // all selected tasks ~ C, subset of B .. unwrapped
            filteredKeys: filteredTaskKeys, // keys of filteredTasks
            selectedKeys: selectedTaskKeys, // keys of selectedTasks
            areAllSelected: (filteredTaskKeys.length === selectedTaskKeys.length && filteredTaskKeys.length !== 0)
        };
    }
);

/**
 * default reducer's substate
 * @type {{loadingStatus: number, data: {}, filterText: string, filterCompany: null, sort: number}}
 */
export const DEFAULT_STATE = {
    loadingStatus: LOADING_STATUS.LOADING,
    data: {}, // task key as attribute name
    filterText: '',
    filterCompany: false, // whether we should filter shown tasks to current context
    currentContext: null, // current context (value for filterCompany=true)
    sort: DEFAULT_SORT,
    forceApproveDialog: false,
    scrollTop: 0,
    hiddenTasks: 0,
    displayedDate: MY_TASKS_DATES.DOC_DUE,
    tasksWithPayment: [],
    batchTasks: null,
    autopayURL: null,
    showAutopayiFrame: false,
    handledTasksKeys: []
};

/**
 * reducer for actions invoked from myTasks/taskDetail
 */
export default function myTasksReducer(state = DEFAULT_STATE, action) {
    let partialState, tasks, newTasks;

    switch (action.type) {
        //remove after cleanup of the store is done for this error case
        case  STARTUP_ERROR:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                handledTasksKeys: [],
                data: {} //clear the data
            };
            return Object.assign({}, state, partialState);
        case SAVE_MY_TASKS_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                handledTasksKeys: []
            };
            return Object.assign({}, state, partialState);

        case SAVE_MY_TASKS_LOADING_DONE:
            partialState = {
                loadingStatus: LOADING_STATUS.DONE,
                handledTasksKeys: []
            };
            return Object.assign({}, state, partialState);

        case SAVE_MY_TASKS_DONE:

            newTasks = _arrayToMap(action.data || []);

            tasks = getTasksForRender(state);
            tasks.selectedKeys.forEach((alreadySelected) => {
                // in case we got some from rehydration, which are no longer valid
                if (newTasks[alreadySelected] !== undefined) {
                    newTasks[alreadySelected].selected = true;
                }
            });

            partialState = {
                loadingStatus: LOADING_STATUS.DONE,
                data: newTasks,
                tasksWithPayment: action.tasksWithPayment
            };
            return Object.assign({}, state, partialState);

        case MY_TASKS_ADD:
            let addedTasks = action.data?.tasks;
            let currentTasks = Object.assign({}, getTasks(state));

            addedTasks.forEach(newTask => {
                //only add tasks if the loading of the page has happened
                if (state.loadingStatus === LOADING_STATUS.DONE) {
                    if (!currentTasks.hasOwnProperty(newTask.key)) {
                        currentTasks[newTask.key] = {};
                        currentTasks[newTask.key].task = Object.assign({}, newTask);
                        currentTasks[newTask.key].task.isNew = true;
                        currentTasks[newTask.key].selected = false;
                    }
                }
            });
            partialState = {
                data: currentTasks
            };
            return Object.assign({}, state, partialState);

        case TASKS_HANDLED_IN_BACKGROUND:
            let tasksHandled = action.data;
            partialState = {
                handledTasksKeys: tasksHandled
            };

            return Object.assign({}, state, partialState);

        case MY_TASKS_REMOVED:
            let removedId = action.data;
            tasks = Object.assign({}, getTasks(state));
            let renderedTasks = getTasksForRender(state);
            if (!_.isEmpty(tasks)) {
                let foundTask = renderedTasks.allTasks.find(task => {
                    return task.task.id === removedId;
                });
                let taskKey = foundTask?.task?.key;

                if (foundTask?.task && taskKey) {
                    //automatically delete if my tasks is not in view
                    let notInMyTasks = window.location.pathname !== "/";
                    let notInViewTask = !window.location.pathname.includes(taskKey);
                    if ((notInViewTask && notInMyTasks) || (renderedTasks.filteredKeys.length > 0 && _.indexOf(renderedTasks.filteredKeys, taskKey) === -1)) {
                        delete tasks[taskKey];
                    } else {
                        tasks[taskKey].task.isHandled = true;
                        tasks[taskKey] = Object.assign({}, tasks[taskKey], {selected: false});
                    }
                }

                partialState = {
                    data: tasks,
                    handledTasksKeys: [removedId]
                };
            }
            return Object.assign({}, state, partialState);


        case HIDDEN_TASKS_LOADED:
            partialState = {
                hiddenTasks: action.data
            };
            return Object.assign({}, state, partialState);

        case SAVE_MY_TASKS_ERROR:
            partialState = {
                loadingStatus: LOADING_STATUS.ERROR,
                data: {}
            };
            return Object.assign({}, state, partialState);

        case TOGGLE_TASK_SELECTION:
            /*
             type: TOGGLE_TASK_SELECTION,
             data: taskId
             */
            tasks = Object.assign({}, getTasks(state));
            if (tasks[action.data] !== undefined) {
                tasks[action.data] = Object.assign({}, tasks[action.data], {selected: !tasks[action.data].selected});
            }

            partialState = {
                data: tasks
            };
            return Object.assign({}, state, partialState);

        case TOGGLE_ALL_SELECTION:
            /*
             type: TOGGLE_ALL_SELECTION,
             data: wantedState
             */
            tasks = getTasksForRender(state);

            // if wanted state is in the action, take it from there
            // else if at least one is not selected, select all
            // else deselect all
            const targetState = action.data !== null ? Boolean(action.data) : !tasks.areAllSelected;

            newTasks = Object.assign({}, getTasks(state));
            tasks.filteredKeys.forEach(key => {
                newTasks[key] = Object.assign({}, newTasks[key], {selected: targetState});
            });

            partialState = {
                data: newTasks
            };
            return Object.assign({}, state, partialState);

        case CHANGE_COMPANY_FILTER:
            /*
             type: CHANGE_FILTER,
             data: companyFilter
             */
            tasks = getTasks(state);
            partialState = {
                filterCompany: action.data || false,
                data: _clearTaskSelection(tasks),
                scrollTop: 0
            };

            return Object.assign({}, state, partialState);

        /**
         * save of payload from /startup
         */
        case LOGGED_IN_TYPE:
            const newContextId = getUsersCurrentContextId(action.data);
            return Object.assign({}, state, {currentContext: newContextId, scrollTop: 0});

        case CHANGE_SEARCH_FILTER:
            /*
             type: CHANGE_FILTER,
             data: companyFilter
             */
            tasks = getTasks(state);
            partialState = {
                filterText: action.data || '',
                data: _clearTaskSelection(tasks),
                scrollTop: 0
            };

            return Object.assign({}, state, partialState);

        case CHANGE_SORT:
            /*
             type: CHANGE_SORT,
             data: newSort
             */
            partialState = {
                sort: action.data || DEFAULT_SORT,
                scrollTop: 0
            };
            return Object.assign({}, state, partialState);

        case CHANGE_DISPLAYED_DATE:
            partialState = {
                displayedDate: action.data || DEFAULT_SORT,
                scrollTop: 0
            };
            return Object.assign({}, state, partialState);
        case TASK_REMOVE_ONE:
            /*
             type: TASK_REMOVE_ONE,
             data: key
             */
            newTasks = Object.assign({}, getTasks(state));
            if (newTasks[action.data] !== undefined) {
                delete newTasks[action.data];
            }

            partialState = {
                data: newTasks
            };
            return Object.assign({}, state, partialState);

        case LOGGED_OUT_TYPE:
            return DEFAULT_STATE;

        case ENFORCE_APPROVE_DIALOG:
            partialState = {
                forceApproveDialog: action.data
            };
            return Object.assign({}, state, partialState);

        case SCROLL_POSITION:
            partialState = {
                scrollTop: action.data
            };
            return Object.assign({}, state, partialState);

        case BATCH_LOADED:
            partialState = {
                batchTasks: action.data
            };
            return Object.assign({}, state, partialState);

        case  ADD_TO_BATCH:
            partialState = Object.assign({}, state);

            let currentBatch = Object.assign([], partialState.batchTasks);
            if (currentBatch === null)
                currentBatch = [];

            let foundIndex = _.findIndex(currentBatch, (task) => {
                return task.key === action.data
            });
            if (foundIndex === -1)
                currentBatch = currentBatch.concat(action.data);
            partialState.batchTasks = currentBatch;
            if (partialState.data[action.data])
                delete partialState.data[action.data];

            return Object.assign({}, state, partialState);

        case UNBUNDLE_BATCH:
            partialState = Object.assign({}, state);
            partialState.batchTasks = null;
            return Object.assign({}, state, partialState);

        case CHANGE_AUTOPAY_URL:
            partialState = Object.assign({}, state);
            partialState.autopayURL = action.data;
            partialState.showAutopayiFrame = Boolean(action.data);

            return Object.assign({}, state, partialState);

        case HIDE_AUTOPAY_IFRAME:
            partialState = Object.assign({}, state);
            partialState.showAutopayiFrame = false;

            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}

// ----------- state selectors, keep the components as simple as possible -----------
export function getTasks(state) {
    //could not load the task list
    if (state.loadingStatus === LOADING_STATUS.ERROR)
        return {};
    return state.data;
}

export function getTask(taskKey, state) {
    let mapp = _arrayToMap(state.tasksWithPayment);
    let fullTaskList = Object.assign({}, state.data, mapp);
    return fullTaskList[taskKey];
}

export function getHiddenTasks(state) {
    return state.hiddenTasks;
}

export function getLoadingStatus(state) {
    return state.loadingStatus;
}

export function getSort(state) {
    return state.sort;
}

export function getFilterText(state) {
    return state.filterText;
}

export function getFilterCompany(state) {
    return state.filterCompany;
}

export function getCurrentContextId(state) {
    return state.currentContext;
}

export function forceApproveDialog(state) {
    return state.forceApproveDialog;
}

export function getScrollPosition(state) {
    return state.scrollTop;
}

export function getDisplayedDate(state) {
    return state.displayedDate;
}

export function getTasksWithPayment(state) {
    return state.tasksWithPayment;
}

export function getBatchTasks(state) {
    return state.batchTasks;
}

export function getAutopayURL(state) {
    return state.autopayURL;
}

export function getShowAutopayiFrame(state) {
    return state.showAutopayiFrame;
}

export function getHandledTasksKeys(state) {
    return state?.handledTasksKeys;
}

// ------------------ utility functions call from the context of this file

/**
 * removes selection of all passed tasks
 */
function _clearTaskSelection(tasks) {
    const outTasks = Object.assign({}, tasks);
    Object.keys(outTasks).forEach((key) => {
        outTasks[key] = Object.assign({}, outTasks[key], {selected: false});
    });
    return outTasks;
}

export function _mapToArray(taskMap) {

    // transient state - we used to have array, but logout keeps the old structure in rehydrate (TODO where can it be cleaned?)
    // so in case we got array here, return empty array instead
    if (Array.isArray(taskMap) || taskMap.status === 500) return [];

    //if the back-end fails completly the error state is cached so we need to remove the remembered tasks
    if (taskMap.errorType) return [];

    const outTasks = [];
    Object.keys(taskMap).forEach((key) => {
        outTasks.push(taskMap[key]);
    });
    return outTasks;
}

function _arrayToMap(tasksArray) {
    const outTasks = {};
    tasksArray.forEach((task) => {
        outTasks[task.key] = {
            selected: false,
            task: task
        };
    });
    return outTasks;
}