import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PopUp} from './PopUp.component';
import OrganisationChart from '../organisationChart/OrganisationChart.component';

export default class OrganisationChartPopUp extends PopUp {
    static propTypes = {
        closeCallback: PropTypes.func, //callback for closing the popup
        handleAction: PropTypes.func, //callback for closing the popup
        translate: PropTypes.func, // to translate texts
        hideClose: PropTypes.bool //do not show the close button
    };

    constructor(props, state) {
        super(props, state);
        this.state = {
            selectedNode: null
        };

        this.buildContent = this.buildContent.bind(this);
        this.onChangeSelectedNode = this.onChangeSelectedNode.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getTitle() {
        return this.props.translate('configuration.organisationChart');
    }

    onChangeSelectedNode(node) {
        this.setState({
            selectedNode: node
        });
    }

    onConfirm() {
        this.props.handleAction(this.state.selectedNode);
    }

    buildContent() {
        return (
            <Fragment>
                <div className="panel-body organisation-chart-dialog">
                    <OrganisationChart isCompact={true} onChangeSelectedNode={this.onChangeSelectedNode}/>
                </div>

                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.onConfirm}
                                ref={(element) => {
                                    this.okButton = element;
                                }}
                                tabIndex="2">{this.props.translate("popUp.ok")}</button>
                        {!this.props.hideClose &&
                            <button className="btn btn-default"
                                    onClick={this.props.closeCallback}
                                    tabIndex="3">{this.props.translate("popUp.cancel")}</button>
                        }
                    </div>
                </div>
            </Fragment>

        );
    }
}
