import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../../translations/translations.wrapper.jsx';

export const AttachmentInformation = ({information, translate}) => {
    return (

        <div>
            {(information !== undefined) &&
            <table className="table table-striped col-md-12 no-side-padding">
                <tbody>
                <tr>
                    <td>{translate("attachmentInformation.title")}</td>
                    <td>{information.title}</td>
                </tr>
                <tr>
                    <td>{translate("attachmentInformation.description")}</td>
                    <td>{information.description}</td>
                </tr>
                <tr>
                    <td>{translate("attachmentInformation.uploader")}</td>
                    <td>{information.uploaderName}</td>
                </tr>
                <tr>
                    <td>{translate("attachmentInformation.date")}</td>
                    <td>{information.created}</td>
                </tr>
                </tbody>
            </table>
            }
        </div>

    )
};

AttachmentInformation.propTypes = {
    information: PropTypes.object, // details object
    translate: PropTypes.func, //translation function
};

export default translate(AttachmentInformation);

