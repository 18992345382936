import React, { useRef, useState } from "react";
import { KEYS } from "utils/constants";

export function AddNew(props) {
    const [showNameInput, setShowNameInput] = useState(false);
    const workflowNameValue = useRef(null);

    const onBlur = function() {
        props.onBlur(workflowNameValue.current.value);
        setShowNameInput(false);
    };

    const handleKeyDown = function(event) {
        switch (event.keyCode) {
            case KEYS.ENTER:
            case KEYS.TAB: {
                onBlur();
                return;
            }
            default:
                return event;
        }
    };

    return (
        <div className="workflow-list-wrapper workflow-add-new pl-32 pr-16" key="addRule_9999">
            <div className="company-workflow-list mx-0">
                {showNameInput &&
                    <div className="workflow workflow-new mb-4">
                        <input type="text" autoFocus={true}
                               className={"new-rule-name"}
                               onBlur={onBlur}
                               onFocus={(e) => {
                                   e.target.select();
                               }}
                               onKeyDown={handleKeyDown}
                               defaultValue={props.defaultValue}
                               ref={workflowNameValue} />
                    </div>
                }
                <div className="col-md-12 px-0 my-4">
                    <button type="button"
                            className={"btn btn-primary-tint " + (props.isFirst ? " indent-20" : "indent-98")}
                            disabled={showNameInput}
                            onClick={e => {
                                setShowNameInput(true);
                            }}>
                        <span className="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-add-circle" />
                        {props.addNewText}
                    </button>
                </div>
            </div>
        </div>
    );
}