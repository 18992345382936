import React, {Component} from 'react';
import translate from "../../../translations/translations.wrapper.jsx";
import {formatDateTime} from "../../../../utils/valueFormatter.function";
import commentsDefaultUserImage from '../../../../assets/comments-default-user.png';

import approved from '../../../../assets/24_OK.svg';
import autoApproved from '../../../../assets/24_autoapprove.svg';
import awaitingReview from '../../../../assets/24_awaiting_review.svg';
import canceled from '../../../../assets/24_cancelled1.svg';
import forwarded from '../../../../assets/24_forwarded_task.svg';
import rejected from '../../../../assets/24_rejected_document1.svg';
import reviewed from '../../../../assets/24_document_reviewed.svg';
import reassigned from "../../../../assets/24_reassign.svg";
import systemTask from '../../../../assets/24_system_task.svg';
import accountView from '../../../../assets/accountView.png';
import business from '../../../../assets/business_x64.png';
import businessNXT from '../../../../assets/business_NXT.svg';
import control from '../../../../assets/control_x64.png';
import documentCenter from '../../../../assets/documentCenter_x64.png';
import calendar from '../../../../assets/visma_calendar.svg';

class CommentsList extends Component {

    constructor(props) {
        super(props);
        this.mapComment = this.mapComment.bind(this);
    }

    setFallbackImage(event) {
        event.target.src = 'https://static-odp.visma.net/commoncomponents/vismaSkin/images/services/default/medium.png';
    }

    mapComment(comment, index) {
        const actionDescription = [1, 2, 3, 5, 9, 22, 55];
        const icons = {
            0: systemTask,
            1: approved,
            2: awaitingReview,
            3: rejected,
            4: canceled,
            5: forwarded,
            9: autoApproved,
            22: reviewed,
            55: reassigned
        };

        const profilePictureBaseUrl = typeof window.ENVIRONMENT !== 'undefined' && window.ENVIRONMENT.PROFILE_PICTURE_BASE_URL;
        const profilePicture = comment.connectUserId ? `${profilePictureBaseUrl}${comment.connectUserId}?type=initials` : commentsDefaultUserImage;
        let applicationId = comment.externalApplicationName ? comment.externalApplicationName.toLowerCase().replace(/visma(\.net)? /, '').replace(/ /g, '_') : 'default';

        let applicationPicture;
        switch (applicationId) {
            case 'absence':
                applicationPicture = calendar;
                break;
            case 'documentcenter':
                applicationPicture = documentCenter;
                break;
            case 'business':
                applicationPicture = business;
                break;
            case 'business_cloud':
                applicationPicture = businessNXT;
                break;
            case 'control':
                applicationPicture = control;
                break;
            case 'accountview':
                applicationPicture = accountView;
                break;
            default:
                applicationPicture = `https://static-odp.visma.net/commoncomponents/vismaSkin/images/services/${applicationId}/medium.png`;
        }

        const picture = comment.externalApplicationName ? applicationPicture : profilePicture;
        const srcIcon = icons[comment.eventType];

        return (
            <div className="comments-list-item" key={`${comment.nodeId}-${index}`}>
                <div className="comments-list-item-image">
                    <img src={picture}
                         width="50"
                         height="50"
                         alt={this.props.translate("taskDetail.profilePicture", comment.userName)}
                         onError={(event) => {
                             this.setFallbackImage(event, comment)
                         }}/>
                    {comment.eventType === 7 ? null :
                        <img className="comments-list-item-icon" src={srcIcon} width="24" height="24"
                             alt="icon"/>}
                </div>
                <div className="comments-list-item-body">
                    <div className="comments-list-item-heading">
                            <span className="comments-list-item-user">
                                {comment.userName}
                            </span>
                        <span className="comments-list-item-date">
                                {formatDateTime(comment.createdDate)}
                            </span>
                    </div>
                    {actionDescription.includes(comment.eventType) &&
                        <div className="comments-list-action">
                            {this.props.translate(`taskDetail.commentsAction.${comment.eventType}`)}
                        </div>}
                    <div className="comments-list-item-comment">
                        {comment.content}
                    </div>
                </div>
            </div>
        );

    }

    render() {
        const commentsList = [
            ...this.props.comments,
            // eslint-disable-next-line array-callback-return
        ].map((comment, index) => {
            if (!comment.current)
              return this.mapComment(comment, index);
        });

        return (
            <div className="comments-list">
                {commentsList}
            </div>
        );
    }
}

const withTranslations = translate(CommentsList);
export default withTranslations;
