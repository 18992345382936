import React from 'react';
import * as reduxSelectors from '../store/application.reducers';
import UserSearch from '../input/UserSearch.component';
import {connect} from 'react-redux';
import translate from '../translations/translations.wrapper.jsx';
import ConfigurationWrapper from '../configuration/ConfigurationWrapper.component';
import {getDepartments, filterChanged, saveDepartmentChanges} from "./departments.action";
import * as _ from "lodash";

import SearchGroupInput from "../input/SearchGroupInput.component";
import "./departments.scss";


export class Departments extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            assignedManagers: [],
            initialSettings: [],
            departments: [],
            possibleManagers: [],
            okEnabled: false,
            showDeletedDepartments: false,
            mapped: []

        };

        this.getData = this.getData.bind(this);
        this.toggleDeletedDepartments = this.toggleDeletedDepartments.bind(this);
        this.filterDepartments = this.filterDepartments.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(props) {
        super.componentDidMount(props);
    }

    getData() {
        this.props.getDepartments();

    }

    save(id, values) {
        let managersIds = (Array.isArray(values) ? values : [values])
            .filter((v) => v.value)
            .map(manager => {
                return manager.value
            });

        let data = {
            departmentId: id,
            departmentManagerIds: managersIds
        };

        this.props.saveDepartmentChanges(data);
    }

    filterDepartments(event) {
        this.props.filterChanged(event.target.value);
    }

    removeFilter() {
        this.props.filterChanged("");
    }

    toggleDeletedDepartments() {
        let newValue = _.clone(this.state.showDeletedDepartments);
        this.setState({
            showDeletedDepartments: !newValue
        });
    }


    buildContent() {
        let departments = [];

        this.props.departments.forEach((department, index) => {
                if (department.name && (!department.mdmDeleted || this.state.showDeletedDepartments))
                    departments.push(<div key={department.internalOrgUnitId + "-" + department.organizationUnitId} className="row">
                        <div className={"col-md-4 " + (department.mdmDeleted ? " text-danger" : "")}>
                            {department.name} {"(" + department.organizationUnitId + ")"} {department.mdmDeleted && this.props.translate("departments.deleted")}
                        </div>
                        <div className="col-md-8">
                            <ManagersView managers={department.managers}
                                          orgUnitId={department.internalOrgUnitId}
                                          canEdit={!department.mdmDeleted}
                                          translate={this.props.translate}
                                          disabled={!this.props.userRoles.systemAdministrator}
                                          valueChanged={this.save}
                                          options={this.props.possibleManagers}/>
                        </div>
                    </div>);
            }
        );

        let noData = this.props.departments.length === 0;

        return (
            <div id="departments">
                <h3 className="no-border d-flex align-items-center pt-4">
                    {this.props.translate("departments.header")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                          title={this.props.translate("departments.headerInfo")}/>
                </h3>
                <div className="row align-items-center mx-0">
                    <div className="col-md-4">
                        <SearchGroupInput onInputChange={this.filterDepartments}
                                          value={this.props.filter}
                                          onClearInput={this.removeFilter}
                                          translate={this.props.translate}/>
                    </div>
                    <div className="col-md-3">
                        <div className="checkbox">
                            <input type="checkbox"
                                   id="showDeletedDepartments"
                                   onChange={this.toggleDeletedDepartments}
                                   checked={this.state.showDeletedDepartments}/>
                            <label className="form-check-label" htmlFor="showDeletedDepartments">
                                {this.props.translate("departments.showDeleted")}
                            </label>
                        </div>
                    </div>
                </div>

                {noData ?
                    <span>{this.props.translate("generic.noData")}</span> :
                    <div className="row actions-list mx-0">
                        <div className="col-md-9">
                            <HeaderRow translate={this.props.translate}/>
                            <div className="main-panel">
                                {departments}
                            </div>
                        </div>
                    </div>
                }
            </div>

        )
    }
}

const withTranslations = translate(Departments);
const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        departments: reduxSelectors.getDepartments(store),
        filter: reduxSelectors.getDepartmentsFilter(store),
        possibleManagers: reduxSelectors.getPossibleDepartmentManagers(store)
    };
};

const connected = connect(mapStateToProps, {getDepartments, filterChanged, saveDepartmentChanges})(withTranslations);
export default connected;

const HeaderRow = (props) => {
    return (
        <div className="row header-row mt-4">
            <div className="col-md-4">
                {props.translate("departments.departments")}
            </div>
            <div className="col-md-8">
                {props.translate("departments.departmentApprovers")}
            </div>
        </div>
    )
};

const ManagersView = (props) => {
    let valueChanged = function (value) {
        props.valueChanged(props.orgUnitId, value);
    };
    return (
        <span>
            {props.canEdit &&
                <UserSearch propagateValue={valueChanged}
                            value={props.managers}
                            options={props.options}
                            stopFocus={true}
                            disabled={props.disabled}
                            multi={true}
                            menuPosition={"fixed"}
                            translate={props.translate}/>
            }

        </span>
    )
};