import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as spinner from '../../../pagelayout/icons/spinner_blue.svg';
import {IMG_BASE} from "../../../../utils/constants";
import {APPROVAL} from "../../../../utils/api/api.js";
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import List from 'react-virtualized/dist/es/List';
/*
 * Using React-Virtualized to load only 5 images at a time, according to scroll position
 * For more information : https://github.com/bvaughn/react-virtualized
 * Live demo: https://bvaughn.github.io/react-virtualized/#/components/List
 * Props:
 *  list: array containing the attachments or thumbanils
 *  scroll: scroll handler, optional
 *  rowHeight: number, must be set, value of the row height
 * */

let searchTimeout;

const MAX_TIMES_TO_RELOAD = 30;
const LOADING_TIME_WARNING = 15;
const RELOAD_IMAGE_CLASS = " spinner spinner-default-blue page-reloading-image";

let reloadTimes = 0;
const Overscan_Row_Count = 4;

export class AttachmentsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageLoading: false,
            errorLoading: false,
            showScrollingPlaceholder: false,
            showDirectDownloadMessage: false
        };
        this.rowRenderer = this.rowRenderer.bind(this);
        this.scroll = this.scroll.bind(this);
        this.scrollToPage = this.scrollToPage.bind(this);
        this.loadFail = this.loadFail.bind(this);
        this.reloadImage = this.reloadImage.bind(this);
        this.tryReload = this.tryReload.bind(this);
        this.closeWarning = this.closeWarning.bind(this);
        this.previewPanel = React.createRef();
    }


    componentDidMount() {
        reloadTimes = 0;
    }


    loadFail(event) {
        if (MAX_TIMES_TO_RELOAD >= reloadTimes) {
            reloadTimes += 1;
            searchTimeout = 5000;
            //reload image
            let image = event.target;
            let source = event.target.src;

            image.src = spinner.default;
            if (image.className.indexOf(RELOAD_IMAGE_CLASS) === -1)
                image.className = RELOAD_IMAGE_CLASS;

            setTimeout(this.reloadImage, searchTimeout, image, source);

            if (reloadTimes === LOADING_TIME_WARNING) {
                this.setState({
                    showDirectDownloadMessage: true
                });

            }
        } else {
            this.setState({
                errorLoading: true
            });
        }
    }

    reloadImage(image, source) {
        searchTimeout = undefined;
        image.src = "";
        image.src = source;
        image.className = image.className.replace(RELOAD_IMAGE_CLASS, "");
        image.setAttribute("style", "");
    }

    tryReload(event) {
        reloadTimes = 0;
        this.setState({
            errorLoading: false,
            showDirectDownloadMessage: false
        });
    }

    closeWarning() {
        this.setState({
            showDirectDownloadMessage: false
        });
    }

    getAttachmentUrl(attachment) {
        return APPROVAL + attachment.urlOriginal.replace("/approval/jaxrs/", '')
    }

    rowRenderer({key, index, style}) {
        let attachment = this.props.list[index];

        const imgMaxWidth = (this.props.zoom * IMG_BASE.width) / 100;
        const imgHeight = (this.props.zoom * IMG_BASE.height) / 100;

        // eslint-disable-next-line default-case
        switch (attachment.status) {
            case "OK": {
                // password protected document
                if (attachment.pageCount <= 0) {
                    const attachmentUrl = this.getAttachmentUrl(attachment);
                    return (
                        <div className={"page_" + attachment.pageId} style={style} key={key}>
                            <div className="attachment-image-container">
                                <div style={{width: attachment.width + "px", height: imgHeight}}
                                     className="encrypted-document-page">
                                    <a href={attachmentUrl}
                                       target="_blank"
                                       className="height-100 col-md-12"
                                       rel="noreferrer"
                                    >
                                        {attachment.pageCount === -1 &&
                                            <span className="spinner spinner-default-blue"/>}
                                        {attachment.pageCount === 0 &&
                                            <span className="vismaicon vismaicon-encrypted"/>}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    let src = attachment.src;

                    //because we cannot know when an image is rotated we cannot set the width and height of it based on our constants
                    //best to let the image adust itself into the container
                    //this container needs to compute its width based on the zoom level


                    return (
                        <div className={"page-container page_" + attachment.pageId} style={style} key={key}>
                            <div className={"attachment-image-container"}
                                 style={{width: imgMaxWidth, height: imgHeight}}
                                 onClick={this.scrollToPage.bind(this, index)}>
                                <img
                                    style={{maxWidth: imgMaxWidth, height: imgHeight}}
                                    alt={"attachment"}
                                    onError={this.loadFail}
                                    src={src}
                                    role="presentation"/>
                            </div>
                        </div>
                    )
                }

            }

            case "ORIGINAL": {
                if (attachment.pageCount === 0) {
                    const attachmentUrl = this.getAttachmentUrl(attachment);
                    return (
                        <div className={"page_container page_" + attachment.pageId} style={style} key={key}>
                            <div className="attachment-image-container">
                                <div style={{width: "80%", height: imgHeight}}
                                     className=" encrypted-document-page">
                                    <a href={attachmentUrl}
                                       target="_blank"
                                       className="height-100 col-md-12"
                                       rel="noreferrer"
                                    >
                                        {attachment.pageCount === -1 &&
                                            <span className="spinner spinner-default-blue loading"/>}
                                        {attachment.pageCount === 0 &&
                                            <span className="vismaicon vismaicon-encrypted"/>}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className={"page_container page_" + attachment.pageId} style={style} key={key}>
                        <div className="attachment-image-container">
                            <div style={{width: "80%", height: imgHeight}}
                                 className=" encrypted-document-page">
                                <a href={this.props.downloadZIP} download
                                   className="height-100 col-md-12 align-vertical-center">
                                    <h2 className="no-border">
                                        {this.props.translate("taskDetail.originalDocMessage")}
                                    </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            }

            case "UNAVAILABLE": {
                return (
                    <div className={"page_container page_" + attachment.pageId} style={style} key={key}>
                        <div className="attachment-image-container">
                            <div style={{width: "80%", height: imgHeight}}
                                 className=" encrypted-document-page">
                                <div className="height-100 col-md-12 align-vertical-center">
                                    <h2 className="no-border">
                                        {this.props.translate("taskDetail.unavailableDocMessage")}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            case "IN_PROGRESS": {
                return (<div className={"page_" + attachment.pageId} style={style} key={key}>
                        <div className="encrypted-document-page no-image-page-image" style={{"width": "700px"}}>
                            <span className="spinner spinner-default-blue loading"/>
                        </div>
                    </div>
                )

            }
        }

    }

    scrollToPage(index) {
        if (this.props.scrollTop)
            this.props.scrollTop(index);
    }

    scroll(event) {
        if (typeof this.props.scroll !== "undefined")
            this.props.scroll(event);
    }

    render() {

        return (
            <div className={"col-md-12 px-0 height-100"} ref={this.previewPanel}>
                {this.props.list.length > 0 && !this.state.errorLoading &&
                    <AutoSizer>
                        {({width, height}) => (
                            <List
                                ref='List'
                                className="List"
                                height={height}
                                overscanRowCount={Overscan_Row_Count}
                                rowCount={this.props.list.length}
                                rowHeight={this.props.rowHeight + 80}
                                rowRenderer={this.rowRenderer}
                                id={this.props.listId}
                                width={width}
                                scrollToAlignment="start"
                                onScroll={this.scroll}
                                scrollToIndex={this.props.scrollToIndex}
                            />
                        )}
                    </AutoSizer>
                }

                {(this.state.showDirectDownloadMessage || this.state.errorLoading) &&
                    <div className="loading-time-warning margin-top-bottom">
                        <div className="alert alert-warning alert-options" role="alert">
                            <h4>{this.props.translate("taskDetail.couldNotLoad")}</h4>
                            <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-warning"
                                  aria-hidden="true"/>

                            <p>
                                <span>{this.props.translate("taskDetail.attachmentsServices")}</span><br/>
                                <a href="https://status.visma.com/" target="blank">
                                    {this.props.translate("taskDetail.tooLongLoading")}
                                </a>
                            </p>

                            <p>
                                {this.state.errorLoading &&
                                    <button type="button" className="btn btn-primary btn-margin-right"
                                            onClick={this.tryReload}>
                                        {this.props.translate("taskDetail.clickToReload")}
                                    </button>
                                }
                                <a href={this.props.downloadLink}>
                                    <button type="button" className="btn btn-default btn-margin-right">
                                        {this.props.translate("taskDetail.clickToDownload")}
                                    </button>
                                </a>
                                <button type="button" className="btn btn-default" onClick={this.closeWarning}>
                                    {this.props.translate("popUp.close")}
                                </button>
                            </p>
                        </div>
                    </div>
                }
            </div>
        )
    }
}


AttachmentsList.propTypes = {
    list: PropTypes.array, // array containing the attachments or thumbanils
    scroll: PropTypes.func, // scroll handler, optional
    rowHeight: PropTypes.number, //integer, determines the height of the displayed row element, virtual scrool will not work without it
    listId: PropTypes.string, // needed to make sure the list will re-render after zoom and rotate controls are pushed
    translate: PropTypes.func
};

export default AttachmentsList;