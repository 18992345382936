import { LOADING_STATUS } from "utils/constants";
import translate from "components/translations/translations.wrapper";
import { TableFooterProps } from "types/tableFooter";

export function TableFooter(props: TableFooterProps) {

    const getPrimaryFooterText = function() {
        return props.showRowData &&
            <span className="text-default">
                        {props.translate("grid.footer.showing")}
                        <b> {" " + props.records + " "} </b>
                        {props.records > 1 ?
                            props.translate("grid.footer.rows") :
                            props.translate("grid.footer.row")}
              </span>;
    };
    const getSecondaryFooterInfo = function() {
        if (props.loadingStatus === LOADING_STATUS.LOADING && props.loadingError === null) {
            return <span className="text-secondary font-13">{props.translate("grid.footer.loading")}</span>;
        }
        if (props.loadingStatus === LOADING_STATUS.DONE && props.asyncLoadingState == null) {
            return;
        }
        if (props.loadingStatus === LOADING_STATUS.DONE && props.asyncLoadingState?.cursor == null) {
            return (
                <span className="text-secondary font-13">
                        <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-success mr-4 align-bottom" />
                    {props.translate("grid.footer.allRowsLoaded")}
                </span>);
        }
        if (props.loadingStatus === LOADING_STATUS.DONE && props.asyncLoadingState?.cursor != null) {
            return <span className="text-secondary font-13">{props.translate("grid.footer.scrollToLoad")}</span>;
        }
        if (props.loadingError != null) {
            return (
                <div className="d-flex">
                    <span
                        className={`vismaicon vismaicon-sm vismaicon-filled vismaicon-${props.loadingError === "ABORTED" ? "warning" : "error"} mr-4 align-bottom`} />
                    <div className="d-flex flex-column">
                        <span
                            className="text-secondary font-13">{props.translate("grid.footer." + props.loadingError?.toLowerCase())}</span>
                        {props.loadingError === "EXCEPTION" && <span
                            className="text-secondary font-12">{props.translate("grid.footer.exceptionDetails")}</span>}
                    </div>
                </div>);
        }
    };


    return (
        <div className="col-md-12 table-footer d-flex">
            <div className="d-flex flex-column align-self-center">
                {getPrimaryFooterText()}
                {getSecondaryFooterInfo()}
            </div>
            {props.loadingError &&
                <button key={"table_retry"} type="button"
                        className={"btn btn-default align-bottom my-2 mx-40"}
                        onClick={props.onReload}>
                    {props.loadingError === "ABORTED" ?
                        props.translate("grid.footer.reload") :
                        props.translate("grid.footer.tryAgain")
                    }
                </button>
            }
        </div>

    );
}

const withTranslations = translate(TableFooter);
export default withTranslations;