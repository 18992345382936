import Flatpickr from "react-flatpickr";
import React from "react";

class FlatpickrWithBlur extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.reference = null;
    }

    render() {
        return React.createElement(Flatpickr, {
            ...this.props,
            ref: this.setFlatpickrRef.bind(this)
        });
    }

    setFlatpickrRef(ref) {
        if (ref && ref.node) {
            this.reference = ref;
        }
    }

    componentDidMount() {
        this.reference.node.addEventListener("blur", this.handleBlur.bind(this));
    }

    componentWillUnmount() {
        this.reference.node.removeEventListener("blur", this.handleBlur.bind(this));
    }

    handleBlur(event) {
        if (!this.props.handleBlur || this.reference.flatpickr?.innerContainer.parentNode.contains(event.relatedTarget)) {
            return;
        }
        this.props.handleBlur(event.target.value);
    }
}

export default FlatpickrWithBlur;