import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import * as Api from 'utils/api/api.js';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import Comment from '../input/Comment.component';
import UserSearch from '../input/UserSearch.component'
import {TASK_ACTIONS} from 'utils/constants';
import ErrorDialog from './ErrorDialog.component';
import {getTaskUserDescription} from 'utils/valueFormatter.function';
import translate from '../translations/translations.wrapper.jsx';

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

export class SendForward extends PopUp {
    constructor(props) {
        super(props);
        this.recipientInput=React.createRef();
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);
        this.focusFirst = this.focusFirst.bind(this);
        this.state = {
            comment: '',
            recipient: null,
            actionRunning: false,
            displayError: null,
            refresh: null
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    focusFirst() {
        this.setState({
            refresh: true
        });
    }

    ok() {
        const self = this;
        if (this.state.comment && this.state.recipient && this.state.recipient.value) {
            this.setState({actionRunning: true});

            const key = this.props.tasks[0].key;
            Api.forwardTask(key, this.state.comment, this.state.recipient.value).then(function (response) {
                    let description = getTaskUserDescription(self.props.tasks[0]);
                    const withPlaceholder = self.props.translate("popUp.forward.toastMessage", description, self.state.recipient.label);
                    self.props.showNotification(withPlaceholder);
                    self.props.handledTaskCallback(key, self.props.navigate);
                    self.props.closeCallback();
                },
                function (error) {
                    self.setState({displayError: error});
                });
        }
    }

    buildContent() {

        const okEnabled = this.state.comment && this.state.recipient && !this.state.actionRunning;
        return (
            <Fragment>
                {this.state.displayError && <ErrorDialog
                    error={this.state.displayError}
                    closeCallback={() => this.setState({actionRunning: false, displayError: null})}
                    translate={this.props.translate}
                />}
                <article className="modal-body ast_send_forward">
                    <div><span className="text-danger">*</span> {this.props.translate("popUp.forward.recipient")}</div>
                    <UserSearch
                        translate={this.props.translate}
                        tabindex={TAB_INDEX}
                        refresh={this.state.refresh}
                        taskId={this.props.tasks[0].key}
                        ariaLabel={this.props.translate("popUp.forward.recipient")}
                        placeholder={this.props.translate("userSearch.placeholder")}
                        propagateValue={value => this.setState({recipient: value,refresh:false})}
                        value={this.state.recipient}
                        releaseFocus={()=>{this.setState({refresh:false})}}
                    />
                    <div className="margin-top">
                        <span className="text-danger">*</span>
                        <label htmlFor="comment">{this.props.translate("popUp.forward.commentLabel")}
                        </label>

                        <VisibleToEverybody translate={this.props.translate}/>

                        <Comment id="comment"
                                 inputHint={this.props.translate("popUp.forward.commentHint")}
                                 translate={this.props.translate}
                                 tabIndex={TAB_INDEX+1}
                                 propagateValue={value => this.setState({comment: value,refresh:false})}
                                 value={this.state.comment}
                        />
                    </div>
                </article>
                <div className="modal-footer">
                    {this.props.isCurrentTaskHandled ?
                        <div className="float-left">
                            <button
                                className="btn btn-primary btn-margin-right "
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 6}>{this.props.translate("popUp.reload")}
                            </button>
                        </div> :
                        <div className="float-right">
                            <button className="btn btn-primary btn-margin-right"
                                    onClick={this.ok}
                                    tabIndex={TAB_INDEX + 2}
                                    disabled={!okEnabled}
                            >{this.props.translate("popUp.ok")}</button>
                            <button className="btn btn-default"
                                    onClick={this.props.closeCallback}
                                    tabIndex={TAB_INDEX + 3}
                                    onBlur={this.focusFirst}
                            >{this.props.translate("popUp.cancel")}</button>
                        </div>
                    }
                </div>
            </Fragment>

        );
    }

    getType() {
        return TASK_ACTIONS.FORWARD;
    }

}

SendForward.propTypes = {
    tasks: PropTypes.array, // selected tasks
    closeCallback: PropTypes.func, //callback for closing the popup
    translate: PropTypes.func, // to translate texts
    showNotification: PropTypes.func, // to show transient notification

};
const withTranslations = translate(SendForward);
export default withTranslations;

