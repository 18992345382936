import {
    useLocation,
    useNavigate,
    useParams,
    useBlocker
} from "react-router-dom";
import {useState, useCallback} from "react";

const ROUTER_BLOCKED="blocked";


export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let params = useParams();
        let history = useNavigate();

        return (
            <Component
                {...props}
                location={location}
                history={history}
                match={{location, params}}
            />
        );
    }

    return ComponentWithRouterProp;
}


export function withRouteBlocker(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let params = useParams();
        let history = useNavigate();

        const [allowNavigation, setBlockNavigation] = useState<boolean>(false);
        const [waitForConfirmation, setWaitForConfirmation] = useState<boolean>(false);

        let _shouldBlock = useCallback(({
                                            currentLocation,
                                            nextLocation
                                        }) => {
            if (waitForConfirmation) {
                // setWaitForConfirmation(true);
                // setBlockNavigation(true);
                return true;
            }

            return allowNavigation && currentLocation.pathname !== nextLocation.pathname
        }, [allowNavigation, waitForConfirmation]);


        let blocker = useBlocker(_shouldBlock);

        const shouldBlockNavigation = (shouldBlock) => {
            setBlockNavigation(shouldBlock);
            setWaitForConfirmation(shouldBlock);
        }
        const shouldWaitForConfirmation = (shouldWait) => {
            setWaitForConfirmation(shouldWait);
        }

        const unblockNavigation = () => {
            setBlockNavigation(false);
            setWaitForConfirmation(false);
        }

        const isNavigationBlocked = () => {
            return blocker.state === ROUTER_BLOCKED;
        }

        return (
            <Component
                {...props}
                location={location}
                history={history}
                blocker={{
                    blocker,
                    history,
                    shouldBlockNavigation,
                    shouldWaitForConfirmation,
                    isNavigationBlocked,
                    unblockNavigation
                }}
                match={{location, params}}
            />
        );
    }

    return ComponentWithRouterProp;
}