import {
    TASK_ACTION_ASYNC, TASK_ACTION_FINISHED,
    UPDATE_ONE_HANDLE_ONLY_FLAG,
    ADD_TO_ACTION_QUEUE,
    REMOVE_ACTION_FROM_QUEUE,
    LOOP_TASKS_FLAG,
    APPROVE_ACTION_RUNNING,
    APPROVE_ACTION_STOPED
} from '../../store/actionTypes';

import {navigateToTaskDetail} from '../../router/router.function.js';
import {approveMultipleShortcutKey} from '../myTasks.action';
import {TASK_ACTIONS} from 'utils/constants';
import * as logger from 'utils/logger.function';
import {getUsersShowCommentOnApprove, getTasksForRender, forceApproveDialog} from '../../store/application.reducers';
import {approveMultipleTasks} from './approveMultipleTasks.action';
import {PATH_TO_TASK_DETAILS} from "components/router/paths.js";

/**
 * redux actions dispatched to execute some task action
 */
export function taskAction(type, taskIds = [], options = {}, navigate = () => {
}) {

    return function (dispatch, getState) {

        const state = getState();
        const showCommentOnApprove = getUsersShowCommentOnApprove(state);

        if (type === TASK_ACTIONS.APPROVE && (!showCommentOnApprove && taskIds.length === 1) && !forceApproveDialog(state)) {
            const {comment, documentVersion, skipRestart, isFromBatch} = options;
            return dispatch(approveMultipleTasks(taskIds, comment, documentVersion, skipRestart, isFromBatch, navigate));
        }

        if (type === TASK_ACTIONS.OPEN) {
            const tasks = getTasksForRender(state); // beware, here it has immutable data

            dispatch(changeOneHandleOnlyFlag(false));

            let wantedId;
            // if none is selected, selected all
            if (taskIds.length === 0) {
                // dispatch(changeAllTaskSelection(true));
                dispatch(changeOneHandleOnlyFlag(true));
                wantedId = tasks.filteredKeys[0];
            } else {
                wantedId = taskIds[0];
            }

            // and navigate to the first task detail
            logger.debug("navigating to task with key:", wantedId);
            navigate(PATH_TO_TASK_DETAILS + wantedId);
            return;
        }
        //default to false after the action has been done
        if (forceApproveDialog(state)) {
            dispatch(approveMultipleShortcutKey(false))
        }

        // every other task action needs dialog
        dispatch(taskActionAsync(type, taskIds));
    }
}


/**
 * sets the flag denoting if the user is in the iteration (eg he did 'handle all' action)
 * while in iteration we return user back to myTasks when the last task is handled .. otherwise we return upon first handle
 * @param oneHandleOnly
 */
export function changeOneHandleOnlyFlag(oneHandleOnly = false) {
    return {
        type: UPDATE_ONE_HANDLE_ONLY_FLAG,
        data: oneHandleOnly
    }
}

export function changeTryToLoopTasksFlag(loopTasks = false) {
    return {
        type: LOOP_TASKS_FLAG,
        data: loopTasks
    }
}

export function taskActionAsync(type, taskIds = []) {
    return {
        type: TASK_ACTION_ASYNC,
        actionType: type,
        taskIds: taskIds
    }
}

/**
 * async action finished, removed from the state all data for action
 * @return {{type}}
 */
export function actionFinished() {
    return {
        type: TASK_ACTION_FINISHED
    };
}

export function addActionToQueue(type, data) {
    return {
        type: ADD_TO_ACTION_QUEUE,
        data: {
            type: type,
            params: data
        }
    };
}

export function removeActionFromQueue(type) {
    return {
        type: REMOVE_ACTION_FROM_QUEUE,
        data: type
    };
}

export function approveActionRunning() {
    return {
        type: APPROVE_ACTION_RUNNING,
        data: TASK_ACTIONS.APPROVE
    };
}

export function approveActionStop() {
    return {
        type: APPROVE_ACTION_STOPED
    };
}

