import {SAVE_SPLITBUTTON_PREFERENCE, LOGGED_OUT_TYPE, DIFFERENT_USER} from '../store/actionTypes';

export const DEFAULT_STATE = {};


/**
 * store.splitButton: { [key] : bool }
 */
export default function splitButtonReducer(state = DEFAULT_STATE, action) {
    switch (action.type) {
        case SAVE_SPLITBUTTON_PREFERENCE:
            let partialState = {};
            partialState[action.buttonId] = action.data;
            return Object.assign({}, state, partialState);
        case LOGGED_OUT_TYPE:
        case DIFFERENT_USER:
            return {};
        default:
            return state;
    }
}


export function getSplitButtonPreference(state) {
    return state;
}

export function getConcreteSplitButtonPreference(state, splitButtonId) {
    return state ? state[splitButtonId] : '';
}