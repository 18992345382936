import React from "react";
import {LOADING_STATUS} from "utils/constants";
import _ from "lodash";
import ImageUnavailable from "./Image.unavailable";
import ImageLoading from "./Image.loading";


const MAX_RELOAD_ATTEMPTS: number = 3;

export const ImageDefault = ({id, key, zoom, style,src, rotation}: any): React.ReactElement => {

    //const imgMaxWidth = zoom;//(zoom * IMG_BASE.width) / 100;
    //const imgHeight = (zoom * IMG_BASE.height) / 100;

    const [loadingStatus, setLoadingStatus] = React.useState<number | null>(null);
    const imageRef = React.useRef<HTMLImageElement>(null);
    let reloadAttempts: number = 0;


    function reloadImage() {
        reloadAttempts += 1;
        imageRef.current?.setAttribute("src", src);
    }

    const debounceImageLoading = _.debounce(reloadImage, 1000);

    const handleLoadError = () => {
        if (reloadAttempts >= MAX_RELOAD_ATTEMPTS) {
            setLoadingStatus(LOADING_STATUS.ERROR);
            return;
        }
        setLoadingStatus(LOADING_STATUS.LOADING);
        debounceImageLoading();
    }

    const renderImage = () => {

        if(loadingStatus === LOADING_STATUS.ERROR)
            return <ImageUnavailable/>;

        if (!src || src === "" || loadingStatus === LOADING_STATUS.LOADING)
            return <ImageLoading/>;

        if (src && src !== "") {
            return <img
                ref={imageRef}
                className={`${doneLoading ? "attachment-image" : "hidden"}  ${rotation ? "rotate_" + rotation : ""}`}
                style={{width: zoom + "%"}}
                alt={`attachment_${id}`}
                onError={handleLoadError}
                src={src}
                role="presentation"/>
        }
    }


    const doneLoading = loadingStatus !== LOADING_STATUS.ERROR && loadingStatus !== LOADING_STATUS.LOADING;

    return (
        <div className={`images-auto-sizer__page-container page_${id}`} style={style} key={key}>
            <div className={"attachment-image-container"}>
                {renderImage()}
            </div>
        </div>
    )
}
export default ImageDefault;