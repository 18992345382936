import {
    PROJECTS_USERS_LOADED,
    PROJECTS_ROLES_LOADED,
    PROJECTS_USERS_ROLES_LOADING
} from '../store/actionTypes';
import * as _ from 'lodash';


export const DEFAULT_STATE = {
    users: null,
    roles: null
};

export default function projectsReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case PROJECTS_USERS_ROLES_LOADING:
            partialState = {
                loading: true
            };

            return Object.assign({}, state, partialState);

        case PROJECTS_USERS_LOADED:
            partialState = Object.assign({}, state);

            partialState.users = (partialState.users || [])
                .concat(action.data || [])
                .filter((user, index, arr) =>
                    arr.findIndex((u) => (u.value === user.value)) === index
                );


            return Object.assign({}, state, partialState);


        case PROJECTS_ROLES_LOADED:
            partialState = Object.assign({}, state);
            let currentRoles = action.data;
            partialState.roles = _.unionWith(currentRoles, _.isEqual);

            return Object.assign({}, state, partialState);


        default:
            return state;
    }
}


export function getProjectsUsers(state) {
    return state.users;
}

export function getProjectsRoles(state) {
    return state.roles;
}



