import React from 'react';

export const EditDeleteButtons = (props) => {

    return (
        <div>
            <span className={"vismaicon vismaicon-edit vismaicon-dynamic me-4" + (props.disabled ? " disabled" : "") + (props.small ? " vismaicon-sm" : "")}
                  title={props.translate("workflows.edit")}
                  onClick={props.editCallback}/>
            <span className={"vismaicon vismaicon-delete vismaicon-dynamic" + (props.disabled ? " disabled" : "") + (props.small ? " vismaicon-sm" : "")}
                  title={props.translate("workflows.delete")}
                  onClick={props.deleteCallback}/>
        </div>
    )
};

export const SaveCancelButtons = (props) => {

    return (
        <div>
            <span
                title={props.saveTooltop ? props.saveTooltop : props.translate("workflowDetails.save")}
                className={"vismaicon vismaicon-save vismaicon-dynamic me-4" + (props.saveDisabled ? " disabled" : "")}
                onClick={props.saveCallback}
            />
            <span className="vismaicon vismaicon-cancel-circle vismaicon-dynamic"
                  title={props.translate("roles.cancel")}
                  onClick={props.cancelCallback}/>
        </div>
    )
};