import {
    ORG_CHART_TREE_LOADING,
    ORG_CHART_TREE_LOADED,
    ORG_CHART_USERS_LOADED,
    ORG_CHART_ROLES_LOADED,
    ORG_CHART_MORE_USERS_LOADED,
    ORG_CHART_CHILDREN_LOADED,
    ORG_CHART_CHANGE_SELECTED_NODE,
    ORG_CHART_CHANGE_SELECTED_DATE,
    ORG_CHART_FILTERS_CHANGED,
    ORG_CHART_SORT_COLUMN_CHANGED
} from '../store/actionTypes';

import * as Api from '../../utils/api/api';
import {handleError} from '../../utils/errorHandle.function';
import {prepareFilters} from "../../utils/tableFilters.function";
import moment from "moment";

let CALL_DATA = {
    role_name: "all",
    _search: true,
    nd: new Date().getTime(),
    rows: 20,
    page: 1,
    sidx: "holder",
    sord: 'asc'
};

export function organisationChartUsersLoading() {
    return {
        type: ORG_CHART_TREE_LOADING
    }
}

export function organisationChartTreeLoaded(value) {
    return {
        type: ORG_CHART_TREE_LOADED,
        data: value
    }
}

export function organisationChartChildrenLoaded(nodeId, value) {
    return {
        type: ORG_CHART_CHILDREN_LOADED,
        data: value,
        nodeId: nodeId
    }
}

export function organisationChartUsersLoaded(value) {
    return {
        type: ORG_CHART_USERS_LOADED,
        data: value
    }
}

export function organisationChartRolesLoaded(value) {
    return {
        type: ORG_CHART_ROLES_LOADED,
        data: value
    }
}

export function changeSelectedNode(value) {
    return {
        type: ORG_CHART_CHANGE_SELECTED_NODE,
        data: value
    }
}

export function changeSelectedDateFilter(value) {
    return {
        type: ORG_CHART_CHANGE_SELECTED_DATE,
        data: value
    }
}

export function orgChartFilterChanged(filter) {
    return {
        type: ORG_CHART_FILTERS_CHANGED,
        data: filter
    };
}

export function changeSelectedDate(value, filters) {
    return function (dispatch) {
        dispatch(changeSelectedDateFilter(value));
        dispatch(getOrgChartTreeNodes(value));
    }
}

export function sortingColumnChanged(sortColumn) {
    return {
        type: ORG_CHART_SORT_COLUMN_CHANGED,
        sortColumn: sortColumn.sort,
        sortLogic: sortColumn.sortBy
    };
}

export function organisationChartMoreUsersLoaded(value) {
    return {
        type: ORG_CHART_MORE_USERS_LOADED,
        data: value
    }
}

export function getOrgChartTreeNodes(date, nodeId) {
    return function (dispatch) {
        let data = {
            nd: new Date().getTime(),
            rows: 10000,
            page: 1
        };
        if (nodeId) {
            data.nodeid = nodeId;
        }

        let formatedDate = moment(date).toISOString().substring(0, 10);

        Api.getOrganizationChartForDate(formatedDate, data).then(response => {
            if (nodeId)
                dispatch(organisationChartChildrenLoaded(nodeId, response));
            else
                dispatch(organisationChartTreeLoaded(response));

            dispatch(getOrganizationChartRoles(nodeId));
        }, error => {
            handleError(error);
        });
    }
}


export function getOrganizationChartUsers(date, value, filters) {
    return function (dispatch) {
        dispatch(organisationChartUsersLoading());

        let callData = Object.assign({}, CALL_DATA);
        if (filters) {
            if (filters.columns) {
                callData.roleName = filters.columns.roleName ? filters.columns.roleName : '';
                callData.holder = filters.columns.holder ? filters.columns.holder : '';
            }
            callData.sidx = filters.sort ? filters.sort.index : '';
            callData.sord = filters.sort ? filters.sort.direction : '';
        }
        let formatedDate = new Date(date).setHours(12);
        formatedDate = new Date(formatedDate).toISOString().substring(0, 10);
        Api.getOrganizationChartUsers(formatedDate, value.id, value.level, callData).then(response => {
            dispatch(organisationChartUsersLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}


export function getOrganizationChartRoles(value) {
    return function (dispatch) {
        Api.getOrganizationChartRoles(value).then(response => {
            dispatch(organisationChartRolesLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}

export function loadMoreUsers(callObject, date, value) {
    return function (dispatch) {
        let filter = prepareFilters(callObject.filters);
        let tocall = Object.assign({}, callObject, filter);
        let formatedDate = new Date(date).setHours(12);
        formatedDate = new Date(formatedDate).toISOString().substring(0, 10);

        Api.getOrganizationChartUsers(formatedDate, value.id, value.level, tocall).then(response => {
            dispatch(organisationChartMoreUsersLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}