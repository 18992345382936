import React, {Component} from 'react';
import AttachmentsList from './AttachmentsList.component';
import * as _ from 'lodash';
import AttachmentInformation from './AttachmentInformation.component';
import {LOADING_STATUS, ZOOM_DIMENSIONS, ATTACHMENT_SERVICE, IMG_BASE} from 'utils/constants';
import {connect} from 'react-redux';
import {changeZoomPercentage, selectedDocumentChanged, changeRotation} from '../../taskDetails.action';
import * as reduxSelectors from '../../../store/application.reducers';
import downloadIcon from '../../../../assets/24_download.svg';
import rotateIcon from '../../../../assets/24_rotate.svg';
import printIcon from '../../../../assets/24_print.svg';
import zoomInIcon from '../../../../assets/24_zoom_in.svg';
import zoomOutIcon from '../../../../assets/24_zoom_out.svg';
import zoomFit from '../../icons/zoom-fit.svg';
import pageUp from '../../../../assets/24_arrowUp.svg';
import pageDown from '../../../../assets/24_arrowDown.svg';
import maximizePanel from "../../icons/maximize.svg";
import maximizePanelDisabled from "../../icons/maximize-disabled.svg";
import minimizePanel from "../../icons/minimize.svg";
import minimizePanelDisabled from "../../icons/minimize-disabled.svg";
import Skeleton from "react-loading-skeleton";
import {generateRandom} from "utils/genereateMathRandom.function";
import {eventBus} from "../../../pagelayout/EventBus";

//same value as the css value for the image container
//these values need to be passed to the react-virtualization component
const PAGE_HEIGHT = 999;
const sizePattern = "<%=size%>";
const pagePattern = "<%= page%>";

export class AttachmentsPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAttachmentInfo: false,
            scrollToIndex: undefined,
            attachments: [],
            listId: undefined,
            selectedImage: 0,
            selectedDocument: 0,
            shouldRotate: false,
            zoomPercentage: 100,
            currentPageOfTotal: 0,
            attachmentsUrlList: []
        };

        this.scroll = this.scroll.bind(this);
        this.toggleAttachmentInfo = this.toggleAttachmentInfo.bind(this);
        this.scrollToPage = this.scrollToPage.bind(this);
        this.computeAttachments = this.computeAttachments.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.zoomControl = this.zoomControl.bind(this);
        this.mapPageModel = this.mapPageModel.bind(this);
        this.findPageStatus = this.findPageStatus.bind(this);
        this.setLocalStoreVariable = this.setLocalStoreVariable.bind(this);
        this.updateZoomOnResize = this.updateZoomOnResize.bind(this);
        this.zoomToFit = this.zoomToFit.bind(this);
        this.panel = React.createRef();
        this.panelScroll = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.attachments, this.props.attachments)) {
            let previewerWidth = this.panel.current?.clientWidth - 16;
            const zoomPercentage = previewerWidth > 0 ? (previewerWidth * 100) / IMG_BASE.width : 100;
            this.setState({
                selectedImage: 0,
                selectedDocument: 0,
                scrollToIndex: 0,
                shouldRotate: false,
                zoomPercentage: zoomPercentage,
                attachments: this.computeAttachments(this.props.attachments, zoomPercentage)
            }, () => {
                this.setState({
                    listId: Math.floor((generateRandom() * 1000000) + 1).toString()
                });
            });

        }
        if (prevProps.isFullWidth !== this.props.isFullWidth || (!prevProps.rerender && this.props.rerender))
            this.updateZoomOnResize();
    }

    zoomToFit() {
        this.updateZoomOnResize();
        this.props.changeZoomPercentage(null);
    }

    updateZoomOnResize() {
        let previewerWidth = this.panel.current?.clientWidth - 16;
        this.setState({
            zoomPercentage: previewerWidth > 0 ? (previewerWidth * 100) / IMG_BASE.width : 100
        });
    }

    componentDidMount() {

        let previewerWidth = this.panel.current?.clientWidth - 16;
        const zoomPercentage = previewerWidth > 0 ? (previewerWidth * 100) / IMG_BASE.width : 100;

        this.setState({
            zoomPercentage: zoomPercentage,
            shouldRotate: false,
            attachmentsUrlList: [],
            attachments: []
        }, () => {
            this.prepareData(this.props.attachments, this.props.zoom);
        });

        eventBus.on('@@grid/refreshAttachmentsPanelLayout', this.updateZoomOnResize);
    }

    componentWillUnmount() {
        eventBus.remove('@@grid/refreshAttachmentsPanelLayout', this.updateZoomOnResize);
    }

    prepareData(attachments, zoom) {
        this.setState({
            listId: Math.floor((generateRandom() * 1000000) + 1).toString(),
            attachments: this.computeAttachments(attachments, zoom)
        });
    }


    findPageStatus(collection, index) {
        let statuses = ["OK", "ORIGINAL", "UNAVAILABLE", "IN_PROGRESS"];
        //password protected and CIR NOT_FOUND have for some reason -1 pages
        if (index == -1) { //eslint-disable-line eqeqeq
            return Object.keys(collection.statusPerPage)[0];
        }

        return _.find(statuses, (status) => {
            let foundIndex = collection.statusPerPage ? _.findIndex(collection.statusPerPage[status], (x) => {
                return x === index + 1;
            }) : -1;

            return foundIndex !== -1;
        });
    }

    mapPageModel(att, index, size, status) {
        let img = {};
        img.urlOriginal = att.urlOriginal;
        img.id = att.id;
        img.pageId = att.pageIds[index] ? att.pageIds[index] : (index + 1);
        img.width = this.props.zoom ? (this.props.zoom * IMG_BASE.width) / 100 : IMG_BASE.width;
        img.height = this.props.zoom ? (this.props.zoom * IMG_BASE.height) / 100 : PAGE_HEIGHT;


        switch (status) {
            case "IN_PROGRESS":
            case "ORIGINAL":
            case "UNAVAILABLE": {
                img.src = null;
                img.status = status;
                break;
            }
            default : {
                img.status = "OK";
                let rotation = decodeURIComponent(att.urlRotationTemplate);
                let decodedUrl = decodeURIComponent(att.urltemplate);

                img.urltemplate = att.urltemplate;
                img.rotationSrc = rotation.replace(sizePattern, ZOOM_DIMENSIONS[4].imageWidth + '')
                    .replace(pagePattern, img.pageId + '');

                img.src = decodedUrl.replace(sizePattern, ZOOM_DIMENSIONS[4].imageWidth + '')
                    .replace(pagePattern, img.pageId + '');

                //Simona this needs to change on back-end after we completely switch to CIR
                if (this.props.taskDetails.attachmentCollection && this.props.taskDetails.attachmentCollection.attachmentCollectionValues && this.props.taskDetails.attachmentCollection.attachmentCollectionValues.attachmentService === ATTACHMENT_SERVICE.CIR) {
                    img.src += "?rotation=" + this.props.rotation + "&waitTimeSeconds=5";
                }
                break;
            }
        }

        return img;
    }

    computeAttachments(attachments, size) {
        let attachmentsModel = attachments !== null ? [] : null;
        if (attachments) {
            let self = this;
            attachments.forEach((att, index) => {
                let img = {};
                let pageModel = {
                    pageCount: 0,
                    images: []
                };

                pageModel.pageCount = att.pageCount;
                pageModel.information = att.information;
                pageModel.id = att.id;
                pageModel.deleteUrlTemplate = att.deletepageurltemplate;


                if (att.pageCount <= 0) {
                    img.width = this.props.zoom ? (this.props.zoom * IMG_BASE.width) / 100 : IMG_BASE.width;
                    img.height = this.props.zoom ? (this.props.zoom * IMG_BASE.height) / 100 : PAGE_HEIGHT;
                    img.pageCount = pageModel.pageCount;
                    img.urlOriginal = att.urlsOriginal ? att.urlsOriginal[Object.keys(att.urlsOriginal)[0]] : "";
                    img.status = this.findPageStatus(att, -1);
                    pageModel.images.push(img);
                }
                _.times(att.pageCount, (index) => {
                    let status = this.findPageStatus(att, index);
                    pageModel.images.push(self.mapPageModel(att, index, size, status));
                });

                attachmentsModel.push(pageModel);

            });
        }

        return attachmentsModel;

    }

    rotateLeft() {
        this.rotateControl(false);
    }

    rotateRight() {
        this.rotateControl(true);
    }

    rotateControl(shouldRotateRight) {
        let attachments = this.state.attachments.slice(0);

        if (this.props.taskDetails.attachmentCollection && this.props.taskDetails.attachmentCollection.attachmentCollectionValues && this.props.taskDetails.attachmentCollection.attachmentCollectionValues.attachmentService === ATTACHMENT_SERVICE.CIR) {
            let currentRotation = this.props.rotation;
            const MIN_ROTATION = 0;
            const MAX_ROTATION = 3;

            currentRotation = shouldRotateRight ? currentRotation + 1 : currentRotation - 1;
            if (currentRotation < MIN_ROTATION && !shouldRotateRight) {
                currentRotation = MAX_ROTATION;
            }
            if (currentRotation > MAX_ROTATION && shouldRotateRight) {
                currentRotation = MIN_ROTATION;
            }

            this.props.changeRotation(currentRotation);
            let currentImg = this.state.attachments[this.state.selectedDocument].images[this.state.selectedImage];

            let decodedUrl = decodeURIComponent(currentImg.urltemplate);
            let rotationSrc = decodedUrl.replace(sizePattern, ZOOM_DIMENSIONS[4].imageWidth + '')
                .replace(pagePattern, currentImg.pageId + '');
            rotationSrc += "?rotation=" + currentRotation + "&waitTimeSeconds=5";

            attachments[this.state.selectedDocument].images[this.state.selectedImage].src = rotationSrc;
        } else {
            let rotationDirection = shouldRotateRight ? '1' : '-1';
            let rotationSrc = this.state.attachments[this.state.selectedDocument].images[this.state.selectedImage].rotationSrc.replace(/<%= rotation%>/, rotationDirection);

            attachments[this.state.selectedDocument].images[this.state.selectedImage].src = rotationSrc + 1 + Math.floor(generateRandom() * 10000);
        }
        this.setState({
            attachments: attachments,
            listId: Math.floor((generateRandom() * 1000000) + 1).toString(),
            shouldRotate: true
        });
    }

    scroll(event) {
        let pageHeight = this.props.zoom ? ((this.props.zoom * IMG_BASE.height) / 100 + 20) : ((this.state.zoomPercentage * IMG_BASE.height) / 100 + 20);
        let currentPage = parseInt((event.scrollTop / pageHeight), 10) + 1;

        let currentImage = currentPage;
        let currentDocument = 0;
        let currentPageOfTotal = 0;

        if (this.props.attachments && this.props.attachments.length >= 1) {
            let index = 0;
            let value = currentPage;

            while (this.props.attachments.length - 1 > index && Math.abs(this.props.attachments[index].pageCount) < value) {
                value -= Math.abs(this.props.attachments[index].pageCount);
                index++;

            }
            currentImage = value - 1;
            currentDocument = index;


            this.props.attachments.forEach((attachment, index) => {
                if (currentDocument > index) {
                    currentPageOfTotal += (attachment.pageCount > 0 ? attachment.pageCount : 1);
                }
                if (currentDocument === index) {
                    currentPageOfTotal += (currentImage + 1);
                }
            });
        }

        //update the store only when moving to a new document
        if (this.state.selectedDocument !== currentDocument && this.state.attachments[currentDocument]) {
            this.props.selectedDocumentChanged(this.state.attachments[currentDocument], currentDocument);
        }

        this.setState({
            selectedImage: currentImage,
            selectedDocument: currentDocument,
            shouldRotate: false,
            currentPageOfTotal: currentPageOfTotal
        });
    }

    scrollToPage(page) {
        this.setState({
            scrollToIndex: page - 1,
            listId: Math.floor((generateRandom() * 1000000) + 1).toString()
        });
    }

    setLocalStoreVariable(displayType) {
        window.localStorage.setItem('attachmentsPanelDisplay', displayType);
    }

    toggleAttachmentInfo(event) {
        this.setState({
            showAttachmentInfo: !this.state.showAttachmentInfo
        });
    }

    getAttachmentsImages(attachments) {
        return (attachments != null && attachments.length > 0) ? _.reduce(attachments, function (flattened, other) {
            return flattened.concat(other.images);
        }, []) : [];
    }

    zoomControl(current, zoomIn) {
        let nextZoom;
        if (zoomIn) {
            nextZoom = (current + 20) <= 300 ? (current + 20) : 300;
        } else {
            nextZoom = (current - 20) >= 50 ? (current - 20) : 50;
        }

        this.props.changeZoomPercentage(nextZoom);
        let attachments = this.state.attachments.slice(0);
        let currentImg = attachments[this.state.selectedDocument]?.images[this.state.selectedImage];

        if (currentImg) {
            let decodedUrl = decodeURIComponent(currentImg.urltemplate);
            attachments[this.state.selectedDocument].images[this.state.selectedImage].src = decodedUrl.replace(sizePattern, ZOOM_DIMENSIONS[4].imageWidth + '')
                .replace(pagePattern, currentImg.pageId + '');
        }

        //Simona this needs to change on back-end after we completely switch to CIR
        if (this.props.taskDetails.attachmentCollection && this.props.taskDetails.attachmentCollection.attachmentCollectionValues && this.props.taskDetails.attachmentCollection.attachmentCollectionValues.attachmentService === ATTACHMENT_SERVICE.CIR) {
            currentImg.src += "?rotation=" + this.props.rotation + "&waitTimeSeconds=5";
        }

        this.setState({
            attachments: attachments,
            listId: Math.floor((generateRandom() * 1000000) + 1).toString(),
            zoomPercentage: nextZoom
        }, () => {
            this.scrollToPage(this.state.currentPageOfTotal);
        });
    }

    renderLoading() {
        return <div className="grid-document-preview-panel-loading">
            <Skeleton className="title-loading-skeleton mb-3" baseColor="#116FAE"/>
            <div className="min-max-btn-group buttons-bar-skeleton d-flex me-4">
                <Skeleton className="button-loading-skeleton ms-4" baseColor="#0D5788"/>
                <Skeleton className="button-loading-skeleton ms-4" baseColor="#0D5788"/>
            </div>
            <div className="buttons-bar-skeleton d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                    <Skeleton className="button-loading-skeleton ms-4" baseColor="#0D5788"/>
                    <Skeleton className="button-loading-skeleton ms-4" baseColor="#0D5788"/>
                    <Skeleton className="range-loading-skeleton ms-4" baseColor="#0D5788"/>
                </div>
                <div className="d-flex align-items-center">
                    <Skeleton className="button-loading-skeleton me-4" baseColor="#0D5788"/>
                    <Skeleton className="range-loading-skeleton me-4" baseColor="#0D5788"/>
                    <Skeleton className="button-loading-skeleton" baseColor="#0D5788"/>
                </div>
                <div className="d-flex align-items-center">
                    <Skeleton className="button-loading-skeleton me-4" baseColor="#0D5788"/>
                    <Skeleton className="button-loading-skeleton me-4" baseColor="#0D5788"/>
                    <Skeleton className="button-loading-skeleton me-4" baseColor="#0D5788"/>
                </div>

            </div>

        </div>
    }

    render() {

        if (this.props.attachments && this.props.attachments.length === 0) {
            return (<span className="col-md-12 text-center padding-top-bottom font-16">
                                    {this.props.translate("taskDetail.noAttachments")}
                                </span>);
        }

        let attachmentsUrlList = this.getAttachmentsImages(this.state.attachments);
        let noAttachmentsClass = this.props.attachments && this.props.attachments.length > 0 ? "" : "disabled";
        let showAttachments = this.props.attachments && this.props.attachments[this.props.selectedDocument.index] ? this.props.attachments[this.props.selectedDocument.index].information.uploaderId !== 0 : false;

        let displayAttachmentInfo = this.props.attachments && this.props.attachments.length > 0 && this.state.showAttachmentInfo && showAttachments;


        let attachmentsActionsProps = {
            rotateLeft: this.rotateLeft.bind(this),
            rotateRight: this.rotateRight.bind(this),
            changeZoomLevel: this.zoomControl,
            changeZoomPercentage: this.props.changeZoomPercentage,
            scrollToPage: this.scrollToPage,
            selectedDocument: this.props.selectedDocument,
            selectedImage: this.state.selectedImage,
            attachments: this.props.attachments,
            attachmentsDownload: this.props.attachmentsDownload,
            zoom: this.props.zoom ? this.props.zoom : this.state.zoomPercentage,
            translate: this.props.translate,
            currentPageOfTotal: this.state.currentPageOfTotal,
            zoomToFit: this.zoomToFit,
            maximizePanel: this.props.maximizePanel,
            minimizePanel: this.props.minimizePanel,
            isAttachmentPanelWidthDefault: this.props.isAttachmentPanelWidthDefault,
            isAttachmentPanelMaxWidth: this.props.isAttachmentPanelMaxWidth
        };

        return (
            <div className="col-md-12 px-0 height-100 w-100" ref={this.panel}>
                <div className="panel panel-default document-pane">

                    <div className={"pb-2 pt-3 px-0 " + noAttachmentsClass}>
                        {this.props.loadingStatus === LOADING_STATUS.LOADING ?
                            this.renderLoading() :
                            <AttachmentsActions {...attachmentsActionsProps}/>
                        }
                    </div>

                    {this.props.loadingStatus === LOADING_STATUS.LOADING &&
                        <div className="col-md-12 p-0 m-0 h-100">
                            <span aria-live={"polite"} aria-busy={true}
                                  className={"react-loading-skeleton attachment-skeleton-img"}/>
                        </div>
                    }

                    {this.props.loadingStatus === LOADING_STATUS.DONE &&
                        <div className="panel-body">
                            {displayAttachmentInfo && this.props.attachments[this.state.selectedDocument] &&
                                <AttachmentInformation
                                    information={this.props.attachments[this.state.selectedDocument].information}/>
                            }
                            {this.state.attachments && this.state.attachments.length > 0 &&
                                <div className={"attachments-images-pane col-md-12 float-left"}>
                                    <AttachmentsList
                                        ref={this.panelScroll}
                                        list={attachmentsUrlList}
                                        scroll={this.scroll}
                                        scrollToIndex={this.state.scrollToIndex}
                                        listId={this.state.listId}
                                        rotate={this.state.shouldRotate}
                                        zoom={this.props.zoom ? this.props.zoom : this.state.zoomPercentage}
                                        translate={this.props.translate}
                                        downloadLink={this.props.attachmentsDownload.downloadPDFUrl}
                                        downloadZIP={this.props.attachmentsDownload.downloadZIPUrl}
                                        rowHeight={!this.props.zoom ? (this.state.zoomPercentage * IMG_BASE.height / 100) : ((this.props.zoom * IMG_BASE.height) / 100)}/>
                                </div>
                            }

                        </div>
                    }
                </div>
            </div>

        )
    }
}

const AttachmentsActions = function (props) {
    const enableResizeIcons = document.body.clientWidth > 1300;
    let downloadPDF = props.attachmentsDownload.downloadPDFUrl;
    let downloadZIP = props.attachmentsDownload.downloadZIPUrl;

    let totalPageNumberOfDoc = 0;
    let total = 0;
    let currentPageOfTotal = 0;

    if (props.attachments) {
        props.attachments.forEach((attachment, index) => {
            if (props.selectedDocument.index > index) {
                currentPageOfTotal += (attachment.pageCount > 0 ? attachment.pageCount : 1);
            }
            if (props.selectedDocument.index === index) {
                currentPageOfTotal += (props.selectedImage + 1);
            }
            total += attachment.pageCount > 0 ? attachment.pageCount : 1;
        });

        totalPageNumberOfDoc = props.attachments.length > 0 ? Math.abs(props.attachments[(props.selectedDocument.index)].pageCount) : -1;
        totalPageNumberOfDoc = totalPageNumberOfDoc === 0 ? 1 : totalPageNumberOfDoc;
    }

    const goToNextPage = function () {
        let gotToNext = props.currentPageOfTotal === total ? total : props.currentPageOfTotal + 1;
        props.scrollToPage(gotToNext);
    }

    const goToPrevPage = function () {
        if (props.currentPageOfTotal - 1 > 0)
            props.scrollToPage(props.currentPageOfTotal - 1);
    }

    const manualZoomChange = function (event) {
        let inputValue = Math.ceil(event.target.value);
        if (!isNaN(inputValue))
            props.changeZoomPercentage(inputValue);
        else
            props.changeZoomPercentage(props.zoom);
    }


    return (
        <div>
            <div className="pages-info-container">
                <div className="title mb-4">
                    {props.translate("taskDetail.doc")} {props.selectedDocument.index + 1}/{props.attachments?.length}
                    {enableResizeIcons &&
                        <div className="min-max-btn-group">
                            <button className="action_btn cursor-pointer m-0 pe-0"
                                    title={props.translate("taskDetail.maximize")}
                                    type="button">
                                <img
                                    className={`details__action-button${props.isAttachmentPanelMaxWidth ? ' disabled' : ''}`}
                                    src={props.isAttachmentPanelMaxWidth ? maximizePanelDisabled : maximizePanel}
                                    alt={props.translate("taskDetail.maximize")}
                                    onClick={props.maximizePanel}
                                />
                            </button>
                            <button className="action_btn cursor-pointer me-2 ms-0 ps-0"
                                    title={props.translate("taskDetail.minimize")}
                                    type="button">
                                <img
                                    className={`details__action-button ${props.isAttachmentPanelWidthDefault ? ' disabled' : ''}`}
                                    src={props.isAttachmentPanelWidthDefault ? minimizePanelDisabled : minimizePanel}
                                    alt={props.translate("taskDetail.minimize")}
                                    onClick={props.minimizePanel}
                                />
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className="document-actions d-flex justify-content-between align-items-center" role={"menubar"}>
                <div className="d-flex justify-content-end align-items-center" role={"menu"}>
                    <button className="action_btn mx-3 px-0" title={props.translate("taskDetail.pageUp")} type="button">
                        <img className={`details__action-button ${currentPageOfTotal <= 1 ? ' disabled' : ''}`}
                             src={pageUp}
                             alt={props.translate("taskDetail.pageUp")}
                             onClick={() => goToPrevPage()}
                        />
                    </button>

                    <button className="action_btn px-0 me-4" title={props.translate("taskDetail.pageDown")}
                            type="button">
                        <img
                            className={`details__action-button ${(currentPageOfTotal >= total) ? ' disabled' : ''}`}
                            src={pageDown}
                            alt={props.translate("taskDetail.pageDown")}
                            onClick={() => goToNextPage()}
                        />
                    </button>

                    <span className="color-white">
                        {props.selectedImage < totalPageNumberOfDoc ? props.selectedImage + 1 : props.selectedImage} / {totalPageNumberOfDoc}
                    </span>
                </div>

                <div role={"menu"}>
                    <button className="action_btn px-0" title={props.translate("taskDetail.zoomOut")} type="button">
                        <img
                            className={"details__action-button me-1 " + (props.zoom === 50 ? "disabled" : "cursor-pointer")}
                            src={zoomOutIcon}
                            alt={props.translate("taskDetail.zoomOut")}
                            onClick={props.changeZoomLevel.bind(null, props.zoom, false)}/>
                    </button>

                    <span className="color-white mx-2">
                        <input type={"number"} value={Math.ceil(props.zoom)} min={50}
                               className={"px-2 py-1 text-center manual-zoom-input"}
                               onChange={manualZoomChange}
                               max={300} step={1}/>
                    </span>

                    <button className="action_btn px-0" title={props.translate("taskDetail.zoomIn")} type="button">
                        <img
                            className={"details__action-button mx-1 " + (props.zoom === 300 ? "disabled" : "cursor-pointer")}
                            src={zoomInIcon}
                            alt={props.translate("taskDetail.zoomIn")}
                            onClick={props.changeZoomLevel.bind(null, props.zoom, true)}
                        />
                    </button>

                    <button className="action_btn px-0" title={props.translate("taskDetail.zoomToFit")} type="button">
                        <img
                            className={"details__action-button mx-1"}
                            src={zoomFit}
                            alt={props.translate("taskDetail.zoomToFit")}
                            onClick={props.zoomToFit}
                        />
                    </button>
                </div>
                <div className={"d-flex align-items-center"} role={"menu"}>
                    <a className="details__action-button me-2" href={downloadZIP} download
                       title={props.translate("taskDetail.download")}>
                        <img src={downloadIcon}
                             alt={props.translate("taskDetail.download")}/>
                    </a>

                    <button className="action_btn" title={props.translate("taskDetail.rotateRight")} type="button">
                        <img className="details__action-button me-3 cursor-pointer"
                             src={rotateIcon}
                             alt={props.translate("taskDetail.rotateRight")}
                             onClick={props.rotateRight}/>
                    </button>

                    <a className={"details__action-button me-3" + (downloadPDF === null ? " disabled" : "")}
                       href={downloadPDF} target='blank'
                       title={downloadPDF === null ? props.translate("taskDetail.cannotPrint") : props.translate("taskDetail.print")}>
                        <img src={printIcon}
                             alt={props.translate("taskDetail.print")}/>
                    </a>

                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (store) {
    return {
        zoom: reduxSelectors.getZoomPercentage(store),
        selectedDocument: reduxSelectors.getSelectedDocument(store),
        attachmentsDownload: reduxSelectors.getAttachmentsDownloadLinks(store),
        rotation: reduxSelectors.getDocumentRotation(store)
    };
};

const connected = connect(mapStateToProps, {
    changeZoomPercentage,
    selectedDocumentChanged,
    changeRotation
})(AttachmentsPreview);

export default connected;
