import {
    SUBSTITUES_LOADED,
    SUBSTITUES_LOADING,
    SUBSTITUES_ACTIVE_FILTER_CHANGE,
    SUBSTITUES_SEARCH_VALUE,
    SUBSTITUES_SORT,
    MORE_SUBSTITUTES_LOADED
} from '../store/actionTypes';

import {createSelector} from 'reselect';
import {LOADING_STATUS} from '../../utils/constants';


const STATUS_FILTER = {
    ALL: "all",
    CURRENT: "current",
    UPCOMING: "upcoming"
};


export const DEFAULT_STATE = {
    activeFilter: STATUS_FILTER.ALL,
    loadingStatus: LOADING_STATUS.LOADING,
    substitutes: {
        page: 1,
        allSubstitutes: []
    },
    searchValue: "",
    sort: {
        fieldName: "",
        sortAsc: true
    }
};
export const getSubstitutesForRender = createSelector(
    [getSubstitutes],
    (substitutes) => {
        return {
            allSubstitutes: substitutes.rows,
            records: substitutes.records,
            page: substitutes.page
        };
    }
);

export default function SubstitutesReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case SUBSTITUES_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                substitutes: []
            };
            return Object.assign({}, state, partialState);

        case SUBSTITUES_LOADED:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.DONE;
            partialState.substitutes = action.data;
            return Object.assign({}, state, partialState);

        case SUBSTITUES_ACTIVE_FILTER_CHANGE:
            partialState = Object.assign([], state);

            partialState.activeFilter = action.data;
            return Object.assign({}, state, partialState);

        case SUBSTITUES_SEARCH_VALUE:
            partialState = Object.assign([], state);

            partialState.searchValue = action.data;
            return Object.assign({}, state, partialState);

        case SUBSTITUES_SORT:
            partialState = Object.assign([], state);

            partialState.sort = action.data;
            return Object.assign({}, state, partialState);

        case MORE_SUBSTITUTES_LOADED:
            let oldData = Object.assign({}, state.substitutes);
            let oldSubs = Object.assign([], state.substitutes.rows);
            // concatenate old rows with new rows
            let allSubs = oldSubs.concat(action.data.rows);
            oldData.rows = allSubs;
            oldData.page = action.data.page;

            partialState = {
                substitutes: oldData
            };
            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}

export function getActiveFilter(state) {
    return state.activeFilter;
}

export function getSearchValue(state) {
    return state.searchValue;
}

export function getSubstitutes(state) {
    return state.substitutes;
}

export function getSorting(state) {
    return state.sort;
}

export function getLoadingStatus(state) {
    return state.loadingStatus;
}




