import {
    SAVE_MY_HISTORY_DONE,
    SAVE_MY_TASKS_ERROR,
    SAVE_MY_HISTORY_LOADING,
    HISTORY_PROCESS_TYPE_CHANGED,
    HISTORY_ADVANCED_SEARCH_OPEN,
    HISTORY_FILTER_CHANGED,
    HISTORY_SORT_COLUMN_CHANGED,
    HISTORY_GET_NEXT_PAGE,
    COST_UNITS_LOADED,
    PREVENT_DATA_RELOAD,
    HISTORY_SAVE_SCROLL_POSITION
} from '../store/actionTypes';
import {HISTORY_FILTERS} from 'utils/constants';
import {handleError} from 'utils/errorHandle.function';
import {navigateToMyHistory} from "components/router/router.function.js";
import * as Api from 'utils/api/api';
import {returnCostUnits} from "components/myhistory/getCostUnits.function";


export function historyTasksLoading() {
    return {
        type: SAVE_MY_HISTORY_LOADING
    };
}

export function tasksLoaded(tasksArray = []) {
    return {
        type: SAVE_MY_HISTORY_DONE,
        data: tasksArray
    };
}

export function tasksLoadingFailed(error) {
    return {
        type: SAVE_MY_TASKS_ERROR,
        data: error
    };
}

export function processFilterChanged(filter) {
    return {
        type: HISTORY_PROCESS_TYPE_CHANGED,
        processFilter: filter
    };
}

export function toggleAdvancedSearch(isOpen) {
    return {
        type: HISTORY_ADVANCED_SEARCH_OPEN,
        advancedSearchOpen: isOpen
    };
}

export function filterChanged(filter) {
    return {
        type: HISTORY_FILTER_CHANGED,
        filter: filter
    };
}

export function sortingColumnChanged(sortColumn) {
    return {
        type: HISTORY_SORT_COLUMN_CHANGED,
        sortColumn: sortColumn.sort,
        sortLogic: sortColumn.sortBy
    };
}

export function moreTasksLoaded(tasks = []) {
    return {
        type: HISTORY_GET_NEXT_PAGE,
        data: tasks
    };
}

export function costUnitsLoaded(costUnits = []) {
    return {
        type: COST_UNITS_LOADED,
        data: costUnits
    };
}

export function preventDataReload(isStopDataReload) {
    return {
        type: PREVENT_DATA_RELOAD,
        data: isStopDataReload
    };
}

export function saveScrollPosition(topScroll) {
    return {
        type: HISTORY_SAVE_SCROLL_POSITION,
        data: topScroll
    };
}

/**
 * async action, takes care of tasks loading
 * @return {Function}
 */
export function loadHistory(callObject) {

    return function (dispatch) {

        dispatch(historyTasksLoading());
        dispatch(saveScrollPosition(0));

        let filter = prepareFilters(callObject);
        delete callObject.filters;

        callObject = Object.assign({}, callObject, {status: "all"}, filter);

        Api.getHistoryTasks(callObject).then(
            response => {
                dispatch(tasksLoaded(response));
            },
            error => {
                dispatch(tasksLoadingFailed(error));
                handleError(error);
                navigateToMyHistory();
            }
        );
    }
}

export function loadMoreHistory(callObject) {
    return function (dispatch) {
        let filter = prepareFilters(callObject);
        callObject = Object.assign({}, callObject, {status: "all"}, filter);

        Api.getHistoryTasks(callObject).then(
            response => {
                dispatch(moreTasksLoaded(response));
            },
            error => {
                dispatch(tasksLoadingFailed(error));
                handleError(error);
                navigateToMyHistory();
            }
        );
    }
}

export function getCostUnits() {
    return function (dispatch) {
        dispatch(returnCostUnits(costUnitsLoaded));
    }
}

function prepareFilters(callObject) {


    let currentFilter = Object.assign({}, callObject.filters);

    let filter = {};
    Object.keys(currentFilter).forEach(key => {
        switch (key) {
            case HISTORY_FILTERS.ACCOUNT:
            case HISTORY_FILTERS.COST_UNITS: {
                filter[key] = JSON.stringify(currentFilter[key]);
                break;
            }
            case HISTORY_FILTERS.COLUMNS: {
                filter = Object.assign({}, filter, currentFilter[key]);
                break;
            }
            case HISTORY_FILTERS.SORTING: {
                filter[HISTORY_FILTERS.SORT_DIRECTION] = currentFilter[key].direction;
                filter[HISTORY_FILTERS.SORT_INDEX] = currentFilter[key].index;
                break;
            }
            default: {
                break;
            }
        }

    });
    return filter;
}

