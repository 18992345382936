import React, {Component, Fragment} from 'react';
import * as _ from "lodash";
import translate from '../translations/translations.wrapper.jsx';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';

import {getProjectsUsersAndRoles} from "./projects.action";
import {EditableDimension, EditableEmails} from './ProjectsEditables.component';

class ProjectsApprovers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            roles: null,
            newUsers: [],
            newRoles: [],
            selectedIdRole: this.props.idRole
        };
        this.changeIdRole = this.changeIdRole.bind(this);
        this.changeApproverEmail = this.changeApproverEmail.bind(this);
        this.mapUserEmails = this.mapUserEmails.bind(this);
        this.mapUserRole = this.mapUserRole.bind(this);
    }

    componentDidMount() {
        this.props.getProjectsUsersAndRoles(this.props.approvers);
    }


    changeIdRole(idRole) {
        this.props.saveIdRole(idRole);
        this.setState({
            selectedIdRole: idRole.value
        });
    }

    changeApproverEmail(value) {
        this.setState({
            users: value
        });
        this.props.saveApproverEmail(value);
    }

    mapUserEmails() {
        return this.props.approvers.map((approver, index) => {
            return _.find(this.props.users, (user) => {
                return user.value === approver;
            });
        });
    }

    mapUserRole() {
        return _.find(this.props.roles, (role) => {
            return role.value === this.props.idRole
        });
    }

    render() {
        if (this.props.users && this.props.roles) {
            let userRole = this.mapUserRole();
            let users = this.mapUserEmails();

            let userEmails = users.length > 0 ? users.map((user, index) => {
                return <div className="margin-right" key={index}>{user ? user.label : ''}</div>
            }) : <span/>;

            if (this.props.editMode) {
                return (
                    <Fragment>
                        <div className="col user-role">
                            <EditableDimension
                                value={userRole}
                                translate={this.props.translate}
                                changeValue={this.changeIdRole}
                                options={this.props.roles}/>
                        </div>
                        <div className="col user-email">
                            <EditableEmails changeValue={this.changeApproverEmail}
                                            value={users}
                                            translate={this.props.translate}
                            />
                        </div>
                    </Fragment>
                )
            }
            else {
                return (
                    <Fragment>
                        <div className="col user-role">{userRole ? userRole.label : ''}</div>
                        <div className="col user-email">{userEmails}</div>
                    </Fragment>
                )
            }
        }
        else {
            return <div className="col"/>;
        }
    }
}

const withTranslations = translate(ProjectsApprovers);
const mapStateToProps = function (store) {
    return {
        roles: reduxSelectors.getProjectsRoles(store),
        users: reduxSelectors.getProjectsUsers(store)
    };
};

const connected = connect(mapStateToProps, {getProjectsUsersAndRoles})(withTranslations);
export default connected;

