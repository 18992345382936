import {
    TASK_OVERVIEW_LOADING,
    TASK_OVERVIEW_LOADED,
    ACTIVE_COUNTER_CHNAGED,
    OVERVIEW_FILTER_CHANGED,
    OVERVIEW_COUNTERS_LOADED,
    OVERVIEW_SORT_COLUMN_CHANGED,
    LOAD_MORE_OVERVIEW,
    OVERVIEW_TASK_SELECTED,
    LOGGED_IN_TYPE
} from '../store/actionTypes';
import {createSelector} from 'reselect';
import {TASK_OVERVIEW_FILTERS, LOADING_STATUS, OVERVIEW_STATUS} from '../../utils/constants';
import * as _ from 'lodash';
import {getUsersCurrentContextId} from '../usercontext/user.reducer';

export const DEFAULT_STATE = {
    loadingStatus: LOADING_STATUS.LOADING,
    counters: null,
    activeCounter: OVERVIEW_STATUS.blockedTasks,
    tasks: [],
    userRoles: null,
    filter: {
        columns: {} //top grid filtering
    },
    sortingLogic: {},   //sorting logic needed for the reactabular grid
    selectedTasks: []
};

export const getOverviewTasks = createSelector(
    [getTasks, getOverviewUserRoles, getOverviewSelectedTasks],
    (tasks, roles, selectedTasks) => {

        let mappedTasks = Object.assign({}, tasks);
        if (roles && tasks.rows) {
            mappedTasks.rows = tasks.rows.map((task, index) => {
                let role = _.find(roles, ['actorTypeCode', task.actorType]);
                task.role = role ? role.actorTypeName : '';
                return task;
            });
        }
        return mappedTasks;
    }
);

export default function myTaskDetailsReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case TASK_OVERVIEW_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                tasks: []
            };
            return Object.assign({}, state, partialState);

        case TASK_OVERVIEW_LOADED:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.DONE;
            partialState.tasks = action.data.tasks;
            partialState.userRoles = action.data.userRoles;
            return Object.assign({}, state, partialState);

        case OVERVIEW_COUNTERS_LOADED:
            partialState = Object.assign([], state);
            partialState.counters = action.data;
            partialState.selectedTasks = [];
            return Object.assign({}, state, partialState);

        case ACTIVE_COUNTER_CHNAGED:
            partialState = Object.assign([], state);
            partialState.activeCounter = action.data;
            partialState.tasks = [];
            partialState.sortingLogic = {};
            partialState.selectedTasks = [];
            return Object.assign({}, state, partialState);

        case LOAD_MORE_OVERVIEW:
            let oldTasks = Object.assign([], state.tasks.rows);
            // concatenate old rows with new rows
            let allTasks = oldTasks.concat(action.data.rows);
            let newData = Object.assign({}, action.data);
            newData.rows = allTasks;
            partialState = {
                tasks: newData
            };
            return Object.assign({}, state, partialState);

        case OVERVIEW_SORT_COLUMN_CHANGED:
            partialState = {
                filter: Object.assign({}, state.filter),
                sortingLogic: Object.assign({}, action.sortLogic)
            };

            partialState.filter[TASK_OVERVIEW_FILTERS.SORTING] = action.sortColumn;
            return Object.assign({}, state, partialState);

        case OVERVIEW_FILTER_CHANGED:
            partialState = {
                filter: action.filter
            };
            return Object.assign({}, state, partialState);

        case OVERVIEW_TASK_SELECTED: {
            partialState = Object.assign([], state);
            let selected = Object.assign([], partialState.selectedTasks);
            const allSelection = action.key.length === state.tasks.rows.length;

            //master select checked
            if (allSelection) {
                //all tasks selected -> must unselect all
                if (state.selectedTasks.length === state.tasks.rows.length) {
                    selected = [];
                }
                //must select all
                else {
                    selected = action.key.map(taskkey => {
                        return taskkey;
                    });
                }
            }
            //single task select/unselect
            else {
                action.key.forEach(taskkey => {
                    let found = _.findIndex(state.selectedTasks, (x => x === taskkey));
                    if (found === -1) {
                        selected.push(taskkey);
                    }
                    else {
                        selected.splice(found, 1);
                    }
                });
            }
            partialState.selectedTasks = selected;
            return Object.assign({}, state, partialState);
        }

        case  LOGGED_IN_TYPE:
            const newContextId = getUsersCurrentContextId(action.data);
            if (newContextId !== state.currentContext) {
                partialState = {
                    currentContext: newContextId,
                    processFilter: {},
                    filter: { columns: {} },
                    sortingLogic: {},
                    selectedTasks: []
                };
            }
            else {
                partialState = Object.assign({}, state);
            }
            return Object.assign({}, state, partialState);
        default:
            return state;
    }
}


export function getOverviewCounters(state) {
    return state.counters;
}

export function getOverviewActiveCounter(state) {
    return state.activeCounter;
}

export function getTasks(state) {
    return state.tasks;
}

export function getOverviewUserRoles(state) {
    return state.userRoles;
}

export function getOverviewFilter(state) {
    return state.filter;
}

export function getOverviewSortingLogic(state) {
    return state.sortingLogic;
}

export function getOverviewLoadingStatus(state) {
    return state.loadingStatus;
}

export function getOverviewSelectedTasks(state) {
    return state.selectedTasks;
}