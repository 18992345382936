import * as Api from "utils/api/api";
import {handleError} from "utils/errorHandle.function";


export function returnCostUnits(costUnitsLoadedHandler) {
    return function (dispatch) {
        Api.getCostUnitTypes().then((costUnits) => {
                let result = [];
                let response = ('result' in costUnits) ? costUnits.result : costUnits;

                if (Array.isArray(response)) {
                    response.forEach((unit) => {
                        if (unit.appearsInIndex) {
                            unit.selectedValue = undefined;
                            result.push(unit);
                        }
                    });
                    dispatch(costUnitsLoadedHandler(result));
                }
            },
            (error) => {
                handleError(error);
            }
        )
    }
}