// import the styles for whole app
import './scss/bootstrap.scss';
import './scss/nc4.scss';
import './scss/utilities.scss';

// datepicker
import "flatpickr/dist/themes/light.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-loading-skeleton/dist/skeleton.css'; //skeleton loading

import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {Provider as RollbarProvider, ErrorBoundary} from '@rollbar/react'

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './utils/snowplow';

import Router from 'components/router/Router.component.jsx';
import store from 'components/store/Store.component.jsx';
import {createRoot} from 'react-dom/client';



const rollbarConfig = {
    accessToken: window.ENVIRONMENT.ROLLBAR_ACCESS_TOKEN,
    environment: window.ENVIRONMENT.NAME,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        client: {
            javascript: {
                code_version: window.ENVIRONMENT.VERSION
            }
        }
    }
};


const App = () => {
    return (<RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
            <ReduxProvider store={store}>
                <Router/>
                    <ToastContainer
                        position={'top-right'}
                        autoClose={2500}
                        hideProgressBar={true}
                        draggable
                        closeButton={false}
                    />
            </ReduxProvider>
        </ErrorBoundary>
    </RollbarProvider>);
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);

