import React, {Component} from 'react';
import {connect} from 'react-redux';
import {unbundleBatch, prepareBatch} from '../mytasks/myTasks.action';
import Confirm from '../popup/ConfirmActionPopup.component';


export class BatchButtons extends Component {

    constructor() {
        super();
        this.state = {
            displayConfirm: false

        };
        this.unbundleTasks = this.unbundleTasks.bind(this);
        this.sign = this.sign.bind(this);
        this.hideConfirm = this.hideConfirm.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }


    unbundleTasks() {
        if (this.props.batch.documents.length > 1)
            this.setState({
                displayConfirm: true
            });
        else
            this.props.unbundleBatch(this.props.batch.id, this.props.batch.version);
    };

    hideConfirm() {
        this.setState({
            displayConfirm: false
        });
    }

    confirmDelete() {
        this.props.unbundleBatch(this.props.batch.id, this.props.batch.version);
        this.hideConfirm();
    }


    sign() {
        if (this.props.okCallback)
            this.props.okCallback();
        this.props.prepareBatch(this.props.batch.id, this.props.batch.version);
    };

    render() {
        return (
            <div>
                {this.state.displayConfirm &&
                <Confirm translate={this.props.translate}
                         message={this.props.translate("myTasks.confirmCancelBatch")}
                         handleAction={this.confirmDelete}
                         closeCallback={this.hideConfirm}/>
                }
                <button
                    className="btn btn-primary btn-margin-right"
                    onClick={this.sign}>
                    {this.props.translate("myTasks.sign")}
                </button>
                <button
                    className="btn btn-default btn-margin-right"
                    onClick={this.unbundleTasks}>
                    {this.props.translate("myTasks.unapproveBundle")}
                </button>
            </div>
        );
    }

};
const mapBatchButtonsStateToProps = function (store) {
    return {};
};
const connected = connect(mapBatchButtonsStateToProps, {unbundleBatch, prepareBatch})(BatchButtons);

export default connected;