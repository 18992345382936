import {Fragment} from "react";
import {CostUnitEditorProps, costUnitInputType, CostUnitModelType, DecisionTableRow} from "../decisionTables/decisionTableTypes";
import _ from "lodash";
import * as Api from "../../utils/api/api";
import translate from "../translations/translations.wrapper";
import {AdvancedSearch} from "../myhistory/HistoryAdvancedSearch.component"
import {fixedMenuStyle} from "../../utils/reactSelectTheme";


export function CostUnit(props: CostUnitEditorProps) {

    const saveValue = function (e: costUnitInputType) {
        if (e) {
            let newRow = _.cloneDeep(props.p.row);
            newRow[props.p.column.name as keyof DecisionTableRow] = e.label;
            let model: CostUnitModelType = {
                column: props.p.column.name.toString(),
                operator: "=",
                value: {value: e.value, label: e.label},
                name: e.label
            }
            props.setCostUnitsValues(model, props.p.row.id);

            props.p.onRowChange(newRow);
        }
    }

    /* const saveEmpty = function () {
         let newRow = _.cloneDeep(props.p.row);
         newRow[props.p.column.name as keyof dtRow] = "";
         props.p.onRowChange(newRow);

     }*/

    let hasValue: boolean = props.value && props.value.label !== undefined && props.value.value !== undefined;

    return (

        <Fragment>
            <AdvancedSearch key={1}
                            bsClass="col-md-12 px-0"
                            placeholder={""}
                            styles={fixedMenuStyle}
                            type={Api.lookupCostCenters}
                            usedInIndexOnly={false}
                            selectedValue={hasValue ? props.value : undefined}
                            dimension={props.dimension}
                            hasAny={true}
                            hasUnassigned={true}
                            onChange={saveValue}
                            tabSelectsValue={false}
                            translate={props.translate}/>

        </Fragment>

    )
}

const CostUnitEditor = translate(CostUnit);
export default CostUnitEditor;
