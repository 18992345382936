import React from "react";


export const ImageLoading = (): React.ReactElement => {
    return (
        <div className={"page_container"}>
            <div className="attachment-image-container__loading-spinner position-absolute">
                        <span className="vismaicon-filled vismaicon spinner spinner-default-grey" role="status"
                              aria-hidden="false"/>
            </div>
        </div>
    )
}

export default ImageLoading;