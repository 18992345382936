import translate from "components/translations/translations.wrapper";
import reattachImg from 'components/taskdetails-remake/icons/reattach_white.svg';
import { useDispatch, useSelector } from "react-redux";
import {
    getDocumentPreviewTitle,
} from "components/store/application.reducers";
import { documentPreviewReattach } from "components/taskdetails-remake/taskDetails.action";

export const AttachmentsPanelHeader = ({translate}) => {
    const dispatch = useDispatch();
    const previewTitle = useSelector(getDocumentPreviewTitle);


    return (
        <div className="d-flex flex-row py-24 px-3">
            <div className="d-flex mr-auto font-16 semibold attachment-header__title__div">
                {previewTitle}
            </div>
            <button className="attachment-header__reattach_button cursor-pointer"
                    title={translate("taskDetail.reattach")}
                    type="button"
                    onClick={() => dispatch(documentPreviewReattach())}>
                <img
                    className={"details__action-button dark me-1 pr-8"}
                    src={new URL(reattachImg, window.location.origin).href}
                    alt={translate("taskDetail.reattach")}
                />
                {translate("taskDetail.reattachDocument")}
            </button>
        </div>

    );
};

export default translate(AttachmentsPanelHeader);