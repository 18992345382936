import React, { useEffect, useRef, useState } from "react";
import {Link} from 'react-router-dom';
import {AddNew} from './AddNew.component';
import {DOCUMENT_TYPE} from "utils/constants";
import * as _ from "lodash";
import { getDateFormatFromLanguage } from "utils/dateFormatter";
import { connect, useSelector} from "react-redux";
import * as reduxSelectors from "../store/application.reducers";
import moment from "moment";
import { Tooltip } from "../tooltip/Tooltip";
import UserProfileTooltip from "../tooltip/UserProfileTooltip.component";
import WorkflowsActionsDropdownComponent from "components/workflows/WorkflowsActionsDropdown.component";
import ActivateWorkflowPopup from "components/popup/ActivateWorkflowPopup.component";

export function Rules(props) {
    const [showRules, setShowRule] = useState(true);
    const docType = DOCUMENT_TYPE.asString(parseInt(props.docType, 10));
    const translatedDocType = props.translate("documentType." + docType).toLowerCase();
    const highlightRuleRef = React.useRef(null);

    const scrollToElement = () => {
        const { current } = highlightRuleRef;
        // @ts-ignore
        current && current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToElement, [props.lastCreatedWorkflow]);

    const generateNewRuleNameWithNumber = function() {
        let increment = 1;
        let name = props.translate("workflows.newWorkflow", translatedDocType);
        props.rules.forEach(rule => {
            if (rule.name.indexOf(name) !== -1) {
                let currentIncrement = rule.name.substr(name.length + 1, 1);
                increment = parseInt(currentIncrement, 10) + 1;
                if (isNaN(increment))
                    increment = 1;
            }
        });
        return name + " " + increment;
    };

    const generateNewRuleText = function() {
        if (props.rules.length === 0)
            return props.translate("workflows.createFirstRule", translatedDocType);
        else
            return props.translate("workflows.newWorkflow", translatedDocType);
    };

    const getWorkflowMessage = function(isActive) {
        //has no workflows
        if (props.rules.length === 0)
            return (
                <span className="label label-danger label-grey float-end">
                        {props.translate("workflows.noWorkflows")}
                    </span>);

        //has no active workflows
        if (!isActive)
            return (
                <span className="label label-danger float-end">
                        {props.translate("workflows.noActiveWorkflow")}
                    </span>);

        return (
            <span>
            <span className="label label-success float-end">
                    {props.translate("workflows.activeWorkflows")}
                </span>
            </span>
        );
    };

    const getWorkflowStatus = function() {
        let hasActive = _.findIndex(props.rules, rule => {
            return rule.active === true;
        }) !== -1;
        return hasActive;
    };

    const getActiveWorkflowName = function() {
        return _.find(props.rules, rule => {
            return rule.active === true;
        })?.name;
    };

    const isRuleHighlighted = function(rule) {
        return props.lastCreatedWorkflow &&
            props.lastCreatedWorkflow.id === rule.idRuleConfiguration &&
            ((new Date().getTime()) - props.lastCreatedWorkflow.created < 5000);
    };

    const buildRulesList = function() {
        return props.rules.map((rule) => {
            return (<div className="workflow-list-wrapper pl-32 pr-16" key={rule.idRuleConfiguration}>
                <div className={"company-workflow-list list-unstyled " + (isRuleHighlighted(rule) ? "highlight" : "")}>
                    <div className="col-md-12 px-0" ref={isRuleHighlighted(rule) ? highlightRuleRef : null}>
                        <Rule rule={rule}
                              translate={props.translate}
                              activateRule={props.activateRule}
                              activateReadonlyRule={props.activateReadonlyRule}
                              deactivateReadonlyRule={props.deactivateReadonlyRule}
                              activeRuleName={getActiveWorkflowName()}
                              showActions={props.showActions}
                              docType={props.docType} />
                    </div>
                </div>
            </div>);
        });
    };

    let hasActiveWorkflow = getWorkflowStatus();
    const arrowClass = "header-icon font-18 glyphicon pull-right mx-2";
    let toggleIconClass = showRules ? arrowClass + " glyphicon-menu-down" : arrowClass + " glyphicon-menu-right";

    return (
        <div className="company" key={props.index}>
            <div className="company-header pl-24 pr-16 py-3">
                <div className="row pb-3">
                    <div className="col-md-8 d-flex">
                        <span className={"document-type-icon-24 document-type-" + docType + "-24"} />
                        <h3 className="ast_doc_name mb-0 ms-3">
                            <strong>{props.translate("documentTypes." + docType)}</strong>
                        </h3>
                    </div>
                    <div className="col-md-4">
                        {getWorkflowMessage(hasActiveWorkflow)}
                        <span className={toggleIconClass} onClick={(e) => setShowRule(!showRules)} />
                    </div>
                </div>
            </div>
            {showRules &&
                <div className="collapsable">
                    {buildRulesList()}
                    {props.showActions &&
                        <AddNew
                            defaultValue={generateNewRuleNameWithNumber()}
                            addNewText={generateNewRuleText()}
                            isFirst={props.rules.length === 0}
                            onBlur={(name) => {
                                props.addRule(name, props.docType);
                            }} />
                    }
                </div>
            }
        </div>
    );
}


const Rule = (props) => {
    let { rule, activateRule, activateReadonlyRule, deactivateReadonlyRule, showActions } = props;
    const dateValueFormatter = getDateFormatFromLanguage(props.userLanguageCode);
    const activateUserInputRef = useRef(null);
    const [showActivate, setShowActivate] = useState(false);
    const preventAction = useSelector(reduxSelectors.getWorkflowsPreventAction);

    const ruleOnChange = (event) => {
        const shouldActivate = !rule.active;
        if (rule.readonly) {
            shouldActivate ? activateReadonlyRule(rule, activateRuleErrorCallback) : deactivateReadonlyRule(rule, activateRuleErrorCallback);
        } else if (props.showActions) {
            activateRule(rule, shouldActivate, activateRuleErrorCallback);
        }
        setShowActivate(false);
    };

    const activateRuleErrorCallback = () => {
        rule.active = !rule.active;
    };

    return (
        <div className="workflow row mx-0">
            {showActivate && <ActivateWorkflowPopup onActivate={ruleOnChange}
                                                    onCancel={() => setShowActivate(false)}
                                                    activate={!rule?.active}
                                                    ruleName={rule?.name}
                                                    replaceActiveRuleName={props.activeRuleName}
                                                    documentType={rule?.documenttype}
                                                    activateUserInputRef={activateUserInputRef}
            ></ActivateWorkflowPopup>}
            <div className="col-md-10">
                <div className="d-flex align-items-center mx-0">
                    <div className="active-slider">
                        <label className="switch switch-label">
                            <input type="checkbox"
                                   checked={rule.active}
                                   onChange={(e)=> {
                                       if(preventAction){
                                           e.preventDefault();
                                           e.stopPropagation();
                                       } else {
                                           setShowActivate(true);
                                       }
                                   }
                            }
                            />
                            <span className={"togglemark " + (props.showActions ? "" : " disabled")} />
                        </label>
                    </div>
                    <Link to={"/configuration/workflows/workflow/" + rule.idRuleConfiguration}
                          className="ast_rule_name rule-name text-decoration-none">
                        {rule.name}
                    </Link>

                </div>


                {rule.lastUpdateDate && rule.lastUpdateUserDTO &&
                    <div
                        className="font-12 workflow-more">{props.translate("workflows.workflowLastUpdateBy", moment(rule.lastUpdateDate).format(dateValueFormatter))}
                        <span className="rule-name"
                              data-for={rule.idRuleConfiguration + "-" + rule.lastUpdateUserDTO.idUser} data-tip="">
                            {rule.lastUpdateUserDTO ? rule.lastUpdateUserDTO.fullName : ""}
                        </span>
                        <Tooltip
                            id={rule.idRuleConfiguration + "-" + rule.lastUpdateUserDTO.idUser}
                            content={() => <UserProfileTooltip user={rule.lastUpdateUserDTO} />}
                        />
                    </div>
                }
            </div>

            {
                showActions &&
                <div className="col-md-2 d-flex align-items-center justify-content-end px-0">
                    <Link className="action" to={"/configuration/workflows/workflow/" + rule.idRuleConfiguration}>
                        <span className="vismaicon vismaicon-edit vismaicon-dynamic"
                              title={props.translate("workflows.edit")} />
                    </Link>
                    <WorkflowsActionsDropdownComponent
                        rule={rule}
                        customContainer={CustomMoreMenu}>
                    </WorkflowsActionsDropdownComponent>
                </div>
            }
        </div>
    );
};
const mapStateToProps = function(store) {
    return {
        userLanguageCode: reduxSelectors.getUsersCurrentLanguage(store),
    };
};

connect(mapStateToProps, {})(Rule);

export const CustomMoreMenu = React.forwardRef(({ children, onClick }, ref) => (
    <span className="action vismaicon  vismaicon-more cursor-pointer"
          title={children}
          ref={ref}
          onClick={(e) => {
              e.preventDefault();
              onClick(e);
          }}
    />
));
