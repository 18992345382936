import {VIRTUAL_PAGE_ROUTING, VIRTUAL_PAGE_ROUTING_END} from '../store/actionTypes';
/**
 * virtualPage phases from AppDynamics documentation:
 *   https://docs.appdynamics.com/download/attachments/35229426/routing.jpeg?version=1&modificationDate=1452723883000&api=v2
 */
export const ACTION = {
    start: 1, // Indicates when a virtual page starts.
    // we don't need this level granularity, PLT or End User Response Time is the main metric we're measuring
    markViewChangeStart: 2, // Sets the view change start time.
    markViewChangeEnd: 3, // Sets the view change end time.
    markViewDOMLoaded: 4, // Sets the view DOM loaded time.
    markXhrRequestsCompleted: 5, // Sets the XHR requests completed time - data from backend call ended
    markViewResourcesLoaded: 6, // additional 3rd party requires loaded - for example when some image was added
    end: 7 // Indicates when a virtual page ends.
};

// since we've moved implementation into component directly under the router, we don't need routeStart action
// /**
//  * @param url
//  * @param action
//  */
// export function routeStart(url) {
//     return reduxAction(url, ACTION.start);
// }

export function pageLoaded() {
    return {
        type: VIRTUAL_PAGE_ROUTING,
        action: ACTION.end
    }
}

export function pageSwitched() {
    return {
        type: VIRTUAL_PAGE_ROUTING,
        action: ACTION.markViewDOMLoaded
    }
}

export function pageDataLoaded() {
    return {
        type: VIRTUAL_PAGE_ROUTING,
        action: ACTION.markXhrRequestsCompleted
    }
}

export function pageTracked() {
    return {
        type: VIRTUAL_PAGE_ROUTING_END
    }
}
