import React, { Component } from 'react';
import * as Api from '../../../utils/api/api.js';
import { ExceptionsList } from './ExceptionsList.component';
import translate from "../../translations/translations.wrapper.jsx";
import { handleError } from "../../../utils/errorHandle.function";
import { pushCustomDataToAppDynamics } from "../../../utils/appDynamics.function";
import { connect } from 'react-redux';
import noExceptionsUser from "../../../assets/ill-exception_user.svg";
import noExceptionsArrow from "../../../assets/ill-pointing_arrow-top-right.svg";

export class DefineExceptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exceptions: null,
            addExceptionPopupVisible: false,
            loading: true
        };

        this.mapData = this.mapData.bind(this);
        this.saveException = this.saveException.bind(this);
        this.editException = this.editException.bind(this);
        this.deleteException = this.deleteException.bind(this);
        this.changeExceptions = this.changeExceptions.bind(this);
        this.showAddExceptionPopup = this.showAddExceptionPopup.bind(this);
        this.hideAddExceptionPopup = this.hideAddExceptionPopup.bind(this);
    }

    componentDidMount() {
        Api.getExceptionsExtended()
            .then(response => {
                return this.mapData(response);
            })
            .then(() => {
                this.setState({
                    loading: false
                });
            });
    }

    mapData(data) {
        const nameNotFoundLabel = this.props.translate("users.nameNotFound");
        const exceptions = data.map((exception) => {
            return {
                role: {
                    label: exception.role.name || '',
                    value: exception.role.id,
                    valid: exception.role.valid
                },
                value: exception.user.id,
                label: exception.user.name || exception.user.email || nameNotFoundLabel,
                valid: exception.user.valid,
                id: exception.id,
                users: exception.users.map((user) => {
                    return {
                        value: user.id,
                        label: user.name || user.email || nameNotFoundLabel,
                        valid: user.valid,
                    }
                })
            };
        });
        this.setState({
            exceptions
        })
    }

    saveException(rows, newRow) {
        const startTime = new Date();
        return Api.saveRoleNameException(newRow)
            .then((response) => {
                const endTime = +(new Date()) - startTime;

                this.setState({
                    exceptions: this.state.exceptions.map((exception, index) => {
                        return index === this.state.exceptions.length - 1
                            ? { ...exception, id: response.id }
                            : exception;
                    })
                });

                pushCustomDataToAppDynamics('costUnitsExceptionsSaveTime', endTime);
            })
            .catch((error) => {
                let matches;
                const idRegEx = /id: (\d+)/g;
                const ids = [];

                while (matches = idRegEx.exec(error.errorMessages)) { //eslint-disable-line no-cond-assign
                    ids.push(matches[1]);
                }
                if(error.errorCode === 332) {
                    handleError({...error,
                        errorTitle: this.props.translate("costUnitExceptions.duplicateExceptionTitle", ids.join(', ')),
                        errorMessages: this.props.translate("costUnitExceptions.duplicateException", ids.join(', '))
                    })
                } else {
                    handleError({...error, errorMessages: this.props.translate("costUnitExceptions.exceptionError", ids.join(', '))})
                }
            });
    }

    editException(row, id) {
        return Api.updateRoleNameException(id, row)
            .catch((error) => {
                handleError({...error, errorMessages: this.props.translate("costUnitExceptions.exceptionError", id)})
            });
    }

    deleteException(id) {
        const startTime = new Date();

        return Api.deleteRoleNameException(id)
            .then(() => {
                const endTime = +(new Date()) - startTime;

                pushCustomDataToAppDynamics('costUnitsExceptionsDeleteTime', endTime);
            })
            .catch((error) => {
                handleError({...error, errorMessages: this.props.translate("costUnitExceptions.exceptionError", id)})
            });
    }

    changeExceptions(data, id) {
        this.setState({
            exceptions: data
        });
    }

    showAddExceptionPopup() {
        this.setState({
            addExceptionPopupVisible: true
        });
    }

    hideAddExceptionPopup() {
        this.setState({
            addExceptionPopupVisible: false
        });
    }

    render() {
        const showAlert = (this.state.exceptions || []).find((exception) => {
           return exception.valid === false || (exception.users || []).find((user) => {
               return user.valid === false;
           });
        });
        const emptyExceptions = (this.state.exceptions || []).filter((row) => {
            //Take into account rows without role (role deleted)
            return row.role && row.role.label;
        }).length === 0;
        return (
            <div>
                <div className="cost-units-exceptions-header">
                     <div>
                         <h3 className="no-border d-flex align-items-center pt-4">{this.props.translate("costUnitExceptions.header")}
                             <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                                   title={this.props.translate("costUnitExceptions.headerInfo")}/>
                         </h3>
                         <p>
                             {this.props.translate("costUnitExceptions.subheader")}
                         </p>
                     </div>

                    <button className="btn btn-primary"
                            onClick={this.showAddExceptionPopup}>
                        {this.props.translate("workflowDetails.exceptions.addNewException")}
                    </button>
                </div>
                {showAlert ?
                    <div className="alert alert-danger alert-nc4">
                        <span className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error" aria-hidden="true"/>
                        <p><strong>{this.props.translate("workflowDetails.exceptions.exceptionsAlertHeader")}</strong></p>
                        <p>{this.props.translate("workflowDetails.exceptions.exceptionsAlert")}</p>
                    </div> : null
                }

                { emptyExceptions && !this.state.loading ?
                    <div className="exceptions-list-empty">
                        <img className="no-exceptions-arrow" src={noExceptionsArrow} alt="No Exceptions Arrow"/>
                        <img src={noExceptionsUser} alt="No Exceptions User"/>
                        <h3>{this.props.translate("workflowDetails.exceptions.noExceptionsYet")}</h3>
                        <p>{this.props.translate("workflowDetails.exceptions.noExceptionsBody")}</p>
                    </div> : null}

                <ExceptionsList data={this.state.exceptions}
                                changeExceptions={this.changeExceptions}
                                translate={this.props.translate}
                                allowActions={this.props.userRoles.systemAdministrator}
                                saveException={this.saveException}
                                editException={this.editException}
                                deleteException={this.deleteException}
                                emptyExceptions={emptyExceptions}
                                addExceptionPopupVisible={this.state.addExceptionPopupVisible}
                                showAddExceptionPopup={this.showAddExceptionPopup}
                                hideAddExceptionPopup={this.hideAddExceptionPopup}
                                loading={this.state.loading}
                />
            </div>)
    }
}

const mapStateToProps = (store) => {
    return {
        roleNameExceptions: store.exceptions.items
    };
};

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps)(DefineExceptions);
const withTranslations = translate(connected);
export default withTranslations;