import Confirm from "components/popup/ConfirmActionPopup.component";
import UserCommentArea from "components/workflows/UserCommentArea.component";
import translate from "components/translations/translations.wrapper";
import { DOCUMENT_TYPE } from "utils/constants";
import { ActivateWorkflowPopupProps } from "types/activateWorkflowPopup";

export function ActivateWorkflowPopup(props: ActivateWorkflowPopupProps) {
    const computeActivateMessage = function() {
        if(props.activate && props.replaceActiveRuleName)
            return props.translate("workflowDetails.confirmDialog.activateMessageReplace", props.replaceActiveRuleName);
        if(props.activate)
            return props.translate("workflowDetails.confirmDialog.activateMessage", props.translate("documentTypes." + DOCUMENT_TYPE.asString(props.documentType)));
        else
            return props.translate("workflowDetails.confirmDialog.deactivateMessage", props.translate("documentTypes." + DOCUMENT_TYPE.asString(props.documentType)))
    }

    return (
        <Confirm translate={props.translate}
                 handleAction={props.onActivate}
                 closeCallback={() => {
                     props.onCancel();
                 }}
                 modalType={"modal-warning"}
                 confirmButtonText={props.activate ? props.translate("workflowDetails.confirmDialog.activateWorkflow")
                     : props.translate("workflowDetails.confirmDialog.deactivateWorkflow")}
                 cancelButtonText={props.translate("workflowDetails.confirmDialog.cancelActivate")}
                 title={props.activate ? props.translate("workflowDetails.confirmDialog.activateTitle", props.ruleName)
                     : props.translate("workflowDetails.confirmDialog.deactivateTitle", props.ruleName)}
                 moreContent={<UserCommentArea
                     message={computeActivateMessage()}
                     inputMessage={props.translate("workflowDetails.confirmDialog.describeChanges")}
                     moreInfo={props.translate("workflowDetails.confirmDialog.moreInfo")}
                     userInput={props.activateUserInputRef}
                 />}
        >
        </Confirm>
    );
}

const withTranslations = translate(ActivateWorkflowPopup);
export default withTranslations;