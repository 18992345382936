import {
    ADD_RULE_CONFIGURATION,
    REMOVE_RULE_CONFIGURATION,
    UPDATE_RULE_CONFIGURATION,
    WORKFLOWS_LOADED,
    WORKFLOWS_LOADING,
    REMOVE_ACTIVE_WORKFLOW,
    WORKFLOWS_AMOUNT_LIMITS_LOADED,
    WORKFLOWS_HISTORY_LOADING,
    WORKFLOWS_HISTORY_LOADED,
    CLEAR_WORKFLOWS_HISTORY,
    MORE_WORKFLOWS_HISTORY_LOADED, WORKFLOWS_PREVENT_ACTION,
} from "../store/actionTypes";

import _ from 'lodash';
import {LOADING_STATUS} from 'utils/constants';

export const DEFAULT_STATE = {
    activeWokflowId: null,
    activeWorkflow: null,
    docTypes: [],
    preventAction: false,
    loadingStatus: LOADING_STATUS.LOADING,
    workflows: [],
    configRoles: [],
    costUnits: [],
    organizationChart: [],
    workflowChanged: false,
    amountLimits: null,
    workflowHistory: null,
    historyLoadingStatus: LOADING_STATUS.LOADING,
    lastCreatedWorkflow: { },
};

export function getWorkflowsForRender(state) {
    if (!state.workflows || state.workflows.length === 0)
        return [];

    let groupedWorkflows = _.groupBy(state.workflows, rule => rule.documenttype);

    if(state.docTypes?.length > 0) {
        state.docTypes.forEach(docType => {
            if (!groupedWorkflows.hasOwnProperty(docType))
                groupedWorkflows[docType] = [];
        });
    }

    return groupedWorkflows;
}


export default function workflowsReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case ADD_RULE_CONFIGURATION:
            partialState = Object.assign([], state);
            partialState.workflows.push(action.data);
            partialState.lastCreatedWorkflow = {
                id: action.data.idRuleConfiguration,
                created: new Date().getTime(),
            }

            return Object.assign({}, state, partialState);


        case REMOVE_RULE_CONFIGURATION:
            partialState = Object.assign([], state);
            let workflows = partialState.workflows;
            let index = _.findIndex(workflows, (x => x.idRuleConfiguration === action.data));
            if (index !== -1) workflows.splice(index, 1);
            partialState.workflows = workflows;

            return Object.assign({}, state, partialState);

        case UPDATE_RULE_CONFIGURATION:
            partialState = Object.assign([], state);
            const workflowsList = partialState.workflows;
            const workflowIndex = _.findIndex(workflowsList, (x => x.idRuleConfiguration === action.data.idRuleConfiguration));
            partialState.workflows = [...workflowsList.slice(0,workflowIndex), action.data, ...workflowsList.slice(workflowIndex+1)];

            return Object.assign({}, state, partialState);


        case REMOVE_ACTIVE_WORKFLOW:
            partialState = Object.assign([], state);
            partialState.activeWokflowId = null;
            partialState.activeWorkflow = null;

            return Object.assign({}, state, partialState);

        case WORKFLOWS_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                workflows: []
            };
            return Object.assign({}, state, partialState);

        case WORKFLOWS_PREVENT_ACTION:
            partialState = Object.assign([], state);
            partialState.preventAction = action.data;

            return Object.assign({}, state, partialState);

        case WORKFLOWS_LOADED:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.DONE;
            partialState.workflows = action.data.workflows;
            partialState.docTypes = action.data.docTypes;
            return Object.assign({}, state, partialState);

        case WORKFLOWS_AMOUNT_LIMITS_LOADED:
            partialState = Object.assign([], state);
            partialState.amountLimits = action.data;

            return Object.assign({}, state, partialState);

        case WORKFLOWS_HISTORY_LOADING:
            partialState = {
                workflowHistory: null,
                historyLoadingStatus: LOADING_STATUS.LOADING
            };
            return Object.assign({}, state, partialState);

        case WORKFLOWS_HISTORY_LOADED:
            partialState = Object.assign([], state);
            partialState.workflowHistory = action.data;
            partialState.historyLoadingStatus = LOADING_STATUS.DONE

            return Object.assign({}, state, partialState);

        case MORE_WORKFLOWS_HISTORY_LOADED:
            let oldData = Object.assign({}, state.workflowHistory);
            let oldRows = Object.assign([], state.workflowHistory.rows);
            // concatenate old rows with new rows
            let allRows = oldRows.concat(action.data.rows);
            oldData.rows = allRows;
            oldData.page = action.data.page;

            partialState = {
                loadingStatus: LOADING_STATUS.DONE,
                workflowHistory: oldData
            };
            return Object.assign({}, state, partialState);

        case CLEAR_WORKFLOWS_HISTORY:
            partialState = {
                workflowHistory: null,
            };
            return Object.assign({}, state, partialState);
        default:
            return state;
    }
}

export function getDocTypes(state) {
    return state.docTypes;
}

export function getLastCreatedWorkflow(state) {
    return state.lastCreatedWorkflow;
}

export function getWorkflowsLoadingStatus(state) {
    return state.loadingStatus;
}

export function getWorkflows(state) {
    return state.workflows;
}

export function amountLimits(state) {
    return state.amountLimits;
}

export function getWorkflowHistory(state) {
    return state.workflowHistory;
}

export function getWorkflowHistoryLoadingStatus(state) {
    return state.historyLoadingStatus;
}

export function getPreventAction(state) {
    return state.preventAction;
}



