import {DOCUMENT_TYPE} from "utils/constants";
import React, {Component} from "react";
import arrowDown from "../../../assets/arrow-down-icon.png";

export class SimulatorHeader extends Component {

    render() {
        let {toggle, docType, collapsed} = this.props;
        return (
            <div className="step-header align-items-center pe-0 cursor-pointer"
                 onClick={toggle}>
                <span>
                    <span>
                        <strong className="text-blue">{this.props.translate("workflowDetails.simulator.header")}</strong>
                        <span className="vismaicon vismaicon-filled vismaicon-dynamic vismaicon-sm vismaicon-help simulator-icon ms-2"
                              title={this.props.translate("workflowDetails.simulator.headerInfo")}/>
                    </span>

                    <br/>
                    <span className="text-muted">
                        {this.props.translate("workflowDetails.simulator.headerSubtext", this.props.translate("documentType." + DOCUMENT_TYPE.asString(docType)))}
                    </span>

                    <img className={`me-3 arrow-icon float-right ${collapsed ? '' : 'right'}`}
                         src={arrowDown}
                         alt="arrow down"/>
                </span>
            </div>
        )

    }
}