import {TASK_OVERVIEW_FILTERS} from './constants';


export function prepareFilters(callObject) {
    let currentFilter = Object.assign({}, callObject);
    let filter = {};

    if (currentFilter) {
        Object.keys(currentFilter).forEach(key => {
            switch (key) {
                case TASK_OVERVIEW_FILTERS.COLUMNS: {
                    filter = Object.assign({}, filter, currentFilter[key]);
                    break;
                }
                case TASK_OVERVIEW_FILTERS.SORTING: {
                    filter[TASK_OVERVIEW_FILTERS.SORT_DIRECTION] = currentFilter[key].direction;
                    filter[TASK_OVERVIEW_FILTERS.SORT_INDEX] = currentFilter[key].index;
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }
    return filter;
}
