import React, {Component} from 'react';
import translate from '../translations/translations.wrapper.jsx';
import {connect} from "react-redux";
import {getEnabledFeaturesForDocumentType, setStepAction, getUsersList} from "./workflowdetails.action";
import {UserSearch} from "../input/UserSearch.component";
import {ApprovalStep4EyesHeader} from "./4eyesApprovalStepHeader.component"
import * as reduxSelectors from "../store/application.reducers";
import {getCostUnitsData} from "../costunits/costunits.action";
import {DOCUMENT_TYPE} from "../../utils/constants";

class ApprovalStep4Eyes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideContent: false,
            errorMessages: [],
            users: []
        };

        this.initialApproverRef = null;

        this.toggle = this.toggle.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.getUsersList(this.props.approvers).then((users) => {
            this.setState({
                users
            })
        });
    }

    toggle() {
        this.setState({
            hideContent: !this.state.hideContent
        });
    }

    onChange(items) {
        if (Array.isArray(items)) {
            items.forEach((item) => {
                if (!this.state.users.find((user) => user.userId === item.value)) {
                    this.setState({
                        users: this.state.users.concat([{idUser: item.value, fullName: item.label}])
                    });
                }
            });

            this.props.onChange(items.map((item) => item.value));
            return;
        }

        this.props.onChange([]);
    }

    render() {
        const value = this.props.approvers.map((id) => {
            const user = this.state.users.find((user) => user.idUser === id);

            if (user) {
                return {
                    value: id,
                    label: user.fullName
                }
            }

            return {
                value: id,
                label: ''
            }
        });

        return (
            <div className={"col-md-12 step approval-4-eyes " + (this.state.hideContent ? " collapsed" : "uncollapsed")}
                 ref="step">

                <ApprovalStep4EyesHeader
                    collapsed={this.state.hideContent}
                    toggle={this.toggle}
                    translate={this.props.translate}
                    docType={this.props.documentType}
                />

                <div className={"toggleable-content" + (this.state.hideContent ? " toggled" : "")}>
                    <div className="row padding-left-right-30">
                        <div className="column">
                            <div className="form-group margin-top">
                                <label className="form-label text-white">
                                    {this.props.translate("documentTypes." + DOCUMENT_TYPE.asString(this.props.documentType))} {this.props.translate("workflowDetails.4EyesApproval.approvedBy")}
                                </label>
                                <UserSearch value={value}
                                            multi={true}
                                            clearable={true}
                                            propagateValue={this.onChange}
                                            userConfiguration={{}}
                                            translate={this.props.translate}
                                />
                                <p className="text-white margin-top-10">
                                    {this.props.approvers.length < 2 ?
                                        this.props.translate("workflowDetails.4EyesApproval.twoApprovers") :
                                        <span>&nbsp;</span>
                                    }
                                    {this.props.approvers.length > 10 ?
                                        this.props.translate("workflowDetails.4EyesApproval.tenApprovers") :
                                        <span>&nbsp;</span>
                                    }


                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const withTranslations = translate(ApprovalStep4Eyes);

const mapStateToProps = function (store) {
    return {
        features: store.workflowDetails.features,
        selectedCostUnitTypes: reduxSelectors.getSeletedCostUnitTypes(store),
    };
};

const connected = connect(mapStateToProps, {
    setStepAction,
    getUsersList,
    getEnabledFeaturesForDocumentType,
    getCostUnitsData
})(withTranslations);
export default connected;
