import {useRef, useState} from "react";


type OperatorType = {
    row: number;
    column: number;
    value: string;
}

export function Operator(props: OperatorType) {
    const [showDropDown, setShowDropDown] = useState<string>("dropdown-menu");
    const [style, setStyle] = useState<number>(0);
    const dropdown = useRef<HTMLButtonElement>(null);

    const handleShow = function () {
        if (showDropDown.includes("show"))
            setShowDropDown("dropdown-menu");
        else {
            setShowDropDown("dropdown-menu show");
            if (dropdown && dropdown.current) {
                setStyle((props.row + 1) * 100);
            }
        }
    }


    return (<div className="dropdown">
            <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" id="dropdownButton1" onClick={handleShow} ref={dropdown}
                    onBlur={handleShow}>
                {props.value}
            </button>
            <ul className={showDropDown} role="menu" aria-hidden="true"
                style={{top: style}}
                aria-labelledby="dropdownButton1">
                <li><a href="#">Any</a></li>
                <li><a href="#">Is</a></li>
                <li><a href="#">Something else here</a></li>
                <li className="divider"></li>
                <li><a href="#">Separated link</a></li>
            </ul>
        </div>
    )
}