import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {connect} from 'react-redux';
import {savePreference} from './splitButton.action';
import {getSplitButtonPreference} from '../store/application.reducers';
import {getConcreteSplitButtonPreference} from './splitButton.reducer';
import {TASK_ACTIONS} from "utils/constants.js";

/**
 * see the proptypes below the class
 * @see https://confluence.visma.com/display/VUX/Split+buttons
 */
export class SplitButtonWithState extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showList: false // whether dropdown is opened
        };
        this.bigButtonClick = this.bigButtonClick.bind(this);
        this.getSelectedAction = this.getSelectedAction.bind(this);
        this.onActionSelected = this.onActionSelected.bind(this);
        this.getSavedPreference = this.getSavedPreference.bind(this);
        this.openMenuList = this.openMenuList.bind(this);
    }

    getSavedPreference() {
        return getConcreteSplitButtonPreference(this.props.saved, this.props.id);
    }

    componentDidMount() {
        if (this.props.isDetails) {
            this.props.savePreference(this.props.id, undefined);
        } else {
            this.props.savePreference(this.props.id, TASK_ACTIONS.OPEN);
        }
    }

    onActionSelected(actionKey) {

        const action = this.getSelectedAction(actionKey);
        /*
         we need to focus some input on modal opening
         so we have to delay execution of action a bit, because #onSelect from split button
         gets executed after this action, which would change focus back to split button
         so we want to execute action after split button finishes it's inner logic
         */
        setTimeout(action.onClick, 50);
    }

    bigButtonClick() {
        if (this.props.defaultAction)
            this.props.defaultAction();
    }

    openMenuList() {
        this.setState({showList: !this.state.showList});
    }

    getSelectedAction(needle) {
        const found = this.props.actions.filter((action) => {
            return action.type === needle;
        });
        return found.length > 0 ? found[0] : undefined;
    }

    render() {

        const action = this.getSelectedAction(this.getSavedPreference());
        const mainTitle = action ? action.label : this.props.initialLabel;

        // if all actions disabled, disable dropup button
        const dropUpDisabled = !this.props.actions.some(item => item.active);
        // if selected action disabled, disable big button .. no action ~> 'open dropdown', so available
        const bigButtonDisabled = action && !action.active;

        const self = this;

        return (
            <DropdownButton
                title={mainTitle}
                id={this.props.id}
                bsPrefix="btn btn-default btn-default-primary no-caret"
                className={this.props.bsClass ? this.props.bsClass + " " : " btn btn-default-primary"}
                disabled={bigButtonDisabled || dropUpDisabled}>
                {this.props.actions.map(function (action) {
                    return (
                        <Dropdown.Item key={action.type}
                                       eventKey={action.type}
                                       title = {action.tooltip}
                                       className={action.active ? "" : "disabled"}
                                       data-cy={action.type}
                                       onClick={(e) => self.onActionSelected(action.type)}
                        >
                            {action.label}
                            <span className="float-right"> {action.shortKey}</span>

                        </Dropdown.Item>
                    );
                })}

            </DropdownButton>


        )
    }
}


SplitButtonWithState.propTypes = {
    id: PropTypes.string, // identifier of instance, under which it saves it's preference into store
    initialLabel: PropTypes.string, // initial title for the 'big' button, before it adopts one from the actions
    bsClass: PropTypes.string, // css classes for button(group)
    actions: PropTypes.arrayOf( // array of actions, they make the list with options
        PropTypes.shape({
            type: PropTypes.string, // identifier of the action
            onClick: PropTypes.func, // function to be called upon action selection
            active: PropTypes.bool, // if this action is enabled
            label: PropTypes.string // text label for dropdown and main button
        })
    ),
    btnBsClass: PropTypes.string
};

// SplitButtonWithState.defaultProps = {
//     id: 'myTasksSplitButton',
//     bsClass: 'btn-group btn-split dropup'
// };

const mapStateToProps = function (store) {
    return {
        saved: getSplitButtonPreference(store)
    };
};
const connected = connect(mapStateToProps, {savePreference})(SplitButtonWithState);
export default connected;
