export const PATH_TO_MY_TASKS="/";

export const PATH_TO_TASK_DETAILS="/task/";

export const PATH_TO_MY_HISTORY="/history";

export const PATH_TO_HISTORY_DETAILS="/taskhistory/";

export const PATH_TO_PROCESS_OVERVIEW="/processoverview";

export const PATH_TO_TASK_OVERVIEW="/taskoverview";

export const PATH_TO_DOCUMENT_OVERVIEW="/documentoverview";


export  const PATH_TO_PROCESS_TASK_DETAIL="/processtask/";

export const PATH_TO_DOCUMENT_OVERVIEW_DETAILS="/document/";

export const PATH_TO_WORKFLOWS="/configuration/workflows";

export const PATH_TO_WORKFLOW_DETAILS="/configuration/workflows/workflow/";