import React, {Component} from 'react';
import ContextSelectorBox from './ContextSelectorBox.component';
import * as OdpApi from '../../utils/api/odp.api';

export class MenuUserBox extends Component {

    constructor(props) {
        super(props);
        this.state = {userBoxOpen: false};
        this.onBlurUserBox = this.onBlurUserBox.bind(this);
        this.onSelection = this.onSelection.bind(this);
    }

    onBlurUserBox(e) {
        // without check for currentTarget/activeElement we would also unBlur for clicks inside dropdown
        // inspiration from here: https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
        // also, we have to add tabIndex to the component, to be focusable, otherwise document.activeElement would be 'body'
        let self = this;
        let currentTarget = e.currentTarget;
        e.stopPropagation();
        setTimeout(function () {
            if (!currentTarget.contains(document.activeElement)) {
                self.setState({userBoxOpen: 0})
            }
        }, 0);
    }

    /**
     * this will be executed from children, when user change the context, we need to execute redux action and collapse userbox
     * @param newContext
     */
    onSelection(newContext) {
        // collapse selector
        this.setState({userBoxOpen: false});

        // call redux action mounted in parent
        // TODO returns promise, add error/positive handling?
        this.props.switchUserContext(newContext);
    }

    render() {
        let userIcon = "dropdown user-dropdown";
        if (this.state.userBoxOpen)
            userIcon += " open";
        if (this.props.collapsed)
            userIcon += " icon";
        // unfortunately styles expect li>.dropdown-toggle so we cannot move <li> to parent component
        return (
            // TODO icon only if collapsed style
            <li className={userIcon}
                onBlur={this.onBlurUserBox}>

                <a className="dropdown-toggle button-context no-caret" role="button"
                   href={"#"}
                   aria-expanded={this.state.userBoxOpen}
                   label={this.props.loggedInData.firstName + this.props.loggedInData.lastName}
                   data-cy={this.props.loggedInData.firstName + this.props.loggedInData.lastName}
                   onClick={() => this.setState({userBoxOpen: !this.state.userBoxOpen})}>
                    {this.props.loggedInData.firstName + " " + this.props.loggedInData.lastName}
                    <span className="vismaicon vismaicon-menu vismaicon-dynamic vismaicon-user"/>
                    <small className="selectedContext">{this.props.userData.companyName}</small>
                    <span className="caret"></span>
                </a>
                <ul className="dropdown-menu company-selection border-0" role="menu">
                    <li className="user-details-area clear" role="menuitem">
                        <div title={this.props.loggedInData.emailAddress}>
                            <div className="vismaicon-user-placeholder vismaicon py-4"
                                 alt={this.props.loggedInData.firstName + " " + this.props.loggedInData.lastName}
                            />
                            <div className="user-text">
                                <span>{this.props.loggedInData.firstName} {this.props.loggedInData.lastName}</span>
                                <span
                                    className="text-disabled">{this.props.loggedInData.emailAddress}</span>
                            </div>
                        </div>
                    </li>

                    <li className="divider"/>

                    <li className="company-selection-area" role="menuitem">
                        <ContextSelectorBox
                            currentContextId={this.props.currentContextId}
                            onSelection={this.onSelection}
                            parentExpanded={this.state.userBoxOpen}
                            translate={this.props.translate}
                            userData={this.props.userData}
                        />
                    </li>

                    <li className="divider"/>
                    <li className="px-3">
                        <a className="btn btn-default pull-right"
                           href={OdpApi.myDetailsUrl()}>{this.props.translate('menu.userBox.myDetails')}</a>
                    </li>
                    <li className="divider divider-strong"/>
                    <li className="context-footer company-selection-footer clear" role="menuitem">
                        <a className="log-out-link vismaicon vismaicon-sm vismaicon-dynamic vismaicon-logout"
                           id="logout_btn"
                           href={"#"}
                           onMouseDown={this.props.logoutUser}>{this.props.translate("menu.userBox.logout")}</a>
                    </li>
                </ul>
            </li>
        );
    }
}

export default MenuUserBox;