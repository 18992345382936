import React, {Component} from 'react';
import {removeSeachParamsFromUrl} from '../router/router.function.js';
import {getApprovalURL} from "utils/getVersion.function";
import {withRouter} from "components/router/withRouter";

export class PreApproval extends Component {
    constructor() {
        super();
        this.state = {
            showPopUp: false
        };
    }

    componentDidMount() {
        const approvalDomain = getApprovalURL();

        //search for batch response
        if (this.props.location && this.props.location.search) {
            let params = new URLSearchParams(this.props.location.search);
            let batchId = params.get('batchId');
            let batchAction = params.get('batchAction');
            let secret = params.get('secret');
            //remove search params from the url
            removeSeachParamsFromUrl();

            window.parent.postMessage({
                data: "fromPreApproval",
                batchId: batchId,
                batchAction: batchAction,
                secret: secret
            }, approvalDomain);
        }
    }

    render() {
        return (
            <div/>
        )
    }

}

export default withRouter(PreApproval);