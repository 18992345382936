import {
    ALL_COST_UNITS_LOADED,
    CONFIG_ROLES_LOADED,
    ORGANIZATION_CHART_LOADED,
    REMOVE_ACTIVE_WORKFLOW,
    RENAME_WORKFLOW_RULE,
    REMOVE_STEP,
    SET_STEP_SETTINGS,
    SET_STEP_CHANGES,
    SET_RULE_TYPE,
    SET_ACTIVE_WORKFLOW,
    SET_ACTION,
    SORT_WORKFLOW_STEPS,
    SET_STEP_EXCEPTIONS,
    STEP_USERS_LOADED,
    SET_STEP_RULE,
    ENABLED_FEATURES_FOR_DOCUMENT_TYPE_LOADED,
    SET_STEP_RULE_CONDITION, SET_4_EYES_ENABLED, SET_4_EYES_FALLBACK_USERS, SET_STEP_RULE_CONDITION_BY_NUMBER,
    OLD_WORKFLOW_DETAILS_LOADED
} from '../store/actionTypes';
import {RULE_TYPES} from 'utils/constants';
import * as _ from 'lodash';


export const DEFAULT_STATE = {
    activeWokflowId: null,
    activeWorkflow: null,
    configRoles: [],
    costUnits: [],
    organizationChart: [],
    workflowChanged: false,
    usersLists: [],
    features: {},
    hasUserChanges: false,
    oldWorkflowDetails: null
};


export default function workflowDetailsReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case ALL_COST_UNITS_LOADED:
            partialState = Object.assign([], state);
            partialState.costUnits = action.data;

            return Object.assign({}, state, partialState);

        case CONFIG_ROLES_LOADED:
            partialState = Object.assign([], state);
            partialState.configRoles = action.data;

            return Object.assign({}, state, partialState);

        case ORGANIZATION_CHART_LOADED:
            partialState = Object.assign([], state);
            partialState.organizationChart = action.data;

            return Object.assign({}, state, partialState);


        case SET_ACTIVE_WORKFLOW:
            partialState = Object.assign([], state);
            partialState.activeWokflowId = action.data.idRuleConfiguration;

            action.data.steps = _.orderBy(action.data.steps, 'stepNumber', 'asc');
            partialState.usersLists = [];
            partialState.activeWorkflow = _.cloneDeep(action.data);
            partialState.workflowChanged = false;
            partialState.hasUserChanges = false;

            return Object.assign({}, state, partialState);

        case REMOVE_ACTIVE_WORKFLOW:
            partialState = Object.assign([], state);
            partialState.usersLists = [];
            partialState.activeWokflowId = null;
            partialState.activeWorkflow = null;

            return Object.assign({}, state, partialState);

        case SET_4_EYES_ENABLED:
            partialState = Object.assign([], state);

            partialState.activeWorkflow = _.cloneDeep(partialState.activeWorkflow);
            partialState.activeWorkflow.twoPersonsRule.enabled = action.data;
            partialState.workflowChanged = true;
            partialState.hasUserChanges = true;

            return Object.assign({}, state, partialState);

        case SET_4_EYES_FALLBACK_USERS:
            partialState = Object.assign([], state);

            partialState.activeWorkflow = _.cloneDeep(partialState.activeWorkflow);
            partialState.activeWorkflow.twoPersonsRule.fallbackUsers = action.data;
            partialState.workflowChanged = action.data.length >= 2 && action.data.length <= 10;

            return Object.assign({}, state, partialState);

        case REMOVE_STEP: {
            let partialState = Object.assign([], state);
            let activeWorkflow = Object.assign({}, partialState.activeWorkflow);

            let stepToRemove = _.findIndex(activeWorkflow.steps, (st => st.idStep === action.data));

            if (stepToRemove !== -1)
                activeWorkflow.steps.splice(stepToRemove, 1);

            partialState.activeWorkflow = activeWorkflow;
            partialState.workflowChanged = true;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);
        }

        case STEP_USERS_LOADED: {
            let partialState = Object.assign([], state);

            partialState.usersLists.push(action.data);
            return Object.assign({}, state, partialState);
        }

        case SET_STEP_EXCEPTIONS: {
            let partialState = Object.assign([], state);
            let exceptionsToChange = _.findIndex(partialState.usersLists, (st => st.stepId === action.stepId));


            if (exceptionsToChange !== -1) {
                partialState.usersLists[exceptionsToChange].exceptions = action.data;
            }
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);
        }

        case RENAME_WORKFLOW_RULE: {
            partialState = Object.assign([], state);
            let prevActiveWorkflow = Object.assign({}, partialState.activeWorkflow);

            // the workflow name has to be between 1 and 80 characters
            let newName = action.data;
            if (newName.length > 80)
                newName = newName.substr(0, 80);

            prevActiveWorkflow.name = newName;
            partialState.hasUserChanges = true;
            partialState.activeWorkflow = prevActiveWorkflow;
            if (newName) {
                partialState.workflowChanged = true;
            }

            return Object.assign({}, state, partialState);

        }

        case SET_ACTION:
            partialState = Object.assign([], state);
            let prevActiveWorkflow = Object.assign({}, partialState.activeWorkflow);

            let stepIndex = -1;
            let ruleIndex = -1;
            let automaticApprovalChanged = false;

            if (action.stepId)
                stepIndex = _.findIndex(prevActiveWorkflow.steps, (step => {
                        return step.idStep === action.stepId
                    }
                ));
            else {
                stepIndex = _.findIndex(prevActiveWorkflow.steps, (step => {
                        return step.stepNumber === action.stepNumber
                    }
                ));
            }

            if (stepIndex !== -1) {
                if (action.ruleId)
                    ruleIndex = _.findIndex(prevActiveWorkflow.steps[stepIndex].rules, (rule => {
                            return rule.idRule === action.ruleId
                        }
                    ));
                else {
                    ruleIndex = _.findIndex(prevActiveWorkflow.steps[stepIndex].rules, (rule => {
                            return rule.ruleNumber === action.ruleNumber
                        }
                    ));
                }

                if (ruleIndex !== -1) {
                    automaticApprovalChanged = (prevActiveWorkflow.steps[stepIndex].rules[ruleIndex].action || {}).automaticApprovalUsers !== action.rule.automaticApprovalUsers;

                    prevActiveWorkflow.steps[stepIndex].rules[ruleIndex].action = action.rule;
                    prevActiveWorkflow.steps[stepIndex].rules[ruleIndex].name = RULE_TYPES[action.rule.xtype].key;
                }
            }

            partialState.activeWorkflow = prevActiveWorkflow;
            partialState.workflowChanged = action.isComplete || automaticApprovalChanged;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);

        case SET_STEP_RULE: {
            partialState = Object.assign([], state);
            let workflow = Object.assign({}, partialState.activeWorkflow);

            let stepIndex = _.findIndex(workflow.steps, (step => {
                    return step.idStep === action.stepId
                }
            ));

            if (stepIndex !== -1) {
                let ruleIndex = _.findIndex(workflow.steps[stepIndex].rules, (rule => {
                        return rule.idRule === action.ruleId
                    }
                ));
                if (ruleIndex !== -1) {
                    workflow.steps[stepIndex].rules[ruleIndex].name = RULE_TYPES[action.rule.xtype].key;
                }
            }

            partialState.activeWorkflow = workflow;
            partialState.workflowChanged = false;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);

        }

        case SET_STEP_RULE_CONDITION: {
            partialState = Object.assign([], state);
            let workflow = Object.assign({}, partialState.activeWorkflow);

            let stepIndex = _.findIndex(workflow.steps, (step => {
                    return step.idStep === action.stepId
                }
            ));

            if (stepIndex !== -1) {
                let ruleIndex = _.findIndex(workflow.steps[stepIndex].rules, (rule => {
                        return rule.idRule === action.ruleId
                    }
                ));
                if (ruleIndex !== -1) {
                    if (action.condition !== null)
                        workflow.steps[stepIndex].rules[ruleIndex].condition = action.condition;
                    else
                        delete workflow.steps[stepIndex].rules[ruleIndex].condition;
                }
            }

            partialState.activeWorkflow = workflow;
            partialState.workflowChanged = action.isComplete;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);
        }

        case SET_STEP_RULE_CONDITION_BY_NUMBER: {
            partialState = Object.assign([], state);
            let workflow = Object.assign({}, partialState.activeWorkflow);

            let stepIndex = _.findIndex(workflow.steps, (step => {
                    return step.stepNumber === action.stepNumber
                }
            ));

            if (stepIndex !== -1) {
                let ruleIndex = _.findIndex(workflow.steps[stepIndex].rules, (rule => {
                        return rule.ruleNumber === action.ruleNumber
                    }
                ));
                if (ruleIndex !== -1) {
                    if (action.condition !== null)
                        workflow.steps[stepIndex].rules[ruleIndex].condition = action.condition;
                    else
                        delete workflow.steps[stepIndex].rules[ruleIndex].condition;
                }
            }

            partialState.activeWorkflow = workflow;
            partialState.workflowChanged = action.isComplete;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);
        }


        case SET_STEP_SETTINGS: {
            let partialState = Object.assign([], state);
            let activeWorkflow = Object.assign({}, partialState.activeWorkflow);

            let changedStep = _.findIndex(activeWorkflow.steps, (st => st.idStep === action.step.idStep));

            if (changedStep !== -1) {
                activeWorkflow.steps[changedStep] = action.step;
            }
            partialState.activeWorkflow = activeWorkflow;
            partialState.workflowChanged = action.isComplete;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);
        }

        case SET_STEP_CHANGES: {
            let partialState = Object.assign([], state);
            partialState.activeWorkflow = action.workflow;
            partialState.workflowChanged = true;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);
        }

        case SET_RULE_TYPE: {
            let partialState = Object.assign([], state);
            partialState.activeWorkflow = action.workflow;
            partialState.workflowChanged = true;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);
        }

        case SORT_WORKFLOW_STEPS:
            partialState = Object.assign([], state);
            let activeWorkflowIndex = _.findIndex(partialState.workflows, (workflow => {
                    return workflow.idRuleConfiguration === action.workflowId
                }
            ));

            partialState.workflows[activeWorkflowIndex].steps = action.data;
            partialState.hasUserChanges = true;
            return Object.assign({}, state, partialState);

        case ENABLED_FEATURES_FOR_DOCUMENT_TYPE_LOADED:
            partialState = {
                features: {
                    ...state.features,
                    [action.documentType]: action.features
                }
            };
            return Object.assign({}, state, partialState);

        case OLD_WORKFLOW_DETAILS_LOADED:
            partialState = Object.assign([], state);
            partialState.oldWorkflowDetails = action.data;

            return Object.assign({}, state, partialState);


        default:
            return state;
    }
}

export function getConfigRoles(state) {
    return state.configRoles;
}

export function getCostUnits(state) {
    return state.costUnits;
}

export function getOrganizationChart(state) {
    return state.organizationChart;
}

export function getActiveWorkflow(state) {
    return state.activeWorkflow ? state.activeWorkflow : null;
}

export function getWorkflowChangedStatus(state) {
    return state.workflowChanged;
}

export function getExceptionUsers(state) {
    return state.usersLists;
}

export function getChangesDoneByUser(state) {
    return state.hasUserChanges;
}

export function getOldWorkflowDetails(state) {
    return state.oldWorkflowDetails;
}
