import { navigateToWorkflows } from "../router/router.function.js";
import React, { useEffect } from "react";
import * as reduxSelectors from "../store/application.reducers";
import { connect } from "react-redux";
import {
    getCostUnits,
    getOldWorkflowJSON,
    getOrganizationChart,
    getRuleConfigurationRoles,
    removeActiveWorkflow,
    removeStep,
    renameRule,
    saveRule,
    set4EyesEnabled,
    set4EyesFallbackUsers,
    setActiveWorkflow,
    setStepChange,
    setStepSettings,
    sortSteps,
} from "./workflowdetails.action";
import translate from "../translations/translations.wrapper.jsx";
import { formatDocumentType } from "utils/valueFormatter.function";
import { DOCUMENT_TYPE } from "utils/constants";
import {withRouter} from "components/router/withRouter.tsx";
import {Link} from "react-router-dom";
import back from "assets/left-arrow.svg";
import {PATH_TO_WORKFLOWS} from "components/router/paths.js";

export function WorkflowDetailsHistory(props) {
    useEffect(() => {
        props.getOldWorkflowJSON(props.match.params.workflowId, props.match.params.version);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props.workflow) {

        return (
            <div id="Workflow-details">
                <div
                    className="title-area workflows-container">
                    <div
                        className="row px-40 workflows-center-align">
                        <Link to={PATH_TO_WORKFLOWS} className="col-md-1 d-flex align-items-center mx-0">
                                 <span className="appicon vismaicon-arrow-left"
                                 />
                        </Link>
                        <div className="col-md-5 d-flex align-items-center px-0">
                        <input type="text"
                                   className="disabled"
                                   value={props.workflow?.description || ""}
                            />
                        </div>
                        <div
                            className="d-flex col-md-6 justify-content-end px-0">
                            <div className="d-flex align-items-center"
                                 id="workflow-header-slider">
                                <label className="switch switch-label m-0">
                                    <input type="checkbox"
                                           className="disabled"
                                           checked={true} />
                                    <span className={"togglemark " + (props.workflow?.active ? "" : " disabled")} />
                                </label>
                                <span className="margin-left-8">
                                        {props.translate("workflowDetails.active")}
                                    </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="workflows-container workflow-details-scroll">
                    <div className="px-40 workflows-center-align">
                        <div className="row mb-4 mt-5 mx-0">
                            <div
                                className="col-md-2 document-type-title text-disabled d-flex align-items-center px-0">
                                    <span
                                        className={"mr-8 appicon appicon-block document-type-icon-24 " + formatDocumentType(props.workflow.documentType) + "-24"} />
                                {props.translate("documentType." + DOCUMENT_TYPE.asString(props.workflow.documentType))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <span className={"appicon loading"} />;
}

const withTranslations = translate(WorkflowDetailsHistory);

const WorkflowDetailsHistoryWithLocation = withRouter(withTranslations);
const mapStateToProps = function(store) {
    return {
        workflow: reduxSelectors.getOldWorkflowDetails(store),
        userRoles: reduxSelectors.getUsersRoles(store),
        usersBusinessFeatures: reduxSelectors.getUsersCurrentBusinessFeatures(store),
    };
};

const connected = connect(mapStateToProps, {
    getRuleConfigurationRoles,
    getCostUnits,
    getOrganizationChart,
    setActiveWorkflow,
    sortSteps,
    setStepSettings,
    setStepChange,
    set4EyesEnabled,
    set4EyesFallbackUsers,
    removeActiveWorkflow,
    saveRule,
    renameRule,
    removeStep,
    getOldWorkflowJSON,
})(WorkflowDetailsHistoryWithLocation);

export default connected;