import React from 'react';
import {TASK_ACTIONS} from 'utils/constants';
import ReassignPopup from '../popup/ReassignPopup.component';
import {connect} from 'react-redux';
import {actionFinished} from '../mytasks/action/taskAction.action';
import {getOverviewTasks, getActionParameters} from '../store/application.reducers';
import {showNotification} from '../notifications/notification.action';
import * as logger from 'utils/logger.function';


/**
 * asynchronous part of the task action - showing the dialog, which will execute the action
 * synchronous part of action is inside redux action
 */
export const AsyncTaskActionExecutor = (props) => {

    // nothing to show
    if (!props.actionParameters.type) {
        return null;
    }

    let taskList = props.tasks && props.tasks.rows ? props.tasks.rows.map((task) => task) : [];

    const tasksForAction = taskList
        .filter((task) => { // find those matching props.taskIds
            return props.actionParameters.taskIds.some((taskKeyWanted) => {
                return taskKeyWanted === task.key;
            });
        });

    /**
     * sometimes, when user has dialog opened and navigates away for some time or changes context
     * the keys for task/history details expire, which then results in empty tasks for the popup
     * so detect this situation and rather hide the popup than check for invalid props inside each popup
     */
    if (tasksForAction.length === 0) {
        logger.error("AsyncTaskActionExecutor", "no task to be shown for these keys: " + props.actionParameters.taskIds);
        props.actionFinished();
        return null;
    }

    const handleCloseCallback=()=>{
        props.actionFinished();
        props.reloadTasks();
    }

    const popupProps = {
        closeCallback: handleCloseCallback,
        tasks: tasksForAction,
        showNotification: props.showNotification,
        taskDetails: props.taskDetails,
        skipComments: true
    };


    if (props.actionParameters.type === TASK_ACTIONS.REASSIGN) {
        return <ReassignPopup {...popupProps} />;
    } else {
        console.error('unmapped action type: ' + props.actionParameters.type);
        return <span/>;
    }
}


const mapStateToProps = function (store) {
    return {
        tasks: getOverviewTasks(store),
        actionParameters: getActionParameters(store),
    };
};

const connected = connect(mapStateToProps, {
    actionFinished,
    showNotification
})(AsyncTaskActionExecutor);


export default connected;