import React from 'react';

export const LinkToFeedBack = ({translate}) => {
    return (
        <li className="icon" title={translate("menu.feedback")}>
            <a className="button-help"  target="tab" href="https://goo.gl/forms/h4f3QHVawDx2CAXm2">
                    <span className="appicon vismaicon-menu vismaicon-dynamic feedback">
                    </span>
            </a>
        </li>
    );
}

export default LinkToFeedBack;