import React, {Component} from 'react';
import Select from 'react-select';
import * as api from "../../../utils/api/api";

export class SimulatorBranches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            branches: [],
        };

        this.fieldRef = null;
    }


    componentDidMount() {
        this.getBranches();
    }

    getBranches(){
        api.getBranches().then((response) => {
            if(response){
                this.setState({
                    branches: response.filter((branch) => {
                        return branch.active && !branch.deleted
                    }).map((branch) => {
                        return {
                            value: branch.branchId,
                            label: `${branch.name} (${branch.branchCd})`
                        }
                    })
                })
            }
        })
    }

    render() {
        return (
            <div className="form-group margin-top" >
                <label className="form-label">{this.props.translate("workflowDetails.simulator.branch")}</label>
                <Select options={this.state.branches}
                        ref={(field) => this.fieldRef=field}
                        defaultValue={this.props.branch}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.props.onChange}
                        name="branch"
                />
            </div>
        );
    }
}

