import React, {Component} from "react";
import * as _ from "lodash";
import {EditDeleteButtons} from "utils/listActionButtons.component";
import {EditExceptionPopup} from "./EditExceptionPopup.component";
import Confirm from "../../popup/ConfirmActionPopup.component";

class ExceptionsRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusFirst: false,
            approver: props.data.users,
            editExceptionPopupVisible: false,
            deleteExceptionPopupVisible: false
        };

        this.delete = this.delete.bind(this);
        this.save = this.save.bind(this);
        this.setApprover = this.setApprover.bind(this);
        this.setRequester = this.setRequester.bind(this);
        this.showEditExceptionPopup = this.showEditExceptionPopup.bind(this);
        this.hideEditExceptionPopup = this.hideEditExceptionPopup.bind(this);
        this.showDeleteExceptionPopup = this.showDeleteExceptionPopup.bind(this);
        this.hideDeleteExceptionPopup = this.hideDeleteExceptionPopup.bind(this);
    }

    delete() {
        this.props.deleteRow(this.props.index);
    }

    save(data) {
        let row = _.cloneDeep(this.props.data);
        row.users = data.users;

        this.hideEditExceptionPopup();
        this.props.changeRow(this.props.index, row);

        setTimeout(() => this.props.save(this.props.index, this.props.data.id));
    }


    setApprover(approver) {
        let row = _.cloneDeep(this.props.data);
        row.users = approver;

        this.setState({
            approver
        });
    }

    setRequester(requester) {
        let row = _.cloneDeep(this.props.data);
        row.valid = requester.valid;
        row.value = requester.value;
        row.label = requester.label;

        this.props.changeRow(this.props.index, row);
    }

    showEditExceptionPopup() {
        this.setState({
            editExceptionPopupVisible: true
        });
    }

    hideEditExceptionPopup() {
        this.setState({
            editExceptionPopupVisible: false
        });
    }

    showDeleteExceptionPopup() {
        this.setState({
            deleteExceptionPopupVisible: true
        });
    }

    hideDeleteExceptionPopup() {
        this.setState({
            deleteExceptionPopupVisible: false
        });
    }

    render() {
        let requester = {
            valid: this.props.data.valid,
            label: this.props.data.label,
            value: this.props.data.value
        };
        let role = this.props.data.role ? {label: this.props.data.role.label, value: this.props.data.role.value} : null;
        let approversList = this.props.data.users.length > 0 ? this.props.data.users.map((app, index) => {
            return (<span key={app.label + "+" + app.value}>
                <span className={app.valid === false ? "text-danger" : ""}>{app.label}</span>
                {(index !== this.props.data.users.length - 1) && <span>, </span>}
            </span>);
        }) : [];
        const approverListInvalid = this.props.data.users.find((user) => {
            return user.valid === false
        });
        return (
            <div className="row exceptions-row mx-0 align-items-center">
                <div className={"col-md-3" + (requester.valid === false ? " text-danger user-not-valid" : "")}>
                    {requester &&
                        <span>{requester.label}</span>
                    }
                </div>

                <div className="col-md-2">
                    {role &&
                        <span>{role.label}</span>
                    }
                </div>

                <div className={"col-md-6" + (approverListInvalid ? " user-not-valid" : "")}>
                    {approversList}
                </div>
                <div className="col-md-1 exceptions-row-actions d-flex justify-content-end align-items-center">
                    {this.state.editExceptionPopupVisible ?
                        <EditExceptionPopup role={role}
                                            requester={requester}
                                            approver={this.props.data.users}
                                            translate={this.props.translate}
                                            noDuplicates={this.props.noDuplicates}
                                            closeCallback={this.hideEditExceptionPopup}
                                            handleAction={this.save}/>
                        : null
                    }
                    {this.state.deleteExceptionPopupVisible ?
                        <Confirm translate={this.props.translate}
                                 message={this.props.translate("projects.confirmDelete")}
                                 handleAction={this.delete}
                                 closeCallback={this.hideDeleteExceptionPopup}/>
                        : null
                    }

                    <EditDeleteButtons
                        disabled={this.props.disabled}
                        editCallback={this.showEditExceptionPopup}
                        deleteCallback={this.showDeleteExceptionPopup}
                        translate={this.props.translate}
                        small={true}
                    />
                </div>
            </div>
        )
    }
}

export default ExceptionsRow;