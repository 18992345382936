/**
 * custom error, to be thrown from the application
 *
 * jva: I was unable to write toString in ES6 syntax for error child, but you can access .detail from the error as usual
 */
export default class ApprovalError extends Error {
    constructor(message, status = null, shouldRedirect = false, detail = null) {
        super(message);
        this.name = 'ApprovalError';
        this.message = message;
        this.status = status;
        this.detail = detail;
        this.shouldRedirect = shouldRedirect;
    }
}

export class AutopayError extends Error {
    constructor(message, httpStatus = 400, errorCode, fieldErrors) {
        super(message);
        this.httpStatus = httpStatus;
        this.status = httpStatus;
        this.errorMessages="error";
        this.errorCode = errorCode;
        this.errorTitle = "Incorrect request";
        this.shouldRedirect = false;
        this.fieldErrors = fieldErrors;
    }
}


