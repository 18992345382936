import {pushErrorToAppDynamics} from './appDynamics.function'

export function debug() {

    // this variable should be completely removed by uglifyjs in production
    const __DEV__ = process.env.NODE_ENV !== 'production';
    // eslint-disable-next-line
    if (__DEV__ || ENVIRONMENT.DEBUG) {
        console.log(...arguments);
    }

}

const logHelpers = {
    debug: debug,
    error: error
};

export default logHelpers;

/**
 * general error reporting function .. this will log error into AppDynamics too
 * @param context string denoting the context of the error
 * @param msg
 */
export function error(context, msg) {
    console.error(...arguments);
    // AC-3202 NC3 - error logging in stage/production
    pushErrorToAppDynamics(context + " " + msg);
}

/**
 * this is used only by errors from API calls
 * @param requestedHeaders headers sent with request
 * @param backendResponse response from the backend, contains requested url
 * @param backendResponseData response detail, if any
 */
export function apiError(requestedHeaders, backendResponse, backendResponseData) {
    // AC-3202 NC3 - error logging in stage/production
    error(backendResponse.url, backendResponse.statusText, ...arguments);
}

