import {
    TASK_ACTION_ASYNC,
    TASK_ACTION_FINISHED,
    LOGGED_OUT_TYPE,
    DIFFERENT_USER,
    UPDATE_ONE_HANDLE_ONLY_FLAG,
    ADD_TO_ACTION_QUEUE,
    REMOVE_ACTION_FROM_QUEUE,
    LOOP_TASKS_FLAG,
    APPROVE_ACTION_RUNNING,
    APPROVE_ACTION_STOPED
} from '../../store/actionTypes';


export const DEFAULT_STATE = {
    // for async action
    action: {
        type: null,
        taskIds: []
    },
    // for the iteration
    oneHandleOnly: true, // ~ if true, first task handled returns back to taskList, otherwise only last task in the iteration handled returns
    actionQueue: [],
    // try to loop task details - it should only be true for the actions truggered from task details view
    loopTasks: false,
    actionsRunning: false
};

/**
 * reducer for elementary task actions
 * store.taskActions: {
 *   type: string,
 *   taskIds: array of string
 * }
 */
export default function taskActionsReducer(state = DEFAULT_STATE, action) {
    let partialState;

    switch (action.type) {
        case TASK_ACTION_ASYNC:
            partialState = {
                action: {
                    type: action.actionType,
                    taskIds: action.taskIds
                },
                actionsRunning: true
            };
            return Object.assign({}, state, partialState);


        case UPDATE_ONE_HANDLE_ONLY_FLAG:
            partialState = {
                oneHandleOnly: action.data
            };
            return Object.assign({}, state, partialState);

        case DIFFERENT_USER:
        case LOGGED_OUT_TYPE:
            return DEFAULT_STATE;

        case TASK_ACTION_FINISHED:
            partialState = {
                oneHandleOnly: state.oneHandleOnly,
                loopTasks: state.loopTasks,
                actionsRunning: state.actionQueue.length !== 0
            };
            return Object.assign({}, DEFAULT_STATE, partialState);

        case ADD_TO_ACTION_QUEUE:
            let newActionQueue = Object.assign([], state.actionQueue)
            newActionQueue.push(action.data);
            return Object.assign({}, DEFAULT_STATE, {actionQueue: newActionQueue});

        case LOOP_TASKS_FLAG:
            partialState = {
                loopTasks: action.data
            };
            return Object.assign({}, state, partialState);

        case REMOVE_ACTION_FROM_QUEUE:
            let actionType = action.data;
            let actionQueue = state.actionQueue.filter(action => action.type !== actionType);
            partialState = {
                actionQueue: actionQueue
            };
            return Object.assign({}, DEFAULT_STATE, partialState);

        case APPROVE_ACTION_RUNNING:
            partialState = {
                actionsRunning: true
            };
            partialState.action = Object.assign({}, DEFAULT_STATE.action);
            partialState.action.type = action.data;
            return Object.assign({}, state, partialState);

        case APPROVE_ACTION_STOPED:
            partialState = {
                actionsRunning: false
            };
            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}


export function getActionParameters(state) {
    return state.action;
}

export function getOneHandleOnlyFlag(state) {
    return state.oneHandleOnly;
}

export function getTryLoopTasksFlag(state) {
    return state.loopTasks;
}

export function areActionsQueued(state) {
    return state.actionQueue.length > 0;
}

export function areTaskActionsRunning(state) {
    return state.actionsRunning;
}