import React, {useEffect} from 'react';
import {VisibleToEverybody} from "components/popup/PopUp.component";
import Comment from "components/input/Comment.component";
import OKCancelButtons from "components/modals/OKCancelButtons";
import {MODAL_CONTENT_TAB_INDEX} from "utils/constants";

const Reject = ({more, translate, closeModal, loopFocus}: any): React.ReactElement => {

    const commentInputRef = React.createRef<HTMLDivElement>();
    const [comment, setComment] = React.useState("");


    const keepFocus = () => {
        commentInputRef.current?.focus();
    };

    useEffect(() => {
        keepFocus();
    }, []);


    const okEnabled = true;
    return (
        <div>
            <h4 className="modal-title">
                {more?.title || translate("popUp.reject.title")}
            </h4>
            <div className="modal-body">
                <div className={"d-flex align-items-center mb-2"}>
                    <label htmlFor="comment"><span
                        className="text-danger">* </span> {translate("popUp.reject.commentLabel")}
                        <span className={"pe-2"}> - </span>
                    </label>
                    <VisibleToEverybody translate={translate}/>
                </div>
                <Comment ref={commentInputRef}
                         inputHint={translate("popUp.reject.commentHint")}
                         translate={translate}
                         tabIndex={MODAL_CONTENT_TAB_INDEX}
                         id="comment"
                         shouldFocusFirst={true}
                         propagateValue={value => setComment(value)}
                         value={comment}
                />
            </div>
            <OKCancelButtons
                tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                okEnabled={okEnabled}
                okCallback={closeModal}
                okText={translate("popUp.reject.rejectTask")}
                cancelEnabled={true}
                cancelCallback={closeModal}
                cancelText={translate("popUp.close")}
                loopFocus={loopFocus}
            />
        </div>
    )
}

export default Reject;