import React,{Fragment} from 'react';
import PropTypes from 'prop-types';
import Comment from '../input/Comment.component';
import {PopUp, VisibleToEverybody} from './PopUp.component';
import {DOCUMENT_ACTIONS} from 'utils/constants';
import translate from '../translations/translations.wrapper.jsx';
import * as Api from 'utils/api/api.js';
import {handleError} from 'utils/errorHandle.function';
import {PATH_TO_DOCUMENT_OVERVIEW} from "components/router/paths.js";

//this is to make sure the tabbing stays inside the dialog
const TAB_INDEX = 1000;

/**
 * popup which will be used in case user has selected 'approve with comment'
 * expects as props:
 * list of selected tasks
 * close popup callback
 * translate function
 */
export class RejectDirectly extends PopUp {

    static propTypes = {
        tasks: PropTypes.array, // selected tasks
        closeCallback: PropTypes.func, //callback for closing the popup
        handledTaskCallback: PropTypes.func, //callback to mark task as handled
        translate: PropTypes.func, // to translate texts
        showNotification: PropTypes.func, // to show transient notification
        approveMultipleTasks: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.ok = this.ok.bind(this);
        this.buildContent = this.buildContent.bind(this);

        this.state = {
            comment: '',
            actionRunning: false // we're waiting for backend call result
        }
    }

    ok() {
        const self = this;

        this.setState({actionRunning: true});


        const taskIds = this.props.tasks.map((task) => {
            return task.key;
        });

        let commentToSend = this.state.comment ? this.state.comment : '';

        Api.rejectDocumentDirectly(commentToSend, taskIds).then(response => {
            self.props.showNotification(response.message);
            self.props.handledTaskCallback(taskIds, self.props.navigate);
            self.props.closeCallback();
            self.props.navigate(PATH_TO_DOCUMENT_OVERVIEW);
        }, error => {
            handleError(error);
        });

    }

    componentDidMount() {
        super.componentDidMount();
        this.commentInput?.focus();
    }


    buildContent() {
        const okEnabled = this.state.comment && !this.state.actionRunning;

        return (
            <Fragment>
                <div className="modal-body">
                    <div>
                        <label htmlFor="comment">{this.props.translate("popUp.approve.commentLabel")}</label>
                    </div>
                    <VisibleToEverybody translate={this.props.translate}/>
                    <Comment
                        ref={(element=null) => {
                            this.commentInput = element;
                        }}
                        inputHint={this.props.translate("popUp.approve.commentHint")}
                        translate={this.props.translate}
                        tabIndex={TAB_INDEX}
                        id="comment"
                        value={this.state.comment}
                        propagateValue={value => this.setState({comment: value})}
                    />
                </div>

                <div className="modal-footer">
                    <div className="float-right">
                        <button className="btn btn-primary btn-margin-right"
                                onClick={this.ok}
                                disabled={!okEnabled}
                                ref={(element=null) => {
                                    this.okButton = element;
                                }}
                                tabIndex={TAB_INDEX + 1}>
                            {this.props.translate("popUp.ok")}
                        </button>
                        <button className="btn btn-default"
                                onBlur={() => {
                                    this.commentInput.focus();
                                }}
                                onClick={this.props.closeCallback}
                                tabIndex={TAB_INDEX + 2}>
                            {this.props.translate("popUp.cancel")}
                        </button>
                    </div>
                </div>
            </Fragment>

        );
    }

    getType() {
        return DOCUMENT_ACTIONS.REJECT_DIRECTLY;
    }

    getTitle() {
        if (this.props.tasks.length > 1) {
            return this.props.translate("popUp.rejectDirectly.titleMultiple", this.props.tasks.length);
        }
        else {
            return this.props.translate("popUp.rejectDirectly.titleSingle", this.props.tasks.length);
        }
    }

}

const withTranslations = translate(RejectDirectly);
export default withTranslations;
