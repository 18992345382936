import {Component} from 'react';
import * as logger from '../../utils/logger.function';
import {connect} from 'react-redux';
import {loadUserData} from './user.action';


/**
 * wrapper component to keep user session active if he has the tab opened for some time and don't do anything in it
 *
 * behavior from old approval:
 *  1) each tab maintain it's state - if it's visible, or not
 *  2) if page is hidden, it does nothing
 *  3) when page gets focus from previously hidden, it will check against backend immediately
 *  4) when page is visible for some time, it should check against backend
 */
export class KeepAlive extends Component {

    constructor() {
        super();
        this.state = {
            activeWindow: false, // we start inactive, component set this upon mounting
            timerId: undefined
        };
        this.checkUserSession = this.checkUserSession.bind(this);
    }

    componentDidMount() {
        // we finished mounting, so we're in active window
        this.setState({ activeWindow: true} );

        // https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

        // Set the name of the hidden property and the change event for visibility
        let hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        const self = this;

        function handleVisibilityChange() {
            let isHiding = self.state.activeWindow && document[hidden];
            const newState =  {
                activeWindow: !document[hidden]
            };

            if(isHiding) {
                // cancel timer
                window.clearInterval(self.state.timerId);
                // and remove it from state
                newState.timerId = undefined;
            } else {
                // run the check
                self.checkUserSession("focus");
                // start timer
                newState.timerId = window.setInterval(function () {
                    self.checkUserSession("timer");
                // eslint-disable-next-line
                }, ENVIRONMENT.KEEP_ALIVE_TIMEOUT_IN_SECONDS * 1000);
            }

            // persist into state
            self.setState( newState );
        }

        // Handle page visibility change
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }

    checkUserSession(reason) {
        logger.debug(new Date(), reason, 'keepalive checking user session');
        this.props.loadUserData();
    }

    render() {
        return null;
    }
}


export default connect(null, { loadUserData })(KeepAlive)