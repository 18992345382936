import {LOGGED_OUT_TYPE, TRANSLATIONS_LOADED, SAVE_VERSION} from '../store/actionTypes';
import changeMomentJsLocale from '../../utils/i18n/moment';
import changeNumbroLocale from '../../utils/i18n/wnumb';
import moment from 'moment';

export const STORE_TRANSLATIONS_DEFAULT = {language: 'en', dictionary: {}};

export default function translationsFunction (state = STORE_TRANSLATIONS_DEFAULT, action) {
    // save the current translations into store
    if (action.type === TRANSLATIONS_LOADED) {

        // change the moment.js locale
        changeMomentJsLocale(action.lang);
        // change the wnumb.js locale
        changeNumbroLocale(action.lang);

        // add new language into dictionary
        let dictionaryPartialState = {};
        dictionaryPartialState[action.lang] = action.dictionary;
        // reuse dictionary and set proper language and translations
        let dictionary = Object.assign({}, state.dictionary, dictionaryPartialState);
        let partialState = {language: action.lang, dictionary: dictionary};
        return Object.assign({}, STORE_TRANSLATIONS_DEFAULT, partialState);
    // in case application changed version, erase all saved dictionaries
    } else if(action.type === SAVE_VERSION) {
        const partialState = {
            language: state.language || STORE_TRANSLATIONS_DEFAULT.language
        };
        return Object.assign({}, STORE_TRANSLATIONS_DEFAULT, partialState);
    } else if (action.type === LOGGED_OUT_TYPE) {
        // remove data about selected language from store, but keep the dictionary
        const partialState = Object.assign({}, { dictionary:state.dictionary } );
        return Object.assign({}, STORE_TRANSLATIONS_DEFAULT, partialState);
    } else {
        return state;
    }
}


export function getLanguage(state) {
    return state.language;
}

export function getDictionaryForLanguage(state, language) {
    return state.dictionary[ language  ] || null;
}

export function getDictionary(state) {
    return getDictionaryForLanguage(state, getLanguage(state) );
}

export function getFallbackDictionary(state) {
    return state.dictionary[ 'en' ];
}

export function getDateFormat(state) {
    let lang = moment().locale(state.language === "en" ? "en-gb" : state.language);
    let valueFormatter = lang.localeData()._longDateFormat.L;

    return valueFormatter;
}