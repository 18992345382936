import React from 'react';
import {formatAmountValue, formatDate, formatDocumentOrganizationUnit} from '../../../../utils/valueFormatter.function';
import {formatWarnings} from "../formatWarnings";
import {PreApprovedDetails} from "./PreApprovalDetails.component";
import {WarningIcon, WarningsTooltip} from "./WarningsTooltip.component";

const VoucherDocumentDetailsDTO = ({details, translate}) => {
    let displayId = details.displayId ? details.displayId : details.documentId;

    let formattedWarnings = formatWarnings(details.warnings);

    return (
        <div>
            {details.description &&
                <span>
                    <div className={`document-data-field ${formattedWarnings.description ? 'has-warning' : ''}`}
                         data-tip data-for="description-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.description")}
                            {formattedWarnings.description && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.description}>
                            {details.description}
                        </div>
                    </div>
                    {formattedWarnings.description &&
                        <WarningsTooltip id="description-tooltip" warnings={formattedWarnings.description}/>
                    }
                </span>
            }
            <div className="document-data-divided">
                {details.supplierName &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.supplierName ? 'has-warning' : ''}`}
                         data-tip data-for="supplierName-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.supplierName")}
                            {formattedWarnings.supplierName && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.supplierName}>
                            {details.supplierName}

                        </div>
                    </div>
                    {formattedWarnings.supplierName &&
                    <WarningsTooltip id="supplierName-tooltip" warnings={formattedWarnings.supplierName}/>
                    }
                </span>
                }

                {details.supplierNumber &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.supplierNumber ? 'has-warning' : ''}`}
                         data-tip data-for="supplierNumber-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.supplierNumber")}
                            {formattedWarnings.supplierNumber && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.supplierNumber}>
                            {details.supplierNumber}
                        </div>
                    </div>
                    {formattedWarnings.supplierNumber &&
                    <WarningsTooltip id="supplierNumber-tooltip" warnings={formattedWarnings.supplierNumber}/>
                    }
                </span>
                }

                {details.invoiceNumber &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.invoiceNumber ? 'has-warning' : ''}`}
                         data-tip data-for="invoiceNumber-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.invoiceNumber")}
                            {formattedWarnings.invoiceNumber && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.invoiceNumber}>
                            {details.invoiceNumber}
                        </div>
                    </div>
                    {formattedWarnings.invoiceNumber &&
                    <WarningsTooltip id="invoiceNumber-tooltip" warnings={formattedWarnings.invoiceNumber}/>
                    }
                </span>
                }

                {details.creditBankAccount &&
                <span>
                    <div
                        className={`document-data-field third ${formattedWarnings.creditBankAccount ? 'has-warning' : ''}`}
                        data-tip data-for="creditBankAccount-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.bankAccount")}
                            {formattedWarnings.creditBankAccount && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.creditBankAccount}>
                            {details.creditBankAccount}
                        </div>
                    </div>
                    {formattedWarnings.creditBankAccount &&
                    <WarningsTooltip id="creditBankAccount-tooltip" warnings={formattedWarnings.creditBankAccount}/>
                    }
                </span>
                }

                {details.voucherNumber &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.voucherNumber")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.voucherNumber}>
                        {details.voucherNumber}
                    </div>
                </div>}

                {details.voucherSeries &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.voucherSeries")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.voucherSeries}>
                        {details.voucherSeries}
                    </div>
                </div>}

                {details.invoiceDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.documentDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.invoiceDate)}
                    </div>
                </div>}

                {details.dueDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.dueDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.dueDate)}
                    </div>
                </div>}

                {details.createdDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.createdDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.createdDate)}
                    </div>
                </div>}

                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.documentID")}
                    </div>
                    <div className="document-data-field-value"
                         title={displayId}>
                        {displayId}
                    </div>
                </div>

                {details.amount !== null &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.amount ? 'has-warning' : ''}`}
                         data-tip
                         data-for="amount-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.amount")}
                            {formattedWarnings.amount && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value">
                            <b>{formatAmountValue(details.amount)} </b>
                            {details.currency}
                            {(details.foreignAmount && details.currency !== details.foreignCurrency) &&
                            <div>
                                <small>
                                    <span>{formatAmountValue(details.foreignAmount)} </span>
                                    {details.foreignCurrency}
                                </small>
                            </div>}
                        </div>
                    </div>
                    {formattedWarnings.amount &&
                    <WarningsTooltip id="amount-tooltip" warnings={formattedWarnings.amount}/>
                    }
                </span>
                }

                {details.documentOrganizationUnit &&
                <span>
                    <div
                        className={`document-data-field third ${formattedWarnings.documentOrganizationUnit ? 'has-warning' : ''}`}
                        data-tip data-for="documentOrganizationUnit-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.documentOrganizationUnit")}
                            {formattedWarnings.documentOrganizationUnit && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={formatDocumentOrganizationUnit(details.documentOrganizationUnit)}>
                            {formatDocumentOrganizationUnit(details.documentOrganizationUnit)}
                        </div>
                    </div>
                    {formattedWarnings.documentOrganizationUnit &&
                    <WarningsTooltip id="documentOrganizationUnit-tooltip"
                                     warnings={formattedWarnings.documentOrganizationUnit}/>
                    }
                </span>
                }

                {details.paymentPreApprovalDetails &&
                <PreApprovedDetails
                    translate={translate}
                    paymentDetails={details.paymentPreApprovalDetails}
                />}
            </div>
        </div>
    )
};

export default VoucherDocumentDetailsDTO;
