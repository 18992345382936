import React from 'react';
import ConfigurationWrapper from "../configuration/ConfigurationWrapper.component";
import "./costunits.scss";
import translate from "../translations/translations.wrapper.jsx";
import CostUnitTypes from "./CostUnitTypes.component";
import {connect} from "react-redux";
import * as reduxSelectors from '../store/application.reducers';
import {
    costUnitTypeReordered,
    costUnitTypeSelected, costUnitTypesSaved,
    costUnitTypeUnselected,
    getCostUnitsData,
    setCostUnitsData
} from "./costunits.action";
import AssignApprovers from './assignapprovers/AssignApprovers.component';
import DefineExceptions from './exceptions/Exceptions.component';
import {companyHasHRMDocuments} from "utils/hrmDocuments.function";
import {userHasAccessRights} from "utils/accessRights.function";
import {handleError, insufficientRightsError} from "utils/errorHandle.function";
import {canViewConfiguration} from "../menu/Menu.component";

export class CostUnits extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            activeComponent: CostUnitTypes,
            okEnabled: false
        };
        this.getData = this.getData.bind(this);
        this.setActiveComponent = this.setActiveComponent.bind(this);
        this.preventNavigation = this.preventNavigation.bind(this);
    }

    componentDidMount(props) {
        if (!userHasAccessRights(this.props.userData.userRoles, this.props.location) || !canViewConfiguration(this.props.userData.userRoles)) {
            handleError(insufficientRightsError);
        }
        super.componentDidMount(props);
    }


    getData() {
        this.props.getCostUnitsData();
        this.setActiveComponent(CostUnitTypes);
        this.props.blocker.unblockNavigation();
    }

    setActiveComponent(component) {
        this.setState({
            activeComponent: component
        });
    };

    preventNavigation(doPrevent) {
        this.setState({
            okEnabled: doPrevent
        });
        this.props.blocker.shouldWaitForConfirmation(doPrevent);
    }

    buildContent() {
        const ActiveComponent = this.state.activeComponent;
        let hasNoSelectedCostUnits = !this.props.initialSelectedCostUnitTypes || (this.props.initialSelectedCostUnitTypes && this.props.initialSelectedCostUnitTypes.length === 0);
        let isHRMDocument = companyHasHRMDocuments(this.props.docTypes);

        return (
            <div id="cost-units">
                <div className="cost-units-scroller">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="wizard no-margin" id="cost-units-wizard">
                                    <button className={`wizard-item ${this.state.activeComponent === CostUnitTypes ? 'active' : ''} ${this.state.okEnabled ? " disabled" : ""}`}
                                        onClick={() => this.getData()}>
                                        <span> 1. {this.props.translate("costUnitsWizard.stepOne")} </span>
                                    </button>
                                    <button className={`wizard-item ${hasNoSelectedCostUnits ? 'disabled' : ''} ${this.state.activeComponent === AssignApprovers ? 'active' : ''}`}
                                        onClick={() => this.setActiveComponent(AssignApprovers)}>
                                        <span> 2. {this.props.translate("costUnitsWizard.stepTwo")} </span>
                                    </button>
                                    {isHRMDocument &&
                                    <button className={`wizard-item ${this.state.activeComponent === DefineExceptions ? 'active' : ''} ${this.state.okEnabled ? " disabled" : ""}`}
                                        onClick={() => this.setActiveComponent(DefineExceptions)}>
                                        <span> 3. {this.props.translate("costUnitsWizard.stepThree")} </span>
                                    </button>
                                    }
                                </div>
                                <ActiveComponent {...this.props} preventNavigation={this.preventNavigation}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const withTranslations = translate(CostUnits);

const mapStateToProps = function (store) {
    const userRoles = reduxSelectors.getUsersRoles(store);
    return {
        costUnitTypes: reduxSelectors.getCostUnitTypes(store),
        selectedCostUnitTypes: reduxSelectors.getSeletedCostUnitTypes(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        initialSelectedCostUnitTypes: store.costUnits.initialSelectedCostUnitTypes,
        docTypes: reduxSelectors.getCostUnitsDocTypes(store),
        userRoles: userRoles,
        systemViewerOnly: !userRoles.systemAdministrator && !userRoles.superAdministrator && userRoles.systemViewer,
        userData: reduxSelectors.getUsersData(store)
    };
};

const connected = connect(mapStateToProps, {
    getCostUnitsData,
    costUnitTypeSelected,
    costUnitTypeUnselected,
    costUnitTypeReordered,
    setCostUnitsData,
    costUnitTypesSaved
})(withTranslations);

export default connected;
