import React from "react";
import ReactTooltip from "react-tooltip";

export function Tooltip(props) {

    const tooltipRef = React.createRef();
    const theme = props.theme || 'light';
    const themeColors = {
        light: {
            textColor: '#252626',
            backgroundColor: '#ffffff'
        },
        dark: {
            textColor: '#DADADA',
            backgroundColor: '#252626'
        }
    };
    const customProps = {};

    if (props.event) {
        customProps.event = props.event;
    }

    if (props.eventOff) {
        customProps.eventOff = props.eventOff;
    }

    if (props.globalEvent) {
        customProps.globalEvent = props.globalEvent;
    }

    if (props.globalEventOff) {
        customProps.globalEventOff = props.globalEventOff;
    }

    if (typeof props.scrollHide !== 'undefined') {
        customProps.scrollHide = props.scrollHide;
    }

    if (typeof props.resizeHide !== 'undefined') {
        customProps.resizeHide = props.resizeHide;
    }

    if (typeof props.disabled !== 'undefined') {
        customProps.disable = props.disabled;
    }

    if (typeof props.offset !== 'undefined') {
        customProps.offset = props.offset;
    }

    if (typeof props.overridePosition !== 'undefined') {
        customProps.overridePosition = props.overridePosition;
    }

    return (<ReactTooltip
        role="tooltip"
        globalEventOff={"keydown"}
        place={props.place || 'top'}
        id={props.id}
        className='tooltip solid-tooltip'
        delayHide={300}
        textColor={themeColors[theme].textColor}
        backgroundColor={themeColors[theme].backgroundColor}
        border={props.borderClass}
        effect='solid'
        getContent={props.content}
        ref={tooltipRef}
        {...customProps}
    />)

}