import React from 'react';
import "./list.scss";

export const ListWithActions = (props) => {

    let headerList = props.header ? props.header.map((header, index) => {

        let sortIconClass = header.sortable && header.sortOrder ? " sort-arrow sort-arrow-" + header.sortOrder : "";

        return (<div className={header.class}
                     key={header.value}
                     onClick={header.sortable ? props.sortHandle.bind(null, index) : null}>
            {props.translate(header.text)}
            <div className={sortIconClass}/>
        </div>);
    }) : [];

    let listElements = props.header ? props.values.map((item, rowIndex) => {
        let row = props.header.map((column) => {
            return (<div className={column.class + " cropped-text"}
                         key={column.value + "-" + item.substituteId + item.userId}
                         title={item[column.value]}>
                {item[column.value]}
            </div>);
        });

        return (<div className="row" key={item.substituteId + "-" + item.version}>
            {row}
            {props.showActions &&
                <div className="col-md-1 d-flex justify-content-end flex-nowrap text-center">
                    <span className="action vismaicon vismaicon-edit vismaicon-dynamic me-lg-2 me-xl-4"
                          title={props.translate("workflows.edit")}
                          onClick={(e) => props.editRow(rowIndex, e)}/>
                    <span className="action no-margin vismaicon vismaicon-delete vismaicon-dynamic"
                          title={props.translate("workflows.delete")}
                          onClick={(e) => props.deleteRow(rowIndex, e)}/>
                </div>
            }
        </div>);
    }) : [];

    let scrollList = function (event) {
        if ((parseInt((event.target.scrollTop + event.target.offsetHeight + 1), 10) >= event.target.scrollHeight)) {
            if (props.scrollAtBottom)
                props.scrollAtBottom();
        }
    };

    return (
        <div className="row">
            <div className="col-md-12 actions-list">
                {props.header &&
                    <div className="row header-row">
                        {headerList}
                        {props.showActions && (
                            <div className="col-md-1 text-center">
                                {props.translate("substitutes.headers.action")}
                            </div>
                        )}
                    </div>
                }

                <div className="main-panel" onScroll={scrollList}>
                    {props.values.length > 0 ?
                        listElements
                        :
                        <h3 className="col-md-12 pt-4 no-border">
                            {props.translate("substitutes.noMatches")}
                        </h3>
                    }
                </div>

            </div>
        </div>
    );
};

export default ListWithActions;
