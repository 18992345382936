import React, {useEffect} from 'react';
import Comment from "components/input/Comment.component";
import {MODAL_CONTENT_TAB_INDEX} from "utils/constants";
import NumericInput from "react-numeric-input";
import OKCancelButtons from "components/modals/OKCancelButtons";
import {formatDate} from "utils/valueFormatter.function";
import Datepicker from "react-flatpickr";
import {postponeTasks} from "components/modals/modal.actions";
import ErrorModal from './Error.modal';


let TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);
TOMORROW = new Date(TOMORROW);

const RADIO_SELECTION = {
    daysBefore: 1,
    daysFromNow: 2,
    thisDay: 3,
};

const Postpone = ({more, translate, closeModal, loopFocus}: any): React.ReactElement => {

    const commentInputRef = React.createRef<HTMLDivElement>();
    const [comment, setComment] = React.useState(null);
    const [selectedRemindMe, setSelectedRemindMe] = React.useState(RADIO_SELECTION.daysFromNow);
    const [errorState, setErrorState] = React.useState<object | null>(null);


    const keepFocus = () => {
        commentInputRef.current?.focus();
    };


    useEffect(() => {
        keepFocus();
    }, []);

    const displayActionError = () => {
        const error = {
            errorType: 'WARNING',
            errorTitle: translate("popUp.postpone.title"),
            errorMessages: translate("popUp.postpone.actionError")
        };

        setErrorState(error);
    }

    const doPostponeTask = () => {
        const taskKeys = more.tasks?.map((task) => {
            return task.key;
        });
        const postponeMassage = translate("popUp.postpone.actionSuccess", taskKeys.length);

        postponeTasks(taskKeys, comment, selectedRemindMe, postponeMassage, closeModal, displayActionError);

    }


    return (
        <div>
            <h4 className="modal-title">
                {more?.title || translate("popUp.postpone.title")}
            </h4>
            <div className="modal-body postpone-dialog">
                <p>
                    {translate("popUp.postpone.postponeExplanation")}
                </p>

                {errorState &&
                    <ErrorModal more={{error: errorState}} closeModal={() => setErrorState(null)} translate={translate}
                                loopFocus={loopFocus}/>
                }

                <form>
                     <span className={"me-4"}>
                         {translate("popUp.postpone.remindMe")}
                     </span>

                    <RemindMe
                        tasks={more.tasks}
                        onChange={(value) => setSelectedRemindMe(value)}
                        translate={translate}
                        selectedRemindMe={selectedRemindMe}
                    />


                    <label htmlFor="comment"><span
                        className="text-danger">* </span> {translate("popUp.postpone.commentLabel")}
                    </label>


                    <Comment
                        id="comment"
                        inputHint={translate("popUp.postpone.commentHint")}
                        translate={translate}
                        propagateValue={value => setComment(value)}
                        value={comment}
                        tabIndex={MODAL_CONTENT_TAB_INDEX + 2}/>
                </form>


                <OKCancelButtons
                    tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                    okEnabled={comment !== null && selectedRemindMe !== null}
                    okCallback={doPostponeTask}
                    okText={translate("popUp.postpone.postponeTask")}
                    cancelEnabled={true}
                    cancelCallback={closeModal}
                    cancelText={translate("popUp.close")}
                    loopFocus={loopFocus}
                />
            </div>
        </div>
    )
}

export default Postpone;


const RemindMe = ({tasks,onChange, selectedRemindMe, translate}: any): React.ReactElement => {


    const getMinDocumentDueDate = (tasks) => {
        return tasks.reduce(function (acc, val) {
            if (acc === null) return val.documentDueDate;
            if (acc < val.documentDueDate) return acc;
            else return val.documentDueDate;
        }, null);
    }


    const minDocDueDate = formatDate(getMinDocumentDueDate(tasks), null);
    const daysFromNowRelative = formatDate(TOMORROW, null);
    return (
        <div>
            <div
                className={"d-flex flex-row align-items-center justify-content-start mb-2"}>
                <input
                    type="radio"
                    id="daysBefore"
                    className="option-radio"
                    tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                    checked={selectedRemindMe === RADIO_SELECTION.daysBefore}
                    value={RADIO_SELECTION.daysBefore}
                    onChange={() => {
                        onChange(RADIO_SELECTION.daysBefore)
                    }}

                    data-testid={"__tst_taskDueDays"}/>
                <div>
                                                 <span className={"me-2"}>
                                                <NumericInput disabled={selectedRemindMe !== RADIO_SELECTION.daysBefore}
                                                              value={1}
                                                              aria-label={translate("popUp.postpone.daysBeforeDoc")}
                                                              onChange={() => {
                                                              }}
                                                              min={1} max={99} step={1}
                                                              tabIndex={MODAL_CONTENT_TAB_INDEX + 2}
                                                />
                                                  </span>
                    <label htmlFor="daysBefore">
                        {translate("popUp.postpone.daysBeforeDoc")}
                        <span className="text-disabled input-preview">
                                     {translate("popUp.postpone.daysBeforeDocRelative", minDocDueDate)}
                                 </span>
                    </label>
                </div>

            </div>
            <div
                className={"d-flex flex-row align-items-center justify-content-start mb-2"}>
                <input
                    type="radio"
                    id="dayFromNow"
                    className="option-radio"
                    tabIndex={MODAL_CONTENT_TAB_INDEX + 3}
                    defaultChecked={false}
                    checked={selectedRemindMe === RADIO_SELECTION.daysFromNow}
                    value={RADIO_SELECTION.daysFromNow}
                    onChange={() => {
                        onChange(RADIO_SELECTION.daysFromNow)
                    }}

                    data-testid={"__tst_taskDueDays"}/>
                <div>
                                                 <span className={"me-2"}>
                                                    <NumericInput
                                                        disabled={selectedRemindMe !== RADIO_SELECTION.daysFromNow}
                                                        value={1}
                                                        aria-label={translate("popUp.postpone.daysBeforeDoc")}
                                                        onChange={() => {
                                                        }}
                                                        min={1} max={99} step={1}
                                                        tabIndex={MODAL_CONTENT_TAB_INDEX + 4}
                                                    />
                                                 </span>
                    <label htmlFor="dayFromNow">
                        {translate("popUp.postpone.daysFromNow")}
                    </label>
                </div>
                <span className="text-disabled input-preview">
                                     {translate("popUp.postpone.daysBeforeDocRelative", daysFromNowRelative)}
                                 </span>
            </div>

            <div
                className={"d-flex flex-row align-items-center justify-content-start mb-2"}>
                <input type="radio" name="optradio"
                       className="option-radio"
                       id="thisDay"
                       checked={selectedRemindMe === RADIO_SELECTION.thisDay}
                       onChange={() => {
                           onChange(RADIO_SELECTION.thisDay)
                       }}
                       value={RADIO_SELECTION.thisDay}
                       tabIndex={MODAL_CONTENT_TAB_INDEX + 5}
                />
                <span>
                                             <Datepicker
                                                 options={{
                                                     allowInput: true,
                                                     defaultDate: TOMORROW,
                                                     minDate: TOMORROW,
                                                     dateFormat: 'd/m/Y',
                                                 }}
                                                 aria-label={translate("popUp.postpone.onThisDate")}
                                                 disabled={selectedRemindMe !== RADIO_SELECTION.thisDay}
                                                 defaultValue={daysFromNowRelative}
                                                 value={TOMORROW}
                                                 onChange={() => {
                                                 }}
                                                 className={"flatpickr w-50 me-2"}
                                             />
                                        <label htmlFor="thisDay">
                                            {translate("popUp.postpone.onThisDate")}
                                            <span className={"disabled ml-4 pl-4 d-none"}>{daysFromNowRelative}</span>
                                        </label>
                                        </span>
            </div>
        </div>)
};