export const SaveDiscardMessage = (props) => {

    return (
        <div>
            <strong>{props.translate("popUp.unsavedChanges")}</strong>
            <p className="paragraph-space mt-4">
                {props.translate("popUp.exitWarning")}
            </p>
            {props.iframeHasUnsavedChanges || props.commentsHaveUnsavedChanges
                ? <ul className="list-disc">
                    {props.iframeHasUnsavedChanges &&
                        <li key={"editor-changes"}>{props.translate("taskDetail.unsavedEditorChanges")}</li>
                    }
                    {props.commentsHaveUnsavedChanges &&
                        <li key={"comments-changes"}>{props.translate("taskDetail.unsavedComments")}</li>
                    }
                </ul>
                : <p className="paragraph-space mt-4">
                {props.translate("popUp.shouldContinue")}
                </p>
            }
        </div>
    );
};