import {
    APP_MENU_ITEMS_LOADING,
    APP_MENU_ITEMS_LOADED,
    APP_MENU_ITEMS_ERROR
} from '../store/actionTypes';
import {LOADING_STATUS} from "../../utils/constants";
import {AppMenuAction, AppMenuState} from "../../types/appMenuItem";


export const DEFAULT_STATE:AppMenuState = {
    loadingStatus: undefined,
    appMenuItems: [],
    error: undefined,
};

export default function appMenuItemsReducer(state: AppMenuState = DEFAULT_STATE, action: AppMenuAction):AppMenuState {
    let partialState;
    switch (action.type) {

        case APP_MENU_ITEMS_LOADING:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.LOADING;
            return Object.assign({}, state, partialState);

        case APP_MENU_ITEMS_LOADED:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.DONE;
            partialState.appMenuItems = action.data;
            return Object.assign({}, state, partialState);

        case APP_MENU_ITEMS_ERROR:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.ERROR;
            partialState.error = action.data;
            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}

export function getAppMenuItems(state: any) {
    return state.appMenuItems;
}