import React, {Component} from 'react';
import './my_history.scss';
import {connect} from 'react-redux';
import HistoryLayout from './HistoryLayout.component';
import * as reduxSelectors from '../store/application.reducers';
import {
    filterChanged,
    loadHistory,
    loadMoreHistory,
    processFilterChanged,
    sortingColumnChanged,
    preventDataReload,
    saveScrollPosition
} from './myHistory.action';
import {HISTORY_FILTERS, LOADING_STATUS, PROCESS_TYPE} from "utils/constants";
import {pageDataLoaded, pageLoaded} from '../router/route.action';
import translate from '../translations/translations.wrapper.jsx';
import {userHasAccessRights} from "utils/accessRights.function";
import _ from 'lodash';
import {navigateToTaskOverview} from "../router/router.function.js";

const FIRST_PAGE = 1;
const NUMBER_OF_ROWS = 40;


export const c1 = class MyHistory extends Component {

    constructor(props) {
        super();
        this.state = {
            columns: []
        };
        //region bindings
        this.filterProcess = this.filterProcess.bind(this);
        this.getTasks = this.getTasks.bind(this);
        this.onBodyScroll = this.onBodyScroll.bind(this);
        this.onCostUnitSearch = this.onCostUnitSearch.bind(this);
        this.callServer = this.callServer.bind(this);
        this.onAccountSearch = this.onAccountSearch.bind(this);
        this.advancedSearchFiltersActive = this.advancedSearchFiltersActive.bind(this);
        this.noOfAdvancedSearchFiltersActive = this.noOfAdvancedSearchFiltersActive.bind(this);
        this.onAdvancedSearchFiltersClear = this.onAdvancedSearchFiltersClear.bind(this);
        //endregion
    }

    componentDidMount() {
        if (this.props.shouldPreventReload) {
            this.props.preventDataReload(false);
            return;
        }
        this.getTasks(this.props.processFilter, FIRST_PAGE, this.props.filters, true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentContextId !== prevProps.currentContextId) {
            this.getTasks(this.props.processFilter, this.props.tasks.page, this.props.filters);
        }
        // we became 'loaded' :)
        if (this.props.loadingStatus !== prevProps.loadingStatus && this.props.loadingStatus === LOADING_STATUS.DONE) {
            // stop tracking virtual page
            this.props.pageLoaded();
        }
    }

    getTasks(processType, page, data) {
        if (!userHasAccessRights(this.props.userData.userRoles, this.props.location)) {
            navigateToTaskOverview();
            return;
        }

        let callObject = {
            type: processType,
            page: page,
            rows: NUMBER_OF_ROWS,
            filters: data
        }
        this.props.loadHistory(callObject);
    }


    onBodyScroll() {
        let newPage = this.props.tasks.page + 1;
        let callObject = {
            type: this.props.processFilter,
            page: newPage,
            rows: NUMBER_OF_ROWS,
            filters: this.props.filters
        }
        this.props.loadMoreHistory(callObject);
    }

    /*
     * top pils filter to switch between active/complete and all eventually
     * */
    filterProcess(type) {
        if (type !== this.props.processFilter) {
            let newType = this.props.processFilter === PROCESS_TYPE.ACTIVE ? PROCESS_TYPE.COMPLETED : PROCESS_TYPE.ACTIVE;
            this.props.processFilterChanged(type);
            let filters = _.cloneDeep(this.props.filters);
            if (filters.columns.status) {
                delete filters.columns.status;
            }
            this.getTasks(newType, FIRST_PAGE, filters, true);
        }
    }

    onCostUnitSearch(query) {
        let unitsObject = {};
        let currentCostUnit = Object.assign([], this.props.filters.costUnits);
        let foundQuery = false;
        //search if the filter has a previous value
        currentCostUnit.forEach((unit) => {
            if (unit.dimension === query.dimension && typeof query.number !== "undefined") {
                unit.number = query.number;
                unit.label = query.label;

                foundQuery = true;
            }
            else if (typeof query.number === "undefined") {
                //filter out the removed Filter value
                currentCostUnit = currentCostUnit.filter(elem => {
                    return elem.dimension !== query.dimension
                });
                foundQuery = true;
            }
        });
        //this is a new filter
        if (!foundQuery) {
            currentCostUnit.push(query);
        }
        let newCostUnits = Object.assign([], currentCostUnit);
        unitsObject[HISTORY_FILTERS.COST_UNITS] = newCostUnits;
        let newFilter = this.getFilterValue(unitsObject, this.props.filters);
        this.props.filterChanged(newFilter);
        this.callServer(newFilter);
    }

    onAccountSearch(query) {
        let accountObject = {};
        accountObject[HISTORY_FILTERS.ACCOUNT] = query ? {
            number: query.value,
            label: query.label
        } : undefined;

        let newFilter = this.getFilterValue(accountObject, this.props.filters);
        this.props.filterChanged(newFilter);
        this.callServer(newFilter);
    }

    getFilterValue(searchValue, existingFilter) {
        let newFilter = Object.assign({}, existingFilter);
        if (searchValue) {
            let key = Object.keys(searchValue)[0];
            newFilter[key] = (searchValue[key]);
        }
        return newFilter;
    }

    advancedSearchFiltersActive() {
        const filters = this.props.filters;
        return filters && (filters.costUnits.length > 0 || filters.account);
    }

    noOfAdvancedSearchFiltersActive() {
        const filters = this.props.filters;
        let activeFilters = filters && filters.account ? 1: 0;
        activeFilters += filters && filters.costUnits ? filters.costUnits.length : 0;
        return activeFilters;
    }

    onAdvancedSearchFiltersClear() {
        const newFilter = _.cloneDeep(this.props.filters);
        newFilter.costUnits = [];
        newFilter.account = undefined;
        this.props.filterChanged(newFilter);
        this.callServer(newFilter);
    }

    callServer(filter) {
        this.getTasks(this.props.processFilter, this.props.tasks.page, filter);
    }

    render() {
        let props = {
            columns: this.state.columns,
            onAccountSearch: this.onAccountSearch,
            onCostUnitSearch: this.onCostUnitSearch,
            onAdvancedSearchFiltersClear: this.onAdvancedSearchFiltersClear,
            noOfAdvancedSearchFiltersActive: this.noOfAdvancedSearchFiltersActive,
            advancedSearchFiltersActive: this.advancedSearchFiltersActive,
            filterProcess: this.filterProcess,
            companyId: this.props.currentContextId,
            onBodyScroll: this.onBodyScroll,
            refreshGrid: this.callServer,
            userRoles: this.props.userData.userRoles,
            saveScrollPosition: this.props.saveScrollPosition,
            location: this.props.location
        };

        return (
            <div className="my-history-page">
                <HistoryLayout {...props}/>
            </div>
        )
    }
}
const withTranslations = translate(c1);


const mapStateToProps = function (store) {
    return {
        tasks: reduxSelectors.getHistoryTasksForRender(store),
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        company: reduxSelectors.getFilterCompany(store),
        processFilter: reduxSelectors.getHistoryProcessFilter(store),
        filters: reduxSelectors.getHistoryFilter(store),
        loadingStatus: reduxSelectors.getHistoryLoadingStatus(store),
        userData: reduxSelectors.getUsersData(store),
        shouldPreventReload: reduxSelectors.shouldPreventHistoryReload(store)
    };
};

const connected = connect(mapStateToProps, {
    loadHistory,
    loadMoreHistory,
    processFilterChanged,
    filterChanged,
    pageLoaded,
    pageDataLoaded,
    sortingColumnChanged,
    preventDataReload,
    saveScrollPosition
})(withTranslations);

export default connected;