import React, {Component} from 'react';
import {TASK_ACTIONS, DETAILS_VIEW_TYPE} from 'utils/constants';
import {connect} from 'react-redux';
import {taskAction} from '../mytasks/action/taskAction.action';
import {
    navigateBetweenProcessTasks,
} from "../mytasks/action/navigateBetweenTasks.action";
import {
    getProcessOverviewTasks,
    getTaskDetails,
    getTaskAttachments,
    getAttachmentsLoadingStatus
} from '../store/application.reducers';
import {TaskDetailActions} from '../mytasks/AvailableActions.class';
import {pageLoaded} from '../router/route.action';
import {
    getHistoryTaskDetails,
    getTaskCommentsData,
    handleAddProcessAndHistoryComments,
} from "../taskdetails-remake/taskDetails.action";
import {formatTaskDetailsTopBanner} from 'utils/valueFormatter.function';
import {handleNPKeyNavigation} from 'utils/keyPressHandler.function';
import _ from 'lodash';
import {preventProcessReload} from '../processoverview/processOverview.action';
import TaskDetails from 'components/taskdetails-remake/TaskDetails.component';
import {PATH_TO_PROCESS_OVERVIEW} from "components/router/paths.js";
import {launchDarkly} from "utils/launchDarkly.ts";

const cl = class TaskDetailsMasterLayer extends Component {
    constructor(props) {
        super(props);

        this.getActionsForTask = this.getActionsForTask.bind(this);
        this.getTaskDetailFromStore = this.getTaskDetailFromStore.bind(this);
        this.requestData = this.requestData.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.getNavigation = this.getNavigation.bind(this);
        this.navigateBack = this.navigateBack.bind(this);
        this.navigateBetweenTasks = this.navigateBetweenTasks.bind(this);

        this.useNewAddComments = launchDarkly.companyClient && launchDarkly.companyClient.variation("fe-improve-add-comments", false);
    }

    /*
     * default view is the attachemnts page so we need to get the attachemnts first
     * */
    componentDidMount() {
        // detail of current task is also in the store (use #getTaskDetailFromStore), but without for example the attachments, so we have to load it again

        this.requestData(this.props.match.params.taskId);
        document.addEventListener('keydown', this.handleKeyPress);
    }

    //this is needed to reload data at navigate between tasks with ctrl+alt+n
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.taskId !== this.props.match.params.taskId) {
            this.requestData(this.props.match.params.taskId);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    navigateBetweenTasks(taskId, isForward) {
        this.props.history(this.props.navigateBetweenProcessTasks(taskId, isForward));
    }

    handleKeyPress(e) {
        handleNPKeyNavigation(e, this.props.match.params.taskId, this.navigateBetweenTasks);
    }

    requestData(taskId) {
        this.props.getHistoryTaskDetails(taskId);
    }

    navigateBack() {
        /*before going back to the Process overview set the prevent data reload to true
            - we need to scroll position to remain in place
        */
        this.props.preventProcessReload(true);
    }

    getTaskDetailFromStore() {
        const thisTaskArray = (this.props.tasks.allTasks || []).filter(task => {
            return task.key === this.props.match.params.taskId
        });
        return thisTaskArray[0];
    }

    getActionsForTask(navigation) {
        let activeActions = new TaskDetailActions();

        // add email
        activeActions.enable(TASK_ACTIONS.EMAIL);

        //check if move actions are available
        if (navigation.currentTask !== navigation.total) {
            //enable move to next
            activeActions.enable(TASK_ACTIONS.NEXT_TASK);
        }
        if (navigation.currentTask !== 1) {
            activeActions.enable(TASK_ACTIONS.PREVIOUS_TASK);
        }
        return activeActions;
    }

    getNavigation(task) {
        //fill in all the data found in the task
        let response = formatTaskDetailsTopBanner(task, true);

        response.companyName = task.companyName;
        //task number and number of selected tasks still need to be computed
        response.currentTask = _.findIndex(this.props.tasks.allTasks, (x => {
            return x.key === task.key;
        })) + 1;
        response.total = this.props.tasks.allTasks?.length;
        return response;
    }


    render() {
        const task = this.getTaskDetailFromStore();

        if (!task) {
            return <div>
                <span className="appicon loading"/>
            </div>;
        }

        let navigation = this.getNavigation(task);
        const activeActions = this.getActionsForTask(navigation);
        let params = {
            taskId: this.props.match.params.taskId
        };

        return (
            <TaskDetails params={params}
                         task={task}
                         taskAction={this.props.taskAction}
                         attachments={this.props.attachments}
                         loadingStatus={this.props.loadingStatus}
                         taskDetails={this.props.taskDetails}
                         navigation={navigation}
                         route={this.props.route}
                         navigateBetweenTasks={this.props.navigateBetweenProcessTasks}
                         activeActions={activeActions}
                         navigateBack={this.navigateBack}
                         navigateBackPath={PATH_TO_PROCESS_OVERVIEW}
                         isHistory={true}
                         type={DETAILS_VIEW_TYPE.PROCESS}
                         routerBlocker={this.props.blocker}
                         allowActionlessComments={this.useNewAddComments && this.props.taskDetails.allowExtraComments}
                         addCommentHandler={this.props.handleAddProcessAndHistoryComments}
                         getCommentsHandler={this.props.getTaskCommentsData} />
        );
    }
}

const mapStateToProps = function (store) {
    return {
        tasks: getProcessOverviewTasks(store),
        taskDetails: getTaskDetails(store),
        attachments: getTaskAttachments(store),
        loadingStatus: getAttachmentsLoadingStatus(store),
    };
};

const connected = connect(mapStateToProps, {
    taskAction,
    navigateBetweenProcessTasks,
    pageLoaded,
    getHistoryTaskDetails,
    preventProcessReload,
    handleAddProcessAndHistoryComments,
    getTaskCommentsData
})(cl);

export default connected;
