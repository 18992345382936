import React, {Component} from "react";
import lockIcon from "../../assets/24_lock_white.svg"
import whiteHelpIcon from "../../assets/24_help.svg"

export class ApprovalStep4EyesHeader extends Component {

    render() {
        let {toggle, collapsed} = this.props;
        let toggleIconClass = "glyphicon font-18 margin-right glyphicon-menu-" + (collapsed ? "right" : "down");
        return (
            <div className="step-header padding-right-0">
                <span className={toggleIconClass} onClick={toggle}/>
                <span>
                    <span>
                        <strong>{this.props.translate("workflowDetails.4EyesApproval.header")}</strong>
                    </span>
                    <img src={whiteHelpIcon}
                         alt="lock icon"
                         width="16"
                         title={this.props.translate("workflowDetails.4EyesApproval.headerInfo")}
                         className="white-icon-help"/>
                     <img src={lockIcon}
                          alt="lock icon"
                          width="16"
                          className="float-right"/>
                </span>
            </div>
        )

    }
};