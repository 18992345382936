import {Component} from 'react';
import {taskAddChange, taskRemovedChange} from "./websocket.action";
import {launchDarkly} from "utils/launchDarkly";
import {connect} from "react-redux";
import {getUsersData} from "../store/application.reducers";
import * as Api from "utils/api/api";

const MAX_ATTEMPTS = 5;
let CURRENT_STEP = 1;

export class WebSocketConnection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ws: null
        };

        this.openLiveConnection = this.openLiveConnection.bind(this);
        this.getWsTokenAndConnect = this.getWsTokenAndConnect.bind(this);
        this.parseWsMessages = this.parseWsMessages.bind(this);
        this.parseWsMessage = this.parseWsMessage.bind(this);
        this.closeLiveConnection = this.closeLiveConnection.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.checkWSConnection = this.checkWSConnection.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.closeLiveConnection, false);
        document.addEventListener('visibilitychange', this.handleVisibilityChange, false);
        window.addEventListener("focus", this.onFocus);

        this.checkWSConnection();
    }

    checkWSConnection() {
        // do not open multiple connections
        if (!this.state.ws || (this.state.ws && this.state.ws?.readyState === "CLOSED"))
            this.getWsTokenAndConnect();
    }

    componentDidUpdate(prevProps) {
        //user logout
        if (prevProps.userData && this.props.userData === null) {
            this.closeLiveConnection();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.closeLiveConnection);
        window.removeEventListener("visibilitychange", this.handleVisibilityChange);
        window.removeEventListener("focus", this.onFocus);
        this.closeLiveConnection();
    }

    onFocus() {
        if (!this.state.ws || (this.state.ws && this.state.ws.readyState === WebSocket.CLOSED)) {
            this.getWsTokenAndConnect();
        }
    }

    openLiveConnection(token) {
        let wsURL = window.ENVIRONMENT.TASKS_WEBSOCKETS + "?token=" + token;
        let ws = new WebSocket(wsURL);

        ws.onopen = () => {
            CURRENT_STEP = 1;
        };

        ws.onmessage = (data) => {
            console.log(data);
            let messages = JSON.parse(data.data);
            this.parseWsMessages(messages);
        };

        ws.onclose = (event) => {
            //server error or abnormal closure of the connection
            if ((event?.code >= 1010 && event?.code <= 1014) || event?.code === 1006) {
                console.debug("ws closed because:" + event.reason);
                setTimeout(this.getWsTokenAndConnect, 5000);
            }
        }

        ws.onerror = (error) => {
            ws.close();
        }

        this.setState({
            ws: ws
        });
    }

    getWsTokenAndConnect() {
        if (!this.state.ws || (this.state.ws && this.state.ws.readyState === WebSocket.CLOSED)) {
            this.setState({
                ws: null
            }, () => {
                Api.getWsToken().then(response => {
                        if (response?.webSocketToken)
                            this.openLiveConnection(response.webSocketToken);
                    }
                ).catch((error) => {
                    CURRENT_STEP += 1;
                    if (CURRENT_STEP <= MAX_ATTEMPTS)
                        setTimeout(this.getWsTokenAndConnect, 5000);
                });
            });
        }
    }

    parseWsMessages(messages) {
        //check for array of events
        if (Array.isArray(messages)) {
            let taskIds = [];
            messages.forEach(message => {
                if (message.event === 'TASK_CHANGED' && message.details?.taskAssignmentStatus !== "BLOCKED") {
                    taskIds.push(message.idTask);
                } else {
                    this.parseWsMessage(message);
                }
            });

            if (taskIds.length > 0)
                this.props.taskAddChange(taskIds);

        } else {
            this.parseWsMessage(messages);
        }
    }

    parseWsMessage(message) {
        if (message.event === 'TASK_DELETED') {
            this.props.taskRemovedChange(message.idTask);
        } else if (message.event === 'TASK_CHANGED') {
            if (message.details?.taskAssignmentStatus === "BLOCKED")
                this.props.taskRemovedChange(message.idTask);
            else
                this.props.taskAddChange(message.idTask);
        }
    }

    closeLiveConnection() {
        if (this.ws)
            this.ws.close();
    }

    handleVisibilityChange() {
        //when the Tab is active again we need to restore the ws connection
        if (!document.hidden) {
            this.checkWSConnection();
        }
    }


    render() {
        return null;
    }
}

const mapStateToProps = function (store) {
    return {
        userData: getUsersData(store)
    };
};
export default connect(mapStateToProps,
    {
        taskAddChange,
        taskRemovedChange
    })(WebSocketConnection);