import {
    TASK_DETAILS_LOADED,
    CHANGE_SELECTED_DOCUMENT,
    TASK_DETAILS_LOADING,
    TASK_DETAILS_LOADING_ERROR,
    HAS_EDITED_DOCUMENT,
    CHANGE_ROTATION,
    UPDATE_DOCUMENT_VERSION,
    WORKFLOW_DETAILS_LOADED,
    WORKFLOW_HISTORY_LOADED,
    WORKFLOW_DETAILS_LOADING_ERROR,
    TASK_DOCUMENT_DATA_LOADED,
    TASK_DOCUMENT_DATA_LOADING_ERROR,
    CHANGE_ZOOM_PERCENTAGE,
    WORKFLOW_DETAILS_LOADING,
    TASK_DOCUMENT_DATA_LOADING,
    TASK_COMMENTS_LOADING,
    TASK_COMMENTS_LOADED,
    CHANGE_IMAGE_ZOOM_PERCENTAGE,
    CACHE_ATTACHMENTS_IMAGES_URLS,
    CHANGE_UNSAVED_COMMENT,
    IFRAME_HAS_CHANGES,
    COMMENTS_HAVE_CHANGES,
    IFRAME_HAS_VBNXT_SAVE_DISCARD_FLAG,
    TASK_COMMENT_SAVING,
    TASK_COMMENT_SAVED,
    TASK_COMMENT_SAVING_ERROR,
    TASK_COMMENT_START_SAVING,
    DOCUMENT_PREVIEW_DETACH,
    DOCUMENT_PREVIEW_REATTACH,
    DOCUMENT_PREVIEW_TITLE_CHANGED,
    DOCUMENT_PREVIEW_STATUS_CHANGED,
    TASK_DETAILS_CLOSING
} from "../store/actionTypes";
import * as Api from 'utils/api/api';
import {handleError, insufficientRightsError} from 'utils/errorHandle.function';
import _ from "lodash";
import {TASK_ACTIONS} from "utils/constants";


export function tasksDetailsLoaded(taskDetails = {}) {
    return {
        type: TASK_DETAILS_LOADED,
        data: taskDetails
    };
}

export function taskDetailsLoading(tasksArray = []) {
    return {
        type: TASK_DETAILS_LOADING,
        data: tasksArray
    };
}

export function taskDetailsClosing(tasksArray = []) {
    return {
        type: TASK_DETAILS_CLOSING
    };
}

export function tasksDetailsLoadingFailed(error) {
    return {
        type: TASK_DETAILS_LOADING_ERROR,
        data: error
    };
}

export function historyTasksDetailsLoadingFailed(error) {
    return {
        type: TASK_DETAILS_LOADING_ERROR,
        data: error
    }
}

export function workflowDetailsLoadingFailed(error) {
    return {
        type: WORKFLOW_DETAILS_LOADING_ERROR,
        data: error
    }
}

export function selectedDocumentChanged(document, index) {
    return {
        type: CHANGE_SELECTED_DOCUMENT,
        data: document,
        index: index
    }
}

export function removeSelectedDocument() {
    return {
        type: CHANGE_SELECTED_DOCUMENT,
        data: {},
        index: 0
    }
}

export function historyTasksDetailsLoaded(historyTaskDetails = {}) {
    return {
        type: TASK_DETAILS_LOADED,
        data: historyTaskDetails
    };
}

export function documentDetailsLoaded(documentDetails = {}) {
    return {
        type: TASK_DETAILS_LOADED,
        data: documentDetails
    };
}

export function hasEditedDocument() {
    return {
        type: HAS_EDITED_DOCUMENT,
        data: true

    }
}

export function workflowDetailsLoaded(data) {
    return {
        type: WORKFLOW_DETAILS_LOADED,
        data: data

    }
}

export function workflowDetailsLoading() {
    return {
        type: WORKFLOW_DETAILS_LOADING
    }
}

export function workflowHistoryLoaded(data) {
    return {
        type: WORKFLOW_HISTORY_LOADED,
        data: data

    }
}

export function updateDocumentVersion(version) {
    return {
        type: UPDATE_DOCUMENT_VERSION,
        data: version

    }
}

export function taskDocumentDataLoading() {
    return {
        type: TASK_DOCUMENT_DATA_LOADING
    }
}

export function taskDocumentDataLoaded(data) {
    return {
        type: TASK_DOCUMENT_DATA_LOADED,
        data: data
    }
}

export function taskDocumentDataLoadingFailed(error) {
    return {
        type: TASK_DOCUMENT_DATA_LOADING_ERROR,
        data: error
    }
}

export function taskCommentsLoading() {
    return {
        type: TASK_COMMENTS_LOADING
    }
}

export function taskCommentsLoaded(data) {
    return {
        type: TASK_COMMENTS_LOADED,
        data: data
    }
}

export function taskCommentStartSaving() {
    return {
        type: TASK_COMMENT_START_SAVING
    }
}

export function taskCommentSaving() {
    return {
        type: TASK_COMMENT_SAVING
    }
}

export function taskCommentSaved() {
    return {
        type: TASK_COMMENT_SAVED
    }
}

export function taskCommentSavingError() {
    return {
        type: TASK_COMMENT_SAVING_ERROR,
    }
}

export function changeUnsavedComment(comment) {
    return {
        type: CHANGE_UNSAVED_COMMENT,
        data: comment
    }
}

export function changeZoomPercentage(zoom) {
    return {
        type: CHANGE_ZOOM_PERCENTAGE,
        data: zoom
    }
}

export function changeImageZoomPercentage(zoom) {
    return {
        type: CHANGE_IMAGE_ZOOM_PERCENTAGE,
        data: zoom
    }
}

export function changeRotation(rotation) {
    return {
        type: CHANGE_ROTATION,
        data: rotation
    }

}

export function updateTaskDetailsAfterAction(taskId, action) {
    return function (dispatch) {
        dispatch(getTaskDetails(taskId, true, action));
    }
}

export function cacheAttachmentsImagesUrls(attachmentIndex, imageIndex, imageURL) {
    return {
        type: CACHE_ATTACHMENTS_IMAGES_URLS,
        data: {attachmentIndex, imageIndex, imageURL}
    }

}

export function documentPreviewDetach() {
    return {
        type: DOCUMENT_PREVIEW_DETACH
    }
}

export function documentPreviewReattach() {
    return {
        type: DOCUMENT_PREVIEW_REATTACH
    }
}

export function documentPreviewTitleChanged(title) {
    return {
        type: DOCUMENT_PREVIEW_TITLE_CHANGED,
        data: title
    }
}

export function documentPreviewStatusChanged(status) {
    return {
        type: DOCUMENT_PREVIEW_STATUS_CHANGED,
        data: status
    }
}

export function integrationIframeHasUnsavedChanges(hasChanges) {
    return {
        type: IFRAME_HAS_CHANGES,
        data: hasChanges
    }
}

export function commentsHaveUnsavedChanges(hasChanges) {
    return {
        type: COMMENTS_HAVE_CHANGES,
        data: hasChanges
    }
}

export function iframeHasVBNXTFlag(hasFlag) {
    return {
        type: IFRAME_HAS_VBNXT_SAVE_DISCARD_FLAG,
        data: hasFlag
    }
}

export function getTaskDetails(taskId, isSilent = false, action = null) {
    return function (dispatch) {

        if (!isSilent)
            dispatch(taskDetailsLoading());

        return Api.getTaskDetails(taskId).then(
            response => {
                dispatch(handleTaskDetailsResponse(response, taskId, action));
            }, error => {
                dispatch(tasksDetailsLoadingFailed(error));
                handleError(error.errorCode === 304 ? insufficientRightsError : error);
                //throw error;
            }
        )
    }
}

function handleTaskDetailsResponse(response, taskId, action) {
    return function (dispatch) {
        let value = {
            taskDetails: response,
            attachments: response.attachmentCollection ? response.attachmentCollection : null
        };
        dispatch(tasksDetailsLoaded(value));

        if (action === TASK_ACTIONS.ADD_ATTACHMENT) {
            let selectedDocument = response.attachmentCollection.attachments[response.attachmentCollection.attachments.length - 1];
            dispatch(selectedDocumentChanged(selectedDocument, response.attachmentCollection.attachments.length - 1));
        }

        if (response.attachmentCollection && response.attachmentCollection.attachments)
            dispatch(_reloadFromCIR(taskId, response.attachmentCollection.attachments, getTaskDetails));
    }
}

export function getHistoryTaskDetails(taskId, isSilent = false) {
    return function (dispatch) {

        if (!isSilent)
            dispatch(taskDetailsLoading());
        let value = {};

        //get the attachments, these are separate for a history task
        const getAttachements = Api.getHistoryAttachments(taskId).then(
            response => {
                value.taskDetails = Object.assign({}, value.taskDetails, response);
                value.attachments = response.attachmentCollection ? response.attachmentCollection : null;

                if (response.attachmentCollection && response.attachmentCollection.attachments)
                    dispatch(_reloadFromCIR(taskId, response.attachmentCollection.attachments, getHistoryTaskDetails));
            },
            error => {
                dispatch(historyTasksDetailsLoadingFailed(error));
                handleError(error);
                //throw error;
            }
        );

        //Beacuse we need to know if the editor is visible or not we need to have this call here
        //response.externalEditorAvailable is the value we need
        const getDocumentInfo = Api.getHistoryDocumentInfo(taskId).then(response => {
                value.taskDetails = Object.assign({}, value.taskDetails, response);
            },
            error => {
                handleError(error);
                // throw error;
            }
        );

        return Promise.all([getAttachements, getDocumentInfo]).then(
            () => {
                return dispatch(historyTasksDetailsLoaded(value));
            }
        );
    }
}

export function getDocumentOverviewDetails(taskId, isExternalEditorAvailable) {
    return function (dispatch) {
        dispatch(taskDetailsLoading());
        let value = {};

        const getDocumentInfo = Api.getDocumentOverviewDetails(taskId).then(
            response => {
                response.externalEditorAvailable = isExternalEditorAvailable;
                value.taskDetails = response;
            },
            error => {
                dispatch(tasksDetailsLoadingFailed(error));
                handleError(error);
                //  throw error;
            }
        );

        const getAttachments = Api.getDocumentOverviewAttachments(taskId).then(response => {
                value.attachments = response.attachmentCollection;
                if (response.attachmentCollection && response.attachmentCollection.attachments)
                    dispatch(_reloadFromCIR(taskId, response.attachmentCollection.attachments, getDocumentOverviewDetails));
            },
            error => {
                //back-end doesn't send a json error it's plain text so the handling will fail, there should be a back-end task to fix this
                // dispatch(handleError(newError));
            }
        );


        Promise.all([getDocumentInfo, getAttachments]).then(
            () => {
                value.taskDetails.attachmentCollection = value.attachments;
                dispatch(documentDetailsLoaded(value));
            }
        );

    }
}

export function getWorkflowDetails(taskId, isDocument) {
    return function (dispatch) {
        dispatch(workflowDetailsLoading());
        if (isDocument) {
            return Api.getWorkflowGraphDataForDocumentOverview(taskId).then((response) => {
                const {events, ...workflowData} = response
                dispatch(workflowDetailsLoaded(workflowData));
                dispatch(workflowHistoryLoaded({events}));
            }, error => {
                dispatch(workflowDetailsLoadingFailed(error));
                handleError(error);
            })
        } else {
            return Api.getWorkflowGraphData(taskId).then((response) => {
                const {events, ...workflowData} = response
                dispatch(workflowDetailsLoaded(workflowData));
                dispatch(workflowHistoryLoaded({events}));
                // eventBus.dispatch('@@grid/refreshGridLayout');
            }, error => {
                dispatch(workflowDetailsLoadingFailed(error));
                handleError(error);
            })
        }
    }
}

function _reloadFromCIR(taskId, attachments, callback, isExternalEditorAvailable) {
    return function (dispatch) {
        let hasProgress = _.findIndex(attachments, (att) => {
            return att["statusPerPage"] ? att["statusPerPage"]["IN_PROGRESS"] : null;
        });
        if (hasProgress !== -1) {
            setTimeout(() => {
                if (window.location.pathname.indexOf(taskId) !== -1)
                    dispatch(callback(taskId, true, isExternalEditorAvailable));

            }, 7000);
        }
    }
}


export function getTaskDocumentData(taskId, isDocument) {
    return function (dispatch) {
        dispatch(taskDocumentDataLoading());
        if (isDocument) {
            return Api.getDocumentOverviewDetails(taskId).then((response) => {
                dispatch(taskDocumentDataLoaded(response));
            }, error => {
                dispatch(taskDocumentDataLoadingFailed(error));
                handleError(error);
            });
        } else {
            return Api.getTaskDocumentDetails(taskId).then((response) => {
                dispatch(taskDocumentDataLoaded(response));
            }, error => {
                dispatch(taskDocumentDataLoadingFailed(error));
                handleError(error);
            })
        }
    }
}

export function getTaskCommentsData(taskId, skipLoading = false) {
    return function (dispatch) {
        if (!skipLoading) {
            dispatch(taskCommentsLoading());
        }
        Api.getTaskComments(taskId).then((response) => {
            dispatch(taskCommentsLoaded(response));
        }, error => {
            handleError(error);
        })
    }
}

export function getDocumentCommentsData(documentId, skipLoading = false) {
    return function (dispatch) {
        if (!skipLoading) {
            dispatch(taskCommentsLoading());
        }
        Api.getDocumentComments(documentId).then((response) => {
            dispatch(taskCommentsLoaded(response));
        }, error => {
            handleError(error);
        })
    }
}

export function handleAddTasksComments(taskKey, comment) {
    return function (dispatch) {
        dispatch(taskCommentSaving());
        Api.insertTasksComment(taskKey, comment).then(() => {
            dispatch(getTaskCommentsData(taskKey, true));
            dispatch(taskCommentSaved());
        }, error => {
            handleError(error);
            dispatch(taskCommentSavingError());
        })
    }
}

export function handleAddDocumentsComments(documentKey, comment) {
    return function (dispatch) {
        dispatch(taskCommentSaving());
        Api.insertDocumentsComment(documentKey, comment).then(() => {
            dispatch(getDocumentCommentsData(documentKey, true));
            dispatch(taskCommentSaved());
        }, error => {
            handleError(error);
            dispatch(taskCommentSavingError());
        })
    }
}

export function handleAddProcessAndHistoryComments(processKey, comment) {
    return function (dispatch) {
        dispatch(taskCommentSaving());
        Api.insertProcessesComment(processKey, comment).then(() => {
            dispatch(getTaskCommentsData(processKey, true));
            dispatch(taskCommentSaved());
        }, error => {
            handleError(error);
            dispatch(taskCommentSavingError());
        })
    }
}