import React from 'react';
import {formatDate, formatDocumentOrganizationUnit} from '../../../../utils/valueFormatter.function';

export const SupplierDocumentDetailsDTO = ({details, translate}) => {
    let displayId = details.displayId ? details.displayId : details.documentId;
    return (
        <div>
            {details.description &&
            <div className="document-data-field">
                <div className="document-data-field-label">
                    {translate("documentData.supplierName")}
                </div>
                <div className="document-data-field-value"
                     title={details.description}>
                    {details.description}
                </div>
            </div>}

            <div className="document-data-divided">
                {details.submitterName &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.submitterName")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.submitterName}>
                        {details.submitterName}
                    </div>
                </div>}

                {details.createdDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.createdDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.createdDate)}
                    </div>
                </div>}

                {details.category &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.category")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.category}>
                        {details.category}
                    </div>
                </div>}

                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.documentID")}
                    </div>
                    <div className="document-data-field-value"
                         title={displayId}>
                        {displayId}
                    </div>
                </div>

                {details.corporateData &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.corporateId")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.corporateData}>
                        {details.corporateData}
                    </div>
                </div>}

                {details.address &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.address")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.address}>
                        {details.address}
                    </div>
                </div>}

                {details.contactInfo &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.phone")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.contactInfo}>
                        {details.contactInfo}
                    </div>
                </div>}

                {details.gln &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.gln")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.gln}>
                        {details.gln}
                    </div>
                </div>}

                {details.bankData &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.bankAccount")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.bankData}>
                        {details.bankData}
                    </div>
                </div>}

                {details.termsOfPayments &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.paymentTerms")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.termsOfPayments}>
                        {details.termsOfPayments}
                    </div>
                </div>}

                {details.documentOrganizationUnit &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.organizationUnit")}
                    </div>
                    <div className="document-data-field-value"
                         title={formatDocumentOrganizationUnit(details.documentOrganizationUnit)}>
                        {formatDocumentOrganizationUnit(details.documentOrganizationUnit)}
                    </div>
                </div>}
            </div>
        </div>
    )
};

export default SupplierDocumentDetailsDTO;

