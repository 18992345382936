import React from "react";
import commentsDefaultUserImage from "../../assets/comments-default-user.png";
import {toast} from 'react-toastify';
import copy from 'copy-to-clipboard';
import translate from "../translations/translations.wrapper.jsx";

export function UserProfileTooltip(props) {
    const setFallbackImage = function (event) {
        event.target.src = 'https://static-odp.visma.net/commoncomponents/vismaSkin/images/services/default/medium.png';
    }

    const copyEmail = function () {
        copy(props.user.email);

        toast(`✓ ${props.translate('taskDetail.copiedToClipboard')}`, {
            type: 'dark'
        });
    }

    const profilePictureBaseUrl = typeof window.ENVIRONMENT !== 'undefined' && window.ENVIRONMENT.PROFILE_PICTURE_BASE_URL;
    const profilePicture = props.user.connectUserId ? `${profilePictureBaseUrl}${props.user.connectUserId}?type=initials` : commentsDefaultUserImage;

    return (<div className="workflow-details-profile-tooltip" data-testid="workflow-details-profile-tooltip">
        <img src={profilePicture}
             width="32"
             height="32"
             alt="avatar"
             onError={(event) => {
                 setFallbackImage(event)
             }}
        />
        <div>
            <div className="workflow-details-profile-tooltip-name">
                {props.user.fullName}
            </div>
            {props.user.email &&
                <div className="workflow-details-profile-tooltip-email">
                    <a href={`mailto:${props.user.email}`}>
                        {props.user.email}
                    </a>
                    <span className="vismaicon vismaicon-sm vismaicon-copy vismaicon-dynamic"
                          onClick={copyEmail}/>
                </div>
            }
        </div>
    </div>)

}

const withTranslations = translate(UserProfileTooltip);

export default withTranslations;