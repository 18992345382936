import {TASK_ACTIONS,TASK_ACTIONS_SHORTCUTS,DOCUMENT_ACTIONS} from "utils/constants.js";

/**
 * wrapper around allowed/enabled actions for particular task, meanings are:
 * - 'not allowed' action is not possible under any circumstances
 * - 'disabled' action is not allowed for current task selection, for example REJECT when at least 2 tasks are selected
 */
export class AvailableActions {

    constructor() {
        /*
         key is the type of action, it's value is if it's enabled
         not allowed action means no such key
         */
        this.values = {};
    }

    /**
     * specifies allowed actions for this instance
     */
    init() {
        const asArray = Array.prototype.slice.call(arguments);
        let definition = {};
        asArray.forEach((arg) => {
            definition[arg] = false;
        })
        this.values = definition;
    }

    /**
     * enables all the actions
     */
    enableAll() {
        for (let key in this.values) {
            if (this.values.hasOwnProperty(key)) {
                this.values[key] = true;
            }
        }
    }

    /**
     * disables all actions
     */
    disableAll() {
        for (let key in this.values) {
            if (this.values.hasOwnProperty(key)) {
                this.values[key] = false;
            }
        }
    }

    /**
     * @param actions... actions to disable
     */
    disable() {
        const asArray = Array.prototype.slice.call(arguments);
        for (let key in this.values) {
            if (this.values.hasOwnProperty(key) && asArray.indexOf(key) !== -1) {
                this.values[key] = false;
            }
        }
    }

    /**
     * @param actions... actions to enable
     */
    enable() {
        const asArray = Array.prototype.slice.call(arguments);
        for (let key in this.values) {
            if (this.values.hasOwnProperty(key) && asArray.indexOf(key) !== -1) {
                this.values[key] = true;
            }
        }
    }


    isAllowed(action) {
        return this.values[action] !== undefined;
    }

    isEnabled(action) {
        return this.isAllowed(action) && this.values[action];
    }

    /**
     * get current definition
     * @return {*}
     */
    get() {
        return Object.assign({}, this.values);
    }

    /**
     * return all allowed actions with their status (enabled/disabled)
     * @return {Array}
     */
    getAll() {
        let out = [];
        for (let key in this.values) {
            if (this.values.hasOwnProperty(key)) {
                out.push(
                    {
                        type: key,
                        active: this.values[key],
                        shortKey:TASK_ACTIONS_SHORTCUTS[key]
                    }
                );

            }
        }
        return out;
    }

    removeFromButtonList(actions) {
        let out = [];
        for (let key in this.values) {
            if (this.values.hasOwnProperty(key) && actions.indexOf(key) === -1) {
                out.push(
                    {
                        type: key,
                        active: this.values[key],
                        shortKey:TASK_ACTIONS_SHORTCUTS[key]
                    }
                );

            }
        }
        return out;
    }

    getSplitButtons() {
        let listToIgnore = [TASK_ACTIONS.APPROVE, TASK_ACTIONS.REJECT, TASK_ACTIONS.APPROVE_BATCH];
        return this.removeFromButtonList(listToIgnore);
    }

    getReviewTaskSplitButtons() {
        let listToIgnore = [TASK_ACTIONS.APPROVE, TASK_ACTIONS.REJECT, TASK_ACTIONS.REVIEW];
        return this.removeFromButtonList(listToIgnore);
    }

    getDocumentSplitButtons() {
        let listToIgnore = [TASK_ACTIONS.APPROVE, TASK_ACTIONS.REJECT, TASK_ACTIONS.REVIEW,DOCUMENT_ACTIONS.SEND_FOR_APPROVAL];
        return this.removeFromButtonList(listToIgnore);
    }
    getApproveAndBatchSplitButtons() {
        let listToIgnore = [  TASK_ACTIONS.REJECT,
            TASK_ACTIONS.POSTPONE,
            TASK_ACTIONS.FORWARD,
            TASK_ACTIONS.REVIEW,
            TASK_ACTIONS.SEND_FOR_REVIEW,
            TASK_ACTIONS.EMAIL,
            TASK_ACTIONS.ADD_ATTACHMENT,
            TASK_ACTIONS.NEXT_TASK,
            TASK_ACTIONS.PREVIOUS_TASK];
        return this.removeFromButtonList(listToIgnore);
    }


    getApproveAndSignSplitButtons() {
        let listToIgnore = [  TASK_ACTIONS.REJECT,
            TASK_ACTIONS.POSTPONE,
            TASK_ACTIONS.FORWARD,
            TASK_ACTIONS.REVIEW,
            TASK_ACTIONS.SEND_FOR_REVIEW,
            TASK_ACTIONS.EMAIL,
            TASK_ACTIONS.ADD_ATTACHMENT,
            TASK_ACTIONS.NEXT_TASK,
            TASK_ACTIONS.APPROVE_BATCH,
            TASK_ACTIONS.PREVIOUS_TASK];
        return this.removeFromButtonList(listToIgnore);
    }

    toString() {
        return this.values;
    }

}

export class MyTasksActions extends AvailableActions {

    constructor(param = undefined) {
        super();

        this.init(
            TASK_ACTIONS.APPROVE,
            TASK_ACTIONS.REJECT,
            TASK_ACTIONS.POSTPONE,
            TASK_ACTIONS.FORWARD,
            TASK_ACTIONS.REVIEW,
            TASK_ACTIONS.SEND_FOR_REVIEW,
            TASK_ACTIONS.EMAIL,
            TASK_ACTIONS.OPEN
        );

        if (param !== undefined && Array.isArray(param)) {
            this.loadActionsForTaskList(param);
        }

        if (param !== undefined && param.constructor === Object) {
            this.enableFromTask(param);
        }
    }

    /**
     * enables all the actions, which are allowed in the passed task
     * @param task
     */
    enableFromTask(task) {
        task.taskActions.forEach((allowedAction) => {
            this.enable(allowedAction);
        });
        // add open & postpone & email
        this.enable(TASK_ACTIONS.POSTPONE, TASK_ACTIONS.EMAIL, TASK_ACTIONS.OPEN);
    }

    /**
     * decide about available actions for the selection of tasks
     * @param taskSelection array of tasks
     * @return {MyTasksActions}
     */
    loadActionsForTaskList(taskSelection) {
        const count = taskSelection.length;

        if (count > 1) {
            // only these 3 support multiple selection
            this.enable(TASK_ACTIONS.OPEN, TASK_ACTIONS.POSTPONE, TASK_ACTIONS.APPROVE);
            // and only approve can be forbidden on per-task basis, so find at least one, which doesn't support approve,
            // then remove approve too :)
            for (let i = 0; i < count; i++) {
                let task = taskSelection[i];
                const hasApproveAllowed = task.taskActions.some((allowedAction) => {
                    return allowedAction === TASK_ACTIONS.APPROVE;
                });
                if (!hasApproveAllowed) {
                    this.disable(TASK_ACTIONS.APPROVE);
                    break;
                }
            }
            // if just one, pick all from task and add those which are UI specific
        } else if (count === 1) {
            let task = taskSelection[0];
            // like for one task
            this.enableFromTask(task);
        } else if (count === 0) {
            // when nothing is selected, allow only 'handle all'
            this.enable(TASK_ACTIONS.OPEN);
        }
        return this;
    }
}

export class TaskDetailActions extends AvailableActions {
    constructor() {
        super();

        this.init(
            TASK_ACTIONS.REJECT,
            TASK_ACTIONS.POSTPONE,
            TASK_ACTIONS.FORWARD,
            TASK_ACTIONS.REVIEW,
            TASK_ACTIONS.SEND_FOR_REVIEW,
            TASK_ACTIONS.EMAIL,
            TASK_ACTIONS.ADD_ATTACHMENT,
            TASK_ACTIONS.APPROVE_BATCH,
            TASK_ACTIONS.APPROVE,
            TASK_ACTIONS.NEXT_TASK,
            TASK_ACTIONS.PREVIOUS_TASK
        );
    }

    /**
     * enables all the actions, which are allowed in the passed task
     * @param task
     */
    enableFromTaskDetail(task) {
        task.taskActions.forEach((allowedAction) => {
            this.enable(allowedAction);
        });
        // if(task.hasAccountingGrid) {
        //     this.enable(TASK_ACTIONS.OPEN_EDITOR);
        // }
        // TODO enableFromTaskDetail ~~ availability of add/delete attachment actions are hidden inside data for the task detail
    }

}

export class PreApprovalActions extends AvailableActions {
    constructor() {
        super();

        this.init(
            TASK_ACTIONS.APPROVE_SIGN,
            TASK_ACTIONS.APPROVE_BATCH,
            TASK_ACTIONS.APPROVE
        );
    }

    /**
     * enables all the actions, which are allowed in the passed task
     * @param task
     */
    enablePreApproval(task) {
        task.taskActions.forEach((allowedAction) => {
            this.enable(allowedAction);
        });
    }

}

export class DocumentDetailActions extends AvailableActions {
    constructor() {
        super();

        this.init(
            TASK_ACTIONS.REJECT,
            TASK_ACTIONS.POSTPONE,
            TASK_ACTIONS.FORWARD,
            TASK_ACTIONS.REVIEW,
            TASK_ACTIONS.SEND_FOR_REVIEW,
            TASK_ACTIONS.EMAIL,
            TASK_ACTIONS.ADD_ATTACHMENT,
            TASK_ACTIONS.APPROVE,
            DOCUMENT_ACTIONS.APPROVE_DIRECTLY,
            DOCUMENT_ACTIONS.REJECT_DIRECTLY,
            TASK_ACTIONS.NEXT_TASK,
            TASK_ACTIONS.PREVIOUS_TASK,
            DOCUMENT_ACTIONS.SEND_FOR_APPROVAL
        );
    }

    /**
     * enables all the actions, which are allowed in the passed task
     * @param task
     */
    enableFromTaskDetail(task) {
        task.taskActions.forEach((allowedAction) => {
            this.enable(allowedAction);
        });
    }

}


