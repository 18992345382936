import React, { useEffect } from "react";
import { MODAL_CONTENT_TAB_INDEX } from "utils/constants";
import OKCancelButtons from "components/modals/OKCancelButtons";
import * as reduxSelectors from "components/store/application.reducers";
import { useDispatch, useSelector } from "react-redux";
import { deleteAttachment } from "components/modals/modal.actions";
import { Store } from "redux";
import { DeleteActionData, DeleteAttachmentProps } from "types/modals";


const DeleteAttachment = ({ more, translate, closeModal, loopFocus, errorCallback}: DeleteAttachmentProps): React.ReactElement => {

    const commentInputRef = React.createRef<HTMLDivElement>();
    const selected = useSelector((store: Store) => reduxSelectors.getSelectedDocument(store));

    const dispatch = useDispatch();


    const keepFocus = () => {
        commentInputRef.current?.focus();
    };

    useEffect(() => {
        keepFocus();
    }, []);

    const deleteAction = () => {
        const currentTask = more?.tasks[0];
        const data: DeleteActionData = {
            key: currentTask.key,
            collectionGuid: currentTask.attachmentCollectionValues.latestAttachmentCollectionGuid,
            id: selected.id,
            deleteMessage: translate("popUp.deleteAttachment.hasBeenRemoved")
        };

        if (currentTask)
            dispatch(deleteAttachment(data,
                closeModal,
                errorCallback));
    };


    const okEnabled = true;
    return (
        <div>
            <h4 className="modal-title">{more?.title || translate("popUp.deleteAttachment.title")}</h4>
            <div className="modal-body ast_delete_attachment">
                <p>{translate("popUp.deleteAttachment.message")}</p>
            </div>
            <OKCancelButtons
                tabIndex={MODAL_CONTENT_TAB_INDEX + 1}
                okEnabled={okEnabled}
                okCallback={deleteAction}
                okText={translate("popUp.deleteAttachment.deleteButton")}
                cancelEnabled={true}
                cancelCallback={closeModal}
                cancelText={translate("popUp.deleteAttachment.keepButton")}
                loopFocus={loopFocus}
            />
        </div>
    );
};

export default DeleteAttachment;