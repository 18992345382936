import {UserSearch} from "../input/UserSearch.component";
import {DecisionTableRow, ApproversType} from "../decisionTables/decisionTableTypes";
import React from "react";
import {FormatterProps} from "react-data-grid";


type UserEditorProps = {
    translate: React.FC,
    p: FormatterProps<DecisionTableRow>

}

export function UserEditor(props: UserEditorProps) {


    return (<UserSearch propagateValue={(e: ApproversType[]) => {
            props.p.onRowChange({...props.p.row, approvers: e})
        }}
                        multi={true}
                        includeSameUser={true}
                        stopFocus={true}
                        value={props.p.row.approvers}
                        translate={props.translate}
                        disabled={true}
                        styles={{
                            container: () => ({
                                position: "static",
                                boxSizing: "border-box"
                            }),
                            menu: () => ({
                                backgroundColor:"white",
                                position: "fixed",
                                width: "50%",
                                right: 658
                            })
                        }}
        />
    )
}