import * as Api from "../../utils/api/api";
import {handleError} from "../../utils/errorHandle.function";
import {AppMenuAction, AppMenuItem} from "../../types/appMenuItem";
import {ApplicationError} from "../../types/applicationError";
import {Dispatch} from "redux";
import {ResultWrapper} from "../../types/response";

export function appMenuItemsLoading(): AppMenuAction {
    return {
        type: "APP_MENU_ITEMS_LOADING",
        data: null
    };
}

export function appMenuItemsLoaded(menuItems: Array<AppMenuItem>): AppMenuAction {
    return {
        type: "APP_MENU_ITEMS_LOADED",
        data: menuItems
    };
}

export function appMenuItemsError(error: ApplicationError): AppMenuAction {
    return {
        type: "APP_MENU_ITEMS_ERROR",
        data: error
    };
}

export function loadAppMenuItems(){
    return function(dispatch: Dispatch<AppMenuAction>) {
        dispatch(appMenuItemsLoading());
        Api.getAppMenuItems().then(
            (response: ResultWrapper<AppMenuItem> )=> {
                dispatch(appMenuItemsLoaded(response.result));
            }, (error : ApplicationError) => {
                dispatch(appMenuItemsError(error));
                handleError(error);
            }
        );
    }
}