import React from 'react';
import {formatAmountValue, formatDate, formatDocumentOrganizationUnit} from '../../../../utils/valueFormatter.function';
import _ from "lodash";
import DatePicker from 'react-flatpickr';
import moment from "moment";
import {formatWarnings} from "../formatWarnings";
import {PreApprovedDetails} from "./PreApprovalDetails.component";
import {WarningIcon, WarningsTooltip} from "./WarningsTooltip.component";

const INVOICE_DATE = "invoiceDate";
const DUE_DATE = "dueDate";

const WorkflowDocumentDetailsDTO = ({details, translate, saveData, userLanguage, classProp, readOnly}) => {
    const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

    let invoiceIsEditable = false;
    let dueDateIsEditable = false;

    if (details.editableFields && !readOnly) {
        invoiceIsEditable = _.findIndex(details.editableFields, (x => {
            return x === INVOICE_DATE
        })) !== -1;
        dueDateIsEditable = _.findIndex(details.editableFields, (x => {
            return x === DUE_DATE
        })) !== -1;
    }

    let dateChanged = function (type, value) {
        if (value && value !== 0) {
            let isDateValue = value instanceof Date && !isNaN(value.valueOf());
            if (!isDateValue) {
                return;
            } else {
                const hours = Math.abs(tzOffset / (60 * 60 * 1000));
                let data = Object.assign({}, details);

                data[type] = moment(value);
                data[type].set({hour: hours, minute: 0, second: 0, millisecond: 0});
                data[type] = data[type].toDate();

                saveData(data);
            }
        }
    };

    let dueDate = details.dueDate ? moment.utc(details.dueDate - tzOffset).toISOString(false).substring(0, 10) : undefined;
    let invoiceDate = details.invoiceDate ? moment.utc(details.invoiceDate - tzOffset).toISOString(false).substring(0, 10) : undefined;

    let formattedWarnings = formatWarnings(details.warnings);


    return (
        <div>
            {details.description &&
                <span>
                    <div className={`document-data-field ${formattedWarnings.description ? 'has-warning' : ''}`}
                         data-tip data-for="description-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.description")}
                            {formattedWarnings.description && WarningIcon}
                        </div>
                        <div className="document-data-field-value"
                             title={details.description}>
                            {details.description}
                        </div>
                    </div>
                    {formattedWarnings.description &&
                        <WarningsTooltip id="description-tooltip" warnings={formattedWarnings.description}/>
                    }
                </span>
            }
            <div className="document-data-divided">
                {details.supplierName &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.supplierName ? 'has-warning' : ''}`}
                         data-tip data-for="supplierName-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.supplierName")}
                            {formattedWarnings.supplierName && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.supplierName}>
                            {details.supplierName}

                        </div>
                    </div>
                    {formattedWarnings.supplierName &&
                    <WarningsTooltip id="supplierName-tooltip" warnings={formattedWarnings.supplierName}/>
                    }
                </span>
                }

                {details.supplierNumber &&
                <span>
                    <div
                        className={`document-data-field third ${formattedWarnings.supplierNumber ? 'has-warning' : ''}`}
                        data-tip data-for="supplierNumber-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.supplierNumber")}
                            {formattedWarnings.supplierNumber && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.supplierNumber}>
                            {details.supplierNumber}
                        </div>
                    </div>
                    {formattedWarnings.supplierNumber &&
                    <WarningsTooltip id="supplierNumber-tooltip" warnings={formattedWarnings.supplierNumber}/>
                    }
                </span>
                }

                {details.invoiceNumber &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.invoiceNumber ? 'has-warning' : ''}`}
                         data-tip data-for="invoiceNumber-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.invoiceNumber")}
                            {formattedWarnings.invoiceNumber && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.invoiceNumber}>
                            {details.invoiceNumber}
                        </div>
                    </div>
                    {formattedWarnings.invoiceNumber &&
                    <WarningsTooltip id="invoiceNumber-tooltip" warnings={formattedWarnings.invoiceNumber}/>
                    }
                </span>
                }

                {details.documentId &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.documentId ? 'has-warning' : ''}`}
                         data-tip data-for="documentId-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.documentID")}
                            {formattedWarnings.documentId && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.documentId}>
                            {details.documentId}
                        </div>
                    </div>
                    {formattedWarnings.documentId &&
                    <WarningsTooltip id="documentId-tooltip" warnings={formattedWarnings.documentId}/>
                    }
                </span>
                }

                {details.invoiceDate &&
                <span>
                    <div
                        className={`document-data-field input third ${formattedWarnings.invoiceDate ? 'has-warning' : ''}`}
                        data-tip data-for="invoiceDate-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.invoiceDate")}
                            {formattedWarnings.invoiceDate && WarningIcon}
                        </div>
                        <div className="document-data-field-value">
                            {invoiceIsEditable ? (
                                <div className="calendar-popup">
                                    <DatePicker
                                        options={{
                                            dateFormat: 'd/m/Y'
                                        }}
                                        value={new Date(invoiceDate)}
                                        onChange={([value]) => dateChanged(INVOICE_DATE, value)}
                                    />
                                </div>
                            ) : (formatDate(details.invoiceDate))}
                        </div>
                    </div>
                    {formattedWarnings.invoiceDate &&
                    <WarningsTooltip id="invoiceDate-tooltip" warnings={formattedWarnings.invoiceDate}/>
                    }
                </span>
                }

                {details.dueDate &&
                <span>
                    <div className={`document-data-field input third ${formattedWarnings.dueDate ? 'has-warning' : ''}`}
                         data-tip data-for="dueDate-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.dueDate")}
                            {formattedWarnings.dueDate && WarningIcon}
                        </div>
                        <div className="document-data-field-value">
                            {dueDateIsEditable ?
                                (<div>
                                    <div className="calendar-popup">
                                        <DatePicker
                                            options={{
                                                dateFormat: 'd/m/Y'
                                            }}
                                            value={new Date(dueDate)}
                                            onChange={([value]) => dateChanged(DUE_DATE, value)}
                                        />
                                    </div>
                                </div>) : (
                                    formatDate(details.dueDate)
                                )}
                        </div>
                    </div>
                    {formattedWarnings.dueDate &&
                    <WarningsTooltip id="dueDate-tooltip" warnings={formattedWarnings.dueDate}/>
                    }
                </span>
                }

                {details.paymentPreApprovalDetails &&
                <PreApprovedDetails
                    translate={translate}
                    paymentDetails={details.paymentPreApprovalDetails}
                />}

                {details.creditBankAccount &&
                <span>
                    <div
                        className={`document-data-field third ${formattedWarnings.creditBankAccount ? 'has-warning' : ''}`}
                        data-tip data-for="creditBankAccount-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.bankAccount")}
                            {formattedWarnings.creditBankAccount && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={details.creditBankAccount}>
                            {details.creditBankAccount}
                        </div>
                    </div>
                    {formattedWarnings.creditBankAccount &&
                    <WarningsTooltip id="creditBankAccount-tooltip" warnings={formattedWarnings.creditBankAccount}/>
                    }
                </span>
                }

                {details.amount !== null &&
                <span>
                    <div className={`document-data-field third ${formattedWarnings.amount ? 'has-warning' : ''}`}
                         data-tip
                         data-for="amount-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.amount")}
                            {formattedWarnings.amount && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value">
                            <b>{formatAmountValue(details.amount)} </b>
                            {details.currency}
                            {(details.foreignAmount && details.currency !== details.foreignCurrency) &&
                            <div>
                                <small>
                                    <span>{formatAmountValue(details.foreignAmount)} </span>
                                    {details.foreignCurrency}
                                </small>
                            </div>}
                        </div>
                    </div>
                    {formattedWarnings.amount &&
                    <WarningsTooltip id="amount-tooltip" warnings={formattedWarnings.amount}/>
                    }
                </span>
                }

                {details.documentOrganizationUnit &&
                <span>
                    <div
                        className={`document-data-field third ${formattedWarnings.documentOrganizationUnit ? 'has-warning' : ''}`}
                        data-tip data-for="documentOrganizationUnit-tooltip">
                        <div className="document-data-field-label">
                            {translate("documentData.documentOrganizationUnit")}
                            {formattedWarnings.documentOrganizationUnit && <WarningIcon/>}
                        </div>
                        <div className="document-data-field-value"
                             title={formatDocumentOrganizationUnit(details.documentOrganizationUnit)}>
                            {formatDocumentOrganizationUnit(details.documentOrganizationUnit)}
                        </div>
                    </div>
                    {formattedWarnings.documentOrganizationUnit &&
                    <WarningsTooltip id="documentOrganizationUnit-tooltip"
                                     warnings={formattedWarnings.documentOrganizationUnit}/>
                    }
                </span>
                }
            </div>
        </div>
    )
};

export default WorkflowDocumentDetailsDTO;
