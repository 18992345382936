import React, {useState} from 'react';
import * as reduxSelectors from "../store/application.reducers";
import {useSelector, useDispatch} from "react-redux";
import {loadAppMenuItems} from "./menuAppList.action";
import {AppMenuItem} from "../../types/appMenuItem";

export default function MenuAppList() {
    const VISMA_NET = "Visma.net";
    const APPROVAL = "Approval";
    const dispatch = useDispatch();
    const appMenuItems = useSelector(reduxSelectors.getAppMenuItems);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const requestFromBackend = function () {
        if(appMenuItems.length === 0) {
            dispatch(loadAppMenuItems());
        }
    }

    const onClickDropdown = function () {
        setDropdownOpen(!dropdownOpen);
        requestFromBackend();
    }

    const onBlurDropdown = function(e: React.FocusEvent<HTMLDivElement>) {
        /* without check for currentTarget/activeElement we would also unBlur for clicks inside dropdown
         * inspiration from here: https://gist.github.com/pstoica/4323d3e6e37e8a23dd59
         * also, we have to add tabIndex to component, to be focusable, otherwise document.activeElement would be 'body'
         **/
        e.stopPropagation();
        let currentTarget = e.currentTarget;
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                setDropdownOpen(false);
            }
        }, 300);
    }

    const computeDropdownSelected = function () {
        const selectedApplication = appMenuItems.find((item: AppMenuItem) => item.selected);
        return VISMA_NET + " " + (selectedApplication ? selectedApplication.name : APPROVAL);
    }

    const computeDropDownMenuItems = function () {
        return appMenuItems.map(function (item: AppMenuItem) {
            return (
                <li key={item.name}>
                    <a className={item.selected ? "active" : ""} href={item.url}
                       onClick={(e) => e.stopPropagation()}>{item.name}</a>
                </li>
            );
        });
    }

    return (
        <div id="brand-list" className={"dropdown navbar-brand "  + (dropdownOpen ? 'open' : '')}
             onClick={onClickDropdown}
             onBlur={onBlurDropdown}>
            <a className="dropdown-toggle no-caret" role="button" aria-expanded={dropdownOpen}
               tabIndex={0}
               href={"#"}>
                    {computeDropdownSelected()}
                    <span className="caret"></span>
            </a>
            <ul className="dropdown-menu" role="menu">
                {computeDropDownMenuItems()}
            </ul>
        </div>
    );
}