// actions creators for redux store
import {TRANSLATIONS_LOADED} from '../store/actionTypes';
// have to move this to own file, we need to monkypatch this piece of implementation
import loadTranslationsImpl from './translations.action.impl';
import {showNotification} from '../notifications/notification.action';
import {getLanguage, getDictionaryForLanguage} from '../store/application.reducers';

/**
 * asynchronous action:
 *  - in case we have translations already, it emits switchLanguage with them
 *  - in case the language is same, it emits nothing
 *  - otherwise it emits switchLanguage when finishes loading
 * @param lang desired localization
 * @param forceReload reload translations, even if we have cached version
 * @param withNotification if we should emit transient notification
 * @returns {Function}
 * @see http://redux.js.org/docs/advanced/AsyncActions.html
 */
export function requestLanguageChange(lang, forceReload = false, withNotification = false) {

    return function (dispatch, getState) {

        let state = getState();
        let translationsWeHave = getDictionaryForLanguage(state, lang);
        let currentLanguage = getLanguage(state);

        // we're changing language to different or we haven't loaded any or we're forced
        if (currentLanguage !== lang || !translationsWeHave || forceReload) {
            // we already loaded this language and we're allowed to use them
            if (translationsWeHave && !forceReload) {
                dispatch(switchLanguage(lang, translationsWeHave));
                if(withNotification) {
                    dispatch(showNotification("Language set to: " + lang));
                }
            } else {
                // wrap the execution of dispatching into own function (command pattern)
                let command = function (lang, translations) {
                    dispatch(switchLanguage(lang, translations));
                };
                // another version with dispatching of notification
                if(withNotification) {
                    command = function (lang, translations) {
                        dispatch(switchLanguage(lang, translations));
                        dispatch(showNotification("Language set to: " + lang));
                    };
                }

                loadTranslationsImpl(lang, command);
            }
        }

        return;
    }
}
/**
 * action which change the translations, eg change the localization of app
 * @param lang
 * @param translations
 * @return {{type, dictionary: *, lang: *}}
 */
export function switchLanguage(lang, translations) {

    return {
        type: TRANSLATIONS_LOADED,
        dictionary: translations,
        lang: lang
    }
}