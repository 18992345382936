import {ADD_NOTIFICATION, REMOVE_NOTIFICATION} from '../store/actionTypes';
import {seamlessLoadTasks} from "../mytasks/myTasks.action";

import {v4 as uuid} from 'uuid';


export const NOTIFICATION_TYPE = {
    POSITIVE_TRANSIENT: 1,
    NEGATIVE_PERMANENT: 2
};

/**
 *
 * @param data
 * @param id optional
 * @param type optional
 * @return {{type: string, data: *}}
 */
export function showNotification(data, id = null, type = NOTIFICATION_TYPE.POSITIVE_TRANSIENT) {
    let messageId = (id === null) ? uuid() : id;
    return {
        type: ADD_NOTIFICATION,
        data: {
            id: messageId,
            data: data,
            type: type,
            created: new Date().getTime() // so we can order them when displaying (they are stored as object in redux store)
        }
    };
}

export function showNotificationWithAction(data, action, id = null, type = NOTIFICATION_TYPE.POSITIVE_TRANSIENT) {
    let messageId = (id === null) ? uuid() : id;
    return {
        type: ADD_NOTIFICATION,
        data: {
            id: messageId,
            data: data,
            type: type,
            action: action,
            created: new Date().getTime() // so we can order them when displaying (they are stored as object in redux store)
        }
    };
}

export function dispatchHideNotification(id) {
    return {
        type: REMOVE_NOTIFICATION,
        id: id
    }
}

export function hideNotification(id) {
    return function (dispatch) {
        dispatch(dispatchHideNotification(id));
        dispatch(seamlessLoadTasks());
    }
}