import React, {Component} from 'react';
import {DOCUMENT_TYPE} from "utils/constants";
import StepSettings from "./StepSettings.component";
import StepRule from "./StepRule.component";

import AmountCondition from "./AmountCondition.Component";
import {DaysCondition} from "./DaysCondition.Component";
import * as _ from "lodash";

export default class Step extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideContent: false,
            editing: false
        };
        this.toggle = this.toggle.bind(this);
        this.swapSteps = this.swapSteps.bind(this);
        this.changeStepSettings = this.changeStepSettings.bind(this);
        this.editMode = this.editMode.bind(this);
        this.removeStep = this.removeStep.bind(this);
        this.changeAmountCondition = this.changeAmountCondition.bind(this);
        this.deleteStepRule = this.deleteStepRule.bind(this);
        this.addRule = this.addRule.bind(this);
    }

    removeStep() {
        this.props.removeStep(this.props.step.idStep);
    }

    toggle() {
        this.setState({
            hideContent: !this.state.hideContent
        });
    }

    swapSteps(oldValue, newValue) {
        this.props.swapSteps(oldValue, newValue);
    }

    changeStepSettings(property, value, isComplete) {
        let newStepValue = Object.assign({}, this.props.step);
        newStepValue[property] = value;

        this.props.saveStepSettings(newStepValue, isComplete);
    }

    editMode(editing) {
        this.setState({
            editing: editing
        });

        this.props.editStep(this.props.step.stepNumber, editing);
    }

    changeAmountCondition(condition, isComplete) {
        this.changeStepSettings("condition", condition, isComplete);
    }

    deleteStepRule(idRule) {
        let newStepValue = Object.assign({}, this.props.step);
        let ruleIndex = _.findIndex(newStepValue.rules, rule => {
            return rule.idRule === idRule
        });
        if (ruleIndex !== -1) {
            newStepValue.rules.splice(ruleIndex, 1);
        }

        this.props.saveStepSettings(newStepValue);
    }

    addRule(stepId) {
        let newRule = {
            action: null,
            idStep: stepId,
            idRule: null,
            name: "NewRule",
            ruleNumber:  this.props.step.rules.length + 1,
            userMap: {}
        };
        let newStepValue = _.cloneDeep(this.props.step);
        newStepValue.rules.push(newRule);

        this.props.saveStepSettings(newStepValue, false);
    }

    render() {
        let {type, step, translate} = this.props;

        let stepClass = "col-md-12 mb-4 step " + (this.state.hideContent ? " collapsed" : "uncollapsed");
        if (this.state.editing)
            stepClass += " editing";

        return (
            <div className={stepClass}
                 ref="step">

                <StepHeader
                    stepName={step.name}
                    idRuleConfiguration={step.idRuleConfiguration}
                    isEditing={this.props.isEditing}
                    stepNumber={(step.stepNumber + 1)}
                    numSteps={this.props.numSteps}
                    collapsed={this.state.hideContent}
                    docType={type}
                    toggle={this.toggle}
                    translate={this.props.translate}
                    swapSteps={this.swapSteps}
                    changeStepName={this.props.changeStepName}
                    recordVersion={step.recordVersion}
                    editMode={this.editMode}
                    removeStep={this.removeStep}
                    canDelete={this.props.canDelete}
                    itemIndex={this.props.itemIndex}
                />

                <div className="row">
                    <div className="col-md-12">
                        {type === DOCUMENT_TYPE.LEAVE_REQUEST ?
                            <DaysCondition translate={this.props.translate} condition={step.condition}
                                           isStepLevel={true}
                                           changeAmountCondition={this.changeAmountCondition}/> :
                            <AmountCondition translate={this.props.translate}
                                             condition={step.condition}
                                             isStepLevel={true}
                                             changeAmountCondition={this.changeAmountCondition}
                                             stepNumber={step.stepNumber}/>}
                    </div>
                </div>


                <div className={"toggleable-content" + (this.state.hideContent ? " toggled" : "")}>

                    {step.rules ? step.rules.map((rule, index) => {
                        return <StepRule type={type}
                                         key={`step-${step.stepNumber}-rule-${rule.idRule || rule.ruleNumber || index}`}
                                         translate={translate}
                                         action={rule.action}
                                         index={index}
                                         idStep={rule.idStep}
                                         idRule={rule.idRule}
                                         ruleNumber={rule.ruleNumber}
                                         condition={rule.condition}
                                         deleteStepRule={this.deleteStepRule}
                                         addRule={index === step.rules.length - 1 ? this.addRule : null}
                                         canDelete={(step.rules.length > 1)}
                                         stepNumber={step.stepNumber}/>
                    }) : []
                    }

                    <StepSettings type={type} step={step} translate={translate}
                                  changeSetting={this.changeStepSettings}/>

                </div>
            </div>
        )
    }
};

class StepHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepNumber: this.props.stepNumber,
            stepName: this.props.stepName,
            saveDisabled: false
        };
        this.save = this.save.bind(this);
        this.changeStepName = this.changeStepName.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.recordVersion !== prevProps.recordVersion || this.props.stepName !== prevProps.stepName) {
            this.setState({
                stepNumber: this.props.stepNumber,
                stepName: this.props.stepName
            });
        }
    }

    changeStepName(event) {
        let newName = event.target.value;
        let disableSave = false;

        if (newName.length > 80)
            newName = newName.substr(0, 80);

        if (!newName) {
            disableSave = true;
        }
        this.setState({
            stepName: newName,
            saveDisabled: disableSave
        }, () => {
            this.save()
        });
    }

    save() {
        //update the step here
        if (this.props.stepNumber !== this.state.stepNumber) {
            this.props.swapSteps(this.props.stepNumber, this.state.stepNumber);
        }
        if (this.props.stepName !== this.state.stepName) {
            this.props.changeStepName(this.props.itemIndex, this.state.stepName);
        }
        this.props.editMode(false);
    }

    render() {
        let {toggle, translate, docType, collapsed, numSteps} = this.props;
        let {stepName} = this.state;
        let toggleIconClass = "glyphicon font-18 margin-right glyphicon-menu-" + (collapsed ? "right" : "down");
        let docImageClass = "me-4 appicon appicon-block document-type-icon-24 document-type-icon document-type-" + DOCUMENT_TYPE.asString(docType) + "-24";

        return (
            <div className={`row ${numSteps > 1 ? "step-header" : ""}`}>
                <div className="col-xs-8 d-flex align-items-center">
                    <span className={toggleIconClass} onClick={toggle}/>
                    <span className={docImageClass}/>
                    <span className={"w-100"}>
                        <strong className="me-3">{translate("workflowDetails.step") + " " + (this.props.itemIndex + 1) + " : "}</strong>
                        <input type="text" className="step-name-input" value={stepName} onChange={this.changeStepName}/>
                    </span>
                </div>
                <div className="col-xs-4 d-flex align-items-center justify-content-end">
                    <span
                        className={"vismaicon vismaicon-delete vismaicon-dynamic" + (!this.props.canDelete ? " disabled" : '')}
                        onClick={this.props.removeStep}
                        title={translate("workflows.delete")}
                    />
                </div>
            </div>
        )

    }
}