import {
    ALL_COST_UNITS_LOADED,
    CONFIG_ROLES_LOADED,
    ORGANIZATION_CHART_LOADED,
    REMOVE_ACTIVE_WORKFLOW,
    RENAME_WORKFLOW_RULE,
    REMOVE_STEP,
    SET_STEP_SETTINGS,
    SET_STEP_CHANGES,
    SET_ACTIVE_WORKFLOW,
    SET_ACTION,
    SORT_WORKFLOW_STEPS,
    SET_STEP_EXCEPTIONS,
    STEP_USERS_LOADED,
    SET_STEP_RULE,
    ENABLED_FEATURES_FOR_DOCUMENT_TYPE_LOADED,
    SET_STEP_RULE_CONDITION,
    SET_4_EYES_ENABLED,
    SET_4_EYES_FALLBACK_USERS,
    SET_STEP_RULE_CONDITION_BY_NUMBER,
    OLD_WORKFLOW_DETAILS_LOADED
} from '../store/actionTypes';

import * as Api from '../../utils/api/api';
import {handleError} from 'utils/errorHandle.function';
import * as _ from "lodash";
import {showNotification} from "../notifications/notification.action";
import {translate} from "../translations/translations.wrapper.jsx";
import {getWorkflows} from "../workflows/workflows.action";


export function ruleConfigurationRolesLoaded(roles) {
    return {
        type: CONFIG_ROLES_LOADED,
        data: roles
    }
}

export function costUnitsLoaded(costUnits = []) {
    return {
        type: ALL_COST_UNITS_LOADED,
        data: costUnits
    };
}

export function organizationChartLoaded(organizationChart = []) {
    return {
        type: ORGANIZATION_CHART_LOADED,
        data: organizationChart
    };
}

export function renameRule(name) {
    return {
        type: RENAME_WORKFLOW_RULE,
        data: name
    };
}

export function removeActiveWorkflow() {
    return {
        type: REMOVE_ACTIVE_WORKFLOW
    };
}

export function removeStep(id) {
    return {
        type: REMOVE_STEP,
        data: id
    };
}

export function sortSteps(steps, workflowID) {
    return {
        type: SORT_WORKFLOW_STEPS,
        data: _.orderBy(steps, 'stepNumber', 'asc'),
        workflowId: workflowID
    };
}

export function setActiveWorkflow(workflow) {
    return {
        type: SET_ACTIVE_WORKFLOW,
        data: workflow
    }
}

export function set4EyesEnabled(value) {
    return {
        type: SET_4_EYES_ENABLED,
        data: value
    }
}

export function set4EyesFallbackUsers(value) {
    return {
        type: SET_4_EYES_FALLBACK_USERS,
        data: value
    }
}

export function setStepAction(stepId, ruleId, rule, stepNumber = -1, ruleNumber = -1, isComplete = true) {
    return {
        type: SET_ACTION,
        stepId: stepId,
        ruleId: ruleId,
        rule: rule,
        stepNumber: stepNumber,
        ruleNumber: ruleNumber,
        isComplete: isComplete
    };
}

export function setStepCondition(stepId, ruleId, condition, isComplete = true) {
    return {
        type: SET_STEP_RULE_CONDITION,
        stepId: stepId,
        ruleId: ruleId,
        condition: condition,
        isComplete: isComplete
    };
}

export function setStepConditionByNumber(stepNumber, ruleNumber, condition, isComplete = true) {
    return {
        type: SET_STEP_RULE_CONDITION_BY_NUMBER,
        stepNumber: stepNumber,
        ruleNumber: ruleNumber,
        condition: condition,
        isComplete: isComplete
    };
}

export function setStepRule(stepId, ruleId, rule) {
    return {
        type: SET_STEP_RULE,
        stepId: stepId,
        ruleId: ruleId,
        rule: rule
    };
}

export function setStepSettings(step = {}, isComplete = true) {
    return {
        type: SET_STEP_SETTINGS,
        step: step,
        isComplete: isComplete
    };
}

export function stepUsersLoaded(data) {
    return {
        type: STEP_USERS_LOADED,
        data: data
    };
}

export function setStepExceptions(stepId, data) {
    return {
        type: SET_STEP_EXCEPTIONS,
        data: data,
        stepId: stepId
    };
}

export function setStepChange(workflow = {}) {
    return {
        type: SET_STEP_CHANGES,
        workflow: workflow
    };
}

export function enabledFeaturesForDocumentTypeLoaded(documentType, features) {
    return {
        type: ENABLED_FEATURES_FOR_DOCUMENT_TYPE_LOADED,
        features: features,
        documentType: documentType
    };
}

export function oldWorkflowDetailsLoaded(workflow) {
    return {
        type: OLD_WORKFLOW_DETAILS_LOADED,
        data: workflow
    };
}

export function getRuleConfigurationRoles() {
    return function (dispatch) {

        Api.getRuleConfigurationRoles().then(repsonse => {
            dispatch(ruleConfigurationRolesLoaded(repsonse));
        }, error => {
            handleError(error);
        });

    }
}


export function getCostUnits() {
    return function (dispatch) {
        Api.getCostUnitTypes().then(response => {
            dispatch(costUnitsLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}

export function getOrganizationChart() {
    return function (dispatch) {
        Api.getOrganizationChart().then(response => {
            dispatch(organizationChartLoaded(response));
        }, error => {
            handleError(error);
        });
    }

}

export function getEnabledFeaturesForDocumentType(documentType, currentContextId) {
    return function (dispatch) {
        Api.getEnabledFeaturesForDocumentType(documentType, currentContextId).then(response => {
            dispatch(enabledFeaturesForDocumentTypeLoaded(documentType, response));
        }, error => {
            handleError(error);
        });
    }
}

export function activateReadonlyRule(rule) {
    return function (dispatch, getState) {
        const state = getState();

        Api.activateReadonlyRule(rule.idRuleConfiguration).then(response => {
            dispatch(setActiveWorkflow(response));
            dispatch(showNotification(translate(state, "workflows.workflowActivated")));
        }, error => {
            handleError(error);
        });
    }
}

export function deactivateReadonlyRule(rule) {
    return function (dispatch) {
        Api.deactivateReadonlyRule(rule.idRuleConfiguration).then(response => {
            dispatch(setActiveWorkflow(response));
        }, error => {
            handleError(error);
        });
    }
}

export function saveRule(rule, comment) {
    return function (dispatch, getState) {
        const state = getState();

        Api.saveRule(rule, comment).then(
            response => {
                if (response)
                    dispatch(setActiveWorkflow(response));
                dispatch(showNotification(translate(state, "workflows.workflowSaved")));
                dispatch(getWorkflows());
            }, error => {
                handleError(error);
                //dispatch(showNotification(translate(state, "workflows.workflowSaveError"), undefined, NOTIFICATION_TYPE.NEGATIVE_PERMANENT));
            });
    }
}

export function getUsersList(ids) {
    return async function (dispatch) {
        const users = [];

        for (const id of ids) {
            users.push(await Api.getCompanyUser(id));
        }

        return users;
    }
}

export function getAllUsers(id, step, nameNotFoundLabel) {
    return function (dispatch) {
        if (step.exceptions) {
            let allowAutomaticApproval = false;
            let getAllUsersExceptions = step.exceptions.map(exception => {
                let getEUsers = [];

                if (exception.users) {
                    getEUsers = exception.users.map(exception => {
                        return Api.getCompanyUser(exception).then(response => {
                            return {
                                valid: response.valid,
                                label: response.fullNameOrEmail || nameNotFoundLabel,
                                value: response.idUser
                            }
                        });
                    });
                }

                return Api.getCompanyUser(exception.user).then(response => {
                        return Promise.all(getEUsers).then(value => {
                            if (exception.automaticApproval) {
                                allowAutomaticApproval = true;
                            }

                            return {
                                valid: response.valid,
                                label: response.fullNameOrEmail || nameNotFoundLabel,
                                value: response.idUser,
                                users: value,
                                automaticApproval: exception.automaticApproval
                            };
                        });
                    }
                );

            });
            Promise.all(getAllUsersExceptions).then((value) => {

                    let data = {
                        stepId: id,
                        exceptions: value,
                        showAuto: allowAutomaticApproval

                    };

                    dispatch(stepUsersLoaded(data));

                }
            );
        }
    }
}


export function getOldWorkflowJSON(ruleId, version) {
    return function (dispatch) {
        Api.getOldWorkflowJSON(ruleId, version).then(response => {
            dispatch(oldWorkflowDetailsLoaded(response));
        }, error => {
            handleError(error);
        });
    }
}