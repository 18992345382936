import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";
import {Store} from "redux";
import {AttachmentsForRender} from "types/attachment";
import AttachmentRenderer from "./AttachmentRenderer";
import "./styles/bypassCIR.scss";
import "./styles/tabs.dark.scss";
import CollapsableTabs from "./CollapsableTabs";
import BypassCIRSkeleton from "./BypassCIR.Skeleton";
import MinMaxGroup from "components/taskdetails-remake/panels/attachments/MinMaxGroup";


interface BypassCIRProps {
    isAttachmentPanelMaxWidth: Boolean;
    isAttachmentPanelWidthDefault: Boolean;
    maximizePanel: React.FC;
    minimizePanel: React.FC;
    ownerDocument?: any;
}

export const BypassCIRContainer = ({...props}: BypassCIRProps): React.ReactElement => {
    const attachments: Array<AttachmentsForRender> = useSelector((store: Store) => reduxSelectors.getTaskAttachmentsForRender(store));
    const isPreviewDetached = useSelector((store: Store) => reduxSelectors.isDocumentPreviewDetached(store));
    const tabsRefs = useRef<(HTMLDivElement | null)[]>([]);
    let selectedDocument = useSelector((store: Store) => reduxSelectors.getSelectedDocument(store));
    let activeTab: number | null = selectedDocument.index;

    const localStorageValue = window.localStorage.getItem(`@@attinfo/is-panel-collapsed`) ? window.localStorage.getItem(`@@attinfo/is-panel-collapsed`).toLowerCase() === "true" : true;
    const [isPanelCollapsed, setIsPanelCollapsed] = useState<boolean>(localStorageValue);

    const setActiveTab = (value: number) => {
        tabsRefs.current.forEach((ref, i) => {
            if (!ref) return;
            activeTab = value;
            ref.classList.toggle('active', value === i);
            ref.classList.toggle('hidden', value !== i);
        });
    }

    const collapseCallback = () => {
        window.localStorage.setItem(`@@attinfo/is-panel-collapsed`, !isPanelCollapsed);
        setIsPanelCollapsed(!isPanelCollapsed);
    }

    const tabContent: React.ReactElement[] | React.ReactElement = attachments && Array.isArray(attachments) ? attachments.map((attachment: AttachmentsForRender, index: number) => {
        return activeTab === index ? <div
            className={`attachments-preview-modal__tab-content tab-content ${(activeTab === index) ? "active" : "hidden"}`}
            ref={ref => tabsRefs.current[index] = ref}
            role="tabpanel"
            aria-labelledby="selected-tab"
            key={`${attachment.id}_tab-pane`}
            aria-hidden={index === 1 ? "false" : "true"}>
            {!isPreviewDetached && <MinMaxGroup {...props} attachment={attachment}/>}
            <AttachmentRenderer attachment={attachment}
                                ownerDocument={props.ownerDocument}
                                isPanelCollapsed={isPanelCollapsed}
                                collapseCallback={collapseCallback}/>
        </div> : <div key={"not-active-tab_" + index}></div>;
    }) : <div key={"no-items"}></div>;

    if (!attachments || !Array.isArray(attachments) || attachments.length === 0) return (<BypassCIRSkeleton/>);
    if (attachments?.length === 1)
        return (<div className={`h-100 ${isPreviewDetached ? "":"no-tabs"}`} data-testid={"__tst_no_tabs"}>
            <div className={"no-tabs__wrap rounded-8 h-100"}>
                {!isPreviewDetached && <MinMaxGroup {...props} attachment={attachments[0]}/>}
                <AttachmentRenderer attachment={attachments[0]}
                                    ownerDocument={props.ownerDocument}
                                    isPanelCollapsed={isPanelCollapsed}
                                    collapseCallback={collapseCallback}/>
            </div>
        </div>);

    return (
        <div className={"h-100 rounded-8"}>
            <CollapsableTabs attachments={attachments}
                             activeTab={activeTab}
                             setActiveTab={setActiveTab}/>
            {tabContent}
        </div>
    )
}

export default BypassCIRContainer;

