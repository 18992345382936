import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {PopUp} from './PopUp.component';
import {HANDLED_TASK_POPUP_TYPE} from '../../utils/constants';
import {navigateToMyTasks} from '../router/router.function.js';

export class HandledTask extends PopUp {

    static propTypes = {
        closeCallback: PropTypes.func, //callback for closing the popup
        handleAction: PropTypes.func, //callback for closing the popup
        translate: PropTypes.func, // to translate texts
        hideClose: PropTypes.bool //do not show the close button
    };

    constructor(props) {
        super(props);
        this.buildContent = this.buildContent.bind(this);

        this.state = {
            comment: '',
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.okButton.focus();
    }

    getTitle() {
        return this.props.translate("popUp.handledTaskWarning.title");
    }

    buildContent() {

        return (
            <Fragment>
                <div className="modal-body">
                  <span>{this.props.translate("popUp.handledTaskWarning.message")}</span>
                </div>

                <div className="modal-footer">
                    <div className="float-start">
                        <button className={`btn btn-margin-right btn-primary`}
                                onClick={this.props.closeCallback}
                                ref={(element) => {
                                    this.okButton = element;
                                }}
                                tabIndex="2">{this.props.translate("popUp.handledTaskWarning.Close")}</button>
                        {!this.props.hideClose &&
                            <button className="btn btn-default"
                                    onClick={navigateToMyTasks}
                                    tabIndex="3">{this.props.translate("popUp.handledTaskWarning.goToTasks")}</button>
                        }
                    </div>
                </div> 
            </Fragment>

        );
    }

    getType() {
        if (this.props.popUpType)
            return this.props.popUpType;
        return HANDLED_TASK_POPUP_TYPE;
    }

}

export default HandledTask;
