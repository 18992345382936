/**
 * main filtering function
 * @param tasks
 * @param search
 * @param onlyForCompany
 * @return {*}
 */
import * as _ from 'lodash';

export function filterTasks(tasks, search, onlyForCompany) {
    let byCompany = filterByCompany(tasks, onlyForCompany);
    return filterBySearch(byCompany, search);
}

/**
 * partial filterTasks
 * @param tasks
 * @param search
 */
function filterBySearch(tasks, search) {
    if (!search) {
        return tasks;
    } else {
        search = search.toLowerCase();
        let couldBeNumeric = search.replace(/ /g, "");
        if (!isNaN(couldBeNumeric))
            search = couldBeNumeric;
    }

    let response= _.filter(tasks, (taskWrapper) =>
    {
        const task = taskWrapper.task;
        const attributesList = ["idProcess", "numberOfComments", "supplierName", "requesterName", "companyName", "description", "amount", "currency"];

        // fulltext search over all attributes in attributesList
        for (let index in attributesList) {

            //if (attribute === 'taskActions') continue; // internal attribute
            if (task[attributesList[index]] && task[attributesList[index]].toString().toLowerCase().indexOf(search) !== -1)
                return true;
        }
        return false;
    })

    return response;
}

/**
 * partial filterTasks
 * @param tasks
 * @param onlyForCompany
 */
function filterByCompany(tasks, onlyForCompany) {
    if (onlyForCompany === null) {
        return tasks;
    }

    return tasks.filter((taskWrapper) => {
        const task = taskWrapper.task;
        return (task.companyId === onlyForCompany);
    });
}
