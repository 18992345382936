import React from 'react';
import translate from '../translations/translations.wrapper.jsx';
import ConfigurationWrapper from '../configuration/ConfigurationWrapper.component';
import * as Api from '../../utils/api/api';
import {handleError} from '../../utils/errorHandle.function';
import Confirm from '../popup/ConfirmActionPopup.component';
import * as _ from 'lodash';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';

import {ProjectsRow} from "./ProjectRow.component";
import "./projects.scss";

export class Projects extends ConfigurationWrapper {

    constructor(props) {
        super(props);
        this.state = {
            headers: null,
            lines: null,
            possibleValues: [],
            version: undefined,
            displayNewRow: false,
            availableWidth: 0,
            okEnabled: false
        };
        this.listRef = null;
        this.addNewRow = this.addNewRow.bind(this);
        this.cancelNewRow = this.cancelNewRow.bind(this);
        this.checkLine = this.checkLine.bind(this);
        this.checkUnique = this.checkUnique.bind(this);
        this.confirmedDelete = this.confirmedDelete.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.getData = this.getData.bind(this);
        this.getNewLine = this.getNewLine.bind(this);
        this.makeNewRowVisible = this.makeNewRowVisible.bind(this);
        this.saveRow = this.saveRow.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.update = this.update.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount(props) {
        super.componentDidMount(props);
        this.updateDimensions();

        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount(props) {
        window.removeEventListener('resize', this.updateDimensions);
        super.componentWillUnmount(props);
    }


    checkUnique(row) {
        let sameRoles = [];
        let areEqual = false;

        for (let i = 0; i < this.state.lines.length; i++) {
            if (row.id !== this.state.lines[i].id && this.state.lines[i].idRole === row.idRole) {
                sameRoles.push(this.state.lines[i]);
            }
        }
        if (sameRoles.length > 0) {
            sameRoles.forEach(function (value, index) {
                let equal = true;
                value.dimensions.forEach(function (cell, index) {
                    if (row.dimensions[index].name !== cell.name) {
                        equal = false;
                        return false;
                    }
                });
                if (equal) {
                    areEqual = true;
                    return true;
                }

            });
        } else {
            return true;
        }
        return !areEqual;
    }

    checkLine(row) {
        if (!this.checkUnique(row))
            return this.props.translate("projects.selectCombination");

        if (!row.idRole)
            return this.props.translate("projects.selectRole");
        if (!row.approvers || row.approvers.length === 0)
            return this.props.translate("projects.selectRoleHolder");

        return null;
    }

    addNewRow(row) {
        this.update(row, true);
    }

    /*
    * @param row
    * @param isNewRow - boolean
    * */
    update(rowData, isNewRow) {
        let mapDimensions = rowData.dimensions.map(dim => {
            return dim.mdmGuid;
        });
        let mapped = {
            dimensions: mapDimensions,
            generalApprovers: rowData.approvers,
            headerVersion: this.state.version,
            id: isNewRow ? null : rowData.id,
            idRole: rowData.idRole,
            lineVersion: isNewRow ? null : rowData.version
        };

        Api.updateGeneralApprovers(mapped).then(response => {
            this.getData();
        }, error => {
            handleError(error);
            this.getData();
        });
    }

    cancelNewRow() {
        this.setState({
            displayNewRow: false,
            editMode: false
        });
    }

    confirmedDelete() {
        if (this.state.idToDelete)
            Api.deleteGeneralApprovers(this.state.idToDelete).then(
                response => {
                    this.getData();
                }, error => {
                    handleError(error);
                    this.getData();
                });

        this.setState({
            idToDelete: undefined,
            confirmDelete: false
        })
    }

    deleteRow(id) {

        this.setState({
            idToDelete: id,
            confirmDelete: true
        });
    }

    saveRow(row) {
        this.update(row, false);
    }


    makeNewRowVisible() {
        this.setState({
            displayNewRow: true,
            editMode: true
        }, ()=>{
            this.listRef.scrollTop = this.listRef.scrollHeight;
        });


    }


    getData() {
        Api.getGeneralApprovers().then(response => {

            let mappedValues = response.result.headers.map(header => {
                let index = _.find(Object.keys(response.result.possibleValues), (value) => {
                    return value === header.mdmGuid;
                });

                let result = response.result.possibleValues[index].map(value => {
                    return {value: value.mdmGuid, label: value.name}
                });
                result.push({value: 0, label: this.props.translate("projects.any")});

                return result;
            });

            this.setState({
                headers: response.result.headers,
                lines: _.sortBy(response.result.lines, function (line) {
                    if (line.dimensions[0])
                        return line.dimensions[0].name === null ? "ANY" : line.dimensions[0].name;
                    return "ANY";
                }),
                possibleValues: mappedValues,
                version: response.result.version,
                displayNewRow: false,
                editMode: false
            });
        }, error => {
            handleError(error);
        });

    }

    getNewLine() {
        if (this.state.lines) {
            let newLine = {};

            let newDimensions = this.state.headers.map(dim => {
                return {mdmGuid: null, name: null}
            });

            newLine.dimensions = newDimensions;
            newLine.approvers = [];
            newLine.idRole = undefined;
            return newLine;
        }
        return [];
    }

    toggleEditMode(state) {
        this.setState({
            editMode: state
        });
    }

    updateDimensions() {
        this.setState({
            availableWidth: this.refs.table.getBoundingClientRect().width - 568
        });
    }


    buildContent() {
        let readOnly = this.props.userRoles.systemViewer && !this.props.userRoles.systemAdministrator;
        let lines = this.state.lines ? this.state.lines.map((line, index) => {
                return (<ProjectsRow line={line} key={line.id}
                                     width={this.state.availableWidth}
                                     readOnly={readOnly}
                                     possibleValues={this.state.possibleValues}
                                     deleteRow={this.deleteRow}
                                     toggleEditMode={this.toggleEditMode}
                                     saveRow={this.saveRow}
                                     checkLine={this.checkLine}
                                     editMode={this.state.editMode}
                                     translate={this.props.translate}/>)
            })
            : <span/>;


        return (
            <div id="Projects" ref="table">
                <h3 className="no-border d-flex align-items-center pt-4">{this.props.translate("projects.header")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4"
                          title={this.props.translate("projects.headerInfo")}/>
                </h3>

                {this.state.confirmDelete &&
                    <Confirm translate={this.props.translate}
                             message={this.props.translate("projects.confirmDelete")}
                             handleAction={this.confirmedDelete}
                             closeCallback={() => {
                                 this.setState({confirmDelete: false})
                             }}/>}

                {this.state.headers ?
                    <div className="actions-list">
                        <HeaderRow headers={this.state.headers}
                                   width={this.state.availableWidth}
                                   translate={this.props.translate}/>
                        <div className="main-panel"
                             ref={(input) => {
                                 this.listRef = input;
                             }}>
                            {lines}
                            {this.state.displayNewRow &&
                                <ProjectsRow line={this.getNewLine()} key="newline"
                                             width={this.state.availableWidth}
                                             isNewRow={true}
                                             editMode={true}
                                             checkLine={this.checkLine}
                                             possibleValues={this.state.possibleValues}
                                             cancelNewRow={this.cancelNewRow}
                                             deleteRow={this.deleteRow}
                                             saveRow={this.addNewRow}
                                             readOnly={readOnly}
                                             toggleEditMode={this.toggleEditMode}
                                             translate={this.props.translate}/>
                            }
                        </div>
                        {!readOnly &&
                            <div className="row add-row border-0">
                                <div className="text-right col col-action-buttons">
                                    <span
                                        className={"vismaicon vismaicon-add-circle vismaicon-dynamic" + (this.state.editMode ? " disabled" : "")}
                                        title={this.props.translate("projects.add")}
                                        onClick={this.makeNewRowVisible}/>

                                </div>
                            </div>
                        }

                    </div> :
                    <span>{this.props.translate("generic.noData")}</span>
                }
            </div>
        )
    }
}

const withTranslations = translate(Projects);

const mapStateToProps = function (store) {
    return {
        currentContextId: reduxSelectors.getUsersCurrentContextId(store),
        userRoles: reduxSelectors.getUsersRoles(store)
    };
};

const connected = connect(mapStateToProps, {})(withTranslations);
export default connected;

const HeaderRow = (props) => {

    let headerClass = props.headers.length > 4 ? {width: "200px"} : {width: (Math.abs(props.width / props.headers.length) + "px")};

    let dimensionHeaders = props.headers ? props.headers.map((header, index) => {

        return <div className="col" key={index} style={headerClass}>{header.name}</div>;

    }) : <div className="col-md-12"/>;

    return (
        <div className="col header-row mb-2">
            {dimensionHeaders}
            <div className="col user-role">
                {props.translate("projects.approverRole")}
            </div>
            <div className="col user-email">
                {props.translate("projects.approverEmail")}
            </div>
            <div className="col text-right col-action-buttons">
                {props.translate("projects.actions")}
            </div>

        </div>
    )
};






