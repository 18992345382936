import {
    COST_UNIT_TYPE_REORDERED, COST_UNIT_TYPE_SELECTED,
    COST_UNIT_TYPE_UNSELECTED,
    COST_UNIT_TYPES_LOADED,
    COST_UNIT_TYPES_SAVED,
    COST_UNIT_ROLES_LOADED,
    COST_UNIT_WARNINGS_LOADED,
    APPROVERS_TREE_ROOT_LOADED,
    APPROVERS_NODES_LOADED,
    COST_UNIT_ROLE_TYPES_LOADED,
    COST_UNITS_ROLES_SORT_CHANGED,
    CLOSE_COST_UNIT_NODE,
    DOCUMENT_TYPES_LOADED, COST_UNITS_BRANCHES_LOADED
} from "../store/actionTypes";
import _ from 'lodash';
import {LOADING_STATUS} from 'utils/constants';

export const DEFAULT_STATE = {
    costUnitTypes: [],
    constUnitTypesVersion: null,
    selectedCostUnitTypes: [],
    costUnitRoles: null,
    costUnitBranches: [],
    warnings: [],
    approversTree: null,
    approversNodes: [],
    costUnitRoleTypes: [],
    documentTypes: [],
    assignApproversLoadingStatus: LOADING_STATUS.DONE,
    sort: {
        sortOrder: "asc",
        sortColumn: "role_name"
    }
};

export default function costUnitsReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {
        case COST_UNIT_TYPES_LOADED :
            const receivedCostUnits = action.data.result || [];
            const selectedCostUnitTypes = _.sortBy(receivedCostUnits.filter((item) => item.selected), 'ordinal');
            const costUnitTypes = _.sortBy(receivedCostUnits.filter((item) => !item.selected), 'dimension');
            partialState = {
                costUnitTypes: costUnitTypes,
                constUnitTypesVersion: action.data.etag || null,
                selectedCostUnitTypes: selectedCostUnitTypes,
                initialSelectedCostUnitTypes: selectedCostUnitTypes,
                costUnitRoles: null,
                approversNodes: []
            };
            return Object.assign({}, state, partialState);
        case COST_UNIT_TYPE_SELECTED :
            action.data.ordinal = state.selectedCostUnitTypes.length + 1;
            partialState = {
                selectedCostUnitTypes: state.selectedCostUnitTypes.concat([action.data]),
                costUnitTypes: state.costUnitTypes.filter((item) => item !== action.data)
            };

            return Object.assign({}, state, partialState);
        case COST_UNIT_TYPE_UNSELECTED :
            partialState = {
                costUnitTypes: _.sortBy(state.costUnitTypes.concat([action.data]), 'dimension'),
                selectedCostUnitTypes: state.selectedCostUnitTypes.filter((item) => item !== action.data)
            };
            partialState.selectedCostUnitTypes.forEach((item, index) => {
                item.ordinal = index + 1;
            });
            return Object.assign({}, state, partialState);
        case COST_UNIT_TYPES_SAVED :
            partialState = {
                costUnitTypesModified: false,
                initialSelectedCostUnitTypes: state.selectedCostUnitTypes
            };
            return Object.assign({}, state, partialState);
        case COST_UNIT_TYPE_REORDERED:
            const reorderedItem = state.selectedCostUnitTypes[action.data.oldIndex];
            let reorderedTypes = [...state.selectedCostUnitTypes];

            reorderedTypes.splice(action.data.oldIndex, 1);
            reorderedTypes.splice(action.data.newIndex, 0, reorderedItem);
            reorderedTypes.forEach((item, index) => {
                item.ordinal = index + 1;
            });

            partialState = {
                selectedCostUnitTypes: reorderedTypes
            };

            return Object.assign({}, state, partialState);

        case COST_UNIT_ROLES_LOADED:
            partialState = Object.assign({}, state);
            partialState.costUnitRoles = action.data;
            partialState.costUnitRoles.costUnitId = action.id;
            partialState.costUnitRoles.costUnitLevel = action.level;
            partialState.approversTree.selectedCostUnit = action.id;

            return Object.assign({}, state, partialState);

        case COST_UNITS_BRANCHES_LOADED:
            partialState = Object.assign({}, state);
            partialState.costUnitBranches = action.data;

            return Object.assign({}, state, partialState);

        case COST_UNIT_WARNINGS_LOADED:
            partialState = Object.assign({}, state);
            partialState.warnings = action.data;

            return Object.assign({}, state, partialState);

        case APPROVERS_TREE_ROOT_LOADED:
            partialState = Object.assign({}, state);
            partialState.approversTree = action.data;
            partialState.approversNodes = [];
            partialState.costUnitRoles = null;

            return Object.assign({}, state, partialState);

        case APPROVERS_NODES_LOADED:
            partialState = Object.assign({}, state);
            let newNodes = Object.assign({}, partialState.approversNodes);
            newNodes[action.id] = {};
            newNodes[action.id].nodes = action.data.nodes;
            newNodes[action.id].id = action.id;
            newNodes[action.id].name = action.name;
            newNodes[action.id].parent = action.parentId;
            newNodes[action.id].parentDimension = action.parentDimension;
            newNodes[action.id].level = action.level;
            newNodes[action.id].number = action.number;
            newNodes[action.id].open = true;
            partialState.approversNodes = newNodes;
            //always update the current version
            state.approversTree.version = action.data.version;

            return Object.assign({}, state, partialState);

        case COST_UNIT_ROLE_TYPES_LOADED:
            partialState = Object.assign({}, state);
            partialState.costUnitRoleTypes = action.data;

            return Object.assign({}, state, partialState);

        case COST_UNITS_ROLES_SORT_CHANGED:
            partialState = Object.assign({}, state);
            partialState.sort = action.sort;
            return Object.assign({}, state, partialState);

        case CLOSE_COST_UNIT_NODE:
            partialState = Object.assign({}, state);
            let nodes = Object.assign([], state.approversNodes);
            let foundNode = state.approversNodes[action.data.id];
            if (foundNode)
                nodes[action.data.id].open = false;

            partialState.approversNodes = nodes;
            return Object.assign({}, state, partialState);

        case DOCUMENT_TYPES_LOADED:
            partialState = Object.assign({}, state);
            partialState.documentTypes = action.data;
            return Object.assign({}, state, partialState);

        default:
            return state;
    }

}

export function selectedCostUnitTypes(state) {
    return state.selectedCostUnitTypes;
}

export function costUnitTypes(state) {
    return state.costUnitTypes;
}

export function costUnitTypesVersion(state) {
    return state.constUnitTypesVersion;
}

export function costUnitRoles(state) {
    return state.costUnitRoles;
}

export function costUnitWarnings(state) {
    return state.warnings;
}

export function approversTree(state) {
    return state.approversTree;
}

export function approversNodes(state) {
    return state.approversNodes;
}

export function costUnitRoleTypes(state) {
    return state.costUnitRoleTypes;
}

export function costUnitBranches(state) {
    return state.costUnitBranches;
}

export function getApproversLoadingStatus(state) {
    return state.assignApproversLoadingStatus;
}

export function getRolesSort(state) {
    return state.sort;
}

export function getCostUnitsDocTypes(state) {
    return state.documentTypes;
}