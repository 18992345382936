import React, { Component } from "react";
import translate from "../translations/translations.wrapper.jsx";
import "./Settings.scss";

export class SettingsButtonsBarView extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <footer className={"fixed-bottom px-5 mb-4 task-action-buttons " + this.props.containerFooterStyling}>
                <div className={this.props.containerInnerStyling}>
                    <div className="row">
                        <div className="col-md-6 ml-auto d-flex">
                            {this.props.hasBackButton &&
                                <button onClick={this.props.backCallback}
                                        className={"btn btn-default " + (this.props.backCallback ? "" : "disabled")}>
                                    {this.props.translate("settings.back")}
                                </button>
                            }
                            {this.props.moreActionsButton}
                        </div>

                        <div className="col-md-6 ms-auto d-flex justify-content-end">
                            {!this.props.hideSave &&
                                <button onClick={this.props.okCallback}
                                        className={"btn btn-primary me-4 " + (this.props.okEnabled ? "" : "disabled")}
                                        data-testid={"__tst_save"}>
                                    {this.props.translate("settings.save")}
                                </button>
                            }
                            {!this.props.hideCancel &&
                                <button onClick={this.props.cancelCallback}
                                        className={"btn btn-default " + (this.props.cancelEnabled ? "" : "disabled")}
                                        data-testid={"__tst_cancel"}>
                                    {this.props.translate("settings.cancel")}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

const withTranslations = translate(SettingsButtonsBarView);
export default withTranslations;