import React from 'react';
import {formatDate, formatAmountValue, formatDocumentOrganizationUnit} from '../../../../utils/valueFormatter.function';
import {CostCentersDetails} from "./CostCentersDetails.component";

const PurchaseOrderDocumentDetailsDTO = ({details, translate}) => {
    let displayId = details.displayId ? details.displayId : details.documentId;

    return (
        <div>
            {details.description &&
            <div className="document-data-field">
                <div className="document-data-field-label">
                    {translate("documentData.description")}
                </div>
                <div className="document-data-field-value"
                     title={details.description}>
                    {details.description}
                </div>
            </div>}

            <div className="document-data-divided">
                {details.claimant &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.requester")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.claimant}>
                        {details.claimant}
                    </div>
                </div>}

                {details.costRequestDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.expenseDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.costRequestDate)}
                    </div>
                </div>}

                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.documentID")}
                    </div>
                    <div className="document-data-field-value"
                         title={displayId}>
                        {displayId}
                    </div>
                </div>

                {details.supplierName &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.supplierName")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.supplierName}>
                        {details.supplierName}
                    </div>
                </div>}

                {details.supplierNumber &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.supplierNumber")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.supplierNumber}>
                        {details.supplierNumber}
                    </div>
                </div>}

                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.amount")}
                    </div>
                    <div className="document-data-field-value">
                        <b>{formatAmountValue(details.amount)} </b>
                        {details.currency}
                        {(details.foreignAmount && details.currency !== details.foreignCurrency) &&
                        <div>
                            <small>
                                <span>{formatAmountValue(details.foreignAmount)} </span>
                                {details.foreignCurrency}
                            </small>
                        </div>}
                    </div>
                </div>

                {details.employeeCode &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.employeeCode")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.employeeCode}>
                        {details.employeeCode}
                    </div>
                </div>}

                {details.documentOrganizationUnit &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.organizationUnit")}
                    </div>
                    <div className="document-data-field-value"
                         title={formatDocumentOrganizationUnit(details.documentOrganizationUnit)}>
                        {formatDocumentOrganizationUnit(details.documentOrganizationUnit)}
                    </div>
                </div>}

                {details.documentCostUnitList && details.documentCostUnitList.length > 0 &&
                <CostCentersDetails costUnitList={details.documentCostUnitList}
                                    translate={translate}/>}
            </div>
        </div>
    )
};

export default PurchaseOrderDocumentDetailsDTO;
