import { COMMENTS_INPUT_MAX_LENGTH, COMMENTS_INPUT_THRESHOLD_LENGTH } from "utils/constants";
import translate from "components/translations/translations.wrapper";
import { CommentsValidationProps } from "types/comments";
import React from "react";

const CommentsValidation: React.FC<CommentsValidationProps> = (props) => {
    return (
        <div className="new-comments-length-validation">
            {!props.comment ?
                <div></div> :
                <div>
                    {props.offline?
                        <div className="font-12 text-danger">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"
                                          data-tooltip-error={props.translate("taskDetail.commentNotDeliveredTooltip")}
                                    />
                            {props.translate("taskDetail.commentNotDelivered")}
                        </div> :
                        <div>
                            {props.comment.length >= COMMENTS_INPUT_THRESHOLD_LENGTH && props.comment.length <= COMMENTS_INPUT_MAX_LENGTH &&
                                <div className="font-12 text-disabled">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-warning" />
                                    {COMMENTS_INPUT_MAX_LENGTH - props.comment.length}
                                    {props.translate("taskDetail.charactersLeft")}
                                </div>
                            }
                            {props.comment.length > COMMENTS_INPUT_MAX_LENGTH &&
                                <div className="font-12 text-danger">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"
                                          data-tooltip-error={props.translate("taskDetail.commentTooLongTooltip")}
                                    />
                                    {props.translate("taskDetail.characters", props.comment.length)}
                                </div>
                            }
                            {props.comment.length < COMMENTS_INPUT_THRESHOLD_LENGTH && props.automaticSave &&
                                <div className="font-12 text-disabled">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-info mt-0" />
                                    {props.translate("taskDetail.automaticCommentSave")}
                                </div>}
                            {props.comment.length < COMMENTS_INPUT_THRESHOLD_LENGTH && !props.automaticSave &&
                                <div className="font-12 text-disabled">
                                    <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-warning mt-0" />
                                    {props.translate("taskDetail.unsavedComments")}
                                </div>}
                        </div>
                    }
                </div>
            }
        </div>
    );
};

const withTranslations = translate(CommentsValidation);

export default withTranslations;