import moment from 'moment';
import {Fragment, useState} from "react";
import {Tooltip} from "components/tooltip/Tooltip";
import timeIcon from "assets/16_time.svg";
import WorkflowDetailsUsersModal
    from "components/taskdetails-remake/panels/workflowdetails/WorkflowDetailsUsersModal.component";
import okIcon from "assets/24_OKwhite.svg";
import activeIcon from "assets/24_hourglass.svg";
import lockedIcon from "assets/24_lock.svg";
import skippedIcon from "assets/24_replace.svg";
import rejectedIcon from "assets/24_rejected_document2.svg";
import canceledIcon from "assets/24_cancelled.svg";
import UserProfileTooltip from "components/tooltip/UserProfileTooltip.component";
import translate from "components/translations/translations.wrapper";
import {Assignment, Explanation, WorkflowDetailsWizardStepProps} from "types/workflowDetailsWizard";
import {StatusType, StepIconType} from "types/workflowDetailsWizardStep";
import { UserDTO } from "types/userDTO";

const tooltipContent = function (userDTO: UserDTO, explanations?: Array<Explanation>) {
    return (
        () => <Fragment>
            <UserProfileTooltip user={userDTO}/>
            {explanations &&
                <div className={"mt-3"}>{explanations[0]?.action}</div>}
        </Fragment>
    )
}
function WorkflowDetailsWizardStep(props: WorkflowDetailsWizardStepProps) {
    const step = props.step || {status: StatusType.PASSED};
    const stepIcons: StepIconType = {
        PASSED: okIcon,
        APPROVED: okIcon,
        CURRENT: activeIcon,
        ACTIVE: activeIcon,
        FUTURE: lockedIcon,
        SKIPPED: skippedIcon,
        REJECTED: rejectedIcon,
        CANCELED: canceledIcon
    };
    const statusClass = step.status.toLowerCase();
    const [moreUsersModalVisible, setMoreUsersModalVisible] = useState(false);

    const toggleMoreUsersModal = function () {
        setMoreUsersModalVisible(!moreUsersModalVisible);
    }
    const computeActiveStepRenderClass = function (status: StatusType) {
        return status === StatusType.ACTIVE ? "wizard-step-details-highlight" : "wizard-step-details-text";
    }

    const tooltip = function (index: number, assignment: Assignment, status: StatusType) {
        const tooltipId = `${assignment.userDTO.idUser}-${status}-tooltip-${index}`;
        return(
            <Fragment key={`${assignment.userDTO.idUser}-step`}>
                {index === 1 ? ', ' : ''}
                <span className="cursor-pointer"
                      data-for={tooltipId} data-tip="">
                                            {assignment.userDTO.firstName} {assignment.userDTO.lastName}
                                        </span>
                <Tooltip
                    id={tooltipId}
                    content={tooltipContent(assignment.userDTO, assignment.explanations)}
                />
            </Fragment>
        );
    }

    const extraApproversPopup = function (assignments: Array<Assignment>) {
        return (
            <Fragment>
                {' '}{props.translate("taskDetail.and")}{' '}
                <span className="cursor-pointer"
                      onClick={toggleMoreUsersModal}>
                                            {assignments.length > 99 ? '99+' : assignments.length - 2}{' '}
                    {props.translate("taskDetail.more")}
                                        </span>
                <WorkflowDetailsUsersModal
                    assignments={assignments}
                    visible={moreUsersModalVisible}
                    onClose={toggleMoreUsersModal}
                />
            </Fragment>
        );
    }

    // @ts-ignore
    const renderStepDetailsTime = function (actionTime: Moment) {
        return (
            <div className="wizard-step-details-time">
                <img src={timeIcon} alt={"received time"}/>
                {actionTime.format('DD/MM/YYYY')} at {actionTime.format('HH:mm')}
            </div>
        );
    }

    // @ts-ignore
    const renderDetailedStepDetails = function (status: StatusType, lastActionTime: moment.Moment) {
        const activeStatus = status === StatusType.ACTIVE || status === StatusType.CURRENT;
        const assignments = activeStatus ? props.step.completedAssignments.concat(props.step.incompleteAssignments) : props.step.completedAssignments;
        return (
            <Fragment>
                {assignments.length !== 0 &&
                    <div className={computeActiveStepRenderClass(status)}>
                        {activeStatus ? props.translate("taskDetail.pendingApprovalBy") : props.translate("taskDetail.approvedBy")}{' '}
                        { // @ts-ignore
                            assignments.slice(0, 2).map((assignment, index) => tooltip(index, assignment, status))
                        }
                        {assignments.length > 2 ? extraApproversPopup(assignments) : ''}
                    </div>}
                {!activeStatus && renderStepDetailsTime(lastActionTime)}
            </Fragment>
        )
    }

    // @ts-ignore
    const renderRejectedStepDetails = function (status: StatusType, lastActionTime: moment.Moment) {
        const rejectedBy = props.step.lastActionBy;
        const tooltipId = rejectedBy != null ? rejectedBy.idUser + "-" + status + "-tooltip" : "";
        return (
            <Fragment>
                {rejectedBy == null ? <div
                        className="wizard-step-details-rejected">{props.translate("workflowProcessStatus.REJECTED")}</div> :
                    <div
                        className="wizard-step-details-rejected">{props.translate("taskDetail.rejectedBy")}{' '}{
                        <Fragment key={`${rejectedBy.idUser}-step`}>
                                <span className="cursor-pointer"
                                      data-for={tooltipId} data-tip="">
                            {rejectedBy.firstName} {rejectedBy.lastName}
                                </span>
                            <Tooltip
                                id={tooltipId}
                                content={tooltipContent(rejectedBy)}
                            />
                        </Fragment>
                    }</div>
                }
                {lastActionTime !== null && renderStepDetailsTime(lastActionTime)}
            </Fragment>
        )
    }

    // @ts-ignore
    const renderCanceledStepDetails = function (lastActionTime: moment.Moment) {
        return (
            <Fragment>
                <div className="wizard-step-details-canceled">
                    {props.translate("workflowProcessStatus.CANCELLED")} {props.translate("taskDetail.from")} {props.displaySourceApplication}
                </div>
                {lastActionTime !== null && renderStepDetailsTime(lastActionTime)}
            </Fragment>
        )
    }

    const renderFutureStepDetails = function (status: StatusType) {
        const incompleteAssignments = props.step.incompleteAssignments
        return (
            incompleteAssignments.length !== 0 &&
            <div className="wizard-step-details-text wizard-step-details-future">
                {props.translate("taskDetail.tasksFor")}{' '}
                {  //@ts-ignore
                    incompleteAssignments.slice(0, 2).map((assignment, index) => tooltip(index, assignment, status))
                }
                {incompleteAssignments.length > 2 ? extraApproversPopup(incompleteAssignments) : ''}
            </div>
        )
    }

    const renderSkippedStepDetails = function () {
        return (
            <div className="wizard-step-details-skipped"
                 title={props.step.explanation}>
                {props.step.explanation}
            </div>
        )
    }

    const renderStepDetails = function (status: StatusType) {
        if (props.approvalInitiated) {
            const approvalInitiatedUser = props.data.requester || props.data.submitter;
            const receivedTime = moment(props.data.received);
            const tooltipId = `${approvalInitiatedUser?.idUser}-${status}-approvalInitiated`;

            return (
                <Fragment>
                    {approvalInitiatedUser ?
                        <div className="wizard-step-details-text">
                        <span className="cursor-pointer"
                              data-for={tooltipId}
                              data-tip="">{approvalInitiatedUser.firstName} {approvalInitiatedUser.lastName} </span>
                            {props.translate("taskDetail.activatedFrom")} {props.data.displaySourceApplication}
                            <Tooltip
                                id={tooltipId}
                                content={tooltipContent(approvalInitiatedUser)}
                            />
                        </div> :
                        <div className="wizard-step-details-text">
                            {props.translate("taskDetail.activatedFromNoUser")} {props.data.displaySourceApplication}
                        </div>
                    }
                    {renderStepDetailsTime(receivedTime)}
                </Fragment>
            )
        }
        const lastActionTime = moment(props.step.lastAction)
        switch (status) {
            case StatusType.ACTIVE:
            case StatusType.CURRENT:
            case StatusType.PASSED:
            case StatusType.APPROVED:
                return renderDetailedStepDetails(status, lastActionTime);
            case 'REJECTED':
                return renderRejectedStepDetails(status, lastActionTime);
            case 'CANCELED':
                return renderCanceledStepDetails(lastActionTime);
            case 'FUTURE':
                return renderFutureStepDetails(status);
            case 'SKIPPED':
                return renderSkippedStepDetails();
            default:
                return null
        }
    }

    return (
        <div className="wizard-step-wrapper">
            <div className={`wizard-step ${statusClass}`}
                 title={props.children || ''}>
                <div className="wizard-step-content">
                    <div className="wizard-step-icon">
                        <img src={stepIcons[step.status]} alt={"step icon"}/>
                    </div>
                    <div className="wizard-step-title">
                        {props.children}
                    </div>
                </div>
            </div>
            <div className="wizard-step-details">
                {renderStepDetails(step.status)}
            </div>
        </div>
    );
}

const withTranslations = translate(WorkflowDetailsWizardStep);
export default withTranslations;
