import React, {Component} from 'react';
import Select from 'react-select';
import * as api from "../../../utils/api/api";
import * as _ from "lodash";

export class SimulatorProjects extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
        };

        this.fieldRef = null;
    }


    componentDidMount() {
        this.getProjects();
    }

    getProjects() {
        api.getSimulatorProjects().then((response) => {
            if (response.result && response.result.projects) {
                const sorted = _.sortBy(response.result.projects, 'identifier');
                this.setState({
                    projects: sorted.map((item, index) => {
                        return {
                            value: item.mdmGuid,
                            label: item.identifier ? `${item.identifier} - ${item.name}` : item.name
                        }
                    })
                })
            }
        })
    }

    render() {
        return (
            <div className="form-group margin-top">
                <label className="form-label">{this.props.translate("workflowDetails.simulator.project")}</label>
                <Select options={this.state.projects}
                        ref={(field) => this.fieldRef = field}
                        defaultValue={this.props.project}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.props.onChange}
                        name="project"
                />
            </div>
        );
    }
}