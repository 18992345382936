import React, {Component} from 'react';
import * as Api from '../../utils/api/api';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import * as _ from "lodash";
import {DropdownButton, Dropdown} from "react-bootstrap";
import {getAllUsers, setStepAction, setStepExceptions} from "./workflowdetails.action";
import translate from '../translations/translations.wrapper.jsx';
import UserSearch from '../input/UserSearch.component';
import Exceptions from "./ExceptionsWorkflows.component";
import {DOCUMENT_TYPE, RULE_TYPES} from '../../utils/constants';

export const FindByEmployeeRole = (props) => {
    const employeeRoleId = props.action && props.action.employeeRole;
    const mapRoleToName = employeeRoleId ? _.find(props.orgChartRoles, (roles => {
        return roles.orgChartRoleId === employeeRoleId
    })) : null;

    const displayValue = mapRoleToName ? mapRoleToName.name : props.translate("workflowDetails.selectRole");

    const menuItems = props.orgChartRoles.map((rule, index) => {
        return <Dropdown.Item eventKey={rule} key={index} onClick={() => selectEmployeeRole(rule)}>
            {rule.name}
        </Dropdown.Item>
    });

    const selectEmployeeRole = function (value) {
        let rule = props.action ? _.clone(props.action) : RULE_TYPES[props.componentType];

        rule.employeeRole = value.orgChartRoleId;

        props.setStepAction(props.idStep, props.idRule, rule, props.stepNumber, props.ruleNumber);
    };

    return (
        <div className="dropdown-menu-scrollable">
            <DropdownButton id="Employee_Role_Selection"
                            className="text-start"
                            bsPrefix="btn btn-default"
                            title={displayValue}>
                {menuItems}
            </DropdownButton>
        </div>
    )
};
const FindByEmployeeStateToProps = function (store) {
    return {
        orgChartRoles: reduxSelectors.getOrganizationChart(store)
    };
};
export const FindByEmployeeRoleAction = connect(FindByEmployeeStateToProps, {setStepAction})(FindByEmployeeRole);


export const OrganisationChart = (props) => {

    let mapRoleToName = props.action && props.action.orgChartRole ? _.find(props.orgChartRoles, (roles => {
        return roles.orgChartRoleId === props.action.orgChartRole
    })) : null;

    let displayValue = mapRoleToName ? mapRoleToName.name : props.translate("workflowDetails.selectRole");

    let menuItems = props.orgChartRoles.map((rule, index) => {
        return <Dropdown.Item eventKey={rule} key={index} onClick={() => selectOrgChartRole(rule)}>
            {rule.name}
        </Dropdown.Item>
    });

    let selectOrgChartRole = function (value) {
        let rule = props.action ? _.clone(props.action) : RULE_TYPES[props.componentType];
        rule.orgChartRole = value.orgChartRoleId;

        props.setStepAction(props.idStep, props.idRule, rule, props.stepNumber, props.ruleNumber);
    };


    return (
        <div className="dropdown-menu-scrollable">
            <DropdownButton id="Org_Chart_Selection"
                            className="text-start"
                            bsPrefix="btn btn-default"
                            title={displayValue}>
                {menuItems}
            </DropdownButton>
        </div>
    )
};
const OrganisationChartStateToProps = function (store) {
    return {
        orgChartRoles: reduxSelectors.getOrganizationChart(store)
    };
};
export const OrganisationChartAction = connect(OrganisationChartStateToProps, {setStepAction})(OrganisationChart);

export const ProjectManagerAction = (props) => {
    return (
        <div className="mt-2">
            <span className="vismaicon vismaicon-filled vismaicon-help"
                  title={props.translate("workflowDetails.managerTitle")}/>
        </div>
    )
};

export const ProjectRole = (props) => {

    let selectProjectRole = function (value) {
        let rule = props.action ? _.clone(props.action) : RULE_TYPES[props.componentType];
        rule.role = value.roleId;

        props.setStepAction(props.idStep, props.idRule, rule, props.stepNumber, props.ruleNumber);
    };

    let findRole = props.action && props.action.role ? _.findIndex(props.configRoles, (role => {
        return role.roleId === props.action.role;
    })) : -1;

    let displayedRole = findRole !== -1 ? props.configRoles[findRole].name : props.translate("workflowDetails.selectRole");

    let menuItems = props.configRoles.map((role, index) => {
        return <Dropdown.Item eventKey={role} key={index} onClick={() => selectProjectRole(role)}>
            {role.name}
        </Dropdown.Item>
    });
    return (
        <div className="dropdown-menu-scrollable">
            <DropdownButton id="Project_Role_Selection"
                            className="text-start"
                            bsPrefix="btn btn-default"
                            title={displayedRole}>
                {menuItems}
            </DropdownButton>
        </div>

    )
};
const ProjectRoleStateToProps = function (store) {
    return {
        configRoles: reduxSelectors.getConfigRoles(store)
    };
};
export const ProjectRoleAction = connect(ProjectRoleStateToProps, {setStepAction})(ProjectRole);


export class CostUnits extends Component {
    constructor(props) {
        super(props);

        this.selectCostUnit = this.selectCostUnit.bind(this);
    }

    selectCostUnit(value) {
        let rule = this.props.action ? _.clone(this.props.action) : RULE_TYPES[this.props.componentType];
        rule.role = value.roleId;

        this.props.setStepAction(this.props.idStep, this.props.idRule, rule, this.props.stepNumber, this.props.ruleNumber);
    };

    render() {
        let findRole = this.props.action ? _.findIndex(this.props.costUnits, (role => {
            return role.roleId === this.props.action.role;
        })) : -1;

        let menuItems = this.props.costUnits.map((rule, index) => {
            return <Dropdown.Item eventKey={rule}
                                  key={index}
                                  onClick={() => this.selectCostUnit(rule)}>
                {rule.name}
            </Dropdown.Item>
        });

        return (
            <div className="dropdown-menu-scrollable">
                <DropdownButton id="Rule_Type_Selection"
                                className="text-start"
                                bsPrefix="btn btn-default"
                                title={findRole !== -1 ? this.props.costUnits[findRole].name : ""}>
                    {menuItems}
                </DropdownButton>
            </div>

        )
    }
};
const withTranslations = translate(CostUnits);
const CostUnitsStateToProps = function (store) {
    return {
        costUnits: reduxSelectors.getCostUnits(store)
    };
};
export const CostUnitsAction = connect(CostUnitsStateToProps, {setStepAction})(withTranslations);


export const DepartmentManagerAction = (props) => {
    return (
        <div className="mt-2">
            <span className="vismaicon vismaicon-filled vismaicon-help"
                  title={props.translate("workflowDetails.departmentTitle")}/>
        </div>
    )
};


export class ListOfApprovers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            exceptions: null,
            showAuto: true,
            automaticApprove: this.props.action ? this.props.action.automaticApprovalUsers : false
        };
        this.changeUser = this.changeUser.bind(this);
        this.save = this.save.bind(this);
        this.changeExceptions = this.changeExceptions.bind(this);
        this.toggleAutomaticApproval = this.toggleAutomaticApproval.bind(this);
        this.mapData = this.mapData.bind(this);
    }


    componentDidMount() {
        const nameNotFoundLabel = this.props.translate("users.nameNotFound");
        if (this.props.action)
            this.props.getAllUsers(this.props.idStep, this.props.action, nameNotFoundLabel);
        this.mapData();
    }

    mapData() {
        const nameNotFoundLabel = this.props.translate("users.nameNotFound");
        //map the users IDs
        if (this.props.action && this.props.action.users && this.props.action.userMap) {
            const users = this.props.action.users.map(userId => {
                const user = this.props.action.userMap[userId] || {
                    idUser: userId,
                    fullNameOrEmail: nameNotFoundLabel
                };

                return {
                    valid: Boolean(user.idUser),
                    label: user.fullNameOrEmail,
                    value: user.idUser
                }
            });
            this.setState({
                users: this.props.action.automaticApprovalUsers ? users[0] : users
            })
        }

        if (this.props.action && this.props.action.exceptions && this.props.action.userMap) {
            //let allowAutomaticApproval = false;

            const exceptions = this.props.action.exceptions.map(exception => {
                const requesterId = exception.user;
                const requester = this.props.action.userMap[requesterId] || {
                    idUser: requesterId,
                    fullNameOrEmail: nameNotFoundLabel
                };
                const approverIds = exception.users;

                return {
                    valid: Boolean(requester.idUser),
                    label: requester.fullNameOrEmail,
                    value: requester.idUser,
                    users: approverIds.map((approverId) => {
                        const approver = this.props.action.userMap[approverId] || {
                            idUser: approverId,
                            fullNameOrEmail: nameNotFoundLabel
                        };

                        return {
                            valid: Boolean(approver.idUser),
                            label: approver.fullNameOrEmail,
                            value: approver.idUser
                        }
                    }),
                    automaticApproval: exception.automaticApproval
                };
            });
            this.setState({
                exceptions: exceptions,
                // showAuto: allowAutomaticApproval
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.action || !this.props.action || prevProps.action.users !== this.props.action.users || this.props.action.exceptions !== prevProps.action.exceptions) {
            this.mapData();
        }
    }

    async changeUser(value) {
        this.setState({
            users: value
        });
        let rule = this.props.action ? _.clone(this.props.action) : RULE_TYPES[this.props.componentType];

        if (this.state.automaticApprove) {
            rule.users = [value.value];
        } else {
            rule.users = value && Array.isArray(value) ? value.map(user => {
                return user.value
            }) : [];
        }

        if (!rule.userMap) {
            rule.userMap = {};
        }

        for (const userId of rule.users) {
            if (!rule.userMap[userId]) {
                rule.userMap[userId] = await Api.getCompanyUser(userId);
            }
        }

        this.props.setStepAction(this.props.idStep, this.props.idRule, rule, this.props.stepNumber, this.props.ruleNumber);
    }

    async save(exceptions) {
        let rule = this.props.action ? _.clone(this.props.action) : RULE_TYPES[this.props.componentType];
        rule.exceptions = exceptions;

        for (const exception of exceptions) {
            const requesterId = exception.user;
            const approverIds = exception.users;

            if (!rule.userMap[requesterId]) {
                rule.userMap[requesterId] = await Api.getCompanyUser(requesterId);
            }

            for (const approverId of approverIds) {
                if (!rule.userMap[approverId]) {
                    rule.userMap[approverId] = await Api.getCompanyUser(approverId);
                }
            }
        }

        this.props.setStepAction(this.props.idStep, this.props.idRule, rule, this.props.stepNumber, this.props.ruleNumber);
    }

    changeExceptions(values) {
        this.setState({
            exceptions: values
        });

        this.props.setStepExceptions(this.props.idStep, values);
    }

    toggleAutomaticApproval(event) {
        this.setState({automaticApprove: event.target.checked});

        let currentUsers = _.clone(this.state.users);
        let rule = this.props.action ? _.clone(this.props.action) : {};

        if (event.target.checked) {
            if (currentUsers.length > 0) {
                currentUsers = {
                    valid: currentUsers[0].valid,
                    label: currentUsers[0].label,
                    value: currentUsers[0].value
                };
            }
            rule.users = [currentUsers.value];
        } else {
            currentUsers = [{valid: currentUsers.valid, label: currentUsers.label, value: currentUsers.value}];
            rule.users = currentUsers.map(user => {
                return user.value
            });

        }
        this.setState({
            users: currentUsers
        });
        rule.automaticApprovalUsers = event.target.checked;
        let isComplete = currentUsers.length > 0;
        this.props.setStepAction(this.props.idStep, this.props.idRule, rule, this.props.stepNumber, this.props.ruleNumber, isComplete);

    }


    render() {

        let users = (this.props.action && !this.props.action.automaticApprovalUsers && this.state.users.length > 0) ? this.state.users.map(user => {
            return {valid: user.valid, label: user.label, value: user.value};
        }) : this.state.users;

        //non-HRM documents don't have exceptions
        let showExceptions = this.props.type !== DOCUMENT_TYPE.INVOICE && this.props.type !== DOCUMENT_TYPE.VOUCHER && this.props.type !== DOCUMENT_TYPE.SUPPLIER;
        let showAutomaticApproval = this.props.type === DOCUMENT_TYPE.EXPENSE_CLAIM || this.props.type === DOCUMENT_TYPE.LEAVE_REQUEST || this.props.type === DOCUMENT_TYPE.TIMESHEET;

        return (
            <div className={"approve-with-exceptions" + (showAutomaticApproval ? " mt-2" : "")}>
                {showAutomaticApproval &&
                    <div className="checkbox">
                        <input type="checkbox"
                               className="automatic-checkbox"
                               id={`amount-condition-checkbox${this.props.idStep}`}
                               checked={this.state.automaticApprove}
                               onChange={this.toggleAutomaticApproval}/>
                        <label htmlFor={`amount-condition-checkbox${this.props.idStep}`}>
                            {this.props.translate("workflowDetails.automaticApproval")}
                        </label>
                    </div>
                }
                <UserSearch propagateValue={this.changeUser}
                            multi={!this.state.automaticApprove}
                            includeSameUser={true}
                            stopFocus={true}
                            value={users}
                            translate={this.props.translate}
                />
                {showExceptions &&
                    <div className="no-side-padding step-exceptions-area">
                        <div className="mt-4">
                            {this.props.translate("workflowDetails.exceptions.exceptEmployees")}
                        </div>
                        <div>
                            <Exceptions data={this.state.exceptions}
                                        stepId={this.props.idStep}
                                        noDuplicates={true}
                                        showAuto={this.state.showAuto}
                                        changeExceptions={this.changeExceptions}
                                        saveExceptions={this.save}
                                        allowActions={showExceptions}/>

                        </div>
                    </div>
                }

            </div>
        )
    }
}

const
    listOfApproverswithTranslations = translate(ListOfApprovers);
const
    ListOfApproversToProps = function (store) {
        return {
            costUnits: reduxSelectors.getCostUnits(store),
            usersList: reduxSelectors.getExceptionUsers(store)
        };
    };
export const
    ListOfApproversAction = connect(ListOfApproversToProps, {
        setStepAction,
        getAllUsers,
        setStepExceptions
    })(listOfApproverswithTranslations);