import {Component} from 'react';
import PropTypes from 'prop-types';
import * as logger from '../../utils/logger.function';
import {requestLanguageChange} from '../translations/translations.action.js';
import {connect} from 'react-redux';
import {getLanguage, getSupportedLanguages} from '../store/application.reducers';

/**
 * wrapper component to enable ctrl+alt+L shortcut to change language of customer without going to ODP
 *
 * expected props:
 * requestLanguageChange ~ redux action
 * languages ~ array of possible languages
 * language ~ currently selected language
 */
export class LanguageSwitcher extends Component {

    static propTypes = {
        supportedLanguages : PropTypes.array,
        language: PropTypes.string
    };

    constructor() {
        super();
        this.catchShortcut = this.catchShortcut.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.catchShortcut);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.catchShortcut);
    }

    catchShortcut(e) {

        if (e.ctrlKey && e.altKey && e.keyCode === 76) {
            // stop the event
            e.stopPropagation();

            // decide which is the next language
            const nextLang = pickNextLanguage(this.props.language, this.props.supportedLanguages);

            logger.debug("detected CTRL + ALT + 'l', changing language to " + nextLang);
            // forced reload, because otherwise the rest of loaded languages wont reload ever
            // might instead listen to version change in translation reducer and erase loaded dictionaries
            this.props.requestLanguageChange(nextLang, false, true);
        }
    }

    render() {
        return null;
    }

}





const mapStateToProps = function (store) {
    return {
        // data after app start to pass to children, so they don't need to connect to store themselves
        supportedLanguages: getSupportedLanguages(store),
        language: getLanguage(store)
    };
};


export default connect(mapStateToProps, {
    requestLanguageChange,
})(LanguageSwitcher)



export function pickNextLanguage(current, possibleValues) {
    // we'll be iterating over array with 7 values, don't optimize prematurely :)
    for (let i = 0; i < possibleValues.length; i++) {
        if (possibleValues[i] === current) {
            if (possibleValues[i + 1]) {
                return possibleValues[i + 1];
            } else { // current is the last item in the array
                return possibleValues[0];
            }
        }
    }

    return 'en';
    // return current;
}