import React, {useState} from "react";
import translate from "../../../translations/translations.wrapper.jsx";
import arrowDown from "../../../../assets/Icon-horizontal arrows.svg";
import moment from "moment";
import {Tooltip} from "../../../tooltip/Tooltip";
import UserProfileTooltip from "../../../tooltip/UserProfileTooltip.component";

const WorkflowHistoryHeader = (props) => {

    return (
        <div>
            <div className="row">
                <div className="col-md-2 pt-4 pb-4">
                    {props.translate("taskDetail.step")}
                </div>
                <div className="col-md-2 pt-4 pb-4">
                    {props.translate("taskDetail.action")}
                </div>
                <div className="col-md-4 pt-4 pb-4">
                    {props.translate("taskDetail.comment")}
                </div>
                <div className="col-md-2 pt-4 pb-4 d-flex align-items-center cursor-pointer"
                     onClick={() => props.onClickSort('date')}>
                    {props.translate("taskDetail.date")}
                    <img className={`arrow-icon ms-2 ${props.sortingDirection === 'asc' ? 'up' : ''}`} src={arrowDown} alt="arrow down"/>
                </div>
                <div className="col-md-2 pt-4 pb-4">
                    {props.translate("taskDetail.user")}
                </div>
            </div>
        </div>
    )
};

const WorkflowHistoryRow = (props) => {

    const getUserTooltip = (user) => {
        return <div>
            <span className="cursor-pointer"
                 data-for={`${user.idUser}-history-row-tooltip`} data-tip>
                <span className="user-row">
                    {user.fullNameOrEmail}
                </span>
            </span>
            <Tooltip
                id={`${user.idUser}-history-row-tooltip`}
                content={() => <UserProfileTooltip user={user} />}
            />
        </div>
    }

    const date = moment(props.data.date);
    const applicationName = props.data.displayApplication ? props.data.displayApplication : props.data.application;

    const columns = {
        "START": {
            step: props.translate("taskDetail.approvalInitiated"),
            action: props.translate("taskDetail.activated"),
            comment: props.data.comment ?
                <div className={"break-word"}>
                    {props.data.comment}<br/> {props.translate("taskDetail.fromUpper")}: {applicationName}
                </div> :
                `${props.translate("taskDetail.fromUpper")}: ${applicationName}`,
            user: props.data.user ? getUserTooltip(props.data.user) : props.data.fullUserName //TO DO check after backend implements this if the fullUserName name is correct
        },
        "END": {
            step: props.translate("taskDetail.approvalCompleted"),
            action: props.translate("taskDetail.returned"),
            comment: (props.data.action === "APPROVED" || props.data.action === "REJECTED") ? `${props.translate("taskDetail.to")}: ${applicationName}` :
                props.data.action === "CANCELED" ?  props.translate("taskDetail.actionTypes.CANCELED") : '',
            user: (props.data.action === "APPROVED" || props.data.action === "REJECTED") ? "Visma.net Approval" :
                props.data.action === "CANCELED" ?  applicationName : '',
        },
        "DEFAULT": {
            step: props.data.stepName,
            action: props.translate(`taskDetail.actionTypes.${props.data.action}`),
            comment:
                <div>
                    {props.data.comment ?
                        <div className={"break-word"}>
                            {props.data.comment}<br/>
                        </div> : null}
                    {(props.data.action === "FORWARDED" || props.data.action === "SENT_FOR_REVIEW") && props.data.toUser?.fullNameOrEmail ?
                        `${props.translate("taskDetail.to")} ${props.data.toUser?.fullNameOrEmail}` : null }
                    {props.data.action === "REASSIGNED" ?
                        `${props.translate("taskDetail.fromUpper")} ${props.data.originalAssignee?.fullNameOrEmail}` +
                        (props.data.toUser?.fullNameOrEmail ? ` ${props.translate("taskDetail.toLower")} ${props.data.toUser?.fullNameOrEmail}` : '') : null }
                    {props.data.action === "DOC_CHANGED" ?
                        `${props.translate("taskDetail.fromUpper")}: ${applicationName}` : null}
                </div>,
            user: <div>
                    {props.data.user ? getUserTooltip(props.data.user) : props.data.fullUserName}
                    {props.data.substitute && props.data.originalAssignee ?
                        <div>
                            {' '}({props.translate("taskDetail.onBehalfOf")}{' '}
                            {getUserTooltip(props.data.originalAssignee)})
                        </div> : null}
                </div>
        }
    }

    const data = columns[props.data.eventType] || columns.DEFAULT;

    return (
        <div className="row workflow-history-row">
            <div className="col-md-2 pt-4 pb-4 cursor-pointer cropped-text" title={data.step}>
                {data.step}
            </div>
            <div className="col-md-2 pt-4 pb-4 cursor-pointer cropped-text" title={data.action}>
                {data.action}
            </div>
            <div className="col-md-4 pt-4 pb-4">
                {data.comment}
            </div>
            <div className="col-md-2 pt-4 pb-4 cursor-pointer cropped-text" title={`${date.format('DD/MM/YYYY')} ${props.translate("taskDetail.at")} ${date.format('HH:mm')}`}>
                {date.format('DD/MM/YYYY')}
            </div>
            <div className="col-md-2 pt-4 pb-4">
                {data.user}
            </div>
        </div>
    )
};

const WorkflowHistoryTable = (props) => {
    const rows = (props.data.events || []).slice();
    const [sortBy, setSortBy] = useState();
    const [sortingDirection, setSortingDirection] = useState('desc');
    const sortOrder = ['START','USER_ACTION', 'END'];

    const onClickSort = (columnName) => {
        setSortBy(columnName)
        setSortingDirection(sortingDirection === 'asc' ? 'desc' : "asc");
    }

    if(sortBy === 'date') {
        rows.sort((row1,row2) => {
            if(sortingDirection === 'asc') {
                return (new Date(row1.date) - new Date(row2.date)) || (sortOrder.indexOf(row1.eventType) - sortOrder.indexOf(row2.eventType));
            }
            return (new Date(row2.date) - new Date(row1.date)) || (sortOrder.indexOf(row2.eventType) - sortOrder.indexOf(row1.eventType));
        });
    }

    return (
        <div>
            <WorkflowHistoryHeader {...props}
                                   onClickSort={onClickSort}
                                   sortingDirection={sortingDirection}/>
            <div>
                {rows.map((row, index) =>
                    <WorkflowHistoryRow translate={props.translate}
                                        data={row}
                                        key={`${row.date}-${index}-${row.action}`}/>
                )}
            </div>
        </div>
    )
};


const withTranslations = translate(WorkflowHistoryTable);
export default withTranslations;