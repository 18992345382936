export const customStyles = {
    control: (provided) =>
        ({...provided, height: 30, minHeight: '30px'}),
    input: (provided) =>
        ({
            ...provided,
            height: 30,
            minHeight: '24px',
            border: 'none',
            boxShadow:"none"
        }),
    indicatorsContainer: (provided) =>
        ({...provided, height: 30, minHeight: '30px'}),
    placeholder: (provided) =>
        ({...provided, height: 30, minHeight: '30px'})
};

export const inPopUpCustomStyles = {
    control: (provided) =>
        ({...provided, height: 36, minHeight: '36px'}),
    input: (provided) =>
        ({
            ...provided,
            height: 24,
            minHeight: '24px',
            border: 'none',
            boxShadow:"none"
        }),
    menu: (provided) =>
        ({
            ...provided,
            position:"fixed",
            top:"inherit"
        }),
    menuList: (provided) =>
        ({
            ...provided,
            maxHeight:"150px"
        }),
    singleValue: (provided) =>
        ({
            ...provided,
           height:"20px"
        }),
    option: (provided) => ({
        ...provided,
        height: "30px",
        padding:"3px 12px"
    }),
    placeholder: (provided) =>
        ({...provided, height: 20, minHeight: '20px'})
};