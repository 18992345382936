import {createStore, applyMiddleware, compose} from 'redux';

import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';

// make the storage persistent, see https://github.com/rt2zz/redux-persist
import {persistStore} from 'redux-persist'
//save delicate data to sessionStorage

// approval reducers - domain logic
import approvalReducers from './application.reducers';

// asynchronous redux actions http://redux.js.org/docs/advanced/AsyncActions.html
const middlewares = [thunk];

// + log the actions in development
if (process.env.NODE_ENV === `development`) {
    middlewares.push(createLogger());
}

const createStoreWithMiddleware = compose(applyMiddleware(...middlewares))(createStore);

// create store
const store = createStoreWithMiddleware(approvalReducers, undefined);

// make it persistent, and blacklist 'flags' that the app uses for initialization
// add  whitelist: [] to save less data
export const persistor = persistStore(store);
// persistStore(store, {
//     storage: asyncSessionStorage,
//     transforms: [compressor],
//     whitelist: sessionReducers,
//     blacklist: [TASK_DETAILS]
// });

export default store;
