import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Api from '../../utils/api/api.js';
import {KEYS} from '../../utils/constants';
import _ from "lodash";

export default class EmailInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            emails: [],
            selectedEmail: '',
            focusedEmailIndex: -1
        };
        this.onChange = this.onChange.bind(this);
        this.focus = this.focus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.getEmails = this.getEmails.bind(this);
        this.emailSelected = this.emailSelected.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.delayEmail = _.debounce((data) => this.getEmails(data), 700);
    }

    componentWillUnmount() {
        this.delayEmail.cancel();
    }

    /**
     * focus the input of the component
     * @return {string}
     */
    focus() {
        this.htmlInput.focus();
    }

    handleKeyDown(event) {
        event.stopPropagation();
        if(this.state.emails.length>0) {
            switch (event.keyCode) {
                case KEYS.TAB: {
                    if (this.state.focusedEmailIndex !== -1) {
                        let email = this.state.emails[this.state.focusedEmailIndex].value;
                        this.setState({
                            emails: [],
                            isValid: true,
                            selectedEmail: email
                        });
                        this.props.propagateValue(email);
                    }
                    this.setState({
                        emails: []
                    });
                    break;
                }
                case  KEYS.ENTER: {
                    if (this.state.focusedEmailIndex !== -1) {
                        this.setState({
                            selectedEmail: this.state.emails[this.state.focusedEmailIndex].value,
                            emails: []
                        });
                    }
                    break;
                }
                case  KEYS.UP_ARROW: {
                    let newIndex = (this.state.focusedEmailIndex === 0) ? this.state.focusedEmailIndex : this.state.focusedEmailIndex - 1;
                    this.setState({focusedEmailIndex: newIndex});
                    break;
                }
                case  KEYS.DOWN_ARROW: {
                    let newIndex = (this.state.focusedEmailIndex === this.state.emails.length - 1) ? this.state.focusedEmailIndex : this.state.focusedEmailIndex + 1;
                    this.setState({focusedEmailIndex: newIndex});
                    break;
                }
                default:
                    break;
            }
        }
    }


    onBlur(event) {
        const value = event.target.value;
        if (value) {
            this.validateEmail(value);
            this.props.propagateValue(value);
        }
    }

    validateEmail(email) {
        let isValid = true;
        let regExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        if (!regExp.test(email)) {
            isValid = false;
        }
        this.setState({
            isValid: isValid
        });
        return isValid;
    }

    getEmails(input) {
        if (!input)
            return Promise.resolve({options: []});

        return Api.getUserEmails(input, true).then(response => {
            let results = [];
            response.forEach(item => {
                results.push({
                    label: item.email,
                    value: item.email
                });
            });
            this.setState({
                emails: results
            });
        })
    }

    onChange(event) {
        const data = event.target.value;
        this.delayEmail(data);
        this.setState({
            selectedEmail: data,
            isValid: true
        });

    };

    emailSelected(data, event) {
        this.setState({
            emails: [],
            isValid: true,
            selectedEmail: data.value
        });
        let value = data ? data.value : undefined;

        this.props.propagateValue(value);
    }

    render() {
        const propsWithoutCallbacks = Object.assign({}, this.props);
        delete propsWithoutCallbacks.translate;
        delete propsWithoutCallbacks.propagateValue;
        delete propsWithoutCallbacks.value;

        let {focusedEmailIndex, isValid, selectedEmail} = this.state;

        let emailList = this.state.emails.map((email, index) => {
            return (<li key={index} className={focusedEmailIndex === index ? 'focused-email' : ''}
                        onClick={this.emailSelected.bind(this, email)}>{email.value}</li>);
        });

        return (
            <div className={isValid ? this.props.className : this.props.className + " has-error"}>
                <input className="email-input" type="email" onKeyDown={this.handleKeyDown} onChange={this.onChange}
                       onBlur={this.onBlur} ref={(input) => {
                    this.htmlInput = input;
                }} value={selectedEmail}
                       tabIndex={this.props.tabIndex}
                       placeholder={this.props.translate("popUp.email.emailPlaceholder")}
                />
                <span className="appicon vismaicon vismaicon-filled vismaicon-user-list pull-right"/>

                {emailList.length > 0 &&
                    <div className="open w-100">
                        <ul className="dropdown-menu list-unstyled email-input-list">
                            {emailList}
                        </ul>
                    </div>
                }

            </div>
        );
    }
}

EmailInput.propTypes = {
    translate: PropTypes.func, // to translate texts
    propagateValue: PropTypes.func, // callback to propage value to parent
    value: PropTypes.string // displayed in the input
};

// EmailInput.defaultProps = {
//     value: ''
// }
