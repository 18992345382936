import React, {Component} from 'react';
import translate from '../translations/translations.wrapper.jsx';
import {Form} from 'react-bootstrap';
import * as Api from '../../utils/api/api';
import _ from 'lodash';
import SettingsButtonsBarView from './SettingsButtonsBarView.component';
import {showErrorMessage} from 'components/usercontext/user.action.js';
import {connect} from 'react-redux';
import SettingsWrapper from "./SettingsWrapper.component";
import {getUsersCurrentContextId} from '../store/application.reducers';
import {handleError} from 'utils/errorHandle.function';

const DAYS_OF_THE_WEEK = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
const AVAILABLE_HOURS = [7, 9, 11, 13, 15, 17, 19];

const NEW_APPROVAL_TASK = 1;
const APPOINTED_SUBSTITUTE = 6;
const EMAIL_EVENTS = 11;
const EMAIL_ENABLED = 8;
const TASK_BLOCKED = 2;
const DOCUMENT_BLOCKED = 7;
const ADMIN_EMAIL_EVENTS = 12;
let canSave = true;

const USER_TYPE = {
    ADMIN: "admin",
    APPROVER: "approver"
}

export class ApproverNotifications extends SettingsWrapper {

    constructor(props) {
        super(props);
        this.state = {
            eventMail: [],
            digestMail: [],
            timeEnabled: false,
            newApprovalTask: false,
            appointedSubstitute: false,
            emailEnabled: false,
            okEnabled: false,
            isUserAdmin: false,
            isUserApprover: false,
            taskBlocked: false,
            documentBlocked: false,
            dateTime: {}
        };
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.dayChanged = this.dayChanged.bind(this);
        this.timeChanged = this.timeChanged.bind(this);
        this.mapDays = this.mapDays.bind(this);
        this.enableSave = this.enableSave.bind(this);
        this.mapData = this.mapData.bind(this);
    }

    componentDidMount(props) {
        super.componentDidMount(props);
    }

    getData() {
        let mails = DAYS_OF_THE_WEEK.map(day => {
            return {day: day, selected: false}
        });
        let hours = AVAILABLE_HOURS.map(hour => {
            return {hour: hour, selected: false}
        });
        this.setState({
            mail: mails,
            hours: hours
        });
        this.mapData();

    }

    mapData() {
        Api.getEmailSettings().then(response => {
            let newApprovalTask = false;
            let appointedSubstitute = false;
            let emailEnabled = false;
            let taskBlocked = false;
            let documentBlocked = false;

            let dateTime = {};

            if (response.eventMail) {
                response.eventMail.forEach(value => {
                    if (value === NEW_APPROVAL_TASK) newApprovalTask = true;
                    if (value === APPOINTED_SUBSTITUTE) appointedSubstitute = true;
                    if (value === TASK_BLOCKED) taskBlocked = true;
                    if (value === DOCUMENT_BLOCKED) documentBlocked = true;
                })
            }

            let approvalDayTime = this.mapDays();
            let adminDayTime = this.mapDays();

            dateTime[USER_TYPE.APPROVER] = approvalDayTime;
            dateTime[USER_TYPE.ADMIN] = adminDayTime;

            if (response.digestMail.length > 0) {
                response.digestMail.forEach(email => {

                    switch (email.digestMailType) {
                        case EMAIL_ENABLED:
                            emailEnabled = email.mailEnabled;
                            break;
                        case EMAIL_EVENTS:
                            approvalDayTime = this.mapDays(email.events);
                            dateTime[USER_TYPE.APPROVER] = this.mapDays(email.events);
                            break;
                        case ADMIN_EMAIL_EVENTS:
                            adminDayTime = this.mapDays(email.events);
                            dateTime[USER_TYPE.ADMIN] = this.mapDays(email.events);
                            break;
                        default:
                            break;
                    }
                })
            }


            this.setState({
                eventMail: response.eventMail,
                digestMail: response.digestMail,
                newApprovalTask: newApprovalTask,
                appointedSubstitute: appointedSubstitute,
                taskBlocked: taskBlocked,
                documentBlocked: documentBlocked,
                emailEnabled: emailEnabled,
                okEnabled: false,
                isUserAdmin: response.userAdmin,
                isUserApprover: response.userApprover,
                approvalDayTime: approvalDayTime,
                adminDayTime: adminDayTime,
                dateTime: dateTime
            });
        }, error => {
            handleError(error);
        });

    }

    save() {
        if (canSave) {
            canSave = false;
            let data = {
                digestMail: [],
                eventMail: []
            };
            //event mail section
            if (this.state.newApprovalTask)
                data.eventMail.push(NEW_APPROVAL_TASK);
            if (this.state.appointedSubstitute)
                data.eventMail.push(APPOINTED_SUBSTITUTE);

            if (this.state.taskBlocked)
                data.eventMail.push(TASK_BLOCKED);
            if (this.state.documentBlocked)
                data.eventMail.push(DOCUMENT_BLOCKED);


            //digest email section
            data.digestMail.push({
                digestMailType: EMAIL_ENABLED,
                mailEnabled: this.state.emailEnabled
            });


            //approver and admin day and time checkboxes
            if (this.state.isUserApprover) {
                let adminDayTimeMap = this.mapDayTimeToSave(USER_TYPE.APPROVER, EMAIL_EVENTS);
                data.digestMail.push(adminDayTimeMap);
            }

            if (this.state.isUserAdmin) {
                let adminDayTimeMap = this.mapDayTimeToSave(USER_TYPE.ADMIN, ADMIN_EMAIL_EVENTS);
                data.digestMail.push(adminDayTimeMap);
            }

            Api.updateEmailSettings(data).then(response => {
                this.props.blocker.unblockNavigation();
                canSave = true;
                this.getData();
            }, error => {
                this.props.blocker.unblockNavigation();
                handleError(error);
            })
        }
    }

    mapDayTimeToSave(userType, eventType) {
        let enabled = false;
        let digestMailEvent = [];
        let currentDayTime = this.state.dateTime[userType];

        currentDayTime.days.forEach((day, index) => {
            if (day.selected) {
                currentDayTime.hours.forEach(hour => {
                    if (hour.selected)
                        digestMailEvent.push({
                            dayOfWeek: index + 1,
                            hourOfDay: hour.hour
                        });

                })
                enabled = true;
            }
        });

        let response = {
            digestMailType: eventType,
            dueDays: 0,
            events: digestMailEvent,
            mailEnabled: enabled
        };
        return response;
    }


    cancel() {
        this.getData();
        this.props.blocker.unblockNavigation();
    }

    enableSave(e, value, type) {
        this.setState({
            [value]: e.target.checked,
            okEnabled: true
        });
        this.props.blocker.shouldWaitForConfirmation(true);
    }

    mapDays(values) {
        let mails = DAYS_OF_THE_WEEK.map((day, index) => {
            return {
                day: day, selected: _.findIndex(values, value => {
                    return value.dayOfWeek === (index + 1)
                }) !== -1
            }
        });

        let hours = AVAILABLE_HOURS.map(hour => {
            return {
                hour: hour, selected: _.findIndex(values, value => {
                    return value.hourOfDay === hour
                }) !== -1
            }
        });
        let dayTime = {
            days: mails,
            hours: hours
        }
        return dayTime;
    }


    dayChanged(value, userType) {
        let noneSelected = true;
        let currentEmail = this.state.dateTime[userType].days;
        let newEmailState = currentEmail.map(day => {
            if (day.day === value.day)
                day.selected = !value.selected;
            if (day.selected)
                noneSelected = false;

            return day;
        });

        let dateTime = Object.assign({}, this.state.dateTime);
        dateTime[userType].days = newEmailState;

        this.setState({
            timeEnabled: !noneSelected,
            okEnabled: true,
            dateTime: dateTime
        });
        this.props.blocker.shouldWaitForConfirmation(true);
    }

    timeChanged(time, userType) {
        let currentTime = this.state.dateTime[userType].hours;

        let newTimeState = currentTime.map(hour => {
            if (hour.hour === time.hour)
                hour.selected = !time.selected;

            return hour;
        });

        let dateTime = Object.assign({}, this.state.dateTime);
        dateTime[userType].hours = newTimeState;

        this.setState({
            okEnabled: true,
            dateTime: dateTime
        });
        this.props.blocker.shouldWaitForConfirmation(true);

    }

    buildContent() {
        let daysTimeProps = {
            dayChanged: this.dayChanged,
            timeChanged: this.timeChanged
        };

        let commonProps = {
            translate: this.props.translate,
            daysTimeProps: daysTimeProps,
            enableSave: this.enableSave
        }

        let approverNotificationsProps = {
            emailEnabled: this.state.emailEnabled,
            appointedSubstitute: this.state.appointedSubstitute,
            newApprovalTask: this.state.newApprovalTask,
            hours: this.state.dateTime[USER_TYPE.APPROVER] ? this.state.dateTime[USER_TYPE.APPROVER].hours : [],
            days: this.state.dateTime[USER_TYPE.APPROVER] ? this.state.dateTime[USER_TYPE.APPROVER].days : [],
            userType: USER_TYPE.APPROVER
        };

        let adminNotificationsProps = {
            taskBlocked: this.state.taskBlocked,
            documentBlocked: this.state.documentBlocked,
            hours: this.state.dateTime[USER_TYPE.ADMIN] ? this.state.dateTime[USER_TYPE.ADMIN].hours : [],
            days: this.state.dateTime[USER_TYPE.ADMIN] ? this.state.dateTime[USER_TYPE.ADMIN].days : [],
            userType: USER_TYPE.ADMIN
        };

        return (
            <span>
                <div className="row px-0 mx-0">
                    {this.state.isUserApprover &&
                        <ApproverNotificationsSettings {...approverNotificationsProps} {...commonProps}/>
                    }

                    {this.state.isUserAdmin &&
                        <AdminNotificationsSettings {...adminNotificationsProps} {...commonProps}/>
                    }
                </div>

                <SettingsButtonsBarView okCallback={this.save} okEnabled={this.state.okEnabled}
                                        cancelEnabled={this.state.okEnabled} cancelCallback={this.cancel}/>
            </span>);
    }
}

const withTranslations = translate(ApproverNotifications);
const mapStateToProps = function (store) {
    return {
        context: getUsersCurrentContextId(store)
    };
};
const connected = connect(mapStateToProps, {showErrorMessage})(withTranslations);
export default connected;

class ApproverNotificationsSettings extends Component {

    render() {
        let props = this.props;

        return (
            <section className="col-md-6 px-0" aria-labelledby={"emailNotifications"}>
                <div className="panel panel-default settings-panel px-0">
                    <div className="panel-heading" id={"emailNotifications"}>
                        <div className="panel-title pe-0">
                            {props.translate("settings.notifications.companyEmail")}
                        </div>
                    </div>
                    <div className="panel-body pb-0">
                        <fieldset>
                            <legend className="mb-2">{props.translate("settings.notifications.asSoonAs")}</legend>
                            <div className="w-100">
                                <Form.Check ref="newApprovalTask" defaultChecked={props.newApprovalTask}
                                            type="checkbox"
                                            id="newApprovalTask"
                                            className="checkbox"
                                            onChange={e => {
                                                props.enableSave(e, "newApprovalTask", NEW_APPROVAL_TASK)
                                            }}
                                            aria-label={props.translate("settings.notifications.asSoonAs") + " " + props.translate("settings.notifications.newTask")}
                                            label={props.translate("settings.notifications.newTask")}/>

                            </div>

                            <div className="w-100">
                                <Form.Check ref="appointedSubstitute" checked={props.appointedSubstitute}
                                            type="checkbox"
                                            inline="true"
                                            id="appointedSubstitute"
                                            className="checkbox"
                                            onChange={e => {
                                                props.enableSave(e, "appointedSubstitute", APPOINTED_SUBSTITUTE)
                                            }}
                                            aria-label={props.translate("settings.notifications.asSoonAs") + " " + props.translate("settings.notifications.appointedSubstitute")}
                                            label={props.translate("settings.notifications.appointedSubstitute")}/>
                            </div>
                        </fieldset>

                        <fieldset className="my-24">
                            <legend className="mb-2">
                                {props.translate("settings.notifications.aReminder")}
                            </legend>
                            <div className="w-100">
                                <Form.Check ref="emailEnabled" checked={props.emailEnabled}
                                            type="checkbox"
                                            inline="true"
                                            id="emailEnabled"
                                            className="checkbox"
                                            onChange={e => {
                                                props.enableSave(e, "emailEnabled", EMAIL_EVENTS)
                                            }}
                                            aria-label={props.translate("settings.notifications.aReminder") + " " + props.translate("settings.notifications.soonOverdue")}
                                            label={props.translate("settings.notifications.soonOverdue")}/>
                            </div>
                        </fieldset>


                        <fieldset className={"row mx-0"}>
                            <legend className="mb-2 px-0">
                                {props.translate("settings.notifications.reminderTasksOn")}
                            </legend>
                            <DayTimeOptions {...props.daysTimeProps} userType={this.props.userType}
                                            uniqId={"rmnd"}
                                            translate={this.props.translate}
                                            ariaLabel={props.translate("settings.notifications.reminderTasksOn")}
                                            hours={this.props.hours} days={this.props.days}/>
                        </fieldset>
                    </div>
                </div>
            </section>
        );
    }

};

class AdminNotificationsSettings extends Component {

    render() {
        let props = this.props;

        return (
            <section className="col-md-6 px-0" aria-labelledby={"adminCompanyEmailTxt"}>
                <div className="panel panel-default settings-panel px-0">
                    <div className="panel-heading" id={"adminCompanyEmailTxt"}>
                        <div className="panel-title pe-0">
                            {props.translate("settings.notifications.adminCompanyEmail")}
                        </div>
                    </div>
                    <div className="panel-body pb-0">
                        <fieldset className="w-100">
                            <legend className="mb-2">{props.translate("settings.notifications.asSoonAs")}</legend>
                            <Form.Check type="checkbox" ref="taskBlocked" checked={props.taskBlocked}
                                        inline="true"
                                        id="taskBlocked"
                                        className="checkbox w-100"
                                        onChange={e => {
                                            props.enableSave(e, "taskBlocked", NEW_APPROVAL_TASK)
                                        }}
                                        aria-label={props.translate("settings.notifications.asSoonAs") + " " + props.translate("settings.notifications.blockedTask")}
                                        label={props.translate("settings.notifications.blockedTask")}/>

                            <Form.Check type="checkbox" ref="documentBlocked" checked={props.documentBlocked}
                                        inline="true"
                                        id="documentBlocked"
                                        className="checkbox"
                                        onChange={e => {
                                            props.enableSave(e, "documentBlocked", APPOINTED_SUBSTITUTE)
                                        }}
                                        aria-label={props.translate("settings.notifications.asSoonAs") + " " + props.translate("settings.notifications.blockedDocument")}
                                        label={props.translate("settings.notifications.blockedDocument")}/>

                        </fieldset>

                        <fieldset className={"row mx-0 py-5 mt-24"}>
                            <legend className="mb-2 px-0">
                                {props.translate("settings.notifications.overviewTasksDocuments")}
                            </legend>
                            <DayTimeOptions {...props.daysTimeProps} userType={this.props.userType}
                                            uniqId={"not"}
                                            translate={this.props.translate}
                                            ariaLabel={props.translate("settings.notifications.overviewTasksDocuments")}
                                            hours={this.props.hours} days={this.props.days}/>
                        </fieldset>
                    </div>
                </div>
            </section>
        );
    }

};

const DayTimeOptions = function (props) {
    let rearangedDays = Object.assign([], props.days);
    if (rearangedDays.length > 1)
        rearangedDays.push(rearangedDays.shift());

    let days = rearangedDays ? rearangedDays.map((day, index) => {
        return (<div className={"w-100"}
                     key={index}>
            <Form.Check type="checkbox" checked={day.selected}
                        inline="true"
                        id={props.uniqId + "dayChanged-" + index}
                        className="checkbox"
                        onChange={props.dayChanged.bind(null, day, props.userType)}
                        label={props.translate("settings." + day.day)}/>
        </div>);
    }) : [];

    let timeEnabled = _.findIndex(props.days, mail => {
        return mail.selected === true
    }) !== -1;

    let hours = props.hours.map((hour, index) => {
        return (<div className="w-100" key={index}>
            <Form.Check type="checkbox" className={"checkbox " + (timeEnabled ? "" : "disabled")}
                        checked={hour.selected}
                        inline="true"
                        id={props.uniqId + "timeChanged" + index}
                        onChange={props.timeChanged.bind(null, hour, props.userType)}
                        label={hour.hour + ":00"}/>
        </div>);
    });

    return (
        <div className="row">
            <aside className="px-0 col-md-5 border-right" aria-label={props.ariaLabel + " days"}>
                {days}
            </aside>

            <aside className="col-md-7 ps-5" aria-label={props.ariaLabel + " hours"}>
                {hours}
            </aside>
        </div>
    );

};
