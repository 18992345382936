import React, {Component} from 'react';

import {logoutUser} from './user.action';
import {connect} from 'react-redux';

/**
 * simple page which just dispatch logoutAction and then redirects to taskList (which requests logging)
 * it seems OWR keeps broking his session :) or it's some fault in firefox ..
 */

export class LogoutPage extends Component {

    componentDidMount() {
        //logout from the notifications panel
        if(window.nc3notify?.logout) {
            window.nc3notify.logout();
        }
        // JSESSIONID is httpOnly cookie, we cannot touch it
        // document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        this.props.logoutUser(true);
    }

    render() {

        return (
            <div className="col-md-12">
                <span className="icon loading"/>
            </div>
        );

    }
}

export default connect(null, {
    logoutUser,
})(LogoutPage)