import React from "react";
import {OKCancelButtonsProps} from "types/modals";

export const OKCancelButtons = ({
                                    tabIndex,
                                    okEnabled,
                                    cancelEnabled,
                                    okCallback,
                                    okText,
                                    cancelText,
                                    cancelCallback,
                                    loopFocus
                                }: OKCancelButtonsProps): React.ReactElement => {

    return (
        <div className="modal-footer">
            <div className="float-right">
                <button className="btn btn-primary btn-margin-right ast_reject_ok_btn"
                        onClick={okCallback}
                        disabled={!okEnabled}
                        tabIndex={tabIndex}>
                    {okText}
                </button>
                <button className="btn btn-default"
                        onClick={cancelCallback}
                        disabled={!cancelEnabled}
                        onBlur={loopFocus}
                        tabIndex={tabIndex + 1}>
                    {cancelText}
                </button>
            </div>
        </div>

    )
}


export default OKCancelButtons;