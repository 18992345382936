import {
    SAVE_MY_HISTORY_DONE,
    SAVE_MY_HISTORY_LOADING,
    LOGGED_IN_TYPE,
    LOGGED_OUT_TYPE,
    HISTORY_PROCESS_TYPE_CHANGED,
    HISTORY_ADVANCED_SEARCH_OPEN,
    HISTORY_FILTER_CHANGED,
    HISTORY_SORT_COLUMN_CHANGED,
    HISTORY_GET_NEXT_PAGE,
    COST_UNITS_LOADED,
    PREVENT_DATA_RELOAD,
    HISTORY_SAVE_SCROLL_POSITION
} from '../store/actionTypes';
import {createSelector} from 'reselect';
import {PROCESS_TYPE, LOADING_STATUS, HISTORY_FILTERS} from '../../utils/constants';
import {formatDateTime} from '../../utils/valueFormatter.function';
import {getUsersCurrentContextId} from '../usercontext/user.reducer';
import * as _ from "lodash";

export const DEFAULT_STATE = {
    loadingStatus: LOADING_STATUS.LOADING,
    data: {
        page: 1
    },
    userContext: null,
    currentContext: null, // current context (value for filterCompany=true)
    processFilter: PROCESS_TYPE.ACTIVE, // process type filter for the in-view my history tasks
    advancedSearchOpen: false, // advanched search view
    costUnitsOptions: [],
    filter: {
        costUnits: [], //cost units filter - has the format required for backend
        columns: {}, //top grid filtering
        account: undefined //account filter,  in the advanced section
    },
    sortingLogic: {},   //sorting logic needed for the reactabular grid
    preventReload: false,
    scrollTop: 0
};
export const getHistoryTasksForRender = createSelector(
    [getTasks, getCurrentContextId, getHistoryProcessFilter],
    (tasksMap, currentCompany, processFilter) => {
        //todo add tasks mapping here
        let rows = [];
        if (tasksMap && tasksMap.rows && tasksMap.rows.length > 0) {
            let mappedTasks = _.cloneDeep(tasksMap);
            rows = mappedTasks.rows.map((row, index) => {
                row.displayId = row.displayId ? row.displayId : row.externalApplicationId;
                row.lastChanged = row.lastChanged ? formatDateTime(row.lastChanged) : '-';
                row.lastChangedByUserName = row.lastChangedByUserName ? row.lastChangedByUserName : '-';
                row.received = formatDateTime(row.received);
                row.index = row.key + "_" + index;
                row.currency = row.currency ? row.currency : '';
                return row;
            });
        }
        return {
            allTasks: rows,
            records: tasksMap.records,
            page: tasksMap.page
        };
    }
);


export default function myHistoryReducer(state = DEFAULT_STATE, action) {
    let partialState, newTasks;

    switch (action.type) {
        case SAVE_MY_HISTORY_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING
            };
            return Object.assign({}, state, partialState);

        case SAVE_MY_HISTORY_DONE:

            newTasks = action.data;

            partialState = {
                loadingStatus: LOADING_STATUS.DONE,
                data: newTasks
            };
            return Object.assign({}, state, partialState);

        case LOGGED_IN_TYPE:
            const newContextId = getUsersCurrentContextId(action.data);
            let newState = {};
            if (newContextId !== state.currentContext) {
                newState.currentContext = newContextId;
                newState.filter = Object.assign({}, DEFAULT_STATE.filter);
            }
            return Object.assign({}, state, newState);

        case HISTORY_PROCESS_TYPE_CHANGED:
            let newFilters = state.filter;
            if (newFilters.columns.status)
                delete newFilters.columns.status;

            partialState = {
                processFilter: action.processFilter,
                filters: newFilters,
                sortingLogic: {}
            };
            return Object.assign({}, state, partialState);

        case HISTORY_ADVANCED_SEARCH_OPEN:
            partialState = {
                advancedSearchOpen: action.advancedSearchOpen
            };
            return Object.assign({}, state, partialState);

        case HISTORY_FILTER_CHANGED:
            partialState = {
                filter: action.filter
            };
            return Object.assign({}, state, partialState);

        case HISTORY_SORT_COLUMN_CHANGED:
            partialState = {
                filter: Object.assign({}, state.filter),
                sortingLogic: Object.assign({}, action.sortLogic)
            };

            partialState.filter[HISTORY_FILTERS.SORTING] = action.sortColumn;
            return Object.assign({}, state, partialState);

        case HISTORY_GET_NEXT_PAGE:
            let oldData = Object.assign({}, state.data);
            let oldTasks = Object.assign([], state.data.rows);
            // concatenate old rows with new rows
            let allTasks = oldTasks.concat(action.data.rows);
            oldData.rows = allTasks;
            oldData.page = action.data.page;

            partialState = {
                loadingStatus: LOADING_STATUS.DONE,
                data: oldData
            };
            return Object.assign({}, state, partialState);

        case COST_UNITS_LOADED:
            partialState = {
                costUnitsOptions: action.data
            };
            return Object.assign({}, state, partialState);

        case PREVENT_DATA_RELOAD:
            partialState = {
                preventReload: action.data
            };
            return Object.assign({}, state, partialState);

        case HISTORY_SAVE_SCROLL_POSITION:
            partialState = {
                scrollTop: action.data
            };
            return Object.assign({}, state, partialState);

        case LOGGED_OUT_TYPE:
            return DEFAULT_STATE;
        default:
            return state;
    }
}

export function getTasks(state) {
    return state.data ? state.data : [];
}

export function getCurrentContextId(state) {
    return state.currentContext;
}

export function getHistoryLoadingStatus(state) {
    return state.loadingStatus;
}

export function getHistoryProcessFilter(state) {
    return state.processFilter;
}

export function gettoggleAdvancedSearch(state) {
    return state.advancedSearchOpen;
}

export function getHistoryFilter(state) {
    return state.filter;
}

export function getHistorySortColumn(state) {
    return state.sortingLogic;
}

export function getCostUnitsOptions(state) {
    return state.costUnitsOptions;
}

export function shouldPreventReload(state) {
    return state.preventReload;
}

export function getHistoryScrollTop(state) {
    return state.scrollTop;
}