import {SHARED_PARAMETERS} from "utils/api/api.impl";
import {snowplowTrackEvent} from "utils/snowplow";
import UTIF from "utif";


const HTML_MIME_TYPE = "text/html";
const TIFF_MIME_TYPE = "image/tiff";

export async function fetchAttachmentBlob(url: string, mimeType: string, userData: any): Promise<string> {

    let objectURL: string = "";
    let startTime = new Date().getTime();
    try {
        // @ts-ignore
        let approvalResponse = await fetch(url, SHARED_PARAMETERS);
        const urlToDataStorage = await approvalResponse?.json();


        let apiStorageUrl = urlToDataStorage.result + "?corsOrigin=" + encodeURIComponent(window.location.origin);
        let dataStorageReply = await fetch(apiStorageUrl);
        const attachmentBlob: Blob | undefined = await dataStorageReply?.blob();

        if (attachmentBlob) {
            if (attachmentBlob.type === TIFF_MIME_TYPE) {
                let arrayBufferTiff = await attachmentBlob.arrayBuffer();
                let decodedTiff = UTIF.decode(arrayBufferTiff);
                UTIF.decodeImage(arrayBufferTiff, decodedTiff[0]);
                let imageObject = await UTIF.toRGBA8(decodedTiff[0]);
                imageObject.width = decodedTiff[0].width;
                imageObject.height = decodedTiff[0].height;

                return imageObject;
            } else {
                const encodedBlob = mimeType === HTML_MIME_TYPE ? new Blob([attachmentBlob], {type: mimeType + ';charset=utf-8'}) : attachmentBlob;
                objectURL = URL.createObjectURL(encodedBlob);
            }
        }

    } catch (error) {
        if (error instanceof Error)
            console.log("Could not get the attachments url " + error.message);
    }
    let stopTime = new Date().getTime();
    let total = ((stopTime - startTime) / 1000).toString() || "unknown";

    snowplowTrackEvent({
        category: "bypass-cir",
        action: "fetch-attachment",
        label: "fetch-attachment",
        value: total
    }, userData);

    return objectURL;
}

export default fetchAttachmentBlob