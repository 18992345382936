import { mapErrorMessage } from "utils/errorHandle.function";
import OneButtonModalFooter from "components/modals/OneButtonModalFooter";
import { MODAL_CONTENT_TAB_INDEX } from "utils/constants";

const ErrorModal = ({more, translate, closeModal, loopFocus}) => {
    const errorMessage = mapErrorMessage(more.error);

    return <div>
        <div className="modal-body">
            <p>{errorMessage}</p>
        </div>
        <OneButtonModalFooter tabIndex={MODAL_CONTENT_TAB_INDEX + 1} enabled={true} callback={closeModal} text={translate("popUp.cancel")} loopFocus = {loopFocus} />
    </div>;
}

export default ErrorModal;