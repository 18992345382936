import React from 'react';
import {DOCUMENT_TYPE} from '../../utils/constants';
import translate from "../translations/translations.wrapper.jsx";
import * as reduxSelectors from "../store/application.reducers";
import {connect} from "react-redux";

const RESOLVE_LINES = "resolveOnLines";
const BLOCK_DOCUMENTS = "optional";
const ALLOW_EDIT = "allowEdit";
const REAPPROVE_TYPE = "reapproveType";

export const StepSettings = (props) => {
    let {type, step, translate} = props;

    let changeResolveOnLines = function (event) {
        let value = event.target.checked;
        props.changeSetting(RESOLVE_LINES, value);
    };
    let changeBlockDocuments = function (event) {
        let value = !event.target.checked;
        props.changeSetting(BLOCK_DOCUMENTS, value);
    };
    let changeAllowEdit = function (event) {
        let value = event.target.checked;
        props.changeSetting(ALLOW_EDIT, value);
    };

    let changeReapprove = function (event, type) {
        props.changeSetting(REAPPROVE_TYPE, type);
    };

    return (
        <div className="d-flex flex-column mt-4">
            {(type === DOCUMENT_TYPE.INVOICE || type === DOCUMENT_TYPE.VOUCHER || type === DOCUMENT_TYPE.COST_REQUEST || type === DOCUMENT_TYPE.EXPENSE_CLAIM) &&
                <div className="checkbox mb-4">
                    <input type="checkbox"
                           id={`approve-lines-checkbox${step.stepNumber}`}
                           checked={step.resolveOnLines}
                           onChange={changeResolveOnLines}/>
                    <label htmlFor={`approve-lines-checkbox${step.stepNumber}`}>
                        {translate("workflowDetails.approveLines")}
                    </label>
                </div>
            }
            <div className="checkbox mb-4">
                <input type="checkbox"
                       id={`workflow-fail-checkbox${step.stepNumber}`}
                       checked={!step.optional}
                       onChange={changeBlockDocuments}/>
                <label htmlFor={`workflow-fail-checkbox${step.stepNumber}`}>
                    {translate("workflowDetails.workflowFail")}
                </label>
            </div>
            <div className="checkbox">
                <input type="checkbox"
                       id={`allow-edit-checkbox${step.stepNumber}`}
                       checked={step.allowEdit}
                       onChange={changeAllowEdit}/>
                <label htmlFor={`allow-edit-checkbox${step.stepNumber}`}>
                    {translate("workflowDetails.allowEdit")}
                </label>
            </div>

            {step.allowEdit &&
                <div className="d-flex flex-column ps-5">
                    <div className="radio">
                        <input type="radio"
                               id={`always-radio${step.stepNumber}`}
                               checked={step.reapproveType === "ALWAYS"}
                               onChange={(e) => changeReapprove(e, "ALWAYS")}/>
                            <label htmlFor={`always-radio${step.stepNumber}`}>
                                {translate("workflowDetails.always")}
                            </label>
                    </div>
                    <div className="radio">
                        <input type="radio"
                               id={`ask-approver-radio${step.stepNumber}`}
                               checked={step.reapproveType === "CONFIRMATION"}
                               onChange={(e) => changeReapprove(e, "CONFIRMATION")}/>
                        <label htmlFor={`ask-approver-radio${step.stepNumber}`}>
                            {translate("workflowDetails.ask")}
                        </label>
                    </div>
                    <div className="radio">
                        <input type="radio"
                               id={`never-radio${step.stepNumber}`}
                               checked={step.reapproveType === "NEVER"}
                               onChange={(e) => changeReapprove(e, "NEVER")}/>
                        <label htmlFor={`never-radio${step.stepNumber}`}>
                            {translate("workflowDetails.never")}
                        </label>
                    </div>
                </div>
            }
        </div>

    )
};

const withTranslations = translate(StepSettings);
const mapStateToProps = function (store) {
    return {
        usersBusinessFeatures: reduxSelectors.getUsersCurrentBusinessFeatures(store)
    };
};
const connected = connect(mapStateToProps, {})(withTranslations);

export default connected;