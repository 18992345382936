import React, {Component} from 'react';
import Select from 'react-select';
import * as api from "../../../utils/api/api";
import * as _ from "lodash";

export class SimulatorDepartments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departments: [],
        };

        this.fieldRef = null;
    }


    componentDidMount() {
        this.getDepartments();
    }

    getDepartments(){
        api.getSimulatorDepartments().then((response) => {
            if(response.result){
                const sorted = _.sortBy(response.result, 'organizationUnitId');
                this.setState({
                    departments: sorted.map((item,index) => {
                        return {
                            value: item.organizationUnitId,
                            label: `${item.organizationUnitId} - ${item.name}`
                        }
                    })
                })
            }
        })
    }

    render() {
        return (
            <div className="form-group margin-top" >
                <label className="form-label">{this.props.translate("workflowDetails.simulator.department")}</label>
                <Select options={this.state.departments}
                        ref={(field) => this.fieldRef=field}
                        defaultValue={this.props.department}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.props.onChange}
                        name="department"
                />
            </div>
        );
    }
}