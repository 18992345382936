import React, {useRef, useState, KeyboardEvent} from "react";
import largeSumIcon from "./icons/largeSumIcon.svg";
import {createNewDecisionTable, deleteDecisionTable} from "./decisionTable.functions";
import {DecisionTable} from "./decisionTable.class";
import {Dropdown} from "react-bootstrap";
import {CustomMoreMenu} from "../workflows/Rules.component";
import Confirm from "../popup/ConfirmActionPopup.component";
import {Link} from "react-router-dom";
import moment from "moment";
import {getDateFormatFromLanguage} from "../../utils/dateFormatter";
import * as reduxSelectors from "../store/application.reducers";
import {connect} from "react-redux";
import {Store} from "redux";
import * as Api from "../../utils/api/api";


type DecisionTablesListProps = {
    decisionTables: DecisionTable[] | null | undefined,
    translate: React.FunctionComponent,
    callback: () => void,
    showNewTable: boolean,
    showNewTableFinished: () => void
}

type DecisionTableRowProps = {
    table: DecisionTable | null,
    translate: React.FunctionComponent,
    callback: () => void,
    askDeleteTable: (etag: string | undefined, uuid: string) => void,
    userLanguageCode?: string,
    uuid: string
}


type AddDecisionTableProps = {
    defaultValue: string | any,
    addNewText: string
    isFirst: boolean,
    onBlur: (name: string) => void
}

export function DecisionTablesList(props: DecisionTablesListProps) {

    const [showConformDelete, setShowConformDelete] = useState<boolean>(false);
    const [nameOfTableToDelete, setNameOfTableToDelete] = useState<string>("");
    const [deleteTableId, setDeleteTableId] = useState<string | null>(null);
    const [deleteTableTag, setDeleteTableTag] = useState<string | undefined>(undefined);


    let deleteDecisionTableAction: () => void = function () {
        if (deleteTableId && deleteTableTag)
            deleteDecisionTable(deleteTableTag, deleteTableId).then(response => {
                setShowConformDelete(false);
                setDeleteTableId(null);
                setNameOfTableToDelete("")
                props.callback();
            });
    }

    let askDeleteTableAction: (etag: string | undefined, uuid: string) => void = function (etag, uuid) {
        setDeleteTableId(uuid);
        setDeleteTableTag(etag);
        // @ts-ignore
        if (props.decisionTables && props.decisionTables[uuid as keyof DecisionTable])
            // @ts-ignore
            setNameOfTableToDelete(props.decisionTables[uuid as keyof DecisionTable]?.name || "");
        setShowConformDelete(true)
    }

    let tableRow = props.decisionTables ? Object.keys(props.decisionTables).map((key: string) => {
        // @ts-ignore
        let table = props.decisionTables ? props.decisionTables[key][0] as DecisionTable : null;
        if (table)
            return <DecisionTableRow
                table={table}
                askDeleteTable={askDeleteTableAction}
                translate={props.translate}
                uuid={key}
                callback={props.callback}/>
        else return <li key={key}/>
    }) : null;

    return (
        <ul className="workflow-list list-unstyled">
            {props.decisionTables &&
                <div className="company">

                    <div className={"workflow-list-wrapper pl-32 pr-16"}>
                        <ul className={"company-workflow-list list-unstyled"}>
                            <li key={"header_column"} className={"row ml-8 px-0 mb-4 mr-24"}>
                                <strong className={"col-md-8"}>{props.translate("decisionTables.decisionTableName")}</strong>
                                <div className={"col-md-4 d-flex align-items-center"}>
                                    <div className={"col-md-6 ml-12"}><strong className={""}>{props.translate("decisionTables.status")}</strong></div>
                                    <div className={"col-md-6 p-0"}><strong className={"float-end"}>{props.translate("decisionTables.actions")}</strong>
                                    </div>
                                </div>

                            </li>


                            {tableRow}
                        </ul>
                    </div>

                    {props.showNewTable &&
                        <AddDecisionTable defaultValue={props.translate("decisionTables.newDecisionTableName")}
                                          addNewText={"new"}
                                          isFirst={true}
                                          onBlur={(name: string) => {
                                              props.showNewTableFinished();
                                              createNewDecisionTable(name).then(() => {
                                                  props.callback();
                                              });
                                          }}/>
                    }
                </div>


            }
            {showConformDelete &&
                <Confirm translate={props.translate}
                         message={props.translate("decisionTables.confirmDialog.deleteMessage", nameOfTableToDelete)}
                         handleAction={deleteDecisionTableAction}
                         closeCallback={() => {
                             setShowConformDelete(false)
                         }}/>
            }
        </ul>
    )
}


function DecisionTableRow(props: DecisionTableRowProps) {
    const dateValueFormatter = getDateFormatFromLanguage(props.userLanguageCode);

    const [createdByUser, setCreatedByUser] = useState<string>("");
    const [updatedByUser, setUpdatedByUser] = useState<string>("");

    if (props.table) {
        Api.getCompanyUser(props.table.createdBy).then((result: object) => {
            // @ts-ignore
            setCreatedByUser(result.name);
        });
        Api.getCompanyUser(props.table.updatedBy).then((result: object) => {
            // @ts-ignore
            setUpdatedByUser(result.name);
        });
    }

    // @ts-ignore
    let createdBy = props.translate("decisionTables.createdBy", createdByUser, moment(props.table.created).format(dateValueFormatter));
    // @ts-ignore
    let updatedBy = props.translate("decisionTables.lastUpdated", updatedByUser, moment(props.table.updated).format(dateValueFormatter));


    return (
        <li className={"workflow row mx-0"} key={props.uuid}>
            <Link to={"decisionTables/details/" + props.table?.name + "/" + props.uuid}
                  className={"d-flex text-decoration-none"}>
                <div className={"col-md-8"}>
                    <div>
                        <img src={largeSumIcon} className={"border-0 mr-8"} alt={"sum"}/>
                        {props.table?.name}
                    </div>
                    <div className={"font-12 workflow-more ml-12 mt-12"}>
                        {createdBy + " " + updatedBy}
                    </div>

                </div>
                <div className="col-md-4 d-flex align-items-center padding-right-4">
                    <div className={"col-md-6"}>
                        <span className="label label-warning">{props.table?.status}</span>
                    </div>
                    <div className={"col-md-6 d-flex justify-content-end"}>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomMoreMenu}/>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey="3" onClick={() => {
                                    props.askDeleteTable(props.table?.etag, props.uuid);
                                }}>
                                    {props.translate("workflows.delete")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Link>
        </li>

    )
}

const mapStateToProps = function (store: Store) {
    return {
        userLanguageCode: reduxSelectors.getUsersCurrentLanguage(store)
    };
};

connect(mapStateToProps, {})(DecisionTableRow);


function AddDecisionTable(props: AddDecisionTableProps) {

    const workflowNameValue = useRef<HTMLInputElement>(null);

    const onBlur = function () {
        if (workflowNameValue.current)
            props.onBlur(workflowNameValue.current.value);
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab': {
                onBlur();
                return;
            }
            default:
                return event;
        }
    }

    return (
        <div className="workflow-list-wrapper workflow-add-new pl-16 pr-16" key="addDecisionTable_9999">
            <div className="company-workflow-list mx-0">

                <div className="workflow mb-4 ml-24">
                    <input type="text" autoFocus={true}
                           className={"new-rule-name"}
                           onBlur={onBlur}
                           onFocus={(e) => {
                               e.target.select()
                           }}
                           onKeyDown={handleKeyDown}
                           defaultValue={props.defaultValue}
                           ref={workflowNameValue}/>
                </div>
            </div>
        </div>
    )
}