import React from 'react';
import '../pagelayout/global.scss';
import './tasksListHeader.scss';
import {COLUMNS} from './taskListSorting.function';
import {KEYS} from "../../utils/constants";

const TaskListHeader = ({
                            translate,
                            currentSort,
                            sortByCallback,
                            selectAllCallback,
                            currentAllSelected,
                            displayedDate
                        }) => {

    /**
     * definition of all columns for myTasks (I don't want to put it into general constants
     * @return {[*,*,*,*]}
     */
    function getColumnDefinition(displayedDate) {
        let columns = [
            {
                name: 'documentType', // will be used for picking proper translation .. also it's discriminator of column from taskListSorting
                align: 'center', // css
                size: 'document-type-column' // css
            },
            {
                name: 'supplierName',
                align: 'left',
                size: 'supplier-name-column'
            },
            {
                name: 'description',
                align: 'left',
                size: "description-column"
            }
        ];

        columns.push(
            {
                name: 'companyName',
                align: 'left',
                size: 'company-name-column'
            }
        );
        columns.push(
            {
                name: displayedDate,
                align: 'left',
                size: 'document-due-column'
            }
        );
        columns.push(
            {
                name: 'amount',
                align: 'right',
                size: 'amount-column'
            },
            {
                name: 'numberOfComments',
                align: 'center',
                size: 'comments-column'
            }
        );

        return columns;
    }

    const columns = getColumnDefinition(displayedDate);

    let columnsView = columns.map((column, index) => {
            const alignClass = column.size + " header-column px-0 align-" + column.align;
            const sortIcon = <span className={"sort-arrow sort-arrow-" + (currentSort > 0 ? "up" : "down")}/>;
            const columnId = COLUMNS[column.name];
            const current = Math.abs(columnId) === Math.abs(currentSort);

            return (
                <li key={columnId + "_" + column.name} className={alignClass} onClick={() => {
                    if (current) sortByCallback(-1 * currentSort);
                    else sortByCallback(columnId);
                }}>
                    <span className="caption" title={column.name===displayedDate?translate("myTasks.selectDate." + displayedDate):undefined}>{translate("myTasks.column." + column.name)}</span>
                    {current && sortIcon}
                </li>
            )
        }
    );

    let handleKeyDown = function (event) {
        if(event.keyCode===KEYS.ENTER){
            event.preventDefault();
            selectAllCallback();
        }
    }

    return (
        <ul className="list-unstyled row px-0 mx-0 d-flex flex-row justify-content-between">
            <li className="task-checkbox" key="checkbox_1">
                <div className="checkbox my-0" onClick={() => selectAllCallback()} onKeyDown={handleKeyDown}>
                    <input type="checkbox"
                           aria-label={translate("myTasks.selectAllTasks")}
                           checked={currentAllSelected} onChange={() => {
                        console.debug("select all clicked")
                    }}/>
                    <label className="checkbox-label text-transparent">.</label>
                </div>
            </li>
            {columnsView}
            <li className="text-end px-0 float-right task-actions">
                <div className="caption">{translate("myTasks.column.action")}</div>
            </li>
        </ul>
    );

};

export default TaskListHeader;

