import React from 'react';
import {formatDate, formatAmountValue, formatDocumentOrganizationUnit} from '../../../../utils/valueFormatter.function';
import {CostCentersDetails} from "./CostCentersDetails.component";

const ExpenseDocumentDetailsDTO = ({details, translate}) => {
    let displayId = details.displayId ? details.displayId : details.documentId;
    // details.branch = {
    //     branchId: 12,
    //     branchCd: "mockedBranchCD",
    //     name: "mockedBranchName"
    // }
    return (
        <div>
            {details.description &&
            <div className="document-data-field">
                <div className="document-data-field-label">
                    {translate("documentData.description")}
                </div>
                <div className="document-data-field-value"
                     title={details.description}>
                    {details.description}
                </div>
            </div>}

            <div className="document-data-divided">
                {details.branch && (details.branch.branchCd || details.branch.name) &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.branch")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.branch.name ? details.branch.name : details.branch.branchCd}>
                        {details.branch.name ? details.branch.name : details.branch.branchCd}
                    </div>
                </div>}

                {details.claimant &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.claimant")}
                    </div>
                    <div className="document-data-field-value"
                         title={details.claimant}>
                        {details.claimant}
                    </div>
                </div>}

                {details.employeeCode &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.employeeCode")}
                    </div>
                    <div className="document-data-field-value">
                        {details.employeeCode}
                    </div>
                </div>}

                {details.receivedDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.receivedDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.receivedDate)}
                    </div>
                </div>}

                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.documentID")}
                    </div>
                    <div className="document-data-field-value"
                         title={displayId}>
                        {displayId}
                    </div>
                </div>

                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.amount")}
                    </div>
                    <div className="document-data-field-value">
                        <b>{formatAmountValue(details.amount)} </b>
                        {details.currency}
                        {(details.foreignAmount && details.currency !== details.foreignCurrency) &&
                        <div>
                            <small>
                                <span>{formatAmountValue(details.foreignAmount)} </span>
                                {details.foreignCurrency}
                            </small>
                        </div>}
                    </div>
                </div>

                {details.dueDate &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.dueDate")}
                    </div>
                    <div className="document-data-field-value">
                        {formatDate(details.dueDate)}
                    </div>
                </div>}

                {details.documentOrganizationUnit &&
                <div className="document-data-field third">
                    <div className="document-data-field-label">
                        {translate("documentData.organizationUnit")}
                    </div>
                    <div className="document-data-field-value"
                         title={formatDocumentOrganizationUnit(details.documentOrganizationUnit)}>
                        {formatDocumentOrganizationUnit(details.documentOrganizationUnit)}
                    </div>
                </div>}

                {details.documentCostUnitList && details.documentCostUnitList.length > 0 &&
                <CostCentersDetails costUnitList={details.documentCostUnitList}
                                    translate={translate}/>}
            </div>
        </div>

    )
};
export default ExpenseDocumentDetailsDTO;

