import React from "react";
import * as reduxSelectors from "../../store/application.reducers";
import {connect} from "react-redux";
import {getCostUnitsBranches} from "../costunits.action";
import arrowDown from "../../../assets/arrow-down-icon.png";

let AssignApproversGridHeader = (props) => {

    const arrow = (<img className={`p-1 me-2 arrow-icon ${props.sort.sortOrder === "asc" ? 'up' : ''}`}
                         src={arrowDown}
                         alt="arrow down"/>);

    let sort = function (type) {
        if (props.id && props.level) {
            let sortOrder;
            if (props.sort.sortColumn === type) {
                sortOrder = props.sort.sortOrder === "asc" ? "desc" : "asc";
            }
            else {
                sortOrder = "asc";
            }
            let response = {sortColumn: type, sortOrder: sortOrder};
            props.onSort(response, props.id, props.level);
        }
    };

    return (
        <div className="row header-row">
            <div className={`${props.costUnitsBranches.length > 0 ? "col-md-2" : "col-md-3"}`}
                 onClick={sort.bind(this, "role_name")}>
                <span className="margin-left-8">
                    {props.translate("costUnitApprovers.approverRole")}
                </span>
                {props.sort.sortColumn === "role_name" && arrow}
            </div>
                <div className={`${props.costUnitsBranches.length > 0 ? "col-md-4" : "col-md-6"}`}
                     onClick={sort.bind(this, "approver")}>
                    {props.translate("costUnitApprovers.approver")}
                    {props.sort.sortColumn === "approver" && arrow}
                </div>
            {props.costUnitsBranches.length > 0 &&
                <div className="col-md-3"
                     onClick={sort.bind(this, "branch")}>
                    {props.translate("costUnitApprovers.branch")}
                    {props.sort.sortColumn === "branch" && arrow}
                </div>
            }
            <div className="col-md-2"
                 onClick={sort.bind(this, "deputy")}>
                {props.translate("costUnitApprovers.deputy")}
                {props.sort.sortColumn === "deputy" && arrow}
            </div>
            <div className="col-md-1">
                {props.translate("costUnitApprovers.actions")}
            </div>
        </div>

    );
};

const mapStateToProps = function (store) {
    return {
        costUnitsBranches: reduxSelectors.getCostUnitBranches(store)
    };
};

AssignApproversGridHeader = connect(mapStateToProps, {
    getCostUnitsBranches,
})(AssignApproversGridHeader);
export {AssignApproversGridHeader};
