import {SAVE_SPLITBUTTON_PREFERENCE} from '../store/actionTypes';


export function savePreference(buttonId, actionType){
    return {
        type: SAVE_SPLITBUTTON_PREFERENCE,
        buttonId: buttonId,
        data: actionType
    }
}
