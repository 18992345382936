import {TASK_ACTIONS, KEYS} from './constants';
import * as _ from "lodash";
import {approveMultipleShortcutKey} from "../components/mytasks/myTasks.action";
import store from 'components/store/Store.component';

const TEXT_AREA = "textarea";
const TEXT = "text";

/**
 * convert utc to localized string
 * @param event
 * @param taskAction
 * @param selectedKeys
 * @param selectedTasks
 */
export function keyPressHandler(event, taskAction, selectedKeys, activeActions) {

    if (canDo(event)) {
        switch (event.keyCode) {
            //pressed 'a' -> open approve dialog
            case KEYS.A_KEY:
                event.preventDefault();
                let shouldEnforceDialog = selectedKeys.length > 0;
                store.dispatch(approveMultipleShortcutKey(shouldEnforceDialog));
                doActionFromList(activeActions, TASK_ACTIONS.APPROVE, taskAction, selectedKeys);
                break;
            //pressed 'e' -> open email dialog
            case KEYS.E_KEY:
                event.preventDefault();
                doActionFromList(activeActions, TASK_ACTIONS.EMAIL, taskAction, selectedKeys);
                break;
            //pressed 'f' -> open forward dialog
            case KEYS.F_KEY:
                event.preventDefault();
                doActionFromList(activeActions, TASK_ACTIONS.FORWARD, taskAction, selectedKeys);
                break;
            //pressed 'r' -> open reject dialog
            case KEYS.R_KEY:
                event.preventDefault();
                doActionFromList(activeActions, TASK_ACTIONS.REJECT, taskAction, selectedKeys);
                break;
            //pressed 'v' -> open review dialog
            case KEYS.V_KEY:
                event.preventDefault();
                doActionFromList(activeActions, TASK_ACTIONS.SEND_FOR_REVIEW, taskAction, selectedKeys);
                break;
            default:
            // nothing
        }
    }
}

export function canDo(e) {
    //do not navigate when user in in a text area or if the popup is visible
    let notPopUpVisible = _.findIndex(e.target.classList, name => {
        return name === "pop-up"
    }) === -1;
    let isNotTextArea = e.target.type !== TEXT_AREA && e.target.type !== TEXT;
    let isNotComboKeyPress = !e.altKey && !e.ctrlKey && !e.shiftKey && !e.metaKey;

    return (isNotTextArea && notPopUpVisible && isNotComboKeyPress);
}

export function handleNPKeyNavigation(event, taskId, callbackToExecute) {
    //do not navigate when user in a text area or if the popup is visible
    let notPopUpVisible = document.getElementsByClassName("pop-up").length === 0;
    let isNotTextArea = event.target.type !== TEXT_AREA && event.target.type !== TEXT;
    if (isNotTextArea && notPopUpVisible) {
        switch (event.keyCode) {
            //pressed 'n'
            case 78:
                const next = _.debounce(callbackToExecute.bind(null, taskId, true), 500);
                next();
                break;

            //pressed 'p'
            case 80:
                const prev = _.debounce(callbackToExecute.bind(null, taskId, false), 500);
                prev();
                break;

            default:
            // nothing
        }
    }
}


function doActionFromList(activeActions, action, taskAction, selectedKeys, navigate) {
    if (activeActions.isEnabled(action)) {
        taskAction(action, selectedKeys, navigate);
    }
}

