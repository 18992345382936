import React from "react";
import translate from "components/translations/translations.wrapper";
import SpacePlanetPictogram from "components/taskdetails-remake/icons/pictogram-space-planet.svg";

export const Unknown = ({translate}: any): React.ReactElement => {
    return <div className={"attachment-image-container unknown h-100 px-96 pb-96 pt-128 bg-natural-white rounded-8"}>

        <img src={new URL(SpacePlanetPictogram, window.location.origin).href} alt={"planet"} />
        <div className={"mb-12"}><strong>{translate("taskDetail.panels.attachments.noFile")}</strong></div>
        <span>{translate("taskDetail.panels.attachments.noFileMore")}</span>

    </div>
}

export default translate(Unknown);