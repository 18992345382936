import React, {Component, Fragment} from "react";
import {
    CostUnitModelType,
    DecisionTablesProps,
    decisionTableColumn,
    dtColumnsResponse,
    DecisionTableRow,
    EmptyRowsProps,
    ApproversType
} from "../decisionTables/decisionTableTypes";
import DataGrid, {Column, DataGridHandle,FormatterProps, RowsChangeData, SelectColumn} from "react-data-grid";
import {Operator} from "../decisionTableDetails/Operators.component";
import {UserEditor} from "../decisionTableDetails/UserEditor.component";
import translate from "../translations/translations.wrapper";
import 'react-data-grid/lib/styles.css';
import {Dropdown, DropdownButton} from "react-bootstrap";
import CostUnitEditor from "../decisionTableDetails/CostUnitEditor.component";
import _ from "lodash";
import {getDecisionTablesToken} from "../decisionTables/decisionTable.functions";
import * as Api from "../../utils/api/api";
import {Tooltip} from "../tooltip/Tooltip";
import "./decisionTableDetails.scss";
import {v4 as uuid} from 'uuid';
import {getDecisionTableDetails, sendUpdatedDecisionTable} from "./decisionTableDetails.functions";

type DetailsState = {
    tableColumns: Column<DecisionTableRow>[] | null,
    columns: decisionTableColumn[] | null,
    selectedRows: ReadonlySet<number> | null,
    rows: DecisionTableRow[],
    token: string | null,
    costUnitModel: CostUnitModelType[] | null,
    etag: string
}


function createRows(): DecisionTableRow[] {
    const rows: DecisionTableRow[] = [];
    return rows;
}


interface newRowType {
    id: number,
    approvers: ApproversType[],

    [key: string]: any;
}


export class Details extends Component<DecisionTablesProps, DetailsState> {
    private grid: React.RefObject<DataGridHandle>;

    constructor(props: DecisionTablesProps) {
        super(props);
        this.state = {
            tableColumns: this.getInitialColumns(),
            columns: null,
            selectedRows: null,
            rows: createRows(),
            token: null,
            costUnitModel: null,
            etag: ""

        };
        this.grid = React.createRef<DataGridHandle>();

        this.getInitialColumns = this.getInitialColumns.bind(this);
        this.mapColumnType = this.mapColumnType.bind(this);
        this.rowKeyGetter = this.rowKeyGetter.bind(this);
        this.setCostUnitsValues = this.setCostUnitsValues.bind(this);
        this.updateDecisionTable = this.updateDecisionTable.bind(this);
        this.addNewRow = this.addNewRow.bind(this);
        this.mapBackendTableModel = this.mapBackendTableModel.bind(this);
        this.generateBaseColumns = this.generateBaseColumns.bind(this);

    }

    mapBackendTableModel(rows: DecisionTableRow[], columns: decisionTableColumn[]): void {
        let mappedRows: DecisionTableRow[] = [];
        let mappedCostUnits: CostUnitModelType[] = [];

        if (rows.length > 0) {
            rows.forEach((row: DecisionTableRow, index: number) => {
                let mRow: DecisionTableRow = {
                    id: index,
                    approvers: row.userIds
                };
                row.conditions.forEach((costUnit: CostUnitModelType) => {
                    let foundColumn = this.state.columns ? this.state.columns.find((col: decisionTableColumn) => {
                        return col.uuid === costUnit.column;
                    }) : null;
                    if (foundColumn) {
                        mRow[foundColumn.name] = costUnit.value;
                    }
                });
                mappedRows.push(mRow);
                mappedCostUnits.push(row.conditions);
            });
        }

        let newColumns = columns.map((column: decisionTableColumn, index: number) => {
            return this.mapColumnType(column, index);
        });
        let mappedColumns = this.state.tableColumns ? _.cloneDeep(this.state.tableColumns).concat(newColumns) : newColumns;
        this.setState({
            rows: mappedRows,
            costUnitModel: mappedCostUnits.length > 0 ? mappedCostUnits : null,
            tableColumns: mappedColumns
        });
    }

    generateBaseColumns() {
        let newColumns: Column<DecisionTableRow>[];

        Api.getAvailableColumnsForDocType(2).then((response: dtColumnsResponse) => {
            newColumns = response.columns.map((column: decisionTableColumn, index: number) => {
                column.uuid = uuid();
                return this.mapColumnType(column, index);
            });

            let mappedColumns = this.state.tableColumns ? _.cloneDeep(this.state.tableColumns).concat(newColumns) : [];
            this.setState({
                tableColumns: mappedColumns,
                columns: response.columns
            });
        });
    }

    componentDidMount() {
        getDecisionTablesToken().then((token: string | null) => {
            this.setState({token: token});

            getDecisionTableDetails(token, this.props.match.params.id).then((result: any) => {

                //no data for this table so we need to get possible columns
                if (result.table.columns && result.table.columns.length === 0) {
                    this.setState({
                        etag: result.etag
                    });
                    this.generateBaseColumns();
                } else {

                    this.setState({
                        etag: result.etag,
                        columns: result.table.columns
                    });

                    this.mapBackendTableModel(result.table.rows, result.table.columns);
                }
            });
        })

    }

    getInitialColumns() {
        return [
            SelectColumn,
            {
                key: 'id',
                name: "Rule",
                width: 50,
                frozen: true,
                minWidth: 40
            },
            {
                key: 'approvers',
                name: 'Approver(s)',
                width: "20%",
                resizable: true,
                editable: true,
                frozen: true,
                formatter: (p: FormatterProps<DecisionTableRow, unknown>) => (
                    <Fragment>
                        <div className={"w-100"}>
                            <Operator row={p.row.id} column={p.column.idx} value={"Is"}/>
                        </div>
                        <UserEditor p={p} translate={this.props.translate}/>
                    </Fragment>
                )
            }
        ]
    }

    onGridRowsUpdated: (rows: DecisionTableRow[], data: RowsChangeData<DecisionTableRow>) => void = function (rows, data) {
        // @ts-ignore
        setRows((rows) => {
            let updatedRows = rows.slice();
            data.indexes.forEach((index: number) => {
                updatedRows[index] = rows[index];
            })
            return {updatedRows};
        });
    }

    setCostUnitsValues: (this: any, value: CostUnitModelType, rowId: number) => void = function (this, value, rowId) {
        let model: CostUnitModelType[][] | null = _.cloneDeep(this.state.costUnitModel);
        //search for the column uuid
        let findColumnIndex = this.state.columns.findIndex((column: decisionTableColumn) => {
            return column.name === value.column;
        })

        if (findColumnIndex !== -1) {
            value.column = this.state.columns[findColumnIndex].uuid;
        }

        if (model && model[rowId] && model[rowId].length > 0) {
            let found = model[rowId].findIndex((unit: CostUnitModelType) => {
                return unit.column === value.column
            });

            if (found !== -1) {
                model[rowId][found].operator = value.operator;
                model[rowId][found].value = value.value;
                model[rowId][found].name = value.name;
                this.setState({costUnitModel: model});
            } else {
                model[rowId].push(value);
                this.setState({costUnitModel: model});
            }
        } else {
            let model = _.cloneDeep(this.state.costUnitModel);
            model[rowId].push(value);
            this.setState({costUnitModel: model});
        }
    }

    mapColumnType: (this: any, column: decisionTableColumn, index: number) => Column<DecisionTableRow> = function (this: any, column, index) {
        if (column.type === "money") {
            let col: Column<DecisionTableRow> = {
                key: column.key ? column.name + "_" + column.key + "" : index + "",
                name: column.name,
                resizable: true,
                formatter: (props) => (
                    <Fragment>
                        <div className={"w-100"}>
                            <Operator row={props.row.id} column={props.column.idx} value={"Is"}/>
                        </div>
                        <input type="number"
                               min={10}
                               max={10000000}
                               step={0.03}
                               className={"numeric_input"}
                               value={props.row[column.name as keyof DecisionTableRow]}
                               onChange={(e) => {
                                   let model: CostUnitModelType = {
                                       column: props.column.name.toString(),
                                       operator: "=",
                                       value: e.target.value,
                                       name: props.column.name.toString()
                                   }
                                   this.setCostUnitsValues(model, props.row.id);
                                   let newRow = _.cloneDeep(props.row);
                                   newRow[props.column.name as keyof DecisionTableRow] = e.target.value;
                                   props.onRowChange(newRow);
                               }}
                        />
                    </Fragment>
                )
            }
            return col;
        } else if (column.matchingField === "custom.role") {

            let menuItems = <Dropdown.Item>NONE</Dropdown.Item>;

            let col: Column<DecisionTableRow> = {
                key: column.key ? column.name + "_" + column.key + "" : index + "",
                name: column.name,
                resizable: true,
                formatter: (props) => (
                    <Fragment>
                        <div className={"w-100"}>
                            <Operator row={props.row.id} column={props.column.idx} value={"Any"}/>
                        </div>
                        <DropdownButton id="Role_Selection"
                                        className="text-start disabled"
                                        bsPrefix="btn btn-default"
                                        title={"ROLES"}>
                            {menuItems}
                        </DropdownButton>
                    </Fragment>
                )
            }
            return col;
        } else if (column.type === "costunit") {
            let col: Column<DecisionTableRow> = {
                key: column.key ? column.name + "_" + column.key + "" : index + "",
                name: column.name,
                resizable: true,
                width: "10%",
                formatter: (props) => (
                    <Fragment>
                        <div className={"w-100"}>
                            <Operator row={props.row.id} column={props.column.idx} value={"Is"}/>
                        </div>
                        <CostUnitEditor dimension={column.key} p={props} setCostUnitsValues={this.setCostUnitsValues}
                                        value={props.row[column.name as keyof DecisionTableRow]}/>
                    </Fragment>
                )
            }
            return col;
        } else if (column.matchingField === "requester") {
            let col: Column<DecisionTableRow> = {
                key: column.key ? column.name + "_" + column.key + "" : index + "",
                name: column.name,
                resizable: true,
                width: "10%",
                formatter: (props) => (
                    <Fragment>
                        <div className={"w-100 disabled"}>
                            <Operator row={props.row.id} column={props.column.idx} value={"Is"}/>
                        </div>
                        <UserEditor p={props} translate={this.props.translate}/>
                    </Fragment>
                )
            }
            return col;
        } else {
            return {
                key: column.key ? column.name + "_" + column.key + "" : index + "",
                name: column.name,
                resizable: true
            }
        }
    }

    rowKeyGetter(row: DecisionTableRow) {
        return row.id;
    }

    updateDecisionTable(this: any) {
        let {token, etag, columns, costUnitModel, rows} = this.state;
        let {match} = this.props;

        let mappedRow = rows.map((row: DecisionTableRow, index: number) => {
            let objToReturn: any = {
                userIds: row.approvers.map((user: ApproversType) => {
                    return user.value
                }),
                rowId: row.id,
                conditions: (costUnitModel && costUnitModel[index]) ? costUnitModel[index] : []
            }
            return objToReturn;
        })
        sendUpdatedDecisionTable(token, etag, match.params.id, mappedRow, match.params.name, columns);
    }

    addNewRow(this: any) {
        let newRow: newRowType = {
            id: this.state.rows.length,
            approvers: []
        };

        let rowToAdd: DecisionTableRow[] = _.clone(this.state.rows);
        rowToAdd.push(newRow);

        let newCostUnitModel: CostUnitModelType[][] = this.state.costUnitModel ? _.cloneDeep(this.state.costUnitModel) : [];
        if (newCostUnitModel)
            newCostUnitModel.push([]);
        else {
            newCostUnitModel = [];
        }

        this.setState({rows: rowToAdd, costUnitModel: newCostUnitModel});
    }

    render() {
        return (<div className="pt-4 workflows">
                <div className="float-right text-end me-3">
                    <div className="d-inline-flex">
                        <button
                            className="btn btn-primary me-3 mb-0"
                            onClick={this.addNewRow}>
                            {this.props.translate("decisionTables.details.addNewRow")}
                        </button>
                    </div>
                </div>
                <h3 className="no-border d-flex align-items-center">{this.props.translate("decisionTables.title")}
                    <span className="vismaicon vismaicon-filled vismaicon-help ms-4" data-tip
                          data-for={"Decision_Table_Title_Info"}/>
                    <Tooltip
                        id={"Decision_Table_Title_Info"}
                        content={() => <span>{this.props.translate("decisionTables.info")}</span>}
                    />
                </h3>

                {this.state.tableColumns &&
                    <Fragment>
                        <div className={"col-md-12 p-0 m-0"}>
                            <DataGrid columns={this.state.tableColumns}
                                      ref={this.grid}
                                      className={"demo-grid"}
                                      rowKeyGetter={this.rowKeyGetter}
                                      rows={this.state.rows}
                                      renderers={{
                                          noRowsFallback: <EmptyRowsRenderer translate={this.props.translate}/>
                                      }}
                                      rowHeight={120}
                                      headerRowHeight={65}
                                      onRowsChange={(e) => {
                                          this.setState({rows: e})
                                      }}
                                      selectedRows={this.state.selectedRows}
                                      onSelectedRowsChange={(e) => {
                                          this.setState({selectedRows: e})
                                      }}
                                      enableVirtualization={true}
                            />
                        </div>

                        <div className={"fixed-bottom row px-4 task-action-buttons mb-4 "}>
                            <div className="float-right text-end me-3">
                                <div className="d-inline-flex">
                                    <button
                                        className="btn btn-primary me-3 mb-0"
                                        onClick={this.updateDecisionTable}>
                                        {this.props.translate("decisionTables.details.updateDecisionTable")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        )
    }
}

const withTranslations = translate(Details);
export default withTranslations;


function EmptyRowsRenderer(props: EmptyRowsProps) {
    return (
        <div className={"row m-0 p-0"} style={{textAlign: 'center', gridColumn: '1/-1'}}>
            <div style={{textAlign: 'center', gridColumn: '1/-1'}}>
                {props.translate("decisionTables.details.noGridData")}
            </div>
        </div>
    );
}