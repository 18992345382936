/**
 * Wraps around calls made for search type input
 * Makes sure the correct results are passed by cancelling previous call and only considering the last call made
 * **/


export class RequestController {

    constructor() {
        this.currentRequest = null;
        this.single = this.single.bind(this);
    }

    single(request) {
        if (this.currentRequest != null) {
            this.currentRequest.cancel();
        }


        this.currentRequest = request;
        return request.then((response) => {
            this.currentRequest = null;
            return response;
        }).catch((error) => {
            this.currentRequest = null;
            return error;
        })
    }


}