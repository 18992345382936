import * as Api from "../../utils/api/api.js";
import translate from "components/translations/translations.wrapper";
import { Account, CostUnitOrganisation } from "types/organisationUnit";
import { AdvancedSearchProps } from "types/advancedSearch";
import AdvancedSearchInput from "components/input/AdvancedSearchInput.component";


export function AdvancedSearchFiltering(props: AdvancedSearchProps) {

    const computeSelectedValue = function(data: Account | CostUnitOrganisation) {
        if (data.selectedValue) {
            return {
                label: data.label,
                value: data.selectedValue,
            };
        }
        return null;

    };
    const organizationTypes = props.costUnits.map((org: CostUnitOrganisation) => {
        const cuLabel = props.translate("myHistory.selectCostUnit", org.name);
        return (
            <AdvancedSearchInput key={org.name + "+" + org.dimension}
                                 placeholder={cuLabel}
                                 name={org.name}
                                 type={Api.lookupCostCenters}
                                 selectedValue={computeSelectedValue(org)}
                                 dimension={org.dimension}
                                 onSearch={props.onSearch}
                                 onChange={props.onSearch.bind(null, org.dimension)}
                                 translate={props.translate}/>
        );
    });

    const accountLabel = props.translate("myHistory.selectAccount");
    const accountingLookup = (<AdvancedSearchInput
        key={"accounting"}
        placeholder={accountLabel}
        name={props.translate("myHistory.account")}
        dimension={null}
        type={Api.lookupAccount}
        selectedValue={computeSelectedValue(props.account)}
        onSearch={props.onSearch}
        onChange={props.onAccountSearch.bind(null)}
        translate={props.translate}/>);

    return (

        <div className="panel-body new-advanced-search-container">
            <div className="row col-md-12 btn-toolbar-advanced advanced-search-header">
                <div className="col-auto mr-auto px-0 title">Advanced search</div>
                <div className="col-auto">
                    <button type="button"
                            className={"btn btn-destructive btn-link " + (!props.isClearActive ? "disabled" : "")}
                            onClick={props.onClearFilters}
                            disabled={!props.isClearActive}>
                         <span className="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-delete"></span>
                        {props.translate("myHistory.clearAllFilters")}
                    </button>
                </div>
            </div>
            <div className="row col-md-12 advanced-search-header-border margin-bottom-8"></div>
            <div className="row col-md-12">
                {organizationTypes}
                {accountingLookup}
            </div>
        </div>
    );

}

const withTranslations = translate(AdvancedSearchFiltering);
export default withTranslations;
