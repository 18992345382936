import React from "react";
import {AttachmentInformationProps} from "types/attachment";
import "./styles/attachmentInformation.scss";
import translate from "components/translations/translations.wrapper";
import arrowDownDark from "components/taskdetails-remake/icons/arrow-down-dark.svg";
import arrowUpDark from "components/taskdetails-remake/icons/arrow-up-dark.svg";
import {taskAction} from 'components/mytasks/action/taskAction.action';
import {TASK_ACTIONS} from "utils/constants";
import {useSelector, useDispatch} from "react-redux";
import {Store} from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import { snowplowTrackEvent } from "utils/snowplow";

const AttachmentInformation = ({
                                   title,
                                   description,
                                   created,
                                   uploaderName,
                                   translate,
                                   collapseCallback,
                                   isPanelCollapsed,
                                   canBeDeleted
                               }: AttachmentInformationProps): React.ReactElement => {


    const panelRef = React.useRef<HTMLDivElement>(null);
    const arrowRef = React.useRef<HTMLImageElement>(null);

    const taskKey = useSelector((store: Store) => reduxSelectors.getTaskDetails(store))?.key;
    const isPreviewDetached = useSelector((store: Store) => reduxSelectors.isDocumentPreviewDetached(store));
    const userData = useSelector((store: Store) => reduxSelectors.getUsersData(store));
    const dispatch = useDispatch();

    const toggleCollapsedState = () => {
        collapseCallback();
        panelRef.current?.classList.toggle("collapsed");
        const isPanelCollapsed = panelRef.current?.classList.contains("collapsed");

        if (arrowRef.current)
            arrowRef.current.src = isPanelCollapsed ? arrowDownDark : arrowUpDark;
    }

    const handleDeleteAttachment = () => {
        if(isPreviewDetached) {
            snowplowTrackEvent({
                category: "task_detail",
                action: "detach-window",
                label: "remove-attachment",
                value: "true",
            }, userData);
        }
        if (taskKey)
            dispatch(taskAction(TASK_ACTIONS.DELETE_ATTACHMENT, [taskKey]))

    }

    return (
        <div className={`attachment-information ${isPanelCollapsed ? "collapsed" : ""}`} ref={panelRef}>
            <div className={"h-100 float-left mr-24 ml-12"}>
                <img src={isPanelCollapsed ? arrowDownDark : arrowUpDark}
                     className={"border-0 cursor-pointer collapse-button"}
                     alt={"collapse arrow"}
                     ref={arrowRef}
                     onClick={toggleCollapsedState}/>
            </div>
            <div className={"d-flex justify-content-between"}>
                <div className="attachment-information__title">
                    <div className={"font-12 attachment-information__title__div pb-2"}>
                        {translate("taskDetail.panels.attachmentInfo.title")}
                    </div>
                    {title || ""}
                </div>
                <div className="attachment-information__date pl-16">
                    <div className={"font-12 attachment-information__title__div"}>
                        {translate("taskDetail.panels.attachmentInfo.uploadDate")}
                        <span className={"mt-2"}>{created || ""}</span>
                    </div>

                </div>
            </div>
            <div className="attachment-information__description mt-16 mb-3">
                <div className={"font-12 attachment-information__title__div pb-2"}>
                    {translate("taskDetail.panels.attachmentInfo.description")}
                </div>
                {description || ""}
            </div>

            <div className={"attachment-information__delete_area d-flex justify-content-between"}>
                <div className="attachment-information__name lightblue-40">
                    {uploaderName || ""}
                </div>

                {canBeDeleted &&
                <button type="button"
                        className={`btn btn-default attachment-information__delete_button ${taskKey ? "" : "disabled"}`}
                        onClick={handleDeleteAttachment}>
                    <span className={"vismaicon vismaicon-dynamic vismaicon-sm vismaicon-cancel-order"}/>
                    {translate("taskDetail.actionBar.deleteAttachment")}
                </button>
                }
            </div>
        </div>
    );

}

export default translate(AttachmentInformation);