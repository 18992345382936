
export interface StepIconType {
    PASSED: string,
    APPROVED: string,
    CURRENT: string,
    ACTIVE: string,
    FUTURE: string,
    SKIPPED: string,
    REJECTED: string,
    CANCELED: string,
};

export type StepIconKeyType = keyof StepIconType;

export enum StatusType{
    PASSED = 'PASSED',
    APPROVED = 'APPROVED',
    CURRENT = 'CURRENT',
    ACTIVE = 'ACTIVE',
    FUTURE = 'FUTURE',
    SKIPPED = 'SKIPPED',
    REJECTED = 'REJECTED',
    CANCELED = 'CANCELED',
}

export type StatusTypeKeys = keyof typeof StatusType;

export const StatusTypeKeysList: Array<StatusTypeKeys> = Object.keys(StatusType) as Array<StatusTypeKeys>;