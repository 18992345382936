import {
    ACTIVE_COUNTER_CHNAGED,
    CHANGE_DOCUMENT_KEY,
    DOCUMENT_OVERVIEW_DOCTYPE_CHANGED,
    DOCUMENT_OVERVIEW_DOCTYPE_CHANGED_TO_DEFAULT,
    DOCUMENT_OVERVIEW_LOADED,
    DOCUMENT_OVERVIEW_LOADING,
    DOCUMENT_OVERVIEW_SORT_COLUMN_CHANGED,
    DOCUMENT_OVERVIEW_TASK_SELECTED,
    DOCUMENT_PREVENT_DATA_RELOAD,
    DOCUMENT_SAVE_SCROLL_POSITION,
    LOAD_MORE_DOCUMENT_OVERVIEW,
    LOGGED_IN_TYPE,
    DOCUMENT_OVERVIEW_FILTER_CHANGED,
    RESET_CHANGE_DOCUMENT_KEY
} from '../store/actionTypes';
import {createSelector} from 'reselect';
import {TASK_OVERVIEW_FILTERS, LOADING_STATUS, DOCUMENT_OVERVIEW_STATUS} from '../../utils/constants';
import * as _ from 'lodash';
import {getUsersCurrentContextId} from '../usercontext/user.reducer';
import {formatDate} from '../../utils/valueFormatter.function';

export const DEFAULT_STATE = {
    activeCounter: DOCUMENT_OVERVIEW_STATUS.BLOCKED,
    counters: null,
    companies: [],
    documentType: 0,
    //we need to refresh redux when a document is updated after an edit
    documentKeyChanged: null,
    filter: {
        columns: {} //top grid filtering
    },
    loadingStatus: LOADING_STATUS.LOADING,
    preventReload: false,
    //sorting logic needed for the reactabular grid
    sortingLogic: {},
    selectedTasks: [],
    scrollTop: 0,
    tasks: []
};

export const getOverviewTasksForRender = createSelector(
    [getTasks, getHasDocumentKeyChanged],
    (tasksMap, newID) => {
        let rows = [];
        if (tasksMap && tasksMap.rows && tasksMap.rows.length > 0) {
            let mappedTasks = _.cloneDeep(tasksMap);
            rows = mappedTasks.rows.map((row, index) => {
                row.index = row.key + "_" + index;
                if (row.weekStart)
                    row.weekStart = formatDate(row.weekStart);
                if (row.weekEnd)
                    row.weekEnd = formatDate(row.weekEnd);
                if (row.fromDate)
                    row.fromDate = formatDate(row.fromDate);
                if (row.toDate)
                    row.toDate = formatDate(row.toDate);
                return row;
            });
        }
        return {
            rows: rows,
            records: tasksMap.records,
            page: tasksMap.page
        };
    }
);

export default function documentOverviewReducer(state = DEFAULT_STATE, action) {
    let partialState;
    switch (action.type) {

        case ACTIVE_COUNTER_CHNAGED:
            partialState = Object.assign([], state);
            partialState.activeCounter = action.data;
            partialState.tasks = [];
            partialState.selectedTasks = [];
            partialState.sortingLogic = {};
            return Object.assign({}, state, partialState);

        case CHANGE_DOCUMENT_KEY:
            partialState = Object.assign([], state);
            let oldDocIndex = _.findIndex(state.tasks.rows, (x => x.key === action.oldKey));
            if (oldDocIndex !== -1) {
                partialState.tasks.rows[oldDocIndex].key = action.newKey;
                partialState.documentKeyChanged = action.newKey;
            }
            return Object.assign({}, state, partialState);

        case DOCUMENT_OVERVIEW_DOCTYPE_CHANGED:
            partialState = {
                documentType: action.docType,
                filter: Object.assign({}, DEFAULT_STATE.filter)
            };
            return Object.assign({}, state, partialState);

        case DOCUMENT_OVERVIEW_DOCTYPE_CHANGED_TO_DEFAULT:
            partialState = {
                documentType: 0
            };
            return Object.assign({}, state, partialState);

        case DOCUMENT_OVERVIEW_LOADING:
            partialState = {
                loadingStatus: LOADING_STATUS.LOADING,
                tasks: [],
                selectedTasks: []
            };
            return Object.assign({}, state, partialState);

        case DOCUMENT_OVERVIEW_LOADED:
            partialState = Object.assign([], state);
            partialState.loadingStatus = LOADING_STATUS.DONE;
            partialState.tasks = action.data.tasks;
            partialState.counters = action.data.counters;
            partialState.companies = action.data.companies;
            partialState.documentKeyChanged = false;

            if (action.data.companies.length === 1) {
                partialState.documentType = action.data.companies[0];
            }

            return Object.assign({}, state, partialState);

        case DOCUMENT_OVERVIEW_SORT_COLUMN_CHANGED:
            partialState = {
                filter: Object.assign({}, state.filter),
                sortingLogic: Object.assign({}, action.sortLogic)
            };

            partialState.filter[TASK_OVERVIEW_FILTERS.SORTING] = action.sortColumn;
            return Object.assign({}, state, partialState);

        case DOCUMENT_OVERVIEW_TASK_SELECTED: {
            partialState = Object.assign([], state);
            let selected = Object.assign([], partialState.selectedTasks);
            const allSelection = action.key.length === state.tasks.rows.length;

            //master select checked
            if (allSelection) {
                //all tasks selected -> must unselect all
                if (state.selectedTasks.length === state.tasks.rows.length) {
                    selected = [];
                }
                //must select all
                else {
                    selected = action.key.map(taskkey => {
                        return taskkey;
                    });
                }
            }
            //single task select/unselect
            else {
                action.key.forEach(taskkey => {
                    let found = _.findIndex(state.selectedTasks, (x => x === taskkey));
                    if (found === -1) {
                        selected.push(taskkey);
                    } else {
                        selected.splice(found, 1);
                    }
                });
            }
            partialState.selectedTasks = selected;
            return Object.assign({}, state, partialState);
        }

        case DOCUMENT_PREVENT_DATA_RELOAD:
            partialState = {
                preventReload: action.data
            };
            return Object.assign({}, state, partialState);

        case DOCUMENT_SAVE_SCROLL_POSITION:
            partialState = {
                scrollTop: action.data
            };
            return Object.assign({}, state, partialState);

        case LOAD_MORE_DOCUMENT_OVERVIEW:
            let oldTasks = Object.assign([], state.tasks.rows);
            // concatenate old rows with new rows
            let allTasks = oldTasks.concat(action.data.rows);
            let newData = Object.assign({}, action.data);
            newData.rows = allTasks;
            partialState = {
                tasks: newData
            };
            return Object.assign({}, state, partialState);

        case  LOGGED_IN_TYPE:
            const newContextId = getUsersCurrentContextId(action.data);
            if (newContextId !== state.currentContext) {
                partialState = {
                    currentContext: newContextId,
                    activeCounter: DOCUMENT_OVERVIEW_STATUS.BLOCKED,
                    filter: Object.assign({}, DEFAULT_STATE.filter),
                    sortingLogic: {},
                    selectedTasks: []
                };
            } else {
                partialState = Object.assign({}, state);
            }
            return Object.assign({}, state, partialState);

        case DOCUMENT_OVERVIEW_FILTER_CHANGED:
            partialState = {
                filter: action.filter
            };
            return Object.assign({}, state, partialState);

        case RESET_CHANGE_DOCUMENT_KEY:
            partialState = Object.assign([], state);
            partialState.documentKeyChanged = null;
            return Object.assign({}, state, partialState);

        default:
            return state;
    }
}


export function getDocumentOverviewActiveCounter(state) {
    return state.activeCounter;
}

export function getOverviewCounters(state) {
    return state.counters;
}

export function getDocumentOverviewCompanies(state) {
    return state.companies;
}

export function getDocumentOverviewDocumentType(state) {
    return state.documentType;
}

export function getHasDocumentKeyChanged(state) {
    return state.documentKeyChanged;
}

export function getDocumentOverviewFilter(state) {
    return state.filter;
}

export function getLoadingStatus(state) {
    return state.loadingStatus;
}

export function shouldPreventDocumentReload(state) {
    return state.preventReload;
}

export function getDocumentOverviewSelectedTasks(state) {
    return state.selectedTasks;
}

export function getDocumentOverviewSortingLogic(state) {
    return state.sortingLogic;
}

export function getDocumentScrollTop(state) {
    return state.scrollTop;
}

export function getTasks(state) {
    return state.tasks ? state.tasks : [];
}
