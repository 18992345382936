import React, {Component} from 'react';
import {DropdownButton, Dropdown} from "react-bootstrap";
import {AMOUNT_RULE_TYPES} from 'utils/constants';
import * as reduxSelectors from '../store/application.reducers';
import {connect} from 'react-redux';
import ChangeAmountLimit from '../popup/ChangeAmountLimit.PopUp.component';

export class AmountCondition extends Component {

    constructor(props) {
        super(props);
        this.showChangeAmountLimit = this.showChangeAmountLimit.bind(this);
        this.changeLimit = this.changeLimit.bind(this);
        this.changeLimitHigh = this.changeLimitHigh.bind(this);
        this.changeLimitLow = this.changeLimitLow.bind(this);
        this.changeLimitType = this.changeLimitType.bind(this);
        this.toggleAmountCondition = this.toggleAmountCondition.bind(this);
        this.showChangeAmountLimit = this.showChangeAmountLimit.bind(this);

        this.state = {
            showAmountLimitsDialog: false
        }
    }

    changeLimit(value) {
        let newAmount = Object.assign({}, this.props.condition);
        newAmount.limit = value;
        this.props.changeAmountCondition(newAmount);
    };


    changeLimitHigh(value) {
        let newAmount = Object.assign({}, this.props.condition);
        newAmount.limitHigh = value;
        let isComplete = newAmount.limitLow !== null && newAmount.limitLow !== undefined;
        this.props.changeAmountCondition(newAmount, isComplete);
    };


    changeLimitLow(value) {
        let newAmount = Object.assign({}, this.props.condition);
        newAmount.limitLow = value;
        let isComplete = newAmount.limitHigh !== null && newAmount.limitHigh !== undefined;
        this.props.changeAmountCondition(newAmount, isComplete);
    };


    changeLimitType(value) {
        let newAmount = Object.assign({}, this.props.condition);
        newAmount.xtype = value;
        newAmount.conditionType = value;
        if (value === AMOUNT_RULE_TYPES["Visma.Approval.Rules.AmountConditionBetween"].xType) {
            if (newAmount.limit)
                delete newAmount.limit;
            newAmount.limitHigh = undefined;
            newAmount.limitLow = undefined;
        }
        else {
            if (newAmount.limitHigh)
                delete newAmount.limitHigh;
            if (newAmount.limitLow)
                delete newAmount.limitLow;
            newAmount.limit = undefined;
        }

        this.props.changeAmountCondition(newAmount, false);
    };


    toggleAmountCondition(event) {
        if (event.target.checked) {
            this.changeLimitType("Visma.Approval.Rules.AmountConditionBelow");
        }
        else {
            this.props.changeAmountCondition(null);
        }

    };

    showChangeAmountLimit() {
        this.setState({showAmountLimitsDialog: true});
    }

    render() {
        let props = this.props;

        let possibleAmountLimit = (onClick) => props.amountLimits ? props.amountLimits.map(amount => {
            return (<Dropdown.Item eventKey={amount.limitId}
                                   key={amount.limitId}
                                   onClick={() => onClick(amount.limitId)}>
                {amount.name}
            </Dropdown.Item>)
        }) : [];

        let hasAmountConditionSet = props.condition && AMOUNT_RULE_TYPES[props.condition.conditionType] !== undefined;
        let limit, lowerLimit, upperLimit, isSimpleLimit;

        if (hasAmountConditionSet) {
            isSimpleLimit = props.condition.conditionType !== AMOUNT_RULE_TYPES["Visma.Approval.Rules.AmountConditionBetween"].xType;
            if (isSimpleLimit) {
                limit = props.amountLimits.find(amount => {
                    return amount.limitId === props.condition.limit
                });
            }
            else {
                lowerLimit = props.amountLimits.find(amount => {
                    return amount.limitId === props.condition.limitLow
                });
                upperLimit = props.amountLimits.find(amount => {
                    return amount.limitId === props.condition.limitHigh
                });
            }
        }

        return (
            <div className="my-4">
                {this.state.showAmountLimitsDialog &&
                    <ChangeAmountLimit
                        translate={props.translate}
                        amountLimits={props.amountLimits}
                        closeCallback={() => {
                           this.setState({ showAmountLimitsDialog: false })
                        }}
                    />
                }

                {this.props.isStepLevel ?
                    <div className="active-slider">
                        <span className="me-3">{props.translate("workflowDetails.stepCondition")}</span>
                        <label className="switch switch-label">
                            <input type="checkbox"
                                   checked={hasAmountConditionSet} onChange={this.toggleAmountCondition}/>
                            <span className={"togglemark " + (hasAmountConditionSet ? "" : " disabled")}/>
                        </label>
                    </div>

                    : <div className="checkbox">
                        <input type="checkbox"
                               id={`amount-condition-checkbox-step${props.stepNumber}-rule${props.idRule || props.ruleNumber}`}
                               checked={hasAmountConditionSet}
                               onChange={this.toggleAmountCondition}/>
                        <label htmlFor={`amount-condition-checkbox-step${props.stepNumber}-rule${props.idRule || props.ruleNumber}`}>
                            {props.translate("workflowDetails.amountCondition")}
                        </label>
                    </div>
                }

                {hasAmountConditionSet &&
                    <div className="d-flex flex-row align-items-center mt-3">
                        <div className="d-inline-flex">
                            <DropdownButton className="text-start mb-0"
                                            bsPrefix="btn btn-default"
                                            id="amount_type"
                                            onSelect={this.changeLimitType}
                                            title={props.translate("workflowDetails." + AMOUNT_RULE_TYPES[props.condition.conditionType].translationKey)}
                            >
                                {Object.keys(AMOUNT_RULE_TYPES).map((key, index) => {
                                    return <Dropdown.Item eventKey={key} key={index}>
                                        {props.translate("workflowDetails." + AMOUNT_RULE_TYPES[key].translationKey)}
                                    </Dropdown.Item>
                                })}
                            </DropdownButton>
                        </div>
                        {isSimpleLimit ?
                            <div className="d-inline-flex">
                                <DropdownButton className="text-start amount mb-0"
                                                bsPrefix="btn btn-default"
                                                id="amount_limit"
                                                title={limit ? limit.name : ''}>
                                    {possibleAmountLimit(this.changeLimit)}
                                </DropdownButton>
                            </div>:
                            (<span>
                                    <div className="d-inline-flex">
                                        <DropdownButton
                                            className="text-start amount mb-0 me-0"
                                            bsPrefix="btn btn-default"
                                            id="amount_limit_low"
                                            title={lowerLimit ? lowerLimit.name : ''}>
                                            {possibleAmountLimit(this.changeLimitLow)}
                                        </DropdownButton>
                                    </div>
                                    <span className="d-inline-flex mx-3"> {props.translate("generic.and")} </span>
                                    <div className="d-inline-flex">
                                        <DropdownButton
                                            className="text-start amount mb-0"
                                            bsPrefix="btn btn-default"
                                            id="amount_limit_high"
                                            title={upperLimit ? upperLimit.name : ''}>
                                            {possibleAmountLimit(this.changeLimitHigh)}
                                        </DropdownButton>
                                    </div>
                                </span>
                            )
                        }

                        <a className="ms-3"
                           onClick={this.showChangeAmountLimit}
                        >
                            {props.translate("workflowDetails.changeLimits")}
                        </a>
                    </div>
                }
            </div>

        )
    }
}

const mapStateToProps = function (store) {
    return {
        amountLimits: reduxSelectors.getWorkflowsAmountLimits(store)
    };
};

const connected = connect(mapStateToProps, {})(AmountCondition);
export default connected;
