import React from "react";
import { OneButtonModalFooterProps } from "types/modals";

export const OneButtonModalFooter = ({
                                    tabIndex,
                                    enabled,
                                    callback,
                                    text,
                                    loopFocus
                                }: OneButtonModalFooterProps): React.ReactElement => {

    return (
        <div className="modal-footer">
            <div className="float-right">
                <button className="btn btn-default"
                        onClick={callback}
                        disabled={!enabled}
                        onBlur={loopFocus}
                        tabIndex={tabIndex + 1}>
                    {text}
                </button>
            </div>
        </div>

    )
}


export default OneButtonModalFooter;