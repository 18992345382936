import React from 'react';
import {eventBus} from "../../../pagelayout/EventBus";

export class CostCentersDetails extends React.Component {
    constructor() {
        super();

        this.state = {
            showMore: false,
            limit: 3
        };

        this.toggleVisible = this.toggleVisible.bind(this);
    }

    toggleVisible() {
        this.setState({
            showMore: !this.state.showMore
        }, () => {
            eventBus.dispatch('@@grid/refreshGridLayout');
        });
    }

    render() {
        const hiddenCostUnitsCount = this.props.costUnitList.length - this.state.limit;
        const costUnits = this.props.costUnitList
            .slice(0, this.state.showMore ? this.props.costUnitList.length : this.state.limit)
            .map((costUnit, index) => {
                return (
                    <div key={index}
                         className="document-data-field third">
                        <div className="document-data-field-label"
                             title={costUnit.dimensionName}>
                            {costUnit.dimensionName}
                        </div>
                        <div className="document-data-field-value"
                             title={`${costUnit.costUnitName} (${costUnit.costUnitNumber})`}>
                            {costUnit.costUnitName} ({costUnit.costUnitNumber})
                        </div>
                    </div>)
            });

        return (
            <div className="cost-units-vertical-overlay">
                <h4>{this.props.translate("documentData.costUnits")}</h4>
                <div className="document-data-divided">
                    {costUnits}
                </div>
                { this.props.costUnitList.length > this.state.limit && !this.state.showMore
                    ? <a className="cost-units-show-more" onClick={this.toggleVisible}>
                        {this.props.translate("documentData.costUnitsShowMore", hiddenCostUnitsCount)}
                    </a>
                    : null
                }
            </div>

        )
    }
};

export default CostCentersDetails;
