import React from "react";
import translate from "components/translations/translations.wrapper";


export const ImageUnavailable = ({style, translate}: any): React.ReactElement => {
    return (
        <div className={"page_container image-unavailable"} style={style}>
            <div className="encrypted-document-page w-75">
                <div className="height-100 w-100 font-20 align-vertical-center text-center no-border">
                    {translate("taskDetail.unavailableDocMessage")}
                </div>
            </div>
        </div>
    )
}

export default translate(ImageUnavailable);