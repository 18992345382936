/* globals ADRUM*/
import * as logger from './logger.function.js';

const GLOBAL = (typeof window !== 'undefined') ? window : null;
const APPD = (typeof ADRUM !== 'undefined') ? ADRUM : null;

/**
 * push error into AppDynamics programatically
 * @param errorMsg
 * @see https://docs.appdynamics.com/display/PRO42/Extend+the+JavaScript+Agent+for+Single+Page+Applications+%28SPAs%29+in+Browser+RUM
 */
export function pushErrorToAppDynamics(errorMsg, vPageView) {
    if (APPD === null) {
        return;
    }
    const errorT = new ADRUM.events.Error({
        msg: errorMsg,
        line: 1 // this attr seems to be mandatory
    });
    errorT.url(window.location.href);
    if (vPageView != null) {
        errorT.parent(vPageView);
    } else {
        errorT.parent(getGlobalPageView());
    }
    _reportToAppDynamics(errorT);
}

/**
 * Store the last generated vPageView in order to track AJAX and errors events
 * @param vPageView the vPageView to store
 */
function setGlobalPageView(vPageView) {
    if (GLOBAL !== null) {
        GLOBAL.vPageView = vPageView;
    }
}

/**
 * Retrieve the last generated vPageView to use as parent for AJAX and error events
 * @returns {*}
 */
export function getGlobalPageView() {
    if (GLOBAL !== null) {
        return GLOBAL.vPageView;
    }
}

/**
 * attach custom data into browser snapshot in the AppDynamics
 * @see https://docs.appdynamics.com/display/PRO42/Customize+the+JavaScript+Agent
 * @see https://docs.appdynamics.com/display/PRO42/Add+Custom+User+Data+to+a+Page+Browser+Snapshot
 * @param key
 * @param value
 */
export function pushCustomDataToAppDynamics(key, value) {
    console.log("ADD TO APPDYNAMICS---------------------------------", key, value, APPD);
    if (APPD === null) {
        return;
    }
    APPD.command("addUserData", key, value);
}

/**
 * Retrieve Ajax event.
 * The request is considered "sent" immediately, since FetchController API is not supported in any browser yet
 * https://developer.mozilla.org/en-US/docs/Web/API/FetchController
 * @param url url of the AJAX request
 * @param method method of the AJAX request
 * @param vPageView optional virtual page to attach to. If not specified, will use the current global page view
 * @returns {*}
 */
export function getAjaxTracker(url, method, vPageView) {
    if (APPD === null) {
        return null;
    }
    // Ignore dev urls
    if (url !== null && url.indexOf("sockjs-node") > -1) {
        return null;
    }
    const ajaxTracker = new APPD.events.Ajax();
    ajaxTracker.url(url);
    if (method !== null) {
        ajaxTracker.method(method);
    }
    if (vPageView != null) {
        ajaxTracker.parent(vPageView);
    } else {
        ajaxTracker.parent(getGlobalPageView());
    }
    logger.debug("ajax", url, ajaxTracker.parent());
    ajaxTracker.markSendTime();
    return ajaxTracker;
}

/**
 * On completion of an AJAX request, push event to AppDynamics
 * @param ajaxTracker the AJAX event to push
 */
export function pushAjaxTracker(ajaxTracker) {
    if (APPD === null || ajaxTracker === null) {
        return;
    }
    ajaxTracker.markRespProcTime();
    ajaxTracker.markFirstByteTime();
    ajaxTracker.markRespAvailTime();
    APPD.report(ajaxTracker);
}

/**
 * start of the UC to measure virtual page in AppDynamics
 * @param url page url
 * @param global if tru
 * @returns {*}
 * @see https://docs.appdynamics.com/display/PRO42/Extend+the+JavaScript+Agent+for+Single+Page+Applications+%28SPAs%29+in+Browser+RUM
 */
export function getPageStartTracker(url, global) {
    if (APPD === null) {
        return null;
    }
    logger.debug('creating virtualPage', url, new Date().getTime());
    const vPageView = new APPD.events.VPageView({url: url});
    // start measuring, don't correlate XHR automatically
    vPageView.markVirtualPageStart();
    vPageView.markViewChangeStart();
    if (global === true) {
        setGlobalPageView(vPageView);
    }
    return vPageView;
}

/**
 * Page is switched, but content is not loaded yet
 * @param vPageView already 'started' tracker
 */
export function pageSwitched(vPageView) {
    if (vPageView === null) {
        return;
    }
    if (vPageView.getViewChangeEnd() === null) {
        vPageView.markViewChangeEnd();
    }
    if (vPageView.getViewResourcesLoaded() === null) {
        vPageView.markViewResourcesLoaded();
    }
    if (vPageView.getViewDOMLoaded() === null) {
        vPageView.markViewDOMLoaded();
    }
}

/**
 * Data is loaded from server
 * @param vPageView already 'started' tracker
 */
export function xhrRequestsCompleted(vPageView) {
    if (vPageView === null) {
        return;
    }
    if (vPageView.getXhrRequestsCompleted() === null) {
        vPageView.markXhrRequestsCompleted();
    }
}

/**
 * UC when we haven't finished page loading, but user is already navigating away
 *
 * TODO AD haven't answered how should we properly track this use case, so see this question later for proper way how to do it
 * https://community.appdynamics.com/t5/EUM-and-Analytics-Mobile-Metrics/tracking-virtual-pages-work-with-VPageView/td-p/23497
 * @param vPageView already 'started' tracker
 * @see https://docs.appdynamics.com/display/PRO42/Extend+the+JavaScript+Agent+for+Single+Page+Applications+%28SPAs%29+in+Browser+RUM
 */
export function prematurePageEnd(vPageView) {
    if (vPageView === null) {
        return;
    }
    pageEnd(vPageView);
    // Report an error
    const errorT = new ADRUM.events.Error({
        msg: "prematurePageEnd",
        line: 1 // this attr seems to be mandatory
    });
    errorT.parent(vPageView);
    errorT.url(window.location.href);
    _reportToAppDynamics(errorT);
}

/**
 * mark page as loaded in AppDynamics
 * @param vPageView already 'started' tracker
 * @see https://docs.appdynamics.com/display/PRO42/Extend+the+JavaScript+Agent+for+Single+Page+Applications+%28SPAs%29+in+Browser+RUM
 */
export function pageEnd(vPageView) {
    if (!vPageView) {
        return;
    }
    logger.debug('ending VirtualPage', vPageView, new Date().getTime());

    pageSwitched(vPageView);
    xhrRequestsCompleted(vPageView);

    vPageView.markVirtualPageEnd();

    if (vPageView === getGlobalPageView()) {
        setGlobalPageView(null);
    }
    logger.debug("Report to APPD", vPageView._url);
    _reportToAppDynamics(vPageView);
}


function _reportToAppDynamics(adObject) {
    if (APPD === null || !adObject) {
        return;
    }
    APPD.report(adObject);
}

// bluebird unhandled rejection handler
if (typeof window.addEventListener !== "undefined") {
    window.addEventListener("unhandledrejection", function (e) {
        if (APPD === null) {
            return;
        }

        const reason = e.reason;
        // const promise = e.detail.promise;
        const title = "Unhandled rejection ";
        // reason can be an object containing a stack trace, so we could improve the message
        const message = title + String(reason);

        const errorT = new ADRUM.events.Error({
            msg: message,
            line: 1
        });
        let pageView = getGlobalPageView();
        // It seems like the error is not reported if there is no page view yet, so we force reporting
        // pageEnd
        if (pageView != null) {
            pageEnd(pageView);
            errorT.parent(pageView);
        }
        _reportToAppDynamics(errorT);
    });
}
